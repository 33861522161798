import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'antd';

import { translate } from 'utils/index';
import { getStatusFromRecommendations } from 'utils/findings';
import Table from 'ui/Table';
import Risk from 'pages/Recent/findingsColumns/risk';
import Status from 'pages/Recent/findingsColumns/status';
import { selectAssessment } from 'pages/Assessment/selectors';
import { sanitizer } from 'utils/sanitizer';
import Select from 'ui/Select';
import { selectFinding } from './reducers';
import {
  selectFinding as selectorsSelectFinding,
  selectAppFilterParameter,
  selectAppFindingsFilterParametersOptions,
  selectAllFindings,
} from './selectors';
import { getRiskValue } from './lib';
import { messages } from './messages';
import Icon from 'ui/Icon';
import { useRole } from 'hooks/useRole';
import AddFindingModal from 'pages/Assessment/addFinding';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { selectCurrentOrganization } from 'pages/Profile/selectors';
import { mixpanelTrackFindingViewed } from 'utils/mixpanel';

import './index.scss';

const Findings = ({ app }) => {
  const dispatch = useDispatch();
  const selectedFinding = useSelector(selectorsSelectFinding);
  const { risk, customerId } = useSelector(selectAssessment);
  const appFilterParameter = useSelector(selectAppFilterParameter);
  const appFilterParameterOptions = useSelector(selectAppFindingsFilterParametersOptions);
  const allAppFindings = useSelector(selectAllFindings);
  const [searchParams] = useSearchParams();
  const findingId = searchParams.get('findingId');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredFindings, setFilteredAppFindings] = useState([]);
  const [addFindingModal, setAddFindingModal] = useState();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { id: organizationId } = useSelector(selectCurrentOrganization);
  const { isRestrictedBoSoViewerRole } = useRole();

  useEffect(() => {
    if (findingId) {
      dispatch(selectFinding(findingId));
    }
  }, [findingId]);

  useEffect(() => {
    if (allAppFindings?.length) {
      setFilteredAppFindings(
        allAppFindings.filter((finding) => {
          if (selectedCategories?.length) {
            return selectedCategories.includes(finding.custom_data?.[appFilterParameter]);
          }
          return true;
        }),
      );
    }
  }, [allAppFindings, selectedCategories]);

  useEffect(() => {
    if (filteredFindings[0] && !selectedFinding?.id) {
      dispatch(selectFinding(filteredFindings[0].id));
    }
  }, [filteredFindings]);

  const onRow = (finding, rowIndex) => ({
    onClick: () => {
      dispatch(selectFinding(finding.id));
      mixpanelTrackFindingViewed({
        relation: customerId || organizationId,
        source: 'assessment',
        streamDirection: isMyVendorsTabSelected ? 'downstream' : 'upstream',
      });
    },
  });

  const columns = [
    {
      title: translate(messages.description),
      dataIndex: 'description',
      render: (description) =>
        description ? (
          <span dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
        ) : (
          translate(messages.noDescription)
        ),
    },
    {
      title: translate(messages.risk),
      dataIndex: 'risk',
      render: (_, finding) => {
        const riskValue = getRiskValue(finding.impact, finding.probability, risk);
        return <Risk risk={riskValue} />;
      },
    },
    {
      title: translate(messages.status),
      dataIndex: 'status',
      width: 140,
      render: (_, finding) => {
        const status = getStatusFromRecommendations(finding);
        return <Status status={status} finding={finding} />;
      },
    },
    {
      title: translate(messages.recommendations),
      dataIndex: 'recommendations',
      align: 'center',
      render: (recommendations) => {
        return recommendations.length ? (
          <div className="assessment-finalized-findings__recommendations">
            {recommendations.length > 9 ? '9+' : recommendations.length}
          </div>
        ) : (
          ''
        );
      },
    },
  ];

  const rowClassName = (record, rowIndex) => {
    return record?.id === selectedFinding?.id ? 'selected' : '';
  };

  return (
    <div className="assessment-finalized-findings">
      <div className="assessment-finalized-findings__header-title">
        <div className="assessment-finalized-findings__header-title-inner">
          <span className="assessment-finalized-findings__header-title-text">
            {translate(messages.findings)}
          </span>
          {` (${filteredFindings?.length})`}
        </div>
        {isMyVendorsTabSelected ? (
          <Button
            type="link"
            className="assessment-finalized-add-finding__button"
            disabled={isRestrictedBoSoViewerRole}
            onClick={(e) => {
              e.stopPropagation();
              setAddFindingModal(true);
            }}
          >
            <Icon icon="plus" />
            {translate(messages.addFinding)}
          </Button>
        ) : null}
      </div>
      {appFilterParameterOptions?.length > 1 && (
        <Select
          mode="multiple"
          innerLabel={translate(messages.categorySelectLabel)}
          showArrow={true}
          options={appFilterParameterOptions || []}
          value={selectedCategories || []}
          allowClear={true}
          onChange={(value) => {
            setSelectedCategories(value);
          }}
        />
      )}

      <Table
        className="assessment-finalized-findings__findings-table"
        columns={columns}
        dataSource={filteredFindings}
        onRow={onRow}
        pagination={false}
        rowClassName={rowClassName}
      />
      {allAppFindings?.[0]?.control ? (
        <AddFindingModal
          visible={addFindingModal}
          toggleModal={setAddFindingModal}
          control={allAppFindings[0].control}
          app={app}
        />
      ) : null}
    </div>
  );
};

Findings.propTypes = {
  app: PropTypes.object,
};

export default Findings;
