export const messages = {
  authMethodTitle: {
    key: 'AuthMethod.authMethodTitle',
    defaultMessage: 'Second authentication method:',
  },
  authMethodButtonNone: {
    key: 'AuthMethod.authMethodButtonNone',
    defaultMessage: 'None',
  },
  authMethodButtonEmail: {
    key: 'AuthMethod.authMethodButtonEmail',
    defaultMessage: 'Email',
  },
  authMethodButtonSMS: {
    key: 'AuthMethod.authMethodButtonSMS',
    defaultMessage: 'SMS',
  },
  toUseSmsFor2Fa: {
    key: 'AuthMethod.toUseSmsFor2Fa',
    defaultMessage: "To use SMS for 2FA, please add your phone number in 'personal data tab'",
  },
};

export default {
  prefix: 'AuthMethod',
  messages,
};
