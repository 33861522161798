import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import './index.scss';

const DoughnutChart = ({ className, color, labels, title, value }) => {
  return (
    <div className="ui-doughnut-chart-container">
      <div className="ui-doughnut-chart-container__content">{value}%</div>
      <Doughnut
        className={className}
        options={{
          borderRadius: 50,
          cutout: '85%',
          events: [],
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: !!title,
              text: title,
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              label: 'My First Dataset',
              data: [value, 100 - value],
              backgroundColor: [color, '#E0DEDE'],
            },
          ],
        }}
      />
    </div>
  );
};

DoughnutChart.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  value: PropTypes.number.isRequired,
};

export default DoughnutChart;
