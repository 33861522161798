export const messages = {
  modalTitle: {
    key: 'uploadListModal.modalTitle',
    defaultMessage: 'Upload completed',
  },
  vendorSuccess: {
    key: 'uploadListModal.vendorSuccess',
    defaultMessage: 'Vendors were created successfully',
  },
  vendorError: {
    key: 'uploadListModal.vendorError',
    defaultMessage: 'Vendors failed to upload',
  },
  customerSuccess: {
    key: 'uploadListModal.customerSuccess',
    defaultMessage: 'Customers were created successfully',
  },
  customerError: {
    key: 'uploadListModal.customerError',
    defaultMessage: 'Customers failed to upload',
  },
  buttonText: {
    key: 'uploadListModal.buttonText',
    defaultMessage: 'OK, GOT IT',
  },
  fileDidntUploadLink: {
    key: 'uploadListModal.fileDidntUploadLink',
    defaultMessage: 'CLICK HERE',
  },
  vendorDescription: {
    key: 'uploadListModal.vendorDescription',
    defaultMessage: `A file with the list of the <em>failed vendors is downloading.</em><br/>Please fix the issues and re-upload the remaining vendors.`,
  },
  customerDescription: {
    key: 'uploadListModal.customerDescription',
    defaultMessage:
      'A file with the list of the <em>failed customers is downloading.</em><br/>Please fix the issues and re-upload the remaining customers.',
  },
};

export default {
  prefix: 'uploadListModal',
  messages,
};
