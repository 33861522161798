import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Dropdown } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { translate } from 'utils/index';
import { createSorterByString } from 'utils/strings';
import Icon from 'ui/Icon';
import Table from 'ui/Table';
import Button from 'ui/Button';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import ImportWizardMenuModal from 'pages/Assessments/importWizardMenuModal';
import { getAssessmentsTemplates, removeTemplate } from 'api/assessmentsTemplates';
import { DATE_FORMAT } from 'constants/date';
import ROUTES from 'constants/routes';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useTheme } from 'hooks/theme';

import Search from './search';
import Actions from './actions';
import { selectTemplates, selectTemplatesLoading } from './selectors';
import { messages } from './messages';
import { TableSource } from 'ui/Table/lib';
import { useRole } from 'hooks/useRole';

import './index.scss';

const Templates = () => {
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();
  const templates = useSelector(selectTemplates);
  const loading = useSelector(selectTemplatesLoading);
  const removeTemplateLoading = useSelector(
    (state) => state.assessmentsTemplates.removeTemplateLoading,
  );
  const { isRestrictedBoSoViewerRole } = useRole();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { colorTheme } = useTheme();
  const [templateToRemove, setTemplateToRemove] = useState();
  const [openWizard, setOpenWizard] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    dispatch(getAssessmentsTemplates());
  }, []);

  useEffect(() => {
    setDataSource(
      (templates || []).map((template) => ({
        ...template,
        key: template.id,
      })),
    );
  }, [templates]);

  const onSearch = (value) => {
    setDataSource(() => {
      const mappedTemplates = (templates || []).map((template) => ({
        ...template,
        key: template.id,
      }));
      return value
        ? mappedTemplates.filter((data) => {
            return data.name.toLowerCase().includes(value.toLowerCase());
          })
        : mappedTemplates;
    });
  };

  const columns = [
    {
      title: translate(messages.lastUpdated),
      dataIndex: 'timestampEdited',
      width: 160,
      render: (timestampEdited) => moment(timestampEdited).format(DATE_FORMAT),
      sorter: (a, b) => new Date(a.timestampEdited) - new Date(b.timestampEdited),
    },
    {
      title: translate(messages.title),
      dataIndex: 'name',
      sorter: createSorterByString('name'),
    },
    {
      title: translate(messages.description),
      dataIndex: 'description',
      sorter: createSorterByString('description'),
    },
    {
      title: translate(messages.numberOfUse),
      dataIndex: 'numberOfUse',
      sorter: (a, b) => a.assessmentName - b.assessmentName,
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id, template) => (
        <Actions
          template={template}
          onRemove={(event) => {
            event.stopPropagation();
            setTemplateToRemove(template);
          }}
        />
      ),
    },
  ];

  const onRemove = async () => {
    const payload = {
      'remove-assessments-template-template-token': templateToRemove.id,
    };

    await dispatch(removeTemplate(payload)).unwrap();
    setTemplateToRemove(null);
    await dispatch(getAssessmentsTemplates());
  };

  const onRow = (template) => ({
    onClick: () => {
      appNavigate(
        isMyVendorsTabSelected
          ? ROUTES.VENDOR_ASSESSMENT_TEMPLATE
          : ROUTES.CUSTOMER_ASSESSMENT_TEMPLATE,
        { templateId: template.id },
      );
    },
  });

  const onClickCreateNew = () =>
    appNavigate(
      isMyVendorsTabSelected
        ? ROUTES.VENDOR_ASSESSMENT_TEMPLATE
        : ROUTES.CUSTOMER_ASSESSMENT_TEMPLATE,
      { templateId: uuidv4() },
    );

  const onClickAddTemplate = () => setOpenWizard((prevState) => !prevState);

  const handlePopupClose = () => setOpenWizard(false);

  const menuItems = [
    {
      key: 'create-new-assessment-template',
      icon: <Icon icon="edit2" />,
      label: translate(messages.createNew),
      onClick: onClickCreateNew,
      'data-test': 'create-new-assessment-template',
    },
    {
      key: 'upload-new-assessment-template',
      icon: <Icon icon="upload2" />,
      label: translate(messages.addFromFile),
      onClick: onClickAddTemplate,
      'data-test': 'upload-new-assessment-template',
    },
  ];

  return (
    <div className="assessments-page-templates">
      <div className="assessments-page-templates__actions">
        <Search onSearch={onSearch} />

        <div className="assessments-page-templates__actions-right">
          <Dropdown
            menu={{ items: menuItems, className: 'assessments-page-templates__menu' }}
            trigger="click"
            disabled={isRestrictedBoSoViewerRole}
          >
            <Button
              data-test="assessments-page-templates-button-create-new-template"
              className="assessments-page-templates__add-template-button"
              beforeIcon="plus"
              color={colorTheme}
              size="sm"
            >
              {translate(messages.addTemplate)}
            </Button>
          </Dropdown>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        onRow={onRow}
        emptyStateSource={TableSource.assessmentsTemplates}
      />
      <RemoveConfirmationModal
        headerText={translate(messages.removeTemplateHeaderText)}
        confirmLoading={removeTemplateLoading}
        onOk={onRemove}
        onCancel={() => setTemplateToRemove(null)}
        text={translate(messages.removeTemplateText)}
        open={!!templateToRemove}
      />
      <ImportWizardMenuModal popupState={openWizard} onClose={handlePopupClose} />
    </div>
  );
};

export default Templates;
