import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as DocumentsIcon } from 'assets/svgs/my-files-empty-docs.svg';
import { ReactComponent as UploadIcon } from 'assets/svgs/upload-icon.svg';
import Button from 'ui/Button';
import { messages } from './messages';
import { translate } from 'utils/index';
import { useRole } from 'hooks/useRole';

import './emptyFilesList.scss';

function EmptyFilesList({ handleClickButton, onFileUpload }) {
  const { isRestrictedBoSoViewerRole } = useRole();

  return (
    <div className="my-files-uploaded-item-list__empty">
      <DocumentsIcon />
      <div className="my-files-uploaded-item-list__empty-info">
        {translate(messages.emptyListMessage)}
        <div className="my-files-uploaded-item-list__empty-info-max-size">
          {translate(messages.maxSizeInfo)}
        </div>
      </div>
      {isRestrictedBoSoViewerRole ? null : (
        <div className="my-files-uploaded-item-list__empty-button">
          <input type="file" id="fileInput" style={{ display: 'none' }} onChange={onFileUpload} />
          <Button
            onClick={handleClickButton}
            beforeComponent={<UploadIcon width={24} />}
            color="blue"
            link
            data-test={'upload-new-file'}
          >
            {translate(messages.uploadNewFile)}
          </Button>
        </div>
      )}
    </div>
  );
}

EmptyFilesList.propTypes = {
  handleClickButton: PropTypes.func,
  onFileUpload: PropTypes.func,
};

export default EmptyFilesList;
