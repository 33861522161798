import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';

import Button from 'ui/Button';
import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';
import Icon from 'ui/Icon';
import Popup from 'ui/Popup';
import Input from 'ui/Input';
import { createOrganization, getProfileOrganizations } from 'api/organizations';
import { API_STATUS } from 'constants/api';
import { getProfile } from 'api/profile';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { selectProfile } from 'pages/Profile/selectors';
import { useRole } from 'hooks/useRole';

const NewOrganizationButton = () => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { isRestrictedBoSoViewerAuditorRole } = useRole();
  const {
    user: { unifiedPendingAssessments },
  } = useSelector(selectProfile);
  const [organizationName, setOrganizationName] = useState({
    value: '',
    error: '',
  });

  const onChangeInput = ({ target: { value } }) => {
    setOrganizationName({ value, error: '' });
  };

  const setPopupOnClick = () => {
    setShowPopup(!showPopup);
    setOrganizationName(() => ({ value: '', error: '' }));
  };

  const onClickSave = async () => {
    if (!organizationName.value.trim()) {
      setOrganizationName((state) => ({
        ...state,
        error: translate(messages.orgSettingsNewOrgInputError),
      }));
      return;
    }

    setLoading(true);
    const { status } = await createOrganization(organizationName.value);
    setPopupOnClick();
    if (status === API_STATUS.FAILED) {
      notification.error({ message: translate(messages.orgSettingsNewOrgError) });
      setLoading(false);
      return;
    }
    dispatch(getProfileOrganizations());
    if (unifiedPendingAssessments.length > 0) {
      dispatch(getProfile({ upstream: !isMyVendorsTabSelected }));
    }
    notification.success({ message: translate(messages.orgSettingsNewOrgSuccess) });
    setLoading(false);
  };

  return (
    <>
      <Button
        data-test="new-organization-button-create-new-organization"
        className="organizations-settings-page__button"
        size="sm"
        name={translate(messages.orgSettingsNewOrganization)}
        beforeComponent={<Icon className="organizations-settings-page__button-icon" icon="plus" />}
        color="pink"
        onClick={setPopupOnClick}
        disabled={isRestrictedBoSoViewerAuditorRole}
      />

      <Popup.WithForm
        className="organizations-settings-page__new-org-popup"
        open={showPopup}
        onClickCancel={setPopupOnClick}
        withIconOnTitle="plus"
        iconColor="pink"
        title={translate(messages.orgSettingsNewOrgTitle)}
        width={400}
        onClickOk={onClickSave}
        okLoading={loading}
        key="new-organization-popup"
      >
        <Input
          data-test="new-organization-input-name"
          block
          key="new-organization-name"
          name="new-organization-name"
          type="text"
          value={organizationName.value}
          placeholder={translate(messages.orgSettingsNewOrgPlaceHolder)}
          onChange={onChangeInput}
          error={organizationName.error}
        />
      </Popup.WithForm>
    </>
  );
};

export default NewOrganizationButton;
