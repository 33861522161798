import React, { useCallback, useEffect, useRef } from 'react';
import { Table as AntdTable } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// COMPONENTS
import Loader from 'ui/Loader';
import EmptyState from 'ui/Table/EmptyState';

// STYLE
import './index.scss';
import { mappedColumns } from 'ui/Table/lib';

const Table = ({
  columns,
  children,
  className,
  loading,
  onRow,
  emptyStateSource,
  locale,
  rowKey,
  ...props
}) => {
  const tableClassName = classNames('ui-table', className, {
    'ui-table--cursor-default': onRow === false,
  });

  const ref = useRef(null);

  const sortableColumns = useCallback(
    columns.map((column) => {
      const { sorter, dataIndex, ...otherColumnProps } = column;

      if (sorter) {
        const { compare, ...otherSorterProps } = sorter;

        return {
          ...otherColumnProps,
          dataIndex,
          sorter: compare
            ? {
                compare: (rowA, rowB) => compare(rowA[dataIndex], rowB[dataIndex]),
                ...otherSorterProps,
              }
            : sorter,
        };
      }

      return column;
    }),
    [columns],
  );

  useEffect(() => {
    if (ref.current && rowKey) {
      ref.current.querySelectorAll('.ant-table-row').forEach((row, rowIndex) => {
        const rowKeyValue = `${rowKey}-row-${rowIndex}`;
        row.setAttribute('data-test', rowKeyValue);
        const cells = row.querySelectorAll('.ant-table-cell');
        cells.forEach((cell, cellIndex) => {
          cell.setAttribute('data-test', `${rowKeyValue}-col-${cellIndex}`);
        });
      });
    }
  }, [columns, props.dataSource, rowKey]);

  return (
    <AntdTable
      ref={ref}
      className={tableClassName}
      columns={mappedColumns(sortableColumns)}
      loading={
        loading
          ? { indicator: <Loader className="ui-table__loader" withBackdrop={false} /> }
          : false
      }
      locale={locale || { emptyText: <EmptyState source={emptyStateSource} /> }}
      onRow={onRow}
      {...props}
    >
      {children}
    </AntdTable>
  );
};

Table.Column = AntdTable.Column;
Table.ColumnGroup = AntdTable.ColumnGroup;
Table.Summary = AntdTable.Summary;

Table.propTypes = {
  columns: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  onRow: PropTypes.func,
  rowKey: PropTypes.string,
  dataSource: PropTypes.array,
  emptyStateSource: PropTypes.string,
  locale: PropTypes.object,
};

export default Table;
