export const messages = {
  assignAssessment: {
    key: 'AssignAssessment.assignAssessment',
    defaultMessage: 'Assign assessment',
  },
  selectFramework: {
    key: 'AssignAssessment.selectFramework',
    defaultMessage: 'Select framework',
  },
  saveAndActive: {
    key: 'AssignAssessment.saveAndActive',
    defaultMessage: 'SAVE AND ACTIVE',
  },
  createNewFramework: {
    key: 'AssignAssessment.createNewFramework',
    defaultMessage: 'CREATE NEW FRAMEWORK',
  },
  createNewFrameworkFromFile: {
    key: 'AssignAssessment.createNewFrameworkFromFile',
    defaultMessage: 'CREATE NEW FRAMEWORK FROM FILE',
  },
  or: {
    key: 'AssignAssessment.or',
    defaultMessage: 'or',
  },
  performedBy: {
    key: 'AssignAssessment.performedBy',
    defaultMessage: 'Performed by',
  },
  vendors: {
    key: 'AssignAssessment.vendors',
    defaultMessage: 'Vendor(s)',
  },
  customers: {
    key: 'AssignAssessment.customers',
    defaultMessage: 'Customers(s)',
  },
  self: {
    key: 'AssignAssessment.self',
    defaultMessage: 'Self',
  },
  selectVendor: {
    key: 'AssignAssessment.selectVendor',
    defaultMessage: 'Select vendor',
  },
  selectVendorDescription: {
    key: 'AssignAssessment.selectVendorDescription',
    defaultMessage: 'You can select more than one vendor',
  },
  selectCustomer: {
    key: 'AssignAssessment.selectCustomer',
    defaultMessage: 'Select customer',
  },
  selectCustomerDescription: {
    key: 'AssignAssessment.selectCustomerDescription',
    defaultMessage: 'You can select more than one customer',
  },
  pleaseSelect: {
    key: 'AssignAssessment.pleaseSelect',
    defaultMessage: 'Please select',
  },
  createNewVendor: {
    key: 'AssignAssessment.createNewVendor',
    defaultMessage: 'CREATE NEW VENDOR',
  },
  createNewCustomer: {
    key: 'AssignAssessment.createNewCustomer',
    defaultMessage: 'CREATE NEW CUSTOMER',
  },
  dueDate: {
    key: 'AssignAssessment.dueDate',
    defaultMessage: 'Due date',
  },
  assessmentTypeRequired: {
    key: 'AssignAssessment.assessmentTypeRequired',
    defaultMessage: 'Assessment type is required',
  },
  vendorRequired: {
    key: 'AssignAssessment.vendorRequired',
    defaultMessage: 'Vendor is required',
  },
  untitledAssessment: {
    key: 'AssignAssessment.untitledAssessment',
    defaultMessage: 'Untitled Assessment',
  },
  assignAndSend: {
    key: 'AssignAssessment.assignAndSend',
    defaultMessage: 'Assign and send',
  },
  ok: {
    key: 'AssignAssessment.ok',
    defaultMessage: 'OK, GOT IT',
  },
  emailSent: {
    key: 'AssignAssessment.emailSent',
    defaultMessage: 'An email with a link to the assessment was sent to',
  },
  youCanFollow: {
    key: 'AssignAssessment.youCanFollow',
    defaultMessage: 'You can follow the assessment status on the assessment or vendor tab',
  },
  selectFrameworkDescription: {
    key: 'AssignAssessment.selectFrameworkDescription',
    defaultMessage: 'Please select from the following list, you can select more than one framework',
  },
  assessmentSent: {
    key: 'AssignAssessment.assessmentSent',
    defaultMessage: 'The assessment has been assigned successfully',
  },
  showcaseSent: {
    key: 'AssignAssessment.showcaseSent',
    defaultMessage: 'The showcase has been assigned successfully',
  },
  vendorNoticed: {
    key: 'AssignAssessment.vendorNoticed',
    defaultMessage: 'The vendor has been notified via email',
  },
  customerNoticed: {
    key: 'AssignAssessment.customerNoticed',
    defaultMessage: 'The customer has been notified via ema',
  },
};

export default {
  prefix: 'AssignAssessment',
  messages,
};
