import React from 'react';
import PropTypes from 'prop-types';

const DashboardIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M5.75 3C4.24011 3 3 4.24011 3 5.75V18.25C3 19.7599 4.24011 21 5.75 21H18.25C19.7599 21 21 19.7599 21 18.25V15.873C21.0132 15.7925 21.0132 15.7104 21 15.6299V5.75C21 4.24011 19.7599 3 18.25 3H5.75ZM5.75 4.5H18.25C18.9491 4.5 19.5 5.05089 19.5 5.75V15H17.3652L16.4854 10.6025C16.4512 10.4327 16.3593 10.2799 16.2252 10.1701C16.0912 10.0603 15.9233 10.0004 15.75 10.0004C15.5767 10.0004 15.4088 10.0603 15.2748 10.1701C15.1407 10.2799 15.0488 10.4327 15.0146 10.6025L14.1348 15H13.8975L12.7422 7.14062C12.7162 6.96585 12.6294 6.80586 12.497 6.68884C12.3646 6.57182 12.1952 6.50529 12.0186 6.50098C11.8355 6.49639 11.6572 6.55889 11.517 6.67671C11.3769 6.79453 11.2847 6.95953 11.2578 7.14062L10.1025 15H9.81543L8.9707 12.0439C8.92562 11.8876 8.83095 11.7502 8.70095 11.6524C8.57095 11.5546 8.41268 11.5017 8.25 11.5017C8.08732 11.5017 7.92905 11.5546 7.79905 11.6524C7.66905 11.7502 7.57438 11.8876 7.5293 12.0439L6.68457 15H4.5V5.75C4.5 5.05089 5.05089 4.5 5.75 4.5ZM12 12.4043L12.4453 15.4316C12.5345 16.0397 13.0667 16.5 13.6816 16.5H14.3398C14.9305 16.5 15.4492 16.0749 15.5654 15.4961C15.5654 15.4958 15.5654 15.4954 15.5654 15.4951L15.75 14.5742L15.9336 15.4951C16.0496 16.0755 16.5687 16.5 17.1602 16.5H19.5V18.25C19.5 18.9491 18.9491 19.5 18.25 19.5H5.75C5.05089 19.5 4.5 18.9491 4.5 18.25V16.5H6.87305C7.42789 16.5 7.92223 16.1272 8.0752 15.5938L8.25 14.9795L8.42578 15.5938C8.57845 16.1262 9.07333 16.5 9.62793 16.5H10.3184C10.9333 16.5 11.4655 16.0397 11.5547 15.4316L12 12.4043Z"
      fill={color}
    />
  </svg>
);

DashboardIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default DashboardIcon;
