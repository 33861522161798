import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { translate } from 'utils/index';
import PageHeader from 'components/PageHeader';
import { getOverview } from 'api/overview';
import Loader from 'ui/Loader';
import DoughnutChart from 'ui/DoughnutChart';
import PieChart from 'ui/PieChart';
import TileWithTitle from 'ui/TileWithTitle';
import BarChart from 'ui/BarChart';
import StackedBarChart from 'ui/StackedBarChart';
import Tabs from 'ui/Tabs';
import { OverviewTab } from 'constants/overviewTab';
import { selectOverview, selectOverviewLoading } from './selectors';
import Findings from './findings';
import Alert from './alert';
import Statuses from './statuses';
import Filters from './filters';
import { messages } from './messages';
import './index.scss';

const Overview = () => {
  const dispatch = useDispatch();
  const overview = useSelector(selectOverview);
  const loading = useSelector(selectOverviewLoading);
  const [selectedOverviewTab, setSelectedOverviewTab] = useState(OverviewTab.Status);
  useEffect(() => {
    dispatch(getOverview());
  }, []);

  const isOverviewEmpty = Object.keys(overview).length === 0;

  const categories = Object.keys(overview['score-by-category'] || {});
  const benchmarks = [];
  const scores = [];
  categories.forEach((key) => {
    benchmarks.push(overview['score-by-category'][key].benchmark);
    scores.push(overview['score-by-category'][key].score);
  });

  return (
    <div className="overview-page">
      <div className="overview-page__container">
        <PageHeader>{translate(messages.overview)}</PageHeader>
        <Filters />
        <Statuses />
        <Alert />
        {(loading || isOverviewEmpty) && <Loader className="overview-page__loader" />}
      </div>
      <div className="overview-page__first-row-content">
        <TileWithTitle
          className="overview-page__first-row-tile"
          title={translate(messages.score)}
          subtitle={translate(messages.averageScore)}
        >
          <DoughnutChart color="#E45647" value={overview['asessments-score']} />
        </TileWithTitle>
        <TileWithTitle
          className="overview-page__first-row-tile"
          title={translate(messages.progress)}
          subtitle={translate(messages.averageProgress)}
        >
          <DoughnutChart color="#FF8F0F" value={overview['asessments-progress']} />
        </TileWithTitle>
        <Findings className="overview-page__first-row-tile" />
        {(loading || isOverviewEmpty) && <Loader className="overview-page__loader" />}
      </div>
      <div className="overview-page__second-row-content">
        <TileWithTitle
          className="overview-page__second-row-tile"
          title={translate(messages.assessmentStatistics)}
        >
          <Tabs
            activeKey={selectedOverviewTab}
            className="overview-page__tabs"
            onChange={(selectedTab) => setSelectedOverviewTab(selectedTab)}
            hideShadowForContent
          >
            <Tabs.Pane
              data-test="overview-tab-status"
              tab={`${translate(messages.status)}`}
              key={OverviewTab.Status}
            >
              <PieChart
                data={[
                  {
                    id: 1,
                    name: translate(messages.completed),
                    value: overview['assessments-by-status']?.completed,
                    color: '#A7E262',
                  },
                  {
                    id: 2,
                    name: translate(messages.inProgress),
                    value: overview['assessments-by-status']?.in_progress,
                    color: '#FFA034',
                  },
                  {
                    id: 3,
                    name: translate(messages.notStarted),
                    value: overview['assessments-by-status']?.not_started,
                    color: '#E45647',
                  },
                ]}
              />
            </Tabs.Pane>
            <Tabs.Pane
              data-test="overview-tab-score"
              tab={`${translate(messages.scoreCategory)}`}
              key={OverviewTab.ScoreCategory}
            >
              <StackedBarChart
                fistColor="rgb(46, 140, 188, 0.5)"
                secondColor="rgb(46,140,188, 0.9)"
                firstValues={[...scores]}
                secondValues={[...benchmarks]}
                labels={[...categories]}
              />
            </Tabs.Pane>
            <Tabs.Pane
              data-test="overview-tab-pass-fail"
              tab={`${translate(messages.score)}`}
              key={OverviewTab.Score}
            >
              <BarChart
                color={[
                  '#E45647',
                  '#E7675A',
                  '#EE8168',
                  '#E2826C',
                  '#E69481',
                  '#FFCA8E',
                  '#FFB055',
                  '#ABE26B',
                  '#A0D85F',
                  '#88CE37',
                ]}
                values={[
                  overview['assessments-by-score']?.s10,
                  overview['assessments-by-score']?.s20,
                  overview['assessments-by-score']?.s30,
                  overview['assessments-by-score']?.s40,
                  overview['assessments-by-score']?.s50,
                  overview['assessments-by-score']?.s60,
                  overview['assessments-by-score']?.s70,
                  overview['assessments-by-score']?.s80,
                  overview['assessments-by-score']?.s90,
                  overview['assessments-by-score']?.s100,
                ]}
                labels={['10', '20', '30', '40', '50', '60', '70', '80', '90', '100']}
              />
            </Tabs.Pane>
            <Tabs.Pane
              data-test="overview-tab-score-category"
              tab={`${translate(messages.passFail)}`}
              key={OverviewTab.PassFail}
            >
              <PieChart
                data={[
                  {
                    id: 1,
                    name: translate(messages.passed),
                    value: overview['assessment-by-result']?.pass,
                    color: '#A7E262',
                  },
                  {
                    id: 2,
                    name: translate(messages.inProgress),
                    value: overview['assessment-by-result']?.in_progress,
                    color: '#FFA034',
                  },
                  {
                    id: 3,
                    name: translate(messages.failed),
                    value: overview['assessment-by-result']?.fail,
                    color: '#E45647',
                  },
                ]}
              />
            </Tabs.Pane>
          </Tabs>
        </TileWithTitle>
        <TileWithTitle
          className="overview-page__second-row-tile"
          title={translate(messages.findingStatus)}
        >
          <BarChart
            color={['#2E8CBC', '#4C5FBD', '#88CE37']}
            values={[
              overview['findings-by-status']?.pending,
              overview['findings-by-status']?.accept,
              overview['findings-by-status']?.approved,
            ]}
            labels={[
              translate(messages.pending),
              translate(messages.accept),
              translate(messages.approved),
            ]}
          />
        </TileWithTitle>
        {(loading || isOverviewEmpty) && <Loader className="overview-page__loader" />}
      </div>
    </div>
  );
};

export default Overview;
