import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { getExcelSuggestedQuestions, saveOfflineAssessment } from 'api/importWizard';
import {
  createCategory,
  transformApiResponse,
  CATEGORY_COLORS,
  CATEGORY_ALT_COLORS,
  getColumnIndex,
  manuallySelectArea,
  updateSelectCells,
  isAreaInActive,
  sortCellsForActiveSelection,
  getCurrentActiveCategory,
  initSelection,
  getCellsFromActiveSheet,
} from 'components/ImportWizard/lib';

const initialState = {
  selectedType: '',
  fileName: '',
  fileData: undefined,
  suggestedQuestions: {},
  categories: {},

  activeSheetName: '',
  activeCategory: undefined,
  activeSelection: 'questions',

  stateSelectedCells: {},
  baseSelectedCells: {},
  currentSelectedCells: {},

  baseCategories: {},
  currentCategories: {},
  isPreview: false,
  isCreating: false,

  manuallySelection: initSelection,
};

const reducers = createSlice({
  name: 'excelImportWizard',
  initialState,
  reducers: {
    setParam: (state, { payload: { key, value } }) => {
      state[key] = value;
    },
    resetParams: () => {
      return initialState;
    },
    setActiveSheetName: (state, { payload: { name } }) => {
      state.activeSheetName = name;
    },
    setFile: (state, { payload: { name, type } }) => {
      state.selectedType = type;
      state.fileName = name;
    },
    setActiveCategory: (state, { payload: { panelKey } }) => {
      if (!panelKey) {
        state.manuallySelection = initSelection;
      }
      state.activeCategory = panelKey;
    },
    setActiveSelection: (state, { payload: { selectionArea } }) => {
      if (!state.activeCategory || !selectionArea) {
        state.manuallySelection = initSelection;
        return;
      }

      state.activeSelection = selectionArea;

      const sortedSelections = Object.entries(getCellsFromActiveSheet(state))
        .filter(
          ([, cellData]) =>
            state.activeCategory === cellData.id && cellData.activeSelection === selectionArea,
        )
        .sort(([cellCodeA], [cellCodeB]) => +cellCodeA.slice(1) - +cellCodeB.slice(1));

      if (sortedSelections.length === 0) {
        return;
      }

      const startData = sortedSelections[0];
      const end =
        sortedSelections.length === 1 ? '' : sortedSelections[sortedSelections.length - 1][0];

      if (!startData || !end) {
        return;
      }
      const start = startData[0];
      const column = getColumnIndex(start[0]) + 1;

      manuallySelectArea(state, start.slice(1), end.slice(1), column, startData[1].color);
    },
    deleteCategory: (state, { payload: { categoryId } }) => {
      delete state.categories[state.activeSheetName][categoryId];
      state.currentCategories = state.categories;
    },
    setPreview: (state) => {
      if (state.isPreview) {
        state.categories = state.currentCategories;
        state.stateSelectedCells = state.currentSelectedCells;
      } else {
        state.categories = state.baseCategories;
        state.stateSelectedCells = state.baseSelectedCells;
        state.activeCategory = undefined;
      }

      state.isPreview = !state.isPreview;
    },
    setStartEndRange: (state, { payload: { value, field } }) => {
      if (isAreaInActive(state) || value.length === 1) {
        return;
      }
      const currentCategory = getCurrentActiveCategory(state);
      const isQuestion = state.activeSelection === 'questions';
      const currentCellsEntries = sortCellsForActiveSelection(state);

      const start = field === 'start' ? value : currentCellsEntries[0]?.[0];

      const end =
        // eslint-disable-next-line  no-nested-ternary
        field === 'start'
          ? currentCellsEntries.length === 1
            ? undefined
            : currentCellsEntries[currentCellsEntries.length - 1]?.[0]
          : value;

      const startValue = start ? +start.slice(1) : undefined;
      const endValue = end ? +end.slice(1) : undefined;
      if (startValue > endValue) {
        return;
      }
      const column = value[0];

      updateSelectCells(state, startValue, endValue, column, currentCategory, isQuestion);
      manuallySelectArea(
        state,
        startValue,
        endValue,
        column ? getColumnIndex(column) + 1 : undefined,
        currentCategory.color,
      );
    },
    setSelectionRange: (state, { payload: { start, end } }) => {
      if (isAreaInActive(state)) {
        return;
      }
      const currentCategory = getCurrentActiveCategory(state);
      const isQuestion = state.activeSelection === 'questions';
      updateSelectCells(
        state,
        +start.slice(1),
        +end.slice(1),
        start[0],
        currentCategory,
        isQuestion,
      );
    },
    setCategoryField: (state, { payload: { id, field, value } }) => {
      state.categories[state.activeSheetName][id][field] = value;
      state.currentCategories = state.categories;
    },
    addNewCategory: (state) => {
      const id = uuidv4();
      const colorIndex = Object.keys(state.categories[state.activeSheetName]).length + 1;
      const color = CATEGORY_COLORS[colorIndex % CATEGORY_COLORS.length];
      const alternativeColor = CATEGORY_ALT_COLORS[colorIndex % CATEGORY_ALT_COLORS.length];

      const emptyCategorySection = {
        column: '',
        start: '',
        end: '',
      };

      state.categories[state.activeSheetName][id] = createCategory(
        id,
        emptyCategorySection,
        emptyCategorySection,
        color,
        alternativeColor,
      );
      state.currentCategories = state.categories;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getExcelSuggestedQuestions.fulfilled,
        (state, { payload: { suggested_questions: suggestedQuestions } }) => {
          state.suggestedQuestions = suggestedQuestions;
          const { newCategories, newSelectedCells } = transformApiResponse(suggestedQuestions);
          state.categories = newCategories;
          state.baseCategories = newCategories;
          state.currentCategories = newCategories;

          state.stateSelectedCells = newSelectedCells;
          state.baseSelectedCells = newSelectedCells;
          state.currentSelectedCells = newSelectedCells;
          // eslint-disable-next-line prefer-destructuring
          state.activeSheetName = Object.keys(newCategories)[0];

          const firstCategoryId = Object.entries(state.categories[state.activeSheetName])
            .map(([, categoryData]) => categoryData)
            .reduce((prev, current) => {
              const prevValue = +prev.questions.start.slice(1);
              const currentValue = +current.questions.start.slice(1);
              return prevValue < currentValue ? prev : current;
            }).id;

          state.activeCategory =
            state.categories?.[state.activeSheetName]?.[firstCategoryId]?.id || undefined;
        },
      )
      .addCase(saveOfflineAssessment.pending, (state) => {
        state.isCreating = true;
      })
      .addMatcher(
        isAnyOf(saveOfflineAssessment.fulfilled, saveOfflineAssessment.rejected),
        (state) => {
          state.isCreating = false;
        },
      );
  },
});

export const {
  setParam,
  resetParams,
  setActiveSheetName,
  setCategoryField,
  setActiveCategory,
  setActiveSelection,
  deleteCategory,
  addNewCategory,
  setPreview,
  setFile,
  setStartEndRange,
  setSelectionRange,
} = reducers.actions;

export default reducers;
