export const messages = {
  // button
  filtersButton: {
    key: 'filters.filtersButton',
    defaultMessage: 'FILTER',
  },

  // popup
  popupTitle: {
    key: 'filters.popupTitle',
    defaultMessage: 'Filters: ',
  },
  filtersTitleTooltipTitle: {
    key: 'filters.filtersTitleTooltipTitle',
    defaultMessage: 'How Filters Work:',
  },
  filtersTitleTooltipContent: {
    key: 'filters.filtersTitleTooltipContent',
    defaultMessage:
      '<ul><li><b>Multi Select: </b> You can select multiple options within a filter. This will apply an &quot;OR&quot; action, showing results that match any of the selected options.</li><li><b>AND Action:</b> Applying multiple filters will result in an &quot;AND&quot; action, showing results that match all selected criteria. Additionally, <b>you can select the same filter twice</b> with different options to match all results.</li></ul>',
  },
  clearAllFilters: {
    key: 'filters.clearAllFilters',
    defaultMessage: 'CLEAR ALL',
  },
  applyFilters: {
    key: 'filters.applyFilters',
    defaultMessage: 'APPLY FILTERS',
  },
  addFilterButton: {
    key: 'filters.addFilterButton',
    defaultMessage: 'ADD FILTER',
  },
  add: {
    key: 'filters.addFilterButton',
    defaultMessage: 'ADD',
  },
  where: {
    key: 'filters.where',
    defaultMessage: 'Where',
  },
  and: {
    key: 'filters.and',
    defaultMessage: 'and',
  },
  or: {
    key: 'filters.or',
    defaultMessage: 'or',
  },
  emptyFilters: {
    key: 'filters.emptyFilters',
    defaultMessage: 'No filters are selected',
  },
  queryTextWhere: {
    key: 'filters.queryTextWhere',
    defaultMessage: 'Where',
  },
  queryTextAnd: {
    key: 'filters.queryTextAnd',
    defaultMessage: 'and',
  },
  queryTextIn: {
    key: 'filters.queryTextIn',
    defaultMessage: 'In',
  },
  queryTextSelect: {
    key: 'filters.queryTextSelect',
    defaultMessage: 'Select',
  },

  // filters labels:
  vendorName: {
    key: 'filters.vendorName',
    defaultMessage: 'Vendor name',
  },
  customerName: {
    key: 'filters.customerName',
    defaultMessage: 'Customer name',
  },
  vendorGroup: {
    key: 'filters.vendorGroup',
    defaultMessage: 'Vendor group',
  },
  vendorStatus: {
    key: 'filters.vendorStatus',
    defaultMessage: 'Vendor status',
  },
  vendorRiskScore: {
    key: 'filters.vendorRiskScore',
    defaultMessage: 'Vendor risk score',
  },
  customerRiskScore: {
    key: 'filters.customerRiskScore',
    defaultMessage: 'Customer risk score',
  },
  vendorEngagementScore: {
    key: 'filters.vendorEngagementScore',
    defaultMessage: 'Vendor engagement score',
  },
  businessOwner: {
    key: 'filters.businessOwner',
    defaultMessage: 'Business owner',
  },
  auditor: {
    key: 'filters.auditor',
    defaultMessage: 'Auditor',
  },
  assessmentName: {
    key: 'filters.assessmentName',
    defaultMessage: 'Assessment name',
  },
  assessmentScore: {
    key: 'filters.assessmentScore',
    defaultMessage: 'Assessment score',
  },
  assessmentStatus: {
    key: 'filters.assessmentStatus',
    defaultMessage: 'Assessment status',
  },
  riskreconGrade: {
    key: 'filters.RiskreconGrade',
    defaultMessage: 'Riskrecon grade',
  },
  riskreconCriticalFindings: {
    key: 'filters.riskreconCriticalFindings',
    defaultMessage: 'Riskrecon critical findings',
  },
  awsCloudMonitoring: {
    key: 'filters.awsCloudMonitoring',
    defaultMessage: 'AWS cloud monitoring',
  },
  azureCloudMonitoring: {
    key: 'filters.azureCloudMonitoring',
    defaultMessage: 'Azure cloud monitoring',
  },
  securityScorecard: {
    key: 'filters.securityScorecard',
    defaultMessage: 'Security scorecard',
  },
  findingsStatus: {
    key: 'filters.findingsStatus',
    defaultMessage: 'Findings status',
  },
  findingsCriticality: {
    key: 'filters.findingsCriticality',
    defaultMessage: 'Findings criticality',
  },
  evidenceExpiration: {
    key: 'filters.evidenceExpiration',
    defaultMessage: 'Evidence expiration',
  },
  organizationName: {
    key: 'filters.organizationName',
    defaultMessage: 'Organization name',
  },
  response: {
    key: 'filters.response',
    defaultMessage: 'Response',
  },
  assessment: {
    key: 'filters.assessment',
    defaultMessage: 'Assessment',
  },
  category: {
    key: 'filters.category',
    defaultMessage: 'Category',
  },
  control: {
    key: 'filters.control',
    defaultMessage: 'Control',
  },
  answer: {
    key: 'filters.answer',
    defaultMessage: 'Response',
  },

  // filters descriptions:
  responseDescription: {
    key: 'filters.responseDescription',
    defaultMessage:
      'Response filter requires you to make selections in a specific order. Begin by choosing an assessment. Once an assessment is selected, relevant options for the next filed will appear. ',
  },

  // empty state
  noMatchingResultsFound: {
    defaultMessage: 'No matching results found',
    key: 'generalMessages.noMatchingResultsFound',
  },
  clearFilters: {
    defaultMessage: 'Clear filters',
    key: 'generalMessages.clearFilters',
  },
};

export default {
  prefix: 'filters',
  messages,
};
