import { createSlice } from '@reduxjs/toolkit';
import { getProfileFindings } from 'api/finding';
import { DEFAULT_PAGINATION } from 'constants/api';

const initialState = {
  findings: [],
  count: 10,
  loading: false,
  totalFindings: 0,
  pagination: DEFAULT_PAGINATION,
  sorter: {},
};

const reducers = createSlice({
  name: 'findingsPage',
  initialState,
  reducers: {
    setParam: (state, { payload: { key, value } }) => {
      state[key] = value;
    },
    setSorter: (state, { payload }) => {
      state.sorter = payload || {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileFindings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfileFindings.fulfilled, (state, action) => {
        const total = action.payload?.total || 0;
        const findings = action.payload?.findings || [];
        const column = action.payload?.column || [];
        const descending = action.payload?.descending || false;

        return {
          ...state,
          findings,
          column,
          descending,
          totalFindings: total,
          loading: false,
          pagination: {
            ...state?.pagination,
            total,
          },
        };
      });
  },
});

export const { setParam, setSorter } = reducers.actions;

export default reducers;
