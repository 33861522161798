import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

// COMPONENTS
import Button from 'ui/Button';

// HOOKS AND REDUX
import { useEmptyState } from 'ui/Table/useEmptyState';
import useHandleApplyFilters from 'components/Filters/useHandleApplyFilters';

// ASSETS
import { ReactComponent as OpenFolder } from 'assets/svgs/open-folder-filters.svg';
import { messages } from 'components/Filters/messages';
import { translate } from 'utils/index';

// STYLES
import './emptyState.scss';

function EmptyState({ source }) {
  const { icon, text, filterableKey } = useEmptyState(source);
  const handleApplyFilters = useHandleApplyFilters({ filterableKey });
  const filters = useSelector((state) => state.filters);

  const iconClassNames = classNames('table-empty-state__icon', {
    'table-empty-state__icon--recent': source?.includes('Recent'),
    'table-empty-state__icon--default': !source?.includes('Recent'),
  });

  const textClassNames = classNames('table-empty-state__text-wrap', {
    'table-empty-state__text-wrap--recent': source?.includes('Recent'),
  });

  const filtersFromURLParamsCount = useMemo(() => {
    return filters?.allFilters[filterableKey]?.filtersCount;
  }, [source, filters, filterableKey]);

  const filtersText = useMemo(() => {
    return (
      <div className="table-empty-state__filters-text">
        <p>{translate(messages.noMatchingResultsFound)}</p>
        <Button
          data-test="empty-state-button-clear-filters"
          color={'blue'}
          onClick={() => handleApplyFilters(true)}
          style={{ textTransform: 'uppercase' }}
          size={'xs'}
          link
        >
          {translate(messages.clearFilters)}
        </Button>
      </div>
    );
  }, [source, filterableKey]);

  return (
    <div className="table-empty-state" data-test={`empty-table-${source}`}>
      <div className={iconClassNames}>
        {filtersFromURLParamsCount ? <OpenFolder /> : <img src={icon} alt={source} />}
      </div>
      <div className={textClassNames}>{filtersFromURLParamsCount ? filtersText : text}</div>
    </div>
  );
}

EmptyState.propTypes = {
  source: PropTypes.string.isRequired,
};

export default EmptyState;
