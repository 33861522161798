import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Popover } from 'antd';

// UI:
import TextArea from 'ui/TextArea';
import Spin from 'ui/Spin';

import './index.scss';

const EditableContent = ({
  className,
  loading,
  onChange,
  placeholder,
  value,
  disabled = false,
  ...otherProps
}) => {
  const [editable, setEditable] = useState();
  const [height, setHeight] = useState();
  const ref = useRef();

  useEffect(() => {
    const offsetHeight = ref?.current?.offsetHeight;
    if (offsetHeight) {
      setHeight(`${offsetHeight}px`);
    }
  }, [editable]);

  const renderPopover = (content, children) => {
    if (content?.length > 50) return <Popover content={content}>{children}</Popover>;
    return children;
  };

  return (
    <div className={classNames('editable-content', className)} {...otherProps}>
      {editable && !disabled ? (
        <TextArea
          autoFocus
          className="editable-content__editable"
          onBlur={() => setEditable(false)}
          onChange={onChange}
          placeholder={placeholder}
          style={{ height }}
          value={value}
          disabled={disabled}
        />
      ) : (
        renderPopover(
          value,
          <div
            data-test="editable-content-div-content"
            className="editable-content__content"
            onClick={() => setEditable(true)}
            ref={ref}
          >
            {value ? <pre className="editable-content__pre">{value}</pre> : placeholder}
          </div>,
        )
      )}
      {loading && <Spin className="editable-content__loader" size={16} />}
    </div>
  );
};

EditableContent.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.node,
  disabled: PropTypes.bool,
};

export default EditableContent;
