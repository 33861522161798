import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { getMyFiles, setFileName, deleteFile } from 'api/myFiles';
import { editFileMetaData } from 'api/assessment';
import { removeFilterReducer, setFilterReducer } from 'components/AddFilterButton/filterReducers';
import { FIRST_OFFSET } from './constants';

const initialState = {
  myFiles: [],
  filters: [],
  isLoading: false,
  isFileNameLoading: false,
  isDeleteFileLoading: false,
  currentOffset: 1,
  totalOffset: 0,
  totalRecords: 0,
  recordsFiltered: 0,
};

const reducers = createSlice({
  name: 'myFiles',
  initialState,
  reducers: {
    setFilter: (state, { payload: { type, value, id } }) => {
      return setFilterReducer(state, type, value, id);
    },
    removeFilter: (state, { payload: { type } }) => removeFilterReducer(state, type),
    clearFilters: (state) => {
      state.filters = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyFiles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setFileName.pending, (state) => {
        state.isFileNameLoading = true;
      })
      .addCase(deleteFile.pending, (state) => {
        state.isDeleteFileLoading = true;
      })
      .addMatcher(isAnyOf(getMyFiles.fulfilled, getMyFiles.rejected), (state, data) => {
        state.totalRecords = data.payload?.totalRecords || 0;
        state.recordsFiltered = data.payload?.recordsFiltered || 0;
        state.currentOffset = data.payload?.currentOffset || 0;
        state.totalOffset = data.payload?.totalOffset || 0;
        if (data.payload?.files && Array.isArray(data.payload?.files)) {
          if (data.payload?.currentOffset > FIRST_OFFSET) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            state.myFiles = [...state.myFiles, ...data?.payload?.files];
          } else {
            state.myFiles = data.payload?.files;
          }
        } else {
          state.myFiles = [];
        }

        state.isLoading = false;
      })
      .addMatcher(isAnyOf(setFileName.fulfilled, setFileName.rejected), (state, { payload }) => {
        if (payload?.title && payload?.fileId) {
          const fileIndex = state.myFiles.findIndex((file) => file.id === payload.fileId);
          if (fileIndex >= 0) {
            state.myFiles[fileIndex].title = payload.title;
          }
        }
        state.isFileNameLoading = false;
      })
      .addMatcher(
        isAnyOf(editFileMetaData.fulfilled, editFileMetaData.rejected),
        (state, { payload }) => {
          if (payload?.metadata && payload?.fileId) {
            const fileIndex = state.myFiles.findIndex((file) => file.id === payload.fileId);
            if (fileIndex >= 0) {
              state.myFiles[fileIndex].metadata = payload.metadata;
            }
          }
          state.isFileNameLoading = false;
        },
      )
      .addMatcher(isAnyOf(deleteFile.fulfilled, deleteFile.rejected), (state, { payload }) => {
        if (payload?.fileId) {
          state.myFiles = state.myFiles.filter((file) => file.id !== payload.fileId);
        }
        state.isDeleteFileLoading = false;
      });
  },
});

export const { clearFilters, setFilter, removeFilter } = reducers.actions;

export default reducers;
