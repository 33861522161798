import React from 'react';
import PropTypes from 'prop-types';

import { ControlType } from 'constants/controlType';

import Answer from './answer';
import AnswerTypeOptions from './answerTypeOptions';

const AnswerPreview = ({ addQuestion, answerType, control, onSave }) => {
  switch (answerType.type) {
    case ControlType.Input:
    case ControlType.TextArea:
    case ControlType.FileUpload:
    case ControlType.DatePicker:
      return <Answer control={control} onSave={onSave} />;
    case ControlType.RadioGroup:
      return (
        <AnswerTypeOptions
          addQuestion={addQuestion}
          control={control}
          controlType={ControlType.RadioGroup}
          options={answerType.options}
          onSave={onSave}
        />
      );
    case ControlType.Checkbox:
      return (
        <AnswerTypeOptions
          addQuestion={addQuestion}
          control={control}
          controlType={ControlType.Checkbox}
          options={answerType.options}
          onSave={onSave}
        />
      );
    case ControlType.DropDown:
    case ControlType.MultiSelect:
      return (
        <AnswerTypeOptions
          addQuestion={addQuestion}
          control={control}
          options={answerType.options}
        />
      );
    default:
      return null;
  }
};

AnswerPreview.propTypes = {
  addQuestion: PropTypes.func,
  answerType: PropTypes.object,
  control: PropTypes.object,
  onSave: PropTypes.func,
};

export default AnswerPreview;
