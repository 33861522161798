import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import Assessment from 'pages/Assessment';

// REDUX
import { setShowCasePreview } from 'pages/Assessment/reducers';

// STYLES
import './assessmentPreview.scss';

const AssessmentPreview = ({ assessmentId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowCasePreview(true));
    return () => {
      dispatch(setShowCasePreview(false));
    };
  }, []);

  return (
    <div className="showcase-preview-assessment">
      <Assessment showcaseAssessmentPreview={assessmentId} />
    </div>
  );
};

AssessmentPreview.propTypes = {
  assessmentId: PropTypes.string,
};

AssessmentPreview.defaultProps = {
  assessmentId: '4e162a30-5f35-4b12-a760-da024260eb80',
};

export default AssessmentPreview;
