import { createSlice } from '@reduxjs/toolkit';
import { getProfile, setProfileLogo, setProfile } from 'api/profile';
import { datadogRum } from '@datadog/browser-rum';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  user: {
    email: '',
    phone: '',
    corporateGeography: undefined,
    nationalNumber: undefined,
    fullname: undefined,
    current_organization: {
      id: '',
    },
    lang: undefined,
    corporateName: '',
    corporateLogo: '',
    mfa_method: 1,
  },
  setProfileResponse: {},
};

const reducers = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileParam: (state, { payload: { key, value } }) => {
      state.user[key] = value;
    },
    setProfileState: (state, { payload: { user } }) => {
      state.user = { ...state.user, ...user };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state, { payload: { profile } }) => {
      state.user = profile || { ...state.user, fullname: '' };
      if (profile?.hashId) {
        const id = uuidv4();
        const orgId = profile?.current_organization?.id;
        datadogRum.setUser({ id, name: profile?.fullname, email: profile?.email, orgId });
      }
    });
    builder.addCase(setProfileLogo.fulfilled, (state, { payload: { corporateLogo } }) => {
      state.user.corporateLogo = corporateLogo;
    });
    builder.addCase(setProfile.fulfilled, (state, { payload }) => {
      state.setProfileResponse = payload;
    });
  },
});

export const { setProfileParam, setProfileState } = reducers.actions;

export default reducers;
