export const messages = {
  removeFileText: {
    key: 'FileList.removeFileText',
    defaultMessage: 'Are you sure you want to permanently delete this file?',
  },
};

export default {
  prefix: 'FileList',
  messages,
};
