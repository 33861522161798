import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Form = ({ autoComplete, children, className, ...props }) => {
  const formClassNames = classNames('ui-form', className);
  return (
    <form autoComplete={autoComplete} className={formClassNames} {...props}>
      {children}
    </form>
  );
};

Form.propTypes = {
  autoComplete: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};

Form.defaultProps = {
  autoComplete: 'on',
};

export default Form;
