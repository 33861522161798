import { getCookie, setCookie } from './apiHelpers';
import { isDevelopment, isTesting } from 'utils/index';

export const TOKEN_AUTH_KEY = 'token2';
export const ORG_ID_AUTH_KEY = 'org_id';
export const WS_TOKEN_AUTH_KEY = 'ws_token';
export const ADMIN_TOKEN = 'admin_token';

export const setAuthenticationTokens = ({ token, org_id: organizationId, ws_token: wsToken }) => {
  // Cookies set in production by BE
  if (!isDevelopment() && !isTesting()) {
    return;
  }

  setCookie(TOKEN_AUTH_KEY, token);
  setCookie(ORG_ID_AUTH_KEY, organizationId);
  setCookie(WS_TOKEN_AUTH_KEY, wsToken);
};

export const getAuthenticationToken = () => {
  // Token inaccessible in production by FE
  if (!isDevelopment() && !isTesting()) {
    return;
  }
  return getCookie(TOKEN_AUTH_KEY);
};
