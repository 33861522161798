export const messages = {
  stageOneLeftDescriptionTitle: {
    key: 'SoPage.stageOneLeftDescriptionTitle',
    defaultMessage: 'New Customer',
  },
  stageOneLeftDescriptionText: {
    key: 'SoPage.stageOneLeftDescriptionText',
    defaultMessage: 'Security Assessment Request',
  },
  stageOneDefaultTitle: {
    key: 'SoPage.stageOneDefaultTitle',
    defaultMessage: 'Welcome',
  },
  stageOneDefaultSubTitle: {
    key: 'SoPage.stageOneDefaultSubTitle',
    defaultMessage: 'to our new customer request page',
  },
  stageOneDefaultDescriptionPart1: {
    key: 'SoPage.stageOneDefaultDescriptionPart1',
    defaultMessage:
      'All you need to do is to answer the risk classification page and the security team will take it from there',
  },
  stageOneDefaultDescriptionPart2: {
    key: 'SoPage.stageOneDefaultDescriptionPart2',
    defaultMessage: 'If you have any questions, please do not hesitate to contact us at ',
  },
  stageTwoLeftDescriptionTitle: {
    key: 'SoPage.stageTwoLeftDescriptionTitle',
    defaultMessage: 'Customer details',
  },
  stageTwoLeftDescriptionText: {
    key: 'SoPage.stageTwoLeftDescriptionText',
    defaultMessage: 'Please fill in the following contact details',
  },
  stageThreeLeftDescriptionTitle: {
    key: 'SoPage.stageThreeLeftDescriptionTitle',
    defaultMessage: 'Security Requirements',
  },
  stageThreeLeftDescriptionText: {
    key: 'SoPage.stageThreeLeftDescriptionText',
    defaultMessage: 'Please fill in the following Security question',
  },
  stageFourOkButton: {
    key: 'SoPage.stageFourOkButton',
    defaultMessage: 'add another customer',
  },
};

export default {
  prefix: 'SoPage',
  messages,
};
