export const messages = {
  placeholder: {
    key: 'TextEditor.placeholder',
    defaultMessage: 'Start writing...',
  },
};

export default {
  prefix: 'TextEditor',
  messages,
};
