import React from 'react';
import PropTypes from 'prop-types';

const RecentIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M11 2C5.48603 2 1 6.48604 1 12C1 17.514 5.48603 22 11 22C16.514 22 21 17.514 21 12C21 6.48604 16.514 2 11 2ZM11 3.5C15.7033 3.5 19.5 7.2967 19.5 12C19.5 16.7033 15.7033 20.5 11 20.5C6.29669 20.5 2.5 16.7033 2.5 12C2.5 9.76877 3.36137 7.748 4.76172 6.2334L9.89258 9.39062C10.3766 9.68862 11 9.34096 11 8.77246V3.5ZM5.95703 8.49512C5.79499 8.50228 5.63964 8.56177 5.51426 8.66468C5.38889 8.76758 5.30025 8.90836 5.26163 9.0659C5.22302 9.22343 5.23651 9.38924 5.30009 9.53846C5.36366 9.68768 5.4739 9.81227 5.61426 9.89355L10.6143 12.8936C10.7175 12.9555 10.8342 12.9916 10.9543 12.9989C11.0745 13.0062 11.1947 12.9845 11.3047 12.9355L15.8047 10.9355C15.8947 10.8955 15.976 10.8382 16.0439 10.7668C16.1118 10.6954 16.1649 10.6113 16.2003 10.5193C16.2357 10.4274 16.2526 10.3293 16.2501 10.2308C16.2476 10.1323 16.2257 10.0353 16.1857 9.94524C16.1457 9.8552 16.0883 9.77392 16.0169 9.70606C15.9455 9.63819 15.8614 9.58506 15.7694 9.5497C15.6774 9.51434 15.5794 9.49744 15.4809 9.49997C15.3824 9.5025 15.2853 9.52441 15.1953 9.56445L11.0518 11.4062L6.38574 8.60645C6.2573 8.52703 6.10788 8.48823 5.95703 8.49512Z"
      fill={color}
    />
  </svg>
);

RecentIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default RecentIcon;
