import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

const ScoreChip = ({ value }) => {
  const scoreChipClassNames = classNames('score-chip', {
    'score-chip__primary': value >= 67,
    'score-chip__warning': value < 67 && value > 33,
    'score-chip__danger': value <= 33,
  });

  return <div className={scoreChipClassNames}>score: {value}%</div>;
};

ScoreChip.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ScoreChip;
