import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getStatusFromRecommendations } from 'utils/findings';
import { translate } from 'utils/index';
import ImpactSelect from 'components/ImpactSelect';
import ProbabilitySelect from 'components/ProbabilitySelect';
import Risk from 'pages/Recent/findingsColumns/risk';
import Status from 'pages/Recent/findingsColumns/status';
import { saveFinding } from 'api/finding';

import { selectAssessment } from 'pages/Assessment/selectors';
import { selectFinding } from './selectors';
import { getRiskValue } from './lib';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import './findingStatuses.scss';

const FindingStatuses = () => {
  const finding = useSelector(selectFinding);
  const { risk, impact, probability } = useSelector(selectAssessment);
  const findingLoading = useSelector(({ assessmentFindings }) => assessmentFindings.findingLoading);
  const { assessor } = useSelector(selectAssessment);
  const [values, setValues] = useState(finding);
  const dispatch = useDispatch();
  const { isViewerRole } = useRole();

  useEffect(() => {
    setValues(finding);
  }, [finding]);

  const riskValue = getRiskValue(values.impact, values.probability, risk);
  const status = getStatusFromRecommendations(finding);

  const impactClassName = classNames(
    'assessment-finalized-finding-statuses__impact',
    `assessment-finalized-finding-statuses__impact--${values.impact}`,
  );

  const probabilityClassName = classNames(
    'assessment-finalized-finding-statuses__probability',
    `assessment-finalized-finding-statuses__probability--${values.probability}`,
  );

  const onChange = (field, value) => {
    setValues((state) => ({ ...state, [field]: value }));

    const payload = {
      control: finding.control,
      finding: {
        id: finding.id,
        [field]: value,
      },
    };

    dispatch(saveFinding(payload));
  };

  return assessor ? (
    <div className="assessment-finalized-finding-statuses">
      <ImpactSelect
        disabled={isViewerRole}
        onChange={(value) => onChange('impact', value)}
        loading={findingLoading && values.impact !== finding.impact}
        options={impact}
        value={values.impact || undefined}
      />
      <ProbabilitySelect
        disabled={isViewerRole}
        onChange={(value) => onChange('probability', value)}
        loading={findingLoading && values.probability !== finding.probability}
        options={probability}
        value={values.probability || undefined}
      />
      <Risk label={`${translate(messages.risk)}:`} risk={riskValue} />
    </div>
  ) : (
    <div className="assessment-finalized-finding-statuses assessment-finalized-finding-statuses--preview">
      <div className="assessment-finalized-finding-statuses__impact-wrapper">
        <label className="assessment-finalized-finding-statuses__impact-label">
          {translate(messages.impact)}:
        </label>
        <div className={impactClassName}>
          {impact.find(({ value }) => value === values.impact)?.key ||
            translate(messages.notSpecified)}
        </div>
      </div>
      <div className="assessment-finalized-finding-statuses__probability-wrapper">
        <label className="assessment-finalized-finding-statuses__probability-label">
          {translate(messages.probability)}:
        </label>
        <div className={probabilityClassName}>
          {probability.find(({ value }) => value === values.probability)?.key ||
            translate(messages.notSpecified)}
        </div>
      </div>
      <Risk label={`${translate(messages.risk)}:`} risk={riskValue} />
      <Status status={status} finding={finding} />
    </div>
  );
};

export default FindingStatuses;
