import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getProfile } from 'api/profile';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import AppPreloader from 'ui/Loader/appPreloader';
import { setMixpanelIdentity } from 'utils/mixpanel';
import { isProduction, isProductionDomain } from 'utils/index';
import { API_STATUS } from 'constants/api';
import { Roles } from 'constants/roles';
import { getProfileOrganizations } from 'api/organizations';
import { selectOrganizationsExtended } from 'pages/Organization/selectors';
import { getOrganizationIdByIndex } from 'utils/organizations';
import { setCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY } from 'utils/auth';

const LoggedUser = ({ children }) => {
  const dispatch = useDispatch();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { organization: organizationIndex } = useParams();
  const [loading, setLoading] = useState(true);
  const { organizations: organizationsExtended } = useSelector(selectOrganizationsExtended) || [];

  const getProfileData = useCallback(async () => {
    setLoading(true);
    let organizationId = null;

    if (organizationsExtended.length === 0) {
      const { payload: organizations } = await dispatch(getProfileOrganizations());
      organizationId = getOrganizationIdByIndex(organizations, organizationIndex);
    } else {
      organizationId = getOrganizationIdByIndex(organizationsExtended, organizationIndex);
    }

    setCookie(ORG_ID_AUTH_KEY, organizationId);

    const resp = await dispatch(getProfile({ upstream: !isMyVendorsTabSelected })).unwrap();

    if (resp?.status === API_STATUS.SUCCESS) {
      const profile = resp?.profile;

      if (profile && isProductionDomain()) setMixpanelIdentity(profile);

      if (window?.StonlyWidget && profile && isProduction()) {
        window.StonlyWidget('identify', profile.email);
      }
      if (window?.SessionStack && profile && isProductionDomain()) {
        window.SessionStack.identify({
          userId: profile.email,
          email: profile.email,
          displayName: profile.fullname ?? profile.email,
          orgId_str: profile.current_organization?.id || '',
          orgName_str: profile.current_organization?.label || '',
          userRole_str: Roles.getRoleName(profile.current_organization?.role),
          domain_str: window.location.hostname,
        });
      }
    }

    setLoading(false);
  }, [isMyVendorsTabSelected, organizationIndex]);

  useEffect(() => {
    getProfileData();
  }, [isMyVendorsTabSelected, organizationIndex]);

  return loading ? <AppPreloader /> : children;
};

LoggedUser.propTypes = {
  children: PropTypes.any,
};

export default LoggedUser;
