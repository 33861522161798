import isEmpty from 'lodash/isEmpty';
import FindingStatus from 'constants/findingStatus';
import RecommendationStatus from 'constants/recommendationStatus';
import { messages } from './messages';

export const getTextByStatusValue = (status) => {
  switch (status) {
    case FindingStatus.open:
      return messages.openStatus;
    case FindingStatus.closed:
      return messages.closedStatus;
    case FindingStatus.pending:
      return messages.pendingStatus;
    default:
      return '';
  }
};

export const getStatusFromRecommendations = (finding) => {
  if (!finding || isEmpty(finding)) return null;
  const recommendationStatuses = (finding.recommendations || []).map(({ status }) => status);

  if (!recommendationStatuses.length) {
    return RecommendationStatus.open;
  }

  if (recommendationStatuses.find((status) => status === RecommendationStatus.approved)) {
    return FindingStatus.pending;
  }

  if (recommendationStatuses.find((status) => status === RecommendationStatus.open) !== undefined) {
    return FindingStatus.open;
  }

  if (recommendationStatuses.find((status) => status === RecommendationStatus.reject)) {
    return FindingStatus.open;
  }

  return FindingStatus.closed;
};
