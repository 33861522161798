import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

import Button from 'ui/Button';
import SecondaryButton from 'ui/SecondaryButton';
import Icon from 'ui/Icon';
import Checkbox from 'ui/Checkbox';
import Loader from 'ui/Loader';
import { translate } from 'utils/index';
import { stripHtml } from 'utils/html';
import { calculateControlKey } from 'pages/Assessment/lib';
import Answer from 'pages/Editor/questions/answer';
import { findControlIndex } from 'pages/Editor/lib';
import { selectTemplate } from 'pages/Editor/selectors';
import { messages } from 'pages/Editor/messages';
import './triggerModal.scss';

const TriggersModal = ({ controls, loading, onCancel, onOk, subjectId, open }) => {
  const { categories = [] } = useSelector(selectTemplate);
  const [selectedControls, setSelectedControls] = useState([]);
  const subjectIndex = categories.findIndex(({ id }) => subjectId === id);

  useEffect(() => {
    setSelectedControls([]);
  }, [open]);

  const setSelectedControl = (id) => {
    if (selectedControls.some((selectedControlId) => id === selectedControlId)) {
      setSelectedControls(selectedControls.filter((selectedControlId) => id !== selectedControlId));
    } else {
      setSelectedControls([...selectedControls, id]);
    }
  };

  const toggleAllControls = () => {
    if (selectedControls.length === controls.length) {
      setSelectedControls([]);
    } else {
      const allControls = controls.map(({ id }) => id);
      setSelectedControls(allControls);
    }
  };

  const footer = [
    <Button
      key="footer_button_trigger_modal_1"
      className="editor-subject-list-subject-modal__footer-cancel-button"
      size="sm"
      color="gray"
      onClick={onCancel}
      link
    >
      {translate(messages.cancel)}
    </Button>,
    <SecondaryButton
      key="footer_button_trigger_modal_2"
      className="editor-subject-list-subject-modal__footer-ok-button"
      onClick={() => onOk(selectedControls)}
      size="medium"
      type="submit"
      outline
      loading={loading}
    >
      {translate(messages.moveControls)}
    </SecondaryButton>,
  ];

  return (
    <Modal
      className="editor-subject-list-subject-modal__modal"
      closeIcon={<Icon icon="close" />}
      footer={footer}
      onCancel={onCancel}
      width={900}
      open={open}
    >
      <div className="editor-subject-list-subject-modal__header">
        <h3 className="editor-subject-list-subject-modal__header-title">
          <Icon className="editor-subject-list-subject-modal__header-title-icon" icon="trigger" />{' '}
          {translate(messages.moveFollowUpControls)}
        </h3>
        <div className="editor-subject-list-subject-modal__header-description">
          {translate(messages.moveFollowUpControlsDescription)}
        </div>
      </div>
      <div className="editor-subject-list-subject-modal__list-wrapper">
        <Checkbox
          className="editor-subject-list-subject-modal__select-all-checkbox"
          onChange={() => {
            toggleAllControls();
          }}
          checked={selectedControls.length === controls.length}
          name="select-all-controls"
          mode="checkbox"
        >
          {translate(messages.selectAll)}
        </Checkbox>
        <div className="editor-subject-list-subject-modal__list">
          {controls.map((control) => (
            <div key={control.id} className="editor-subject-list-subject-modal__question">
              <Checkbox
                className="editor-subject-list-subject-modal__question-checkbox"
                onChange={() => {
                  setSelectedControl(control.id);
                }}
                checked={selectedControls.includes(control.id)}
                name={`question-${control.id}`}
                mode="checkbox"
              />
              <div className="editor-subject-list-subject-modal__question-wrapper">
                <div className="editor-subject-list-subject-modal__question-title">
                  <span className="editor-subject-list-subject-modal__question-key">
                    {calculateControlKey(
                      subjectIndex + 1,
                      findControlIndex(categories, subjectId, control.id),
                    )}
                  </span>
                  {stripHtml(control.title)}
                </div>
                <Answer control={control} disabled />
              </div>
            </div>
          ))}
        </div>
      </div>
      {loading && <Loader />}
    </Modal>
  );
};

TriggersModal.propTypes = {
  controls: PropTypes.array,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  subjectId: PropTypes.string,
  open: PropTypes.bool,
};

export default TriggersModal;
