export class Apps {
  static azure = '07cd2406-17fe-4ac7-92d0-84d8709b01bf';
  static aws = '9a80dd38-e614-4683-9d19-08e090a964bc';
  static riskRecon = '4ef1b34e-645f-488e-9f46-3fd7b65eb3eb';
  static qualys = '732cdc6c-5824-4237-8f8c-10d4cc595c22';
  static ssc = 'f4a59108-9edf-406d-a362-273f6d6c20ef';
  static ibm = 'f79bbe70-0486-435e-acce-393d0fdb1f33';
  static cloudhawk = '00726027-c9c8-4b52-9795-a84bfcb12f7a';
  static sbom = '09b75be4-d192-4e78-9f8a-b8ac8cadedbb';
  static cloudMonitoring = '56fa4be5-ec55-4804-a9e1-fc44880dec6b';
}
