import { isProductionDomain } from 'utils/index';
import { createStonlyConfig } from 'configs/createStonlyConfig';
import { createHotjarConfig } from 'configs/createHotjarConfig';
import { initializeMixpanel } from 'utils/mixpanel';
import { createDataDogConfig } from 'configs/createDataDogConfig';
import { ENV } from 'configs/env';

export const setThirdPartyConfigs = async () => {
  if (ENV.MIXPANEL_KEY) initializeMixpanel(); // initialize Mixpanel
  if (!isProductionDomain()) return;

  // third-party services for production
  await createHotjarConfig(); // initialize Hotjar
  if (ENV.DATADOG_APPLICATION_ID && ENV.DATADOG_CLIENT_TOKEN) await createDataDogConfig(); // initialize DataDog
  if (ENV.STONLY_ID) await createStonlyConfig(); // initialize Stonly
};
