import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import Icon from 'ui/Icon';
import { translate } from 'utils/index';
import { DATE_FORMAT } from 'constants/date';
import { messages } from './messages';
import './dueDate.scss';

const DueDate = ({ due }) => {
  const isBackAction = window.history.length > 2;

  return (
    <div
      className={classNames('assessment-due-date', {
        'assessment-due-date--without-back-action': !isBackAction,
      })}
    >
      <Icon className="assessment-due-date__icon" icon="date" />
      <span className="assessment-due-date__label">{translate(messages.dueBy)}</span>{' '}
      {moment(due).format(DATE_FORMAT)}
    </div>
  );
};

DueDate.propTypes = {
  due: PropTypes.string,
};

export default DueDate;
