export const messages = {
  companyDomain: {
    key: 'Continuous monitoring tab.companyDomain',
    defaultMessage: 'Company domain',
  },
  enterDomain: {
    key: 'Continuous monitoring tab.enterDomain',
    defaultMessage: 'Enter domain',
  },
  pleaseEnterCompanyDomain: {
    key: 'Continuous monitoring tab.pleaseEnterCompanyDomain',
    defaultMessage: 'Please enter company domain for scanning',
  },
  appsMonitoring: {
    key: 'Continuous monitoring tab.appsMonitoring',
    defaultMessage: 'Apps monitoring',
  },
  score: {
    key: 'Continuous monitoring tab.score',
    defaultMessage: 'Score',
  },
  rating: {
    key: 'Continuous monitoring tab.rating',
    defaultMessage: 'Rating',
  },
  findings: {
    key: 'Continuous monitoring tab.findings',
    defaultMessage: 'Findings',
  },
  grade: {
    key: 'Continuous monitoring tab.grade',
    defaultMessage: 'Grade',
  },
  domainrating: {
    key: 'Continuous monitoring tab.rating',
    defaultMessage: 'Rating',
  },
  criticalFindings: {
    key: 'Continuous monitoring tab.criticalFindings',
    defaultMessage: 'Critical findings',
  },
  freeContinuouslyUpdated: {
    key: 'Continuous monitoring tab.freeContinuouslyUpdated',
    defaultMessage: 'Free continuously updated',
  },
  update: {
    key: 'Continuous monitoring tab.update',
    defaultMessage: 'UPDATE',
  },
  credit: {
    key: 'Continuous monitoring tab.credit',
    defaultMessage: 'credit',
  },
  yourCredits: {
    key: 'Continuous monitoring tab.yourCredits',
    defaultMessage: 'Your credits',
  },
  domain: {
    key: 'Continuous monitoring tab.domain',
    defaultMessage: 'Domain',
  },
  viewDetails: {
    key: 'Continuous monitoring tab.viewDetails',
    defaultMessage: 'VIEW DETAILS',
  },
  getMore: {
    key: 'Continuous monitoring tab.getMore',
    defaultMessage: 'GET MORE',
  },
  domainBreakdown: {
    key: 'Continuous monitoring tab.domainBreakdown',
    defaultMessage: 'Domain breakdown',
  },
  lastUpdate: {
    key: 'Continuous monitoring tab.lastUpdate',
    defaultMessage: 'last update',
  },
  domainBreakdownDescription: {
    key: 'Continuous monitoring tab.domainBreakdownDescription',
    defaultMessage: 'get a per-category rating score and critical findings count',
  },
  credits: {
    key: 'Continuous monitoring tab.credits',
    defaultMessage: 'credits',
  },

  viewByCategory: {
    key: 'Continuous monitoring tab.viewByCategory',
    defaultMessage: 'VIEW BY CATEGORY',
  },
  fullFindingsAndDetailedReport: {
    key: 'Continuous monitoring tab.fullFindingsAndDetailedReport',
    defaultMessage: 'Full findings and detailed report',
  },
  fullFindingsAndDetailedReportDescription: {
    key: 'Continuous monitoring tab.fullFindingsAndDetailedReportDescription',
    defaultMessage: 'get the full critical findings information and a detailed report',
  },
  getReportAndFindings: {
    key: 'Continuous monitoring tab.getReportAndFindings',
    defaultMessage: 'GET REPORT & FINDINGS',
  },
  purchasedLastDate: {
    key: 'Continuous monitoring tab.purchasedLastDate',
    defaultMessage: 'Purchased last on',
  },
  neverPurchased: {
    key: 'Continuous monitoring tab.neverPurchased',
    defaultMessage: 'Never',
  },
  viewMore: {
    key: 'Continuous monitoring tab.viewMore',
    defaultMessage: 'VIEW MORE',
  },
  downloadReport: {
    key: 'Continuous monitoring tab.downloadReport',
    defaultMessage: 'DOWNLOAD REPORT',
  },
  inProgressItMightTake: {
    key: 'Continuous monitoring tab.inProgressItMightTake',
    defaultMessage: 'In progress, it might take up to 48 hours',
  },
  noScanResultForDomain: {
    key: 'Continuous monitoring tab.noScanResultForDomain',
    defaultMessage: 'No scan result for domain',
  },
  saveAndClose: {
    key: 'Continuous monitoring tab.saveAndClose',
    defaultMessage: 'Save and close',
  },
  run: {
    key: 'Continuous monitoring tab.run',
    defaultMessage: 'Run',
  },
  qualysControlsTitle: {
    key: 'Continuous monitoring tab.qualysControlsTitle',
    defaultMessage: 'All results',
  },
  riskReconCreditBundle: {
    key: 'Continuous monitoring tab.riskReconCreditBundle',
    defaultMessage: 'Riskrecon credits bundle',
  },
  riskReconScanPurchased: {
    key: 'Continuous monitoring tab.riskReconScanPurchased',
    defaultMessage: 'Full Riskrecon scan purchased!',
  },
  riskrecon: {
    key: 'Continuous monitoring tab.riskrecon',
    defaultMessage: 'Riskrecon',
  },
  contactMe: {
    key: 'Continuous monitoring tab.contactMe',
    defaultMessage: 'CONTACT ME',
  },
  noThanks: {
    key: 'Continuous monitoring tab.noThanks',
    defaultMessage: 'No THANKS',
  },
  gotIt: {
    key: 'Continuous monitoring tab.gotIt',
    defaultMessage: 'GOT IT',
  },
  youHave: {
    key: 'Continuous monitoring tab.youHave',
    defaultMessage: 'You have',
  },
  creditsLeft: {
    key: 'Continuous monitoring tab.creditsLeft',
    defaultMessage: 'credits left',
  },
  inactive: {
    key: 'Continuous monitoring tab.inactive',
    defaultMessage: 'Inactive',
  },
  inProgress: {
    key: 'Continuous monitoring tab.inProgress',
    defaultMessage: 'In progress',
  },
  active: {
    key: 'Continuous monitoring tab.active',
    defaultMessage: 'Active',
  },
  noResults: {
    key: 'Continuous monitoring tab.noResults',
    defaultMessage: 'No results',
  },
  riskReconScanPurchaseText: {
    key: 'Continuous monitoring tab.riskReconScanPurchaseText',
    defaultMessage:
      "Your full scan is on its way, we'll notify you once its ready (this could take up to 48 hours).",
  },
  riskReconCreditBundleText: {
    key: 'Continuous monitoring tab.riskReconCreditBundleText',
    defaultMessage:
      'To view full Riskrecon scans you need to purchase a credit bundle. To learn more, please click on the "contact me" button and we\'ll get back to you.',
  },
  scanVendor: {
    key: 'Continuous monitoring tab.scanVendor',
    defaultMessage: 'SCAN VENDOR',
  },
  notUpToDate: {
    key: 'Continuous monitoring tab.notUpToDate',
    defaultMessage: 'This information might not be up to date',
  },
  viewAnyway: {
    key: 'Continuous monitoring tab.viewAnyway',
    defaultMessage: 'View anyways',
  },
  detailedReport: {
    key: 'Continuous monitoring tab.detailedReport',
    defaultMessage: 'Detailed report',
  },
  viewFindings: {
    key: 'Continuous monitoring tab.viewFindings',
    defaultMessage: 'View findings',
  },
  creditsRequest: {
    key: 'Continuous monitoring tab.creditsRequest',
    defaultMessage: 'Riskrecon credits request',
  },
  creditRequestText: {
    key: 'Continuous monitoring tab.creditRequestText',
    defaultMessage: 'Credit request accepted. Our team will contact you shortly',
  },
  creditsBundle: {
    key: 'Continuous monitoring tab.creditsBundle',
    defaultMessage: 'Riskrecon credits bundle',
  },
  creditsBundleText: {
    key: 'Continuous monitoring tab.creditsBundleText',
    defaultMessage:
      'To view the full Riskrecon scans you need to purchase a credit bundle. To learn more please click on the "contact me" button, and we\'ll get back to you.',
  },
};

export default {
  prefix: 'Continuous monitoring tab',
  messages,
};
