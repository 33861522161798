import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import Input from 'ui/Input';
import { setPagination, setSearch } from '../reducers';
import { getProfileVendors } from 'api/vendor';
import { translate } from 'utils/index';
import { generalMessages } from 'constants/messages';

const Search = () => {
  const dispatch = useDispatch();
  const searchValue = useSelector((state) => state.vendors.search);

  const searchDebounced = useCallback(
    debounce(() => {
      dispatch(setPagination({ current: 1 }));
      dispatch(getProfileVendors()).unwrap();
    }, 500),
    [],
  );

  const search = (event) => {
    dispatch(setSearch(event.target.value));
    searchDebounced();
  };

  return (
    <Input.Search
      onChange={search}
      size="medium"
      value={searchValue}
      placeholder={translate(generalMessages.search)}
    />
  );
};

export default Search;
