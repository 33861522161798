import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import PageHeader from 'components/PageHeader';
import Card from 'ui/Card';
import ActionsBar from 'pages/Findings/actionsBar';
import FindingsList from 'pages/Findings/findingsList';
import StrengthenAuth from 'components/StrengthenAuth';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Findings/messages';

// REDUX
import { getProfileFindings } from 'api/finding';

// STYLES
import './index.scss';
import { FILTERABLE_KEYS } from 'components/Filters/lib';

const Findings = () => {
  const dispatch = useDispatch();

  const { filtersToApi } = useSelector(
    (state) => state.filters.allFilters[FILTERABLE_KEYS.FINDINGS_LIST_FILTERS],
  );

  useEffect(() => {
    dispatch(getProfileFindings());
  }, [filtersToApi]);

  return (
    <div className="findings-page">
      <PageHeader className="findings-page__header">{translate(messages.pageTitle)}</PageHeader>
      <Card>
        <ActionsBar />
        <FindingsList />
      </Card>
      <StrengthenAuth request={() => dispatch(getProfileFindings({}))} />
    </div>
  );
};

export default Findings;
