import { getTrustedPartnersRequest } from 'api/organizations';
import { StatusType } from 'constants/statusType';
import { parseResponseToTrustedOrg } from 'pages/Organization/edit/trustedOrganizationsUtils/parseResponseToTrustedOrg';
import { useEffect, useState } from 'react';

export function useTrustedPartners(org_id, refetch) {
  const [response, setResponse] = useState({ organizations: [], status: StatusType.IDLE });

  useEffect(async () => {
    setResponse((prev) => ({ ...prev, status: StatusType.PENDING }));

    try {
      const res = await getTrustedPartnersRequest(org_id);

      setResponse({
        organizations: parseResponseToTrustedOrg(res),
        status: StatusType.RESOLVED,
      });
    } catch {
      setResponse((prev) => ({ ...prev, status: StatusType.REJECTED }));
    }
  }, [org_id, refetch]);

  return { organizations: response.organizations, status: response.status };
}
