import { ControlType } from 'constants/controlType';

export const buttonTypes = {
  // UpstreamAI
  explain: 'explain',
  example: 'example',
  improve: 'improve',
  // DownstreamAI
  audit: 'audit',
};

export const titleStructures = {
  explainOnly: 'explainOnly',
  exampleOnly: 'exampleOnly',
  auditOnly: 'auditOnly',
  explainAndExample: 'explainAndExample',
  improveAndExample: 'improveAndExample',
};

export const getTitleStructure = ({ controlAnswerType, sourceButton }) => {
  switch (controlAnswerType) {
    case ControlType.TextArea:
    case ControlType.Input:
      if (sourceButton === buttonTypes.explain) {
        return titleStructures.explainAndExample;
      }
      if (sourceButton === buttonTypes.example) {
        return titleStructures.exampleOnly;
      }
      if (sourceButton === buttonTypes.improve) {
        return titleStructures.improveAndExample;
      }
      if (sourceButton === buttonTypes.audit) {
        return titleStructures.auditOnly;
      }
      break;
    default: {
      if (sourceButton === buttonTypes.explain) {
        return titleStructures.explainOnly;
      }
    }
  }
};

// Downstream AI
export const setDueDeltaToRecommendations = (recommendations) => {
  if (!recommendations) return;
  const dueDelta = 14;
  return recommendations.map((recommendation) => ({
    ...recommendation,
    'due_delta': dueDelta,
  }));
};
