import { useContext } from 'react';
import { NavCollapsedContext } from 'context/navCollapsedContext';

export function useNavCollapsedContext() {
  const context = useContext(NavCollapsedContext);
  if (!context) {
    throw new Error('NavCollapsedContext not defined');
  }
  return context;
}
