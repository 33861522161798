import React from 'react';
import { Button, Layout } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ROUTES from 'constants/routes';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import PrivateElement from 'components/PrivateElement';
import LoggedUser from 'components/LoggedUser';
import Messages from 'components/Messenger';
import logo from 'assets/images/logo.svg';
import findings from 'assets/images/findings.svg';
import { useNavCollapsedContext } from 'hooks/useNavCollapsedContext';
import PendingTrustedUsersModal from 'components/BaseLayout/pendingTrustedUsersModal';

import Menu from './menu';
import Placeholder from './placeholder';
import MyVendorsMyCustomersSwitcher from './myVendorsMyCustomersSwitcher';
import AddButton from './addButton';
import ProfileMenu from './profileMenu';
import InviteTeamMemberButton from 'components/BaseLayout/inviteTeamMemberButton';
import { useRole } from 'hooks/useRole';
import { DEV_MODE_LS_KEY } from 'utils/index';
import { selectProfile } from 'pages/Profile/selectors';
import './index.scss';

const { Content, Header, Sider } = Layout;

const BaseLayout = ({ children, withPlaceholder }) => {
  const { collapsed, handleCollapsed } = useNavCollapsedContext();
  const {
    user: { email },
  } = useSelector(selectProfile);

  const { isBoSoRole, isRestrictedBoSoViewerAuditorRole, isAuditorRole } = useRole();
  const appNavigate = useAppNavigate();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const navigateToRecent = () =>
    appNavigate(isMyVendorsTabSelected ? ROUTES.VENDOR_RECENT : ROUTES.CUSTOMER_RECENT);

  const handleToggleDevMode = () => {
    const devMode = localStorage.getItem(DEV_MODE_LS_KEY);
    if (devMode) {
      localStorage.removeItem(DEV_MODE_LS_KEY);
    } else {
      localStorage.setItem(DEV_MODE_LS_KEY, 'true');
    }
    window.location.reload();
  };
  const isFindingsUser = email.includes('@findings.co');

  return (
    <PrivateElement>
      <LoggedUser>
        <Layout className={classNames('base-layout', { 'menu-collapsed': collapsed })}>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={handleCollapsed}
            className="base-layout__sider"
            theme={isMyVendorsTabSelected ? 'white' : 'dark'}
          >
            <Button type="link" className="base-layout__logo" onClick={navigateToRecent}>
              <img className="base-layout__logo-img" src={logo} alt="Findings" />
            </Button>
            <Button type="link" className="base-layout__logo-findings" onClick={navigateToRecent}>
              <img className="base-layout__logo-findings-img" src={findings} alt="Findings" />
            </Button>
            <hr className="base-layout__border" />
            <Menu />
            {isFindingsUser && (
              <button
                className={`base-layout__dev-mode-button ${
                  localStorage.getItem(DEV_MODE_LS_KEY) ? 'active' : ''
                }`}
                onClick={handleToggleDevMode}
              >
                information
              </button>
            )}
          </Sider>
          <Layout className="base-layout__layout">
            <Header className="base-layout__header">
              <MyVendorsMyCustomersSwitcher />
              <div className="base-layout__header-aside">
                {isRestrictedBoSoViewerAuditorRole ? null : <InviteTeamMemberButton />}
                <Messages />
                {((isMyVendorsTabSelected && !isAuditorRole) || isBoSoRole) && <AddButton />}
                <ProfileMenu />
              </div>
            </Header>
            <Content className="base-layout__content">
              {withPlaceholder ? <Placeholder>{children}</Placeholder> : children}
            </Content>
            <PendingTrustedUsersModal />
          </Layout>
        </Layout>
      </LoggedUser>
    </PrivateElement>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node,
  withPlaceholder: PropTypes.bool,
};

export default BaseLayout;
