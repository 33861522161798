export const messages = {
  dashboardPageTitle: {
    key: 'dashboard.dashboardPageTitle',
    defaultMessage: 'Dashboard',
  },

  dashboardChartNoData: {
    key: 'dashboard.dashboardChartNoData',
    defaultMessage: 'No results found ',
  },

  download: {
    key: 'dashboard.download',
    defaultMessage: 'DOWNLOAD',
  },

  // general:
  high: {
    key: 'dashboard.high',
    defaultMessage: 'High',
  },
  medium: {
    key: 'dashboard.medium',
    defaultMessage: 'Medium',
  },
  low: {
    key: 'dashboard.low',
    defaultMessage: 'Low',
  },

  // charts:
  // scoreCircularChart
  scoreCircularChartHigh: {
    key: 'dashboard.scoreCircularChartHigh',
    defaultMessage: 'High',
  },
  scoreCircularChartMedium: {
    key: 'dashboard.scoreCircularChartMedium',
    defaultMessage: 'MEDIUM',
  },
  scoreCircularChartLow: {
    key: 'dashboard.scoreCircularChartLow',
    defaultMessage: 'LOW',
  },
  scoreCircularChartEmpty: {
    key: 'dashboard.scoreCircularChartEmpty',
    defaultMessage: '(no data)',
  },

  // lineChart

  // totalSummary
  TotalSummaryOfTotal: {
    key: 'dashboard.TotalSummaryOfTotal',
    defaultMessage: 'of',
  },
};

export default {
  prefix: 'dashboard',
  messages,
};
