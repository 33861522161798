import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

import Form from 'ui/Form';
import Input from 'ui/Input';
import { newVendorDetails } from 'pages/SoBoPages/schema';
import { translate } from 'utils/index';
import { messages } from 'pages/SoBoPages/messages';
import { selectSoBoPages } from 'pages/SoBoPages/selectors';

const DetailsForm = ({ onSubmit, children }) => {
  const { payload: state } = useSelector(selectSoBoPages);

  const {
    values: { fullname, email, company },
    handleChange,
    handleSubmit,
    errors,
  } = useFormik({
    validateOnChange: false,

    initialValues: {
      fullname: state.fullname,
      email: state.email,
      company: state.company,
    },

    validationSchema: newVendorDetails(
      translate(messages.detailsFormFullnameInvalid),
      translate(messages.detailsFormFullnameError),
      translate(messages.detailsFormFullnameRequired),
      translate(messages.detailsFormEmailError),
      translate(messages.detailsFormEmailRequired),
      translate(messages.detailsFormCompanyRequired),
    ),

    onSubmit: ({ fullname: fullnameValue, email: emailValue, company: companyValue }) => {
      onSubmit(fullnameValue, emailValue, companyValue);
    },
  });

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Item>
          <Input
            data-test={'details-form-input-fullname'}
            name="fullname"
            value={fullname}
            label={translate(messages.detailsFormFullnameLabel)}
            placeholder={translate(messages.detailsFormFullnamePlaceholder)}
            onChange={handleChange}
            error={errors.fullname}
          />
        </Form.Item>
        <Form.Item>
          <Input
            data-test={'details-form-input-email'}
            name="email"
            value={email}
            label={translate(messages.detailsFormEmailLabel)}
            placeholder={translate(messages.detailsFormEmailPlaceholder)}
            onChange={handleChange}
            error={errors.email}
          />
        </Form.Item>
        <Form.Item>
          <Input
            data-test={'details-form-input-company'}
            name="company"
            value={company}
            label={translate(messages.detailsFormCompanyLabel)}
            placeholder={translate(messages.detailsFormCompanyPlaceholder)}
            onChange={handleChange}
            error={errors.company}
          />
        </Form.Item>
        {children}
      </Form>
    </div>
  );
};

DetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.any,
};

export default DetailsForm;
