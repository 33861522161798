import React from 'react';
import PropTypes from 'prop-types';
import { ControlType } from 'constants/controlType';
import { translate } from 'utils/index';
import { messages } from 'pages/Editor/messages';
import AnswerTypeButton from './answerTypeButton';
import './answerTypeMenu.scss';

const AnswerTypeMenu = ({ groupCounts, selected, setSelectedType }) => {
  return (
    <div className="editor-answer-bank-answer-type-menu">
      <AnswerTypeButton
        onClick={() => setSelectedType(null)}
        selected={!selected}
        text={translate(messages.answerTypeAll)}
      />
      <AnswerTypeButton
        count={groupCounts[0]}
        onClick={() => setSelectedType(ControlType.RadioGroup)}
        icon="radio-button"
        selected={selected === ControlType.RadioGroup}
        text={translate(messages.answerTypeRadioButton)}
      />
      <AnswerTypeButton
        count={groupCounts[1]}
        onClick={() => setSelectedType(ControlType.Checkbox)}
        icon="multi-select"
        selected={selected === ControlType.Checkbox}
        text={translate(messages.answerTypeCheckbox)}
      />
      <AnswerTypeButton
        count={groupCounts[2]}
        onClick={() => setSelectedType(ControlType.DropDown)}
        icon="drop-down"
        selected={selected === ControlType.DropDown}
        text={translate(messages.answerTypeDropDown)}
      />
      <AnswerTypeButton
        count={groupCounts[3]}
        onClick={() => setSelectedType(ControlType.MultiSelect)}
        icon="drop-down"
        selected={selected === ControlType.MultiSelect}
        text={translate(messages.answerTypeMultiSelect)}
      />
    </div>
  );
};

AnswerTypeMenu.propTypes = {
  groupCounts: PropTypes.array,
  selected: PropTypes.number,
  setSelectedType: PropTypes.func,
};

export default AnswerTypeMenu;
