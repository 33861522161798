export const messages = {
  groupOfAssessments: {
    key: 'assessments.groupOfAssessments',
    defaultMessage: 'Group of assessments',
  },
  assessmentRequired: {
    key: 'assessments.assessmentRequired',
    defaultMessage: 'Assessments required',
  },
  youCanSelectMoreThanOne: {
    key: 'assessments.youCanSelectMoreThanOne',
    defaultMessage: 'You can select more than one',
  },
  saveAndClose: {
    key: 'assessments.saveAndClose',
    defaultMessage: 'Save and close',
  },
  createVendors: {
    key: 'assessments.createVendors',
    defaultMessage: 'Create vendors',
  },
  monthly: {
    key: 'assessments.monthly',
    defaultMessage: 'Monthly',
  },
  never: {
    key: 'assessments.never',
    defaultMessage: 'Never',
  },
  quarterly: {
    key: 'assessments.quarterly',
    defaultMessage: 'Quarterly',
  },
  semiAnnually: {
    key: 'assessments.semiAnnually',
    defaultMessage: 'Semi-annually',
  },
  annually: {
    key: 'assessments.annually',
    defaultMessage: 'Annually',
  },
  autoAssessmentPeriod: {
    key: 'assessments.autoAssessmentPeriod',
    defaultMessage: 'Auto assessment period',
  },
  untitledAssessment: {
    key: 'assessments.untitledAssessment',
    defaultMessage: 'Untitled Assessment',
  },
  assignAssessment: {
    key: 'assessments.assignAssessment',
    defaultMessage: 'Assign assessment',
  },
  pleaseSelect: {
    key: 'assessments.pleaseSelect',
    defaultMessage:
      'Please select from the following list, you may select more than one assessment.',
  },
};

export default {
  prefix: 'assessments',
  messages,
};
