import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import NewVendorFooter from 'components/NewVendorFooter';
import { ReactComponent as ArrowIconLeft } from 'assets/svgs/arrowLeft.svg';
import { translate } from 'utils/index';
import { generalMessages } from 'constants/messages';
import { messages } from './messages';
import ControlComponent from 'components/ControlComponent';
import { ControlType } from 'constants/controlType';
import './index.scss';
import { useSelector } from 'react-redux';

const Assessments = ({ handleBackTab, onSubmit, isLoading, isNextDisabled }) => {
  const [checkedAssessments, setCheckedAssessments] = useState([]);
  const [checkedAssessmentPeriod, setCheckedAssessmentPeriod] = useState(null);

  const { assessments } = useSelector(({ assessmentsTemplates }) => {
    return {
      assessments: assessmentsTemplates.templates,
    };
  });

  const assessmentsPeriods = useMemo(
    () => [
      { label: translate(messages.never), value: 0 },
      { label: translate(messages.monthly), value: 1 },
      { label: translate(messages.quarterly), value: 2 },
      { label: translate(messages.semiAnnually), value: 3 },
      { label: translate(messages.annually), value: 4 },
    ],
    [],
  );

  return (
    <div className="assessments">
      <div className="assessments__header">
        <h3 className="assessments__header-title">{translate(messages.assignAssessment)}</h3>
        <p>{translate(messages.pleaseSelect)}</p>
      </div>

      <div className="assessments__assign-list">
        {assessments.length > 0 && (
          <ControlComponent
            type={ControlType.Checkbox}
            isSimple
            data={{
              payload: {
                mode: 'none',
                options: assessments.map((assessment) => ({
                  ...assessment,
                  label: assessment.name || translate(messages.untitledAssessment),
                  value: assessment.id,
                })),
                onChange: (value) => {
                  setCheckedAssessments(value);
                },
                name: 'assessments',
                value: checkedAssessments,
              },
            }}
          />
        )}
      </div>

      <div className="assessments__auto-period">
        <ControlComponent
          type={ControlType.DropDown}
          isSimple
          data={{
            label: translate(messages.autoAssessmentPeriod),
            payload: {
              options: assessmentsPeriods,
              onChange: (value) => {
                setCheckedAssessmentPeriod(value);
              },
              name: 'assessment-periods',
              value: checkedAssessmentPeriod,
              'data-test': 'assessment-auto-periods',
            },
          }}
        />
      </div>
      <NewVendorFooter
        prevBeforeComponent={<ArrowIconLeft width={13} height={14} />}
        nextAction={() => {
          onSubmit(checkedAssessments, checkedAssessmentPeriod);
        }}
        isNextDisabled={isNextDisabled}
        prevAction={handleBackTab}
        nextLabel={translate(messages.createVendors)}
        prevLabel={translate(generalMessages.back)}
        isNextLoading={isLoading}
        dataTestPrev="assessments__back-button"
        dataTestNext="assessments__save-button"
      />
    </div>
  );
};

Assessments.propTypes = {
  handleBackTab: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
};
export default Assessments;
