import React, { useCallback } from 'react';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import Button from 'ui/Button';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'components/Messenger/messages';

// API
import { getUnreadChatThreadsNotifications, markAllAsRead } from 'api/messenger';
import { API_STATUS } from 'constants/api';

const ReadAll = ({ fetchData }) => {
  const dispatch = useDispatch();

  const handleReadAll = useCallback(async () => {
    const status = await markAllAsRead();

    if (status === API_STATUS.SUCCESS) {
      if (fetchData) fetchData();
      return dispatch(getUnreadChatThreadsNotifications({}));
    }
    return notification.error({ message: translate(messages.errorMarkAllAsRead) });
  }, [fetchData]);

  return (
    <Button
      data-test="messenger-notifications-list-button-read-all"
      className="messenger-notifications-list__header-read-all"
      link
      color="blue"
      name={translate(messages.readAll)}
      onClick={handleReadAll}
      size="xs"
    />
  );
};

ReadAll.propTypes = {
  fetchData: PropTypes.func,
};

export default ReadAll;
