import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useEffect, useState } from 'react';
import { ScreenSize } from 'constants/screenSize';

export const useTheme = () => {
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const [screenSize, setScreenSize] = useState(ScreenSize.desktop);

  window.onresize = () => {
    if (window.innerWidth < 1280) {
      setScreenSize(ScreenSize.mobile);
    } else if (window.innerWidth < 1480) {
      setScreenSize(ScreenSize.desktop);
    } else if (window.innerWidth < 1920) {
      setScreenSize(ScreenSize.desktopMedium);
    } else {
      setScreenSize(ScreenSize.desktopLarge);
    }
  };

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth < 1280) {
        setScreenSize(ScreenSize.mobile);
      } else if (window.innerWidth < 1480) {
        setScreenSize(ScreenSize.desktop);
      } else if (window.innerWidth < 1920) {
        setScreenSize(ScreenSize.desktopMedium);
      } else {
        setScreenSize(ScreenSize.desktopLarge);
      }
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return {
    colorTheme: isMyVendorsTabSelected ? 'pink' : 'green',
    screenSize,
  };
};
