import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Input from 'ui/Input';
import { translate } from 'utils/index';
import { messages } from 'pages/Editor/messages';
import { saveTemplate } from 'api/editor';
import { selectTemplate } from 'pages/Editor/selectors';
import Loader from 'ui/Loader';
import { useRole } from 'hooks/useRole';

const TemplateHeader = () => {
  const template = useSelector(selectTemplate);
  const dispatch = useDispatch();
  const [header, setHeader] = useState({
    title: '',
    description: '',
    load: false,
  });
  const { isRestrictedBoSoViewerRole } = useRole();

  useEffect(() => {
    setHeader({ title: template.title, description: template.description });
  }, [template.title, template.description]);

  const onChangeInputTitle = ({ target: { value } }) => {
    setHeader((state) => ({ ...state, title: value }));
  };

  const onChangeInputDescription = ({ target: { value } }) => {
    setHeader((state) => ({ ...state, description: value }));
  };

  const onBlur = async ({ target: { value } }, key) => {
    if (template[key] === value) return;

    setHeader((state) => ({ ...state, load: true }));
    await dispatch(saveTemplate({ title: header.title, description: header.description }));
    setHeader((state) => ({ ...state, load: false }));
  };

  return (
    <div className="editor__template-header">
      <div className="editor__template-header-description">{translate(messages.mainText)}</div>
      <div className="editor__template-header-title">
        {header.load && <Loader className="editor__template-header-loader" />}
        <Input
          value={header.title || ''}
          placeholder={translate(messages.assessmentTitle)}
          data-test="template-header-input-title"
          inputClassName="editor__template-header-title-input"
          onChange={onChangeInputTitle}
          onBlur={(event) => onBlur(event, 'title')}
          disabled={isRestrictedBoSoViewerRole}
          style={{ direction: template?.rtl ? 'rtl' : 'ltr' }}
        />
        <Input
          inline
          value={header.description || ''}
          placeholder={translate(messages.assessmentDescription)}
          data-test="template-header-input-description"
          inputClassName="editor__template-header-description-input"
          onChange={onChangeInputDescription}
          onBlur={(event) => onBlur(event, 'description')}
          disabled={isRestrictedBoSoViewerRole}
          style={{ direction: template?.rtl ? 'rtl' : 'ltr' }}
        />
      </div>
    </div>
  );
};

export default TemplateHeader;
