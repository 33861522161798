import React from 'react';
import PropTypes from 'prop-types';

const ChevronIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M8.06866 9L6.56866 10.5L12.639 16.5703L18.7093 10.5L17.2093 9L12.639 13.5703L8.06866 9Z"
      fill={color}
    />
  </svg>
);

ChevronIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ChevronIcon;
