import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { resetPassword } from 'api/authentication';
import { generalMessages } from 'constants/messages';
import { API_STATUS } from 'constants/api';
import CardWithLogo from 'components/CardWithLogo';
import Input from 'ui/Input';
import Button from 'ui/Button';
import Form from 'ui/Form';
import { translate } from 'utils/index';

import { messages } from 'pages/Login/messages';
import { loginEmailSchema } from 'pages/Login/schema';
import { LOGIN_FORM_MODE } from 'pages/Login/lib';

import './forgotPasswordForm.scss';

const ForgotPasswordForm = ({ setMode }) => {
  const [email, setEmail] = useState({ value: '', error: '' });
  const [loading, setLoading] = useState(false);
  const { value, error } = email;

  const onChange = ({ target: { value: inputValue } }) => {
    setEmail({ ...email, value: inputValue });
  };

  const onClickBack = () => {
    setMode(LOGIN_FORM_MODE.LOGIN);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await loginEmailSchema(translate(messages.emailErrorFormat)).validate({ email: value });
      const { status } = await resetPassword(value);
      setLoading(false);
      if (status === API_STATUS.SUCCESS) {
        setMode(LOGIN_FORM_MODE.FORGOT_PASSWORD_SUCCESS);
      }
    } catch ({ errors }) {
      setLoading(false);
      setEmail({ ...email, error: errors[0] });
    }
  };

  return (
    <div className="forgot-password-form">
      <CardWithLogo.Header
        title={translate(messages.forgotPasswordTitle)}
        subtitle={translate(messages.forgotPasswordSubtitle)}
        backButtonOnClick={onClickBack}
      />
      <Form onSubmit={onSubmit} className="forgot-password-form__form">
        <Form.Item>
          <Input.Email
            label={translate(messages.emailInputLabel)}
            value={value}
            onChange={onChange}
            error={error}
            onBlur={onSubmit}
            data-test="forgot-password-form-email-input"
            data-test-error="forgot-password-form-email-error"
            block
          />
        </Form.Item>
        <Form.Item>
          <Button
            centered
            loading={loading}
            name={translate(generalMessages.send)}
            color="pink"
            onClick={onSubmit}
            data-test="forgot-password-form-submit-button"
          />
        </Form.Item>
      </Form>
    </div>
  );
};

ForgotPasswordForm.propTypes = {
  setMode: PropTypes.func,
};

export default ForgotPasswordForm;
