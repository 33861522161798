import React from 'react';
import Text from 'ui/Text';
import Button from 'ui/Button';
import { ReactComponent as NotFoundIcon } from 'assets/svgs/not-found.svg';
import { translate } from 'utils/index';
import ROUTES from 'constants/routes';
import { messages } from './messages';

import './index.scss';

const NotFound = () => {
  const onClick = () => {
    window?.location.replace(ROUTES.HOME);
  };

  return (
    <section className="not-found">
      <div className="not-found__container">
        <NotFoundIcon />
        <div className="not-found__content">
          <div className="not-found__404">404</div>
          <Text color="text" level={4} text={translate(messages.description)} />
          <Button onClick={onClick} color="pink" size="sm" data-test="not-found-back-home-button">
            {translate(messages.buttonText)}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
