export const messages = {
  title: {
    key: 'ContinuousMonitoringPage.title',
    defaultMessage: 'Findings',
  },
  sentToVendor: {
    key: 'ContinuousMonitoringPage.sentToVendor',
    defaultMessage: 'Sent',
  },
  notSentToVendor: {
    key: 'ContinuousMonitoringPage.notSentToVendor',
    defaultMessage: 'SEND TO VENDOR',
  },
  lastSentToVendorTooltipSentOn: {
    key: 'ContinuousMonitoringPage.lastSentToVendorTooltipSentOn',
    defaultMessage: 'Sent On',
  },
  lastSentToVendorTooltipResend: {
    key: 'ContinuousMonitoringPage.lastSentToVendorTooltipResend',
    defaultMessage: 'Resend invitation',
  },
  lastSentToVendorSuccess: {
    key: 'ContinuousMonitoringPage.lastSentToVendorSuccess',
    defaultMessage: 'Successfully sent to vendor',
  },
  lastSentToVendorError: {
    key: 'ContinuousMonitoringPage.lastSentToVendorError',
    defaultMessage: 'Failed to send to vendor',
  },
};

export default {
  prefix: 'ContinuousMonitoringPage',
  messages,
};
