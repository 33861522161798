import React from 'react';

import { translate } from 'utils/index';
import OrganizationLogo from 'ui/OrganizationLogo';
import OrganizationIco from 'components/BaseLayout/organizationIco';
import { Sorter } from 'ui/Table/sorter';

import { messages } from 'pages/Organization/messages';
import { renderStatus } from './renderStatus';
import { renderActionsRowForTable } from './renderActionsRowForTable';

export const columns = (refetchOrg) => {
  return [
    {
      title: translate(messages.orgEditTrustedOrganizationsOrganization),
      key: 'name',
      dataIndex: 'name',
      render: (name, { corporateLogo }) => {
        return (
          <div className="trusted-organizations-tab__organization">
            {corporateLogo ? (
              <OrganizationLogo
                circle
                path={corporateLogo}
                alt={name}
                className="trusted-organizations-tab__organization-icon"
              />
            ) : (
              <OrganizationIco className="trusted-organizations-tab__icon" />
            )}
            {name}
          </div>
        );
      },
      sorter: {
        compare: Sorter.STRING,
      },
    },
    {
      title: translate(messages.orgEditTrustedOrganizationsEmail),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: translate(messages.orgEditTrustedOrganizationsDate),
      key: 'date',
      dataIndex: 'date',
    },
    {
      title: translate(messages.orgEditTrustedOrganizationsStatus),
      key: 'status',
      dataIndex: 'status',
      render: (status) => {
        return renderStatus(status);
      },
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      render: (_, { status, email, key }) =>
        renderActionsRowForTable(refetchOrg, status, email, key),
    },
  ];
};
