import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from 'ui/Input';
import './checkbox.scss';

const Checkbox = ({
  autoFocus,
  checked,
  className,
  color,
  defaultChecked,
  disabled,
  onChange,
  children,
  mode,
  name,
  value,
  error,
  ...restProps
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current.focus();
    }
  }, []);

  const checkboxClassName = classNames('ui-checkbox', className, {
    'ui-checkbox--mode-checkbox': mode === 'checkbox',
    'ui-checkbox--mode-label': mode !== 'checkbox',
    [`ui-checkbox--color-${color}`]: color,
    'ui-checkbox--checked': checked,
    'ui-checkbox--disabled': disabled,
  });

  return (
    <div className={checkboxClassName} data-test={restProps['data-test']}>
      <span className="ui-checkbox__input-wrapper">
        <Input
          value={value}
          id={name}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          onChange={onChange}
          inputRef={ref}
          type="checkbox"
          error={error}
          data-test="ui-checkbox-input"
        />
      </span>
      {children && (
        <label htmlFor={name} className="ui-checkbox__label">
          {' '}
          {children}
        </label>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  autoFocus: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf(['green', 'pink']),
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  mode: PropTypes.oneOf(['checkbox', 'label']),
  error: PropTypes.string,
  'data-test': PropTypes.string.isRequired,
};

export default Checkbox;
