import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { saveRecommendation } from 'api/recommendation';

const initialState = {
  recommendationLoading: false,
};

const reducers = createSlice({
  name: 'assessmentRecommendations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveRecommendation.pending, (state) => {
        state.recommendationLoading = true;
      })
      .addMatcher(isAnyOf(saveRecommendation.fulfilled, saveRecommendation.rejected), (state) => {
        state.recommendationLoading = false;
      });
  },
});

export default reducers;
