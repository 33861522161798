import React from 'react';
import PropTypes from 'prop-types';

const ErrorIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M12 2C6.48603 2 2 6.48604 2 12C2 17.514 6.48603 22 12 22C17.514 22 22 17.514 22 12C22 6.48604 17.514 2 12 2ZM12 3.5C16.7033 3.5 20.5 7.2967 20.5 12C20.5 16.7033 16.7033 20.5 12 20.5C7.29669 20.5 3.5 16.7033 3.5 12C3.5 7.2967 7.29669 3.5 12 3.5ZM15.2432 7.98926C15.0451 7.99394 14.8569 8.07679 14.7197 8.21973L12 10.9395L9.28027 8.21973C9.21036 8.14773 9.12672 8.0905 9.03429 8.05142C8.94187 8.01233 8.84254 7.99219 8.74219 7.99219C8.59293 7.99222 8.44707 8.03679 8.32328 8.12019C8.19949 8.20358 8.1034 8.32202 8.0473 8.46033C7.99119 8.59865 7.97763 8.75056 8.00835 8.89662C8.03907 9.04269 8.11267 9.17627 8.21973 9.28027L10.9395 12L8.21973 14.7197C8.14775 14.7888 8.09028 14.8716 8.0507 14.9632C8.01111 15.0548 7.9902 15.1534 7.98918 15.2532C7.98817 15.3529 8.00707 15.4519 8.04479 15.5443C8.08251 15.6367 8.13828 15.7206 8.20883 15.7912C8.27939 15.8617 8.36332 15.9175 8.4557 15.9552C8.54808 15.9929 8.64706 16.0118 8.74684 16.0108C8.84662 16.0098 8.9452 15.9889 9.03679 15.9493C9.12839 15.9097 9.21116 15.8523 9.28027 15.7803L12 13.0605L14.7197 15.7803C14.7888 15.8523 14.8716 15.9097 14.9632 15.9493C15.0548 15.9889 15.1534 16.0098 15.2532 16.0108C15.3529 16.0118 15.4519 15.9929 15.5443 15.9552C15.6367 15.9175 15.7206 15.8617 15.7912 15.7912C15.8617 15.7206 15.9175 15.6367 15.9552 15.5443C15.9929 15.4519 16.0118 15.3529 16.0108 15.2532C16.0098 15.1534 15.9889 15.0548 15.9493 14.9632C15.9097 14.8716 15.8523 14.7888 15.7803 14.7197L13.0605 12L15.7803 9.28027C15.8893 9.17559 15.9642 9.0404 15.9951 8.89242C16.026 8.74444 16.0115 8.59058 15.9534 8.451C15.8953 8.31143 15.7965 8.19266 15.6697 8.11026C15.543 8.02787 15.3943 7.98569 15.2432 7.98926Z"
      fill={color}
    />
  </svg>
);

ErrorIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ErrorIcon;
