/* eslint-disable jsx-a11y/anchor-is-valid */
import { notification } from 'antd';

import Loader from 'ui/Loader';
import { translate } from 'utils/index';
import { messages } from './messages';

import './indicateFileUploading.scss';

export function openFileUploadIndicator(onAbort) {
  notification.open({
    className: 'file-upload-indicator',
    duration: 0,
    closeIcon: null,
    placement: 'topRight',
    key: 'file-indicator',
    message: (
      <div className="file-upload-indicator__inner">
        <div className="file-upload-indicator__icon">
          <Loader />
        </div>
        <div className="file-upload-indicator__message">
          <h2>{translate(messages.indicatorTitle)}</h2>
          <p>
            {translate(messages.indicatorSubtitle)}{' '}
            <a
              role="button"
              href="#"
              data-test="abort-file-uploading"
              onClick={() => {
                onAbort();
                closeFileUploadIndicator();
              }}
            >
              {translate(messages.indicatorAbortionText)}
            </a>
          </p>
        </div>
      </div>
    ),
  });
}

export function closeFileUploadIndicator() {
  notification.close('file-indicator');
}
