/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Tooltip } from 'antd';

import { messages } from '../messages';
import { ChevronIcon, CloseCancelIcon, QuestionIcon } from 'components/Icons';
import { translate } from 'utils/index';
import Button from 'ui/Button';
import Filter from './filter/Index';
import { sanitizer } from 'utils/sanitizer';

import './index.scss';

const FiltersPopup = ({
  isOpen = false,
  onClose,
  optionalFilterItems = [],
  filters = {},
  filtersCount = 0,
  clearAllFilters,
  applyFilters,
  onChangeFilter,
  removeFilter,
  fetchFilterOptions,
  addQueryBlock,
  filterableKey,
}) => {
  useEffect(() => {
    let timeoutId = null;
    let dropdownTriggerElement = document.querySelector(
      '.filters-panel__add-filter-dropdown__trigger',
    );
    if (isOpen && filtersCount === 0) {
      timeoutId = setTimeout(() => {
        if (dropdownTriggerElement) {
          dropdownTriggerElement.click();
        }
      }, 300);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isOpen]);

  return (
    <div className="filters-panel">
      <div className="filters-panel__title">
        <h3>{translate(messages.popupTitle)}</h3>
        <Tooltip
          color="white"
          placement="bottomLeft"
          className="filters-panel__title__tooltip__container"
          overlayClassName="filters-panel__title__tooltip__overlay"
          title={
            <div className="filters-panel__title__tooltip">
              <h4>{translate(messages.filtersTitleTooltipTitle)}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(translate(messages.filtersTitleTooltipContent)),
                }}
              ></div>
            </div>
          }
        >
          <a href="#" role="button">
            <QuestionIcon />
          </a>
        </Tooltip>
        <a className="filters-panel__title__close-button" role="button" onClick={() => onClose()}>
          <CloseCancelIcon />
        </a>
      </div>

      <div className="filters-panel__content">
        {Object.keys(filters)?.length ? (
          Object.keys(filters).map((filterKey, index) => {
            const filter = filters[filterKey];
            return (
              <Filter
                filterableKey={filterableKey}
                containerIndex={index}
                key={filterKey}
                filter={filter}
                onChangeFilter={onChangeFilter}
                removeFilter={removeFilter}
                fetchFilterOptions={fetchFilterOptions}
                addQueryBlock={addQueryBlock}
              />
            );
          })
        ) : (
          <span className="filters-panel__no-filters">{translate(messages.emptyFilters)}</span>
        )}

        <Dropdown
          className="filters-panel__add-filter-dropdown"
          menu={{
            items: optionalFilterItems,
            className: 'filters-panel__add-filter-dropdown__menu',
          }}
          trigger="click"
        >
          <Button
            data-test="add-filter-button"
            color="blue"
            outline={true}
            size="sm"
            squared={true}
            className="filters-panel__add-filter-dropdown__trigger"
            style={{ marginLeft: Object.keys(filters)?.length ? '55px' : '0' }}
          >
            +&nbsp;&nbsp;
            {translate(messages.addFilterButton)}
            <ChevronIcon height={'15'} width={'15'} />
          </Button>
        </Dropdown>
      </div>
      <div className="filters-panel__footer">
        <Button
          data-test="clear-all-filters-button"
          size="sm"
          color="black"
          outline={true}
          squared={true}
          onClick={() => {
            clearAllFilters();
            onClose();
          }}
        >
          {translate(messages.clearAllFilters)}
        </Button>
        <Button
          data-test="apply-filters-button"
          size="sm"
          squared={true}
          color="blue"
          outline={true}
          onClick={() => {
            applyFilters();
            onClose();
          }}
        >
          {translate(messages.applyFilters)}
        </Button>
      </div>
    </div>
  );
};

FiltersPopup.propTypes = {
  isOpen: PropTypes.bool,
  filterableKey: PropTypes.string,
  onClose: PropTypes.func,
  optionalFilterItems: PropTypes.array,
  filters: PropTypes.object,
  filtersCount: PropTypes.number,
  clearAllFilters: PropTypes.func,
  applyFilters: PropTypes.func,
  onChangeFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  fetchFilterOptions: PropTypes.func,
  addQueryBlock: PropTypes.func,
};

export default FiltersPopup;
