import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// COMPONENTS:
import Tabs from 'ui/Tabs';
import PageSubHeader from 'components/PageSubheader';
import Settings from 'pages/Editor/settings';
import TemplateHeader from 'pages/Editor/templateHeader';
import Button from 'ui/Button';
import Loader from 'ui/Loader';
import Subjects from './subjects';
import Questions from './questions';
import SubjectList from './subjects/list';
import SubjectHeader from './subjectHeader';
import BackToFollowUpQuestion from './backToFollowUpQuestion';
import { ReactComponent as ArrowRight } from 'assets/svgs/arrowRight.svg';

// UTILS
import { messages } from './messages';
import { SUBJECT_KEY_TABS, createNewEmptyCategories } from 'pages/Editor/subjects/lib';
import { translate } from 'utils/index';
import ROUTES from 'constants/routes';
import { getTemplate, saveTemplate } from 'api/editor';
import { useMixpanelEditAssessmentTrack } from 'hooks/useMixpanelEditAssessmentTrack';
import { MixpanelAssignAssessmentSource } from 'constants/mixpanel';

// HOOKS
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useTheme } from 'hooks/theme';

// REDUX
import { selectBackToFollowUpQuestion, selectTemplate } from './selectors';
import { clearTemplate } from './reducers';
import { selectOrganizationsExtended } from 'pages/Organization/selectors';
import { resetEditOrganizationId } from 'pages/Organization/reducers';
import { selectProfile } from 'pages/Profile/selectors';
import { useRole } from 'hooks/useRole';
import { EditIcon } from 'components/Icons';

// STYLES:
import './index.scss';

const Editor = () => {
  const { templateId } = useParams();
  const [searchParams] = useSearchParams();
  const [themeColor, setThemeColor] = useState('pink');
  const [loading, setLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(SUBJECT_KEY_TABS.MAIN);
  const { title, templateId: sourceTemplate } = useSelector(selectTemplate);
  const { isNewTemplate, template } = useSelector((state) => state.editor);
  const dispatch = useDispatch();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { colorTheme } = useTheme();
  const appNavigate = useAppNavigate();
  const { editOrganizationId } = useSelector(selectOrganizationsExtended);
  useMixpanelEditAssessmentTrack();
  const { isAuditorRole, isBoSoRole, isRestrictedBoSoViewerRole } = useRole();

  useEffect(() => {
    if (!isMyVendorsTabSelected) {
      setThemeColor('green');
    }
  }, [isMyVendorsTabSelected]);

  const {
    user: {
      current_organization: { id: currentOrgId },
    },
  } = useSelector(selectProfile);
  const backToFollowUpQuestion = useSelector(selectBackToFollowUpQuestion);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await dispatch(getTemplate({ id: templateId, orgId: editOrganizationId || '' }));
      setLoading(false);
    };

    getData().then();

    return () => {
      dispatch(clearTemplate());
    };
  }, [templateId]);

  const onPreview = () => {
    const route = isMyVendorsTabSelected
      ? ROUTES.VENDOR_ASSESSMENT_TEMPLATE_PREVIEW
      : ROUTES.CUSTOMER_ASSESSMENT_TEMPLATE_PREVIEW;
    appNavigate(
      route,
      {
        organizationId: currentOrgId,
        assessmentId: templateId,
      },
      null,
      true,
    );
  };

  const onAssign = () => {
    appNavigate(
      isMyVendorsTabSelected ? ROUTES.VENDOR_ASSIGN_ASSESSMENT : ROUTES.CUSTOMER_ASSIGN_ASSESSMENT,
      {},
      { state: { templateId, source: MixpanelAssignAssessmentSource.Editor } },
    );
  };

  const isAssessmentEdit = !!searchParams.get('assessment');

  const headerTag = useMemo(() => {
    if (isAssessmentEdit) {
      return translate(messages.assessment);
    }
    return translate(messages.template);
  }, [isAssessmentEdit]);

  const editTemplate = () => {
    return appNavigate(`${ROUTES.VENDOR_ASSESSMENT_TEMPLATE}`, {
      templateId: sourceTemplate,
    });
  };

  const setDefaultValues = () => {
    if (!template.title || !template.categories[0].title) {
      const defaultValuesPayload = {};

      if (!template.title) {
        defaultValuesPayload.title = 'Assessment';
      }
      if (!template?.categories?.length) {
        defaultValuesPayload.categories = createNewEmptyCategories();
      }

      dispatch(saveTemplate(defaultValuesPayload));
    }
  };

  const onChangeTab = (key) => {
    setActiveTabKey(key);
    setDefaultValues();
  };

  const onClickContinueButton = () => {
    onChangeTab(SUBJECT_KEY_TABS.QUESTIONNAIRE);
  };

  useEffect(() => {
    return () => {
      if (editOrganizationId) dispatch(resetEditOrganizationId());
    };
  }, [editOrganizationId]);

  return (
    <div className="editor">
      {loading && <Loader />}
      <PageSubHeader className="editor__header" withBackArrow>
        {title || translate(messages.assessmentEditor)}{' '}
        <span className={`editor__header-tag-${headerTag}`}>{headerTag}</span>
        <div className="editor__header-buttons">
          {searchParams.get('assessment') && !isBoSoRole && !isAuditorRole && (
            <Button
              data-test="editor-button-edit-template"
              className="editor__header-button-edit-template"
              link
              color="blue"
              onClick={editTemplate}
              name={translate(messages.editTemplate)}
              disabled={isRestrictedBoSoViewerRole}
              size="xs"
            >
              <EditIcon height={'14'} />
            </Button>
          )}
          <Button
            onClick={onPreview}
            target="_blank"
            size="sm"
            color={themeColor}
            outline
            disabled={isNewTemplate || isRestrictedBoSoViewerRole}
            data-test="editor-button-preview"
          >
            {translate(messages.previewButton)}
          </Button>
          {!isAssessmentEdit && !isNewTemplate && template?.categories?.length > 0 && (
            <Button
              onClick={onAssign}
              size="sm"
              color={themeColor}
              data-test="editor-button-assign"
              disabled={isRestrictedBoSoViewerRole}
            >
              {translate(messages.assignButton)}
            </Button>
          )}
        </div>
      </PageSubHeader>
      <Tabs rounded activeKey={activeTabKey} onChange={onChangeTab}>
        <Tabs.Pane
          tab={translate(messages.main)}
          key={SUBJECT_KEY_TABS.MAIN}
          data-test="editor-tab-pane-main"
        >
          <div className="editor__main-wrapper">
            <div className="editor__subjects-wrapper">
              <TemplateHeader />
              <Subjects onChangeTab={onChangeTab} />
              <Button
                onClick={onClickContinueButton}
                size="sm"
                color={colorTheme}
                className="editor__continue-button"
                data-test="editor-continue-button"
              >
                {translate(messages.continueButton)} <ArrowRight width={12} height={11} />
              </Button>
            </div>
            <div className="editor__settings-wrapper">
              <Settings isAssessmentEdit={isAssessmentEdit} themeColor={themeColor} />
            </div>
          </div>
        </Tabs.Pane>
        <Tabs.Pane
          tab={translate(messages.questionnaire)}
          key={SUBJECT_KEY_TABS.QUESTIONNAIRE}
          data-test="editor-tab-pane-questionnaire"
        >
          <DndProvider backend={HTML5Backend}>
            <div className="editor__questionnaire">
              <div className="editor__questionnaire-left">
                <SubjectList />
              </div>
              <div className="editor__questionnaire-right">
                <SubjectHeader />
                <Questions />
              </div>
            </div>
          </DndProvider>
        </Tabs.Pane>
      </Tabs>
      {backToFollowUpQuestion && <BackToFollowUpQuestion />}
    </div>
  );
};

export default Editor;
