import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button as AntdButton, notification } from 'antd';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import { mixpanelTrackCollaborationRequested } from 'utils/mixpanel';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useTheme } from 'hooks/theme';
import { collaborateControls } from 'api/assessment';
import { API_STATUS } from 'constants/api';
import { translate } from 'utils/index';
import { sanitizer } from 'utils/sanitizer';
import { isEmail } from 'utils/strings';
import TextArea from 'ui/TextArea';
import Checkbox from 'ui/Checkbox';
import Button from 'ui/Button';
import Modal from 'ui/Modal';
import Input from 'ui/Input';
import Form from 'ui/Form';
import Icon from 'ui/Icon';

import { NOTIFICATION_DURATION } from 'constants/general';
import { setCollaborate, setCollaboratePopup, setCollaborateQuestions } from './reducers';
import { selectAssessment, selectCategory, selectCategoryKey } from './selectors';
import { calculateControlKey } from './lib';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';

import './collaborate.scss';

const initialValues = {
  emails: '',
  comment: '',
  control_ids: [],
};

const Collaborate = ({ handleGetAssessment }) => {
  const dispatch = useDispatch();
  const { collaborate, collaboratePopup, collaborateQuestions } = useSelector(
    (state) => state.assessment,
  );
  const { controls, title: categoryTitle } = useSelector(selectCategory);
  const key = useSelector(selectCategoryKey);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { colorTheme } = useTheme();
  const [loading, setLoading] = useState(false);
  const assessment = useSelector(selectAssessment);
  const { isRestrictedBoSoViewerRole } = useRole();

  useEffect(() => {
    return () => {
      dispatch(setCollaborate(false));
      dispatch(setCollaborateQuestions([]));
    };
  }, []);

  const cancelCollaborate = (event) => {
    event.stopPropagation();
    dispatch(setCollaborate(false));
    dispatch(setCollaborateQuestions([]));
  };

  const onCollaborate = () => {
    dispatch(setCollaboratePopup(true));
  };

  const { errors, values, touched, handleSubmit, handleChange, resetForm, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: yup.object({
        emails: yup
          .string()
          .transform((value) => Array.from(new Set(value.split(';'))).join(';'))
          .required(translate(messages.requiredEmail))
          .test(
            'emails',
            translate(messages.emailErrorFormat),
            (value) => value && value.split(';').every(isEmail),
          ),
        control_ids: yup.array().of(yup.string()).min(1, translate(messages.controlErrorFormat)),
      }),
      onSubmit: async () => {
        setLoading(true);
        const emailsArray = values.emails.split(';');
        const payload = {
          control_ids: values.control_ids.join(','),
          comment: values.comment,
          emails: emailsArray.join(','),
        };

        const response = await dispatch(collaborateControls(payload)).unwrap();

        if (response.status === API_STATUS.SUCCESS) {
          notification.success({
            message: translate(messages.questionCollaboratedSuccessfully),
            duration: NOTIFICATION_DURATION.TWO_SECONDS,
          });

          mixpanelTrackCollaborationRequested({
            ...payload,
            controls,
            assessment,
            categoryName: categoryTitle,
            source: values.control_ids.length === 1 ? 'single' : 'multiple',
          });

          if (handleGetAssessment) await handleGetAssessment();

          resetForm();
          dispatch(setCollaboratePopup(false));
          dispatch(setCollaborate(false));
          dispatch(setCollaborateQuestions([]));
        }
        setLoading(false);
      },
    });

  useEffect(() => {
    setFieldValue('control_ids', collaborateQuestions);
  }, [collaborateQuestions]);

  const onCancel = () => {
    dispatch(setCollaboratePopup(false));
    resetForm();
    setFieldValue('control_ids', collaborateQuestions);
  };

  const setSelectedQuestion = (id) => {
    if (values.control_ids.some((selectedQuestionId) => id === selectedQuestionId)) {
      const questions = values.control_ids.filter(
        (selectedQuestionId) => id !== selectedQuestionId,
      );
      setFieldValue('control_ids', questions);
    } else {
      setFieldValue('control_ids', [...values.control_ids, id]);
    }
  };

  const footer = [
    <Button
      data-test="assessment-collaborate-cancel-button-cancel"
      key="button_collaborate_1"
      className="assessment-collaborate__footer-cancel-button"
      size="sm"
      color="white"
      onClick={onCancel}
      fluid
    >
      {translate(messages.cancel)}
    </Button>,
    <Button
      data-test="assessment-collaborate-cancel-button-ok"
      key="button_collaborate_2"
      className="assessment-collaborate__footer-ok-button"
      size="sm"
      color={colorTheme}
      onClick={handleSubmit}
      fluid
      type="submit"
      loading={loading}
    >
      {translate(messages.share)}
    </Button>,
  ];

  const buttonClassName = classNames('assessment-collaborate__button', {
    'assessment-collaborate__button--color-pink': isMyVendorsTabSelected,
  });

  const title = (
    <span>
      <Icon icon="share" /> {translate(messages.shareControls)}
    </span>
  );

  return (
    <div className="assessment-collaborate">
      {collaborate && collaborateQuestions.length == 0 && (
        <AntdButton className={buttonClassName} disabled={isRestrictedBoSoViewerRole}>
          <Icon className="assessment-collaborate__icon" icon="share" />
          <span style={{ paddingTop: '1px' }}>
            {translate(messages.toSharePleaseSelectControls)}
          </span>{' '}
          <span className="assessment-collaborate__continue" style={{ paddingTop: '1px' }}>
            {translate(messages.clickToContinue)}
          </span>
          <span className="assessment-collaborate__divider" />
          <AntdButton
            className="assessment-collaborate__cancel-button"
            type="link"
            onClick={cancelCollaborate}
            icon={<Icon icon="close" />}
            disabled={isRestrictedBoSoViewerRole}
          />
        </AntdButton>
      )}
      {collaborate && collaborateQuestions.length >= 1 && (
        <AntdButton
          className={buttonClassName}
          onClick={onCollaborate}
          disabled={isRestrictedBoSoViewerRole}
        >
          <Icon className="assessment-collaborate__icon" icon="share" />
          <span style={{ paddingTop: '1px' }}>
            {`${collaborateQuestions.length} ${translate(messages.controlsSelected)}`}
          </span>{' '}
          <span className="assessment-collaborate__continue" style={{ paddingTop: '1px' }}>
            {translate(messages.clickToContinue)}
          </span>
          <span className="assessment-collaborate__divider" />
          <AntdButton
            disabled={isRestrictedBoSoViewerRole}
            className="assessment-collaborate__cancel-button"
            type="link"
            onClick={cancelCollaborate}
            icon={<Icon icon="close" />}
          />
        </AntdButton>
      )}

      <Modal
        className="assessment-collaborate__modal"
        open={collaboratePopup && !isRestrictedBoSoViewerRole}
        onCancel={onCancel}
        width={685}
        title={title}
        footer={footer}
      >
        <Form onSubmit={handleSubmit}>
          <div className="assessment-collaborate__description">
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizer(translate(messages.shareControlsDescription)),
              }}
            />
          </div>
          <Input
            data-test="assessment-collaborate-email-input"
            className="assessment-collaborate__email"
            name="emails"
            type="email"
            placeholder={translate(messages.enterEmailAddress)}
            onChange={handleChange}
            value={values.emails}
            error={touched.emails && errors.emails}
          />
          <TextArea
            className="assessment-collaborate__comment"
            name="comment"
            onChange={handleChange}
            placeholder={translate(messages.addComment)}
            value={values.comment}
          />
          <label className="assessment-collaborate__label">{translate(messages.controls)}</label>
          <div
            className={classNames('assessment-collaborate__questions', {
              'assessment-collaborate__questions--error': touched.control_ids && errors.control_ids,
            })}
          >
            {controls.map(
              (control, index) =>
                collaborateQuestions.includes(control.id) && (
                  <div className="assessment-collaborate__question" key={control.id}>
                    <div className="assessment-collaborate__question-key">
                      <Checkbox
                        onChange={() => setSelectedQuestion(control.id)}
                        checked={values.control_ids.includes(control.id)}
                        name={`question-${control.id}`}
                        mode="checkbox"
                      >
                        {calculateControlKey(key, index)}
                      </Checkbox>
                    </div>
                    <div className="assessment-collaborate__question-text">
                      <span
                        dangerouslySetInnerHTML={{ __html: sanitizer(control.title) }}
                        data-test="collaboration-modal-control-question"
                      />
                    </div>
                  </div>
                ),
            )}
          </div>
          {touched.control_ids && errors.control_ids && (
            <p className="assessment-collaborate__error">{errors.control_ids}</p>
          )}
        </Form>
      </Modal>
    </div>
  );
};

Collaborate.propTypes = {
  handleGetAssessment: PropTypes.func,
};

export default Collaborate;
