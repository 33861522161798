import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as AssessmentIcon } from 'assets/svgs/assessment-icon.svg';

import './index.scss';

const AssessmentItem = ({ name, isActive, onSetActiveAssessmentId }) => {
  const assessmentItemsClassNames = classNames('assessment-item', {
    'assessment-item--active': isActive,
  });

  return (
    <div
      className={assessmentItemsClassNames}
      onClick={onSetActiveAssessmentId}
      data-test={`assessment-button-${name}`}
    >
      <div>
        <AssessmentIcon width={21} height={27} />
      </div>
      <p>{name}</p>
    </div>
  );
};

AssessmentItem.propTypes = {
  name: PropTypes.string,
  isActive: PropTypes.bool,
  onSetActiveAssessmentId: PropTypes.func,
};

export default AssessmentItem;
