import React from 'react';
import PropType from 'prop-types';

import './label.scss';

const Label = ({ text, className }) => {
  return <div className={`form-label ${className}`}>{text}</div>;
};

Label.propTypes = {
  className: PropType.string,
  text: PropType.string,
};
export default Label;
