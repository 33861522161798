import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import SecondaryButton from 'ui/SecondaryButton';
import { translate } from 'utils/index';
import { saveTemplate } from 'api/editor';

import { messages } from 'pages/Editor/messages';
import './addSubject.scss';

const AddSubject = ({ setActiveSubject, disabled = false }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();

  const onAddSubject = async () => {
    const id = uuidv4();
    const payload = {
      categories: [
        {
          id,
          title: '',
          benchmark: 0,
          weight: 0,
        },
      ],
    };
    setLoading(true);
    await dispatch(saveTemplate(payload)).unwrap();
    setActiveSubject(id);
    setLoading(false);
  };

  return (
    <SecondaryButton
      className="editor-add-subject"
      dashed
      fullWidth
      onClick={onAddSubject}
      icon="plus"
      loading={loading}
      size="large"
      disabled={disabled}
    >
      {translate(messages.addSubject)}
    </SecondaryButton>
  );
};

AddSubject.propTypes = {
  setActiveSubject: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default AddSubject;
