import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getDashboardChartData, getDashboardLayout } from 'api/dashboard';

const initialState = {
  loading: false,
  charts: null,
};

const reducers = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardLayout.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardChartData.pending, (state, action) => {
        const rowIndex = action.meta?.arg?.rowIndex;
        const colIndex = action.meta?.arg?.colIndex;

        if (
          colIndex !== undefined &&
          rowIndex !== undefined &&
          state?.charts?.length &&
          state.charts?.[rowIndex][colIndex]
        ) {
          state.charts[rowIndex][colIndex].loading = true;
        }
      })
      .addMatcher(
        isAnyOf(getDashboardLayout.fulfilled, getDashboardLayout.rejected),
        (state, action) => {
          state.loading = false;
          state.charts = action.payload?.layout || [];
        },
      )
      .addMatcher(
        isAnyOf(getDashboardChartData.fulfilled, getDashboardChartData.rejected),
        (state, action) => {
          const rowIndex = action.meta.arg?.rowIndex;
          const colIndex = action.meta.arg?.colIndex;

          if (
            colIndex !== undefined &&
            rowIndex !== undefined &&
            state?.charts?.length &&
            state.charts[rowIndex][colIndex]
          ) {
            state.charts[rowIndex][colIndex].data = action.payload?.data || null;
            state.charts[rowIndex][colIndex].loading = false;
          }
        },
      );
  },
});

export const { setChartData, selectYear } = reducers.actions;

export default reducers;
