import { createSlice } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import {
  getAnswerTypes,
  getTemplate,
  removeAssessmentFile,
  uploadProfileAssessmentsTemplateLogo,
  saveTemplate,
  saveTemplateFinding,
  saveTemplateRecommendation,
  appTriggers,
  removeControlFile,
  moveControlBetweenCategories,
  addAppTrigger,
  removeAppTrigger,
} from 'api/editor';

const initialState = {
  answerTypes: [],
  answerTypesLoading: false,
  appsRequirements: {},
  appsLoading: false,
  template: {},
  loading: false,
  saveLoading: false,
  selectedSubject: null,
  activeQuestion: null,
  backToFollowUpQuestion: null,
};

const reducers = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    clearTemplate: (state) => {
      state.template = {};
    },
    addSubject: (state, { payload }) => {
      state.template.categories = state.template.categories.concat([payload]);
    },
    removeSubject: (state, { payload }) => {
      state.template.categories = state.template.categories.filter(
        (subject) => subject.id !== payload.subjectId,
      );
    },
    editSubject: (state, { payload: { subjectIndex, key, value } }) => {
      state.template.categories[subjectIndex][key] = value;
    },
    selectSubject: (state, { payload }) => {
      state.selectedSubject = payload;
    },
    setActiveQuestion: (state, { payload }) => {
      state.activeQuestion = payload;
    },
    setBackToFollowUpQuestion: (state, { payload }) => {
      state.backToFollowUpQuestion = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getTemplate.fulfilled,
        (
          state,
          {
            payload: {
              assessmentsTemplate: { assessmentJson },
              isNewTemplate,
            },
            meta,
          },
        ) => {
          state.loading = false;
          if (meta.arg.id === '9c47b278-c4ce-4840-8cd5-400d75043504') {
            const response = getTemplateWithApps();
            state.template = response.assessmentsTemplate.assessmentJson;
            state.template.id = state.template.id || meta.arg.id;
            return;
          }
          // eslint-disable-next-line no-underscore-dangle
          state.template = assessmentJson._assessment || assessmentJson;
          state.template.id = state.template.id || meta.arg.id;
          state.isNewTemplate = isNewTemplate;
        },
      )
      .addCase(getTemplate.rejected, (state) => {
        state.loading = false;
      })
      .addCase(saveTemplate.pending, (state) => {
        state.saveLoading = true;
      })
      .addCase(
        saveTemplate.fulfilled,
        (state, { payload: { _assessment: assessment, isNewTemplate } }) => {
          if (assessment) {
            state.template = assessment;
            state.isNewTemplate = isNewTemplate;
          }
          state.saveLoading = false;
        },
      )
      .addCase(saveTemplate.rejected, (state) => {
        state.saveLoading = false;
      })
      .addCase(addAppTrigger.pending, (state) => {
        state.saveLoading = true;
      })
      .addCase(addAppTrigger.fulfilled, (state, { payload: { _assessment: assessment } }) => {
        if (assessment) {
          state.template = assessment;
        }
        state.saveLoading = false;
      })
      .addCase(addAppTrigger.rejected, (state) => {
        state.saveLoading = false;
      })
      .addCase(removeAppTrigger.pending, (state) => {
        state.saveLoading = true;
      })
      .addCase(removeAppTrigger.fulfilled, (state, { payload: { _assessment: assessment } }) => {
        if (assessment) {
          state.template = assessment;
        }
        state.saveLoading = false;
      })
      .addCase(removeAppTrigger.rejected, (state) => {
        state.saveLoading = false;
      })
      .addCase(saveTemplateFinding.fulfilled, (state, { payload: { _assessment: assessment } }) => {
        state.template = assessment;
      })
      .addCase(
        moveControlBetweenCategories.fulfilled,
        (state, { payload: { _assessment: assessment } }) => {
          state.template = assessment;
        },
      )
      .addCase(
        saveTemplateRecommendation.fulfilled,
        (state, { payload: { _assessment: assessment } }) => {
          state.template = assessment;
        },
      )
      .addCase(getAnswerTypes.pending, (state) => {
        state.answerTypesLoading = true;
      })
      .addCase(getAnswerTypes.fulfilled, (state, { payload: { data } }) => {
        state.answerTypesLoading = false;
        state.answerTypes = sortBy(data, 'type');
      })
      .addCase(getAnswerTypes.rejected, (state) => {
        state.answerTypesLoading = false;
      })
      .addCase(appTriggers.pending, (state) => {
        state.appsLoading = true;
      })
      .addCase(appTriggers.fulfilled, (state, { payload: { requirements } }) => {
        state.appsLoading = false;
        state.appsRequirements = requirements || {};
      })
      .addCase(appTriggers.rejected, (state) => {
        state.appsLoading = false;
      })
      .addCase(
        uploadProfileAssessmentsTemplateLogo.fulfilled,
        (state, { payload: { corporateLogo } }) => {
          if (corporateLogo) {
            state.template.customer_logo = corporateLogo;
          }
        },
      )
      .addCase(
        removeAssessmentFile.fulfilled,
        (
          state,
          {
            payload: {
              data: { assessmentJson },
            },
          },
        ) => {
          state.template = assessmentJson;
        },
      )
      .addCase(
        removeControlFile.fulfilled,
        (
          state,
          {
            payload: {
              data: { assessmentJson },
            },
          },
        ) => {
          state.template = assessmentJson;
        },
      );
  },
});

const getTemplateWithApps = () => {
  return {
    'status': 1,
    'msg': 'Success',
    'isNewTemplate': false,
    'assessmentsTemplate': {
      'assessmentJson': {
        'categories': [
          {
            'id': '6c171beb-c326-4675-921c-4351470bf901',
            'title': 'Category 1',
            'score': 0,
            'status': 0,
            'findings': 0,
            'position': 0,
            'progress': 0,
            'overview': '',
            'benchmark': 0.0,
            'weight': 0.0,
            'app': null,
            'controls': [
              {
                'id': '14ebe995-de4a-4555-a70b-93454fd8fa91',
                'categoryId': '6c171beb-c326-4675-921c-4351470bf901',
                'answer_type_idx': '0dbfcb50-cd1a-444f-9b0e-9b82170ea950',
                'title': 'Question 2',
                'answer': '',
                'status': 0,
                'enabled': true,
                'base_id': 'f33fa9b7-724d-4056-82b5-bb2b60f0f8fc',
                'triggers': {
                  '1': ['f30bc44d-d58b-4989-aec1-f0502d06be36'],
                },
                'comment': '',
                'suggestions': '',
                'description': '',
                'descriptionRight': '',
                'optional': false,
                'pending': false,
                'readonly': false,
                'emailTrigger': false,
                'app_trigger': null,
                'gaps': [],
                'findings': [],
              },
              {
                'id': 'f30bc44d-d58b-4989-aec1-f0502d06be36',
                'categoryId': '6c171beb-c326-4675-921c-4351470bf901',
                'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
                'title': 'Question 1',
                'answer': '',
                'status': 0,
                'enabled': true,
                'base_id': '78c68091-22d8-46fd-b774-8556eaa3a59f',
                'triggers': {},
                'comment': '',
                'suggestions': '',
                'description': '',
                'descriptionRight': '',
                'optional': false,
                'pending': false,
                'readonly': false,
                'emailTrigger': false,
                'app_trigger': '49210d50-f4cc-43db-9be4-ec2cb4c504a8',
                'gaps': [],
                'findings': [],
              },
              {
                'id': '95aec850-c6f2-4887-9da3-8de3d32319b1',
                'categoryId': '6c171beb-c326-4675-921c-4351470bf901',
                'answer_type_idx': '0c22f0ce-d8f9-4a47-a803-e54590cf8334',
                'title': 'Who is your cloud provider?',
                'answer': '',
                'status': 0,
                'enabled': true,
                'base_id': '54fb9bd9-4b0f-4c96-8b36-f9f140363705',
                'triggers': {
                  '1': [
                    'a186aa21-b5d1-4429-9dd4-96ee04d83538',
                    '258124c3-244b-4e67-af59-59b4b748d02c',
                    '5c5e7113-a5cb-46b1-bb56-8c351f704248',
                  ],
                  '2': [
                    'b9c7eb7a-d423-48da-8dd0-1b38c7ef14ab',
                    '5c5e7113-a5cb-46b1-bb56-8c351f704248',
                  ],
                },
                'comment': '',
                'suggestions': '',
                'description':
                  'The following questions will allow Findings to connect to the assessed company\u2019s cloud provider instance and VIEW relevant information. This requires the assessed company\u2019s explicit consent within the cloud provider\u2019s console.',
                'descriptionRight': '',
                'optional': false,
                'pending': false,
                'readonly': false,
                'emailTrigger': false,
                'app_trigger': null,
                'gaps': [],
                'findings': [],
                apps: ['d0de70e6-8b6b-47a0-a847-5b8379a91f94'],
              },
              {
                'id': 'b9c7eb7a-d423-48da-8dd0-1b38c7ef14ab',
                'categoryId': '6c171beb-c326-4675-921c-4351470bf901',
                'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
                'title': 'What is you subscription ID?',
                'answer': '',
                'status': 0,
                'enabled': false,
                'base_id': '84c63d2b-29a0-4a46-8ee1-1c653b17adf4',
                'triggers': {},
                'comment': '',
                'suggestions': '',
                'description': '',
                'descriptionRight': '',
                'optional': false,
                'pending': false,
                'readonly': false,
                'emailTrigger': false,
                'app_trigger': null,
                'gaps': [],
                'findings': [],
                apps: ['d0de70e6-8b6b-47a0-a847-5b8379a91f94'],
              },
              {
                'id': '258124c3-244b-4e67-af59-59b4b748d02c',
                'categoryId': '6c171beb-c326-4675-921c-4351470bf901',
                'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
                'title': 'What is your account ID?',
                'answer': '',
                'status': 0,
                'enabled': false,
                'base_id': 'cf77bbcf-e512-4c67-a904-42569e71e329',
                'triggers': {},
                'comment': '',
                'suggestions': '',
                'description': '',
                'descriptionRight': '',
                'optional': false,
                'pending': false,
                'readonly': false,
                'emailTrigger': false,
                'app_trigger': null,
                'gaps': [],
                'findings': [],
                apps: ['d0de70e6-8b6b-47a0-a847-5b8379a91f94'],
              },
              {
                'id': '5c5e7113-a5cb-46b1-bb56-8c351f704248',
                'categoryId': '6c171beb-c326-4675-921c-4351470bf901',
                'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
                'title': 'What is the email address of your cloud administrator?',
                'answer': '',
                'status': 0,
                'enabled': false,
                'base_id': 'f974aeef-809f-4b60-8f53-bb931903a065',
                'triggers': {},
                'comment': '',
                'suggestions': '',
                'description': '',
                'descriptionRight':
                  'After submitting this assessment, a handshake request will be sent to the assessed company\u2019s cloud provider console. Findings will also notify the cloud administrator within the assessed company, to ensure this handshake is accepted.',
                'optional': false,
                'pending': false,
                'readonly': false,
                'emailTrigger': false,
                'app_trigger': null,
                'gaps': [],
                'findings': [],
                apps: ['d0de70e6-8b6b-47a0-a847-5b8379a91f94'],
              },
              {
                'id': 'a186aa21-b5d1-4429-9dd4-96ee04d83538',
                'categoryId': '6c171beb-c326-4675-921c-4351470bf901',
                'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
                'title': 'What is your account\u2019s region?',
                'answer': '',
                'status': 0,
                'enabled': false,
                'base_id': 'ad484818-0b64-445c-88ea-1a99474ad35c',
                'triggers': {},
                'comment': '',
                'suggestions': '',
                'description': '',
                'descriptionRight': '',
                'optional': false,
                'pending': false,
                'readonly': false,
                'emailTrigger': false,
                'app_trigger': null,
                'gaps': [],
                'findings': [],
                apps: ['d0de70e6-8b6b-47a0-a847-5b8379a91f94'],
              },
              {
                'id': 'bed51ea0-0852-46c9-8c02-e333ba0f09d2',
                'categoryId': '6c171beb-c326-4675-921c-4351470bf901',
                'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
                'title': 'Please enter your domain/website address',
                'answer': '',
                'status': 0,
                'enabled': true,
                'base_id': '9a57669f-eb07-4336-aa7c-4e948fe5e2fc',
                'triggers': {},
                'comment': '',
                'suggestions': '',
                'description':
                  'Providing a domain/website address allows Findings to activate automatic apps that analyze and provide insights with regards to the assessed company.',
                'descriptionRight': '',
                'optional': false,
                'pending': false,
                'readonly': false,
                'emailTrigger': false,
                'app_trigger': '49210d50-f4cc-43db-9be4-ec2cb4c504a8',
                'gaps': [],
                'findings': [],
                apps: [
                  'a55c3cd5-1974-4392-8338-33bdb71f1ce8',
                  'f4a59108-9edf-406d-a362-273f6d6c20ef',
                ],
              },
              {
                'id': '9a3f1d69-50f2-42f3-b7a1-e9ebf84d8a57',
                'categoryId': '6c171beb-c326-4675-921c-4351470bf901',
                'answer_type_idx': '0381df95-3bbb-42cd-86d5-d8820ca1c989',
                'title': 'Do you have any other relevant domains/websites?',
                'answer': '',
                'status': 0,
                'enabled': true,
                'base_id': '9846cb9c-d4df-4ac4-8f96-b21b164cfdec',
                'triggers': {
                  '1': ['d1ead5aa-815b-4747-98b4-2bee76bca326'],
                },
                'comment': '',
                'suggestions': '',
                'description': '',
                'descriptionRight':
                  'A \u201crelevant domain/website\u201d is any digital asset owned by the company that might have any implications to the current business engagement.',
                'optional': false,
                'pending': false,
                'readonly': false,
                'emailTrigger': false,
                'app_trigger': null,
                'gaps': [],
                'findings': [],
                apps: [
                  'a55c3cd5-1974-4392-8338-33bdb71f1ce8',
                  'f4a59108-9edf-406d-a362-273f6d6c20ef',
                ],
              },
              {
                'id': 'd1ead5aa-815b-4747-98b4-2bee76bca326',
                'categoryId': '6c171beb-c326-4675-921c-4351470bf901',
                'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
                'title': 'Please enter your domain/website address',
                'answer': '',
                'status': 0,
                'enabled': false,
                'base_id': '9a57669f-eb07-4336-aa7c-4e948fe5e2fc',
                'triggers': {},
                'comment': '',
                'suggestions': '',
                'description':
                  'Providing a domain/website address allows Findings to activate automatic apps that analyze and provide insights with regards to the assessed company.',
                'descriptionRight': '',
                'optional': false,
                'pending': false,
                'readonly': false,
                'emailTrigger': false,
                'app_trigger': '49210d50-f4cc-43db-9be4-ec2cb4c504a8',
                'gaps': [],
                'findings': [],
                apps: [
                  'a55c3cd5-1974-4392-8338-33bdb71f1ce8',
                  'f4a59108-9edf-406d-a362-273f6d6c20ef',
                ],
              },
            ],
          },
          {
            'id': '99d37566-e426-4a32-aa17-e325350176ea',
            'title': 'Category 2',
            'score': 0,
            'status': 0,
            'findings': 0,
            'position': 1,
            'progress': 0,
            'overview': '',
            'benchmark': 0.0,
            'weight': 0.0,
            'app': null,
            'controls': [
              {
                'id': 'b79349d7-86a1-4e74-bf1d-a16f5eedadbb',
                'categoryId': '99d37566-e426-4a32-aa17-e325350176ea',
                'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
                'title': 'question 1',
                'answer': '',
                'status': 0,
                'enabled': true,
                'base_id': 'ef620c54-2c33-4890-b9af-18a042213290',
                'triggers': {},
                'comment': '',
                'suggestions': '',
                'description': '',
                'descriptionRight': '',
                'optional': false,
                'pending': false,
                'readonly': false,
                'emailTrigger': false,
                'app_trigger': null,
                'gaps': [],
                'findings': [],
              },
            ],
          },
        ],
        'answer_types': {
          '0381df95-3bbb-42cd-86d5-d8820ca1c989': {
            'id': '0381df95-3bbb-42cd-86d5-d8820ca1c989',
            'type': 2,
            'options': [
              {
                'key': 1,
                'value': 'Yes',
                'weight': 0,
              },
              {
                'key': 2,
                'value': 'No',
                'weight': 0,
              },
            ],
            'weight': 0,
            'rtl': false,
          },
          '08648ee7-07e7-41b3-99c0-8b63905d029f': {
            'id': '08648ee7-07e7-41b3-99c0-8b63905d029f',
            'type': 0,
            'options': null,
            'weight': 0,
            'rtl': false,
          },
          '0c22f0ce-d8f9-4a47-a803-e54590cf8334': {
            'id': '0c22f0ce-d8f9-4a47-a803-e54590cf8334',
            'type': 2,
            'options': [
              {
                'key': 1,
                'value': 'AWS',
                'weight': 0,
              },
              {
                'key': 2,
                'value': 'Azure',
                'weight': 0,
              },
            ],
            'weight': 0,
            'rtl': false,
          },
          '0dbfcb50-cd1a-444f-9b0e-9b82170ea950': {
            'id': '0dbfcb50-cd1a-444f-9b0e-9b82170ea950',
            'type': 2,
            'options': [
              {
                'key': 1,
                'value': '1',
                'weight': 0,
              },
              {
                'key': 2,
                'value': '2',
                'weight': 3,
              },
            ],
            'weight': 0,
            'rtl': false,
          },
        },
        'status': 0,
        'finalized': false,
        'files': [],
        'id': '9c47b278-c4ce-4840-8cd5-400d75043504',
        'title': 'dawid 25 - app triggers',
        'impact': [
          {
            'key': 'Trivial',
            'value': 1,
          },
          {
            'key': 'Minor',
            'value': 2,
          },
          {
            'key': 'Moderate',
            'value': 3,
          },
          {
            'key': 'Major',
            'value': 4,
          },
          {
            'key': 'Extreme',
            'value': 5,
          },
        ],
        'probability': [
          {
            'key': 'Rare',
            'value': 1,
          },
          {
            'key': 'Unlikely',
            'value': 2,
          },
          {
            'key': 'Moderate',
            'value': 3,
          },
          {
            'key': 'Likely',
            'value': 4,
          },
          {
            'key': 'Very Likely',
            'value': 5,
          },
        ],
        'risk': [
          'Low',
          'Low',
          'Low',
          'Low',
          'Low',
          'Low',
          'Low',
          'Low',
          'Medium',
          'Medium',
          'Medium',
          'Medium',
          'Medium',
          'Medium',
          'Medium',
          'High',
          'High',
          'High',
          'High',
          'Critical',
          'Critical',
          'Critical',
          'Critical',
          'Critical',
          'Show Stopper',
        ],
        'upstream': false,
        'customer_logo': '0ab30ffa-b3dc-47af-8d0b-b5ae6969d477.png',
        'rtl': false,
        'findings': {
          'all-findings': 0,
          'open-findings': 0,
          'open-recs': 0,
          'overdue-recs': 0,
        },
        'total_score': {
          'score': 0,
          'status': 0,
          'benchmark': 0.0,
          'completion': 0.0,
          'progress': 0.0,
        },
        'report': null,
        'description': 'test',
        'group': null,
      },
    },
    'assessmentsGroups': [],
    'assessmentsTemplates': [],
  };
};

export const {
  clearTemplate,
  selectSubject,
  addSubject,
  removeSubject,
  editSubject,
  setActiveQuestion,
  setBackToFollowUpQuestion,
} = reducers.actions;

export default reducers;
