import { ControlType } from 'constants/controlType';

export function flatControls(categories) {
  if (!categories) {
    return [];
  }

  const controls = [];

  categories.forEach((category) =>
    category.controls.forEach((control) => {
      controls.push({
        ...control,
        categoryId: category.id,
      });
    }),
  );
  return controls;
}

export function checkForMissingOptionsInAnswerType(answerType = {}) {
  if (!isEditableAnswerType(answerType?.type)) return answerType;

  // Support for Old Frontend corrupted data.
  if (!answerType?.options) {
    return {
      ...answerType,
      options: [{ key: 1, weight: 0, value: '' }],
    };
  }

  return answerType;
}

export function getAnswerType(answerTypeIdx, answerTypes = []) {
  const answerType = answerTypes[answerTypeIdx];
  return answerType || {};
}

export function isEditableAnswerType(type) {
  return [
    ControlType.RadioGroup,
    ControlType.Checkbox,
    ControlType.DropDown,
    ControlType.MultiSelect,
    ControlType.DatePicker,
  ].includes(type);
}

export function allowFindings(type) {
  return [
    ControlType.RadioGroup,
    ControlType.Checkbox,
    ControlType.DropDown,
    ControlType.MultiSelect,
  ].includes(type);
}

export function allowTriggers(type) {
  return [
    ControlType.RadioGroup,
    ControlType.Checkbox,
    ControlType.DropDown,
    ControlType.MultiSelect,
  ].includes(type);
}

export function getDefaultAnswer(type) {
  switch (type) {
    case ControlType.Input:
    case ControlType.TextArea:
      return '';
    case ControlType.DatePicker:
      return undefined;
    case ControlType.RadioGroup:
    case ControlType.Checkbox:
    case ControlType.DropDown:
    case ControlType.MultiSelect:
      return 0;
    case ControlType.FileUpload:
      return [];
    default:
      return '';
  }
}

export function getControlNameByType(type) {
  const [name] = Object.entries(ControlType).find(([, controlType]) => type === controlType);
  return name;
}

export const findControlIndex = (controls, controlId) => {
  return controls.findIndex((subjectControl) => subjectControl.id === controlId);
};
