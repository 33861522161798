import { useContext } from 'react';
import { VendorManageContext } from 'context/vendorManageContext';

export function useVendorManageContext() {
  const context = useContext(VendorManageContext);
  if (!context) {
    throw new Error('VendorManageContext not defined');
  }
  return context;
}
