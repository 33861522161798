import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getAnswerRepository } from 'api/assessment';

const initialState = {
  repository: [],
};

const reducers = createSlice({
  name: 'answerRepository',
  initialState,
  reducers: {
    clearRepository: (state) => {
      state.repository = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(getAnswerRepository.fulfilled, getAnswerRepository.rejected),
      (state, { payload: { categories } }) => {
        state.repository = categories;
      },
    );
  },
});

export const { clearRepository } = reducers.actions;

export default reducers;
