export const messages = {
  customerHeader: {
    key: 'CustomerList.customerHeader',
    defaultMessage: 'Customers',
  },
  date: {
    key: 'CustomerList.date',
    defaultMessage: 'DATE',
  },
  organization: {
    key: 'CustomerList.organization',
    defaultMessage: 'ORGANIZATION',
  },
  company: {
    key: 'CustomerList.company',
    defaultMessage: 'COMPANY',
  },
  name: {
    key: 'CustomerList.name',
    defaultMessage: 'NAME',
  },
  email: {
    key: 'CustomerList.email',
    defaultMessage: 'EMAIL',
  },
  phone: {
    key: 'CustomerList.phone',
    defaultMessage: 'PHONE',
  },
  onboardingNotCompleted: {
    key: 'CustomerList.onboardingNotCompleted',
    defaultMessage: 'Organization in onboarding process (No one member complete onboarding).',
  },
  notSpecified: {
    key: 'CustomerList.notSpecified',
    defaultMessage: 'Not specified',
  },
  customer: {
    key: 'CustomerList.customer',
    defaultMessage: 'CUSTOMER',
  },
  status: {
    key: 'CustomerList.status',
    defaultMessage: 'TRUST INDICATOR',
  },
  activeAssessments: {
    key: 'CustomerList.activeAssessments',
    defaultMessage: 'ACTIVE ASSESSMENTS',
  },
  customerRisk: {
    key: 'CustomerList.customerRisk',
    defaultMessage: 'CUSTOMER RISK',
  },
  removeCustomerText: {
    key: 'CustomerList.removeCustomerText',
    defaultMessage: 'Are you sure you want to permanently delete this customer?',
  },
  addFilter: {
    key: 'CustomerList.addFilter',
    defaultMessage: 'Add filter',
  },
  filterPlaceholder: {
    key: 'CustomerList.filterPlaceholder',
    defaultMessage: 'Select value',
  },
  filterCompany: {
    key: 'CustomerList.filterCompany',
    defaultMessage: 'Company',
  },
  filterAssessment: {
    key: 'CustomerList.filterAssessment',
    defaultMessage: 'Assessment',
  },
  filterCustomerRisk: {
    key: 'CustomerList.filterCustomerRisk',
    defaultMessage: 'Customer risk',
  },
  filterParentOrganization: {
    key: 'CustomerList.filterParentOrganization',
    defaultMessage: 'Organization',
  },
};

export default {
  prefix: 'CustomerList',
  messages,
};
