import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils/index';
import { messages } from '../messages';
import Loader from 'ui/Loader';

import { eDashboardChartTypes } from '../lib';
import {
  ScoreCircularChart,
  LineChart,
  TotalSummary,
  MagicTable,
  BarChart,
  DoubleDoughnutChart,
  MultiProgressChart,
  PieChart,
} from '../charts';
import { RiskreconIcon } from 'components/Icons';
import { getDashboardChartData } from 'api/dashboard';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { FILTERABLE_KEYS } from 'components/Filters/lib';

import './index.scss';

const DashboardChart = ({ rowIndex, colIndex }) => {
  const dispatch = useDispatch();
  const chart = useSelector((state) => state.dashboard.charts[rowIndex][colIndex]);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const { filtersToApi } = useSelector(
    (state) => state.filters.allFilters[FILTERABLE_KEYS.DASHBOARD_FILTERS],
  );
  const { loading } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (rowIndex !== undefined && colIndex !== undefined && chart && !loading) {
      dispatch(
        getDashboardChartData({
          chartType: chart.chartType,
          dataType: chart.dataType,
          rowIndex,
          colIndex,
          upstream: !isMyVendorsTabSelected,
        }),
      );
    }
  }, [filtersToApi, loading]);

  const titleIcon = useMemo(() => {
    switch (chart?.icon) {
      case 'RiskreconIcon':
        return <RiskreconIcon />;
      default:
        return null;
    }
  }, [chart?.icon]);

  const renderChart = useMemo(() => {
    switch (chart.chartType) {
      case eDashboardChartTypes.scoreCircularChart:
        return <ScoreCircularChart chart={chart} />;
      case eDashboardChartTypes.lineChart:
        return <LineChart chart={chart} />;
      case eDashboardChartTypes.totalSummary:
        return <TotalSummary chart={chart} />;
      case eDashboardChartTypes.magicTable:
        return <MagicTable chart={chart} />;
      case eDashboardChartTypes.barChart:
        return <BarChart chart={chart} />;
      case eDashboardChartTypes.doubleDoughnutChart:
        return <DoubleDoughnutChart chart={chart} />;
      case eDashboardChartTypes.multiProgressChart:
        return <MultiProgressChart chart={chart} />;
      case eDashboardChartTypes.pieChart:
        return <PieChart chart={chart} />;
      default:
        return 'No chart found';
    }
  }, [chart]);

  if (!chart) {
    return null;
  }

  return (
    <div className={`dashboard__chart chart-size--${chart.size}`}>
      <div className="dashboard__chart__header">
        <div className="dashboard__chart__header-content">
          <h3>
            {chart.icon ? titleIcon : null}
            {translate({ key: chart.title.key, defaultMessage: chart.title.value })}
          </h3>
          {chart.data && chart.subtitle ? (
            <p>{translate({ key: chart.subtitle?.key, defaultMessage: chart.subtitle?.value })}</p>
          ) : null}
          {!chart.data && !chart.loading ? <p>{translate(messages.dashboardChartNoData)}</p> : null}
        </div>
      </div>
      <div className="dashboard__chart-content">{chart.loading ? <Loader /> : renderChart}</div>
    </div>
  );
};

DashboardChart.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  colIndex: PropTypes.number.isRequired,
};

export default DashboardChart;
