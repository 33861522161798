import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'ui/Input';

import './index.scss';

const Search = ({ onSearch }) => {
  const [newValue, setNewValue] = useState('');

  const search = (event) => {
    const { value } = event.target;
    setNewValue(value);
    onSearch(value);
  };

  return (
    <Input.Search className="templates-search" onChange={search} size="medium" value={newValue} />
  );
};

Search.propTypes = {
  onSearch: PropTypes.func,
};

export default Search;
