export const messages = {
  phoneLabel: {
    key: 'PhoneInput.phoneLabel',
    defaultMessage: 'Phone',
  },
  phonePlaceholder: {
    key: 'PhoneInput.phonePlaceholder',
    defaultMessage: 'Type phone number',
  },
  phoneErrorRequired: {
    key: 'PhoneInput.phoneErrorRequired',
    defaultMessage: 'Phone is required',
  },
  phoneErrorInvalidFormat: {
    key: 'PhoneInput.phoneErrorInvalidFormat',
    defaultMessage: 'Invalid phone format',
  },
  prefixLabel: {
    key: 'PhoneInput.prefixLabel',
    defaultMessage: 'Prefix',
  },
  prefixPlaceholder: {
    key: 'PhoneInput.prefixPlaceholder',
    defaultMessage: 'GB +1',
  },
  prefixErrorRequired: {
    key: 'PhoneInput.prefixErrorRequired',
    defaultMessage: 'Prefix is required',
  },
};

export default {
  prefix: 'PhoneInput',
  messages,
};
