import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import update from 'immutability-helper';

import { isKeyCodeAnEnterCode, translate } from 'utils/index';
import { saveTemplate } from 'api/editor';
import SearchExpandable from 'components/SearchExpandable';
import SecondaryButton from 'ui/SecondaryButton';
import Icon from 'ui/Icon';

import { selectSubject, addSubject, removeSubject } from 'pages/Editor/reducers';
import { selectSubject as selectorsSelectSubject, selectTemplate } from 'pages/Editor/selectors';
import { messages } from 'pages/Editor/messages';
import ListItem from './listItem';
import { useRole } from 'hooks/useRole';
import './list.scss';

const SubjectList = () => {
  const dispatch = useDispatch();
  const template = useSelector(selectTemplate);
  const subject = useSelector(selectorsSelectSubject);
  const [searchValue, setSearchValue] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const selectedSubjectId = useSelector((state) => state.editor.selectedSubject);

  const initActiveEdit = {
    id: '',
    value: '',
  };
  const [activeEdit, setActiveEdit] = useState(initActiveEdit);
  const [subjects, setSubjects] = useState([]);
  const { isRestrictedBoSoViewerRole } = useRole();

  useEffect(() => {
    if (!selectedSubjectId && subjects[0]?.id && subjects[0].id !== selectedSubjectId) {
      dispatch(selectSubject(subjects[0].id));
    }
  }, [subjects]);

  useEffect(() => {
    if (template?.categories && template?.categories?.length && !subject.id) {
      dispatch(selectSubject(template?.categories[0]?.id));
    }
    if (template?.categories?.length > 0) {
      setSubjects(template.categories);
    }
  }, [template]);

  const subjectOnClick = (subjectId) => {
    if (subjectId !== activeEdit.id) dispatch(selectSubject(subjectId));
  };

  const addSubjectOnClick = async () => {
    const categoryId = uuidv4();
    dispatch(addSubject({ id: categoryId, title: '', controls: [] }));
    dispatch(selectSubject(categoryId));
    setActiveEdit((state) => ({ ...state, id: categoryId, isNew: true }));
  };

  const onChangeInputTitle = ({ target: { value } }) => {
    setActiveEdit((state) => ({ ...state, value }));
  };

  const onDoubleClickTitle = (categoryId, categoryTitle) => {
    if (!isRestrictedBoSoViewerRole) {
      setActiveEdit({ value: categoryTitle, id: categoryId });
    }
  };

  const onBlurInputTitle = useCallback(async () => {
    setActiveEdit(initActiveEdit);

    if (!activeEdit.value && activeEdit.isNew) {
      dispatch(removeSubject({ subjectId: activeEdit.id }));

      setSubjects((prevSubjects) => {
        return prevSubjects.filter((prevSubject) => prevSubject.id !== activeEdit.id);
      });
      return null;
    }

    const payloadCategories = template.categories.map((category) => {
      if (category.id === activeEdit.id) {
        return { ...category, title: activeEdit.value };
      }
      return category;
    });

    setSubjects(payloadCategories);

    const payload = {
      categories: [
        {
          id: activeEdit.id,
          title: activeEdit.value,
        },
      ],
    };
    await dispatch(saveTemplate(payload)).unwrap();
    return {};
  }, [activeEdit]);

  const onSearch = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    setSubjects(template.categories.filter((_subject) => _subject.title.includes(value)));
  };

  const onCancelSearch = () => {
    setSearchValue('');

    setSubjects(template.categories);
  };

  const onPressEnter = (event) => {
    if (!isKeyCodeAnEnterCode(event)) return null;

    return onBlurInputTitle();
  };

  const reorderSubjects = useCallback((dragIndex, hoverIndex) => {
    setSubjects((prevSubjects) =>
      update(prevSubjects, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevSubjects[dragIndex]],
        ],
      }),
    );
  }, []);

  const saveReorderedSubjects = async () => {
    const payload = {
      categories: subjects.map(({ id }, index) => ({
        id,
        position: index,
        controls: [],
      })),
    };

    await dispatch(saveTemplate(payload)).unwrap();
  };

  const className = classNames('editor-subject-list__subjects', {
    'editor-subject-list__subjects--is-subject-dragging': isDragging,
  });

  return (
    <div className="editor-subject-list">
      <div className="editor-subject-list__header">
        <div className="editor-subject-list__header-title">
          <span>
            <span className="editor-subject-list__header-title-text">
              {translate(messages.subjects)}
            </span>
            {` (${!isEmpty(template) ? template?.categories?.length : 0})`}
          </span>

          <div className="editor-subject-list__header-search">
            <SearchExpandable value={searchValue} onCancel={onCancelSearch} onChange={onSearch} />
          </div>
        </div>
        <div className="editor-subject-list__subjects-header">
          <div className="editor-subject-list__subjects-header-cell editor-subject-list__subjects-header-cell--name">
            {translate(messages.name)}
          </div>
        </div>
      </div>
      <div className={className}>
        {subjects?.map(({ id, title, weight, app }, index) => {
          return (
            <ListItem
              key={id}
              activeEdit={activeEdit}
              id={id}
              index={index}
              selectedSubjectId={selectedSubjectId}
              title={title}
              weight={weight}
              subjectOnClick={subjectOnClick}
              onDoubleClickTitle={onDoubleClickTitle}
              onChangeInputTitle={onChangeInputTitle}
              onBlurInputTitle={onBlurInputTitle}
              onPressEnter={onPressEnter}
              reorderSubjects={reorderSubjects}
              saveReorderedSubjects={saveReorderedSubjects}
              disabled={isRestrictedBoSoViewerRole || Boolean(app)}
              onReorderChange={(isDragging) => {
                setIsDragging(isDragging);
              }}
            />
          );
        })}
      </div>
      <div className="editor-subject-list__add-subject-button">
        <SecondaryButton
          data-test="subject-list-add-subject-button"
          className="editor-subject-list__add-subject-button"
          disabled={isRestrictedBoSoViewerRole}
          onClick={addSubjectOnClick}
          iconComponent={<Icon icon="plus" />}
          noBorder
        >
          {translate(messages.addSubject)}
        </SecondaryButton>
      </div>
    </div>
  );
};

export default SubjectList;
