export const messages = {
  okText: {
    key: 'InfoModal.okText',
    defaultMessage: 'Ok, got it',
  },
};

export default {
  prefix: 'InfoModal',
  messages,
};
