import React, { useState } from 'react';
import { Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { translate } from 'utils/index';
import { saveFinding } from 'api/finding';

import { selectFinding } from './reducers';
import { selectFinding as selectorsSelectFinding, selectPreviousFinding } from './selectors';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import { TrashIcon } from 'components/Icons';

import './removeFinding.scss';

const RemoveFinding = () => {
  const selectedFinding = useSelector(selectorsSelectFinding);
  const previousFinding = useSelector(selectPreviousFinding);
  const dispatch = useDispatch();
  const [popupOpened, setPopupOpened] = useState(false);
  const navigate = useNavigate();
  const { isRestrictedBoSoViewerRole } = useRole();

  const onRemove = () => {
    const payload = {
      control: selectedFinding.control,
      finding: {
        id: selectedFinding.id,
      },
    };
    setPopupOpened(false);
    dispatch(saveFinding(payload));

    if (previousFinding) {
      dispatch(selectFinding(previousFinding.id));
    }

    if (previousFinding === null) {
      navigate(-1);
    }
  };
  return (
    <div>
      <Tooltip title={translate(messages.removeFinding)}>
        <Button
          className="assessment-finalized-remove-finding"
          disabled={isRestrictedBoSoViewerRole}
          type="link"
          onClick={() => setPopupOpened(true)}
        >
          <TrashIcon />
        </Button>
      </Tooltip>
      <RemoveConfirmationModal
        onCancel={() => setPopupOpened(false)}
        onOk={onRemove}
        open={popupOpened}
        text={translate(messages.removeFindingPopupText)}
        headerText={translate(messages.removeFindingPopupHeaderText)}
      />
    </div>
  );
};

export default RemoveFinding;
