import React from 'react';
import { Button, Tooltip } from 'antd';
import SecondaryButton from 'ui/SecondaryButton';
import { rejectTrustPartner, establishTrustPartner, requestTrustPartner } from 'api/organizations';
import Icon from 'ui/Icon';
import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';
import { OrgStatusType } from './orgStatusType';
import { TrashIcon } from 'components/Icons';

export const renderActionsRowForTable = (refetchOrg, status, email, key) => {
  if (status === OrgStatusType.PENDING) {
    return (
      <div className="trusted-organizations-tab__organization-actions">
        <SecondaryButton
          className="trusted-organizations-tab__approve-button"
          onClick={async () => {
            await establishTrustPartner(key);
            await refetchOrg();
          }}
          size="small"
          icon="check"
          uppercase
          outline
        >
          {translate(messages.orgEditTrustedOrganizationsApprove)}
        </SecondaryButton>
        <SecondaryButton
          className="trusted-organizations-tab__reject-button"
          onClick={async () => {
            await rejectTrustPartner(key);
            await refetchOrg();
          }}
          size="small"
          icon="close"
          uppercase
          outline
        >
          {translate(messages.orgEditTrustedOrganizationsReject)}
        </SecondaryButton>
      </div>
    );
  }
  return (
    <div className="trusted-organizations-tab__organization-actions">
      {status === OrgStatusType.WAITING_APPROVAL && (
        <Tooltip title={translate(messages.orgEditTrustedOrganizationsResend)}>
          <Button
            className="trusted-organizations-tab__action"
            type="link"
            onClick={async () => {
              await requestTrustPartner(email);
              await refetchOrg();
            }}
          >
            <Icon icon="back" />
          </Button>
        </Tooltip>
      )}
      {status !== OrgStatusType.REJECTED && (
        <Tooltip title={translate(messages.orgEditTrustedOrganizationsRemove)}>
          <Button
            className="trusted-organizations-tab__action"
            type="link"
            onClick={async () => {
              await rejectTrustPartner(key);
              await refetchOrg();
            }}
          >
            <TrashIcon />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
