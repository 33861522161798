import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

export const locales = {
  bg: 'bg',
  en: 'en',
  he: 'he',
  it: 'it',
  ja: 'ja',
  pl: 'pl',
};

/* eslint-disable no-console */
const loadTranslationFiles = async () => {
  const messages = {};
  const prefixes = [];
  const defaultPrefix = 'findings';

  const context = await require.context('..', true, /messages\.js$/);
  const files = context.keys().slice(context.keys().length / 2);

  files.forEach((path) => {
    const {
      default: { messages: defaultMessages, prefix },
    } = context(path) || {};

    if (!prefix) console.error(`i18n | Prefix in path: ${path} is not defined.`);

    if (prefixes.includes(prefix)) console.error(`i18n | Prefix "${prefix}" is already in use.`);

    prefixes.push(prefix);

    Object.entries(defaultMessages).forEach(([key, value]) => {
      const translationKey = `${prefix || defaultPrefix}.${key}`;
      if (messages[translationKey]) {
        return console.error(`i18n | Key: ${translationKey} is already in use.`);
      }

      messages[translationKey] = value;
      return null;
    });
  });

  return messages;
};

const initializeTranslations = async () => {
  const allAppTranslations = await loadTranslationFiles();

  i18next.use(initReactI18next).init({
    resources: {
      en: allAppTranslations,
      // TODO: Use extractor and import: pl, he, bg, jp, it locales
    },
    lng: locales.en,
    fallbackLng: locales.en,
    interpolation: { escapeValue: false },
  });
};

export default initializeTranslations;
