import React from 'react';
import PropTypes from 'prop-types';

import deleteImage from 'assets/svgs/illustration-for-remove-action.svg';
import Divider from 'ui/Divider';
import Popup from 'ui/Popup/index';

const RemovePopup = ({
  open,
  onClickCancel,
  okButtonText,
  onClickOk,
  mainText,
  additionalInfo,
  ...otherProps
}) => {
  return (
    <Popup.Confirmation
      open={open}
      onClickCancel={onClickCancel}
      okButtonText={okButtonText}
      onClickOk={onClickOk}
      {...otherProps}
    >
      <div className="ui-popup__remove-popup-content">
        <img src={deleteImage} alt="delete-illustration" />
        <Divider hidden />
        <h3>{mainText}</h3>
        {additionalInfo}
      </div>
    </Popup.Confirmation>
  );
};

RemovePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  okButtonText: PropTypes.string,
  onClickOk: PropTypes.func.isRequired,
  mainText: PropTypes.string,
  additionalInfo: PropTypes.string,
};
export default RemovePopup;
