import React from 'react';
import PropTypes from 'prop-types';

const ForwardFileIcon = ({
  color = 'currentColor',
  height = '24',
  width = '24',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M6.25 2C5.01625 2 4 3.01625 4 4.25V19.75C4 20.9838 5.01625 22 6.25 22H17.75C18.9838 22 20 20.9838 20 19.75V9.25C20 9.0511 19.9209 8.86036 19.7803 8.71973L19.7725 8.71191L13.2803 2.21973C13.1396 2.07907 12.9489 2.00004 12.75 2H6.25ZM6.25 3.5H12V7.75C12 8.98375 13.0162 10 14.25 10H18.5V19.75C18.5 20.1733 18.1732 20.5 17.75 20.5H6.25C5.82675 20.5 5.5 20.1733 5.5 19.75V4.25C5.5 3.82675 5.82675 3.5 6.25 3.5ZM13.5 4.56055L17.4395 8.5H14.25C13.8268 8.5 13.5 8.17325 13.5 7.75V4.56055ZM12.7422 11.4922C12.5929 11.4922 12.4471 11.5368 12.3233 11.6202C12.1995 11.7036 12.1034 11.822 12.0473 11.9603C11.9912 12.0986 11.9776 12.2506 12.0084 12.3966C12.0391 12.5427 12.1127 12.6763 12.2197 12.7803L13.9395 14.5H8.25C8.15062 14.4986 8.05194 14.517 7.95972 14.554C7.86749 14.5911 7.78355 14.6461 7.71277 14.7159C7.642 14.7857 7.58579 14.8688 7.54743 14.9605C7.50907 15.0522 7.48932 15.1506 7.48932 15.25C7.48932 15.3494 7.50907 15.4478 7.54743 15.5395C7.58579 15.6312 7.642 15.7143 7.71277 15.7841C7.78355 15.8539 7.86749 15.9089 7.95972 15.946C8.05194 15.983 8.15062 16.0014 8.25 16H13.9395L12.2197 17.7197C12.1477 17.7888 12.0903 17.8716 12.0507 17.9632C12.0111 18.0548 11.9902 18.1534 11.9892 18.2532C11.9882 18.3529 12.0071 18.4519 12.0448 18.5443C12.0825 18.6367 12.1383 18.7206 12.2088 18.7912C12.2794 18.8617 12.3633 18.9175 12.4557 18.9552C12.5481 18.9929 12.6471 19.0118 12.7468 19.0108C12.8466 19.0098 12.9452 18.9889 13.0368 18.9493C13.1284 18.9097 13.2112 18.8523 13.2803 18.7803L16.2803 15.7803C16.4209 15.6396 16.4999 15.4489 16.4999 15.25C16.4999 15.0511 16.4209 14.8604 16.2803 14.7197L13.2803 11.7197C13.2104 11.6477 13.1267 11.5905 13.0343 11.5514C12.9419 11.5123 12.8425 11.4922 12.7422 11.4922Z"
      fill={color}
    />
  </svg>
);

ForwardFileIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ForwardFileIcon;
