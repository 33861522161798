import { isDevelopment, isProduction, isTesting } from 'utils/index';
import { ADMIN_TOKEN, ORG_ID_AUTH_KEY, TOKEN_AUTH_KEY } from 'utils/auth';

export const convertObjectToFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return formData;
};
export const setCookie = (name, value, days) => {
  if (isProduction() && name !== ORG_ID_AUTH_KEY) return;

  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};
export const clearCookie = (name) => {
  if (name === ORG_ID_AUTH_KEY)
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=;`;
  else if (name === ADMIN_TOKEN)
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${window.location.hostname};Secure=true;SameSite=None;`;
  else
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${window.location.hostname};`;
};

export const getCookie = (name) => {
  // Tokens are inaccessible in production by FE
  if (!isDevelopment() && !isTesting() && name === TOKEN_AUTH_KEY) {
    return;
  }
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};
