import { createSelector } from '@reduxjs/toolkit';

export const selectTemplates = createSelector(
  ({ assessmentsTemplates }) => assessmentsTemplates,
  ({ templates }) => templates,
);

export const selectTemplatesOptions = createSelector(selectTemplates, (templates) =>
  templates.map(({ name, id, categoryCount, dueDelta }) => ({
    key: id,
    label: name,
    value: id,
    dueDelta: dueDelta,
    categoryCount,
  })),
);

export const selectTemplatesLoading = createSelector(
  ({ assessmentsTemplates }) => assessmentsTemplates,
  ({ loading, cloneTemplateLoading, removeTemplateLoading }) =>
    loading || cloneTemplateLoading || removeTemplateLoading,
);
