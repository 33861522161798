import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';
import AppResultsCard from 'components/AppsMonitoring/appResultsCard';
import { translate } from 'utils/index';
import { messages } from 'pages/Vendors/VendorManage/Tabs/ContinuousMonitoring/messages';

function RenderAppsView({ currentVendor }) {
  return (
    <div className="continuous-monitoring-tab__apps">
      <div className="continuous-monitoring-tab__apps-title">
        <Icon className="continuous-monitoring-tab__apps-title-icon" icon="apps" />
        {translate(messages.appsMonitoring)}
      </div>
      <div className="continuous-monitoring-tab__apps-list">
        {currentVendor?.continuousMonitoring.map((app) => AppResultsCard({ app, currentVendor }))}
      </div>
    </div>
  );
}

RenderAppsView.propTypes = {
  currentVendor: PropTypes.object,
};

export default RenderAppsView;
