import React from 'react';
import { countries } from 'countries-list';
import { isSupportedCountry } from 'react-phone-number-input';

export const getCountryByPrefix = (value) => {
  return getCountries().find((countryItem) => countryItem.value === value);
};

export const getCountryCodeByPrefix = (value) => {
  const country = getCountryByPrefix(value) || {};
  return country.code;
};

export const getCountries = () => {
  const countriesList = [];

  Object.entries(countries).forEach(([code, { phone, emoji }]) => {
    if (!isSupportedCountry(code)) {
      return;
    }
    if (phone.includes(',')) {
      phone.split(',').forEach((prefix) => {
        countriesList.push({
          emoji,
          code,
          value: Number(prefix),
          label: (
            <span className="prefix-icon-container">
              <span className={`fi fi-${code.toLowerCase()}`}></span> {code} (+{phone})
            </span>
          ),
        });
      });
      return;
    }

    countriesList.push({
      emoji,
      code,
      value: Number(phone),
      label: (
        <span className="prefix-icon-container">
          <span className={`fi fi-${code.toLowerCase()}`}></span>
          {code} (+{phone})
        </span>
      ),
    });
  });

  return countriesList;
};

export const customPhoneInputStyles = (error) => ({
  container: (styles) => {
    return {
      ...styles,
      marginTop: 5,
      marginBottom: 5,
    };
  },
  placeholder: (styles) => ({
    ...styles,
    marginLeft: 10,
    marginRight: 0,
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingRight: 0,
    color: '#333333',
  }),
  control: (styles, { isFocused, menuIsOpen }) => {
    const purpleColor = '#744fc0';
    styles.borderColor = '#e0dede';
    styles['&:hover'].borderColor = purpleColor;

    if (error) {
      styles.borderColor = '#e45647';
    }

    if (menuIsOpen || isFocused) {
      styles.boxShadow = `0 0 0 1px ${purpleColor}`;
      styles.borderColor = purpleColor;
    }

    return {
      ...styles,
      height: 48,
      borderRadius: 4,
      backgroundColor: '#FAFAFA',
      fontWeight: 500,
      minWidth: 120,
    };
  },
});

export const phoneWithoutPrefix = (phone, prefix) => {
  return (phone || '').split(prefix || 0)[1] || '';
};
