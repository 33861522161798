import React from 'react';
import PropTypes from 'prop-types';

const CheckedApprovedIcon = ({
  color = 'currentColor',
  height = '24',
  width = '24',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M12 2C6.48603 2 2 6.48604 2 12C2 17.514 6.48603 22 12 22C17.514 22 22 17.514 22 12C22 6.48604 17.514 2 12 2ZM12 3.5C16.7033 3.5 20.5 7.2967 20.5 12C20.5 16.7033 16.7033 20.5 12 20.5C7.29669 20.5 3.5 16.7033 3.5 12C3.5 7.2967 7.29669 3.5 12 3.5ZM15.7354 8.99316C15.5406 8.99884 15.3556 9.0801 15.2197 9.21973L10.75 13.6895L8.78027 11.7197C8.71116 11.6477 8.62839 11.5903 8.53679 11.5507C8.4452 11.5111 8.34662 11.4902 8.24684 11.4892C8.14706 11.4882 8.04808 11.5071 7.9557 11.5448C7.86332 11.5825 7.77939 11.6383 7.70883 11.7088C7.63828 11.7794 7.58251 11.8633 7.54479 11.9557C7.50707 12.0481 7.48817 12.1471 7.48918 12.2468C7.4902 12.3466 7.51111 12.4452 7.5507 12.5368C7.59028 12.6284 7.64775 12.7112 7.71973 12.7803L10.2197 15.2803C10.3604 15.4209 10.5511 15.4999 10.75 15.4999C10.9489 15.4999 11.1396 15.4209 11.2803 15.2803L16.2803 10.2803C16.3885 10.1749 16.4623 10.0393 16.4922 9.89126C16.5221 9.7432 16.5066 9.58958 16.4477 9.4505C16.3888 9.31141 16.2893 9.19335 16.1622 9.11175C16.0351 9.03015 15.8863 8.98882 15.7354 8.99316Z"
      fill={color}
    />
  </svg>
);

CheckedApprovedIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default CheckedApprovedIcon;
