import React from 'react';
import PropTypes from 'prop-types';

const EvidenceIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M10.25 1.5C9.1017 1.5 8.15285 2.38331 8.02539 3.5H6.25C5.01625 3.5 4 4.51625 4 5.75V19.25C4 20.4838 5.01625 21.5 6.25 21.5H17.75C18.9838 21.5 20 20.4838 20 19.25V5.75C20 4.51625 18.9838 3.5 17.75 3.5H15.9746C15.8471 2.38331 14.8983 1.5 13.75 1.5H10.25ZM10.25 3H13.75C14.1732 3 14.5 3.32675 14.5 3.75C14.5 4.17325 14.1732 4.5 13.75 4.5H10.25C9.82675 4.5 9.5 4.17325 9.5 3.75C9.5 3.32675 9.82675 3 10.25 3ZM6.25 5H8.38477C8.79062 5.60095 9.47736 6 10.25 6H13.75C14.5226 6 15.2094 5.60095 15.6152 5H17.75C18.1732 5 18.5 5.32675 18.5 5.75V19.25C18.5 19.6733 18.1732 20 17.75 20H6.25C5.82675 20 5.5 19.6733 5.5 19.25V5.75C5.5 5.32675 5.82675 5 6.25 5ZM12 8C9.5145 8 7.5 10.0145 7.5 12.5C7.5 14.9855 9.5145 17 12 17C14.4855 17 16.5 14.9855 16.5 12.5C16.5 10.0145 14.4855 8 12 8ZM14.5 10.5C14.6279 10.5 14.7558 10.5487 14.8535 10.6465C15.049 10.842 15.049 11.158 14.8535 11.3535L11.6035 14.6035C11.506 14.701 11.378 14.75 11.25 14.75C11.122 14.75 10.994 14.701 10.8965 14.6035L9.14648 12.8535C8.95098 12.658 8.95098 12.342 9.14648 12.1465C9.34198 11.951 9.65802 11.951 9.85352 12.1465L11.25 13.543L14.1465 10.6465C14.2442 10.5487 14.3721 10.5 14.5 10.5Z"
      fill={color}
    />
  </svg>
);

EvidenceIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default EvidenceIcon;
