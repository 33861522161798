import axios from 'axios';
import { GetProfileSummaryCustomers, GetProfileSummarySelf } from 'api/endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getSummaryCustomersRequest = async (args, { getState }) => {
  const { app } = getState();
  const url = app.isMyVendorsTabSelected
    ? GetProfileSummaryCustomers.url
    : GetProfileSummarySelf.url;
  return axios.post(url);
};

export const getSummaryCustomers = createAsyncThunk(
  'getSummaryCustomers',
  getSummaryCustomersRequest,
);
