import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'utils/index';
import { messages } from './messages';

import Input from './input';

const Password = ({ onIconClick, label, placeholder, ...restProps }) => {
  const [visible, setVisible] = useState();

  const onClick = () => {
    setVisible(!visible);
    return onIconClick && onIconClick();
  };

  const type = visible ? 'text' : 'password';

  return (
    <Input
      {...restProps}
      label={label || translate(messages.password)}
      placeholder={placeholder || translate(messages.password)}
      afterIcon="eye"
      onIconClick={onClick}
      type={type}
    />
  );
};

Password.propTypes = {
  onIconClick: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Password;
