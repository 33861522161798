import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { translate } from 'utils/index';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { fillCategoriesByEmpty } from 'pages/Editor/subjects/lib';

import { messages } from 'pages/Editor/messages';
import { selectTemplate } from 'pages/Editor/selectors';
import Subject from './subject';
import AddSubject from './addSubject';
import { useRole } from 'hooks/useRole';
import './index.scss';

const Subjects = () => {
  const { categories = [] } = useSelector(selectTemplate);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const [activeSubject, setActiveSubject] = useState('');
  const { isRestrictedBoSoViewerRole } = useRole();

  const currentCategories = useMemo(
    () => fillCategoriesByEmpty(categories.filter((category) => !category.isPlaceholder)),
    [categories],
  );
  return (
    <div
      className={`editor-subjects ${!isMyVendorsTabSelected && 'editor-subjects--customer-view'}`}
    >
      <div className="editor-subjects__title">{translate(messages.subjectList)}:</div>
      <div className="editor-subjects__grid">
        {categories.length > 0 && (
          <div className="editor-subjects__header">
            <div className="editor-subjects__header-cell editor-subjects__header-cell--name">
              {translate(messages.name)}
            </div>
            {isMyVendorsTabSelected && (
              <>
                <div className="editor-subjects__header-cell editor-subjects__header-cell--weight">
                  {translate(messages.weight)}
                </div>
                <div className="editor-subjects__header-cell editor-subjects__header-cell--benchmark">
                  {translate(messages.benchmark)}
                </div>
              </>
            )}
            <div className="editor-subjects__header-cell editor-subjects__header-cell--questions">
              {translate(messages.questions)}
            </div>
            {isMyVendorsTabSelected && (
              <div className="editor-subjects__header-cell editor-subjects__header-cell--visible">
                {translate(messages.visible)}
              </div>
            )}
            <div className="editor-subjects__header-cell editor-subjects__header-cell--actions" />
          </div>
        )}
        {currentCategories.map((category, index) => (
          <Subject
            key={category.id}
            category={category}
            id={category.id}
            activeSubject={activeSubject}
            setActiveSubject={setActiveSubject}
            dataIndex={index}
            disabled={isRestrictedBoSoViewerRole}
          />
        ))}
      </div>
      <AddSubject setActiveSubject={setActiveSubject} disabled={isRestrictedBoSoViewerRole} />
    </div>
  );
};

export default Subjects;
