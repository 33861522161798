import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';
import { messages } from './messages';

import './index.scss';

const ArchiveToggle = ({ fetchData, value, onChange }) => {
  const onSelect = (event) => {
    onChange(event.target.value);
    if (fetchData) {
      fetchData();
    }
  };

  return (
    <Radio.Group
      className="archive-toggle"
      value={value}
      onChange={onSelect}
      data-test="archive-radio-group"
    >
      <Radio.Button value={0} data-test="archive-radio-active">
        {translate(messages.active)}
      </Radio.Button>
      <Radio.Button value={1} data-test="archive-radio-archived">
        {translate(messages.archived)}
      </Radio.Button>
    </Radio.Group>
  );
};

ArchiveToggle.propTypes = {
  fetchData: PropTypes.func,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default ArchiveToggle;
