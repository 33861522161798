import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { generatePath, Navigate, Routes } from 'react-router';

import ROUTES from 'constants/routes';
import { DEFAULT_ORGANIZATION_INDEX, getOrganizationIdFromEmailUrl } from 'utils/organizations';

import NotFound from 'pages/NotFound';
import OldRoutesPreloader from 'components/OldRoutesPreloader';
import EmailLinksRedirect from 'components/EmailLinksRedirect';

import { OLD_ROUTES } from './oldRoutes';
import { PUBLIC_ROUTES } from './publicRoutes';
import { PRIVATE_ROUTES } from './privateRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from 'pages/Profile/selectors';
import NotSupportedOnMobile from 'pages/NotSupportedOnMobile';
import PrivateElement from 'components/PrivateElement';
import { setTranslations } from 'utils/languages';

const ALL_ROUTES = {
  ...OLD_ROUTES,
  ...PUBLIC_ROUTES,
  ...PRIVATE_ROUTES,
};

const App = () => {
  const dispatch = useDispatch();
  const [isTranslationsLoaded, setIsTranslationsLoaded] = useState(false);
  const {
    user: { lang },
  } = useSelector(selectProfile);

  useEffect(() => {
    if (lang !== undefined && !isTranslationsLoaded) {
      setTranslations(lang, dispatch, () => setIsTranslationsLoaded(true));
    }
  }, [lang]);

  if (/Android|iPhone/i.test(navigator.userAgent)) {
    return <NotSupportedOnMobile />;
  }

  if (window.location.search.includes('ret') && !window.location.pathname.includes('login')) {
    window.location.replace(`${ROUTES.LOGIN}${window.location.search}`);
  }
  if (window.location.hash) {
    return <OldRoutesPreloader />;
  }

  if (getOrganizationIdFromEmailUrl()) {
    return (
      <PrivateElement>
        <EmailLinksRedirect />
      </PrivateElement>
    );
  }

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Navigate
            to={generatePath(ROUTES.VENDOR_RECENT, {
              organization: DEFAULT_ORGANIZATION_INDEX,
            })}
          />
        }
      />
      {Object.entries(ALL_ROUTES).map(([, route]) => (
        <Route key={route.path} exact path={route.path} element={route.element} />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default App;
