export const messages = {
  answerRepository: {
    key: 'AnswerRepository.answerRepository',
    defaultMessage: 'ANSWER REPOSITORY',
  },
  results: {
    key: 'AnswerRepository.results',
    defaultMessage: 'Results',
  },
  select: {
    key: 'AnswerRepository.select',
    defaultMessage: 'SELECT',
  },
  cancel: {
    key: 'AnswerRepository.cancel',
    defaultMessage: 'CANCEL',
  },
  emptyContentAnswerSubtitle: {
    key: 'AnswerRepository.emptyContentAnswerSubtitle',
    defaultMessage: 'Please select a question',
  },
  noAnswerWasFound: {
    key: 'AnswerRepository.noAnswerWasFound',
    defaultMessage: 'No answer was found',
  },
  emptyContentControlsSubtitle: {
    key: 'AnswerRepository.emptyContentControlsSubtitle',
    defaultMessage: 'Please select an category  or change search value',
  },
  hideIncompatible: {
    key: 'AnswerRepository.hideIncompatible',
    defaultMessage: 'Hide incompatible answer types',
  },
};

export default {
  prefix: 'AnswerRepository',
  messages,
};
