import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showSentAssessmentPopup: false,
  sentAssessmentCustomers: '',
};

const reducers = createSlice({
  name: 'assignAssessment',
  initialState,
  reducers: {
    setShowSentAssessmentPopup: (state, { payload }) => {
      state.showSentAssessmentPopup = payload;
    },
    setSentAssessmentCustomers: (state, { payload }) => {
      state.sentAssessmentCustomers = payload;
    },
  },
});

export const { setSentAssessmentCustomers, setShowSentAssessmentPopup } = reducers.actions;

export default reducers;
