import React from 'react';
import { translate } from 'utils/index';
import { messages } from './messages';
import Input from './input';

const Email = (props) => {
  return (
    <Input
      type="email"
      name="email"
      label={translate(messages.emailLabel)}
      placeholder={translate(messages.emailPlaceholder)}
      {...props}
    />
  );
};

export default Email;
