import { triggerControls } from 'pages/Assessment/lib';
import { flatControls } from 'utils/controls';

export function triggerCategoriesControls(control, categories) {
  const allControls = flatControls(categories);
  const controlsWithTriggers = triggerControls(control, allControls);
  return groupControlsByCategory([...controlsWithTriggers, control]);
}

export function triggerControlsForCategories(controls, categories) {
  const allControls = flatControls(categories);

  const controlsWithTriggers = controls.flatMap((control) => {
    return triggerControls(control, allControls);
  });

  return groupControlsByCategory([...controls, ...controlsWithTriggers]);
}

export function groupControlsByCategory(controls) {
  return controls.reduce((acc, { categoryId, ...control }) => {
    const categoryIndex = acc.findIndex((category) => category.id === categoryId);

    if (categoryIndex === -1) {
      acc.push({ id: categoryId, controls: [control] });
    } else {
      acc[categoryIndex].controls.push(control);
    }

    return acc;
  }, []);
}

export const getAllCategoryTriggers = (controls, controlTriggers = {}) => {
  const triggers = Object.entries(controlTriggers).flatMap(([, triggers]) => triggers);

  const nextTriggers = triggers.flatMap((trigger) => {
    const control = controls.find(({ id }) => id === trigger);
    if (!control) {
      return [];
    }
    return getAllCategoryTriggers(controls, control.triggers);
  });

  if (nextTriggers.length) {
    return triggers.concat(nextTriggers);
  }

  return triggers;
};
