import React, { useEffect, useMemo } from 'react';
import { Tabs as AntTabs } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './tabs.scss';

const Tabs = ({
  className,
  rounded,
  isOnClickTabDisabled,
  inner,
  children,
  type,
  hideShadowForContent,
  centeredTabs,
  ...rest
}) => {
  const tabsClassNames = classNames('ui-tabs', className, {
    'ui-tabs--rounded': rounded,
    'ui-tabs--inner': inner,
    'ui-tabs--disabled-tab-on-click': isOnClickTabDisabled,
    'ui-tabs--for-overview-page': hideShadowForContent,
    'ui-tabs--centered': centeredTabs,
  });

  useEffect(() => {
    setTimeout(() => {
      const container = document.querySelector('.ant-tabs-nav-list');
      const tabs = container
        ? Array.from(container.children).filter((tab) => tab.classList.contains('ant-tabs-tab'))
        : [];

      children.forEach((child, index) => {
        const tab = tabs[index];
        if (tab) {
          tab.setAttribute('data-test', `tabs-${child?.props?.['data-test']}`);
        }
      });
    }, []);
  }, [children]);

  const tabItems = useMemo(() => {
    return children.reduce((acc, child) => {
      if (!child) return acc;
      return acc.concat({
        key: child?.key,
        label: child?.props?.tab,
        className: child?.props?.className,
        children: child?.props?.children,
        disabled: child?.props?.disabled || false,
      });
    }, []);
  }, [children]);

  return (
    <div className={tabsClassNames}>
      <AntTabs type={type || ''} items={tabItems} {...rest} />
    </div>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  rounded: PropTypes.bool,
  isOnClickTabDisabled: PropTypes.bool,
  inner: PropTypes.bool,
  type: PropTypes.string,
  hideShadowForContent: PropTypes.bool,
  centeredTabs: PropTypes.bool,
};

export default Tabs;
