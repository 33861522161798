import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { getCustomersGroups } from 'api/customersGroup';

import { validPayload } from 'pages/Customers/CustomerManage/lib';
import { addProfileCustomer, setProfileCustomer } from 'api/customers';
import { API_STATUS } from 'constants/api';
import { translate } from 'utils/index';
import ROUTES from 'constants/routes';
import { useAppNavigate } from 'hooks/navigation';
import { selectProfile } from 'pages/Profile/selectors';
import { NOTIFICATION_DURATION } from 'constants/general';
import { selectGroups } from 'components/AssessmentsFilters/selectors';
import { messages } from 'pages/Customers/CustomerManage/messages';
import { triggerCategoriesControls } from 'utils/triggers';
import {
  mixpanelTrackAssignedAssessmentFromVendorOrCustomer,
  mixpanelTrackVendorOrCustomer,
} from 'utils/mixpanel';
import { MixpanelAssignAssessmentSource } from 'constants/mixpanel';
import { useIsVendorDirty } from 'hooks/useIsVendorDirty';
import { saveControl } from 'api/assessment';

const CONTACT_INFORMATION_TAB_KEY = 'Contact Information';

export const CustomerManageContext = createContext({});

const initFormContact = (currentCustomer) => ({
  corporateName: currentCustomer?.profileCorporateName || '',
  companyContact: currentCustomer?.profileFullname || '',
  email: currentCustomer?.profileEmail || '',
  phone: currentCustomer?.profilePhone === 'undefined' ? '' : currentCustomer?.profilePhone,
  groups: currentCustomer?.profileRelationGroups,
});

export const CustomerManageContextProvider = ({ children }) => {
  const { customerId } = useParams();
  const appNavigate = useAppNavigate();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    user: { _assessmentJson: assessmentJson },
  } = useSelector(selectProfile);
  const { currentCustomer } = useSelector((state) => state.customers);
  const groups = useSelector(selectGroups);

  const [currentTab, setCurrentTab] = useState(CONTACT_INFORMATION_TAB_KEY);
  const [contactFormErrors, setContactFormErrors] = useState(undefined);
  const [isAddingAnalysis, setIsAddingAnalysis] = useState(false);
  const [isRemovingAnalysis, setIsRemovingAnalysis] = useState(false);
  const [customerContactData, setCustomerContactData] = useState(() =>
    initFormContact(currentCustomer),
  );
  const customerAssetsDataDefault = {
    assessments: '',
    assessmentsGroups: '',
    'assessments-period': null,
    apps: '',
  };
  const [customerAssetsData, setCustomerAssetsData] = useState(customerAssetsDataDefault);
  const customerAssessmentsDataDefault = currentCustomer?._assessmentJson?.length
    ? currentCustomer?._assessmentJson
    : [assessmentJson]; // eslint-disable-line no-underscore-dangle
  const [customerAssessmentsData, setCustomerAssessmentsData] = useState(
    customerAssessmentsDataDefault,
  );
  const isContactFormDirty = useRef(customerContactData?.corporateName);

  const handleTabChange = (activeKey) => {
    setCurrentTab(activeKey);
  };

  useEffect(() => {
    setCustomerContactData(initFormContact(currentCustomer));
    setCustomerAssessmentsData(customerAssessmentsDataDefault);
    isContactFormDirty.current = false;
  }, [currentCustomer, assessmentJson]);

  const [isCustomerDirty] = useIsVendorDirty(
    currentCustomer,
    customerContactData,
    customerAssessmentsData,
    customerAssetsData,
    customerAssessmentsDataDefault,
    customerAssetsDataDefault,
    initFormContact,
  );

  useEffect(() => {
    if (groups?.length === 0) {
      dispatch(getCustomersGroups());
    }
  }, []);

  const updateCustomer = async (isAddingTab) => {
    let response;
    const currentError = await validPayload(customerContactData);
    if (Object.keys(currentError)?.length > 0) {
      setContactFormErrors(currentError);
      handleTabChange(CONTACT_INFORMATION_TAB_KEY);
      return null;
    }

    const payload = {
      ...customerContactData,
      ...customerAssetsData,
      assessmentJson: customerId ? customerAssessmentsData : customerAssessmentsData[0],
    };

    if (isAddingTab) {
      setIsAddingAnalysis(true);
      const {
        payload: {
          vendor: { _assessmentJson: assessmentJsonFromBE },
        },
      } = await dispatch(
        setProfileCustomer({
          ...payload,
          assessmentJson: [...payload.assessmentJson, assessmentJson],
          customerId,
        }),
      );
      setIsAddingAnalysis(false);
      return assessmentJsonFromBE?.length === 0 ? assessmentJson.id : assessmentJsonFromBE[0]?.id;
    }

    if (!customerId) {
      response = await dispatch(addProfileCustomer(payload));
      mixpanelTrackVendorOrCustomer(
        {
          ...payload,
          impactAnalysisInfoFilled:
            JSON.stringify(assessmentJson) === JSON.stringify(payload.assessmentJson),
          relation: response?.payload?.profile_customer,
        },
        false,
      );
    } else {
      response = await dispatch(
        setProfileCustomer({
          ...payload,
          customerId,
        }),
      );
    }

    mixpanelTrackAssignedAssessmentFromVendorOrCustomer(
      MixpanelAssignAssessmentSource.NewCustomer,
      {
        ...payload,
        relation: response?.payload?.profile_customer,
      },
      'upstream',
    );

    const { status } = response.payload;
    if (status === API_STATUS.FAILED || !status) return null;

    notification.success({
      message: translate(
        customerId ? messages.customerHasBeenUpdated : messages.customerHasBeenAdded,
      ),
      duration: NOTIFICATION_DURATION.TWO_SECONDS,
    });

    if (location.state?.from === ROUTES.CUSTOMER_SHOWCASES_ASSIGN_SHOWCASE) {
      return appNavigate(ROUTES.CUSTOMER_SHOWCASES_ASSIGN_SHOWCASE, null, {
        state: {
          customerId: response.payload.profile_customer,
          templates: location.state?.templates,
        },
      });
    }

    return navigate(-1);
  };

  const onChangeContactFrom = (value, name) => {
    isContactFormDirty.current = true;
    setCustomerContactData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const onChangeAssessment = (values, assessmentId, newControlId) => {
    if (!customerAssessmentsData?.length) {
      return null;
    }
    const currentAssessment = [...customerAssessmentsData].find(
      (assessment) => assessment.id === assessmentId,
    );

    const categoriesWithControls = triggerCategoriesControls(
      values.find((control) => control.id === newControlId),
      currentAssessment?.categories,
    );
    const allTriggeredControls = categoriesWithControls.flatMap(({ controls }) => controls);

    setCustomerAssessmentsData((prev) => {
      return prev.map((assessment) => {
        if (assessment.id === assessmentId) {
          return {
            ...assessment,
            categories: assessment.categories.map((assessmentCategory) => {
              return {
                ...assessmentCategory,
                controls: assessmentCategory.controls.map((control) => {
                  const currentUpdatedControl =
                    allTriggeredControls.find(
                      (currentControl) => currentControl.id === control.id,
                    ) || {};
                  return {
                    ...control,
                    ...currentUpdatedControl,
                  };
                }, []),
              };
            }),
          };
        }
        return assessment;
      });
    });

    if (customerId) {
      dispatch(
        saveControl({
          categoryId: values[0].categoryId,
          assessmentId,
          control: { answer: values[0].answer, id: newControlId },
        }),
      );
    }
    return null;
  };

  const onChangeAssets = ({ checkedAssessmentRequired, checkedAssessmentPeriod }) => {
    setCustomerAssetsData({
      assessments: checkedAssessmentRequired.join(','),
      assessmentsGroups: [],
      'assessments-period':
        typeof checkedAssessmentPeriod?.[0] === 'number' ? checkedAssessmentPeriod?.[0] : null,
      apps: '',
    });
  };

  const onChangeTitle = async (value, id) => {
    const newAssessmentJson = customerId ? customerAssessmentsData : customerAssessmentsData[0];

    await dispatch(
      setProfileCustomer({
        assessmentJson: newAssessmentJson.map((assessment) => {
          if (assessment.id === id) {
            return {
              ...assessment,
              title: value,
            };
          }
          return assessmentJson;
        }),
        customerId,
        ...customerContactData,
        ...customerAssetsData,
      }),
    );
  };

  const onDeleteCategory = async (id) => {
    setIsRemovingAnalysis(true);
    const newAssessmentJson = customerId ? customerAssessmentsData : customerAssessmentsData[0];

    await dispatch(
      setProfileCustomer({
        assessmentJson: newAssessmentJson.filter((assessment) => assessment.id !== id),
        customerId,
        ...customerContactData,
        ...customerAssetsData,
      }),
    );
    setIsRemovingAnalysis(false);
  };

  const value = useMemo(
    () => ({
      currentCustomer,

      customerContactData,
      contactFormErrors,
      isContactFormDirty,
      onChangeContactFrom,

      customerAssessmentsData,
      customerAssetsData,
      updateCustomer,
      onChangeAssessment,
      onChangeAssets,
      handleTabChange,
      onChangeTitle,
      onDeleteCategory,
      currentTab,
      groups,
      isAddingAnalysis,
      isRemovingAnalysis,
      isCustomerDirty,
    }),
    [
      currentCustomer,
      currentTab,
      customerContactData,
      customerAssessmentsData,
      customerAssetsData,
      contactFormErrors,
      assessmentJson,
      groups,
      onChangeAssessment,
      onChangeContactFrom,
      onChangeAssets,
      updateCustomer,
      onChangeTitle,
      onDeleteCategory,
      isAddingAnalysis,
      isRemovingAnalysis,
      isCustomerDirty,
    ],
  );

  return <CustomerManageContext.Provider value={value}>{children}</CustomerManageContext.Provider>;
};

CustomerManageContextProvider.propTypes = {
  children: PropTypes.node,
};
