import React from 'react';
import PropTypes from 'prop-types';

const KebabIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M6.56866 10C6.03823 10 5.52952 10.2107 5.15445 10.5858C4.77938 10.9609 4.56866 11.4696 4.56866 12C4.56866 12.5304 4.77938 13.0391 5.15445 13.4142C5.52952 13.7893 6.03823 14 6.56866 14C7.0991 14 7.60781 13.7893 7.98288 13.4142C8.35795 13.0391 8.56866 12.5304 8.56866 12C8.56866 11.4696 8.35795 10.9609 7.98288 10.5858C7.60781 10.2107 7.0991 10 6.56866 10ZM12.5687 10C12.0382 10 11.5295 10.2107 11.1545 10.5858C10.7794 10.9609 10.5687 11.4696 10.5687 12C10.5687 12.5304 10.7794 13.0391 11.1545 13.4142C11.5295 13.7893 12.0382 14 12.5687 14C13.0991 14 13.6078 13.7893 13.9829 13.4142C14.358 13.0391 14.5687 12.5304 14.5687 12C14.5687 11.4696 14.358 10.9609 13.9829 10.5858C13.6078 10.2107 13.0991 10 12.5687 10ZM18.5687 10C18.0382 10 17.5295 10.2107 17.1545 10.5858C16.7794 10.9609 16.5687 11.4696 16.5687 12C16.5687 12.5304 16.7794 13.0391 17.1545 13.4142C17.5295 13.7893 18.0382 14 18.5687 14C19.0991 14 19.6078 13.7893 19.9829 13.4142C20.358 13.0391 20.5687 12.5304 20.5687 12C20.5687 11.4696 20.358 10.9609 19.9829 10.5858C19.6078 10.2107 19.0991 10 18.5687 10Z"
      fill={color}
    />
  </svg>
);

KebabIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default KebabIcon;
