import React from 'react';
import PropTypes from 'prop-types';
import './error.scss';

const Error = ({ error }) => {
  if (!error) return null;

  return <span className="ui-form-error">{error}</span>;
};

Error.propTypes = {
  error: PropTypes.string,
};

export default Error;
