import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetEvidences,
  GetRecentAssessments,
  GetRecentCustomers,
  GetRecentFindings,
  GetRecentVendors,
} from 'api/endpoints';

// eslint-disable-next-line default-param-last
const getRecentVendorsRequest = async (args = {}, { getState }) => {
  const {
    app,
    recent: { displayAmount },
  } = getState();
  const url = app.isMyVendorsTabSelected ? GetRecentVendors.url : GetRecentCustomers.url;

  const payload = {
    amount: displayAmount,
    ...args,
  };

  if (app.isMyVendorsTabSelected) {
    payload.column = args?.column || '';
    payload.descending = args?.descending;
  }

  return axios.post(url, payload);
};

export const getRecentVendors = createAsyncThunk('getRecentVendors', getRecentVendorsRequest);

// eslint-disable-next-line default-param-last
const getRecentAssessmentsRequest = async (args = {}, { getState }) => {
  const {
    app,
    recent: { displayAmount },
  } = getState();

  const payload = {
    descending: true,
    amount: displayAmount,
    upstream: !app.isMyVendorsTabSelected ? 1 : 0,
    ...args,
  };
  return axios.post(GetRecentAssessments.url, payload);
};

export const getRecentAssessments = createAsyncThunk(
  'getRecentAssessments',
  getRecentAssessmentsRequest,
);

// eslint-disable-next-line default-param-last
const getRecentEvidencesRequest = async (args = {}, { getState }) => {
  const {
    app,
    recent: { displayAmount },
  } = getState();

  const params = {
    descending: true,
    count: displayAmount,
    upstream: !app.isMyVendorsTabSelected,
    ...args,
  };
  return axios.get(GetEvidences.url, { params });
};

export const getRecentEvidences = createAsyncThunk('getRecentEvidences', getRecentEvidencesRequest);

// eslint-disable-next-line default-param-last
const getRecentFindingsRequest = async (args = {}, { getState }) => {
  const {
    app,
    recent: { displayAmount },
  } = getState();

  const payload = {
    amount: displayAmount,
    upstream: !app.isMyVendorsTabSelected ? 1 : 0,
    ...args,
  };
  return axios.post(GetRecentFindings.url, payload);
};

export const getRecentFindings = createAsyncThunk('getRecentFindings', getRecentFindingsRequest);
