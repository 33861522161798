import React from 'react';

const LoadMorePopupContent = () => (
  <div>
    <br />
    <strong>Trusted Organizations</strong> - allows organizations to get extra indicators about
    their 3rd party vendors. Without disclosing any sensitive (or even company-specific)
    information, we generate additional “trust indicators” for your vendors, to help with decision
    making, from organizations you listed you can trust (either because you know the team or their
    business and interactions are similar to yours).
    <br />
    <br />
    These “trust indicators” are non-specific aggregative score a vendor received across all trusted
    organizations. This might provide an indication towards the vendors standing with other
    like-minded organizations. It is important to emphasis that in order to safeguard your data and
    information, we only use meta-data to generate this “trust indicator”, meaning no other user
    could infer which assessments you sent the vendor or how many, what do your assessments include,
    nor the type of business interaction you have with that vendor. Here comes that consent part -
    after receiving a request from another organization, you have full control over what happens
    next:
    <br />
    <br />
    Approving the request will list the requester as your trusted organization, meaning you’ll
    receive “trust indicators” for your vendors from it (and others you’d add). You can at any time
    remove any organization from your trusted organizations, with a simple click.Declining the
    request will remove it from your Findings account - as easy as that.
  </div>
);

export default LoadMorePopupContent;
