import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import { calculateControlKey } from 'pages/Assessment/lib';
import { stripHtml } from 'utils/html';
import { saveTemplate } from 'api/editor';
import { translate } from 'utils/index';
import { allowFindings, allowTriggers, getAnswerType } from 'utils/controls';
import AnswerRepository from 'components/AnswerRepository';
import EditableContent from 'ui/EditableContent';
import SecondaryButton from 'ui/SecondaryButton';
import Divider from 'ui/Divider';
import AnswerPreview from './answerPreview';
import RequiredSwitcher from './requiredSwitcher';
import VisibleSwitcher from './visibleSwitcher';
import Triggers from './triggers';

import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { messages } from 'pages/Editor/messages';
import {
  selectAnswerTypes,
  selectSaveLoading,
  selectSubjectKey,
  selectTemplate,
} from 'pages/Editor/selectors';
import {
  EditIcon,
  FindingIcon,
  TrashIcon,
  FindingFilledIcon,
  FollowUpControlIcon,
} from 'components/Icons';

import './activeQuestion.scss';
import Loader from 'ui/Loader';

const ActiveQuestion = ({
  categoryId,
  control,
  index,
  setEditAnswers,
  setFindings,
  defaultShowTriggers,
  onRemoveQuestion,
  openVisibleSwitcher,
  handleSetVisibleSwitcherOpen,
}) => {
  const subjectKey = useSelector(selectSubjectKey);
  const loading = useSelector(selectSaveLoading);
  const answerTypes = useSelector(selectAnswerTypes);
  const { answerTypesLoading } = useSelector((state) => state.editor);
  const dispatch = useDispatch();
  const [values, setValues] = useState({ ...control, title: stripHtml(control.title) });
  const [showAddQuestion, setShowAddQuestion] = useState();
  const [showTriggers, setShowTriggers] = useState(defaultShowTriggers || false);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { rtl } = useSelector(selectTemplate);

  const { answer_type_idx: answerTypeIdx, enabled, optional } = control;
  const answerType = getAnswerType(answerTypeIdx, answerTypes);

  const findingsLength = useMemo(
    () => control.findings && control.findings.flatMap(({ triggers }) => triggers).length,
    [control],
  );

  const onSave = (field, value) => {
    const payload = {
      categories: [
        {
          id: categoryId,
          controls: [{ ...control, [field]: value }],
        },
      ],
    };
    dispatch(saveTemplate(payload));
  };

  const onSaveDebounced = useCallback(debounce(onSave, 1000), [control]);

  const onTextChange = (field, value) => {
    setValues((state) => ({ ...state, [field]: value }));
    onSaveDebounced(field, value);
  };

  return (
    <div className="editor-active-question">
      {answerTypesLoading && <Loader />}
      <div className="editor-active-question__container">
        <div className="editor-active-question__main">
          <div className="editor-active-question__key">
            {calculateControlKey(subjectKey, index)}
          </div>
          <div className="editor-active-question__wrapper">
            <EditableContent
              className="editor-active-question__top-description"
              onChange={(value) => onTextChange('description', value)}
              loading={loading && values.description !== control.description}
              placeholder={translate(messages.addTopDescription)}
              value={values.description}
              data-test={`editor-active-question-top-description-${index}`}
              style={{ direction: rtl ? 'rtl' : 'ltr' }}
            />
            <EditableContent
              className="editor-active-question__title"
              onChange={(value) => onTextChange('title', value)}
              loading={loading && values.title !== control.title}
              placeholder={translate(messages.enterQuestion)}
              value={values.title}
              data-test={`editor-active-question-title-${index}`}
              style={{ direction: rtl ? 'rtl' : 'ltr' }}
            />
            <EditableContent
              className="editor-active-question__bottom-description"
              onChange={(value) => onTextChange('descriptionRight', value)}
              loading={loading && values.descriptionRight !== control.descriptionRight}
              placeholder={translate(messages.addBottomDescription)}
              value={values.descriptionRight}
              data-test={`editor-active-question-bottom-description-${index}`}
              style={{ direction: rtl ? 'rtl' : 'ltr' }}
            />
            <div className="editor-active-question__question-preview">
              <AnswerPreview
                data-test={`editor-active-question-answer-preview-${index}`}
                answerType={answerType}
                control={control}
                onSave={(value) => onSave('answer', value)}
                addQuestion={(value) => {
                  setShowTriggers(true);
                  setShowAddQuestion(value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="editor-active-question__action-bar">
          {isMyVendorsTabSelected && (
            <>
              <RequiredSwitcher
                onSave={(value) => onSave('optional', value)}
                defaultValue={optional}
                data-test={`editor-active-question-required-switch-${index}`}
              />
              <Divider vertical size="xxs" />
              <VisibleSwitcher
                onSave={(value) => onSave('enabled', value)}
                defaultValue={enabled}
                data-test={`editor-active-question-visible-switch-${index}`}
                open={openVisibleSwitcher}
                setOpen={handleSetVisibleSwitcherOpen}
              />
              <Divider vertical size="xxs" />
            </>
          )}
          <SecondaryButton
            className="editor-active-question__edit-answers-button"
            data-test={`editor-active-question-edit-answers-button-${index}`}
            onClick={setEditAnswers}
            iconComponent={<EditIcon />}
            noBorder
          >
            {translate(messages.editAnswers)}
          </SecondaryButton>
          <div className="editor-active-question__right-actions-buttons">
            <div
              data-test={`editor-active-question-remove-answers-button-${index}`}
              className="editor-active-question__remove-answers-button"
              onClick={onRemoveQuestion}
            >
              <TrashIcon />
            </div>
          </div>
          {allowFindings(answerType.type) && (
            <>
              <Divider vertical size="xxs" />
              <SecondaryButton
                data-test={`editor-active-question-findings-button-${index}`}
                className="editor-active-question__findings-button"
                onClick={setFindings}
                iconComponent={control.findings.length ? <FindingFilledIcon /> : <FindingIcon />}
                counter={control.findings.length ? findingsLength : undefined}
                uppercase
                noBorder
              >
                {translate(messages.findings)}
              </SecondaryButton>
            </>
          )}
          {allowTriggers(answerType.type) && isMyVendorsTabSelected && (
            <>
              <Divider vertical size="xxs" />
              <SecondaryButton
                className="editor-active-question__triggers-button"
                data-test={`editor-active-question-triggers-button-${index}`}
                onClick={() => setShowTriggers((state) => !state)}
                iconComponent={<FollowUpControlIcon />}
                counter={
                  Object.entries(control.triggers).flatMap(([, triggers]) => triggers).length
                }
                uppercase
                noBorder
              >
                {translate(messages.followUpQuestions)}
              </SecondaryButton>
            </>
          )}
          {!isMyVendorsTabSelected && (
            <AnswerRepository
              data-test={`editor-active-question-answer-repository-${index}`}
              control={control}
              onSelect={(answer) => onSave('answer', answer)}
              isEditor
            />
          )}
        </div>
      </div>
      {showTriggers && (
        <Triggers
          answerType={answerType}
          control={control}
          onClose={() => setShowTriggers(false)}
          showAddQuestion={showAddQuestion}
          setShowAddQuestion={setShowAddQuestion}
          data-test={`editor-active-question-triggers-${index}`}
        />
      )}
    </div>
  );
};

ActiveQuestion.propTypes = {
  categoryId: PropTypes.string,
  control: PropTypes.object,
  defaultShowTriggers: PropTypes.bool,
  index: PropTypes.number,
  setEditAnswers: PropTypes.func,
  setFindings: PropTypes.func,
  setShowTriggers: PropTypes.func,
  showTriggers: PropTypes.bool,
  onRemoveQuestion: PropTypes.func,
  openVisibleSwitcher: PropTypes.bool,
  handleSetVisibleSwitcherOpen: PropTypes.func,
};

export default ActiveQuestion;
