import { setFilters } from 'components/Filters/reducers';
import { cleanFiltersObj, parseFiltersToRequest } from 'components/Filters/lib';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useCallback } from 'react';
import { mixpanelFilterApplied } from 'utils/mixpanel';

function useHandleApplyFilters({ filterableKey, filters, filtersCount, applyFilters }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleMixpanel = useCallback(
    (clear) => {
      const numberOfFilteredValues = Object?.values(filters).reduce((acc, filter) => {
        return acc + filter?.selected?.length;
      }, 0);
      mixpanelFilterApplied({
        source: filterableKey,
        numberOfFilters: clear ? 0 : Object?.keys(filters)?.length,
        filterNames: clear ? [] : Object?.keys(filters),
        numberOfFilteredValues: clear ? 0 : numberOfFilteredValues,
      });
    },
    [filters, filtersCount, filterableKey],
  );

  return useCallback(
    (clear) => {
      if (clear) {
        navigate(`${location.pathname}`, { replace: true });

        dispatch(
          setFilters({
            filters: {},
            filtersCount: 0,
            filterableKey: filterableKey,
            filtersToApi: {},
          }),
        );
        if (applyFilters) {
          applyFilters({
            filtersToApi: {},
          });
        }
        handleMixpanel(clear);
        return;
      }
      const { cleanedFilters, updatedFiltersCount } = cleanFiltersObj({
        filters,
        filtersCount,
      });
      const filtersToApi = parseFiltersToRequest(cleanedFilters);

      const filtersSearchParams = new URLSearchParams({
        filters: JSON.stringify(filtersToApi),
      }).toString();
      navigate(`${location.pathname}?${filtersSearchParams}`, { replace: true });

      dispatch(
        setFilters({
          filters: cleanedFilters,
          filtersCount: updatedFiltersCount,
          filterableKey,
          filtersToApi,
        }),
      );
      if (applyFilters) {
        applyFilters({
          filtersToApi,
        });
      }
      handleMixpanel();
    },
    [filterableKey, filters],
  );
}

export default useHandleApplyFilters;
