import { createSelector } from '@reduxjs/toolkit';

export const selectOverview = createSelector(
  ({ overview }) => overview?.overview,
  (overview) => overview || {},
);

export const selectOverviewLoading = createSelector(
  ({ overview }) => overview.loading,
  (loading) => loading,
);
