import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';

import Icon from 'ui/Icon';
import { translate } from 'utils/index';

import { messages } from 'pages/Recent/messages';
import './risk.scss';

const Risk = ({ label, risk }) => {
  const className = classNames(
    'recent-page-findings-risk',
    `recent-page-findings-risk--${camelCase(risk)}`,
  );

  return (
    <div className={className}>
      {label && <span className="recent-page-findings-risk__label">{label}</span>}
      <Icon icon="flag" />
      {risk || translate(messages.notSpecified)}
    </div>
  );
};

Risk.propTypes = {
  label: PropTypes.string,
  risk: PropTypes.string,
};

export default Risk;
