import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import ExcelSheet from 'ui/ExcelSheet';
import Loader from 'ui/Loader';
import { convertExcelToJson } from 'components/FileBrowser/utils';
import { useTheme } from 'hooks/theme';

const ExcelView = ({ fileUrl }) => {
  const [sheets, setSheets] = useState({
    activeIndex: 0,
    data: [],
  });
  const { colorTheme } = useTheme();

  useEffect(() => {
    const getFileData = async () => {
      const data = await convertExcelToJson(fileUrl);
      setSheets((prevState) => ({ ...prevState, data }));
    };
    getFileData().then();
  }, [fileUrl]);

  const onClick = (index) => {
    setSheets((prevState) => ({ ...prevState, activeIndex: index }));
  };

  return (
    <div className="file-browser-excel-view">
      {sheets.data.length === 0 && <Loader />}
      <ExcelSheet sheetData={sheets?.data[sheets.activeIndex]?.sheetData || []} />
      <div className="file-browser-excel-view__buttons">
        {sheets.data.length > 0 &&
          sheets.data.map((sheet, index) => (
            <Button
              key={`button_excel_view_${index}`}
              className="file-browser-excel-view__button"
              data-test={`file-browser-excel-view-button-${sheet?.sheetName}`}
              name={sheet?.sheetName}
              onClick={() => onClick(index)}
              outline
              size="sm"
              squared
              color={index === sheets.activeIndex ? colorTheme : 'gray'}
            />
          ))}
      </div>
    </div>
  );
};

ExcelView.propTypes = {
  fileUrl: PropTypes.string,
};

export default ExcelView;
