import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import { useTheme } from 'hooks/theme';
import { translate } from 'utils/index';
import { Apps } from 'constants/apps';
import AwsIcon from 'assets/svgs/aws-icon-2.svg';
import AzureIcon from 'assets/svgs/azure-icon.svg';
import { messages } from 'pages/Assessment/messages';
import { selectCloudMonitoringResultCategory } from 'pages/Assessment/selectors';
import { generateCloudAnswers, getAssessment } from 'api/assessment';

import './cloudMonitoringActivatePopup.scss';

const CloudMonitoringActivatePopup = ({ open, setOpen, submit }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { colorTheme } = useTheme();
  const resultCategory = useSelector(selectCloudMonitoringResultCategory);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setSubmitLoading(true);
    await submit();
    setOpen(false);
    setSubmitLoading(false);
  };
  const activateCloudMonitoring = async () => {
    setLoading(true);
    await dispatch(generateCloudAnswers());
    await dispatch(getAssessment({ id: params.assessmentId }));
    setOpen(false);
    setLoading(false);
  };

  const title = (
    <span>
      <img
        className="assessment-finalize-cloud-monitoring-activate-popup__icon-title"
        src={resultCategory.app === Apps.azure ? AzureIcon : AwsIcon}
        alt="Cloud Monitoring"
      />
      {translate(messages.submitWithoutCloudMonitoringReview)}
    </span>
  );

  const footer = [
    <Button
      data-test="modal-button-accept"
      key="ACCEPT_BUTTON"
      className="ui-modal__footer-ok-button"
      size="sm"
      color={colorTheme}
      loading={submitLoading}
      onClick={onSubmit}
    >
      {translate(messages.proceedAnyway)}
    </Button>,
    <Button
      data-test="modal-button-cancel"
      key="CANCEL_BUTTON"
      className="ui-modal__footer-cancel-button"
      size="sm"
      color="white"
      loading={loading}
      onClick={activateCloudMonitoring}
    >
      {translate(messages.activateReview)}
    </Button>,
  ];

  return (
    <Modal
      className="assessment-finalize-cloud-monitoring-activate-popup"
      open={open}
      onOk={onSubmit}
      onCancel={() => setOpen(false)}
      width={650}
      title={title}
      footer={footer}
    >
      <p className="assessment-finalize-cloud-monitoring-activate-popup__description">
        {translate(messages.submitWithoutCloudMonitoringActivateDescription)}
      </p>
    </Modal>
  );
};

CloudMonitoringActivatePopup.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  submit: PropTypes.func,
};

export default CloudMonitoringActivatePopup;
