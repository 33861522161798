export const messages = {
  weEmployToHighestLevelOf: {
    key: 'CardWIthEnforce2Fa.weEmployToHighestLevelOf',
    defaultMessage:
      'We employ the highest level of protection to safeguard the information of all parties.',
  },
  select2FaMethod: {
    key: 'CardWIthEnforce2Fa.select2FaMethod',
    defaultMessage: 'Select 2FA method',
  },
};

export default {
  prefix: 'CardWIthEnforce2Fa',
  messages,
};
