import React, { useEffect, useRef } from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { DATE_FORMAT } from 'constants/date';
import Icon from 'ui/Icon';
import { isValidDate } from 'ui/DatePicker/lib';

import './index.scss';

const DatePicker = ({ value, className, color, ...props }) => {
  const dataPickerClassName = classNames('ui-date-picker', className, {
    [`ui-date-picker--color-${color}`]: color,
  });
  const ref = useRef();

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (isValidDate(inputValue)) {
      props.onChange(inputValue === '' ? '' : moment(inputValue));
    }
  };

  useEffect(() => {
    const input = ref.current.querySelector('input');
    input.addEventListener('keyup', handleInputChange);

    return () => {
      input.removeEventListener('keyup', handleInputChange);
    };
  }, []);

  return (
    <div ref={ref}>
      <AntDatePicker
        className={dataPickerClassName}
        value={value ? (moment.isMoment(value) ? value : moment(value)) : undefined}
        format={DATE_FORMAT}
        suffixIcon={<Icon icon="date" />}
        {...props}
      />
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'gray']),
  onChange: PropTypes.func,
};

export default DatePicker;
