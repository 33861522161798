export default class FindingStatus {
  static open = 0;
  static closed = 1;
  static pending = 2;
}

export class FindingApproveStatus {
  static approved = 1;
  static deselected = 2;
  static rejected = 3;
}

// For mixpanel
export const findingApprovalStatusEnum = {
  [FindingApproveStatus.approved]: 'approved',
  [FindingApproveStatus.deselected]: 'deselected',
  [FindingApproveStatus.rejected]: 'rejected',
};
