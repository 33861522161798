import React from 'react';
import PropTypes from 'prop-types';

// File icons
import { ReactComponent as DefaultIcon } from 'assets/svgs/file.svg';
import { ReactComponent as PngIcon } from 'assets/svgs/file-png.svg';
import { ReactComponent as JpgIcon } from 'assets/svgs/file-jpg.svg';
import { ReactComponent as ExcelIcon } from 'assets/svgs/file-excel.svg';
import { ReactComponent as CSVIcon } from 'assets/svgs/file-csv.svg';
import { ReactComponent as WordIcon } from 'assets/svgs/file-word.svg';
import { ReactComponent as PdfIcon } from 'assets/svgs/file-pdf.svg';

const fileTypeIcons = {
  jpg: <JpgIcon />,
  jpeg: <JpgIcon />,
  png: <PngIcon />,
  xlsx: <ExcelIcon />,
  xls: <ExcelIcon />,
  csv: <CSVIcon />,
  doc: <WordIcon />,
  docx: <WordIcon />,
  pdf: <PdfIcon />,
  cyclonedx: <CSVIcon />,
  spdx: <CSVIcon />,
  'text/csv': <CSVIcon />,
};

const FileIcon = ({ type }) => {
  if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    return <ExcelIcon />;
  }
  if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    return <WordIcon />;
  }

  return fileTypeIcons[type?.toLowerCase()] || <DefaultIcon width={37} height={37} />;
};

FileIcon.propTypes = {
  type: PropTypes.string,
};
export default FileIcon;
