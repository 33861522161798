import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';
import { messages } from './messages';
import moment from 'moment';
import classNames from 'classnames';
import { ClockIcon } from 'components/Icons';

import './myFilesExpiration.scss';

const MyFilesExpiration = ({ expirationDate, showDaysExpiringSoon = true }) => {
  const diff = useMemo(() => {
    if (expirationDate === null) return 0;
    const now = moment();
    const formattedExpirationDate = moment(expirationDate);

    return formattedExpirationDate.diff(now, 'days') + 1;
  }, [expirationDate]);

  const composedClassExpiredNames = useMemo(() => {
    return classNames('my-files-upload-item__file-label', {
      'my-files-upload-item__file-label--no-expiration': expirationDate === null,
      'my-files-upload-item__file-label--expiring-soon': diff > 0 && diff <= 60,
      'my-files-upload-item__file-label--valid': diff >= 60,
      'my-files-upload-item__file-label--expired': diff <= 0,
    });
  }, [diff]);

  const expiredLabel = useMemo(() => {
    const now = moment();
    if (expirationDate === null) return translate(messages.noExpiration);
    const formattedExpirationDate = moment(expirationDate);

    return diff <= 0
      ? translate(messages.expiredStatus)
      : diff >= 0 && diff <= 60
      ? translate(
          formattedExpirationDate.isSame(now, 'day')
            ? messages.expiringToday
            : showDaysExpiringSoon
            ? messages.expiringSoonLong
            : messages.expiringSoonShort,
          { days: diff },
        )
      : translate(messages.validateStatus);
  }, [expirationDate, diff]);

  return (
    <div className={composedClassExpiredNames}>
      <ClockIcon height="13" width="13" />
      {expiredLabel}
    </div>
  );
};

MyFilesExpiration.propTypes = {
  expirationDate: PropTypes.string,
  showDaysExpiringSoon: PropTypes.bool,
};

export default MyFilesExpiration;
