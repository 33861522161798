import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Modal from 'ui/Modal';
import Icon from 'ui/Icon';
import Button from 'ui/Button';
import Input from 'ui/Input';
import Form from 'ui/Form';
import { translate } from 'utils/index';
import { generalMessages } from 'constants/messages';
import { useTheme } from 'hooks/theme';
import { getProfile } from 'api/profile';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { API_STATUS } from 'constants/api';
import { createOrganizationFromPending, getProfileOrganizations } from 'api/organizations';

import { messages } from './messages';
import './addOrganizationModal.scss';

const AddOrganizationModal = ({
  assessments,
  closeAssessmentsModal,
  onCancel,
  open,
  assessmentsListForOrgModal,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { colorTheme } = useTheme();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const { errors, values, touched, handleSubmit, handleChange, resetForm } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: yup.object({
      name: yup.string().required(translate(messages.nameRequired)),
    }),
    onSubmit: async () => {
      setLoading(true);

      const assessmentsList = assessmentsListForOrgModal.reduce((acc, assessment) => {
        const [[id]] = Object.entries(assessment);
        acc.push(id);
        return acc;
      }, []);

      const { status } = await createOrganizationFromPending(values.name, assessmentsList);

      if (status === API_STATUS.SUCCESS) {
        await dispatch(getProfileOrganizations());
        await dispatch(getProfile({ upstream: !isMyVendorsTabSelected })).unwrap();

        if (assessments.length === 0) {
          closeAssessmentsModal();
        }

        resetForm();
        onCancel();
      }
      setLoading(false);
    },
  });

  const title = (
    <span>
      <Icon icon="plus" /> {translate(messages.addOrganizationModalTitle)}
    </span>
  );

  const footer = () => (
    <div className="pending-unassigned-assessments-add-organization-modal__footer">
      <Button
        size="sm"
        color="white"
        onClick={onCancel}
        fluid
        data-test="pending-unassigned-assessments-add-organization-modal-button-cancel"
      >
        {translate(generalMessages.cancel)}
      </Button>
      <Button
        data-test="pending-unassigned-assessments-add-organization-modal-button-save"
        size="sm"
        color={colorTheme}
        onClick={handleSubmit}
        fluid
        type="submit"
        loading={loading}
      >
        {translate(generalMessages.save)}
      </Button>
    </div>
  );

  return (
    <Modal
      className="pending-unassigned-assessments-add-organization-modal"
      open={open}
      onCancel={onCancel}
      width={420}
      title={title}
      footer={null}
    >
      <Form>
        <div className="pending-unassigned-assessments-add-organization-modal__body">
          <Input
            data-test="new-organization-input-name"
            block
            name="name"
            value={values.name}
            placeholder={translate(messages.enterOrganizationName)}
            onChange={handleChange}
            error={touched.name && errors.name}
          />
        </div>
        {footer()}
      </Form>
    </Modal>
  );
};

AddOrganizationModal.propTypes = {
  assessments: PropTypes.array,
  closeAssessmentsModal: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool,
  assessmentsListForOrgModal: PropTypes.array,
};

export default AddOrganizationModal;
