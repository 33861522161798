import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { getOrganizationDataForBoPage, getOrganizationDataForSoPage } from 'api/organizations';
import { translate } from 'utils/index';
import { messages } from './messages';

const initialState = {
  additionalData: {
    status: undefined,
    generalDescriptionTitle: '',
    generalDescription: '',
    salesGeneralDescriptionTitle: '',
    salesGeneralDescription: '',
    currentUploadedFiles: [],
    logo: '',
  },
  payload: {
    fullname: '',
    email: '',
    company: '',
    assessment: {
      categories: [],
    },
  },
};

const reducers = createSlice({
  name: 'soboPages',
  initialState,
  reducers: {
    setPayload: (state, { payload }) => {
      state.payload = { ...state.payload, ...payload };
    },
    setParam: (state, { payload: { key, paramName, value } }) => {
      state[key][paramName] = value;
    },
    resetState: (state) => {
      state.additionalData.currentUploadedFiles = [];
      state.payload = initialState.payload;
    },
    addFiles: (state, { payload: { files, controlId, categoryId } }) => {
      const category = state.payload.assessment.categories.find(
        (category) => category.id === categoryId,
      );
      const control = category.controls.find((control) => control.id === controlId);
      files.forEach?.((file) => {
        if (control.answer?.find?.((answer) => answer.id === file.id)) {
          return notification.error({
            message: `${file.title} ${translate(messages.stageThreeFileExist)}`,
          });
        } else {
          control.answer = [...control.answer, file];
        }
      });
    },
    removeFile: (state, { payload: { fileId, categoryId, controlId } }) => {
      const {
        payload: {
          assessment: { categories },
        },
      } = state;
      state.payload.assessment.categories = categories?.map((category) => {
        if (category.id === categoryId) {
          category?.controls?.map((control) => {
            if (control.id === controlId) {
              control.answer = control.answer?.filter((answer) => {
                return answer?.id !== fileId;
              });
            }
            return control;
          });
        }
        return category;
      });
    },
    onChangeControl: (state, { payload: { categoryId, controlId, answerValue } }) => {
      const {
        assessment: { categories },
      } = state.payload;

      state.payload.assessment.categories = categories.map((category) => {
        if (category.id === categoryId) {
          category.controls.map((control, index) => {
            if (control.id === controlId) {
              category.controls[index].answer = answerValue;
            }
            return control;
          });
        }
        return category;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getOrganizationDataForBoPage.fulfilled,
      (state, { payload: { generalDescriptionTitle, generalDescription, _assessment } }) => {
        state.additionalData.generalDescriptionTitle = generalDescriptionTitle;
        state.additionalData.generalDescription = generalDescription;
        state.payload.assessment = _assessment;
      },
    );
    builder.addCase(
      getOrganizationDataForSoPage.fulfilled,
      (
        state,
        { payload: { salesGeneralDescriptionTitle, salesGeneralDescription, salesAssessment } },
      ) => {
        state.additionalData.salesGeneralDescriptionTitle = salesGeneralDescriptionTitle;
        state.additionalData.salesGeneralDescription = salesGeneralDescription;
        state.payload.assessment = salesAssessment;
      },
    );
    builder.addMatcher(
      isAnyOf(getOrganizationDataForBoPage.fulfilled, getOrganizationDataForSoPage.fulfilled),
      (state, { payload: { logo, status } }) => {
        state.additionalData.status = status;
        state.additionalData.logo = logo;
      },
    );
  },
});

export const { setPayload, onChangeControl, resetState, addFiles, setParam, removeFile } =
  reducers.actions;

export default reducers;
