import { generalMessages } from 'constants/messages';
import { translate } from '.';

export const getSbomFindingsSeverity = (severity) => {
  switch (severity) {
    case 1:
      return translate(generalMessages.lowRisk);
    case 2:
      return translate(generalMessages.mediumRisk);
    case 3:
      return translate(generalMessages.highRisk);
    case 4:
      return translate(generalMessages.criticalRisk);
    default:
      return translate(generalMessages.unknown);
  }
};

export const getSbomFindingMaxSeverity = (finding) => {
  const maxSeverity = Math.max(...Object.values(finding?.description));
  return getSbomFindingsSeverity(maxSeverity);
};

export const getSbomFindingSeverityData = (finding) => {
  const severityIndex = Math.max(...Object.values(finding?.description));
  const severityValue = getSbomFindingsSeverity(severityIndex);

  return { severityValue, severityIndex };
};
