/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Progress, Tooltip } from 'antd';

import emptyLogo from 'assets/svgs/emptyChart-MultiProgressChart.svg';
import colors from 'ui/colors.scss';
import { FindingIcon } from 'components/Icons';
import { translate } from 'utils/index';
import { getNavPathWithFilters, useAppNavigate, useGetPath } from 'hooks/navigation';
import ROUTES from 'constants/routes';
import { FILTERABLE_KEYS, filtersMetadata } from 'components/Filters/lib';
import { useSelector } from 'react-redux';

import './index.scss';

const MultiProgressChart = ({ chart }) => {
  const { data } = chart;
  const appNavigate = useAppNavigate();
  const getPath = useGetPath();

  const { filtersToApi } = useSelector(
    (state) => state.filters.allFilters[FILTERABLE_KEYS.DASHBOARD_FILTERS],
  );

  const handleClick = (item) => {
    if (!ROUTES[data.navRoute] || !data?.filterKey || !item) return;
    const isMultipleKeys = filtersMetadata[data.filterKey].multipleKeys;
    let selected = [item.id === item.label ? item.id : { key: item.id, value: item.label }];

    const filters = {
      ...filtersToApi,
      [data.filterKey]: {
        selected: isMultipleKeys ? [selected] : selected,
      },
    };

    appNavigate(getNavPathWithFilters(getPath(ROUTES[data.navRoute]), filters));
  };

  return (
    <div className="dashboard__chart__multi-progress-chart">
      {data?.dataset ? (
        <ul>
          {data.dataset.map((item, index) => {
            return (
              <li key={item.id}>
                <Tooltip
                  placement="top"
                  title={
                    <div className="dashboard__chart__multi-progress-chart__tooltip">
                      <h6>
                        {translate({ key: item.id, defaultMessage: item.label })}{' '}
                        {translate({
                          key: data.subjectText?.key,
                          defaultMessage: data.subjectText?.value,
                        })}
                      </h6>
                      <p>
                        <span style={{ backgroundColor: item.color }}></span> {item.percentage}%
                      </p>
                    </div>
                  }
                >
                  <a
                    role="button"
                    onClick={() => handleClick(item)}
                    className="dashboard__chart__multi-progress-chart__content"
                    style={{
                      cursor: data.navRoute ? 'pointer' : 'default',
                    }}
                  >
                    <div className="dashboard__chart__multi-progress-chart__title">
                      <h5>
                        <FindingIcon color={item.color} />{' '}
                        {translate({ key: item.id, defaultMessage: item.label })}
                      </h5>
                      <div>
                        <span className="dashboard__chart__multi-progress-chart__title__percentage">
                          {item.percentage}%
                        </span>
                        <span className="dashboard__chart__multi-progress-chart__title__value">
                          ({item.value})
                        </span>
                      </div>
                    </div>
                    <Progress
                      className="dashboard__chart__multi-progress-chart__progress"
                      type="line"
                      trailColor={colors.gray200}
                      strokeColor={item.color}
                      strokeWidth={7}
                      showInfo={false}
                      percent={item.percentage}
                    />
                  </a>
                </Tooltip>
              </li>
            );
          })}
        </ul>
      ) : (
        <img src={emptyLogo} alt="empty chart" />
      )}
    </div>
  );
};

MultiProgressChart.propTypes = {
  chart: PropTypes.object,
};

export default MultiProgressChart;
