import React from 'react';

import Search from 'pages/Findings/search';
import Filters from 'components/Filters';
import { FILTERABLE_KEYS } from 'components/Filters/lib';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import {
  FINDINGS_LIST_ALLOWED_FILTERS_DOWNSTREAM,
  FINDINGS_LIST_ALLOWED_FILTERS_UPSTREAM,
} from 'components/Filters/allowedFilters';

const ActionsBar = () => {
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  return (
    <div className="findings-page__action-bar">
      <div className="findings-page__action-bar-top">
        <Search />
        <Filters
          filterableKey={FILTERABLE_KEYS.FINDINGS_LIST_FILTERS}
          allowedFilters={
            isMyVendorsTabSelected
              ? FINDINGS_LIST_ALLOWED_FILTERS_DOWNSTREAM
              : FINDINGS_LIST_ALLOWED_FILTERS_UPSTREAM
          }
        />
      </div>
    </div>
  );
};

export default ActionsBar;
