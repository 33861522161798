import React from 'react';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';
import SecondaryButton from 'ui/SecondaryButton';

import { getDefaultRecommendation } from './lib';

import { messages } from './messages';

import './recommendations.scss';
import Recommendation from './recommendation';

const Recommendations = ({
  control,
  errors,
  finding,
  handleChange,
  note,
  recommendations,
  setFieldValue,
  setNote,
  touched,
  auditCss,
  enableRemove,
}) => {
  const handleRemove = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
    if (note[index]) {
      setNote((state) => ({ ...state, [index]: false }));
    }
  };
  return (
    <FieldArray
      name="finding.recommendations"
      render={(arrayHelpers) => (
        <div className="assessment-recommendations">
          {recommendations &&
            recommendations?.length > 0 &&
            recommendations.map((recommendation, index) => {
              return (
                <Recommendation
                  key={recommendation.id}
                  handleRemove={() => handleRemove(arrayHelpers, index)}
                  index={index}
                  recommendation={recommendation}
                  arrayHelpers={arrayHelpers}
                  control={control}
                  errors={errors}
                  finding={finding}
                  handleChange={handleChange}
                  note={note}
                  setFieldValue={setFieldValue}
                  setNote={setNote}
                  touched={touched}
                  auditCss={auditCss}
                  enableRemove={enableRemove}
                />
              );
            })}
          <SecondaryButton
            className="assessment-recommendations__add-recommendation-button"
            onClick={() => arrayHelpers.push(getDefaultRecommendation())}
            size="medium"
            icon="add3"
            uppercase
            link
          >
            {translate(messages.addRecommendation)}
          </SecondaryButton>
        </div>
      )}
    />
  );
};

Recommendations.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.array,
  finding: PropTypes.object,
  handleChange: PropTypes.func,
  note: PropTypes.object,
  recommendations: PropTypes.array,
  setFieldValue: PropTypes.func,
  setNote: PropTypes.func,
  touched: PropTypes.array,
  auditCss: PropTypes.object,
  enableRemove: PropTypes.bool,
};

export default Recommendations;
