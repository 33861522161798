import { createSelector } from '@reduxjs/toolkit';

export const selectLanguage = ({ app }) => app.language;

export const selectIsMyVendorsTabSelected = createSelector(
  ({ app }) => app,
  (app) => app.isMyVendorsTabSelected,
);

export const selectIsTranslationsLoaded = ({ app }) => app.isTranslationsLoaded;
