import * as yup from 'yup';

const requiredStringSchema = (reqMsg) => yup.string().required(reqMsg);
const emailSchema = (reqMsg, formatMsg) => yup.string().email(formatMsg).required(reqMsg);

export const contactInformationSchema = (
  requiredCompanyName,
  requiredCompanyContact,
  requiredCompanyEmail,
  companyEmailErrorFormat,
) =>
  yup.object({
    corporateName: requiredStringSchema(requiredCompanyName),
    companyContact: requiredStringSchema(requiredCompanyContact),
    email: emailSchema(companyEmailErrorFormat, requiredCompanyEmail),
  });
