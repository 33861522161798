import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './index.scss';

const Divider = ({ className, hidden, size, vertical, ...props }) => {
  const dividerClassNames = classNames('ui-divider', className, `ui-divider--${size}`, {
    'ui-divider--hidden': hidden,
    'ui-divider--vertical': vertical,
  });

  return <hr className={dividerClassNames} {...props} />;
};

Divider.propTypes = {
  className: PropTypes.string,
  hidden: PropTypes.bool,
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg']),
  vertical: PropTypes.bool,
};

Divider.defaultProps = {
  size: 'sm',
};

export default Divider;
