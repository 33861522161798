import React, { useMemo, useState } from 'react';
import Text from 'ui/Text';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import FileUpload from 'ui/FileUpload';
import FileBrowser from 'components/FileBrowser';
import Loader from 'ui/Loader';
import { FileUploadType } from 'constants/fileUploadType';
import { translate } from 'utils/index';
import { useTheme } from 'hooks/theme';
import { messages } from './messages';
import './index.scss';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';

const focusedStyle = {
  borderColor: '#828282',
  borderWidth: 1,
  borderStyle: 'dashed',
  borderRadius: 4,
};

const rejectStyle = {
  borderColor: '#e45647',
  borderStyle: 'dashed',
  borderRadius: 4,
};

const UploadFiles = ({
  className,
  disabled,
  edit,
  browse,
  handleUpload,
  uploadedFiles,
  accept,
  loading,
  maxSize,
  handleRemove,
  onDrop,
  title,
  openMyFiles = true,
  ...otherProps
}) => {
  const { getRootProps, isFocused, isDragAccept, isDragReject } = useDropzone({ accept });
  const [fileToRemove, setFileToRemove] = useState(null);
  const { colorTheme } = useTheme();

  const parsedAcceptedFiles = useMemo(() => {
    const allTypes = accept.split(',').map((item) => FileUploadType[item.trim()] || item.trim());
    return Array.from(new Set(allTypes));
  }, [accept]);

  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const onRemoveFile = () => {
    handleRemove(fileToRemove.id);
    setFileToRemove(null);
  };

  const uploadFilesClassName = classNames('drop-wrapper', className, {
    'drop-wrapper--disabled': disabled,
  });

  return (
    <div
      className={uploadFilesClassName}
      {...getRootProps({ style })}
      onDrop={(event) => {
        event.preventDefault();
        onDrop(event);
      }}
    >
      <div className="upload-files">
        <div className="upload-files__title">
          {title ? title : translate(messages.uploadFiles)}:
        </div>
        <div className="upload-files__support">
          <Text color="gray" text={`${translate(messages.support)}: `} />
          {parsedAcceptedFiles.map((type, index) => (
            <span key={`${type}-${index}` /* eslint-disable-line react/no-array-index-key */}>
              <Text color="gray" text={type.toUpperCase()} />
              {index !== parsedAcceptedFiles.length - 1 ? (
                <Text color="gray" text=", " />
              ) : (
                <Text color="gray" text=". " />
              )}
            </span>
          ))}
          <Text color="gray" text={`${maxSize || '50mb'}`} />
        </div>
        {uploadedFiles?.length > 0 && (
          <div className="upload-files__items">
            <FileBrowser
              files={uploadedFiles}
              disabled={disabled}
              edit={edit}
              browse={browse}
              setFileToRemove={setFileToRemove}
              {...otherProps}
            />
          </div>
        )}
        <div className="upload-files__button">
          <FileUpload
            accept={accept}
            buttonColor={colorTheme}
            disabled={disabled}
            handleUpload={handleUpload}
            size="sm"
            text={translate(messages.browse)}
            openMyFiles={openMyFiles}
          />
        </div>
      </div>
      {loading && <Loader className="upload-files__loader" />}
      <RemoveConfirmationModal
        onCancel={() => setFileToRemove(false)}
        onOk={onRemoveFile}
        open={!!fileToRemove}
        text={translate(messages.removeFileText)}
        headerText={translate(messages.removeFileTextHeader)}
      />
    </div>
  );
};

UploadFiles.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  edit: PropTypes.func,
  browse: PropTypes.bool,
  handleUpload: PropTypes.func,
  handleRemove: PropTypes.func,
  loading: PropTypes.bool,
  uploadedFiles: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, type: PropTypes.string, id: PropTypes.string }),
  ),
  accept: PropTypes.string.isRequired,
  maxSize: PropTypes.string,
  onDrop: PropTypes.func,
  title: PropTypes.string,
  openMyFiles: PropTypes.bool,
};

export default UploadFiles;
