import { getTranslations } from 'api/translation';
import { TRANSLATIONS_LS_KEY } from 'utils/index';

export const LANGUAGES = [
  {
    label: 'English',
    value: 0,
    code: 'en',
  },
  {
    label: 'עברית',
    value: 1,
    code: 'he',
  },
  {
    label: 'Bulgarian',
    value: 2,
    code: 'bg',
  },
  {
    label: 'Findings',
    value: 3,
    code: 'fi',
  },
  {
    label: 'Polish',
    value: 4,
    code: 'pl',
  },
  {
    label: 'German',
    value: 5,
    code: 'de',
  },
];

export const setTranslations = async (lang, dispatch, next) => {
  const currentCode = LANGUAGES.find((item) => item.value === lang)?.code;
  const translations = await dispatch(getTranslations(currentCode)).unwrap();
  if (translations) {
    localStorage.setItem(TRANSLATIONS_LS_KEY, JSON.stringify(translations));
    if (next) {
      next();
    }
  }
};
