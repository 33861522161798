export const messages = {
  createShowcase: {
    key: 'ShowcasesTemplateList.createShowcase',
    defaultMessage: 'CREATE SHOWCASE',
  },
  columnLastUpdated: {
    key: 'ShowcasesTemplateList.columnLastUpdated',
    defaultMessage: 'LAST UPDATED',
  },
  columnTitle: {
    key: 'ShowcasesTemplateList.columnTitle',
    defaultMessage: 'Title',
  },
  columnDescription: {
    key: 'ShowcasesTemplateList.columnDescription',
    defaultMessage: 'DESCRIPTION',
  },
  columnAssessmentIncluded: {
    key: 'ShowcasesTemplateList.columnAssessmentIncluded',
    defaultMessage: 'ASSESSMENT INCLUDED',
  },
};

export default {
  prefix: 'ShowcasesTemplateList',
  messages,
};
