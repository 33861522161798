import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Popup from 'ui/Popup/Popup';
import Button from 'ui/Button';
import Divider from 'ui/Divider';
import { translate } from 'utils/index';
import { generalMessages } from 'constants/messages';

const ConfirmationPopup = ({
  okButtonColor,
  okButtonText,
  cancelButtonText,
  onClickOk,
  onClickCancel,
  children,
  footer,
  ...otherProps
}) => {
  const DefaultFooter = useMemo(
    () => (
      <div className="ui-popup__confirmation">
        <Button
          centered
          data-test="popup-confirmation-button-ok"
          size="xs"
          width={120}
          color={okButtonColor || 'pink'}
          name={okButtonText || translate(generalMessages.ok)}
          onClick={onClickOk}
        />
        {onClickCancel && (
          <>
            <Divider size="xs" hidden />
            <Button
              link
              data-test="popup-confirmation-button-cancel"
              color="black"
              name={cancelButtonText || translate(generalMessages.cancel)}
              onClick={onClickCancel}
            />
          </>
        )}

        <Divider size="sm" hidden />
      </div>
    ),
    [onClickOk, okButtonColor],
  );

  return (
    <Popup
      maskClosable
      width={400}
      footer={footer || DefaultFooter}
      onCancel={onClickCancel}
      {...otherProps}
    >
      <div style={{ textAlign: 'center' }}>{children}</div>
    </Popup>
  );
};

ConfirmationPopup.propTypes = {
  okButtonColor: PropTypes.string,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onClickOk: PropTypes.func,
  onClickCancel: PropTypes.func,
  children: PropTypes.any,
  footer: PropTypes.any,
};
export default ConfirmationPopup;
