export class Roles {
  static Admin = 0;
  static BO = 1;
  static SO = 2;
  static Owner = 3;
  static Reporter = 4;
  static Viewer = 5;
  static Auditor = 6;

  static getRoleName(role) {
    switch (role) {
      case Roles.Admin:
        return 'Admin';
      case Roles.BO:
        return 'BO';
      case Roles.SO:
        return 'SO';
      case Roles.Owner:
        return 'Owner';
      case Roles.Reporter:
        return 'Reporter';
      case Roles.Viewer:
        return 'Viewer';
      case Roles.Auditor:
        return 'Auditor';
      default:
        return 'Unknown';
    }
  }
}
