import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js/auto';
import './index.scss';

const StackedBarChart = ({
  className,
  fistColor,
  secondColor,
  firstValues,
  secondValues,
  labels,
}) => {
  return (
    <div className="ui-stacked-bar-chart-container">
      <Bar
        className={className}
        plugins={[ChartDataLabels]}
        options={{
          scales: {
            y: {
              stacked: true,
            },
            x: {
              stacked: true,
            },
          },
          plugins: {
            legend: {
              display: true,
            },
            datalabels: {
              display: false,
            },
          },
          borderRadius: 50,
          maintainAspectRatio: false,
        }}
        data={{
          labels: [...labels],
          datasets: [
            {
              label: 'Score',
              data: [...firstValues],
              backgroundColor: fistColor,
              barThickness: 12,
            },
            {
              label: 'Benchmark',
              data: [...secondValues],
              backgroundColor: secondColor,
              barThickness: 12,
            },
          ],
        }}
      />
    </div>
  );
};

StackedBarChart.propTypes = {
  className: PropTypes.string,
  fistColor: PropTypes.string.isRequired,
  secondColor: PropTypes.string.isRequired,
  firstValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  secondValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StackedBarChart;
