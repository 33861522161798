export const messages = {
  stageOneLeftDescriptionTitle: {
    key: 'BoPage.stageOneLeftDescriptionTitle',
    defaultMessage: 'New Vendor',
  },
  stageOneLeftDescriptionText: {
    key: 'BoPage.stageOneLeftDescriptionText',
    defaultMessage: 'Security Assessment Request',
  },
  stageOneDefaultTitle: {
    key: 'BoPage.stageOneDefaultTitle',
    defaultMessage: 'Welcome',
  },
  stageOneDefaultSubTitle: {
    key: 'BoPage.stageOneDefaultSubTitle',
    defaultMessage: 'to our new vendor request page',
  },
  stageOneDefaultDescriptionPart1: {
    key: 'BoPage.stageOneDefaultDescriptionPart1',
    defaultMessage:
      'All you need to do is to answer the risk classification page and the security team will take it from there',
  },
  stageOneDefaultDescriptionPart2: {
    key: 'BoPage.stageOneDefaultDescriptionPart2',
    defaultMessage: 'If you have any questions, please do not hesitate to contact us at ',
  },
  stageTwoLeftDescriptionTitle: {
    key: 'BoPage.stageTwoLeftDescriptionTitle',
    defaultMessage: 'Vendor details',
  },
  stageTwoLeftDescriptionText: {
    key: 'BoPage.stageTwoLeftDescriptionText',
    defaultMessage: 'Please fill in the following contact details',
  },
  stageThreeLeftDescriptionTitle: {
    key: 'BoPage.stageThreeLeftDescriptionTitle',
    defaultMessage: 'Security Requirements',
  },
  stageThreeLeftDescriptionText: {
    key: 'BoPage.stageThreeLeftDescriptionText',
    defaultMessage: 'Please fill in the following Security question',
  },
  stageFourOkButton: {
    key: 'BoPage.stageFourOkButton',
    defaultMessage: 'add another vendor',
  },
};

export default {
  prefix: 'BoPage',
  messages,
};
