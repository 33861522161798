export const messages = {
  companyName: {
    key: 'Contact information tab.companyName',
    defaultMessage: 'Company name',
  },
  companyDomain: {
    key: 'Contact information tab.companyDomain',
    defaultMessage: 'Company domain',
  },
  enterCompanyName: {
    key: 'Contact information tab.enterCompanyName',
    defaultMessage: 'Enter company name',
  },
  enterDomain: {
    key: 'Contact information tab.enterDomain',
    defaultMessage: 'Enter domain',
  },
  contactName: {
    key: 'Contact information tab.contactName',
    defaultMessage: 'Contact name',
  },
  enterContactName: {
    key: 'Contact information tab.enterContactName',
    defaultMessage: 'Enter contact name',
  },
  contactEmail: {
    key: 'Contact information tab.contactEmail',
    defaultMessage: 'Contact email',
  },
  enterContactEmail: {
    key: 'Contact information tab.enterContactEmail',
    defaultMessage: 'Enter contact email',
  },
  contactPhone: {
    key: 'Contact information tab.contactPhone',
    defaultMessage: 'Contact phone',
  },
  vendorStatus: {
    key: 'Contact information tab.vendorStatus',
    defaultMessage: 'Vendor status',
  },
  groups: {
    key: 'Contact information tab.groups',
    defaultMessage: 'Groups',
  },
  enterContactPhoneNumber: {
    key: 'Contact information tab.enterContactPhoneNumber',
    defaultMessage: 'Enter contact phone number',
  },
  saveAndClose: {
    key: 'Contact information tab.saveAndClose',
    defaultMessage: 'Save and close',
  },
  createNewVendor: {
    key: 'Contact information tab.createNewVendor',
    defaultMessage: 'Create new vendor',
  },
  notSpecified: {
    key: 'Contact information tab.notSpecified',
    defaultMessage: 'Not specified',
  },
  pendingRequest: {
    key: 'Contact information tab.pendingRequest',
    defaultMessage: 'Pending request',
  },
  assessmentInProgress: {
    key: 'Contact information tab.assessmentInProgress',
    defaultMessage: 'Assessment in progress',
  },
  BORequestNotApproved: {
    key: 'Contact information tab.BORequestNotApproved',
    defaultMessage: 'BO request Not approved',
  },
  securityTeamApproved: {
    key: 'Contact information tab.securityTeamApproved',
    defaultMessage: 'Security team - Approved',
  },
  securityTeamDeclined: {
    key: 'Contact information tab.securityTeamDeclined',
    defaultMessage: 'Security team - Declined',
  },
  selectStatusPlaceholder: {
    key: 'Contact information tab.selectStatusPlaceholder',
    defaultMessage: 'Select status',
  },
};

export default {
  prefix: 'Contact information tab',
  messages,
};
