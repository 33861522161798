import React, { useState } from 'react';
import moment from 'moment';

import { translate } from 'utils/index';
import Icon from 'ui/Icon';
import Button from 'ui/Button';
import SecondaryButton from 'ui/SecondaryButton';
import NotesSvg from 'assets/svgs/notes.svg';
import NotesCornerSvg from 'assets/svgs/note-corner.svg';
import { useAppNavigate } from 'hooks/navigation';
import { useVendorManageContext } from 'hooks/useVendorManageContext';
import Tag from 'ui/Tag';
import { DATE_FORMAT } from 'constants/date';
import ROUTES from 'constants/routes';

import { messages } from '../../../messages';
import NoteModal from './noteModal';
import { NoteIcon } from 'components/Icons';
import './index.scss';

const Notes = () => {
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [currentNote, setCurrentNote] = useState(null);
  const { currentVendor } = useVendorManageContext();
  const { notes = [] } = currentVendor;
  const navigate = useAppNavigate();

  const handleClickAssessment = async ({ assessmentId, findingId, controlId }) => {
    if (findingId) {
      return navigate(`${ROUTES.VENDOR_ASSESSMENT_FINDINGS}/*`, {
        assessmentId,
        '*': `?findingId=${findingId}`,
      });
    }

    return navigate(`${ROUTES.VENDOR_ASSESSMENT_QUESTIONNAIRE}/*`, {
      assessmentId,
      '*': `?control=${controlId}`,
    });
  };

  const noteOnClick = (event, note) => {
    event.stopPropagation();
    setShowNoteModal(true);
    setCurrentNote(note);
  };

  const addNote = (event) => {
    event.stopPropagation();
    setShowNoteModal(true);
  };

  const renderPlaceholder = () => (
    <div className="continuous-monitoring-tab-notes__notes-placeholder-wrapper">
      <div className="continuous-monitoring-tab-notes__notes-placeholder">
        <img src={NotesSvg} alt="Notes" />
        <span>{translate(messages.noNotes)}</span>
      </div>
    </div>
  );

  return (
    <div className="continuous-monitoring-tab-notes">
      <div className="continuous-monitoring-tab-notes__header">
        <div className="continuous-monitoring-tab-notes__header-title-wrapper">
          <NoteIcon className="continuous-monitoring-tab-notes__header-title-icon" />
          <div>
            <div className="continuous-monitoring-tab-notes__header-title">
              {translate(messages.notes)}
            </div>
            <div className="continuous-monitoring-tab-notes__header-description">
              {translate(messages.viewAllVendorNotes)}
            </div>
          </div>
        </div>
        <SecondaryButton
          data-test="add-general-note-button"
          className="continuous-monitoring-tab-notes__add-note-button"
          onClick={addNote}
          size="medium"
          icon="plus"
          link
        >
          {translate(messages.addNote)}
        </SecondaryButton>
      </div>
      {notes.length > 0 ? (
        <div data-test="notes-list" className="continuous-monitoring-tab-notes__notes">
          {notes.map((note) => {
            return (
              <div
                key={note.noteId}
                onClick={(event) => noteOnClick(event, note)}
                className="continuous-monitoring-tab-notes__note"
              >
                <div className="continuous-monitoring-tab-notes__note-wrapper">
                  <div className="continuous-monitoring-tab-notes__note-title-wrapper">
                    {!note.assessmentId ? (
                      translate(messages.general)
                    ) : (
                      <>
                        <div className="continuous-monitoring-tab-notes__note-tag-wrapper">
                          <Tag
                            className="assessment-tag"
                            color="blue"
                            name={note.assessmentName}
                            textEllipsis
                            onClick={(event) => {
                              handleClickAssessment(note);
                              event.stopPropagation();
                            }}
                          />
                        </div>
                        <span className="continuous-monitoring-tab-notes__note-divider" />
                        <div className="continuous-monitoring-tab-notes__note-control-title">
                          {note.findingDescription ? (
                            <Icon
                              className="continuous-monitoring-tab-notes__note-finding-flag"
                              icon="flag"
                            />
                          ) : (
                            <span className="continuous-monitoring-tab-notes__note-control-title-control-position">
                              ({note.controlPosition})
                            </span>
                          )}
                          {note.findingDescription || note.controlTitle}
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    {note.note.slice(0, 200)}
                    {note.note.length > 200 && (
                      <>
                        <span>...</span>{' '}
                        <Button
                          className="continuous-monitoring-tab-notes__note-read-more-button"
                          color="black"
                          link
                        >
                          Read more
                        </Button>
                      </>
                    )}
                  </div>
                  <span className="continuous-monitoring-tab-notes__note-corner">
                    <img src={NotesCornerSvg} alt="Note" />
                  </span>
                </div>
                <div className="continuous-monitoring-tab-notes__note-date">
                  {translate(messages.lastUpdated)}:{' '}
                  {moment(note.noteTimestampEdited).format(DATE_FORMAT)}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        renderPlaceholder()
      )}
      <NoteModal
        handleClickAssessment={handleClickAssessment}
        note={currentNote}
        open={showNoteModal}
        setOpen={(value) => {
          setShowNoteModal(value);
          if (!value) {
            setCurrentNote(null);
          }
        }}
        vendorId={currentVendor.id}
      />
    </div>
  );
};

export default Notes;
