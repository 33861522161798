import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';
import DOMPurify from 'dompurify';

import { translate } from 'utils/index';
import { messages } from './messages';

import './index.scss';

const TextEditor = ({ content, setContent, ...otherProps }) => {
  const editor = useRef(null);
  const [currentContent, setCurrentContent] = useState(content);

  useEffect(() => {
    setCurrentContent(content);
  }, [content]);

  const configEditor = useMemo(
    () => ({
      placeholder: translate(messages.placeholder),
      readonly: false,
      askBeforePasteHTML: false,
      history: {
        enable: true,
        maxHistoryLength: 1,
      },
    }),
    [],
  );

  const configPurify = {
    FORCE_BODY: true,
    ALLOWED_ATTR: ['style', 'class', 'type', 'href', 'rel', 'src', 'alt', 'width', 'height'],
    FORBID_TAGS: ['script'],
  };

  const handleChange = (newContent) => {
    setCurrentContent(newContent);
  };
  return (
    <div
      onBlur={() => {
        setContent(DOMPurify.sanitize(currentContent, configPurify));
      }}
    >
      <JoditEditor
        ref={editor}
        value={currentContent}
        onChange={handleChange}
        config={configEditor}
        {...otherProps}
      />
    </div>
  );
};

TextEditor.propTypes = {
  content: PropTypes.any,
  setContent: PropTypes.func.isRequired,
};

export default TextEditor;
