import React, { useEffect, useMemo, useState } from 'react';
import { notification } from 'antd';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import CardWithLogo from 'components/CardWithLogo';
import StageDescription from 'pages/SoBoPages/stageDescription';
import { translate } from 'utils/index';
import { messages } from 'pages/SoBoPages/NewCustomer/messages';
import { NEW_CUSTOMER_SO_PAGE } from 'pages/SoBoPages/NewCustomer/lib';
import { selectSoBoPages } from 'pages/SoBoPages/selectors';
import { addOrganizationCustomer } from 'api/organizations';
import { API_STATUS } from 'constants/api';
import { messages as generalSoboMessages } from 'pages/SoBoPages/messages';
import AssessmentForm from 'pages/SoBoPages/assessmentForm';
import { categoriesWithMappedControls, controlsMap } from 'pages/SoBoPages/lib';
import { generalMessages } from 'constants/messages';

const StageThreeDescription = () => {
  return (
    <StageDescription
      title={translate(messages.stageThreeLeftDescriptionTitle)}
      text={translate(messages.stageThreeLeftDescriptionText)}
    />
  );
};

const StageThree = ({ setStage }) => {
  const { organizationId } = useParams();
  const [categories, setCategories] = useState([]);
  const { payload } = useSelector(selectSoBoPages);
  const { assessment } = payload;

  const mappedControls = useMemo(() => controlsMap(assessment), [assessment]);

  useEffect(() => {
    setCategories(() => categoriesWithMappedControls(assessment, mappedControls));
  }, [assessment]);

  const onClickBack = () => {
    setStage(NEW_CUSTOMER_SO_PAGE.STAGE_2);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const status = await addOrganizationCustomer(payload, organizationId);

    if (status === API_STATUS.FAILED) {
      return notification.error({ message: translate(generalSoboMessages.stageThreeSubmitFail) });
    }
    return setStage(NEW_CUSTOMER_SO_PAGE.STAGE_4);
  };
  return (
    <div className="so-page-stage-three">
      <AssessmentForm assessment={assessment} categories={categories} onSubmit={onSubmit}>
        <CardWithLogo.Footer
          leftButtonOnClick={onClickBack}
          rightButtonType="submit"
          rightButtonText={translate(generalMessages.submit)}
        />
      </AssessmentForm>
    </div>
  );
};

StageThree.Description = StageThreeDescription;

StageThree.propTypes = {
  setStage: PropTypes.func,
};

export default StageThree;
