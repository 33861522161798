export const setFilterReducer = (state, type, value, id) => {
  const selectedFilter = state?.filters.find((filter) => filter.type === type);

  if (selectedFilter) {
    state.filters = state.filters.map((filter) => {
      return filter.type === type
        ? { type, value: value === undefined ? '' : value, id: id !== undefined ? id : '' }
        : filter;
    });
    return;
  }

  state.filters.push({ type, value, id });
};

export const removeFilterReducer = (state, type) => {
  state.filters = state.filters.filter((filter) => filter.type !== type);
};
