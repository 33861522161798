import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getControlNameByType } from 'utils/controls';
import Icon from 'ui/Icon';
import { getIconForAnswerType } from './lib';
import './controls.scss';

const AnswerRepositoryControls = ({ controls, onSelectControl, selectedControl }) => {
  return (
    <div className="answer-repository-controls__container">
      {controls.map(({ title, type, id, rtl }) => (
        <div
          onClick={() => onSelectControl(id)}
          key={id}
          className={classNames('answer-repository-controls__row', {
            selected: selectedControl === id,
          })}
          title={title}
        >
          <strong className={classNames('answer-repository-controls__row-header', { hebrew: rtl })}>
            <Icon
              className="answer-repository-controls__row-icon"
              icon={getIconForAnswerType(type)}
            />
            {getControlNameByType(type)}
          </strong>
          <label className={classNames('answer-repository-controls__row-label', { hebrew: rtl })}>
            {title}
          </label>
        </div>
      ))}
    </div>
  );
};

AnswerRepositoryControls.propTypes = {
  controls: PropTypes.array,
  onSelectControl: PropTypes.func,
  selectedControl: PropTypes.string,
};

export default AnswerRepositoryControls;
