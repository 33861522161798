import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router';

import OrganizationEditHeading from 'pages/Organization/edit/heading';
import { selectOrganizationsExtended } from 'pages/Organization/selectors';
import { getOrganizationProfiles } from 'api/organizations';
import OrganizationEditTabs from 'pages/Organization/edit/tabs';
import Divider from 'ui/Divider';

import 'assets/icons/selection.json';
import './index.scss';

const OrganizationEdit = () => {
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const { organizations } = useSelector(selectOrganizationsExtended);
  const [searchParams] = useSearchParams();
  const orgIndex = searchParams.get('orgIndex');
  const initState = {
    label: '',
    corporateLogo: '',
    profiles: [],
  };
  const [organization, setOrganization] = useState(initState);

  const getProfiles = useCallback(async () => {
    const profiles = await dispatch(getOrganizationProfiles({ organizationId, orgIndex }));
    await setOrganization((state) => ({ ...state, profiles }));
  }, [organizations, organizationId, orgIndex]);

  useEffect(() => {
    if (organizations.length !== 0 && !organizations[orgIndex].profiles) getProfiles().then();
  }, [organizations, orgIndex]);

  useEffect(() => {
    return () => {
      setOrganization(initState);
    };
  }, []);

  useEffect(() => {
    setOrganization((state) => ({
      ...state,
      label: organizations.length > 0 ? organizations[orgIndex].label : '',
      corporateLogo: organizations.length > 0 ? organizations[orgIndex].corporateLogo : '',
    }));
  }, [organizations[orgIndex]?.id]);

  return (
    <div className="organization-edit-page">
      {organizations.length > 0 && (
        <>
          <OrganizationEditHeading
            label={organization.label || ''}
            orgId={organizationId}
            logoPath={organization.corporateLogo}
            getProfiles={getProfiles}
          />
          <Divider size="md" hidden />
          <OrganizationEditTabs />
        </>
      )}
    </div>
  );
};

export default OrganizationEdit;
