import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Popup from 'ui/Popup';
import Button from 'ui/Button';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';

const UploadLogoButtons = ({
  handleClickUpload,
  handleClickRemove,
  showRemovePopup,
  handleRemoveLogo,
}) => {
  return (
    <div className="organization-edit-page__subtitle-links">
      <Button
        data-test="organization-edit-page-upload-logo-button-upload"
        link
        color="blue"
        onClick={handleClickUpload}
      >
        {translate(messages.orgEditLinkUploadLogo)}
      </Button>
      <span style={{ margin: '0px 4px' }}> {'|'}</span>
      <Button link color="blue" onClick={handleClickRemove}>
        {translate(messages.orgEditLinkRemoveLogo)}
      </Button>
      <Popup.Remove
        width={300}
        open={showRemovePopup}
        mainText={translate(messages.orgEditLinkRemoveLogoMessage)}
        okButtonText={translate(messages.orgEditLinkRemoveLogoConfirm)}
        onClickOk={handleRemoveLogo}
        onClickCancel={handleClickRemove}
      />
    </div>
  );
};

UploadLogoButtons.propTypes = {
  setCurrentLogoPath: PropTypes.func,
  handleClickUpload: PropTypes.func,
  handleClickRemove: PropTypes.func,
  showRemovePopup: PropTypes.bool,
  handleRemoveLogo: PropTypes.func,
};

export default UploadLogoButtons;
