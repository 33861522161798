export const messages = {
  findings: {
    key: 'AssessmentFindings.findings',
    defaultMessage: 'Findings',
  },
  sbomFindings: {
    key: 'AssessmentFindings.sbomFindings',
    defaultMessage: 'SBOM Findings',
  },
  description: {
    key: 'AssessmentFindings.description',
    defaultMessage: 'DESCRIPTION',
  },
  risk: {
    key: 'AssessmentFindings.risk',
    defaultMessage: 'RISK',
  },
  status: {
    key: 'AssessmentFindings.status',
    defaultMessage: 'STATUS',
  },
  recommendations: {
    key: 'AssessmentFindings.recommendations',
    defaultMessage: 'RECOMMENDATIONS',
  },
  noDescription: {
    key: 'AssessmentFindings.noDescription',
    defaultMessage: 'No description',
  },
  noDComponent: {
    key: 'AssessmentFindings.noDComponent',
    defaultMessage: 'No component',
  },
  riskNotCalculated: {
    key: 'AssessmentFindings.riskNotCalculated',
    defaultMessage: 'Not Calculated',
  },
  finding: {
    key: 'AssessmentFindings.finding',
    defaultMessage: 'Finding',
  },
  question: {
    key: 'AssessmentFindings.question',
    defaultMessage: 'QUESTION',
  },
  answer: {
    key: 'AssessmentFindings.answer',
    defaultMessage: 'ANSWER',
  },
  impact: {
    key: 'AssessmentFindings.impact',
    defaultMessage: 'IMPACT',
  },
  version: {
    key: 'AssessmentFindings.version',
    defaultMessage: 'VERSION',
  },
  severity: {
    key: 'AssessmentFindings.severity',
    defaultMessage: 'SEVERITY',
  },
  probability: {
    key: 'AssessmentFindings.probability',
    defaultMessage: 'PROBABILITY',
  },
  choose: {
    key: 'AssessmentFindings.choose',
    defaultMessage: 'Choose',
  },
  goToQuestion: {
    key: 'AssessmentFindings.goToQuestion',
    defaultMessage: 'Go to question',
  },
  removeFinding: {
    key: 'AssessmentFindings.removeFinding',
    defaultMessage: 'Remove finding',
  },
  removeFindingPopupText: {
    key: 'AssessmentFindings.removeFindingPopupText',
    defaultMessage: 'All finding information will be lost',
  },
  removeFindingPopupHeaderText: {
    key: 'AssessmentFindings.removeFindingPopupHeaderText',
    defaultMessage: 'Delete finding?',
  },
  notSpecified: {
    key: 'AssessmentFindings.notSpecified',
    defaultMessage: 'Not specified',
  },
  addFinding: {
    key: 'AssessmentFindings.addFinding',
    defaultMessage: 'ADD FINDING',
  },
  closeFindingTitle: {
    key: 'AssessmentFindings.closeFindingTitle',
    defaultMessage: 'Are you sure you want to close the finding?',
  },
  yesClose: {
    key: 'AssessmentFindings.yesClose',
    defaultMessage: 'Yes, close',
  },
  enterNote: {
    key: 'AssessmentFindings.enterNote',
    defaultMessage: 'Enter note',
  },
  noteRequired: {
    key: 'AssessmentFindings.noteRequired',
    defaultMessage: 'Note required',
  },
  closeFindingText: {
    key: 'AssessmentFindings.closeFindingText',
    defaultMessage:
      'There are open recommendations to this finding, closing the finding will close all recommendations. Please indicate way the finding was closed (this is an internal note, only shared in your organization.',
  },
  categorySelectLabel: {
    key: 'AssessmentFindings.categorySelectLabel',
    defaultMessage: 'Category',
  },
};

export default {
  prefix: 'AssessmentFindings',
  messages,
};
