import React from 'react';
import PropTypes from 'prop-types';

const CloseCancelIcon = ({
  color = 'currentColor',
  height = '24',
  width = '24',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M6.66663 6.66669L17.3333 17.3334"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M17.3333 6.66669L6.66663 17.3334"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

CloseCancelIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default CloseCancelIcon;
