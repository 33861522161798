import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { translate } from 'utils/index';
import openFindingsIcon from 'assets/images/open-findings.svg';
import recommendationsIcon from 'assets/images/recommendations.svg';
import overdueRecommendationsIcon from 'assets/images/overdue-recommendations.svg';
import TileWithTitle from 'ui/TileWithTitle';
import { selectOverview } from './selectors';
import { messages } from './messages';
import './findings.scss';

const Findings = ({ className }) => {
  const { findings } = useSelector(selectOverview);
  return (
    <TileWithTitle className={className} title={translate(messages.findings)}>
      <div className="overview-findings__items">
        <div className="overview-findings__item">
          <img src={openFindingsIcon} alt="Open findings" />
          <div className="overview-findings__counter-wrapper">
            <div className="overview-findings__counter-label">
              {translate(messages.openFindings)}:
            </div>
            <div className="overview-findings__counter">{findings?.['open-findings']}</div>
          </div>
        </div>

        <div className="overview-findings__item">
          <img src={recommendationsIcon} alt="Recommendations" />
          <div className="overview-findings__counter-wrapper">
            <div className="overview-findings__counter-label">
              {translate(messages.recommendations)}:
            </div>
            <div className="overview-findings__counter">{findings?.['open-recs']}</div>
          </div>
        </div>

        <div className="overview-findings__item">
          <img src={overdueRecommendationsIcon} alt="Overdue recommendations" />
          <div className="overview-findings__counter-wrapper">
            <div className="overview-findings__counter-label">
              {translate(messages.overdueRecommendations)}:
            </div>
            <div className="overview-findings__counter">{findings?.['overdue-recs']}</div>
          </div>
        </div>
      </div>
    </TileWithTitle>
  );
};

Findings.propTypes = {
  className: PropTypes.string,
};
export default Findings;
