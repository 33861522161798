import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'utils/index';
import { messages } from 'pages/Assessment/messages';
import { countAllEnabledControls, countApprovedControls } from 'pages/Assessment/finalized/lib';

const Approved = ({ category, showCounter }) => {
  const allControls = countAllEnabledControls(category);
  const approvedControls = countApprovedControls(category);
  const allApproved = allControls === approvedControls;

  if (showCounter) {
    return allApproved ? translate(messages.all) : `${approvedControls}/${allControls}`;
  }

  return <div>{allApproved ? translate(messages.all) : approvedControls || '-'}</div>;
};

Approved.propTypes = {
  category: PropTypes.object,
  showCounter: PropTypes.bool,
};

export default Approved;
