import React from 'react';
import PropTypes from 'prop-types';

const AddPlusCircledIcon = ({
  color = 'currentColor',
  height = '24',
  width = '24',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M12 2C6.48603 2 2 6.48604 2 12C2 17.514 6.48603 22 12 22C17.514 22 22 17.514 22 12C22 6.48604 17.514 2 12 2ZM12 3.5C16.7033 3.5 20.5 7.2967 20.5 12C20.5 16.7033 16.7033 20.5 12 20.5C7.29669 20.5 3.5 16.7033 3.5 12C3.5 7.2967 7.29669 3.5 12 3.5ZM11.9883 6.98926C11.7895 6.99236 11.6002 7.07423 11.4617 7.21686C11.3233 7.3595 11.2471 7.55125 11.25 7.75V11.25H7.75C7.65062 11.2486 7.55194 11.267 7.45972 11.304C7.36749 11.3411 7.28355 11.3961 7.21277 11.4659C7.142 11.5357 7.08579 11.6188 7.04743 11.7105C7.00907 11.8022 6.98932 11.9006 6.98932 12C6.98932 12.0994 7.00907 12.1978 7.04743 12.2895C7.08579 12.3812 7.142 12.4643 7.21277 12.5341C7.28355 12.6039 7.36749 12.6589 7.45972 12.696C7.55194 12.733 7.65062 12.7514 7.75 12.75H11.25V16.25C11.2486 16.3494 11.267 16.4481 11.304 16.5403C11.3411 16.6325 11.3961 16.7164 11.4659 16.7872C11.5357 16.858 11.6188 16.9142 11.7105 16.9526C11.8022 16.9909 11.9006 17.0107 12 17.0107C12.0994 17.0107 12.1978 16.9909 12.2895 16.9526C12.3812 16.9142 12.4643 16.858 12.5341 16.7872C12.6039 16.7164 12.6589 16.6325 12.696 16.5403C12.733 16.4481 12.7514 16.3494 12.75 16.25V12.75H16.25C16.3494 12.7514 16.4481 12.733 16.5403 12.696C16.6325 12.6589 16.7164 12.6039 16.7872 12.5341C16.858 12.4643 16.9142 12.3812 16.9526 12.2895C16.9909 12.1978 17.0107 12.0994 17.0107 12C17.0107 11.9006 16.9909 11.8022 16.9526 11.7105C16.9142 11.6188 16.858 11.5357 16.7872 11.4659C16.7164 11.3961 16.6325 11.3411 16.5403 11.304C16.4481 11.267 16.3494 11.2486 16.25 11.25H12.75V7.75C12.7514 7.64962 12.7327 7.54997 12.6949 7.45695C12.6571 7.36394 12.6011 7.27946 12.53 7.20852C12.459 7.13758 12.3745 7.08161 12.2814 7.04395C12.1883 7.00629 12.0887 6.98769 11.9883 6.98926Z"
      fill={color}
    />
  </svg>
);

AddPlusCircledIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default AddPlusCircledIcon;
