import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { notification } from 'antd';

import Form from 'ui/Form';
import Input from 'ui/Input';
import CardWithLogo from 'components/CardWithLogo';
import UploadLogo from 'components/UploadLogo';
import Divider from 'ui/Divider';
import { ONBOARDING_FORM_STAGE } from 'pages/Activation/Onboarding/lib';
import { selectProfile } from 'pages/Profile/selectors';
import { setProfile } from 'api/profile';
import { setProfileParam } from 'pages/Profile/reducers';
import { translate } from 'utils/index';
import { API_STATUS } from 'constants/api';
import { messages } from './messages';
import { mixpanelTrackOnBoarding } from 'utils/mixpanel';
import { getCookie } from 'utils/apiHelpers';
import { TOKEN_AUTH_KEY } from 'utils/auth';
import Card from 'ui/Card';
import shareIcon from 'assets/svgs/share-icon.svg';

const StageThree = ({ setStage, finishOnboarding }) => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const { user } = useSelector(selectProfile);
  const [corporateName, setCorporateName] = useState(user.corporateName);
  const cookieToken = getCookie(TOKEN_AUTH_KEY);

  const onChange = ({ target: { value } }) => setCorporateName(value);

  const onBlur = () => dispatch(setProfileParam({ key: 'corporateName', value: corporateName }));

  const onClickBack = () => setStage(ONBOARDING_FORM_STAGE.STAGE_2FA_METHOD);

  const onSubmit = async () => {
    const { status, data } = await dispatch(
      setProfile({ user, token: cookieToken ? cookieToken : token, isOnBoarding: true }),
    ).unwrap();

    if (status !== API_STATUS.SUCCESS) {
      return notification.error({ message: translate(messages.stageThreeSetProfileError) });
    }

    mixpanelTrackOnBoarding({
      source: data?.source,
      daysFromInvite: data?.daysFromInvite,
    });

    finishOnboarding();
  };

  return (
    <Card className="onboarding-card">
      <div className="onboarding-card__left-box">
        <div className="onboarding-card__left-box__content">
          <img src={shareIcon} alt="thunder auto" />
          <p>{translate(messages.stageThreeLeftBoxContent)}</p>
        </div>
      </div>
      <div className="onboarding-card__inner">
        <UploadLogo token={cookieToken ? cookieToken : token} />
        <Divider size="lg" />
        <Form onSubmit={onSubmit}>
          <Form.Item>
            <Input
              data-test="onboard-stage-three-input-company-label"
              label={translate(messages.stageThreeCompanyNameLabel)}
              placeholder={translate(messages.stageThreeCompanyNamePlaceHolder)}
              block
              value={corporateName}
              onChange={onChange}
              onBlur={onBlur}
            />
          </Form.Item>
          <CardWithLogo.Footer
            leftButtonOnClick={onClickBack}
            rightButtonText={translate(messages.stageThreeSubmitButton)}
            rightButtonOnClick={onSubmit}
            noRightIcon
            rightButtonWidth={180}
          />
        </Form>
      </div>
    </Card>
  );
};

StageThree.propTypes = {
  setStage: PropTypes.func,
  finishOnboarding: PropTypes.func,
};

export default StageThree;
