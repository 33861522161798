import React from 'react';
import PropTypes from 'prop-types';
import RiskReconIconGray from 'assets/svgs/risk-recon-icon-gray.svg';
import SscIconGray from 'assets/svgs/ssc-icon-gray.svg';
import QualysIconGray from 'assets/svgs/qualys-icon-gray.svg';
import AzureIconGray from 'assets/svgs/azure-icon-gray.svg';
import AwsIconGray from 'assets/svgs/aws-icon-gray.svg';
import IBMIconGray from 'assets/svgs/ibm-x-force-icon-gray.svg';

import Form from 'ui/Form';
import Input from 'ui/Input';
import Button from 'ui/Button';

import { translate } from 'utils/index';
import { messages } from 'pages/Vendors/VendorManage/Tabs/ContinuousMonitoring/messages';

function AddDomainView({
  disabled,
  vendorContactData,
  onChangeContactFrom,
  setTouched,
  touched,
  currentErrors,
  scanDomain,
  loader,
}) {
  return (
    <div className="continuous-monitoring-tab__add-domain-wrapper">
      <div className="continuous-monitoring-tab__app-icons">
        <img
          className="continuous-monitoring-tab__app-icons-icon"
          src={RiskReconIconGray}
          alt="Risk Recon"
        />
        <img
          className="continuous-monitoring-tab__app-icons-icon"
          src={SscIconGray}
          alt="Security Scorecard"
        />
        <img
          className="continuous-monitoring-tab__app-icons-icon"
          src={QualysIconGray}
          alt="Qualys"
        />
        <img
          className="continuous-monitoring-tab__app-icons-icon"
          src={AzureIconGray}
          alt="Azure"
        />
        <img className="continuous-monitoring-tab__app-icons-icon" src={AwsIconGray} alt="AWS" />
        <img
          className="continuous-monitoring-tab__app-icons-icon"
          src={IBMIconGray}
          alt="IBM X-Force"
        />
      </div>
      <div className="continuous-monitoring-tab__add-domain-title">
        {translate(messages.pleaseEnterCompanyDomain)}
      </div>
      <Form className="continuous-monitoring-tab__add-domain-form">
        <Input
          data-test="company-domain-input"
          disabled={disabled}
          className="continuous-monitoring-tab__company-domain-input"
          error={touched.companyDomain && currentErrors?.companyDomain}
          placeholder={translate(messages.enterDomain)}
          onChange={({ target: { value } }) => onChangeContactFrom(value, 'companyDomain')}
          onBlur={() => {
            if (
              vendorContactData.companyDomain &&
              !vendorContactData.companyDomain.startsWith('http://') &&
              !vendorContactData.companyDomain.startsWith('https://')
            ) {
              onChangeContactFrom(`http://${vendorContactData.companyDomain}`, 'companyDomain');
            }
            setTouched((state) => ({ ...state, companyDomain: true }));
          }}
          size="medium"
          value={vendorContactData.companyDomain}
        />
        <Button
          data-test="company-domain-button-send"
          className="continuous-monitoring-tab__send-button"
          onClick={scanDomain}
          size="sm"
          squared
          color="blue"
          disabled={
            loader ||
            !vendorContactData.companyDomain ||
            (touched.companyDomain && currentErrors?.companyDomain)
          }
          loading={loader}
        >
          Run
        </Button>
      </Form>
    </div>
  );
}

AddDomainView.propTypes = {
  disabled: PropTypes.bool,
  vendorContactData: PropTypes.object,
  onChangeContactFrom: PropTypes.func,
  setTouched: PropTypes.func,
  touched: PropTypes.object,
  currentErrors: PropTypes.object,
  scanDomain: PropTypes.func,
  loader: PropTypes.bool,
};

export default AddDomainView;
