export const messages = {
  lastUpdated: {
    key: 'AssessmentsTemplates.lastUpdated',
    defaultMessage: 'LAST UPDATED',
  },
  title: {
    key: 'AssessmentsTemplates.title',
    defaultMessage: 'TITLE',
  },
  description: {
    key: 'AssessmentsTemplates.description',
    defaultMessage: 'DESCRIPTION',
  },
  numberOfUse: {
    key: 'AssessmentsTemplates.numberOfUse',
    defaultMessage: 'NUMBER OF USE',
  },
  actions: {
    key: 'AssessmentsTemplates.actions',
    defaultMessage: 'ACTIONS',
  },
  assignTemplate: {
    key: 'AssessmentsTemplates.assignTemplate',
    defaultMessage: 'Assign template',
  },
  edit: {
    key: 'AssessmentsTemplates.edit',
    defaultMessage: 'Edit',
  },
  cloneTemplate: {
    key: 'AssessmentsTemplates.cloneTemplate',
    defaultMessage: 'Clone template',
  },
  remove: {
    key: 'AssessmentsTemplates.remove',
    defaultMessage: 'Remove',
  },
  removeTemplateText: {
    key: 'AssessmentsTemplates.removeTemplateText',
    defaultMessage: 'All template information will be lost',
  },
  removeTemplateHeaderText: {
    key: 'AssessmentsTemplates.removeTemplateHeaderText',
    defaultMessage: 'Delete template?',
  },
  addTemplate: {
    key: 'AssessmentsTemplates.addTemplate',
    defaultMessage: 'ADD TEMPLATE',
  },
  createNew: {
    key: 'AssessmentsTemplates.createNew',
    defaultMessage: 'Create new',
  },
  addFromFile: {
    key: 'AssessmentsTemplates.addFromFile',
    defaultMessage: 'Add from file',
  },
};

export default {
  prefix: 'AssessmentsTemplates',
  messages,
};
