import React from 'react';
import Loader from './index';
import './appPreloader.scss';

const AppPreloader = () => {
  return (
    <div className="AppPreloader">
      <Loader />
    </div>
  );
};

export default AppPreloader;
