import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

// COMPONENTS
import Icon from 'ui/Icon';
import Select from 'ui/Select';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'components/AddFilterButton/messages';

// STYLES
import './index.scss';

const Filter = ({ children, filter, assessmentType, onRemove, fetchData, onChange, ...props }) => {
  const [filterDomId, setFilterDomId] = useState(null);

  useEffect(() => {
    setFilterDomId(`filter-${uuidv4()}`);
  }, []);

  const onBlur = () => {
    if (filter.value === '') fetchData();
  };

  return (
    <div
      id={filterDomId}
      className={classNames(
        'ui-filter',
        `ui-filter--${assessmentType ? 'assessment-type' : filter.type}`,
      )}
    >
      <Select
        value={filter.value}
        onChange={(value, selected) => {
          const selectedId = Array.isArray(value)
            ? selected.map((selected) => selected.id)
            : selected.id;
          onChange(value, selectedId, filter.type);
          fetchData();
        }}
        onBlur={onBlur}
        placeholder={translate(messages.filterPlaceholder)}
        style={{ width: '100%' }}
        labelKey="value"
        {...props}
      >
        {children}
      </Select>

      {onRemove && (
        <Button type="link" className="ui-filter__close-button" onClick={onRemove}>
          <Icon className="ui-filter__close-button-ico" icon="close" />
        </Button>
      )}
    </div>
  );
};

Filter.propTypes = {
  children: PropTypes.node,
  fetchData: PropTypes.func,
  filter: PropTypes.object,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  assessmentType: PropTypes.bool,
};

export default Filter;
