import React from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';
import Form from 'ui/Form';
import Input from 'ui/Input';
import Checkbox from 'ui/Checkbox';

import { passwordRules } from './schema';
import PasswordRulesBlock from './passwordRulesBlock';
import { messages } from './messages';

const NewPasswordForm = ({ showOldPassword, onSubmit, checkTermsAndConditions, children }) => {
  const {
    errors,
    values: { oldPasswordValue, password, passwordConfirmation, termsAndConditions },
    handleSubmit,
    handleChange,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      showOldPassword,
      oldPasswordValue: '',
      password: '',
      passwordConfirmation: '',
      termsAndConditions: false,
      validateTermsAndConditions: checkTermsAndConditions,
    },

    validationSchema: passwordRules(
      translate(messages.passwordLengthValidation),
      translate(messages.passwordSpecialCharsQtyValidation),
      translate(messages.passwordNumbersQtyValidation),
      translate(messages.passwordUpperValidation),
      translate(messages.passwordLowerValidation),
      translate(messages.passwordRequired),
      translate(messages.passwordConfirmationMatch),
      translate(messages.passwordConfirmationRequired),
      checkTermsAndConditions ? translate(messages.termsAndConditionsCheck) : null,
    ),

    onSubmit: () => onSubmit(password, showOldPassword && oldPasswordValue),
  });

  const handleCheckboxChange = () => {
    setFieldValue('termsAndConditions', !termsAndConditions);
  };

  return (
    <div className="new-password-form">
      <Form onSubmit={handleSubmit} autoComplete="off">
        {showOldPassword && (
          <Form.Item>
            <Input.Password
              block
              autoComplete="off"
              name="oldPasswordValue"
              label={translate(messages.oldPasswordLabel)}
              placeholder={translate(messages.passwordPlaceholder)}
              onChange={handleChange}
              value={oldPasswordValue}
              error={errors.oldPasswordValue}
              data-test="new-password-form-input-old-password"
            />
          </Form.Item>
        )}
        <Form.Item>
          <Input.Password
            block
            name="password"
            label={translate(messages.newPasswordLabel)}
            placeholder={translate(messages.passwordPlaceholder)}
            onChange={handleChange}
            value={password}
            error={touched.password ? errors.password : ''}
            data-test="new-password-form-input-password"
            data-test-error="new-password-form-input-password-error"
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            block
            name="passwordConfirmation"
            label={translate(messages.confirmPasswordLabel)}
            placeholder={translate(messages.passwordPlaceholder)}
            onChange={handleChange}
            value={passwordConfirmation}
            error={touched.passwordConfirmation ? errors.passwordConfirmation : ''}
            data-test="new-password-form-input-password-confirmation"
            data-test-error="new-password-form-input-password-confirmation-error"
          />
        </Form.Item>
        <Form.Item>
          <PasswordRulesBlock />
        </Form.Item>
        {checkTermsAndConditions && (
          <Form.Item>
            <Checkbox
              onChange={handleCheckboxChange}
              checked={termsAndConditions}
              name={'terms-and-conditions'}
              mode="checkbox"
              error={touched.termsAndConditions ? errors.termsAndConditions : ''}
            >
              {translate(messages.termsAndConditionsLabel1)}{' '}
              <a href="https://findings.co/eula/" target="_blank" rel="noopener noreferrer">
                {translate(messages.termsAndConditionsLabel2)}
              </a>
            </Checkbox>
          </Form.Item>
        )}
        {children}
      </Form>
    </div>
  );
};

NewPasswordForm.propTypes = {
  showOldPassword: PropTypes.bool,
  onSubmit: PropTypes.func,
  children: PropTypes.any,
  checkTermsAndConditions: PropTypes.bool,
};

NewPasswordForm.defaultProps = {
  showOldPassword: false,
};

export default NewPasswordForm;
