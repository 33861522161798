import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import { translate } from 'utils/index';
import { generalMessages } from 'constants/messages';
import Icon from 'ui/Icon';

import './index.scss';

const NewRemoveConfirmationModal = ({ confirmLoading, onCancel, onOk, text, title, open }) => {
  const footer = [
    <Button
      key="cancel-button"
      className="new-remove-confirmation-modal__footer-cancel-button"
      size="sm"
      color="white"
      onClick={onCancel}
      fluid
      data-test="new-remove-confirmation-cancel-button"
    >
      {translate(generalMessages.cancel)}
    </Button>,
    <Button
      key="ok-button"
      className="new-remove-confirmation-modal__footer-ok-button"
      size="sm"
      color="red"
      onClick={onOk}
      fluid
      type="submit"
      loading={confirmLoading}
      data-test="new-remove-confirmation-ok-button"
    >
      {translate(generalMessages.yesRemove)}
    </Button>,
  ];

  const titleWrapper = (
    <span>
      <Icon icon="info" /> {title}
    </span>
  );

  return (
    <Modal
      className="new-remove-confirmation-modal"
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      onOk={onOk}
      width={645}
      open={open}
      title={titleWrapper}
      footer={footer}
    >
      {text}
    </Modal>
  );
};

NewRemoveConfirmationModal.propTypes = {
  confirmLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  text: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
};

export default NewRemoveConfirmationModal;
