// this file defines the allowd filters for each filterable list upstram/downstream

import { eFilterKeys } from './lib';

export const DASHBOARD_ALLOWED_FILTERS_DOWNSTREAM = [
  eFilterKeys.vendorName,
  eFilterKeys.vendorGroup,
  eFilterKeys.vendorStatus,
  eFilterKeys.vendorRiskScore,
  eFilterKeys.vendorEngagementScore,
  // eFilterKeys.businessOwner,
  // eFilterKeys.auditor,
  eFilterKeys.assessmentName,
  // eFilterKeys.assessmentScore,
  eFilterKeys.assessmentStatus,
  eFilterKeys.riskreconGrade,
  // eFilterKeys.riskreconCriticalFindings,
  // eFilterKeys.awsCloudMonitoring,
  // eFilterKeys.azureCloudMonitoring,
  // eFilterKeys.securityScorecard,
  // eFilterKeys.findingsStatus,
  // eFilterKeys.findingsCriticality,
  eFilterKeys.evidenceExpiration,
  eFilterKeys.organizationName,
  eFilterKeys.response,
];

export const ASSESSMENTS_LIST_ALLOWED_FILTERS_DOWNSTREAM = [
  eFilterKeys.vendorName,
  eFilterKeys.vendorGroup,
  eFilterKeys.vendorStatus,
  eFilterKeys.vendorRiskScore,
  eFilterKeys.vendorEngagementScore,
  // eFilterKeys.businessOwner,
  // eFilterKeys.auditor,
  eFilterKeys.assessmentName,
  // eFilterKeys.assessmentScore,
  eFilterKeys.assessmentStatus,
  eFilterKeys.riskreconGrade,
  // eFilterKeys.riskreconCriticalFindings,
  // eFilterKeys.awsCloudMonitoring,
  // eFilterKeys.azureCloudMonitoring,
  // eFilterKeys.securityScorecard,
  // eFilterKeys.findingsStatus,
  // eFilterKeys.findingsCriticality,
  eFilterKeys.evidenceExpiration,
  eFilterKeys.organizationName,
  eFilterKeys.response,
];
export const ASSESSMENTS_LIST_ALLOWED_FILTERS_UPSTREAM = [
  eFilterKeys.customerName,
  eFilterKeys.customerRiskScore,
  eFilterKeys.assessmentName,
];

export const VENDORS_LIST_ALLOWED_FILTERS = [
  eFilterKeys.vendorName,
  eFilterKeys.vendorGroup,
  eFilterKeys.vendorStatus,
  eFilterKeys.vendorRiskScore,
  eFilterKeys.vendorEngagementScore,
  // eFilterKeys.businessOwner,
  // eFilterKeys.auditor,
  eFilterKeys.assessmentName,
  // eFilterKeys.assessmentScore,
  eFilterKeys.assessmentStatus,
  eFilterKeys.riskreconGrade,
  // eFilterKeys.riskreconCriticalFindings,
  // eFilterKeys.awsCloudMonitoring,
  // eFilterKeys.azureCloudMonitoring,
  // eFilterKeys.securityScorecard,
  // eFilterKeys.findingsStatus,
  // eFilterKeys.findingsCriticality,
  eFilterKeys.evidenceExpiration,
  eFilterKeys.organizationName,
  eFilterKeys.response,
];

export const CUSTOMERS_LIST_ALLOWED_FILTERS = [
  eFilterKeys.customerName,
  eFilterKeys.assessmentName,
  eFilterKeys.customerRiskScore,
];

export const FINDINGS_LIST_ALLOWED_FILTERS_DOWNSTREAM = [
  eFilterKeys.vendorName,
  eFilterKeys.vendorGroup,
  eFilterKeys.vendorStatus,
  eFilterKeys.vendorRiskScore,
  eFilterKeys.vendorEngagementScore,
  // eFilterKeys.businessOwner,
  // eFilterKeys.auditor,
  eFilterKeys.assessmentName,
  // eFilterKeys.assessmentScore,
  eFilterKeys.assessmentStatus,
  eFilterKeys.riskreconGrade,
  // eFilterKeys.riskreconCriticalFindings,
  // eFilterKeys.awsCloudMonitoring,
  // eFilterKeys.azureCloudMonitoring,
  // eFilterKeys.securityScorecard,
  eFilterKeys.findingsStatus,
  eFilterKeys.findingsCriticality,
  eFilterKeys.evidenceExpiration,
  eFilterKeys.organizationName,
  eFilterKeys.response,
];

export const FINDINGS_LIST_ALLOWED_FILTERS_UPSTREAM = [
  eFilterKeys.customerName,
  eFilterKeys.assessmentName,
  eFilterKeys.findingsCriticality,
  eFilterKeys.findingsStatus,
];

export const EVIDENCES_LIST_ALLOWED_FILTERS_DOWNSTREAM = [
  eFilterKeys.vendorName,
  eFilterKeys.vendorGroup,
  eFilterKeys.vendorStatus,
  eFilterKeys.vendorRiskScore,
  // eFilterKeys.vendorEngagementScore,
  // eFilterKeys.businessOwner,
  // eFilterKeys.auditor,
  eFilterKeys.assessmentName,
  // eFilterKeys.assessmentScore,
  // eFilterKeys.assessmentStatus,
  // eFilterKeys.riskreconGrade,
  // eFilterKeys.riskreconCriticalFindings,
  // eFilterKeys.awsCloudMonitoring,
  // eFilterKeys.azureCloudMonitoring,
  // eFilterKeys.securityScorecard,
  // eFilterKeys.findingsStatus,
  // eFilterKeys.findingsCriticality,
  eFilterKeys.evidenceExpiration,
  eFilterKeys.organizationName,
  eFilterKeys.response,
];
