import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

function HrWithCildren({ children, ...props }) {
  return (
    <span className="hr-with-children" {...props}>
      {children}
    </span>
  );
}

HrWithCildren.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HrWithCildren;
