import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CardWithLogo from 'components/CardWithLogo';
import StageDescription from 'pages/SoBoPages/stageDescription';
import { NEW_VENDOR_BO_PAGE } from 'pages/SoBoPages/NewVendor/lib';
import { translate } from 'utils/index';
import { sanitizer } from 'utils/sanitizer';
import { messages } from 'pages/SoBoPages/NewVendor/messages';
import { selectSoBoPages } from 'pages/SoBoPages/selectors';

import ladyWithCard from 'assets/svgs/lady-with-card.svg';
import './stageOne.scss';

const StageOneDescription = () => {
  return (
    <>
      <StageDescription
        title={translate(messages.stageOneLeftDescriptionTitle)}
        text={translate(messages.stageOneLeftDescriptionText)}
      />
      <img src={ladyWithCard} alt="lady-with-card" className="bo-page-stage-one__illustration" />
    </>
  );
};

const StageOne = ({ setStage }) => {
  const {
    additionalData: { generalDescriptionTitle, generalDescription },
  } = useSelector(selectSoBoPages);

  const onClick = () => {
    setStage(NEW_VENDOR_BO_PAGE.STAGE_2);
  };

  return (
    <div className="bo-page-stage-one">
      <div className="bo-page-stage-one__content">
        {generalDescriptionTitle && <h2>{generalDescriptionTitle}</h2>}
        {!generalDescriptionTitle && (
          <div className="bo-page-stage-one__title">
            <h1>{translate(messages.stageOneDefaultTitle)}</h1>
            <h3>{translate(messages.stageOneDefaultSubTitle)}</h3>
          </div>
        )}
        {generalDescription && (
          <div dangerouslySetInnerHTML={{ __html: sanitizer(generalDescription) }} />
        )}
        {!generalDescription && (
          <div className="bo-page-stage-one__description">
            <p>{translate(messages.stageOneDefaultDescriptionPart1)}</p>
            <p>
              {translate(messages.stageOneDefaultDescriptionPart2)}{' '}
              <a href="mailto:support@findings.co">support@findings.co</a>
            </p>
          </div>
        )}
      </div>
      <CardWithLogo.Footer rightButtonOnClick={onClick} />
    </div>
  );
};

StageOne.Description = StageOneDescription;

StageOne.propTypes = {
  setStage: PropTypes.func,
};

export default StageOne;
