import React, { useState } from 'react';
import { useFormik } from 'formik';
import { notification } from 'antd';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { requestTrustPartner } from 'api/organizations';
import { messages } from 'pages/Organization/messages';
import { translate } from 'utils/index';
import Button from 'ui/Button';
import Input from 'ui/Input';
import Popup from 'ui/Popup';
import { useTheme } from 'hooks/theme';

import '../trustedOrganizations.scss';

const addOrganizationSchema = (emailError, emailRequired) =>
  yup.object().shape({
    email: yup.string().email(emailError).required(emailRequired),
  });

const AddOrganizationButton = ({ refetchOrganizations, ...props }) => {
  const [showPopup, setShowPopup] = useState(false);
  const { colorTheme } = useTheme();
  const initValues = { email: '' };

  const setPopupOnClick = () => {
    setShowPopup(!showPopup);
    setValues(initValues, false);
    setErrors({});
  };

  const {
    errors,
    values: { email },
    handleSubmit,
    handleChange,
    setValues,
    setErrors,
  } = useFormik({
    validateOnChange: false,

    initialValues: {
      email: '',
    },

    validationSchema: addOrganizationSchema(
      translate(messages.orgEditUserEmailErrorFormat),
      translate(messages.orgEditUserEmailRequired),
    ),

    onSubmit: async () => {
      await requestTrustPartner(email);
      await refetchOrganizations();
      setShowPopup(!showPopup);
      await setValues(initValues);

      return notification.success({ message: translate(messages.orgSettingsNewOrgSuccess) });
    },
  });

  return (
    <>
      <Button
        size="sm"
        name={translate(messages.orgEditTrustedOrganizationsOrganization)}
        // beforeComponent={<Icon className="organizations-settings-page__button-icon" icon="plus" />}
        beforeIcon="plus"
        color={colorTheme}
        onClick={setPopupOnClick}
        className="trusted-organizations-tab__add-button"
        data-test="trusted-organizations-tab-button-add-organization"
        {...props}
      />
      <Popup.WithForm
        className="organizations-settings-page__new-org-popup"
        open={showPopup}
        onClickCancel={setPopupOnClick}
        withIconOnTitle="plus"
        title={translate(messages.addTrustedOrganization)}
        width={400}
        onClickOk={handleSubmit}
        okButtonText={translate(messages.sendRequest)}
      >
        <Input
          block
          name="email"
          type="text"
          value={email}
          placeholder={translate(messages.emailPlaceholder)}
          onChange={handleChange}
          error={errors.email}
          data-test="organization-edit-new-user-input-email"
        />
      </Popup.WithForm>
    </>
  );
};

AddOrganizationButton.propTypes = {
  refetchOrganizations: PropTypes.func,
};

export default AddOrganizationButton;
