import React from 'react';
import { useSelector } from 'react-redux';

import { selectFinding } from 'pages/Assessment/finalized/findings/selectors';
import Recommendation from './recommendation';
import './index.scss';

const Recommendations = () => {
  const { recommendations = [] } = useSelector(selectFinding);

  return (
    <div className="assessment-finalized-recommendations">
      {recommendations.map((recommendation, index) => (
        <Recommendation key={recommendation.id} recommendation={recommendation} index={index} />
      ))}
    </div>
  );
};

export default Recommendations;
