import React from 'react';
import { PieChart as PieChartRecharts, Pie, Cell } from 'recharts';
import PropTypes from 'prop-types';
import './index.scss';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value,
  name,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      <tspan>{name}</tspan>
      <tspan dx="-3rem" dy="1.2em">{`${(percent * 100).toFixed(0)}% (${value})`}</tspan>
    </text>
  );
};

const PieChart = ({ data }) => {
  return (
    <PieChartRecharts width={400} height={400} className="ui-pie-chart-container">
      <Pie
        data={data}
        cx={200}
        cy={200}
        label={renderCustomizedLabel}
        outerRadius={80}
        dataKey="value"
      >
        {data.map(({ id, color }) => (
          <Cell key={`cell-${id}`} fill={color} />
        ))}
      </Pie>
    </PieChartRecharts>
  );
};

PieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default PieChart;
