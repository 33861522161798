import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Button from 'ui/Button';
import Card from 'ui/Card';
import { getProfile } from 'api/profile';
import { selectProfile } from 'pages/Profile/selectors';
import { useAppNavigate } from 'hooks/navigation';
import ROUTES from 'constants/routes';
import { translate } from 'utils/index';
import { messages } from './messages';

import logo from 'assets/svgs/logo-white.svg';
import dataSecurity from 'assets/svgs/data-security.svg';
import securityFirst from 'assets/svgs/security-first.svg';

import './index.scss';

const NewUserActivation = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { user } = useSelector(selectProfile);
  const appNavigate = useAppNavigate();

  useEffect(() => {
    if (params?.token) dispatch(getProfile({ token: params.token }));
  }, []);

  const searchParamsObject = Object.fromEntries(searchParams.entries());

  return (
    <div className="new-user-activation">
      {user.fullname !== undefined && (
        <Card className="new-user-activation__card">
          <div className="new-user-activation__card__left-box">
            <div className="new-user-activation__card__left-box__content">
              <h1>{translate(messages.welcomeLeft)}</h1>
              <img src={logo} alt="findings-logo" />
              <div className="new-user-activation__card__left-box__content__divider"></div>
              <p>{translate(messages.welcomeAuditAi)}</p>
            </div>
          </div>
          <div className="new-user-activation__card__inner">
            <div className="new-user-activation__card__inner__title">
              <h2> {translate(messages.welcomeTitle)}</h2>
              <p>{translate(messages.welcomeSubtitle)}</p>
            </div>
            <div className="new-user-activation__card__inner__content">
              <img src={dataSecurity} alt="data-security" />
              <div>
                <h3>{translate(messages.welcomeContentSecurityTitle)}</h3>
                <p>{translate(messages.welcomeContentSecurityText)}</p>
              </div>
            </div>
            <div className="new-user-activation__card__inner__content">
              <img src={securityFirst} alt="data-security" />
              <div>
                <h3>{translate(messages.welcomeContentPrivacyTitle)}</h3>
                <p>{translate(messages.welcomeContentPrivacyText)}</p>
              </div>
            </div>
            <div className="new-user-activation__card__inner__footer">
              <p>{translate(messages.welcomeFooterText)}</p>
              <Button
                data-test="new-user-welcome-button-next"
                color="pink"
                afterIcon="right-arrow"
                onClick={() =>
                  appNavigate(
                    params.assessmentId ? ROUTES.ONBOARD_ASSESSMENT : ROUTES.ONBOARD,
                    params,
                    {},
                    false,
                    searchParamsObject,
                  )
                }
              >
                {translate(messages.welcomeNextButton)}
              </Button>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};

export default NewUserActivation;
