import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';

import emptyLogo from 'assets/svgs/emptyChart-barChart.svg';
import { chartTheme } from 'pages/Dashboard/lib';
import { translate } from 'utils/index';
import CustomNivoChartTooltip from '../customNivoChartTooltip';
import ROUTES from 'constants/routes';
import { getNavPathWithFilters, useAppNavigate, useGetPath } from 'hooks/navigation';
import { FILTERABLE_KEYS, filtersMetadata } from 'components/Filters/lib';
import { useSelector } from 'react-redux';
import colors from 'ui/colors.scss';

import './index.scss';

const BarChart = ({ chart }) => {
  const { data } = chart;
  const appNavigate = useAppNavigate();
  const getPath = useGetPath();
  const { filtersToApi } = useSelector(
    (state) => state.filters.allFilters[FILTERABLE_KEYS.DASHBOARD_FILTERS],
  );

  const mappedData = useMemo(() => {
    return data?.dataset?.map((item) => {
      return {
        ...item,
        [data.indexBy]: item[data.indexBy].key
          ? translate({
              key: item[data.indexBy].key,
              defaultMessage: item[data.indexBy].value,
            })
          : item[data.indexBy],
      };
    });
  }, [data]);

  const handleClick = (clickData) => {
    if (!ROUTES[data.navRoute] || !data?.filterKey || !data?.indexBy) return;
    const isMultipleKeys = filtersMetadata[data.filterKey].multipleKeys;
    const selectedItem = data?.dataset[clickData?.index][data?.indexBy];
    if (!selectedItem) return;

    const selected = [
      {
        key: selectedItem.key || selectedItem,
        value: selectedItem.key
          ? translate({
              key: selectedItem.key,
              defaultMessage: selectedItem.value,
            })
          : selectedItem,
      },
    ];

    const filters = {
      ...filtersToApi,
      [data.filterKey]: {
        selected: isMultipleKeys ? [selected] : selected,
      },
    };

    appNavigate(getNavPathWithFilters(getPath(ROUTES[data.navRoute]), filters));
  };

  return (
    <div className="dashboard__chart__bar-chart">
      {data && data.keys && data.dataset && data.indexBy ? (
        <ResponsiveBar
          data={mappedData || []}
          onMouseEnter={(_datum, event) => {
            if (ROUTES[data?.navRoute] && data?.filterKey) {
              event.currentTarget.style.cursor = 'pointer';
            }
          }}
          keys={data.keys}
          indexBy={data.indexBy}
          theme={chartTheme}
          onClick={handleClick}
          margin={{ top: 10, right: 0, bottom: 50, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={({ data }) => {
            return data.color || colors.gray200;
          }}
          borderRadius={4}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: translate({
              key: data.legends?.x?.key,
              defaultMessage: data.legends?.x?.value,
            }),
            legendPosition: 'middle',
            legendOffset: 40,
            truncateTickAt: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: translate({
              key: data.legends?.y?.key,
              defaultMessage: data.legends?.y?.value,
            }),
            legendPosition: 'middle',
            legendOffset: -50,
            truncateTickAt: 0,
          }}
          enableGridX={true}
          enableTotals={false}
          labelSkipWidth={12}
          labelSkipHeight={10}
          labelTextColor={({ data }) => {
            return data?.data?.labelColor || '#fff';
          }}
          legends={[]}
          tooltip={(data) => <CustomNivoChartTooltip data={data} chartType={chart.chartType} />}
        />
      ) : (
        <img src={emptyLogo} alt="no-data" />
      )}
    </div>
  );
};

BarChart.propTypes = {
  chart: PropTypes.object,
};

export default BarChart;
