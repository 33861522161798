export const messages = {
  assign: {
    key: 'PendingUnassignedAssessment.assign',
    defaultMessage: 'Assign',
  },
  youHaveGotNewAssessments: {
    key: 'PendingUnassignedAssessment.youHaveGotNewAssessments',
    defaultMessage: 'You have got new assessments',
  },
  title: {
    key: 'PendingUnassignedAssessment.title',
    defaultMessage: 'TITLE',
  },
  subTitle: {
    key: 'PendingUnassignedAssessment.subTitle',
    defaultMessage:
      'Since you belong to more than one organization, Please assign to the relevant organization or create a new one. ',
  },
  toVendor: {
    key: 'PendingUnassignedAssessment.toVendor',
    defaultMessage: 'TO VENDOR',
  },
  toCustomer: {
    key: 'PendingUnassignedAssessment.toCustomer',
    defaultMessage: 'TO CUSTOMER',
  },
  fromCustomer: {
    key: 'PendingUnassignedAssessment.fromCustomer',
    defaultMessage: 'FROM CUSTOMER',
  },
  fromVendor: {
    key: 'PendingUnassignedAssessment.fromVendor',
    defaultMessage: 'FROM VENDOR',
  },
  contactEmail: {
    key: 'PendingUnassignedAssessment.contactEmail',
    defaultMessage: 'CONTACT E-MAIL',
  },
  assessments: {
    key: 'PendingUnassignedAssessment.assessments',
    defaultMessage: 'ASSESSMENTS',
  },
  company: {
    key: 'PendingUnassignedAssessment.company',
    defaultMessage: 'COMPANY',
  },
  assignOrganization: {
    key: 'PendingUnassignedAssessment.assignOrganization',
    defaultMessage: 'Assign organization',
  },
  selectOrganization: {
    key: 'PendingUnassignedAssessment.selectOrganization',
    defaultMessage: 'Select organization',
  },
  createNewOrganization: {
    key: 'PendingUnassignedAssessment.createNewOrganization',
    defaultMessage: 'CREATE NEW ORGANIZATION',
  },
  addOrganizationModalTitle: {
    key: 'PendingUnassignedAssessment.addOrganizationModalTitle',
    defaultMessage: 'Create new organization',
  },
  enterOrganizationName: {
    key: 'PendingUnassignedAssessment.enterOrganizationName',
    defaultMessage: 'Enter organization name',
  },
  nameRequired: {
    key: 'PendingUnassignedAssessment.nameRequired',
    defaultMessage: 'Name required',
  },
  organizationRequired: {
    key: 'PendingUnassignedAssessment.organizationRequired',
    defaultMessage: 'Organization required',
  },
};

export default {
  prefix: 'PendingUnassignedAssessment',
  messages,
};
