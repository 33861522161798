import React from 'react';
import PropTypes from 'prop-types';
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';

import { selectProfile } from 'pages/Profile/selectors';
import { messages } from './messages';
import { translate } from 'utils/index';
import Input from 'ui/Input';
import Button from 'ui/Button';
import { ONBOARDING_FORM_STAGE } from 'pages/Activation/Onboarding/lib';
import { loginUser } from 'api/authentication';
import { NOTIFICATION_DURATION } from 'constants/general';
import { API_STATUS } from 'constants/api';
import { ORG_ID_AUTH_KEY } from 'utils/auth';
import { setCookie } from 'utils/apiHelpers';
import Card from 'ui/Card';

import quoteIcon from 'assets/svgs/quote-icon.svg';
import nasdaqLogo from 'assets/svgs/nasdaq-logo.svg';
import HrWithCildren from 'ui/HrWithCildren';

const StageZero = ({ setStage }) => {
  const dispatch = useDispatch();

  const { user } = useSelector(selectProfile);

  const handleGoogleLogin = async (credentialResponse) => {
    const loginResp = await dispatch(
      loginUser({ sso: true, ssoToken: credentialResponse?.credential }),
    );

    if (loginResp?.payload?.status === API_STATUS.FAILED) {
      notification.error('Unable to login with google.');
    }

    if (loginResp?.payload?.org_id) {
      setCookie(ORG_ID_AUTH_KEY, loginResp?.payload?.org_id);
      notification.success({
        message: translate(messages.welcome),
        duration: NOTIFICATION_DURATION.ONE_SECOND,
        className: 'notification-login-form-success',
      });
    }

    setStage(ONBOARDING_FORM_STAGE.STAGE_2);
  };

  return (
    <Card className="onboarding-card stage-zero">
      <div className="onboarding-card__left-box">
        <div className="onboarding-card__left-box__content">
          <img src={quoteIcon} alt="quote" />
          <p>{translate(messages.stageZeroLeftBoxContentP1)}</p>
          <p className="onboarding-card__left-box__content-p2">
            {translate(messages.stageZeroLeftBoxContentP2)}
          </p>
          <img src={nasdaqLogo} alt="nasdaq logo" />
        </div>
      </div>
      <div className="onboarding-card__inner">
        <h1 className="zero-title">{translate(messages.stageZeroTitle)}</h1>
        <div className="google-sso-login-wrapper">
          <GoogleLogin
            ux_mode="popup"
            onSuccess={handleGoogleLogin}
            locale="en-us"
            width={'354'}
            onError={(error) => {
              notification.error(translate(messages.stageZeroGoogleError));
            }}
          />
        </div>
        <HrWithCildren>{translate(messages.stageZeroDivider)}</HrWithCildren>
        <Input
          value={user?.email || ''}
          disabled={true}
          data-test="onboarding-email-disabled-input"
        />
        <Button
          onClick={() => setStage(ONBOARDING_FORM_STAGE.STAGE_1)}
          data-test="continue-stage"
          color="pink"
          className="onboarding-card__inner__continue-to-password-btn"
        >
          {translate(messages.stageZeroNextStep)}
        </Button>
        <p className="onboarding-card__inner__terms">
          {translate(messages.stageZeroTermsText)}{' '}
          <a href="https://findings.co/eula/" target="_blank" rel="noreferrer">
            {translate(messages.stageZeroTermsLink)}
          </a>
        </p>
      </div>
    </Card>
  );
};

StageZero.propTypes = {
  setStage: PropTypes.func,
};

export default StageZero;
