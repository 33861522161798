export const trimMessage = (message) => {
  if (!message) return '';
  return message.length > 150 ? `${message.substring(0, 150)}...` : message;
};

export const calcFilters = (filters) => {
  return filters?.reduce((acc, filter) => {
    const { type, id } = filter;
    if (type === 'vendor' || type === 'customer') acc.participants_filter = id;
    if (type === 'assessment') acc.assessment_filter = id;
    return acc;
  }, {});
};
