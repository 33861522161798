import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  AddProfileCustomerBulk,
  AddProfileVendor,
  GetProfileCustomerBulkLog,
  GetProfileVendor,
  GetProfileVendors,
  RemoveProfileVendor,
  RunContinuousMonitoring,
  SetProfileVendor,
  SetProfileVendorEnableStatus,
  SetProfileVendorStatus,
  SetVendorBoAuditorUsers,
} from 'api/endpoints';
import { parseFileLinksFromAssessment } from 'utils/assessment';
import { FILTERABLE_KEYS } from 'components/Filters/lib';
import { MASKED_VALUE } from 'constants/general';

const parseVendorPayload = (args, isEdit) => {
  const payload = {
    email: args.email,
    phone: args.phone,
    fullname: args.companyContact,
    corporateName: args.corporateName,
    companyDomain:
      (args?.obfuscateDomain && args.companyDomain !== MASKED_VALUE) || !args?.obfuscateDomain
        ? args.companyDomain
        : null,
    corporateIndustriy: -1,
    corporateGeography: -1,
    corporateSize: -1,
    [isEdit ? 'setVendorGroups' : 'addVendorGroups']: args['setVendorGroups'] || -1,
    color: -1,
    assessments: args.assessments,
    assessmentsGroups: args.assessmentsGroups,
    'assessments-period': args['assessments-period'] || 0,
    apps: args.apps,
    vendorStatus: args.vendorStatus || 0,
    _assessmentJson: JSON.stringify(args.assessmentJson),
  };

  if (!isEdit) {
    payload['file-links'] = JSON.stringify(parseFileLinksFromAssessment(args.assessmentJson));
  }

  return payload;
};

export const addProfileVendorRequest = async (args) => {
  return axios.post(AddProfileVendor.url, parseVendorPayload(args));
};

export const addProfileVendor = createAsyncThunk('AddProfileVendor', addProfileVendorRequest);

const GetProfileVendorsRequest = async (args, { getState }) => {
  const { vendors, filters } = getState();

  const payload = {
    'get-vendors-offset': vendors.pagination.current,
    'get-vendors-count': vendors.pagination.pageSize,
    'get-vendors-search': vendors.search,
    'column': vendors.sorter.column || '',
    'descending': vendors.sorter.descending,
    archive: vendors.archive,
    filters: JSON.stringify(
      filters.allFilters[FILTERABLE_KEYS.VENDORS_LIST_FILTERS].filtersToApi || {},
    ),
  };

  return axios.post(GetProfileVendors.url, payload);
};

export const getProfileVendors = createAsyncThunk('GetProfileVendors', GetProfileVendorsRequest);

const GetProfileVendorRequest = async (token) => {
  return axios.post(GetProfileVendor.url, {
    'get-vendor-vendor-token': token,
  });
};

export const getProfileVendor = createAsyncThunk('GetProfileVendor', GetProfileVendorRequest);

export const SetProfileVendorRequest = async (args) => {
  return axios.post(SetProfileVendor.url, {
    ...parseVendorPayload(args, true),
    vendorToken: args.vendorId,
  });
};

export const setProfileVendor = createAsyncThunk('SetProfileVendor', SetProfileVendorRequest);

const removeVendorRequest = async ({ id }) => {
  const payload = {
    'remove-vendor-profile-vendor-id': id,
  };

  return axios.post(RemoveProfileVendor.url, payload);
};

export const removeVendor = createAsyncThunk('removeVendor', removeVendorRequest);

const setVendorEnableStatusRequest = async ({ id, isActive }) => {
  const payload = {
    vendorToken: id,
    vendorEnable: isActive,
  };

  return axios.post(SetProfileVendorEnableStatus.url, payload);
};

export const setVendorEnableStatus = createAsyncThunk(
  'setVendorEnableStatus',
  setVendorEnableStatusRequest,
);

const uploadVendorCustomerBulkRequest = async (payload, abortController) => {
  return axios.post(AddProfileCustomerBulk.url, payload, { signal: abortController?.signal });
};

export const uploadVendorCustomerBulk = createAsyncThunk(
  'uploadVendorCustomerBulk',
  uploadVendorCustomerBulkRequest,
);

const getProfileCustomerBulkLogRequest = async (payload) => {
  return axios.get(GetProfileCustomerBulkLog.url, { params: payload });
};

export const getProfileCustomerBulkLog = createAsyncThunk(
  'getProfileCustomerBulkLogRequest',
  getProfileCustomerBulkLogRequest,
);

const runContinuousMonitoringRequest = async (payload) => {
  return axios.post(RunContinuousMonitoring.url, payload);
};

export const runContinuousMonitoring = createAsyncThunk(
  'runContinuousMonitoringRequest',
  runContinuousMonitoringRequest,
);

export const changeVendorStatusRequest = async ({ vendorId, vendorStatus }) => {
  const payload = {
    vendorStatus,
    vendorToken: vendorId,
  };
  return axios.post(SetProfileVendorStatus.url, payload);
};

export const changeVendorStatus = createAsyncThunk('changeVendorStatus', changeVendorStatusRequest);

export const setVendorBoAuditorUsersRequest = async ({ vendorId, emails }) => {
  const payload = {
    vendorBoAuditorEmails: emails.join(','),
    vendorId: vendorId,
  };
  return axios.post(SetVendorBoAuditorUsers.url, payload);
};

export const setVendorBoAuditorUsers = createAsyncThunk(
  'setVendorBoAuditorUsers',
  setVendorBoAuditorUsersRequest,
);
