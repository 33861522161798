import Input from './input';
import Password from './password';
import Search from './search';
import Email from './email';

Input.Password = Password;
Input.Search = Search;
Input.Email = Email;

export default Input;
