export const messages = {
  detailsFormFullnameLabel: {
    key: 'SoBo Pages.detailsFormFullnameLabel',
    defaultMessage: 'Full name',
  },
  detailsFormEmailLabel: {
    key: 'SoBo Pages.detailsFormEmailLabel',
    defaultMessage: 'Email',
  },
  detailsFormCompanyLabel: {
    key: 'SoBo Pages.detailsFormCompanyLabel',
    defaultMessage: 'Company',
  },
  detailsFormFullnamePlaceholder: {
    key: 'SoBo Pages.detailsFormFullnamePlaceholder',
    defaultMessage: 'Enter name',
  },
  detailsFormEmailPlaceholder: {
    key: 'SoBo Pages.detailsFormEmailPlaceholder',
    defaultMessage: 'Enter Email',
  },
  detailsFormCompanyPlaceholder: {
    key: 'SoBo Pages.detailsFormCompanyPlaceholder',
    defaultMessage: 'Enter Company Name',
  },
  detailsFormFullnameInvalid: {
    key: 'SoBo Pages.detailsFormFullnameInvalid',
    defaultMessage: 'Invalid full name',
  },
  detailsFormFullnameError: {
    key: 'SoBo Pages.detailsFormFullnameError',
    defaultMessage: 'Please enter full name',
  },
  detailsFormFullnameRequired: {
    key: 'SoBo Pages.detailsFormFullnameRequired',
    defaultMessage: 'Full name is required',
  },
  detailsFormEmailError: {
    key: 'SoBo Pages.detailsFormEmailError',
    defaultMessage: 'Invalid email format',
  },
  detailsFormEmailRequired: {
    key: 'SoBo Pages.detailsFormEmailRequired',
    defaultMessage: 'Email is required',
  },
  detailsFormCompanyRequired: {
    key: 'SoBo Pages.detailsFormCompanyRequired',
    defaultMessage: 'Company name is required',
  },
  stageThreeSubmitFail: {
    key: 'SoBo Pages.stageThreeSubmitFail',
    defaultMessage: 'Unable to submit assessment',
  },
  stageThreeFileExist: {
    key: 'SoBo Pages.stageThreeFileExist',
    defaultMessage: 'already exist',
  },
  stageFourTitle: {
    key: 'SoBo Pages.stageFourTitle',
    defaultMessage: 'The Security assessment request was successfully sent',
  },
  stageFourSubTitle: {
    key: 'SoBo Pages.stageFourSubTitle',
    defaultMessage: 'the security team will contact you if needed',
  },
};

export default {
  prefix: 'SoBo Pages',
  messages,
};
