import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const CustomNivoChartTooltip = ({ data, chartType }) => {
  const { value, label, color } = data;
  return (
    <div className="nivo-chart-cust-tooltip">
      <div
        className="nivo-chart-cust-tooltip__color-indicator"
        style={{
          background: color,
        }}
      ></div>
      {label}:&nbsp; <strong> {value}</strong>
    </div>
  );
};

CustomNivoChartTooltip.propTypes = {
  data: PropTypes.object.isRequired,
  chartType: PropTypes.string.isRequired,
};

export default CustomNivoChartTooltip;
