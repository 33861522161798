export function createSorterByString(field) {
  return function sortBy(a, b) {
    const nameA = a[field].toLowerCase();
    const nameB = b[field].toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };
}

export function isEmail(value) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}

export function boldString(str, find) {
  const re = new RegExp(find, 'ig');
  return str.replace(re, `<b>${find}</b>`);
}
