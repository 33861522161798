import React from 'react';
import PropTypes from 'prop-types';

const BriefcaseIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M9.25 3C8.29252 3 7.5 3.79252 7.5 4.75V6H3.75C2.79252 6 2 6.79252 2 7.75V19.75C2 20.7075 2.79252 21.5 3.75 21.5H20.25C21.2075 21.5 22 20.7075 22 19.75V7.75C22 6.79252 21.2075 6 20.25 6H16.5V4.75C16.5 3.79252 15.7075 3 14.75 3H9.25ZM9.25 4.5H14.75C14.8975 4.5 15 4.60248 15 4.75V6H9V4.75C9 4.60248 9.10248 4.5 9.25 4.5ZM3.75 7.5H20.25C20.3975 7.5 20.5 7.60248 20.5 7.75V14.5H13.5V14.25C13.5 13.836 13.164 13.5 12.75 13.5H11.25C10.836 13.5 10.5 13.836 10.5 14.25V14.5H3.5V7.75C3.5 7.60248 3.60248 7.5 3.75 7.5ZM3.5 16H10.5V16.25C10.5 16.664 10.836 17 11.25 17H12.75C13.164 17 13.5 16.664 13.5 16.25V16H20.5V19.75C20.5 19.8975 20.3975 20 20.25 20H3.75C3.60248 20 3.5 19.8975 3.5 19.75V16Z"
      fill={color}
    />
  </svg>
);

BriefcaseIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default BriefcaseIcon;
