import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  addProfileVendor,
  getProfileVendor,
  getProfileVendors,
  setProfileVendor,
} from 'api/vendor';
import { API_STATUS, DEFAULT_PAGINATION } from 'constants/api';

const initialState = {
  vendors: [],
  pagination: DEFAULT_PAGINATION,
  sorter: { column: 'timestampCreated', descending: true },
  search: '',
  currentVendor: undefined,
  riskReconCredits: 0,
  isLoading: false,
  isVendorsFetching: false,
  isVendorFetching: false,
  isFromRecent: false,
  archive: 0,
};

const reducers = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    resetCurrentVendor: (state) => {
      state.currentVendor = undefined;
    },
    setFromRecent: (state, { payload }) => {
      state.isFromRecent = payload;
    },
    setSearch: (state, { payload }) => {
      state.search = payload || '';
    },
    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
    setSorter: (state, { payload }) => {
      state.sorter = payload || {};
    },
    setArchive: (state, { payload }) => {
      state.archive = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProfileVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setProfileVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProfileVendors.pending, (state) => {
        state.isVendorsFetching = true;
      })
      .addCase(getProfileVendor.pending, (state) => {
        state.isVendorFetching = true;
      })
      .addMatcher(
        isAnyOf(getProfileVendors.fulfilled, getProfileVendors.rejected),
        (state, data) => {
          state.isVendorsFetching = false;

          const { status, vendors, total } = data.payload;
          if (status !== API_STATUS.FAILED) {
            state.vendors = vendors;
            state.pagination = {
              ...state.pagination,
              total,
            };
          }
        },
      )
      .addMatcher(
        isAnyOf(
          addProfileVendor.fulfilled,
          addProfileVendor.rejected,
          setProfileVendor.fulfilled,
          setProfileVendor.rejected,
        ),
        (state, data) => {
          state.isLoading = false;
          if (data.payload?.status !== API_STATUS.FAILED) {
            state.currentVendor = data.payload?.vendor || state.currentVendor;
          }
        },
      )
      .addMatcher(isAnyOf(getProfileVendor.fulfilled, getProfileVendor.rejected), (state, data) => {
        state.isVendorFetching = false;
        const { status, vendor, tokens } = data.payload;
        if (status !== API_STATUS.FAILED) {
          state.currentVendor = vendor;
          state.riskReconCredits = tokens || 0;
        }
      });
  },
});

export const {
  resetCurrentVendor,
  setFromRecent,
  setPagination,
  setSorter,
  setSearch,
  setArchive,
} = reducers.actions;

export default reducers;
