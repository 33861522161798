import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './link.scss';

const Link = ({ path, text, block, children, className, color, ...restProps }) => {
  const linkClassNames = classNames('ui-link', className, {
    [`ui-link--${color}-link`]: color,
    'ui-link--block': block,
  });

  return (
    <RouterLink className={linkClassNames} to={path} {...restProps}>
      {text || children}
    </RouterLink>
  );
};

Link.defaultProps = {
  color: 'pink',
};

Link.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  path: PropTypes.string,
  color: PropTypes.oneOf(['pink', 'gray', 'text']),
  text: PropTypes.string,
  block: PropTypes.bool,
};

export default Link;
