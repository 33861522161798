/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import emptyLogo from 'assets/svgs/emptyChart-vendorRiskTable.svg';
import { translate } from 'utils/index';
import { getNavPathWithFilters, useGetPath, useAppNavigate } from 'hooks/navigation';
import ROUTES from 'constants/routes';
import { FILTERABLE_KEYS, filtersMetadata } from 'components/Filters/lib';
import { useSelector } from 'react-redux';
import './index.scss';

const MagicTable = ({ chart }) => {
  const { data } = chart;
  const appNavigate = useAppNavigate();
  const getPath = useGetPath();
  const { filtersToApi } = useSelector(
    (state) => state.filters.allFilters[FILTERABLE_KEYS.DASHBOARD_FILTERS],
  );

  const handleCellClick = (cell) => {
    if (!ROUTES[data.navRoute] || !data.rows.filterKey || !data.cols.filterKey) return;

    const isRowsMultipleKeys = filtersMetadata[data.rows.filterKey].multipleKeys;
    const isColsMultipleKeys = filtersMetadata[data.cols.filterKey].multipleKeys;

    const rowTitleItem = data.rows?.titles[cell.rowIndex];
    const rowSelected = [
      {
        key: rowTitleItem.key || rowTitleItem,
        value: rowTitleItem.key
          ? translate({
              key: rowTitleItem.key,
              defaultMessage: rowTitleItem.value,
            })
          : rowTitleItem,
      },
    ];

    const colTitleItem = data.cols?.titles[cell.cellIndex];
    const colsSelected = [
      {
        key: colTitleItem.key || colTitleItem,
        value: colTitleItem.key
          ? translate({
              key: colTitleItem.key,
              defaultMessage: colTitleItem.value,
            })
          : colTitleItem,
      },
    ];

    const filters = {
      ...filtersToApi,
      [data.rows.filterKey]: {
        selected: isRowsMultipleKeys ? [rowSelected] : rowSelected,
      },
      [data.cols.filterKey]: {
        selected: isColsMultipleKeys ? [colsSelected] : colsSelected,
      },
    };

    appNavigate(getNavPathWithFilters(getPath(ROUTES[data.navRoute]), filters));
  };

  return (
    <div className="dashboard__chart__magic-table">
      {data ? (
        <div className="dashboard__chart__magic-table__container">
          <div className="dashboard__chart__magic-table__legend-left">
            <span>
              {translate({
                key: data?.rows?.legend?.key,
                defaultMessage: data?.rows?.legend?.value,
              })}
            </span>
          </div>
          <div className="dashboard__chart__magic-table__wrapper">
            <table>
              <tbody>
                {data.table?.map((row, rowIndex) => {
                  return (
                    <tr key={'row-' + rowIndex}>
                      <th className="row-th" key={data.rows?.titles[rowIndex] + '-row-head'}>
                        {translate({
                          key: data.rows?.titles[rowIndex]?.key,
                          defaultMessage: data.rows?.titles[rowIndex]?.value,
                        })}
                      </th>

                      {row.map((cell, cellIndex) => {
                        return (
                          <td key={'table-cell-' + rowIndex + '+' + cellIndex}>
                            <Tooltip
                              placement="bottom"
                              title={
                                <div className="dashboard__chart__magic-table__cell__tooltip">
                                  <h5>
                                    {translate({
                                      key: data.rows?.titles[rowIndex]?.key,
                                      defaultMessage: data.rows?.titles[rowIndex]?.value,
                                    })}{' '}
                                    {translate({
                                      key: data.rows?.legend?.key,
                                      defaultMessage: data.rows?.legend?.value,
                                    })}
                                    <br />
                                    {translate({
                                      key: data.cols?.titles[cellIndex]?.key,
                                      defaultMessage: data.cols?.titles[cellIndex]?.value,
                                    })}{' '}
                                    {translate({
                                      key: data.cols?.legend?.key,
                                      defaultMessage: data.cols?.legend?.value,
                                    })}
                                  </h5>
                                  <p>
                                    <span style={{ backgroundColor: cell.color }}></span>{' '}
                                    {cell?.value}{' '}
                                    {translate({
                                      key: data.subjectText?.key,
                                      defaultMessage: data.subjectText?.value,
                                    })}
                                  </p>
                                </div>
                              }
                            >
                              <a
                                role="button"
                                onClick={() => handleCellClick({ rowIndex, cellIndex })}
                                style={{
                                  backgroundColor: cell.color,
                                  cursor: data.navRoute ? 'pointer' : 'default',
                                }}
                                data-test={`magic-table-cell-${rowIndex}-${cellIndex}`}
                              >
                                {cell?.value}
                              </a>
                            </Tooltip>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                <tr key={'col-heads'}>
                  {data.cols?.titles?.map((col, index) => {
                    return (
                      <React.Fragment key={col.key ? col.key : col + '-col-head-fr'}>
                        {index === 0 && <th key="empty-th"></th>}
                        <th key={col + '-col-head'} className="col-th">
                          {col?.key
                            ? translate({ key: col.key, defaultMessage: col.value })
                            : col.key}
                        </th>
                      </React.Fragment>
                    );
                  })}
                </tr>
              </tbody>
            </table>
            <div className="dashboard__chart__magic-table__legend-bottom">
              {translate({
                key: data.cols?.legend?.key,
                defaultMessage: data.cols?.legend?.value,
              })}
            </div>
          </div>
        </div>
      ) : (
        <img src={emptyLogo} alt="no-data" />
      )}
    </div>
  );
};

MagicTable.propTypes = {
  chart: PropTypes.object,
};

export default MagicTable;
