import React from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'ui/Icon';
import { useGetControlById } from 'pages/Assessment/hooks';
import { translate } from 'utils/index';
import { selectSubject, setActiveQuestion, setBackToFollowUpQuestion } from './reducers';
import { selectBackToFollowUpQuestion } from './selectors';
import { scrollToControl } from './lib';
import { messages } from './messages';
import './backToFollowUpQuestion.scss';

const BackToFollowUpQuestion = () => {
  const controlId = useSelector(selectBackToFollowUpQuestion);
  const getControlById = useGetControlById(true);
  const dispatch = useDispatch();

  const control = getControlById(controlId);

  const onBackToFollowUpQuestion = () => {
    dispatch(selectSubject(control.categoryId));
    dispatch(setActiveQuestion(control.id));
    dispatch(setBackToFollowUpQuestion(null));

    scrollToControl(control.id);
  };

  const onCloseBackToFollowUpQuestion = () => {
    dispatch(setBackToFollowUpQuestion(null));
  };

  return (
    <div className="editor-back-to-follow-up-question">
      <div className="editor-back-to-follow-up-question__wrapper">
        <div className="editor-back-to-follow-up-question__action">
          <Button
            className="editor-back-to-follow-up-question__back-button"
            onClick={onBackToFollowUpQuestion}
            type="link"
          >
            <Icon icon="left-arrow" />
            {translate(messages.returnToFollowUpQuestions)}
          </Button>
          <Button
            className="editor-back-to-follow-up-question__close-button"
            onClick={onCloseBackToFollowUpQuestion}
            type="link"
          >
            <Icon icon="close" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BackToFollowUpQuestion;
