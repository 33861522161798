export const messages = {
  newMessageInputDefaultPlaceholder: {
    key: 'chat-component.newMessageInputDefaultPlaceholder',
    defaultMessage: 'Write a message',
  },
  toLongMessageError: {
    key: 'chat-component.toLongMessageError',
    defaultMessage: 'Message too long, please keep it under 1000 characters',
  },
};

export default {
  prefix: 'chat-component',
  messages,
};
