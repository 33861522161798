import React from 'react';
import Form from 'ui/Form';
import Input from 'ui/Input';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';
import { messages } from './messages';
import './index.scss';

const SecondAuthForm = ({ otpCode, setOtpCode, children, error }) => {
  return (
    <>
      <Form.Item className="second-auth-form__item">
        <Input
          block
          error={error}
          label={translate(messages.labelCode)}
          placeholder={translate(messages.inputCode)}
          value={otpCode}
          onChange={({ target: { value } }) => setOtpCode(value)}
          data-test="second-auth-form-code-input"
          data-test-error="second-auth-form-code-error"
        />
      </Form.Item>
      {children}
    </>
  );
};

SecondAuthForm.propTypes = {
  children: PropTypes.any,
  error: PropTypes.string,
  otpCode: PropTypes.string,
  setOtpCode: PropTypes.func,
};

export default SecondAuthForm;
