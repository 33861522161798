import * as ExcelJS from 'exceljs';
import { v4 as uuidv4 } from 'uuid';

import omitBy from 'lodash/omitBy';

import { messages } from 'components/ImportWizard/excel/messages';

import { translate } from 'utils/index';

export const EXCEL_IMPORT_WIZARD_STAGE = {
  WELCOME: 'WELCOME',
  CATEGORIES_MAP: 'CATEGORIES_MAP',
};

export const initSelection = {
  left: 0,
  top: 0,
  width: 0,
  height: 0,
  opacity: 0,
  background: 'rgba(12,66,77,0.2)',
};

export const convertExcelToJson = (file, setFile) => {
  const reader = new FileReader();
  reader.onload = async (e) => {
    const data = e.target.result;
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(data);

    const fileData = workbook.worksheets.map((worksheet) => {
      const sheetName = worksheet.name;
      const json = [];

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        const rowValues = row.values;
        rowValues.shift(); // Remove the first element which is an empty value due to the way ExcelJS handles row indexing
        const rowObject = {};
        rowValues.forEach((value, colIndex) => {
          rowObject[`column${colIndex}`] = value;
        });
        json.push(rowObject);
      });

      return { sheetName, sheetData: json };
    });

    setFile(fileData);
  };
  reader.readAsArrayBuffer(file);
};

export const CATEGORY_COLORS = ['#CDEEFE', '#E1F1E3', '#FFE5C6', '#DECEFF', '#C8D1FF'];
export const CATEGORY_ALT_COLORS = ['#2E8CBC', '#88CE37', '#FFA742', '#744FC0', '#4C5FBD'];

export const getColumnIndex = (letters) => {
  return letters.split('').reduce((r, a) => r * 26 + parseInt(a, 36) - 10, 0);
};

export const getColumnLabel = (value) => {
  return (value + 10).toString(36).toUpperCase();
};

export const createCategory = (id, questions, answers, color, alternativeColor) => {
  return {
    id,
    title: translate(messages.excelImportWizardPlaceholderForCategory),
    color,
    alternativeColor,
    questions,
    answers,
    topDesc: '',
    bottomDesc: '',
  };
};

export const isQuestionExistsEmpty = (questions) => {
  if (!questions) {
    return false;
  }
  return !questions.start || !questions.end;
};

export const parseCategoriesToStructure = (categories, stateSelectedCells, title) => {
  return {
    title,
    sheets: Object.entries(categories).map(([sheetName, sheetData]) => ({
      name: sheetName,
      categories: Object.values(sheetData).map((data) => {
        const { start: questionStart, end: questionEnd } = getValues(
          data.id,
          stateSelectedCells[sheetName],
          'questions',
        );
        const { start: answerStart, end: answerEnd } = getValues(
          data.id,
          stateSelectedCells[sheetName],
          'answers',
        );
        const questionColumn = questionStart[0];
        const questionStartValue = questionStart.slice(1);
        const questionEndValue = questionEnd.slice(1);
        const answerColumn = answerStart[0];
        const answerStartValue = answerStart.slice(1);
        const answerEndValue = answerEnd.slice(1);

        return {
          title: data.title,
          questions: {
            start: `${questionColumn}${questionStartValue}`,
            end: `${questionColumn}${questionEndValue}`,
            position: [0, 0],
          },
          answers: {
            start: `${answerColumn}${answerStartValue}`,
            end: `${answerColumn}${answerEndValue}`,
            position: [0, 0],
          },
        };
      }),
    })),
  };
};

export const createSelectedRows = (start, end) => {
  if (!start && !end) {
    return [];
  }

  if (!start || !end) {
    return [start || end];
  }

  return Array(end - start + 1)
    .fill(undefined)
    .map((_, index) => start + index);
};

export const transformApiResponse = (suggestedQuestions) => {
  let currentSheetIndex = 0;
  const newSelectedCells = {};
  const newCategories = {};
  // transformation of backend response to data which we can use on frontend
  Object.entries(suggestedQuestions)
    .reduce((rows, [, sheetData], index) => [...rows, index, sheetData.relevant_rows], [])
    .flat()
    .forEach((data, colorIndex) => {
      if (typeof data === 'number') {
        currentSheetIndex = data;
        return;
      }
      const [start, end] = data;
      const [sheetName, { question_column, answer_column }] =
        Object.entries(suggestedQuestions)[currentSheetIndex];

      const color = CATEGORY_COLORS[colorIndex % CATEGORY_COLORS.length];
      const alternativeColor = CATEGORY_ALT_COLORS[colorIndex % CATEGORY_ALT_COLORS.length];
      const id = uuidv4();
      newCategories[sheetName] = newCategories[sheetName] || {};

      const selectedRows = createSelectedRows(start, end);
      newSelectedCells[sheetName] = {
        ...newSelectedCells[sheetName],
        ...selectedRows.reduce((acc, row) => {
          const newData = {};
          if (question_column) {
            newData[`${question_column}${row}`] = {
              color,
              id,
              activeSelection: 'questions',
            };
          }
          if (answer_column) {
            newData[`${answer_column}${row}`] = {
              color: alternativeColor,
              id,
              activeSelection: 'answers',
            };
          }
          return {
            ...acc,
            ...newData,
          };
        }, {}),
      };

      const answers = {
        column: answer_column,
        start: answer_column ? `${answer_column}${start}` : '',
        end: answer_column ? `${answer_column}${end}` : '',
      };
      const questions = {
        column: question_column,
        start: question_column ? `${question_column}${start}` : '',
        end: question_column ? `${question_column}${end}` : '',
      };

      newCategories[sheetName][id] = createCategory(
        id,
        questions,
        answers,
        color,
        alternativeColor,
      );
    });

  return {
    newCategories,
    newSelectedCells,
  };
};

export const isCategoryValid = (questions, answers) => {
  return questions.start && questions.end && answers.start && answers.end;
};

export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r},${g},${b}, ${alpha})`;
  }
  return `rgba(${r},${g},${b})`;
};

export const manuallySelectArea = (state, start, end, column, color) => {
  let parsedStart = start;
  let parsedEnd = end;

  if (!start && end) {
    parsedStart = end;
  }

  if (!end && start) {
    parsedEnd = start;
  }

  const startEle = document.querySelector(`[data-row="${parsedStart}"][data-column="${column}"]`);
  const endEle = document.querySelector(`[data-row="${parsedEnd}"][data-column="${column}"]`);

  if (!startEle || !endEle || !column) {
    state.manuallySelection = initSelection;
    return;
  }
  const tableEle = document.getElementById('excel-table');
  const tableDimensions = tableEle.getBoundingClientRect();

  const { left, top, width } = startEle.getBoundingClientRect();
  const { top: endTop, height } = endEle.getBoundingClientRect();

  state.manuallySelection = {
    opacity: 1,
    top: top - tableDimensions.y,
    left: left - tableDimensions.x,
    width,
    height: endTop - top + height,
    background: hexToRGB(color, 0.2),
  };
};

export const updateSelectCells = (state, start, end, column, currentCategory, isQuestion) => {
  const clearCells = cleanCellsForActiveSelections(state);
  const selectedRows = createSelectedRows(start, end);
  const newData = {
    ...clearCells,
    ...selectedRows.reduce((acc, row) => {
      const newData = {};
      newData[`${column}${row}`] = {
        color: isQuestion ? currentCategory.color : currentCategory.alternativeColor,
        id: currentCategory.id,
        activeSelection: state.activeSelection,
      };
      return {
        ...acc,
        ...newData,
      };
    }, {}),
  };
  state.stateSelectedCells[state.activeSheetName] = newData;
  state.currentSelectedCells = state.stateSelectedCells;
};

export const getValues = (categoryId, selectedCellsForCurrentSheet, field) => {
  const sortedSelections = Object.entries(selectedCellsForCurrentSheet)
    .filter(([, cellValue]) => cellValue.id === categoryId && cellValue.activeSelection === field)
    .sort(([cellCodeA], [cellCodeB]) => +cellCodeA.slice(1) - +cellCodeB.slice(1))
    .map(([cellCode]) => cellCode);

  if (sortedSelections.length === 0) {
    return {
      start: '',
      end: '',
    };
  }

  const start = sortedSelections[0];
  const end = sortedSelections.length === 1 ? '' : sortedSelections[sortedSelections.length - 1];
  return {
    start,
    end,
  };
};

export const cleanCellsForActiveSelections = (state) => {
  return omitBy(
    getCellsFromActiveSheet(state),
    (selection) =>
      selection.id === state.activeCategory && state.activeSelection === selection.activeSelection,
  );
};

export const isAreaInActive = (state) => {
  return state.isPreview || !state.activeCategory || !state.activeSelection;
};

export const sortCellsForActiveSelection = (state) => {
  return (
    Object.entries(getCellsFromActiveSheet(state))
      .filter(
        (cellEntry) =>
          cellEntry[1].id === state.activeCategory &&
          cellEntry[1].activeSelection === state.activeSelection,
      )
      .sort(([cellCodeA], [cellCodeB]) => +cellCodeA.slice(1) - +cellCodeB.slice(1)) || []
  );
};

export const getCurrentActiveCategory = (state) => {
  return state.categories[state.activeSheetName][state.activeCategory];
};

export const getCellsFromActiveSheet = (state) => {
  return state.stateSelectedCells[state.activeSheetName];
};
