import React from 'react';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';
import { messages } from 'components/FileBrowser/messages';
import { NavigateIcon } from 'components/Icons';

// this component is displaying placeholder + download button instead of doc
const DocView = ({ handleDownload }) => {
  return (
    <div className="file-browser-doc-view" onClick={handleDownload}>
      <NavigateIcon className="file-browser-doc-view__icon" />
      <h3 className="file-browser-doc-view__text">{translate(messages.downloadDocForView)}</h3>
    </div>
  );
};

DocView.propTypes = { handleDownload: PropTypes.func.isRequired };
export default DocView;
