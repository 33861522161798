import { createSlice } from '@reduxjs/toolkit';
import { getOrganizationAssessmentsLog } from 'api/eventLogs';

const initialState = {
  entries: [],
  offset: 1,
  count: 15,
  loading: false,
};

const reducers = createSlice({
  name: 'eventLogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationAssessmentsLog.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrganizationAssessmentsLog.fulfilled, (state, { payload: { entries } }) => {
        state.entries = entries;
        state.loading = false;
      });
  },
});

export default reducers;
