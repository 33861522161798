import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from 'ui/Button';
import { translate } from 'utils/index';
import Modal from 'ui/Modal';
import FinalizeSvg from 'assets/svgs/finalize.svg';
import { finalizeAssessment, getAssessment } from 'api/assessment';
import { selectProfile } from 'pages/Profile/selectors';
import { AssessmentStatus } from 'constants/assessmentStatus';
import {
  selectAssessment,
  selectAssessmentErrors,
  selectIsCloudMonitoring,
  selectIsCloudMonitoringReviewed,
  selectIsCloudMonitoringTriggered,
} from 'pages/Assessment/selectors';
import { messages } from 'pages/Assessment/messages';
import { useTheme } from 'hooks/theme';
import { calculateErrors } from 'pages/Assessment/finalize/lib';
import { API_STATUS } from 'constants/api';
import { useRole } from 'hooks/useRole';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import {
  selectCategory,
  setCurrentErrorId,
  setCurrentErrorIndex,
  setErrors,
} from 'pages/Assessment/reducers';
import FinalizeLoading from './loading';
import FinalizeComplete from './complete';
import CloudMonitoringReviewPopup from './cloudMonitoringReviewPopup';
import CloudMonitoringActivatePopup from './cloudMonitoringActivatePopup';
import { mixpanelTrackAssessmentSubmitted } from 'utils/mixpanel';
import { calcDueDateDaysDiff } from 'utils/mixpanel_lib';

import './index.scss';

const stages = {
  info: 'info',
  loading: 'loading',
  complete: 'complete',
};

const Finalize = () => {
  const [finalizeModal, setFinalizeModal] = useState(false);
  const [finalization, setFinalization] = useState(true);
  const [isCloudMonitoringReviewPopup, setCloudMonitoringReviewPopup] = useState(false);
  const [isCloudMonitoringActivatePopup, setCloudMonitoringActivatePopup] = useState(false);
  const [stage, setStage] = useState(stages.info);
  const dispatch = useDispatch();
  const { assessmentId } = useParams();
  const assessment = useSelector(selectAssessment);
  const { errorsList, selectedErrorId } = useSelector(selectAssessmentErrors);
  const { user } = useSelector(selectProfile);
  const { colorTheme } = useTheme();
  const { isRestrictedBoSoViewerRole } = useRole();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const isCloudMonitoring = useSelector(selectIsCloudMonitoring);
  const isCloudMonitoringTriggered = useSelector(selectIsCloudMonitoringTriggered);
  const isCloudMonitoringReviewed = useSelector(selectIsCloudMonitoringReviewed);

  useEffect(() => {
    if (!finalizeModal) {
      setStage(stages.info);
    }
  }, [finalizeModal]);

  useEffect(() => {
    const errors = calculateErrors(assessment);

    if (
      !isRestrictedBoSoViewerRole &&
      finalization &&
      !assessment.finalized &&
      assessment?.total_score?.completion === 100 &&
      errors?.errorsCount === 0 &&
      (!isCloudMonitoring ||
        (isCloudMonitoring && isCloudMonitoringTriggered && isCloudMonitoringReviewed))
    ) {
      setTimeout(() => {
        setFinalizeModal(true);
        setFinalization(false);
      }, 1000);
    }
  }, [assessment]);

  const openModal = () => setFinalizeModal(true);

  const handleOk = async () => {
    setStage(stages.loading);
    const response = await dispatch(finalizeAssessment()).unwrap();

    if (response.status === API_STATUS.FAILED) {
      setFinalizeModal(false);
    } else {
      mixpanelTrackAssessmentSubmitted({
        relation: assessment?.customerId,
        assessmentProgress: assessment?.total_score?.completion,
        dueDate: calcDueDateDaysDiff(assessment?.due),
        forceSubmitted: false,
        streamDirection: isMyVendorsTabSelected ? 'downstream' : 'upstream',
        assessmentName: assessment?.title,
        assignedOrganizationName: assessment?.customerName,
        assigneeOrganizationName: user?.current_organization?.label,
      });
    }
  };

  const handleCancel = () => setFinalizeModal(false);

  const onClose = async () => {
    setFinalizeModal(false);
    await dispatch(getAssessment({ id: assessmentId })).unwrap();
  };

  const submit = async () => {
    await handleOk();
    await onClose();
  };

  const customer = assessment.assessor ? user.corporateName : assessment.customerName;

  const renderInfo = () => (
    <div className="assessment-finalize">
      <img className="assessment-finalize__image" src={FinalizeSvg} alt="Index" />
      <div className="assessment-finalize__text">{translate(messages.submit, { customer })}</div>
      {translate(messages.submitDescription)}
    </div>
  );

  const renderStage = useMemo(() => {
    switch (stage) {
      case stages.info:
        return renderInfo();
      case stages.loading:
        return <FinalizeLoading onNext={() => setStage(stages.complete)} />;
      case stages.complete:
        return <FinalizeComplete onClose={onClose} />;
      default:
        return null;
    }
  }, [stage]);

  const handleFinalize = async () => {
    const errors = calculateErrors(assessment);
    if (errors.errorsCount !== 0 || assessment.status >= AssessmentStatus.finalized) {
      await dispatch(setErrors());
      return;
    }

    if (
      !isMyVendorsTabSelected &&
      isCloudMonitoring &&
      isCloudMonitoringTriggered &&
      !isCloudMonitoringReviewed
    ) {
      return setCloudMonitoringReviewPopup(true);
    }

    if (!isMyVendorsTabSelected && isCloudMonitoring && !isCloudMonitoringTriggered) {
      return setCloudMonitoringActivatePopup(true);
    }

    setStage(stages.info);

    openModal();
  };

  useEffect(() => {
    const categoriesWithErrors = Object.keys(errorsList);

    if (!selectedErrorId && categoriesWithErrors?.length > 0) {
      const firstErrorId = Object.keys(errorsList[categoriesWithErrors[0]])[0];

      dispatch(selectCategory(categoriesWithErrors[0]));
      dispatch(setCurrentErrorIndex({ number: 0 }));
      dispatch(setCurrentErrorId({ controlId: firstErrorId }));
    }
  }, [errorsList]);

  return (
    <div className="assessment-finalize">
      <div className="assessment-finalize__finalize-button-wrapper">
        <Button
          className="assessment-finalize__finalize-button"
          disabled={isRestrictedBoSoViewerRole || assessment.status >= AssessmentStatus.finalized}
          size="sm"
          color={colorTheme}
          onClick={handleFinalize}
          data-test="assessment-finalize-button-finalize"
        >
          {translate(messages.submitAssessment)}
        </Button>
      </div>
      <Modal
        className="assessment-finalize__finalize-modal"
        open={finalizeModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={translate(messages.submitAssessment)}
        cancelText={translate(messages.didntFinishYet)}
        width={460}
        footer={stage !== stages.info ? null : undefined}
      >
        {renderStage}
      </Modal>
      <CloudMonitoringReviewPopup
        open={isCloudMonitoringReviewPopup}
        setOpen={setCloudMonitoringReviewPopup}
        submit={submit}
      />
      <CloudMonitoringActivatePopup
        open={isCloudMonitoringActivatePopup}
        setOpen={setCloudMonitoringActivatePopup}
        submit={submit}
      />
    </div>
  );
};

export default Finalize;
