export class ControlStatus {
  static none = 0;
  static approved = 1;
  static unapproved = 2;
}

// For mixpanel
export const ControlStatusEnum = {
  [ControlStatus.none]: 'deselected',
  [ControlStatus.approved]: 'approved',
  [ControlStatus.unapproved]: 'rejected',
};
