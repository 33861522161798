export const messages = {
  updateAnswer: {
    key: 'UpdateAnswerConfirmationModal.updateAnswer',
    defaultMessage: 'Update answer?',
  },
  updateAnswerButton: {
    key: 'UpdateAnswerConfirmationModal.updateAnswerButton',
    defaultMessage: 'Update answer',
  },
  noThanks: {
    key: 'UpdateAnswerConfirmationModal.noThanks',
    defaultMessage: 'No thanks',
  },
  wouldYouLikeToUpdateAnswer: {
    key: 'UpdateAnswerConfirmationModal.wouldYouLikeToUpdateAnswer',
    defaultMessage: "Would you like to update the vendor's answer before close the finding?",
  },
};

export default {
  prefix: 'UpdateAnswerConfirmationModal',
  messages,
};
