import React from 'react';

// COMPONENTS
import UserManagement from 'pages/Organization/edit/userManagement';
import Tabs from 'ui/Tabs';
import Notifications from 'pages/Organization/edit/notifications';
import OktaForm from 'pages/Organization/edit/okta';
import SoBoPages from 'pages/Organization/edit/soboPages';
import Security from 'pages/Organization/edit/security';
import Apps from './apps';
import TrustedOrganizations from './trustedOrganizations';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';

const OrganizationEditTabs = () => {
  return (
    <Tabs rounded>
      <Tabs.Pane
        data-test="organization-edit-page-tab-pane-user-management"
        className="organization-edit-page__tab-pane"
        key="1"
        tab={translate(messages.orgEditTabUserManagement)}
      >
        <UserManagement />
      </Tabs.Pane>
      <Tabs.Pane
        data-test="organization-edit-page-tab-pane-notifications"
        className="organization-edit-page__tab-pane"
        key="2"
        tab={translate(messages.orgEditTabNotification)}
      >
        <Notifications />
      </Tabs.Pane>
      <Tabs.Pane
        data-test="organization-edit-page-tab-pane-security"
        className="organization-edit-page__tab-pane"
        key="security"
        tab={translate(messages.orgEditTabSecurity)}
      >
        <Security />
      </Tabs.Pane>
      <Tabs.Pane
        data-test="organization-edit-page-tab-pane-okta"
        className="organization-edit-page__tab-pane"
        key="3"
        tab={translate(messages.orgEditTabOkta)}
      >
        <OktaForm />
      </Tabs.Pane>
      <Tabs.Pane
        data-test="organization-edit-page-tab-pane-sobo"
        className="organization-edit-page__tab-pane"
        key="4"
        tab={translate(messages.orgEditTabSOBO)}
      >
        <SoBoPages />
      </Tabs.Pane>
      <Tabs.Pane
        data-test="organization-edit-page-tab-pane-apps"
        className="organization-edit-page__tab-pane"
        key="5"
        tab={translate(messages.orgEditTabApps)}
      >
        <Apps />
      </Tabs.Pane>
      <Tabs.Pane
        data-test="organization-edit-page-tab-pane-trusted-organization"
        className="organization-edit-page__tab-pane"
        key="trusted-organization"
        tab={translate(messages.orgEditTabTrustedOrganization)}
      >
        <TrustedOrganizations />
      </Tabs.Pane>
    </Tabs>
  );
};

export default OrganizationEditTabs;
