import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Text from 'ui/Text';
import NewPasswordForm from 'components/NewPasswordForm';
import Divider from 'ui/Divider';
import Form from 'ui/Form';
import Button from 'ui/Button';
import AuthMethod from 'components/AuthMethod/authMethod';
import { translate } from 'utils/index';
import { setProfileMfa, setProfilePassword } from 'api/profile';
import { selectProfile } from 'pages/Profile/selectors';
import { API_STATUS } from 'constants/api';
import { messages } from 'pages/Profile/messages';
import { getOrganizationSecurity } from 'api/organizations';
import { selectSecuritySettings } from 'pages/Organization/selectors';
import { AttentionIcon } from 'components/Icons';
import colors from 'ui/colors.scss';
import Loader from 'ui/Loader';

import './password.scss';

const Password = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    user: { mfa_method: mfaMethodValue, phone },
  } = useSelector(selectProfile);
  const { enforce2fa } = useSelector(selectSecuritySettings);
  const [mfaMethod, setMfaMethod] = useState(mfaMethodValue);

  useEffect(async () => {
    setLoading(true);
    await dispatch(getOrganizationSecurity());
    setLoading(false);
  }, []);

  const onClickMfa = (mfaType) => {
    setMfaMethod(mfaType);
  };

  const onSubmitNewPassword = async (newPassword, currentPassword) => {
    const { status } = await setProfilePassword(newPassword, currentPassword);
    if (status === API_STATUS.SUCCESS) {
      return notification.success({ message: translate(messages.passwordTabPasswordSuccess) });
    }
    return notification.error({ message: translate(messages.passwordTabPasswordFail) });
  };

  const onSubmitMfa = async () => {
    const { status } = await setProfileMfa(undefined, mfaMethod);
    if (status === API_STATUS.SUCCESS) {
      return notification.success({ message: translate(messages.passwordTabMfaSuccess) });
    }
    return notification.error({ message: translate(messages.passwordTabMfaFail) });
  };

  return (
    <>
      <Divider hidden size="sm" />
      <div className="password-tab">
        <div className="password-tab__left">
          <Text text={translate(messages.passwordTabInputTitle)} />
          <Divider size="sm" hidden />
          <NewPasswordForm showOldPassword onSubmit={onSubmitNewPassword}>
            <Form.Item>
              <Button
                data-test="profile-settings-button-password-submit"
                type="submit"
                size="sm"
                name={translate(messages.passwordTabResetPasswordButton)}
                color="pink"
              />
            </Form.Item>
          </NewPasswordForm>
        </div>

        <div className="password-tab__right">
          <Text level={5} text={translate(messages.passwordTabMfaTitle)} />
          <Divider size="sm" hidden />
          {enforce2fa && (
            <div className="password-tab__alert">
              <AttentionIcon color={colors.red600} className="password-tab__alert-icon" />
              {translate(messages.yourAdminRequires2Fa)}
            </div>
          )}
          <Form.Item>
            <AuthMethod
              hideNone={enforce2fa}
              disableSms={!phone || phone === 'undefined'}
              mfaMethod={mfaMethod}
              onClick={onClickMfa}
            />
          </Form.Item>
          <Button
            data-test="profile-settings-button-mfa-submit"
            size="sm"
            name={translate(messages.passwordTabMfaMethodButton)}
            color="pink"
            onClick={onSubmitMfa}
          />
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default Password;
