import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const PageHeader = ({ noMargin, children, className }) => {
  return (
    <div
      className={classNames('page-header', className, {
        'page-header--no-margin': noMargin,
      })}
    >
      <h1 className="page-header__title">{children}</h1>
    </div>
  );
};

PageHeader.propTypes = {
  noMargin: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default PageHeader;
