import Form from './form';
import Item from './item';
import Label from './label';
import Error from './error';

Form.Item = Item;
Form.Label = Label;
Form.Error = Error;

export default Form;
