import React from 'react';
import PropTypes from 'prop-types';

const RiskreconIcon = ({ color = 'currentColor', height = '30', width = '31', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <circle cx="15.8911" cy="15" r="13" fill="#0E2435" />
    <path
      d="M15.8912 3.9585C9.80281 3.9585 4.84949 8.91183 4.84949 15.0002C4.84949 21.0885 9.80281 26.0418 15.8912 26.0418C21.9795 26.0418 26.9328 21.0885 26.9328 15.0002C26.9328 8.91183 21.9795 3.9585 15.8912 3.9585ZM15.8912 5.61475C21.0844 5.61475 25.2766 9.80693 25.2766 15.0002C25.2766 20.1934 21.0844 24.3856 15.8912 24.3856C10.6979 24.3856 6.50574 20.1934 6.50574 15.0002C6.50574 9.80693 10.6979 5.61475 15.8912 5.61475ZM20.0156 11.6801C19.8005 11.6864 19.5963 11.7761 19.4463 11.9303L14.5109 16.8656L12.336 14.6907C12.2597 14.6112 12.1683 14.5478 12.0672 14.5041C11.9661 14.4603 11.8572 14.4373 11.747 14.4361C11.6369 14.435 11.5276 14.4559 11.4256 14.4975C11.3236 14.5392 11.2309 14.6008 11.153 14.6787C11.0751 14.7566 11.0135 14.8492 10.9719 14.9513C10.9302 15.0533 10.9093 15.1625 10.9105 15.2727C10.9116 15.3829 10.9347 15.4917 10.9784 15.5929C11.0221 15.694 11.0855 15.7854 11.165 15.8617L13.9254 18.6221C14.0807 18.7774 14.2914 18.8646 14.5109 18.8646C14.7305 18.8646 14.9411 18.7774 15.0965 18.6221L20.6173 13.1013C20.7368 12.9849 20.8183 12.8352 20.8513 12.6718C20.8843 12.5083 20.8671 12.3387 20.8021 12.1851C20.7371 12.0315 20.6272 11.9011 20.4869 11.811C20.3466 11.7209 20.1823 11.6753 20.0156 11.6801Z"
      fill="#27AE60"
    />
  </svg>
);

RiskreconIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default RiskreconIcon;
