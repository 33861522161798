import React from 'react';
import PropTypes from 'prop-types';
import { Spin as AntSpin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Spin = ({ className, size }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: size || 12 }} spin />;

  return <AntSpin className={className} indicator={antIcon} />;
};

Spin.propTypes = {
  className: PropTypes.string,
  size: PropTypes.bool,
};

export default Spin;
