import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'assets/icons/style.css';
import './index.scss';

const Icon = ({ icon, className, title, onClick, children, color, ...restProps }) => {
  const iconClassNames = classNames(className, 'ui-icon', `icon-${icon}`, {
    [`ui-icon--${color}`]: color,
  });

  return (
    <span
      role="presentation"
      title={title}
      className={iconClassNames}
      onClick={onClick}
      data-test={`font-icon-${icon}`}
      {...restProps}
    >
      {children}
    </span>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.element,
  color: PropTypes.string,
};

export default Icon;
