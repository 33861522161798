export const messages = {
  title: {
    key: 'NotSupportedOnMobile.title',
    defaultMessage: 'Not supported on mobile',
  },
  yet: {
    key: 'NotSupportedOnMobile.yet',
    defaultMessage: 'YET!',
  },
};

export default {
  prefix: 'NotSupportedOnMobile',
  messages,
};
