import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ControlComponent from 'components/ControlComponent';
import { removeControlFile, uploadControlFiles } from 'api/assessment';
import { useCustomerManageContext } from 'hooks/useCustomerManageContext';
import { API_STATUS } from 'constants/api';
import { useFileUploadProcess } from 'components/FileUploadIndicator/useFileUploadProcess';
import { stripHtmlFromText } from 'utils/html';
import { kebabCase } from 'lodash';
import { parsePayload } from './lib';
import { useParams } from 'react-router';

import './categoryForm.scss';

const CategoryForm = ({ category, assessmentId }) => {
  const { onChangeAssessment } = useCustomerManageContext();
  const assessmentData = useSelector((state) => state.assessment);
  const { isFileUploading } = assessmentData;
  const { customerId } = useParams();

  const { abortController, startUploading, finishUploding, renderConfirmationModal } =
    useFileUploadProcess();

  const dispatch = useDispatch();

  const getNewControls = (value, controlId) => {
    const newControls = category.controls.map((control) =>
      control.id === controlId ? { ...control, answer: value } : control,
    );
    const newControl = newControls.find((control) => control.id === controlId);

    return [newControl, newControls];
  };

  const handleUpload = async (files, controlId) => {
    const { id } = category;
    if (customerId) {
      startUploading();
      const { payload } = await dispatch(
        uploadControlFiles({
          files,
          controlId,
          categoryId: id,
          assessmentId,
          abortController,
        }),
      );
      finishUploding();

      if (payload.status === API_STATUS.SUCCESS) {
        const newFiles = category.controls.map((control) => {
          if (control.id === controlId) {
            return {
              ...control,
              answer: [...control.answer, ...files],
            };
          }
          return control;
        });
        const [newControl] = getNewControls(files, controlId);

        onChangeAssessment(newFiles, assessmentId, newControl.id);
      }
    } else {
      const newFiles = category.controls.map((control) => {
        if (control.id === controlId) {
          return {
            ...control,
            answer: [...control.answer, ...files],
          };
        }
        return control;
      });
      const [newControl] = getNewControls(files, controlId);

      onChangeAssessment(newFiles, assessmentId, newControl.id);
    }
  };

  const onChange = (value, controlId) => {
    const [newControl] = getNewControls(value, controlId);
    onChangeAssessment([newControl], assessmentId, newControl.id);
  };

  const handleRemove = async (fileId, controlId) => {
    if (customerId) {
      await dispatch(removeControlFile({ fileId, controlId, assessmentId })).unwrap();
    }

    const controlAnswers = category.controls.find((control) => control.id === controlId).answer;
    const newFiles = category.controls.map((control) => {
      if (control.id === controlId) {
        return {
          ...control,
          answer: controlAnswers.filter((answer) => answer.id !== fileId),
        };
      }
      return control;
    });

    onChangeAssessment(newFiles, assessmentId, controlId);
  };

  const renderControl = (control) => {
    const { answerData, title: label, description, id } = control;
    const payload = parsePayload(
      control,
      control.answer,
      onChange,
      handleUpload,
      handleRemove,
      isFileUploading,
    );
    const rawLabel = stripHtmlFromText(label).toLowerCase();
    return (
      <>
        <ControlComponent
          key={id}
          type={answerData?.type}
          isSimple
          data={{
            label,
            description,
            assessmentId,
            payload: {
              ...payload,
              'data-test': `customer-manage-${kebabCase(rawLabel)}`,
            },
          }}
        />
        {renderConfirmationModal()}
      </>
    );
  };

  const { title } = category;

  return (
    <div className="category-form">
      <div className="category-form__header">{title}</div>
      <div className="category-form__controls">
        {category.controls.filter((control) => control.enabled).map(renderControl)}
      </div>
    </div>
  );
};

CategoryForm.propTypes = {
  category: PropTypes.any,
  assessmentId: PropTypes.string,
};

export default CategoryForm;
