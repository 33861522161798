import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getAnswerType } from 'utils/controls';
import { ControlType } from 'constants/controlType';
import FileList from 'ui/FileList';

import { selectAnswerTypes } from './selectors';
import { selectAnswerTypes as editorSelectAnswerTypes } from 'pages/Editor/selectors';

const ControlAnswerPreview = ({ control = {}, isEditor, optionKey }) => {
  const answerTypes = useSelector(isEditor ? editorSelectAnswerTypes : selectAnswerTypes);

  const { answer, answer_type_idx: answerTypeIdx } = control;
  const answerType = getAnswerType(answerTypeIdx, answerTypes);

  switch (answerType.type) {
    case ControlType.TextArea:
    case ControlType.Input:
    case ControlType.DatePicker:
      return answer;
    case ControlType.DropDown:
    case ControlType.RadioGroup:
      if (isEditor)
        return answerType.options.find((option) => option.key === optionKey)?.value || null;
      return answerType.options.find((option) => option.key === answer)?.value || null;
    case ControlType.MultiSelect:
    case ControlType.Checkbox: {
      if (isEditor) {
        const answers = answerType.options
          .filter((option) => option.key === optionKey)
          .map((option) => option.value);

        return answers.join(', ');
      }
      const answers = answerType.options
        .filter((option) => (answer || []).includes(option.key))
        .map((option) => option.value);

      return answers.join(', ');
    }
    case ControlType.FileUpload:
      return <FileList files={answer} />;
    default:
      return null;
  }
};

ControlAnswerPreview.propTypes = {
  control: PropTypes.object,
  isEditor: PropTypes.bool,
  optionKey: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default ControlAnswerPreview;
