export const messages = {
  showcasesHeader: {
    key: 'Showcases.showcasesHeader',
    defaultMessage: 'Showcases',
  },
  createNewModalTitle: {
    key: 'Showcases.createNewModalTitle',
    defaultMessage: 'Create new showcase',
  },
  sharedTabTitle: {
    key: 'Showcases.sharedTabTitle',
    defaultMessage: 'Shared',
  },
  templatesTabTitle: {
    key: 'Showcases.templatesTabTitle',
    defaultMessage: 'Templates',
  },
  editShowcase: {
    key: 'Showcases.editShowcase',
    defaultMessage: 'Edit showcase',
  },
  showCaseTitle: {
    key: 'Showcases.showCaseTitle',
    defaultMessage: 'Showcase title',
  },
  showcaseTitlePlaceholder: {
    key: 'Showcases.showcaseTitlePlaceholder',
    defaultMessage: 'Enter title',
  },
  showCaseDescription: {
    key: 'Showcases.showCaseDescription',
    defaultMessage: 'Description',
  },
  showcaseDescriptionPlaceholder: {
    key: 'Showcases.showcaseDescriptionPlaceholder',
    defaultMessage: 'Enter description',
  },
  selectAssessments: {
    key: 'Showcases.selectAssessments',
    defaultMessage: 'Select Assessments',
  },
  showcaseAssessmentsPlaceholder: {
    key: 'Showcases.showcaseAssessmentsPlaceholder',
    defaultMessage: 'Select one or more assessments',
  },
  moreThanOne: {
    key: 'Showcases.moreThanOne',
    defaultMessage: '(you can select more than one)',
  },
  create: {
    key: 'Showcases.create',
    defaultMessage: 'Create',
  },
  requiredTitle: {
    key: 'Showcases.requiredTitle',
    defaultMessage: 'Title is required',
  },
  requiredDescription: {
    key: 'Showcases.requiredDescription',
    defaultMessage: 'Description is required',
  },
  requiredAssessment: {
    key: 'Showcases.requiredAssessment',
    defaultMessage: 'Assessment is required',
  },
  showcaseSubTitle: {
    key: 'Showcases.showcaseSubTitle',
    defaultMessage:
      'Share your compliance posture using existing answered assessments and speed up your sales cycle.',
  },
};

export default {
  prefix: 'Showcases',
  messages,
};
