import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, Modal } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SecondaryButton from 'ui/SecondaryButton';
import UiButton from 'ui/Button';
import Icon from 'ui/Icon';
import Input from 'ui/Input';
import Checkbox from 'ui/Checkbox';
import Loader from 'ui/Loader';

import { translate } from 'utils/index';
import { getAnswerType, isEditableAnswerType } from 'utils/controls';

import { selectAnswerTypes, selectAssessment } from 'pages/Assessment/selectors';
import { selectAnswerTypes as editorSelectAnswerTypes } from 'pages/Editor/selectors';

import { getAnswerRepository } from 'api/assessment';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';

import Controls from './controls';
import Answers from './answers';
import { selectRepository } from './selectors';
import { messages } from './messages';
import {
  mixpanelAnswerRepositoryOpened,
  mixpanelAnswerRepositoryResponseSelected,
} from 'utils/mixpanel';

import './index.scss';

const AnswerRepository = ({ control, disabled, onSelect, isEditor }) => {
  const { finalized, customerId, title: assessmentName } = useSelector(selectAssessment);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const [modalOpened, toggleModal] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const answerTypes = useSelector(isEditor ? editorSelectAnswerTypes : selectAnswerTypes);
  const { answer_type_idx: answerTypeIdx } = control;
  const answerType = getAnswerType(answerTypeIdx, answerTypes);
  const [hideIncompatible, setHideIncompatible] = useState(true);
  const repository = useSelector((state) => selectRepository(state, answerType, hideIncompatible));
  const { user } = useSelector((state) => state.profile);

  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedControl, setSelectedControl] = useState();
  const [selectedAnswer, setSelectedAnswer] = useState();

  useEffect(() => {
    async function fetchRepository() {
      if ((!repository || repository.length === 0) && modalOpened) {
        setIsLoading(true);
        await dispatch(getAnswerRepository()).unwrap();
        setIsLoading(false);
      }
    }
    fetchRepository();
  }, [modalOpened]);

  const filteredRepository = repository.map((category) => ({
    ...category,
    controls: category.controls.filter(({ title }) =>
      title.toLowerCase().includes(search.toLowerCase()),
    ),
  }));

  const onSearch = ({ target: { value } }) => {
    setSearch(value);
  };

  const onCancel = () => {
    toggleModal(false);
  };

  const onOk = () => {
    onSelect(selectedAnswer?.answer, control.id);
    mixpanelAnswerRepositoryResponseSelected({
      relation: customerId,
      assessmentName: assessmentName,
      assigneeOrganizationName: user?.current_organization?.label,
      originalControl: control?.title,
      response: selectedAnswer?.answer,
    });
    toggleModal(false);
  };

  const onSelectControl = (id) => {
    setSelectedControl(id);
    setSelectedAnswer(undefined);
  };

  const onSetSelectedAnswer = (title) => {
    // eslint-disable-next-line no-nested-ternary
    const answer = isEditableAnswerType(answerType.type)
      ? Array.isArray(title)
        ? answerType.options.filter((option) => title.includes(option.value)).map(({ key }) => key)
        : (answerType.options.find((option) => option.value === title) || {}).key
      : title;

    setSelectedAnswer({ value: title, answer });
  };

  const controls = filteredRepository.find(({ id }) => id === selectedSubject)?.controls || [];
  const answers = useMemo(
    () => controls.find(({ id }) => id === selectedControl)?.answers.filter((answer) => answer),
    [controls],
  );

  const footer = [
    <UiButton
      data-test={`answer-repository-button-cancel`}
      key="ui_button_1"
      className="answer-repository_footer-cancel-button"
      size="sm"
      color="gray"
      onClick={onCancel}
      link
    >
      {translate(messages.cancel)}
    </UiButton>,
    <SecondaryButton
      key="ui_button_2"
      data-test={`answer-repository-button-ok`}
      className="answer-repository__footer-ok-button"
      onClick={onOk}
      size="medium"
      type="submit"
      outline
    >
      {translate(messages.select)}
    </SecondaryButton>,
  ];

  const isRepoButtonVisible = !isMyVendorsTabSelected && !finalized;

  const onClickOpenRepository = () => {
    toggleModal(true);
    mixpanelAnswerRepositoryOpened({
      relation: customerId,
      assessmentName: assessmentName,
      assigneeOrganizationName: user?.current_organization?.label,
      originalControl: control?.title,
    });
  };

  return (
    <div>
      {isRepoButtonVisible && (
        <SecondaryButton
          data-test="answer-repository-button-repository"
          className={classNames('answer-repository__button', {
            'answer-repository__button--is-editor': isEditor,
          })}
          disabled={disabled}
          icon="answer-repository"
          tooltip={translate(messages.answerRepository)}
          onClick={onClickOpenRepository}
        />
      )}
      <Modal
        className="answer-repository__modal"
        closeIcon={<Icon icon="close" />}
        open={modalOpened}
        onCancel={onCancel}
        width={900}
        footer={footer}
      >
        <div className="answer-repository__header">
          <h3 className="answer-repository__header-title">
            {translate(messages.answerRepository)}
          </h3>
          <div className="answer-repository__header-description">
            {translate(messages.answerRepository)}
          </div>
          <Input.Search className="answer-repository__search" onChange={onSearch} value={search} />
          <div className="answer-repository__categories">
            {filteredRepository.map((category, index) => (
              <UiButton
                key={`ui_button_${index}`}
                data-test={`answer-repository-button-${category.title}`}
                afterComponent={
                  <span className="answer-repository__controls-counter">
                    {category.controls.length}
                  </span>
                }
                className="answer-repository__category-button"
                color={selectedSubject === category.id ? 'blue' : 'gray'}
                disabled={category.controls.length === 0}
                fluid
                lowercase
                outline
                onClick={() => setSelectedSubject(category.id)}
                size="md"
              >
                {category.title}
              </UiButton>
            ))}
          </div>
        </div>
        <div className="answer-repository__body">
          <div className="answer-repository__list-wrapper">
            {controls.length > 0 ? (
              <>
                <div className="answer-repository__list-header">{translate(messages.results)}:</div>
                <div className="answer-repository__panel">
                  <Controls
                    controls={controls}
                    selectedControl={selectedControl}
                    onSelectControl={onSelectControl}
                  />
                  <Answers
                    answers={answers}
                    answerType={answerType}
                    selectedControl={selectedControl}
                    selectedAnswer={selectedAnswer}
                    onSetSelectedAnswer={onSetSelectedAnswer}
                  />
                </div>
              </>
            ) : (
              <Empty
                className="answer-repository__empty"
                description={translate(messages.emptyContentControlsSubtitle)}
              />
            )}
          </div>
          <div className="answer-repository__action-bar">
            <Checkbox
              checked={hideIncompatible}
              className="answer-repository__hide-incompatible"
              onChange={() => setHideIncompatible(!hideIncompatible)}
              name="hide-incompatible"
              mode="checkbox"
            >
              {translate(messages.hideIncompatible)}
            </Checkbox>
          </div>
          {isLoading && <Loader />}
        </div>
      </Modal>
    </div>
  );
};

AnswerRepository.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  isEditor: PropTypes.bool,
};

export default AnswerRepository;
