export const messages = {
  createNewVendor: {
    key: 'Vendor new vendor.createNewVendor',
    defaultMessage: 'Create new vendor',
  },
  assignAssessment: {
    key: 'Vendor new vendor.assignAssessment',
    defaultMessage: 'Assign assessment',
  },
  overview: {
    key: 'Vendor new vendor.overview',
    defaultMessage: 'Overview',
  },
  contactInformation: {
    key: 'Vendor new vendor.contactInformation',
    defaultMessage: 'Contact Information',
  },
  vendorHasBeenAdded: {
    key: 'Vendor new vendor.vendorHasBeenAdded',
    defaultMessage: 'Vendor has been added!',
  },
  vendorHasBeenUpdated: {
    key: 'Vendor new vendor.vendorHasBeenUpdated',
    defaultMessage: 'Vendor has been updated!',
  },
  requiredCompanyName: {
    key: 'Vendor new vendor.requiredCompanyName',
    defaultMessage: 'Company name is required',
  },
  requiredCompanyContact: {
    key: 'Vendor new vendor.requiredCompanyContact',
    defaultMessage: 'Company contact is required',
  },
  requiredCompanyEmail: {
    key: 'Vendor new vendor.requiredCompanyEmail',
    defaultMessage: 'Email is required',
  },
  inputNameError: {
    key: 'Vendor new vendor.inputNameError',
    defaultMessage: 'Only alphabets are allowed',
  },
  companyEmailErrorFormat: {
    key: 'Vendor new vendor.companyEmailErrorFormat',
    defaultMessage: 'Email format is invalid',
  },
  companyDomainErrorFormat: {
    key: 'Vendor new vendor.companyDomainErrorFormat',
    defaultMessage: 'Company domain format is invalid (e.g. https://findings.co)',
  },
  impactAnalysisInformation: {
    key: 'Vendor new vendor.impactAnalysisInformation',
    defaultMessage: 'Impact Analysis information',
  },
  exitWithoutSaving: {
    key: 'Vendor new vendor.exitWithoutSaving',
    defaultMessage: 'Exit without saving?',
  },
  saveChanges: {
    key: 'Vendor new vendor.saveChanges',
    defaultMessage: 'Save changes?',
  },
  allVendorInformationWillBeLost: {
    key: 'Vendor new vendor.allVendorInformationWillBeLost',
    defaultMessage: 'All vendor information will be lost',
  },
  wouldYouLikeToSaveChanges: {
    key: 'Vendor new vendor.wouldYouLikeToSaveChanges',
    defaultMessage: 'Would you like to save changes before exit?',
  },
  saveChangesButton: {
    key: 'Vendor new vendor.saveChangesButton',
    defaultMessage: 'SAVE CHANGES',
  },
  completeVendorButton: {
    key: 'Vendor new vendor.completeVendorButton',
    defaultMessage: 'COMPLETE VENDOR',
  },
  exitAnywayButton: {
    key: 'Vendor new vendor.exitAnywayButton',
    defaultMessage: 'EXIT ANYWAY',
  },
  run: {
    key: 'Vendor new vendor.run',
    defaultMessage: 'Run',
  },
  dontSaveButton: {
    key: 'Vendor new vendor.dontSaveButton',
    defaultMessage: "DON'T SAVE",
  },
  notes: {
    key: 'Vendor new vendor.notes',
    defaultMessage: 'Notes',
  },
  note: {
    key: 'Vendor new vendor.note',
    defaultMessage: 'Note',
  },
  lastUpdated: {
    key: 'Vendor new vendor.lastUpdated',
    defaultMessage: 'Last updated',
  },
  viewAllVendorNotes: {
    key: 'Vendor new vendor.viewAllVendorNotes',
    defaultMessage: 'View all vendor general and control/findings related notes.',
  },
  noNotes: {
    key: 'Vendor new vendor.noNotes',
    defaultMessage: 'No notes',
  },
  addNote: {
    key: 'Vendor new vendor.addNote',
    defaultMessage: 'ADD NOTE',
  },
  saveAndClose: {
    key: 'Vendor new vendor.saveAndClose',
    defaultMessage: 'Save and close',
  },
  requiredNote: {
    key: 'Vendor new vendor.requiredNote',
    defaultMessage: 'Note is required',
  },
  general: {
    key: 'Vendor new vendor.general',
    defaultMessage: 'General',
  },
  internal: {
    key: 'Vendor new vendor.internal',
    defaultMessage: 'Internal',
  },
  by: {
    key: 'Vendor new vendor.by',
    defaultMessage: 'by',
  },
  assessment: {
    key: 'Vendor new vendor.assessment',
    defaultMessage: 'Assessment',
  },
};

export default {
  prefix: 'Vendor new vendor',
  messages,
};
