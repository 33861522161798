import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import Pusher from 'pusher-js';

import { openOrCreateChat, sendMessage } from 'api/messenger';
import { DATE_FORMAT } from 'constants/date';
import { getCookie } from 'utils/apiHelpers';
import { WS_TOKEN_AUTH_KEY } from 'utils/auth';
import { ENV } from 'configs/env';

const useChat = ({
  threadId,
  chatRef,
  threadTitle,
  controlId,
  findingId,
  recommendationId,
  categoryId,
  assessmentId,
}) => {
  const threads = useSelector((state) => state.chat?.threads) || {};
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const pusher = new Pusher(ENV.PUSHER_APP_KEY, {
      cluster: 'mt1',
      forceTLS: true,
    });
    const wsToken = getCookie(WS_TOKEN_AUTH_KEY);
    const channel = pusher.subscribe(wsToken);

    channel.bind('GetProfileChatThread', async () => {
      await getThread(false);
      scrollToBottom();
    });

    return () => {
      pusher.disconnect();
    };
  }, []);

  useEffect(() => {
    async function fetchThread() {
      if (threadId && !threads[threadId]) await getThread();
      scrollToBottom();
    }
    fetchThread().then();
  }, [threadId]);

  const getThread = async (showLoading = true) => {
    if (showLoading) {
      setLoading(true);
    }

    await dispatch(
      openOrCreateChat({
        threadId,
        assessmentId,
        threadTitle,
        controlId,
        findingId,
        recommendationId,
        categoryId,
      }),
    ).unwrap();
    if (showLoading) {
      setLoading(false);
    }
  };

  const scrollToBottom = () => {
    const ref = (chatRef || {}).current;
    if (!ref) return;

    ref.scroll({
      top: ref.scrollHeight,
      left: 0,
    });
  };

  const onSendMessage = async (msg) => {
    await dispatch(sendMessage({ threadId, msg })).unwrap();
  };

  const prepareThread = (data) => {
    const sortedThread = {};

    sortBy(data, 'timestampCreated').forEach((chatThreads) => {
      const { timestampCreated } = chatThreads;
      const formattedDate = moment(timestampCreated).format(DATE_FORMAT);
      if (sortedThread[formattedDate]) {
        sortedThread[formattedDate].push(chatThreads);
      } else {
        sortedThread[formattedDate] = [chatThreads];
      }
    });

    return Object.entries(sortedThread);
  };

  return {
    chatThread: prepareThread(threads[threadId]?.thread || []),
    sendMessage: onSendMessage,
    loading,
  };
};

export default useChat;
