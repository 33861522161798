import { setCookie } from 'utils/apiHelpers';
import { getProfileOrganizations } from 'api/organizations';
import { ORG_ID_AUTH_KEY } from 'utils/auth';
import { translate } from 'utils/index';
import { notification } from 'antd';
import { NOTIFICATION_DURATION } from 'constants/general';
import { useDispatch } from 'react-redux';
import { DEFAULT_ORGANIZATION_INDEX, getOrganizationIndex } from 'utils/organizations';
import ROUTES from 'constants/routes';
import { useAppNavigate } from 'hooks/navigation';
import { useLocation } from 'react-router';
import { Roles } from 'constants/roles';
import { messages } from 'pages/Login/messages';
import { View } from 'constants/view';

function usePostLogin() {
  const location = useLocation();
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();
  const { state: originalPath } = location;

  return async ({ organizationId, orgIdFromLogin, role, redirect, view }) => {
    // set organization id cookie
    setCookie(ORG_ID_AUTH_KEY, organizationId || orgIdFromLogin);

    // set profile organizations in organizations store
    const { payload: organizations } = await dispatch(getProfileOrganizations());

    notification.success({
      message: translate(messages.welcome),
      duration: NOTIFICATION_DURATION.ONE_SECOND,
      className: 'notification-login-form-success',
    });

    // SOBO users should be redirected to the vendors/customers pages
    if (role === Roles.BO || role === Roles.SO) {
      const path = role === Roles.BO ? ROUTES.VENDOR_RECENT : ROUTES.CUSTOMER_RECENT;
      return appNavigate(path);
    }

    if (role === Roles.Auditor) {
      return appNavigate(ROUTES.VENDOR_RECENT);
    }

    // Redirect to specified path
    if (originalPath || redirect) {
      return appNavigate(originalPath || redirect);
    }

    // TODO: remove ret handling after migration to new login redirect flow
    if (location.search.includes('ret')) {
      return appNavigate(`/${location.search.split('ret=')[1]}`, {
        organization: DEFAULT_ORGANIZATION_INDEX,
      });
    }

    // default redirect
    const orgIndex = getOrganizationIndex({
      organizations,
      orgId: organizationId || orgIdFromLogin,
    });

    const recentRoute = view === View.myVendors ? ROUTES.VENDOR_RECENT : ROUTES.CUSTOMER_RECENT;

    return appNavigate(recentRoute, {
      organization: orgIndex || DEFAULT_ORGANIZATION_INDEX,
    });
  };
}

export default usePostLogin;
