import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Login, Logout, ResetProfilePassword } from './endpoints';

export const loginPostRequest = async ({
  email,
  password,
  otpCode,
  sso = false,
  ssoToken = undefined,
  mfaMethod,
  setEmailMfa,
}) => {
  const payload = {};

  if (sso) {
    payload['login-sso'] = sso;
    payload['login-sso-token'] = ssoToken;
  } else {
    payload['login-email'] = email;
    payload['login-password'] = password;
  }
  if (otpCode) {
    payload['login-otp-code'] = otpCode;
  }
  if (mfaMethod) {
    payload['set-mfa-method'] = mfaMethod;
  }
  if (setEmailMfa) {
    payload['setEmailMfa'] = setEmailMfa;
  }

  return await axios.post(Login.url, payload);
};

export const resetPassword = async (email) =>
  axios.post(ResetProfilePassword.url, { 'reset-password-email': email });

export const loginUser = createAsyncThunk('LoginUser', loginPostRequest);

export const logoutRequest = async () => {
  return await axios.post(Logout.url);
};
