import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';

import { useTheme } from 'hooks/theme';

import { finalizeAssessment, getAssessment } from 'api/assessment';

import Modal from 'ui/Modal';
import Button from 'ui/Button';

import { ReactComponent as InfoIcon } from 'assets/svgs/info.svg';

import { generalMessages } from 'constants/messages';

import { selectAssessment } from 'pages/Assessment/selectors';

import { messages } from './messages';

import './forceSubmitModal.scss';
import { API_STATUS } from 'constants/api';
import { mixpanelTrackAssessmentSubmitted } from 'utils/mixpanel';
import { calcDueDateDaysDiff } from 'utils/mixpanel_lib';
import { selectProfile } from 'pages/Profile/selectors';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';

const ForceSubmitModal = ({ open, onCancel }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { colorTheme } = useTheme();
  const assessment = useSelector(selectAssessment);
  const { user } = useSelector(selectProfile);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const handleOnOk = async () => {
    setIsSubmitting(true);
    const payload = {
      forceSubmitted: true,
    };
    const forceSubmitResponse = await dispatch(finalizeAssessment(payload)).unwrap();

    if (forceSubmitResponse.status === API_STATUS.SUCCESS) {
      mixpanelTrackAssessmentSubmitted({
        relation: assessment?.customerId,
        assessmentProgress: assessment?.total_score?.completion,
        dueDate: calcDueDateDaysDiff(assessment?.due),
        forceSubmitted: true,
        assessmentName: assessment?.title,
        streamDirection: isMyVendorsTabSelected ? 'downstream' : 'upstream',
        assignedOrganizationName: assessment?.customerName,
        assigneeOrganizationName: user?.current_organization?.label,
      });
    }

    setIsSubmitting(false);
    dispatch(
      getAssessment({
        id: assessment?.id,
      }),
    );
    onCancel();
  };

  return (
    <Modal open={open} onCancel={onCancel} footer={null} width={651}>
      <div className="force-submit-modal">
        <div className="force-submit-modal__header">
          <InfoIcon /> {translate(messages.forceSubmitHeader)}
        </div>
        <div className="force-submit-modal__description">
          {translate(messages.forceSubmitDescription)}
        </div>
        <div className="force-submit-modal__footer">
          <Button
            color={colorTheme}
            outline
            size="sm"
            data-test="force-submit-cancel-button"
            onClick={onCancel}
          >
            {translate(messages.cancel)}
          </Button>
          <Button
            loading={isSubmitting}
            color={colorTheme}
            size="sm"
            data-test="force-submit-cancel-button"
            onClick={handleOnOk}
          >
            {translate(generalMessages.submit)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ForceSubmitModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default ForceSubmitModal;
