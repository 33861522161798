import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { openFileUploadIndicator, closeFileUploadIndicator } from './indicateFileUploading';
import { selectNavigateTo, selectUploadInProgress } from './selectors';
import { setNavigateTo, toggleFileUploading } from './reducers';
import ExitFileUploadingConfirmationModal from 'components/FileUploadIndicator/ExitFileUploadingConfirmationModal';

export const useFileUploadProcess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const uploadInProgress = useSelector(selectUploadInProgress);
  const navigateTo = useSelector(selectNavigateTo);

  const abortController = new AbortController();

  const startUploading = () => {
    openFileUploadIndicator(abortUploading);
    dispatch(toggleFileUploading({ uploadInProgress: true }));
  };

  const finishUploding = () => {
    closeFileUploadIndicator();
    dispatch(toggleFileUploading({ uploadInProgress: false }));
    dispatch(setNavigateTo(null));
  };

  const abortUploading = () => {
    abortController.abort();
    finishUploding();
  };

  const handleStay = () => {
    dispatch(setNavigateTo(null));
  };

  const handleExit = () => {
    abortController.abort();
    finishUploding();

    if (navigateTo?.newTab) {
      window.open(navigateTo?.path, '_blank');
    } else {
      navigate(navigateTo?.path, navigateTo?.args);
    }
  };

  function renderConfirmationModal() {
    if (uploadInProgress) {
      return (
        <ExitFileUploadingConfirmationModal
          open={uploadInProgress && !!navigateTo}
          exit={handleExit}
          stay={handleStay}
        />
      );
    }
    return null;
  }

  return {
    abortController,
    startUploading,
    finishUploding,
    renderConfirmationModal,
  };
};
