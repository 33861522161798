import React, { useEffect } from 'react';

import Tabs from 'ui/Tabs';
import PageHeader from 'components/PageHeader';
import Password from 'pages/Profile/settings/password';
import Card from 'ui/Card';
import PersonalData from 'pages/Profile/settings/personalData';
import { translate } from 'utils/index';
import { getProfile } from 'api/profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from 'pages/Profile/selectors';
import { messages } from 'pages/Profile/messages';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';

import './index.scss';

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(selectProfile);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  useEffect(() => {
    if (!user.email) {
      dispatch(getProfile({ upstream: !isMyVendorsTabSelected })).unwrap();
    }
  }, []);

  return (
    <div className="profile-settings-page">
      <PageHeader>{translate(messages.settingsTabTitle)}</PageHeader>
      <Tabs rounded>
        <Tabs.Pane
          tab={translate(messages.settingsTabPersonalDataTitle)}
          key="1"
          data-test="profile-settings-tab-pane-personal-data"
        >
          <Card noMargin>{user.email && <PersonalData user={user} />}</Card>
        </Tabs.Pane>
        <Tabs.Pane
          tab={translate(messages.passwordTabTitle)}
          key="2"
          data-test="profile-settings-tab-pane-password"
        >
          <Card noMargin>
            <Password />
          </Card>
        </Tabs.Pane>
      </Tabs>
    </div>
  );
};

export default ProfileSettings;
