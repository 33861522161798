import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getSummaryCustomers } from 'api/summary';
import { getCustomersGroups } from 'api/customersGroup';
import { removeFilterReducer, setFilterReducer } from 'components/AddFilterButton/filterReducers';
import { getAssessmentsPageTypeFilter } from 'api/filtersData';

const initialState = {
  filters: [],
  customers: [],
  customersLoading: false,
  assessmentsTemplates: [],
  assessmentsTemplateLoading: false,
  groups: [],
  groupsLoading: false,
};

const reducers = createSlice({
  name: 'assessmentsFilters',
  initialState,
  reducers: {
    setFilter: (state, { payload: { type, value, id } }) =>
      setFilterReducer(state, type, value, id),
    removeFilter: (state, { payload: { type } }) => removeFilterReducer(state, type),
    clearFilters: (state) => {
      state.filters = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSummaryCustomers.pending, (state) => {
        state.customersLoading = true;
      })
      .addCase(getAssessmentsPageTypeFilter.pending, (state) => {
        state.assessmentsTemplateLoading = true;
      })
      .addCase(getCustomersGroups.pending, (state) => {
        state.groupsLoading = true;
      })
      .addMatcher(
        isAnyOf(getSummaryCustomers.fulfilled, getSummaryCustomers.rejected),
        (state, { payload: { vendors, customers } }) => {
          state.customers = vendors || customers;
          state.customersLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(getAssessmentsPageTypeFilter.fulfilled, getAssessmentsPageTypeFilter.rejected),
        (state, { payload: { assessment_names: assessmentsTemplates } }) => {
          state.assessmentsTemplates = assessmentsTemplates || [];
          state.assessmentsTemplateLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(getCustomersGroups.fulfilled, getCustomersGroups.rejected),
        (state, { payload: { groups } }) => {
          state.groups = groups;
          state.groupsLoading = false;
        },
      );
  },
});

export const { clearFilters, setFilter, removeFilter } = reducers.actions;

export default reducers;
