import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import SecondaryButton from 'ui/SecondaryButton';
import EditableContent from 'ui/EditableContent';
import Loader from 'ui/Loader';
import Divider from 'ui/Divider';
import { calculateControlKey } from 'pages/Assessment/lib';
import { translate } from 'utils/index';
import { saveTemplate } from 'api/editor';

import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { selectActiveQuestion, selectSubject, selectSubjectKey } from 'pages/Editor/selectors';
import { selectSubject as setSubject } from 'pages/Editor/reducers';
import Button from 'ui/Button';

import { messages } from 'pages/Editor/messages';
import AnswerBank from './answerBank';
import RequiredSwitcher from './requiredSwitcher';
import VisibleSwitcher from './visibleSwitcher';
import AnswerCreator from './answerCreator';
import AddAppTrigger from './addAppTrigger';
import { useRole } from 'hooks/useRole';

import './addQuestion.scss';

const defaultQuestion = {
  title: '',
  answer_type: undefined,
  answer: '',
  enabled: true,
  optional: false,
  findings: [],
};

const defaultTitle = 'Question title';

const stages = {
  addButton: 'addButton',
  addQuestion: 'addQuestion',
  createAnswer: 'createAnswer',
  addAppTrigger: 'addAppTrigger',
};

const hideAppTriggers = false;

const AddQuestion = ({
  className,
  defaultStage,
  defaultValues = {},
  onSuccess,
  setActiveQuestion,
  hideAddButton,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [stage, setStage] = useState(defaultStage || stages.addButton);
  const [showAnswerBank, setShowAnswerBank] = useState();
  const [values, setValues] = useState({ ...defaultQuestion, ...defaultValues });
  const subject = useSelector(selectSubject);
  const subjectKey = useSelector(selectSubjectKey);
  const activeQuestion = useSelector(selectActiveQuestion);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { isRestrictedBoSoViewerRole } = useRole();
  const { rtl } = useSelector((state) => state.editor.template);

  useEffect(() => {
    setValues({ ...defaultQuestion, ...defaultValues });
  }, [defaultStage, subjectKey]);

  useEffect(() => {
    setStage(defaultStage || stages.addButton);
  }, [defaultStage]);

  const onAddQuestion = async (args = {}) => {
    const id = uuidv4();
    const newQuestion = {
      id,
      ...values,
      ...args,
      answer_type_idx: args.answer_type,
      title: values.title || args.title || defaultTitle,
    };
    const payload = {
      categories: [
        {
          id: subject.id,
          controls: [newQuestion],
        },
      ],
    };
    setLoading(true);
    let categoriesPlaceholderCounter = 0;
    await dispatch(
      saveTemplate({
        ...payload,
        categories: payload.categories.map((category) => {
          let title = subject.title;
          if (!title) {
            categoriesPlaceholderCounter++;
            title = `${translate(messages.categoryPlaceholder)} ${categoriesPlaceholderCounter}`;
          }
          return {
            ...category,
            title,
          };
        }),
      }),
    ).unwrap();

    dispatch(setSubject(subject.id));
    setValues({ ...defaultQuestion, ...defaultValues });
    setLoading(false);
    setStage(stages.addButton);
    if (setActiveQuestion) {
      setActiveQuestion(id);
    }
    if (onSuccess) {
      onSuccess(id);
    }
  };

  const onShowForm = () => {
    setActiveQuestion(null);
    setStage(stages.addQuestion);
  };

  const onShowAddTriggerForm = () => {
    setActiveQuestion(null);
    setStage(stages.addAppTrigger);
  };

  const selectAnswerType = async (value) => {
    setValues((state) => ({ ...state, answer_type: value }));
    await onAddQuestion({ answer_type: value });
  };

  const onClickCancel = () => {
    setStage(stages.addButton);
    if (onCancel) {
      onCancel();
    }
  };

  const renderAddQuestion = () => (
    <div
      className={classNames('editor-add-question', className, {
        'editor-add-question--is-active': !activeQuestion,
      })}
      onClick={() => setActiveQuestion(null)}
    >
      <div className="editor-add-question__main">
        <div className="editor-add-question__key">
          {calculateControlKey(subjectKey, subject?.controls?.length)}
        </div>
        <div className="editor-add-question__wrapper">
          <EditableContent
            className="editor-add-question__top-description"
            onChange={(value) => setValues((state) => ({ ...state, description: value }))}
            placeholder={translate(messages.addTopDescription)}
            value={values.description}
            disabled={isRestrictedBoSoViewerRole}
            style={{ direction: rtl ? 'rtl' : 'ltr' }}
          />
          <EditableContent
            className="editor-add-question__title"
            onChange={(value) => setValues((state) => ({ ...state, title: value }))}
            placeholder={translate(messages.enterQuestion)}
            value={values.title}
            disabled={isRestrictedBoSoViewerRole}
            style={{ direction: rtl ? 'rtl' : 'ltr' }}
          />
          <EditableContent
            className="editor-add-question__bottom-description"
            onChange={(value) => setValues((state) => ({ ...state, descriptionRight: value }))}
            placeholder={translate(messages.addBottomDescription)}
            value={values.descriptionRight}
            disabled={isRestrictedBoSoViewerRole}
            style={{ direction: rtl ? 'rtl' : 'ltr' }}
          />
          <div>
            <SecondaryButton onClick={() => setStage(stages.createAnswer)} link>
              {translate(messages.createNewAnswer)}
            </SecondaryButton>{' '}
            {translate(messages.or)}{' '}
            <SecondaryButton onClick={() => setShowAnswerBank(true)} link>
              {translate(messages.openAnswerBank)}
            </SecondaryButton>
          </div>
        </div>
      </div>
      <div className="editor-add-question__action-bar">
        {isMyVendorsTabSelected && (
          <>
            <RequiredSwitcher
              onSave={(value) => setValues((state) => ({ ...state, optional: value }))}
              defaultValue={values.optional}
            />
            <Divider vertical size="xxs" />
            <VisibleSwitcher
              onSave={(value) => setValues((state) => ({ ...state, enabled: value }))}
              defaultValue={values.enabled}
              data-test="editor-add-question-visible-switcher"
            />
          </>
        )}
        <Button
          data-test="editor-add-question-cancel-button"
          className="editor-add-question__cancel-button"
          size="sm"
          color="gray"
          onClick={onClickCancel}
          link
        >
          {translate(messages.cancel)}
        </Button>
      </div>
      <AnswerBank
        open={showAnswerBank}
        toggleModal={setShowAnswerBank}
        selectAnswerType={selectAnswerType}
      />
      {loading && <Loader />}
    </div>
  );

  const renderAddButton = (renderTriggerModal, disabled) => {
    return (
      <div className="editor-add-question__add-button-wrapper">
        <SecondaryButton
          data-test={`editor-add-question-add-button${disabled ? '-disabled' : ''}`}
          className="editor-add-question__add-button"
          dashed
          disabled={disabled || isRestrictedBoSoViewerRole}
          onClick={onShowForm}
          icon="plus"
          size="large"
        >
          {translate(messages.addQuestion)}
        </SecondaryButton>
        <SecondaryButton
          data-test={`editor-add-question-add-trigger-button${
            isRestrictedBoSoViewerRole ? '-disabled' : ''
          }`}
          className="editor-add-question__add-trigger-button"
          color="purple"
          dashed
          disabled={isRestrictedBoSoViewerRole}
          onClick={onShowAddTriggerForm}
          icon="plus"
          size="large"
        >
          {translate(messages.addAppTrigger)}
        </SecondaryButton>
        {renderTriggerModal && (
          <AddAppTrigger categoryId={subject.id} onCancel={() => setStage(stages.addButton)} />
        )}
      </div>
    );
  };

  switch (stage) {
    case stages.addButton:
      return hideAppTriggers ? (
        <div className="editor-add-question__add-button-wrapper">
          <SecondaryButton
            data-test="editor-add-question-add-button"
            className="editor-add-question__add-button"
            dashed
            onClick={onShowForm}
            icon="plus"
            size="large"
            disabled={isRestrictedBoSoViewerRole}
          >
            {translate(messages.addQuestion)}
          </SecondaryButton>
        </div>
      ) : (
        renderAddButton()
      );
    case stages.addQuestion:
      return (
        <>
          {renderAddQuestion()}
          {!hideAddButton && renderAddButton(false, true)}
        </>
      );
    case stages.createAnswer:
      return (
        <>
          <AnswerCreator
            className={className}
            onAddQuestion={onAddQuestion}
            onCancel={() => setStage(stages.addQuestion)}
            title={values.title || defaultTitle}
            disabled={isRestrictedBoSoViewerRole}
          />
          {renderAddButton(false, true)}
        </>
      );
    case stages.addAppTrigger:
      return renderAddButton(true);
    default:
      return null;
  }
};

AddQuestion.propTypes = {
  className: PropTypes.string,
  defaultStage: PropTypes.string,
  defaultValues: PropTypes.object,
  onSuccess: PropTypes.func,
  setActiveQuestion: PropTypes.func,
  hideAddButton: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default AddQuestion;
