import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import tail from 'lodash/tail';
import { useDispatch } from 'react-redux';

import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';
import { Roles } from 'constants/roles';
import Select from 'ui/Select';
import { getOrganizationProfiles, setProfileOrganizationRole } from 'api/organizations';
import { API_STATUS } from 'constants/api';
import { isDowngradeRole } from 'utils/users';

import TransferOwnershipModal from './transferOwnershipModal';
import DowngradeRoleModal from './downgradeRoleModal';

const RoleSelect = ({ currentUserRole, role, orgId, orgIndex, profileId, userName }) => {
  const [value, setValue] = useState();
  const [newrole, setNewRole] = useState();
  const dispatch = useDispatch();
  const [transferOwnershipModalOpen, setTransferOwnershipModalOpen] = useState(false);
  const [downgradeModalOpen, setDowngradeModalOpen] = useState(false);

  const onSelect = async (selected) => {
    setValue(selected);
    const status = await setProfileOrganizationRole(orgId, profileId, selected);
    await dispatch(getOrganizationProfiles({ organizationId: orgId, orgIndex }));
    if (status === API_STATUS.SUCCESS) {
      notification.success({ message: translate(messages.orgEditUserRoleUpdateSuccess) });
      return;
    }
    notification.error({ message: translate(messages.orgEditUserRoleUpdateError) });
  };

  const onChangeRole = (selected) => {
    setNewRole(selected);

    if (selected === Roles.Owner) {
      return setTransferOwnershipModalOpen(true);
    }

    if (isDowngradeRole(role, selected)) {
      return setDowngradeModalOpen(true);
    }

    onSelect(selected);
  };

  useEffect(() => {
    setValue(role);
  }, [role]);

  const options = [
    { label: translate(messages.Owner), value: Roles.Owner, key: 'OwnerRoleUpdate' },
    { label: translate(messages.Admin), value: Roles.Admin, key: 'AdminRoleUpdate' },
    { label: translate(messages.BO), value: Roles.BO, key: 'BORoleUpdate' },
    { label: translate(messages.SO), value: Roles.SO, key: 'SORoleUpdate' },
    { label: translate(messages.Viewer), value: Roles.Viewer, key: 'ViewerRoleUpdate' },
    { label: translate(messages.Auditor), value: Roles.Auditor, key: 'AuditorRoleUpdate' },
  ];

  return (
    <div>
      <Select
        size="large"
        color="default"
        value={value}
        onChange={onChangeRole}
        options={currentUserRole === 0 ? tail(options) : options}
        className="user-management-tab__role-selection"
      />
      <TransferOwnershipModal
        open={transferOwnershipModalOpen}
        setOpen={setTransferOwnershipModalOpen}
        userName={userName}
        onOk={() => onSelect(newrole)}
      />
      <DowngradeRoleModal
        open={downgradeModalOpen}
        setOpen={setDowngradeModalOpen}
        userName={userName}
        onOk={() => onSelect(newrole)}
        newRole={newrole}
      />
    </div>
  );
};

RoleSelect.propTypes = {
  currentUserRole: PropTypes.number,
  role: PropTypes.number,
  orgId: PropTypes.string,
  orgIndex: PropTypes.string,
  profileId: PropTypes.string,
  userName: PropTypes.string,
};

export default RoleSelect;
