export const messages = {
  stageZeroTitle: {
    key: 'Onboard.stageZeroTitle',
    defaultMessage: 'Lets get started by setting up your account:',
  },
  stageZeroGoogleError: {
    key: 'Onboard.stageZeroGoogleError',
    defaultMessage: 'Unable to login with google',
  },
  stageZeroDivider: {
    key: 'Onboard.stageZeroDivider',
    defaultMessage: 'OR',
  },
  stageZeroNextStep: {
    key: 'Onboard.stageZeroNextStep',
    defaultMessage: 'Continue',
  },
  stageZeroTermsText: {
    key: 'Onboard.stageZeroTermsText',
    defaultMessage: 'By proceeding, you agree to the',
  },
  stageZeroLeftBoxContentP1: {
    key: 'Onboard.stageZeroLeftBoxContentP1',
    defaultMessage:
      'Findings enable companies to overcome compliance and supply chain challenges easily ” ',
  },
  stageZeroTermsLink: {
    key: 'Onboard.stageZeroTermsLink',
    defaultMessage: 'Findings’ Terms',
  },
  stageZeroLeftBoxContentP2: {
    key: 'Onboard.stageZeroLeftBoxContentP2',
    defaultMessage: 'Randall Hopkins,',
  },
  stageOneTitle: {
    key: 'Onboard.stageOneTitle',
    defaultMessage: 'Please choose your password:',
  },
  stageOnePasswordError: {
    key: 'Onboard.stageOnePasswordError',
    defaultMessage: 'Unable to set password',
  },
  authMethodTitle: {
    key: 'Onboard.authMethodTitle',
    defaultMessage: 'Second authentication method:',
  },
  stageTwoTitle: {
    key: 'Onboard.stageTwoTitle',
    defaultMessage: 'Please fill in your personal details: ',
  },
  stageTwoInputFullNameLabel: {
    key: 'Onboard.stageTwoInputFullNameLabel',
    defaultMessage: 'Full name',
  },
  stageTwoInputFullNamePlaceholder: {
    key: 'Onboard.stageTwoInputFullNamePlaceholder',
    defaultMessage: 'Enter full name',
  },
  stageTwoInputNameError: {
    key: 'Onboard.stageTwoInputNameError',
    defaultMessage: 'Only alphabets are allowed',
  },
  stageTwoInputRequiredName: {
    key: 'Onboard.stageTwoInputRequiredName',
    defaultMessage: 'Name is required',
  },
  stageTwoInputEmailLabel: {
    key: 'Onboard.stageTwoInputEmailLabel',
    defaultMessage: 'Work email',
  },
  stageTwoInputEmailError: {
    key: 'Onboard.stageTwoInputEmailError',
    defaultMessage: 'Email format is invalid',
  },
  stageTwoInputRequiredEmail: {
    key: 'Onboard.stageTwoInputRequiredEmail',
    defaultMessage: 'Email is required',
  },
  stageTwoInputLanguageLabel: {
    key: 'Onboard.stageTwoInputLanguageLabel',
    defaultMessage: 'Language',
  },
  stageTwoInputRequiredLanguage: {
    key: 'Onboard.stageTwoInputRequiredLanguage',
    defaultMessage: 'Language is required',
  },
  stageTwoMfaSetSuccess: {
    key: 'Onboard.stageTwoMfaSetSuccess',
    defaultMessage: 'Second authentication method successfully set',
  },
  stageTwoMfaSetFail: {
    key: 'Onboard.stageTwoMfaSetFail',
    defaultMessage: 'Unable to set second authentication method',
  },
  stageTwoInputPhoneLabel: {
    key: 'Onboard.stageTwoInputPhoneLabel',
    defaultMessage: 'Phone',
  },
  stageTwoInputPhoneError: {
    key: 'Onboard.stageTwoInputPhoneError',
    defaultMessage: 'Invalid phone number',
  },
  stageTwoPhoneErrorRequired: {
    key: 'Onboard.stageTwoPhoneErrorRequired',
    defaultMessage: 'Phone is required',
  },
  stageTwoPrefixErrorRequired: {
    key: 'Onboard.stageTwoPrefixErrorRequired',
    defaultMessage: 'Prefix is required',
  },
  stageTwoAuthTitle: {
    key: 'Onboard.stageTwoAuthTitle',
    defaultMessage: 'Authentication code',
  },
  stageTwoAuthSubTitle: {
    key: 'Onboard.stageTwoAuthSubTitle',
    defaultMessage: 'Please enter the code you received via sms:',
  },
  stageTwoAuthTestMfaErrorCode: {
    key: 'Onboard.stageTwoAuthTestMfaErrorCode',
    defaultMessage: 'Code is incorrect, please try again',
  },
  stageTwoAuthTestMfaEmptyCode: {
    key: 'Onboard.stageTwoAuthTestMfaEmptyCode',
    defaultMessage: 'Please enter code',
  },
  stageThreeCompanyNameLabel: {
    key: 'Onboard.stageThreeCompanyNameLabel',
    defaultMessage: 'Company',
  },
  stageThreeCompanyNamePlaceHolder: {
    key: 'Onboard.stageThreeCompanyNamePlaceHolder',
    defaultMessage: 'Enter name',
  },
  stageThreeSubmitButton: {
    key: 'Onboard.stageThreeSubmitButton',
    defaultMessage: 'save & continue',
  },
  stageThreeSetProfileError: {
    key: 'Onboard.stageThreeSetProfileError',
    defaultMessage: 'Unable to set Profile',
  },
  stageOneLeftBoxContent: {
    key: 'Onboard.stageOneLeftBoxContent',
    defaultMessage:
      'Click the Explain button and our AI will give you a clear idea of what you were asked',
  },
  stageTwoLeftBoxContent: {
    key: 'Onboard.stageTwoLeftBoxContent',
    defaultMessage: 'Did you know you can automate your response with Findings at no extra cost.',
  },
  stageThreeLeftBoxContent: {
    key: 'Onboard.stageThreeLeftBoxContent',
    defaultMessage:
      'Struggling to find an answer? Our Share feature lets you effortlessly gather insights from colleagues and experts via email, with their responses automatically added into the system, no onboarding required.',
  },
  yourAdminRequires2Fa: {
    key: 'Login.yourAdminRequires2Fa',
    defaultMessage: 'Your admin requires 2FA.',
  },
  pleaseSelectYourPreferred: {
    key: 'Login.pleaseSelectYourPreferred',
    defaultMessage: 'Please select your preferred method:',
  },
  workEMail: {
    key: 'Login.workEMail',
    defaultMessage: 'Work email',
  },
};

export default {
  prefix: 'Onboard',
  messages,
};
