import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

// COMPONENTS
import StageZero from './stageZero';
import StageOne from 'pages/Activation/Onboarding/stageOne';
import StageTwo from 'pages/Activation/Onboarding/stageTwo';
import Stage2faMethod from 'pages/Activation/Onboarding/stage2FaMethod';
import StageThree from 'pages/Activation/Onboarding/stageThree';
import { useSearchParams } from 'react-router-dom';
import CardWithEnforce2Fa from 'components/CardWithEnforce2Fa';

// CONSTANTS
import { ONBOARDING_FORM_STAGE } from 'pages/Activation/Onboarding/lib';
import ROUTES from 'constants/routes';
import { DEFAULT_ORGANIZATION_INDEX } from 'utils/organizations';

// UTILS AND HOOKS
import { useRole } from 'hooks/useRole';
import { useAppNavigate } from 'hooks/navigation';
import { selectProfile } from 'pages/Profile/selectors';

import './index.scss';

const Onboarding = () => {
  const [stage, setStage] = useState(ONBOARDING_FORM_STAGE.STAGE_0);
  const { isBoRole, isBoSoRole, isAuditorRole } = useRole();
  const appNavigate = useAppNavigate();
  const { assessmentId } = useParams();
  const [searchParams] = useSearchParams();
  const { user } = useSelector(selectProfile);

  const finishOnboarding = () => {
    if (isBoSoRole) {
      return appNavigate(isBoRole ? ROUTES.VENDOR_VENDORS : ROUTES.CUSTOMER_CUSTOMERS);
    }

    if (isAuditorRole) {
      return appNavigate(ROUTES.VENDOR_RECENT);
    }

    if (assessmentId) {
      if (searchParams.get('showcase') === 'true') {
        return appNavigate(
          user?.profile?.view ? ROUTES.CUSTOMER_SHOWCASE_PREVIEW : ROUTES.VENDOR_SHOWCASE_PREVIEW,
          {
            organization: DEFAULT_ORGANIZATION_INDEX,
            showcaseId: assessmentId,
          },
        );
      }
      const appId = searchParams.get('app');
      if (appId) {
        return appNavigate(ROUTES.CUSTOMER_CONTINUOUS_MONITORING, { assessmentId, appId });
      }
      return appNavigate(ROUTES.CUSTOMER_ASSESSMENT, {
        organization: DEFAULT_ORGANIZATION_INDEX,
        assessmentId,
      });
    }

    return appNavigate(ROUTES.CUSTOMER_RECENT, { organization: DEFAULT_ORGANIZATION_INDEX });
  };

  return (
    <div className="onboarding-page">
      {stage === ONBOARDING_FORM_STAGE.STAGE_0 && <StageZero setStage={setStage} />}

      {stage === ONBOARDING_FORM_STAGE.STAGE_1 && <StageOne setStage={setStage} />}

      {stage === ONBOARDING_FORM_STAGE.STAGE_2 && <StageTwo setStage={setStage} />}

      {stage === ONBOARDING_FORM_STAGE.STAGE_2FA_METHOD && (
        <CardWithEnforce2Fa>
          <Stage2faMethod setStage={setStage} finishOnboarding={finishOnboarding} />
        </CardWithEnforce2Fa>
      )}

      {stage === ONBOARDING_FORM_STAGE.STAGE_3 && (
        <StageThree setStage={setStage} finishOnboarding={finishOnboarding} />
      )}
    </div>
  );
};

export default Onboarding;
