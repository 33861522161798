import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import AppResults from 'components/AppsMonitoring/appResults';
import AppLink from 'components/AppsMonitoring/appLinks';
import Button from 'ui/Button';
import {
  appIcons,
  appsNames,
  appsStatuses,
} from 'pages/Vendors/VendorManage/Tabs/ContinuousMonitoring/lib';
import { translate } from 'utils/index';
import { messages } from 'pages/Vendors/VendorManage/Tabs/ContinuousMonitoring/messages';
import { AppStatus } from 'constants/appStatus';
import Clock from 'assets/svgs/clock2.svg';
import Icon from 'ui/Icon';
import { Apps } from 'constants/apps';

function AppResultsCard({ app, currentVendor }) {
  const appClassName = classNames('continuous-monitoring-tab__app', {
    [`continuous-monitoring-tab__app--${appsNames[app.id]}`]: app.id,
  });

  const header = useMemo(() => {
    return (
      <div className="continuous-monitoring-tab__app-header">
        <h3 className="continuous-monitoring-tab__app-title" data-test="app-name">
          <img
            className="continuous-monitoring-tab__app-icon"
            src={appIcons[app.id]}
            alt={app.name}
          />
          {app.name}
        </h3>
        <span
          className={classNames('continuous-monitoring-tab__app-status', {
            [`continuous-monitoring-tab__app-status--${appsStatuses[app.status]}`]: app.status,
          })}
          data-test="app-status"
        >
          {translate(messages[appsStatuses[app.status]])}
        </span>
      </div>
    );
  }, [app.id, app.name, app.status]);

  const results = useMemo(
    () => (
      <>
        <div className="continuous-monitoring-tab__app-body">
          <AppResults results={app?.results || {}} appId={app.id} />
        </div>
        <div className="continuous-monitoring-tab__app-footer">
          <AppLink app={app} currentVendor={currentVendor} />
        </div>
      </>
    ),
    [app.id, currentVendor.id],
  );

  const inProgress = useMemo(
    () => (
      <div className="continuous-monitoring-tab__app-placeholder" data-test="app-placeholder">
        {/* TODO: change the way img is rendered? */}
        <img className="continuous-monitoring-tab__app-placeholder-icon" src={Clock} alt="Clock" />
        <div>{translate(messages.inProgressItMightTake)}</div>
      </div>
    ),
    [app.status],
  );

  const noResults = useMemo(
    () =>
      app.id !== Apps.ssc ? (
        <div className="continuous-monitoring-tab__app-no-result" data-test="app-no-result">
          <Icon className="continuous-monitoring-tab__app-no-result-icon" icon="no-scan" />
          <div>{translate(messages.noScanResultForDomain)}</div>
        </div>
      ) : (
        <Button color="blue" outline data-test="continuous-monitoring-tab-button-ssc-scan-vendor">
          {/* TODO: Check what functionality should be implemented if any */}
          {translate(messages.scanVendor)}
        </Button>
      ),
    [app.status, app.id],
  );

  return (
    <div className={appClassName} data-test={`app-name-${appsNames[app.id]}`}>
      {header}
      {!!app?.results &&
        (app?.status === AppStatus.active || app?.status === AppStatus.inactive) &&
        results}
      {app?.status === AppStatus.inProgress && inProgress}
      {app?.status === AppStatus.noResults && noResults}
    </div>
  );
}

AppResultsCard.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    results: PropTypes.object,
  }).isRequired,
  currentVendor: PropTypes.object,
};

export default AppResultsCard;
