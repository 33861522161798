import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import classNames from 'classnames';

import { translate } from 'utils/index';
import { getRiskValueByScore } from 'utils/vendor';
import Table from 'ui/Table';
import Icon from 'ui/Icon';
import ScoreBenchmark from 'components/AssessmentScoreBenchmark';

import { messages } from '../messages';
import './averageScorePopover.scss';

const AverageScorePopover = ({ children, score, vendor }) => {
  const [popover, setPopover] = useState(false);

  const assessmentsScoreColumns = [
    {
      title: '',
      dataIndex: 'name',
      width: 250,
      render: (name) => {
        return (
          <div data-test="recent-page-vendors-average-score-popover-assessments-name">{name}</div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'score',
      width: 200,
      render: (score, assessment) => (
        <div data-test="recent-page-vendors-average-score-popover-assessments-score-benchmark">
          <ScoreBenchmark score={score} benchmark={assessment.benchmark} />
        </div>
      ),
    },
  ];

  const appsScoresColumns = [
    {
      title: '',
      dataIndex: 'name',
      width: 250,
      render: (name) => {
        return <div data-test="recent-page-vendors-average-score-popover-apps-name">{name}</div>;
      },
    },
    {
      title: '',
      dataIndex: 'results',
      width: 100,
      render: (results) => {
        return (
          <div data-test="recent-page-vendors-average-score-popover-apps-results">
            {results.score || results.Score || results.Grade || results.rating}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'results',
      width: 100,
      render: (results) => {
        const findings = results.findings || results.Amount;

        if (!findings) {
          return null;
        }

        return (
          <div
            className="recent-page-vendors-average-score-popover__app-findings"
            data-test="recent-page-vendors-average-score-popover-apps-findings"
          >
            {findings}
            <Icon
              className="recent-page-vendors-average-score-popover__app-findings-icon"
              icon="flag"
            />
          </div>
        );
      },
    },
  ];

  const riskValue = getRiskValueByScore(score);

  const scoreClassName = classNames('recent-page-vendors-average-score-popover__header-score', {
    [`recent-page-vendors-average-score-popover__header-score--${riskValue}`]: true,
  });

  const title = (
    <div className="recent-page-vendors-average-score-popover__header">
      <div className="recent-page-vendors-average-score-popover__header-content">
        <div className="recent-page-vendors-average-score-popover__header-title">
          {translate(messages.averageScore)}
        </div>
        <span className="recent-page-vendors-average-score-popover__header-sub-title">
          {translate(messages.basedOn)}:
        </span>
      </div>
      <div className={scoreClassName}>
        <div
          data-test="recent-page-vendors-average-score-popover-header-score-percentage"
          className="recent-page-vendors-average-score-popover__header-score-percentage"
        >
          {Math.round(score * 100) / 100}%
        </div>
        <div
          data-test="recent-page-vendors-average-score-popover-header-score-value"
          className="recent-page-vendors-average-score-popover__header-score-title"
        >
          {riskValue} {translate(messages.score)}
        </div>
      </div>
    </div>
  );

  const popup = (
    <div className="recent-page-vendors-average-score-popover__content">
      <div className="recent-page-vendors-average-score-popover__content-title">
        {translate(messages.assessmentsScore)}:
      </div>
      {vendor.assessmentScores.length > 0 ? (
        <Table
          className="recent-page-vendors-average-score-popover__assessments-score-table"
          columns={assessmentsScoreColumns}
          dataSource={vendor.assessmentScores}
          pagination={false}
        />
      ) : (
        <div className="recent-page-vendors-average-score-popover__empty-state">
          <div className="recent-page-vendors-average-score-popover__empty-state-label">
            {translate(messages.emptyStateLabel)}
          </div>
          <div className="recent-page-vendors-average-score-popover__empty-state-value">
            {translate(messages.emptyStateValue)}
          </div>
        </div>
      )}

      <div className="recent-page-vendors-average-score-popover__apps-content-title">
        {translate(messages.apps)}:
      </div>
      {vendor?.appsScores?.length > 0 ? (
        <Table
          className="recent-page-vendors-average-score-popover__assessments-score-table"
          columns={appsScoresColumns}
          dataSource={vendor.appsScores}
          pagination={false}
        />
      ) : (
        <div className="recent-page-vendors-average-score-popover__empty-state">
          <div className="recent-page-vendors-average-score-popover__empty-state-label">
            {translate(messages.noAppsScore)}
          </div>
          <div className="recent-page-vendors-average-score-popover__empty-state-value">
            {translate(messages.emptyStateValue)}
          </div>
        </div>
      )}
    </div>
  );

  if (vendor.assessmentScores.length === 0 && vendor.appsScores.length === 0) {
    return children;
  }

  return (
    <Popover
      className="recent-page-vendors-average-score-popover"
      content={popup}
      title={title}
      trigger="hover"
      open={popover}
      onOpenChange={setPopover}
    >
      {children}
    </Popover>
  );
};

AverageScorePopover.propTypes = {
  children: PropTypes.node,
  score: PropTypes.number,
  vendor: PropTypes.object,
};

export default AverageScorePopover;
