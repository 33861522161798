import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LoaderIndicator from 'ui/Loader/indicator';
import './buttonLoader.scss';

const ButtonLoader = ({ color, size }) => {
  const loaderClassName = classNames('ui-button-loader', {
    [`ui-button-loader--${color}`]: true,
    [`ui-button-loader--${size}`]: true,
  });

  return (
    <span className={loaderClassName}>
      <span className="ui-button-loader__indicator">
        <LoaderIndicator />
      </span>
    </span>
  );
};

ButtonLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

export default ButtonLoader;
