import React, { useState } from 'react';
import PropType from 'prop-types';

import { ReactComponent as EditIcon } from 'assets/svgs/edit-pen.svg';
import { ReactComponent as CheckMarkIcon } from 'assets/svgs/check-mark-alt.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/close-x-cancel.svg';
import Input from 'ui/Input';
import { translate } from 'utils/index';
import { messages } from './messages';

import './categroyTabLabel.scss';

const EDIT_ICON_SIZE = 15;
const CLOSE_ICON_SIZE = 12;

const CategoryTabLabel = ({ text, editAction, isEditing, saveAction, cancelAction }) => {
  const [value, setValue] = useState(text || '');

  const handleSetValue = (event) => {
    setValue(event.target.value);
  };

  const handleSaveAction = () => {
    saveAction(value);
  };

  return (
    <div className="category-tab-label">
      {isEditing ? (
        <div
          className="category-tab-label__editing"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Input
            onChange={handleSetValue}
            value={value}
            size="small"
            placeholder={translate(messages.inputPlaceholder)}
          />{' '}
          <CheckMarkIcon onClick={handleSaveAction} width={15} height={12} />
          <CloseIcon onClick={cancelAction} width={CLOSE_ICON_SIZE} height={CLOSE_ICON_SIZE} />
        </div>
      ) : (
        <>
          {text}
          <EditIcon
            className="category-tab-label__edit-icon"
            onClick={editAction}
            width={EDIT_ICON_SIZE}
            height={EDIT_ICON_SIZE}
          />
        </>
      )}
    </div>
  );
};

CategoryTabLabel.propTypes = {
  text: PropType.string,
  editAction: PropType.func,
  saveAction: PropType.func,
  cancelAction: PropType.func,
  isEditing: PropType.bool,
};

export default CategoryTabLabel;
