import React from 'react';
import PropTypes from 'prop-types';

const ExportCsvFileIcon = ({
  color = 'currentColor',
  height = '24',
  width = '24',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4.25C4 3.0095 5.0095 2 6.25 2H12.75C12.957 2 13.1445 2.08404 13.2803 2.21973L19.7803 8.71973C19.916 8.85541 20 9.04275 20 9.25V13.1758C19.772 13.0668 19.5195 13 19.25 13C18.9805 13 18.728 13.0668 18.5 13.1758V10H14.25C13.0095 10 12 8.9905 12 7.75V3.5H6.25C5.8365 3.5 5.5 3.8365 5.5 4.25V19.75C5.5 20.1635 5.8365 20.5 6.25 20.5H15.1748C15.2598 20.6775 15.3692 20.8438 15.5127 20.9873L16.5254 22H6.25C5.0095 22 4 20.9905 4 19.75V4.25ZM17.4395 8.5L13.5 4.56055V7.75C13.5 8.1635 13.8365 8.5 14.25 8.5H17.4395ZM18.5 14.75C18.5 14.3355 18.8355 14 19.25 14C19.6645 14 20 14.3355 20 14.75V20.4395L21.2197 19.2197C21.5127 18.9267 21.9873 18.9267 22.2803 19.2197C22.5728 19.5127 22.5728 19.9873 22.2803 20.2803L19.7803 22.7803C19.6338 22.9268 19.442 23 19.25 23C19.058 23 18.8657 22.9268 18.7197 22.7803L16.2197 20.2803C15.9267 19.9878 15.9267 19.5122 16.2197 19.2197C16.5127 18.9267 16.9873 18.9267 17.2803 19.2197L18.5 20.4395V14.75ZM9.84667 11.6172C9.97755 11.5341 10.1251 11.4962 10.2695 11.5C10.5102 11.5064 10.7437 11.628 10.8828 11.8467L12 13.6026L13.1172 11.8477C13.3402 11.4977 13.8038 11.3952 14.1523 11.6172C14.5013 11.8397 14.6048 12.3038 14.3828 12.6533L12.8896 15L14.3828 17.3467C14.6048 17.6962 14.5013 18.1603 14.1523 18.3828C14.0278 18.4623 13.889 18.5 13.751 18.5C13.5035 18.5 13.2607 18.3769 13.1172 18.1524L12 16.3975L10.8828 18.1533C10.7403 18.3773 10.4965 18.5 10.249 18.5C10.111 18.5 9.97167 18.4618 9.84667 18.3828C9.49767 18.1603 9.39518 17.6972 9.61718 17.3477L11.1113 15L9.61718 12.6524C9.39518 12.3029 9.49767 11.8397 9.84667 11.6172Z"
      fill={color}
    />
  </svg>
);

ExportCsvFileIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ExportCsvFileIcon;
