import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { generalMessages } from 'constants/messages';
import { translate } from 'utils/index';

import './vendorRisk.scss';

const VendorRisk = ({ value }) => {
  const className = classNames(
    'recent-page-vendors-vendor-risk',
    `recent-page-vendors-vendor-risk--${value}`,
  );

  const riskValue = useMemo(() => {
    const formattedValue = value?.toLowerCase();

    if (formattedValue === 'low') {
      return translate(generalMessages.lowRisk);
    }

    if (formattedValue === 'medium') {
      return translate(generalMessages.mediumRisk);
    }

    if (formattedValue === 'high') {
      return translate(generalMessages.highRisk);
    }

    return null;
  }, [value]);

  if (!riskValue) {
    return (
      <div className="recent-page-vendors-vendor-risk__empty-vendor-risk">
        {translate(generalMessages.na)}
      </div>
    );
  }

  return <div className={className}>{riskValue}</div>;
};

VendorRisk.propTypes = {
  value: PropTypes.string,
};

export default VendorRisk;
