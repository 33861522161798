import { useCallback } from 'react';
import { getProfileOrganizations, setOrganizationLogo } from 'api/organizations';
import { API_STATUS } from 'constants/api';
import { notification } from 'antd';
import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';
import { useDispatch } from 'react-redux';

function useLogoUpload({
  setCurrentLogoPath,
  organizationId,
  orgIndex,
  setPopupState,
  abortController,
  startUploading,
  finishUploding,
}) {
  const dispatch = useDispatch();

  const setLogo = useCallback(
    async (file) => {
      startUploading();
      const setOrgResp = await dispatch(
        setOrganizationLogo({ orgId: organizationId, orgIndex, file, abortController }),
      );
      finishUploding();

      if (setOrgResp?.payload?.status === API_STATUS.SUCCESS) {
        const { payload } = await dispatch(getProfileOrganizations());
        setCurrentLogoPath(payload[orgIndex].corporateLogo);
        return notification.success({ message: translate(messages.orgEditLinkUploadLogoSuccess) });
      }
      return notification.error({ message: translate(messages.orgEditLinkUploadLogoError) });
    },
    [organizationId, orgIndex],
  );

  const onRemove = useCallback(async () => {
    setPopupState('removePopup');
    const {
      payload: { status },
    } = await dispatch(setOrganizationLogo({ orgId: organizationId, orgIndex, file: null }));

    if (status === API_STATUS.SUCCESS) {
      const { payload } = await dispatch(getProfileOrganizations());
      setCurrentLogoPath(payload[orgIndex].corporateLogo);
      return notification.success({ message: translate(messages.orgEditLinkRemoveLogoSuccess) });
    }
    return notification.error({ message: translate(messages.orgEditLinkRemoveLogoError) });
  }, [organizationId, orgIndex]);

  return { setLogo, onRemove };
}

export default useLogoUpload;
