export const messages = {
  overview: {
    key: 'Overview.overview',
    defaultMessage: 'Overview',
  },
  numberOfVendors: {
    key: 'Overview.numberOfVendors',
    defaultMessage: 'Number of vendors',
  },
  activeAssessments: {
    key: 'Overview.activeAssessments',
    defaultMessage: 'Active assessments',
  },
  overdueAssessments: {
    key: 'Overview.overdueAssessments',
    defaultMessage: 'Overdue assessments',
  },
  highRisk: {
    key: 'Overview.highRisk',
    defaultMessage: 'Enterprise is at <strong>high</strong> risk',
  },
  mediumRisk: {
    key: 'Overview.mediumRisk',
    defaultMessage: 'Enterprise is at <strong>medium</strong> risk',
  },
  lowRisk: {
    key: 'Overview.lowRisk',
    defaultMessage: 'Enterprise is at <strong>low</strong> risk',
  },
  openFindings: {
    key: 'Overview.openFindings',
    defaultMessage: 'Open findings',
  },
  recommendations: {
    key: 'Overview.recommendations',
    defaultMessage: 'Recommendations',
  },
  overdueRecommendations: {
    key: 'Overview.overdueRecommendations',
    defaultMessage: 'Overdue recommendations',
  },
  progress: {
    key: 'Overview.progress',
    defaultMessage: 'Progress',
  },
  averageScore: {
    key: 'Overview.averageScore',
    defaultMessage: 'Average score for all assessments',
  },
  averageProgress: {
    key: 'Overview.averageProgress',
    defaultMessage: 'Average progress for all assessments',
  },
  assessmentStatistics: {
    key: 'Overview.assessmentStatistics',
    defaultMessage: 'Assessment Statistics',
  },
  completed: {
    key: 'Overview.completed',
    defaultMessage: 'Completed',
  },
  inProgress: {
    key: 'Overview.inProgress',
    defaultMessage: 'In progress',
  },
  notStarted: {
    key: 'Overview.notStarted',
    defaultMessage: 'Not started',
  },
  findingStatus: {
    key: 'Overview.findingStatus',
    defaultMessage: 'Finding Status',
  },
  status: {
    key: 'Overview.status',
    defaultMessage: 'STATUS',
  },
  scoreCategory: {
    key: 'Overview.scoreCategory',
    defaultMessage: 'SCORE/CATEGORY',
  },
  score: {
    key: 'Overview.score',
    defaultMessage: 'SCORE',
  },
  passFail: {
    key: 'Overview.passFail',
    defaultMessage: 'PASS/FAIL',
  },
  pending: {
    key: 'Overview.pending',
    defaultMessage: 'Pending',
  },
  accept: {
    key: 'Overview.accept',
    defaultMessage: 'Accept',
  },
  approved: {
    key: 'Overview.approved',
    defaultMessage: 'Approved',
  },
  findings: {
    key: 'Overview.findings',
    defaultMessage: 'Findings',
  },
  passed: {
    key: 'Overview.passed',
    defaultMessage: 'Passed',
  },
  failed: {
    key: 'Overview.failed',
    defaultMessage: 'Failed',
  },
};

export default {
  prefix: 'Overview',
  messages,
};
