export const messages = {
  showMore: {
    key: 'Expander.showMore',
    defaultMessage: 'SHOW MORE',
  },
  showLess: {
    key: 'Expander.showLess',
    defaultMessage: 'SHOW LESS',
  },
};

export default {
  prefix: 'Expander',
  messages,
};
