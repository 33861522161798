import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getRiskValueByScore } from 'utils/vendor';
import './inherentRisk.scss';

const InherentRisk = ({ score }) => {
  const value = getRiskValueByScore(score);

  const className = classNames(
    'recent-page-vendors-inherent-risk',
    `recent-page-vendors-inherent-risk--${value}`,
  );

  return (
    <div className={className}>
      <div className="recent-page-vendors-inherent-risk__wrapper">
        <div className="recent-page-vendors-inherent-risk__score">{score}</div>
        <div className="recent-page-vendors-inherent-risk__value">{value}</div>
      </div>
    </div>
  );
};

InherentRisk.propTypes = {
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InherentRisk;
