import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const TabPane = ({ children, tab, tabPaneContainerClassName, ...props }) => {
  const tabPaneClassNames = classNames('ui-tab-pane', tabPaneContainerClassName);

  return (
    <div className={tabPaneClassNames} data-test={props['data-test']} {...props}>
      {children}
    </div>
  );
};

TabPane.propTypes = {
  tabPaneContainerClassName: PropTypes.string,
  tab: PropTypes.string,
  children: PropTypes.node,
  'data-test': PropTypes.string.isRequired,
  tabKey: PropTypes.string,
};

export default TabPane;
