import * as yup from 'yup';

export const loginEmailSchema = (emailError) =>
  yup.object().shape({
    email: yup.string().email(emailError).required(),
  });

export const loginFullSchema = (emailError, requiredEmail, requiredPassword) =>
  yup.object({
    email: yup.string().email(emailError).required(requiredEmail),
    password: yup.string().required(requiredPassword),
  });
