import React from 'react';
import PropTypes from 'prop-types';

import Spin from 'ui/Spin';

import './title.scss';

const Title = ({ category, rowIndex, title }) => {
  const isPending =
    category.controls.filter((control) => control.enabled && control.pending).length > 0;

  return (
    <div className="assessment-finalized-categories-title">
      {rowIndex + 1}. {title}
      {isPending && <Spin className="assessment-finalized-categories-title__loader" size={16} />}
    </div>
  );
};

Title.propTypes = {
  category: PropTypes.object,
  rowIndex: PropTypes.number,
  title: PropTypes.string,
};

export default Title;
