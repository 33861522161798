import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import Icon from 'ui/Icon';
import { messages } from './messages';
import { translate } from 'utils/index';

function ExitFileUploadingConfirmationModal({ open, exit, stay }) {
  return (
    <Modal
      open={open}
      okText={translate(messages.modalStay)}
      onOk={stay}
      cancelText={translate(messages.modalExit)}
      onCancel={exit}
      closable={false}
      title={
        <>
          <Icon icon="info" />
          {translate(messages.modalTitle)}
        </>
      }
    >
      <p>{translate(messages.modalDescription)}</p>
    </Modal>
  );
}

ExitFileUploadingConfirmationModal.propTypes = {
  open: PropTypes.bool,
  exit: PropTypes.func,
  stay: PropTypes.func,
};

export default ExitFileUploadingConfirmationModal;
