import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, generatePath, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import noInternet from 'no-internet';
import PropTypes from 'prop-types';

// COMPONENTS
import PageSubheader from 'components/PageSubheader';
import UploadedFilesPreview from 'components/UploadedFilesPreview';
import Modal from 'ui/Modal';
import Divider from 'ui/Divider';
import Finalized from './finalized';
import CategoryHeader from './categoryHeader';
import CategoryNavigation from './categoryNavigation';
import Categories from './categories';
import Files from './files';
import Controls from './controls';
import Status from './status';
import DueDate from './dueDate';
import VendorName from 'pages/Assessment/vendorName';
import ErrorsNavigation from 'pages/Assessment/validation/errorsNavigation';
import NoInternetConnection from 'pages/Assessment/validation/noInternetConnection';
import Loader from 'ui/Loader';
import CloudMonitoringReviewPopup from 'pages/Assessment/cloudMonitoringReviewPopup';
import StrengthenAuth from 'components/StrengthenAuth';

// CONSTANTS
import { AssessmentStatus } from 'constants/assessmentStatus';
import { generalMessages } from 'constants/messages';
import ResponseAutomation from 'assets/svgs/response-automation.svg';
import { NO_INTERNET_CHECK_INTERVAL } from 'constants/general';

// API
import { getAssessment, saveControlsWithTriggers } from 'api/assessment';

// UTILS
import { translate } from 'utils/index';
import { transformPathToVendor, transformPathToCustomer } from 'utils/redirects';
import { messages } from './messages';

// HOOKS
import { useIsMyVendorsTabSelected, useSelectedPath } from 'hooks/navigation';

// REDUX
import { clearAssessment, resetErrors, selectCategory as selectCategoryReducer } from './reducers';
import {
  selectAllControlsWithUnAppliedSuggestion,
  selectAssessment,
  selectAssessmentErrors,
  selectCategory,
  selectIsAssessmentLoading,
} from './selectors';

// STYLES
import './index.scss';

const Assessment = ({ showcaseAssessmentPreview }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { pathname } = useLocation();
  const isPreview = pathname.includes('preview');
  const navigate = useNavigate();
  const assessment = useSelector(selectAssessment);
  const {
    due,
    title,
    finalized,
    status,
    total_score: totalScore,
    upstream,
    assessor,
    assesse,
    customerName,
    customerId,
  } = assessment;
  const loading = useSelector(selectIsAssessmentLoading);
  const controlsWithSuggestion = useSelector(selectAllControlsWithUnAppliedSuggestion);
  const category = useSelector(selectCategory);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const [useAutomationResponse, setUseAutomationResponse] = useState(true);
  const [automationResponseModal, setAutomationResponseModal] = useState(false);
  const [automationResponseLoading, setAutomationResponseLoading] = useState(false);
  const [noConnection, setNoConnection] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get('categoryId');
  const { errorsCount } = useSelector(selectAssessmentErrors);
  const selectedPath = useSelectedPath();

  useEffect(() => {
    return () => dispatch(resetErrors());
  }, []);

  const handleGetAssessment = useCallback(async () => {
    await dispatch(
      getAssessment({
        id: showcaseAssessmentPreview || params.assessmentId,
        preview: showcaseAssessmentPreview ? true : isPreview,
      }),
    ).unwrap();
  }, [showcaseAssessmentPreview, params.assessmentId, isPreview]);

  useEffect(() => {
    if (categoryId) {
      dispatch(selectCategoryReducer(categoryId));
    }
  }, [categoryId]);

  const headerRef = useRef(null);

  useEffect(() => {
    const ref = headerRef.current;
    if (ref && !showcaseAssessmentPreview) {
      ref.scrollIntoView(true);
    }
  }, [category.id]);

  useEffect(() => {
    if (showcaseAssessmentPreview) return;
    const { organization, assessmentId } = params;
    const vendorSelectedPath = transformPathToVendor(selectedPath);
    const customerSelectedPath = transformPathToCustomer(selectedPath);
    const vendorTabPath = generatePath(`${vendorSelectedPath}/*`, {
      organization,
      assessmentId,
      '*': `?${searchParams.toString()}`,
    });
    const customerTabPath = generatePath(`${customerSelectedPath}/*`, {
      organization,
      assessmentId,
      '*': `?${searchParams.toString()}`,
    });

    switch (upstream) {
      case true:
        if (!assessor && assesse) navigate(vendorTabPath, { replace: true }); // F T T
        else {
          navigate(customerTabPath, { replace: true }); // T T T + T F T + F F T
        }
        break;
      case false:
        if (!assessor && assesse) navigate(customerTabPath, { replace: true }); // F T F
        else {
          navigate(vendorTabPath, { replace: true }); // T T F + T F F
        }
        break;
      default:
        break;
    }
  }, [upstream, assessor, assesse]);

  useEffect(() => {
    if (useAutomationResponse && controlsWithSuggestion?.length > 0) {
      setTimeout(() => {
        setAutomationResponseModal(true);
      }, 1000);
    }
    if (Object.entries(category)?.length) {
      setUseAutomationResponse(false);
    }
  }, [category]);

  const showFinalized = useMemo(() => {
    return (finalized && assessor && !isPreview) || status > AssessmentStatus.reviewed;
  }, [status, finalized, assessor]);

  useEffect(() => {
    const controlId = searchParams.get('control');
    if (Object.keys(assessment).length === 0 || showFinalized || !controlId) {
      return null;
    }

    const control = assessment.categories
      .flatMap((category) => category.controls)
      .find((control) => control.id === controlId);

    dispatch(selectCategoryReducer(control.categoryId));

    setTimeout(() => {
      const parent = document.querySelector('.assessment__right-panel-inner');
      const controlElement = document.getElementById(`control-${controlId}`);
      const controlElementOffsetTop = controlElement.offsetTop;

      parent.scroll({
        top: controlElementOffsetTop,
        left: 0,
        behavior: 'smooth',
      });
    }, 100);
  }, [assessment]);

  const handleAutomationResponse = async (controls) => {
    const controlsToSave = controls.map((control) => ({
      ...control,
      answer: control.suggest,
    }));

    setAutomationResponseLoading(true);
    await dispatch(saveControlsWithTriggers(controlsToSave)).unwrap();
    setAutomationResponseModal(false);
    setAutomationResponseLoading(false);
  };

  noInternet({
    callback: (offline) => (offline ? setNoConnection(true) : setNoConnection(false)),
    milliseconds: NO_INTERNET_CHECK_INTERVAL,
    timeout: NO_INTERNET_CHECK_INTERVAL,
  });

  function handleScroll(event) {
    const scrollPosition = event.target.scrollTop;

    if (scrollPosition > 1 && !isScrolled) {
      setIsScrolled(true);
    } else if (scrollPosition < 1 && isScrolled) {
      setIsScrolled(false);
    }
  }

  return showFinalized ? (
    <Finalized />
  ) : (
    <div className="assessment">
      <div className="assessment__left-panel">
        <PageSubheader withBackArrow className="assessment__page_header">
          {title}
        </PageSubheader>
        {!showcaseAssessmentPreview && (
          <div className="assessment__details">
            {isMyVendorsTabSelected && (
              <>
                <DueDate due={due} />
                <Divider vertical />
              </>
            )}
            <VendorName name={customerName} id={customerId} />
          </div>
        )}
        {loading && !title && <Loader className="assessment__loader" />}
        <Status status={status} completion={totalScore?.completion} />
        <Categories showcaseAssessmentPreview={showcaseAssessmentPreview} />
        {showcaseAssessmentPreview ? <UploadedFilesPreview /> : <Files />}
      </div>
      <div className="assessment__right-panel">
        <div className="assessment__right-panel-inner" onScroll={handleScroll}>
          {noConnection && <NoInternetConnection />}

          <CategoryHeader
            ref={headerRef}
            handleAutomationResponse={handleAutomationResponse}
            handleGetAssessment={handleGetAssessment}
            isScrolled={isScrolled}
          />
          <Controls showcaseAssessmentPreview={showcaseAssessmentPreview} />
          <CloudMonitoringReviewPopup />
        </div>
        {errorsCount > 0 && <ErrorsNavigation />}
        <CategoryNavigation />
      </div>
      <Modal
        className="assessment__response-automation-modal"
        open={automationResponseModal}
        onOk={() => handleAutomationResponse(controlsWithSuggestion)}
        onCancel={() => setAutomationResponseModal(false)}
        okText={translate(messages.useAutomation)}
        cancelText={translate(generalMessages.cancel)}
        confirmLoading={automationResponseLoading}
        width={460}
        footer={undefined}
      >
        <img
          className="assessment__response-automation-modal-image"
          src={ResponseAutomation}
          alt={translate(messages.responseAutomationTitle)}
        />
        <br />
        <h3 className="assessment__response-automation-modal-title">
          {translate(messages.responseAutomationTitle)}
        </h3>
        {translate(messages.responseAutomationText, { number: controlsWithSuggestion?.length })}
      </Modal>
      <StrengthenAuth
        request={() =>
          dispatch(
            getAssessment({
              id: showcaseAssessmentPreview || params.assessmentId,
              preview: showcaseAssessmentPreview ? true : isPreview,
            }),
          )
        }
        deps={[params.assessmentId, showcaseAssessmentPreview]}
        cleanup={() => dispatch(clearAssessment())}
      />
    </div>
  );
};

Assessment.propTypes = {
  showcaseAssessmentPreview: PropTypes.string,
};

export default Assessment;
