import React from 'react';
import PropTypes from 'prop-types';
import Button from 'ui/Button';
import { useTheme } from 'hooks/theme';
import './index.scss';

const PageActionFooter = ({
  prevAction,
  prevLabel,
  nextLabel,
  nextAction,
  prevBeforeComponent,
  nextAfterComponent,
  isPrevDisabled,
  isNextDisabled,
  isNextLoading,
  isPrevLoading,
  dataTestPrev,
  dataTestNext,
}) => {
  const { colorTheme } = useTheme();

  return (
    <footer>
      {prevAction ? (
        <Button
          data-test={dataTestPrev}
          loading={isPrevLoading}
          disabled={isPrevDisabled}
          size="sm"
          className="footer__button"
          type="button"
          color="white"
          onClick={prevAction}
          name={prevLabel}
          beforeComponent={prevBeforeComponent || null}
        />
      ) : (
        <div />
      )}
      <Button
        data-test={dataTestNext}
        loading={isNextLoading}
        disabled={isNextDisabled}
        type="submit"
        size="sm"
        className="footer__button"
        color={colorTheme}
        onClick={nextAction}
        name={nextLabel}
        afterComponent={nextAfterComponent || null}
      />
    </footer>
  );
};

PageActionFooter.propTypes = {
  prevAction: PropTypes.func.isRequired,
  prevLabel: PropTypes.string.isRequired,
  prevBeforeComponent: PropTypes.node,
  isPrevLoading: PropTypes.bool,

  nextAction: PropTypes.func.isRequired,
  nextLabel: PropTypes.string.isRequired,
  nextAfterComponent: PropTypes.node,
  isNextLoading: PropTypes.bool,

  isPrevDisabled: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
  dataTestPrev: PropTypes.string.isRequired,
  dataTestNext: PropTypes.string.isRequired,
};

export default PageActionFooter;
