export const messages = {
  downloadDocForView: {
    key: 'FileBrowser.downloadDocForView',
    defaultMessage: 'Download the document to view it',
  },
};

export default {
  prefix: 'FileBrowser',
  messages,
};
