import React from 'react';
import PropTypes from 'prop-types';

const ChatIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M12 2C6.48615 2 2 6.48615 2 12C2 13.6377 2.43135 15.1674 3.13086 16.5322L2.04688 20.4141C1.7944 21.3158 2.68596 22.2063 3.58789 21.9541L7.47168 20.8701C8.83552 21.5687 10.3635 22 12 22C17.5139 22 22 17.5139 22 12C22 6.48615 17.5139 2 12 2ZM12 3.5C16.7031 3.5 20.5 7.29685 20.5 12C20.5 16.7031 16.7031 20.5 12 20.5C10.4985 20.5 9.09613 20.1091 7.87207 19.4268C7.69924 19.3303 7.4953 19.3061 7.30469 19.3594L3.61133 20.3906L4.64258 16.6992C4.69585 16.5086 4.67163 16.3047 4.5752 16.1318C3.89183 14.9067 3.5 13.5023 3.5 12C3.5 7.29685 7.29685 3.5 12 3.5Z"
      fill={color}
    />
  </svg>
);

ChatIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ChatIcon;
