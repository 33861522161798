import React from 'react';
import { Button, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { translate } from 'utils/index';
import { getOffset } from 'utils/html';
import Icon from 'ui/Icon';
import ROUTES from 'constants/routes';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { selectCategory } from 'pages/Assessment/reducers';

import { messages } from './messages';
import { selectFinding } from './selectors';
import './goToQuestion.scss';

const GoToQuestion = () => {
  const finding = useSelector(selectFinding);
  const appNavigate = useAppNavigate();
  const { assessmentId } = useParams();
  const dispatch = useDispatch();
  const isVendorSelected = useIsMyVendorsTabSelected();

  const goToQuestion = () => {
    dispatch(selectCategory(finding.control.categoryId));
    const path = isVendorSelected
      ? ROUTES.VENDOR_ASSESSMENT_QUESTIONNAIRE
      : ROUTES.CUSTOMER_ASSESSMENT_QUESTIONNAIRE;

    appNavigate(path, { assessmentId }, { replace: true });
    setTimeout(() => {
      const controlElement = document.getElementById(`control-${finding.control.id}`);
      const controlElementOffset = getOffset(controlElement);

      window.scroll({
        top: controlElementOffset.top - 70,
        left: 0,
        behavior: 'smooth',
      });
    });
  };

  return (
    <Tooltip title={translate(messages.goToQuestion)}>
      <Button
        className="assessment-finalized-finding-go-to-question"
        type="link"
        onClick={goToQuestion}
      >
        <Icon icon="symlink" />
      </Button>
    </Tooltip>
  );
};

export default GoToQuestion;
