import { createSlice } from '@reduxjs/toolkit';
import { getCategoryUnreadCount, getChat, sendMessage } from 'api/messenger';

const initialState = {
  threads: {},
  untreadThreadsCount: {},
};

const reducers = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setThreadAsRead: (state, { payload: { threadId } }) => {
      state.threads[threadId] = { ...state.threads[threadId], unread: false };
      state.untreadThreadsCount[threadId] = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChat.fulfilled, (state, { payload }) => {
        state.threads = {
          ...state.threads,
          [payload.thread_id]: {
            thread: payload.thread,
            unread: payload.unread,
            title: payload.thread_title,
          },
        };
      })
      .addCase(sendMessage.fulfilled, (state, { meta }) => {
        const { threadId, msg } = meta.arg;

        state.threads = {
          ...state.threads,
          [threadId]: {
            ...state.threads[threadId],
            thread: [
              ...state.threads[threadId].thread,
              { msg, timestampCreated: new Date().toString(), self: true },
            ],
          },
        };
      })
      .addCase(getCategoryUnreadCount.fulfilled, (state, { payload }) => {
        state.untreadThreadsCount = payload?.untreadThreadsCount || {};
      });
  },
});

export const { setThreadAsRead } = reducers.actions;

export default reducers;
