import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  GetProfileCustomers,
  AddProfileCustomer,
  SetProfileCustomer,
  GetProfileCustomer,
} from 'api/endpoints';
import { parseFileLinksFromAssessment } from 'utils/assessment';
import { FILTERABLE_KEYS } from 'components/Filters/lib';

const parseCustomerPayload = (args, isEdit) => {
  const prefix = `${isEdit ? 'set' : 'add'}-customer-customer`;
  const payload = {
    [`${prefix}-email`]: args.email,
    [`${prefix}-phone`]: args.phone,
    [`${prefix}-fullname`]: args.companyContact,
    [`${prefix}-corporateName`]: args.corporateName,
    [`${prefix}-corporateIndustriy`]: -1,
    [`${prefix}-corporateGeography`]: -1,
    [`${prefix}-corporateSize`]: -1,
    [isEdit ? 'setCustomerGroups' : 'addCustomerGroups']: args.groups || -1,
    [`${prefix}-color`]: -1,
    [`${prefix}-assessments`]: args.assessments,
    [`${prefix}-enabled`]: true,
    [`${prefix}-assessmentsGroups`]: args.assessmentsGroups,
    [`${prefix}-assessments-period`]: args['assessments-period'] || 0,
    [`${prefix}-apps`]: args.apps,
    [`${prefix}-_assessmentJson`]: JSON.stringify(args.assessmentJson),
  };

  if (!isEdit) {
    payload['file-links'] = JSON.stringify(parseFileLinksFromAssessment(args.assessmentJson) || {});
  }

  return payload;
};

export const addProfileCustomerRequest = async (args) => {
  return axios.post(AddProfileCustomer.url, parseCustomerPayload(args));
};

export const addProfileCustomer = createAsyncThunk('AddProfileCustomer', addProfileCustomerRequest);

const GetProfileCustomersRequest = async (args, { getState }) => {
  const { customers, filters } = getState();

  const payload = {
    'get-customers-offset': customers.pagination.current,
    'get-customers-count': customers.pagination.pageSize,
    'get-customers-search': customers.search,
    descending: customers.sorter.descending,
    column: customers.sorter.column || '',
    filters: JSON.stringify(
      filters.allFilters[FILTERABLE_KEYS.CUSTOMERS_LIST_FILTERS].filtersToApi || {},
    ),
  };

  return axios.post(GetProfileCustomers.url, payload);
};

export const getProfileCustomers = createAsyncThunk(
  'GetProfileCustomers',
  GetProfileCustomersRequest,
);

const GetProfileCustomerRequest = async (token) => {
  return axios.post(GetProfileCustomer.url, {
    'get-customer-customer-token': token,
  });
};

export const getProfileCustomer = createAsyncThunk('getProfileCustomer', GetProfileCustomerRequest);

export const setProfileCustomerRequest = async (args) => {
  return axios.post(SetProfileCustomer.url, {
    ...parseCustomerPayload(args, true),
    'set-customer-customer-token': args.customerId,
  });
};

export const setProfileCustomer = createAsyncThunk('setProfileCustomer', setProfileCustomerRequest);
