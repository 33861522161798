export const messages = {
  explainButton: {
    key: 'Upstream AI.explainButton',
    defaultMessage: 'Explain',
  },
  explainButtonInner: {
    key: 'Upstream AI.explainButtonInner',
    defaultMessage: 'Explain Control',
  },
  exampleButton: {
    key: 'Upstream AI.exampleButton',
    defaultMessage: 'Example',
  },
  exampleButtonInner: {
    key: 'Upstream AI.exampleButtonInner',
    defaultMessage: 'Example response',
  },
  popoverNote1: {
    key: 'Upstream AI.popoverNote1',
    defaultMessage: 'NOTE',
  },
  popoverNote2: {
    key: 'Upstream AI.popoverNote2',
    defaultMessage: 'This is an example of a response for your reference',
  },
  improveButton: {
    key: 'Upstream AI.improveButton',
    defaultMessage: 'Improve',
  },
  improveButtonInner: {
    key: 'Upstream AI.improveButtonInner',
    defaultMessage: 'Improve response',
  },
  thirdPartyConsentTitle: {
    key: 'Upstream AI.thirdPartyConsentTitle',
    defaultMessage: '3rd party AI consent',
  },
  thirdPartyConsentButtonAgree: {
    key: 'Upstream AI.thirdPartyConsentButtonAgree',
    defaultMessage: 'I agree',
  },
  thirdPartyConsentButtonDisagree: {
    key: 'Upstream AI.thirdPartyConsentButtonDisagree',
    defaultMessage: 'no thanks',
  },
  auditButton: {
    key: 'Upstream AI.auditButton',
    defaultMessage: 'Audit',
  },
  auditButtonInner: {
    key: 'Upstream AI.auditButtonInner',
    defaultMessage: 'Audit response',
  },
  findings: {
    key: 'Upstream AI.findings',
    defaultMessage: 'Findings',
  },
  noFindings: {
    key: 'Upstream AI.noFindings',
    defaultMessage: 'No findings',
  },
  impact: {
    key: 'Upstream AI.impact',
    defaultMessage: 'Impact',
  },
  probability: {
    key: 'Upstream AI.probability',
    defaultMessage: 'Probability',
  },
  addFinding: {
    key: 'Upstream AI.addFinding',
    defaultMessage: 'Add finding',
  },
  addNewFinding: {
    key: 'Upstream AI.addNewFinding',
    defaultMessage: 'Add new finding',
  },
  confirmFindingSuccess: {
    key: 'Upstream AI.confirmFindingSuccess',
    defaultMessage: 'Finding add successfully',
  },
  confirmFindingError: {
    key: 'Upstream AI.confirmFindingError',
    defaultMessage: 'Failed to add the finding',
  },
  feedbackText1: {
    key: 'Upstream AI.feedbackText1',
    defaultMessage: 'Help us improve - was this',
  },
  feedbackText2: {
    key: 'Upstream AI.feedbackText2',
    defaultMessage: 'helpful?',
  },
  aiPowered: {
    key: 'Upstream AI.aiPowered',
    defaultMessage: 'AI-Powered',
  },
  copy: {
    key: 'Upstream AI.copy',
    defaultMessage: 'Copy',
  },
  thirdPartyConsentContent: {
    key: 'Upstream AI.thirdPartyConsentContent',
    defaultMessage:
      'To improve your response, we\'ve partnered with trusted third-party language models. Your response will be securely sent to them for analysis and suggestions. Rest assured, your data is handled with utmost security and privacy. By clicking "I agree", you agree to securely transmit your response for improvement.',
  },
};

export default {
  prefix: 'Upstream AI',
  messages,
};
