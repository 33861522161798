import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ConfigProvider, Switch, Tooltip } from 'antd';

import Icon from 'ui/Icon';
import Spin from 'ui/Spin';
import Checkbox from 'ui/Checkbox';
import Chat from 'components/Chat';
import ApproveAnswer from 'ui/ApproveAnswer';
import AnswerRepository from 'components/AnswerRepository';
import { getAnswerType } from 'utils/controls';
import { sanitizer } from 'utils/sanitizer';
import { AssessmentStatus } from 'constants/assessmentStatus';
import { selectApps, selectCurrentOrganization } from 'pages/Profile/selectors';
import { messages } from './messages';
import { translate } from 'utils/index';

import Findings from './findings';
import ChatButton from './chatButton';
import Note from './note';
import ControlAnswerEdit from './controlAnswerEdit';
import SecondaryButton from 'ui/SecondaryButton';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import UpstreamAiButton from 'components/UpstreamAI/upstreamAiButton';
import { buttonTypes } from 'components/UpstreamAI/lib';
import { setCollaborateQuestions } from './reducers';
import { ControlType } from 'constants/controlType';
import { saveNote } from 'api/recommendation';
import ShareButton from 'pages/Assessment/shareButton';
import { Apps } from 'constants/apps';
import { NoteIcon } from 'components/Icons';

import {
  selectAnswerTypes,
  selectAssessment,
  selectAssessmentErrors,
  selectCategory,
  selectCategoryKey,
  selectIsAssessmentDisabled,
} from './selectors';
import { calculateControlKey, hasSuggestionNearControl, hasSuggestionNearTitle } from './lib';
import { chatSources } from 'components/Chat/lib';
import { mixpanelInternalNoteUpdated } from 'utils/mixpanel';
import { API_STATUS } from 'constants/api';

import './control.scss';

const Control = ({
  control,
  disabled,
  onChange,
  onAnswerChange,
  index,
  showcaseAssessmentPreview,
  customAnswerTypes,
  customPayload,
  hideChat,
  getNextEnabledControlApps,
  notShared,
}) => {
  const [searchParams] = useSearchParams();
  const [showChat, setShowChat] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [showErrorView, setShowErrorView] = useState(false);
  const dispatch = useDispatch();
  const category = useSelector(selectCategory);
  const answerTypes = useSelector(selectAnswerTypes);
  const key = useSelector(selectCategoryKey);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const {
    upstream,
    finalized,
    assessor,
    rtl,
    status: assessmentStatus,
    id: assessmentId,
    title: assessmentName,
    customerId,
    customerName,
  } = useSelector(selectAssessment);

  const { errorsList, selectedErrorId } = useSelector(selectAssessmentErrors);
  const { collaborate, collaborateQuestions, showCasePreview } = useSelector(
    (state) => state.assessment,
  );
  const isAssessmentDisabled = useSelector(selectIsAssessmentDisabled) || showCasePreview;
  const { id: organizationId } = useSelector(selectCurrentOrganization);
  const allApps = useSelector(selectApps);

  const {
    apps = [],
    categoryId,
    id,
    title,
    description,
    descriptionRight,
    answer_type_idx: answerTypeIdx,
    answer,
    pending,
    status,
    suggest,
    control_answer_type: controlAnswerType,
    shareResponse,
    note,
  } = control;

  const isCloudMonitoringCategory = [Apps.aws, Apps.azure].includes(category.app);
  const isPresubmit = isCloudMonitoringCategory && !finalized;

  const answerType = getAnswerType(answerTypeIdx, answerTypes || customAnswerTypes);
  const controlIdParam = searchParams.get('controlId');

  useEffect(() => {
    if (controlIdParam === id) {
      setShowChat(true);
    }
  }, [controlIdParam]);

  useEffect(() => {
    setShowErrorView(errorsList[categoryId] ? !!errorsList[categoryId][id] : false);
  }, [errorsList]);

  const controlApps = useMemo(() => {
    if (apps?.includes(Apps.aws) && apps?.includes(Apps.azure)) {
      if (Object.keys(control.triggers)?.length && apps.length === 2) {
        return [Apps.cloudMonitoring];
      }
      return getNextEnabledControlApps(index);
    }
    return apps;
  }, [apps]);

  if (answerType.type === undefined) {
    return null;
  }

  const onSelectCollaborateQuestion = (currentId) => {
    // eslint-disable-next-line no-shadow
    if (collaborateQuestions.some((id) => id === currentId)) {
      // eslint-disable-next-line no-shadow
      const questions = collaborateQuestions.filter((id) => id !== currentId);
      dispatch(setCollaborateQuestions(questions));
    } else {
      dispatch(setCollaborateQuestions([...collaborateQuestions, currentId]));
    }
  };

  const onUseSuggestion = () => {
    onAnswerChange(suggest, id);
  };

  const onNoteSave = async (comment) => {
    const payload = {
      note: {
        id,
        comment,
      },
    };
    const {
      payload: { status },
    } = await dispatch(saveNote(payload));
    if (status === API_STATUS.SUCCESS) {
      mixpanelInternalNoteUpdated({
        relation: customerId,
        source: 'control',
        type: note?.note && comment ? 'updated' : comment ? 'created' : 'deleted',
        description: comment,
      });
    }
  };

  const collaborateControlSelected = collaborateQuestions.includes(id);

  const controlComponentClassName = classNames('assessment-controls-control__control-type', {
    'assessment-controls-control__control-type--with-suggestion': suggest && !answer,
    'assessment-controls-control__control-type--rtl': rtl,
  });

  const disableSuggestion =
    suggest &&
    (Array.isArray(suggest)
      ? JSON.stringify(suggest) === JSON.stringify(answer)
      : suggest === answer);

  const dataForMixpanel = {
    assessmentName,
    relation: customerId || organizationId,
    assigneeOrganizationName: customerName,
    originalControl: control.title,
  };

  const renderAnswer = () => {
    if (notShared) {
      return null;
    }

    return pending ? (
      <Spin className="assessment-controls-control__loader" size={16} />
    ) : hasSuggestionNearControl(answerType.type) && suggest ? (
      <div className="assessment-controls-control__control-type-wrapper">
        <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
          <ControlAnswerEdit
            control={control}
            className={controlComponentClassName}
            disabled={disabled}
            onAnswerChange={onAnswerChange}
            categoryId={categoryId}
          />
          <Button
            type="link"
            className="assessment-controls-control__suggestion-button"
            onClick={onUseSuggestion}
            disabled={disableSuggestion || disabled}
          >
            <Icon icon="suggestion" />
          </Button>
        </ConfigProvider>
      </div>
    ) : (
      <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
        <ControlAnswerEdit
          control={control}
          className={controlComponentClassName}
          disabled={disabled}
          onAnswerChange={onAnswerChange}
          categoryId={categoryId}
          customAnswerTypes={customAnswerTypes}
          customPayload={customPayload}
        />
      </ConfigProvider>
    );
  };

  return (
    <div id={`control-${id}`} className="assessment-controls-control">
      <div
        className={classNames('assessment-controls-control__key', {
          'assessment-controls-control__key--collaborate': collaborate,
          'assessment-controls-control__key--error': showErrorView && id === selectedErrorId,
        })}
      >
        <span>
          {collaborate ? (
            <Checkbox
              checked={collaborateControlSelected}
              className="assessment-controls-control__collaborate-checkbox"
              onChange={() => onSelectCollaborateQuestion(id)}
              name={`collaborateQuestion-${id}`}
              mode="checkbox"
            >
              {calculateControlKey(key, index)}
            </Checkbox>
          ) : (
            calculateControlKey(key, index)
          )}
        </span>
      </div>

      <div className="assessment-controls-control__wrapper">
        {controlApps?.length > 0 && (
          <div className="assessment-controls-control__app-icons">
            {controlApps.map((app) => {
              const currentApp = allApps?.find(({ id: appId }) => appId === app) || {};
              return (
                <Tooltip key={currentApp?.id} title={currentApp?.label}>
                  <img
                    className="assessment-controls-control__app-icon"
                    key={app?.id}
                    src={currentApp?.icon}
                    alt={currentApp?.name}
                  />
                </Tooltip>
              );
            })}
          </div>
        )}
        {description && description !== 'None' && (
          <div
            className={classNames('assessment-controls-control__description', {
              'assessment-controls-control__description--rtl': rtl,
            })}
          >
            <Icon className="assessment-controls-control__description-ico" icon="info" />
            <span dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
          </div>
        )}
        <div
          key={id}
          className={classNames('assessment-controls-control__title', {
            'assessment-controls-control__title--rtl': rtl,
          })}
        >
          <div className="assessment-controls-control__title-content">
            <pre
              className="assessment-controls-control__title-pre"
              dangerouslySetInnerHTML={{ __html: sanitizer(title) }}
              data-test="assessment-control-question"
            />
            {!isMyVendorsTabSelected && !finalized && (
              <UpstreamAiButton
                controlId={id}
                assessmentId={assessmentId}
                controlAnswerType={controlAnswerType}
                buttonType={buttonTypes.explain}
                dataForMixpanel={dataForMixpanel}
              />
            )}
          </div>

          {hasSuggestionNearTitle(answerType.type) && suggest && (
            <Button
              type="link"
              className="assessment-controls-control__suggestion-button assessment-controls-control__suggestion-button--near-title"
              onClick={onUseSuggestion}
              disabled={disableSuggestion}
            >
              <Icon icon="suggestion" />
            </Button>
          )}
        </div>
        {descriptionRight && descriptionRight !== 'None' && (
          <div
            className={classNames('assessment-controls-control__description-bottom', {
              'assessment-controls-control__description-bottom--rtl': rtl,
            })}
          >
            <Icon className="assessment-controls-control__description-ico" icon="info" />
            <span dangerouslySetInnerHTML={{ __html: sanitizer(descriptionRight) }} />
          </div>
        )}
        {/* eslint-disable-next-line no-nested-ternary */}
        <div className="assessment-controls-control__control-wrapper">
          {!!answer &&
            (controlAnswerType === ControlType.Input ||
              controlAnswerType === ControlType.TextArea) && (
              <UpstreamAiButton
                controlId={id}
                assessmentId={assessmentId}
                controlAnswerType={controlAnswerType}
                control={control}
                buttonType={
                  !isMyVendorsTabSelected && !finalized
                    ? buttonTypes.improve
                    : finalized && isMyVendorsTabSelected
                    ? buttonTypes.audit
                    : null
                }
                dataForMixpanel={dataForMixpanel}
              />
            )}
          {!isMyVendorsTabSelected &&
            !finalized &&
            !answer &&
            (controlAnswerType === ControlType.Input ||
              controlAnswerType === ControlType.TextArea) && (
              <UpstreamAiButton
                controlId={id}
                assessmentId={assessmentId}
                controlAnswerType={controlAnswerType}
                buttonType={buttonTypes.example}
                dataForMixpanel={dataForMixpanel}
              />
            )}
          {renderAnswer()}
        </div>

        {isMyVendorsTabSelected && (showNote || note?.note) && (
          <Note
            description={note?.note}
            date={note?.timestampEdited}
            editMode={showNote}
            setEditMode={setShowNote}
            onSave={onNoteSave}
            data-test={`control-note-${index}`}
          />
        )}

        {showErrorView && errorsList[categoryId] && (
          <div className="assessment-controls-control__error-message">
            <Icon icon="info" color="red" className={`assessment-controls-control__error-icon`} />
            {errorsList[categoryId][id]}
          </div>
        )}
        <div className="assessment-controls-control__actions">
          {!hideChat && (
            <ChatButton
              data-test={`control-chat-button-${index}`}
              toggleChat={() => setShowChat((state) => !state)}
              threadId={id}
            />
          )}
          {isMyVendorsTabSelected && (
            <SecondaryButton
              className="assessment-controls-control__note-button"
              onClick={() => setShowNote((state) => !state)}
              iconComponent={<NoteIcon />}
              tooltip={translate(messages.note)}
              data-test={`control-note-button-${index}`}
            />
          )}
          {!showcaseAssessmentPreview && !isMyVendorsTabSelected && !isPresubmit && (
            <ShareButton
              data-test={`control-share-button-${index}`}
              controlId={id}
              emailsList={control['collab_emails'] || []}
            />
          )}
          {showcaseAssessmentPreview ||
          (assessor && finalized) ||
          assessmentStatus > AssessmentStatus.reviewed ? (
            <Findings
              data-test={`control-findings-button-${index}`}
              control={control}
              showcaseAssessmentPreview={showcaseAssessmentPreview}
            />
          ) : null}
          {!isPresubmit && (
            <AnswerRepository control={control} disabled={disabled} onSelect={onAnswerChange} />
          )}
          {((!assessor && assessmentStatus === AssessmentStatus.disabled) ||
            (assessor && finalized && !upstream)) &&
            !notShared && (
              <ApproveAnswer
                className="assessment-controls-control__action-approve"
                onApprove={() => onChange('status', 1, id, categoryId)}
                onDisapprove={() => onChange('status', 2, id, categoryId)}
                onClear={() => onChange('status', 0, id, categoryId)}
                disabled={disabled || !assessor || isAssessmentDisabled}
                value={status}
              />
            )}
          {isCloudMonitoringCategory && !isMyVendorsTabSelected && (
            <div className="assessment-controls-control__share-response-wrapper">
              <span className="assessment-controls-control__share-response-label">
                {translate(messages.shareResponse)}
              </span>
              <Switch
                className="assessment-controls-control__share-response-switch"
                size="small"
                checked={shareResponse}
                onChange={(value) => onChange('shareResponse', value, id, categoryId)}
                disabled={finalized}
              />
            </div>
          )}
        </div>
        {showChat && (
          <Chat
            assessmentId={assessmentId}
            className="assessment-controls-control__chat"
            threadId={id}
            controlId={control.id}
            categoryId={category.id}
            disabled={disabled}
            threadTitle={title}
            close={() => setShowChat(false)}
            source={chatSources.control}
            relation={customerId || organizationId}
            newMessagePlaceholder={
              isMyVendorsTabSelected
                ? translate(messages.chatInputPlaceholderVensors)
                : translate(messages.chatInputPlaceholderCustomers)
            }
          />
        )}
      </div>
    </div>
  );
};

Control.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
  index: PropTypes.number,
  onChange: PropTypes.func,
  onAnswerChange: PropTypes.func,
  showcaseAssessmentPreview: PropTypes.bool,
  customAnswerTypes: PropTypes.object,
  customPayload: PropTypes.object,
  hideChat: PropTypes.bool,
  getNextEnabledControlApps: PropTypes.func,
  notShared: PropTypes.bool,
};

export default Control;
