import React from 'react';
import PropTypes from 'prop-types';
import Select from 'ui/Select';
import { translate } from 'utils/index';
import { ControlType } from 'constants/controlType';
import { messages } from 'pages/Editor/messages';
import './answerTypeSelector.scss';

const AnswerTypeSelector = ({ onChange, value }) => {
  const options = [
    {
      label: translate(messages.answerTypeInput),
      value: ControlType.Input,
      icon: 'single-line-text',
    },
    {
      label: translate(messages.answerTypeTextArea),
      value: ControlType.TextArea,
      icon: 'multi-line-text',
    },
    {
      label: translate(messages.answerTypeRadioButton),
      value: ControlType.RadioGroup,
      icon: 'radio-button',
    },
    {
      label: translate(messages.answerTypeCheckbox),
      value: ControlType.Checkbox,
      icon: 'multi-select',
    },
    {
      label: translate(messages.answerTypeDropDown),
      value: ControlType.DropDown,
      icon: 'drop-down',
    },
    {
      label: translate(messages.answerTypeMultiSelect),
      value: ControlType.MultiSelect,
      icon: 'drop-down',
    },
    {
      label: translate(messages.answerTypeFileUpload),
      value: ControlType.FileUpload,
      icon: 'upload2',
    },
    {
      label: translate(messages.answerTypeDatePicker),
      value: ControlType.DatePicker,
      icon: 'date-outline',
    },
  ];

  return (
    <Select
      data-test={`editor-answer-creator-answer-type-selector-${value}`}
      className="editor-answer-creator-answer-type-selector"
      dropdownClassName="editor-answer-creator-answer-type-selector__dropdown"
      color="gray"
      placeholder={translate(messages.chooseAnswerType)}
      options={options}
      onChange={onChange}
      size="large"
      value={value}
      listHeight={300}
    />
  );
};

AnswerTypeSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
};

export default AnswerTypeSelector;
