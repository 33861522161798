import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// COMPONENTS
import Icon from 'ui/Icon';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'components/UpstreamAI/messages';

// STYLES
import './feedback.scss';

function Feedback({ selectedButton, setMixpanelFeedback }) {
  const mainClass = classNames('upstream-ai-feedback', {
    [`upstream-ai-feedback--${selectedButton}`]: selectedButton,
  });
  const [vote, setVote] = useState(0); // 0: no vote, 1: up, -1: down

  const leftIconClass = 'upstream-ai-feedback__left-icon';
  const rightIconClass = 'upstream-ai-feedback__right-icon';

  const handleVoteUp = () => {
    setVote(1);
    setMixpanelFeedback('good');
  };
  const handleVoteDown = () => {
    setVote(-1);
    setMixpanelFeedback('bad');
  };

  return (
    <div data-test="ai-feedback" className={mainClass}>
      <div>
        <span style={{ color: '#828282' }}>{translate(messages.feedbackText1)}</span>{' '}
        <span style={{ fontWeight: '500' }}>{translate(messages.feedbackText2)}</span>
      </div>
      <Icon
        icon={vote === 1 ? 'vote-up-filled' : 'suggestion'}
        className={`${leftIconClass} ${vote === 1 && `${leftIconClass}--filled`}`}
        onClick={handleVoteUp}
        data-test="ai-feedback--vote-up"
      />
      <Icon
        icon={vote === -1 ? 'vote-down-filled' : 'vote-down'}
        className={`${rightIconClass} ${vote === -1 && `${rightIconClass}--filled`}`}
        onClick={handleVoteDown}
        data-test="ai-feedback--vote-down"
      />
    </div>
  );
}

Feedback.propTypes = {
  selectedButton: PropTypes.string,
  setMixpanelFeedback: PropTypes.func,
};

export default Feedback;
