import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import autosize from 'autosize';
import Spin from 'ui/Spin';
import './index.scss';

const TextArea = ({
  autoFocus,
  className,
  disabled,
  error,
  label,
  name,
  loading,
  onBlur,
  onChange,
  placeholder,
  style,
  value,
  inputClassName,
  autoResize = false,
  maxLength = null,
  'data-test': dataTest,
}) => {
  const textAreaClassNames = classNames('ui-textarea', className);

  const textAreaControlClassNames = classNames('ui-textarea-control', inputClassName, {
    'ui-textarea-control--error': error,
  });
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (autoResize && textAreaRef.current) {
      autosize(textAreaRef.current);
    }
  }, [autoResize]);

  const handleOnChange = (event) => {
    // handling onChange for editable content in assessment editor
    if (className === 'editable-content__editable') {
      onChange(event.target.value);
      return;
    }

    onChange(event);
  };

  return (
    <div className={textAreaClassNames}>
      <div className="ui-textarea__label">{label}</div>
      <div className="ui-textarea__wrapper">
        <textarea
          ref={textAreaRef}
          autoFocus={autoFocus}
          className={textAreaControlClassNames}
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          rows={autoResize ? 1 : undefined}
          onChange={handleOnChange}
          placeholder={placeholder}
          style={style}
          value={value}
          maxLength={maxLength}
          data-test={dataTest}
        />
        {loading && <Spin className="ui-textarea__loader" size={16} />}
      </div>
      <div className="ui-textarea__error">{error}</div>
    </div>
  );
};

TextArea.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string.isRequired,
  autoResize: PropTypes.bool,
  maxLength: PropTypes.number,
  'data-test': PropTypes.string.isRequired,
};

export default TextArea;
