import React from 'react';
import PropTypes from 'prop-types';

import PageActionFooter from 'components/PageActionFooter';

import './index.scss';

const NewVendorFooter = ({
  prevLabel,
  prevAction,
  nextAction,
  nextLabel,
  isNextDisabled,
  prevBeforeComponent,
  nextAfterComponent,
  isNextLoading,
  dataTestPrev = 'new-vendor-footer-prev-cancel-button',
  dataTestNext = 'new-vendor-footer-next-save-button',
}) => {
  return (
    <div className="new-vendor-footer">
      <PageActionFooter
        prevLabel={prevLabel}
        nextLabel={nextLabel}
        nextAction={nextAction}
        prevAction={prevAction}
        isNextDisabled={isNextDisabled}
        prevBeforeComponent={prevBeforeComponent}
        nextAfterComponent={nextAfterComponent}
        isNextLoading={isNextLoading}
        dataTestPrev={dataTestPrev}
        dataTestNext={dataTestNext}
      />
    </div>
  );
};

NewVendorFooter.propTypes = {
  prevLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  nextAction: PropTypes.func.isRequired,
  prevAction: PropTypes.func,
  isNextDisabled: PropTypes.bool,
  prevBeforeComponent: PropTypes.node,
  nextAfterComponent: PropTypes.node,
  isNextLoading: PropTypes.bool,
  dataTestPrev: PropTypes.string.isRequired,
  dataTestNext: PropTypes.string.isRequired,
};

export default NewVendorFooter;
