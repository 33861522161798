import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';
import FinalizeSvg from 'assets/svgs/finalize.svg';

import { messages } from 'pages/Assessment/messages';
import './loading.scss';

const FinalizeLoading = ({ onNext }) => {
  const [step, setStep] = useState(0);
  const steps = [
    messages.assessmentLockdown,
    messages.calculatingScore,
    messages.calculatingGaps,
    messages.detectingAdvice,
    messages.determiningAssessmentResult,
    messages.submittingAssessment,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((currentStep) => currentStep + 1);
      if (step === steps.length - 1) {
        clearInterval(interval);
        onNext();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [step]);

  return (
    <div className="assessment-finalize-loading">
      <img className="assessment-finalize-loading__image" src={FinalizeSvg} alt="Index" />
      <div className="assessment-finalize-loading__text">
        {translate(messages.pleaseWaitWhileFindingsCalculatesYourPosture)}
      </div>
      <div>{translate(messages.stepOneOf, { step: step + 1, countSteps: steps.length })}</div>

      {translate(steps[step])}
    </div>
  );
};

FinalizeLoading.propTypes = {
  onNext: PropTypes.func,
};

export default FinalizeLoading;
