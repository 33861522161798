import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import {
  getCountries,
  customPhoneInputStyles,
  getCountryByPrefix,
} from 'components/PhoneInput/lib';
import { translate } from 'utils/index';
import { messages } from './messages';
import './prefixSelect.scss';
// eslint-disable-next-line
import '/node_modules/flag-icons/css/flag-icons.min.css';

const PrefixSelect = ({ onChange, selectedValue, error }) => {
  const onOverrideChange = (newValue) => {
    onChange(newValue);
  };

  const filterOptions = (candidate, input) => {
    if (input) {
      return (
        (candidate.value || '').toString().includes(input.replace('+', '')) ||
        (candidate.data.code || '').toLowerCase().includes(input.toLowerCase())
      );
    }
    return true;
  };

  const renderOption = ({ data, ...rest }) => {
    const { code, value } = data;
    return (
      <div
        className="phone-prefix-select-item"
        onClick={() => {
          rest.selectOption(value);
          onOverrideChange(value);
        }}
      >
        <span className={`fi fi-${code.toLowerCase()}`}></span>
        <span>{code}</span>
        <span>(+{value})</span>
      </div>
    );
  };

  const countries = getCountries();
  return (
    <Select
      value={getCountryByPrefix(selectedValue)}
      defaultValue={getCountryByPrefix(selectedValue)}
      blurInputOnSelect
      styles={customPhoneInputStyles(error)}
      placeholder={translate(messages.prefixPlaceholder)}
      options={countries}
      filterOption={filterOptions}
      components={{
        IndicatorSeparator: null,
        Option: renderOption,
      }}
    />
  );
};

PrefixSelect.defaultProps = {
  onChange: () => {},
};

PrefixSelect.propTypes = {
  onChange: PropTypes.func,
  selectedValue: PropTypes.number,
  error: PropTypes.string,
};

export default PrefixSelect;
