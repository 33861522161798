import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllAppControls,
  selectAnswerTypes,
  selectSubject,
  selectSubjectKey,
} from 'pages/Editor/selectors';
import { calculateControlKey } from 'pages/Assessment/lib';
import { findControlIndex, getAnswerType } from 'utils/controls';
import { selectApps } from 'pages/Profile/selectors';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import { translate } from 'utils/index';
import { removeAppTrigger } from 'api/editor';
import { messages } from '../messages';
import Question from './question';
import Collapse from 'ui/Collapse';
import { Apps } from 'constants/apps';
import FindingsCloudMonitoringIcon from 'assets/svgs/findings-cloud-monitoring-icon.svg';
import OtherTrigger from 'pages/Editor/questions/otherTrigger';
import { getOffset } from 'utils/html';
import { TrashIcon } from 'components/Icons';
import { useRole } from 'hooks/useRole';
import './appQuestions.scss';

const cloudMonitoringMainControl = '60b5dd54-998b-496d-a28f-72a782e29258';

const AppQuestions = ({ control }) => {
  const [loading, setLoading] = useState(false);
  const [appToRemove, setAppToRemove] = useState(null);
  const dispatch = useDispatch();
  const subject = useSelector(selectSubject);
  const allAppControls = useSelector((state) => selectAllAppControls(state, control.apps));
  const subjectKey = useSelector(selectSubjectKey);
  const apps = useSelector(selectApps);
  const appsJoined = control.apps?.join('-');
  const [activeCollapsibleKey, setActiveCollapsibleKey] = useState([appsJoined]);
  const isCloudMonitoringControl =
    control.apps?.length === 2 &&
    control.apps?.includes(Apps.aws) &&
    control.apps?.includes(Apps.azure);
  const { isRestrictedBoSoViewerRole } = useRole();

  const answerTypes = useSelector(selectAnswerTypes);
  const [showAddQuestion, setShowAddQuestion] = useState();
  const [showTriggers, setShowTriggers] = useState((control.triggers[3] || []).length > 0);
  const answerType = getAnswerType(control.answer_type_idx, answerTypes);

  const onRemove = async () => {
    setLoading(true);

    await Promise.all(
      appToRemove.map(async (appId) => {
        await dispatch(removeAppTrigger({ appId, categoryId: subject.id })).unwrap();
      }),
    );

    setAppToRemove(null);
    setLoading(false);
  };

  return (
    <>
      <div className="editor-app-questions">
        <div className="editor-app-questions__apps-icons">
          {isCloudMonitoringControl ? (
            <span>
              <img
                className="editor-app-questions__apps-icon"
                src={FindingsCloudMonitoringIcon}
                alt={'Findings Cloud Monitoring'}
              />
            </span>
          ) : (
            control.apps?.map((app) => {
              const currentApp = apps?.find(({ id }) => id === app) || {};
              return (
                <img
                  className="editor-app-questions__apps-icon"
                  key={app.id}
                  src={currentApp?.icon}
                  alt={currentApp?.name}
                />
              );
            })
          )}
        </div>

        <div className="editor-app-questions__apps-wrapper">
          <div className="editor-app-questions__apps">
            {isCloudMonitoringControl ? (
              <span>Findings Cloud Monitoring</span>
            ) : (
              control.apps?.map((app, index) => {
                const currentApp = apps.find((item) => item.id === app) || {};
                const isLast = index === control.apps.length - 1;
                return (
                  <span key={currentApp.id}>
                    {currentApp.label}
                    {isLast ? '' : ', '}
                  </span>
                );
              })
            )}
          </div>
          <div className="editor-app-questions__controls-wrapper">
            <div className="editor-app-questions__controls-label">controls:</div>
            <div className="editor-app-questions__controls">
              {allAppControls.map((control, index) => {
                const controlIndex = findControlIndex(subject.controls, control.id);
                const controlKey = calculateControlKey(subjectKey, controlIndex);
                const isLast = index === allAppControls.length - 1;

                const popoverContent = (
                  <div>
                    <span className="editor-app-questions__control-popover-suffix">
                      {controlKey}
                    </span>
                    {control.title}
                  </div>
                );

                return (
                  <Popover
                    key={control.id}
                    className="editor-app-questions__control-popover"
                    content={popoverContent}
                  >
                    <span className="editor-app-questions__control">
                      {controlKey}
                      {isLast ? '' : ', '}
                    </span>
                  </Popover>
                );
              })}
            </div>
          </div>
        </div>
        <Button
          className="editor-app-questions__remove-button"
          type="link"
          onClick={(event) => {
            setAppToRemove(control.apps);
          }}
          disabled={isRestrictedBoSoViewerRole}
        >
          <TrashIcon />
        </Button>
        <RemoveConfirmationModal
          headerText={translate(messages.removeAppHeaderText)}
          confirmLoading={loading}
          onOk={onRemove}
          onCancel={() => setAppToRemove(null)}
          text={translate(messages.removeAppText)}
          open={!!appToRemove}
        />
      </div>
      <Collapse
        accordion={false}
        defaultActiveKey={[appsJoined]}
        ghost={true}
        collapsible={true}
        className="editor-questions__question-box__collapse"
        onChange={(key) => setActiveCollapsibleKey(key)}
        activeKey={activeCollapsibleKey}
        panels={[
          {
            id: appsJoined,
            header: (
              <p>
                {translate(
                  activeCollapsibleKey?.length
                    ? messages.appsQuestionsCollapseHeaderTextHide
                    : messages.appsQuestionsCollapseHeaderTextShow,
                )}
              </p>
            ),
            children: (
              <>
                {allAppControls.map((control, index) => {
                  if (control.base_id === cloudMonitoringMainControl) {
                    return (
                      <Question
                        key={control.id}
                        control={control}
                        disabled={true}
                        index={control.counter}
                        withOtherTrigger
                        addQuestion={(value) => {
                          setShowTriggers(true);
                          setShowAddQuestion(value);

                          setTimeout(() => {
                            const otherTriggerElement =
                              document.querySelector('.editor-other-trigger');

                            const otherTriggerElementOffset = getOffset(otherTriggerElement);

                            window.scroll({
                              top: otherTriggerElementOffset.top - 70,
                              left: 0,
                              behavior: 'smooth',
                            });
                          }, 200);
                        }}
                      />
                    );
                  }
                  return (
                    <Question
                      key={control.id}
                      control={control}
                      disabled={true}
                      index={control.counter}
                    />
                  );
                })}
              </>
            ),
          },
        ]}
      />
      {showTriggers && (
        <OtherTrigger
          answerType={answerType}
          control={control}
          onClose={() => setShowTriggers(false)}
          showAddQuestion={showAddQuestion}
          setShowAddQuestion={setShowAddQuestion}
          data-test={`editor-app-question-triggers`}
        />
      )}
    </>
  );
};

AppQuestions.propTypes = {
  control: PropTypes.object,
};

export default AppQuestions;
