import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import classNames from 'classnames';

const TileWithTitle = ({ children, title, subtitle, className }) => {
  return (
    <div className={classNames('ui-tile-with-title', className)}>
      <div className="ui-tile-with-title__title">{title}</div>
      <div className="ui-tile-with-title__subtitle">{subtitle}</div>
      {children}
    </div>
  );
};

TileWithTitle.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};

export default TileWithTitle;
