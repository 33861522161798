import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Dropdown } from 'antd';

import Icon from 'ui/Icon';
import { translate } from 'utils/index';
import { AnswerStatus } from 'constants/answerStatus';
import { messages } from './messages';
import { DeactivateBlockIcon } from 'components/Icons';
import './index.scss';

const ApproveAnswer = ({ className, disabled, onClear, onApprove, onDisapprove, value }) => {
  const approveClassName = classNames('ui-approve-answer', className, {
    'ui-approve-answer--approved': value === AnswerStatus.approved,
    'ui-approve-answer--disapproved': value === AnswerStatus.disapproved,
    'ui-approve-answer--disabled': disabled,
  });

  const menuItems = [
    {
      key: 'none',
      label: translate(messages.deselect),
      onClick: onClear,
      icon: <DeactivateBlockIcon className="ui-approve-answer__ico-disapproved" />,
      'data-test': 'approve-dropdown-clear',
    },
    {
      key: 'approve',
      label: translate(messages.approve),
      onClick: onApprove,
      icon: <Icon icon="check2" className="ui-approve-answer__ico-approved" />,
      'data-test': 'approve-dropdown-approve',
    },
    {
      key: 'disapprove',
      label: translate(messages.disapprove),
      onClick: onDisapprove,
      icon: <Icon icon="close" className="ui-approve-answer__ico-disapproved" />,
      'data-test': 'approve-dropdown-disapprove',
    },
  ];

  const renderButton = () => {
    switch (value) {
      case AnswerStatus.none:
        return (
          <Button type="link" className="ui-approve-answer__button">
            <Icon icon="check2" />
          </Button>
        );
      case AnswerStatus.approved:
        return (
          <Button
            type="link"
            className="ui-approve-answer__button ui-approve-answer__button--approved"
          >
            <Icon icon="check2" />
          </Button>
        );
      case AnswerStatus.disapproved:
        return (
          <Button
            type="link"
            className="ui-approve-answer__button ui-approve-answer__button--disapproved"
          >
            <Icon icon="close3" />
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <div className={approveClassName}>
      <Dropdown
        menu={{ items: menuItems, className: 'ui-approve-answer__menu' }}
        trigger="click"
        disabled={disabled}
      >
        {renderButton()}
      </Dropdown>
    </div>
  );
};

ApproveAnswer.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClear: PropTypes.func,
  onApprove: PropTypes.func,
  onDisapprove: PropTypes.func,
  value: PropTypes.number,
};

export default ApproveAnswer;
