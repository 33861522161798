import { ControlStatus } from 'constants/controlStatus';
import { ControlType } from 'constants/controlType';
import { getAnswerType } from 'utils/controls';

export const tabs = {
  overview: 'overview',
  questionnaire: 'questionnaire',
  findings: 'findings',
  securedEvidence: 'securedEvidence',
};

export function countApprovedControls(category = {}) {
  const approvedControls = (category.controls || []).filter(
    ({ enabled, status }) => enabled && status === ControlStatus.approved,
  );
  return approvedControls.length;
}

export function countNotApprovedControls(category = {}) {
  const unApprovedControls = (category.controls || []).filter(
    ({ enabled, status }) =>
      enabled && (status === ControlStatus.unapproved || status === ControlStatus.none),
  );
  return unApprovedControls.length;
}

export function countAllEnabledControls(category = {}) {
  const enabledControls = (category.controls || []).filter(({ enabled }) => enabled);
  return enabledControls.length;
}

export function hasControlFiles(control, answerTypes) {
  const answerType = getAnswerType(control.answer_type_idx, answerTypes);
  return answerType.type === ControlType.FileUpload && control.answer && control.answer.length;
}
