import React from 'react';
import { Tag as AntTag } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// STYLE:
import './index.scss';

const Tag = ({ id, name, size, color, textEllipsis, className, onClick, icon }) => {
  const composedClassName = classNames(className, 'ui-tag', {
    [`ui-tag--${color}`]: color,
    [`ui-tag--${size}`]: true,
    [`ui-tag--with-icon`]: icon,
    'ui-tag--text-ellipsis': textEllipsis,
  });
  return (
    <AntTag key={`${id || name}-tag`} className={composedClassName} color={color} onClick={onClick}>
      {icon && React.cloneElement(icon, { className: 'ui-tag__icon' })}
      {icon ? <span>{name}</span> : name}
    </AntTag>
  );
};

Tag.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['small']),
  color: PropTypes.oneOf(['blue']),
  textEllipsis: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
};

export default Tag;
