import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import moment from 'moment';

import Icon from 'ui/Icon';
import { DATE_FORMAT } from 'constants/date';
import { translate } from 'utils/index';
import { messages } from './messages';
import { sanitizer } from 'utils/sanitizer';
import './status.scss';

const Status = ({ vendor }) => {
  const iconClass = classNames('vendors-list-status__badge', {
    'vendors-list-status__badge--red': vendor.trustScore < 33,
    'vendors-list-status__badge--orange': vendor.trustScore >= 33 && vendor.trustScore < 66,
    'vendors-list-status__badge--green': vendor.trustScore >= 66,
  });

  return vendor.trustType ? (
    <Tooltip
      title={
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              translate(messages.statusTooltip, {
                date: moment(vendor.trustUpdate).format(DATE_FORMAT),
              }),
            ),
          }}
        />
      }
    >
      <div className="vendors-list-status__badge-wrapper">
        <Icon className={iconClass} icon="trusted-badge" />
        <div>
          <div className="vendors-list-status__badge-score">
            {Math.round((vendor.trustScore + Number.EPSILON) * 100) / 100}%
          </div>
          <div className="vendors-list-status__badge-text">
            {vendor.trustType === 1 ? 'by findings' : 'by trusted organization'}
          </div>
        </div>
      </div>
    </Tooltip>
  ) : (
    <div className="vendors-list-status__empty"> {translate(messages.emptyStateValue)}</div>
  );
};

Status.propTypes = {
  vendor: PropTypes.object,
};

export default Status;
