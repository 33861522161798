import React from 'react';
import { useSelector } from 'react-redux';
import { translate } from 'utils/index';
import Icon from 'ui/Icon';
import { selectOverview } from './selectors';
import { messages } from './messages';
import './statuses.scss';

const Statuses = () => {
  const overview = useSelector(selectOverview);

  return (
    <div className="overview-statuses">
      <div className="overview-statuses__status">
        <div className="overview-statuses__status-image-wrapper">
          <Icon icon="vendors" className="overview-statuses__status-image" />
        </div>
        <div className="overview-statuses__status-text">
          <div>{translate(messages.numberOfVendors)}</div>
          <div className="overview-statuses__status-counter">
            {overview['asessments-customers'] || 0}
          </div>
        </div>
      </div>

      <div className="overview-statuses__status">
        <div className="overview-statuses__status-image-wrapper">
          <Icon icon="checklist" className="overview-statuses__status-image" />
        </div>
        <div className="overview-statuses__status-text">
          <div>{translate(messages.activeAssessments)}</div>
          <div className="overview-statuses__status-counter">
            {overview['asessments-active'] || 0}
          </div>
        </div>
      </div>

      <div className="overview-statuses__status">
        <div className="overview-statuses__status-image-wrapper">
          <Icon icon="overdue-assessments" className="overview-statuses__status-image" />
        </div>
        <div className="overview-statuses__status-text">
          <div>{translate(messages.overdueAssessments)}</div>
          <div className="overview-statuses__status-counter">
            {overview['asessments-overdue'] || 0}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statuses;
