import { ControlType } from 'constants/controlType';

export const getGroupCounts = (rows) => {
  const radioGroupLength = rows.filter((row) => row.type === ControlType.RadioGroup).length;
  const checkboxLength = rows.filter((row) => row.type === ControlType.Checkbox).length;
  const dropdownLength = rows.filter((row) => row.type === ControlType.DropDown).length;
  const multiselectLength = rows.filter((row) => row.type === ControlType.MultiSelect).length;

  return [radioGroupLength, checkboxLength, dropdownLength, multiselectLength];
};

// eslint-disable-next-line default-param-last
export function filterByType(list = [], type) {
  return list.filter((item) => item.type === type);
}
