import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import Input from 'ui/Input';
import TextArea from 'ui/TextArea';
import CheckboxGroup from 'ui/CheckboxGroup';
import RadioGroup from 'ui/RadioGroup';
import UploadFiles from 'components/UploadFiles';
import Select from 'ui/Select';
import DatePicker from 'ui/DatePicker';

import { translate } from 'utils/index';
import { sanitizer } from 'utils/sanitizer';
import { filterArrayValue, parseArrayValue } from 'utils/assessment';

import { ControlType as ControlTypeEnum } from 'constants/controlType';
import { ALLOW_FILES } from 'constants/general';

import EmptyComponent from 'components/EmptyComponent';
import { removeError } from 'pages/Assessment/reducers';
import { selectAssessment, selectAssessmentErrors } from 'pages/Assessment/selectors';
import { schemas } from 'pages/Assessment/finalize/lib';
import { BACKEND_INPUT_DATE_FORMAT } from 'constants/date';
import { selectTemplate } from 'pages/Editor/selectors';

import { messages } from './messages';

import './index.scss';

const ControlComponent = ({ className, type, data, isSimple = false, debounceTime }) => {
  const [value, setValue] = useState(data?.payload?.value || '');
  const [disabledSuggestion, disableSuggestion] = useState(false);
  const { errorsList } = useSelector(selectAssessmentErrors);
  const dispatch = useDispatch();
  const { rtl } = useSelector(selectTemplate);
  const { rtl: rtlAssessment } = useSelector(selectAssessment);

  useEffect(() => {
    if (data?.payload && type !== ControlTypeEnum.EmptySpace) {
      setValue(data.payload.value);
    }

    const controlId = data?.payload?.controlId;
    const categoryId = data?.payload?.categoryId;

    if (categoryId && controlId && errorsList?.[categoryId]?.[controlId]) {
      if (data.payload.appTrigger && schemas[data.payload.appTrigger]) {
        try {
          schemas[data.payload.appTrigger].validateSync(data?.payload?.value);
          dispatch(removeError({ categoryId, controlId }));
        } catch (error) {
          /* empty */
        }
      } else if (data?.payload?.value) {
        dispatch(removeError({ categoryId, controlId }));
      }
    }
  }, [data?.payload?.value]);

  const Component = useMemo(() => {
    switch (type) {
      case ControlTypeEnum.Input:
        return Input;
      case ControlTypeEnum.TextArea:
        return TextArea;
      case ControlTypeEnum.RadioGroup:
        return RadioGroup;
      case ControlTypeEnum.Checkbox:
        return CheckboxGroup;
      case ControlTypeEnum.DropDown:
      case ControlTypeEnum.MultiSelect:
        return Select;
      case ControlTypeEnum.FileUpload:
        return UploadFiles;
      case ControlTypeEnum.DatePicker:
        return DatePicker;
      case ControlTypeEnum.EmptySpace:
        return EmptyComponent;
      default:
        return EmptyComponent;
    }
  }, [type]);

  const props = {};

  if (type === ControlTypeEnum.MultiSelect) {
    props.mode = 'multiple';
  }

  const onChangeDebounced = useCallback(
    debounce((newValue) => {
      data.payload.onChange(newValue);
    }, debounceTime || 1000),
    [],
  );

  if (type === ControlTypeEnum.Input || type === ControlTypeEnum.TextArea) {
    if (data?.payload?.onChange) {
      props.onChange = (event) => {
        setValue(event.target.value);
        onChangeDebounced(event.target.value);
        disableSuggestion(true);
      };

      props.value = value || (!disabledSuggestion ? data?.payload?.suggest : '');
    }
  }
  if (!isSimple) {
    props.options = Array.isArray(data?.payload?.options)
      ? data.payload.options.filter((option) => option.label !== '' || option.label !== 0)
      : [];

    if (type === ControlTypeEnum.RadioGroup) {
      if (data?.payload?.onClick) {
        props.onClick = (event) => {
          const parsedValue =
            // eslint-disable-next-line eqeqeq
            props.value == event.target.value ? '' : parseInt(event.target.value, 10);
          setValue(parsedValue);
          data.payload.onClick(parsedValue);
          disableSuggestion(true);
        };

        props.value = parseInt(value, 10) || (!disabledSuggestion ? data?.payload?.suggest : null);
      }
    }

    if (type === ControlTypeEnum.Checkbox || type === ControlTypeEnum.MultiSelect) {
      if (data?.payload?.onChange) {
        props.onChange = (newValue) => {
          const parsedValue = parseArrayValue(newValue);
          setValue(parsedValue);
          data.payload.onChange(parsedValue);
          disableSuggestion(true);
        };
      }

      const suggest = Array.isArray(data?.payload?.suggest)
        ? data.payload.suggest
        : [data?.payload?.suggest];

      props.value = [];
      if (value) props.value = filterArrayValue(value);
      else if (!disabledSuggestion && suggest[0]) props.value = suggest;
    }

    if (type === ControlTypeEnum.DropDown) {
      props.onChange = (newValue) => {
        const parsedValue = newValue || 0;
        setValue(parsedValue);
        data.payload.onChange(parsedValue);
        disableSuggestion(true);
      };

      const suggest = !disabledSuggestion ? data?.payload?.suggest || null : null;
      props.value = value || suggest;
    }

    if (type === ControlTypeEnum.FileUpload) {
      props.loading = data.payload.loading;
    } else {
      props.loading = data.payload.loading && data.payload.value !== value;
    }
  }

  if (type === ControlTypeEnum.MultiSelect || type === ControlTypeEnum.DropDown) {
    props.size = 'large';
    props.color = 'gray';
    props.placeholder = data.payload.placeholder || translate(messages.chooseAnswer);
    props.allowClear = true;
  }
  if (type === ControlTypeEnum.FileUpload) {
    props.accept = data.payload.accept ? data.payload.accept : ALLOW_FILES;
  }

  if (type === ControlTypeEnum.DatePicker) {
    props.color = 'gray';
    props.className = 'control__date-picker';
    props.value = value ? value : '';

    props.onChange = (val) => {
      const parsedValue = val ? moment(val).format(BACKEND_INPUT_DATE_FORMAT) : '';
      setValue(parsedValue);
      data.payload.onChange(parsedValue);
    };
  }

  return (
    <div
      data-test={data?.payload['data-test']}
      className={classNames('control', className, {
        'control--with-disabled-suggestion': disabledSuggestion,
      })}
    >
      {data?.label && (
        <label
          dangerouslySetInnerHTML={{
            __html: sanitizer(data.label),
          }}
          className="control__label"
        />
      )}
      {data?.description && (
        <div
          className="control__description"
          dangerouslySetInnerHTML={{
            __html: sanitizer(data.description),
          }}
        />
      )}
      {React.cloneElement(<Component />, {
        className: 'control__control-type',
        style: { direction: rtl || rtlAssessment ? 'rtl' : 'ltr' },
        rtl: rtl || rtlAssessment,
        assessmentId: data?.assessmentId,
        ...data?.payload,
        'data-test': `${data?.payload['data-test']}_control`,
        ...props,
      })}
    </div>
  );
};

ControlComponent.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    ControlTypeEnum.Input,
    ControlTypeEnum.TextArea,
    ControlTypeEnum.RadioGroup,
    ControlTypeEnum.Checkbox,
    ControlTypeEnum.DropDown,
    ControlTypeEnum.FileUpload,
    ControlTypeEnum.MultiSelect,
    ControlTypeEnum.DatePicker,
    ControlTypeEnum.EmptySpace,
  ]).isRequired,
  data: PropTypes.shape({
    payload: PropTypes.any, // TODO propTypes are very limited it should be enum of components :/
    label: PropTypes.string,
    description: PropTypes.string,
    categoryId: PropTypes.string,
    controlId: PropTypes.string,
    assessmentId: PropTypes.string,
    accept: PropTypes.string,
  }),
  isSimple: PropTypes.bool,
  debounceTime: PropTypes.number,
  value: PropTypes.any,
};

export default ControlComponent;
