/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { useLocation, useNavigate, matchPath } from 'react-router';
import { useIdleTimer } from 'react-idle-timer';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { LOGOUT_TIMER } from 'constants/general';
import { MixpanelLogoutTypes } from 'constants/mixpanel';
import { NavCollapsedContextProvider } from 'context/navCollapsedContext';
import { ORG_ID_AUTH_KEY, TOKEN_AUTH_KEY, WS_TOKEN_AUTH_KEY } from 'utils/auth';
import ROUTES from 'constants/routes';
import { clearCookie, getCookie, setCookie } from 'utils/apiHelpers';
import { getOrganizations } from 'utils/organizations';
import { useSelectedPath } from 'hooks/navigation';
import useScript from 'configs/useScript';
import { isProductionDomain } from 'utils/index';
import { mixpanelTrackLogout } from 'utils/mixpanel';
import AssessmentSentPopup from 'pages/AssignAssessment/assessmentSentPopup';
import AppPreloader from './ui/Loader/appPreloader';
import App from './pages/App';
import ScrollToTop from './pages/App/scrollToTop';
import configs from './configs';
import { ErrorBoundary } from 'components/BaseLayout/errorBoundary';
import 'antd/dist/antd.css';
import { selectProfile } from 'pages/Profile/selectors';
import { isFindingsUser } from 'utils/isFindingsUser';
import { ENV } from 'configs/env';
import './index.scss';

const EntryComponentWrapper = ({ children }) => {
  const {
    user: { email },
  } = useSelector(selectProfile);
  const isEligible = (isProductionDomain() || !isFindingsUser(email)) && ENV.HUBSPOT_ID;
  useScript(`//js.hs-scripts.com/${ENV.HUBSPOT_ID}.js`, 'hs-script-loader', isEligible); // initialize Hubspot

  return <>{children}</>;
};

EntryComponentWrapper.propTypes = {
  children: PropTypes.node,
};

const EntryComponent = () => {
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const selectedPath = useSelectedPath();
  const location = useLocation();

  useEffect(() => {
    const initializeApp = async () => {
      setLoading(true);
      await configs.initializeConfig();
      await configs.initializeFonts();
      await configs.initializeTranslations();
      setStore(await configs.initializeReduxStore());
      setOrganizationId();
      setLoading(false);
    };
    initializeApp();
  }, []);

  const onIdle = () => {
    clearCookie(TOKEN_AUTH_KEY);
    clearCookie(ORG_ID_AUTH_KEY);
    clearCookie(WS_TOKEN_AUTH_KEY);
    navigate(ROUTES.LOGOUT);
    mixpanelTrackLogout(true, MixpanelLogoutTypes.Idle);
  };

  const setOrganizationId = () => {
    if (getCookie(ORG_ID_AUTH_KEY)) {
      return;
    }

    const organizations = getOrganizations();
    const match = selectedPath
      ? matchPath({ path: selectedPath, end: true }, location.pathname)
      : null;

    if (!match) {
      return;
    }

    const organization = organizations?.find(
      ({ index }) => index === Number(match.params.organization),
    );

    if (organization) {
      setCookie(ORG_ID_AUTH_KEY, organization.id);
    }
  };

  useIdleTimer({ onIdle, timeout: LOGOUT_TIMER });

  if (loading || !store) {
    return <AppPreloader />;
  }

  return (
    <Provider store={store}>
      <EntryComponentWrapper>
        <GoogleOAuthProvider clientId={ENV.GOOGLE_CLIENT_ID}>
          <NavCollapsedContextProvider>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
            <AssessmentSentPopup />
          </NavCollapsedContextProvider>
        </GoogleOAuthProvider>
      </EntryComponentWrapper>
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <EntryComponent />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
configs.serviceWorker.unregister();
