import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const AssessmentScoreBenchmark = ({ className, score, benchmark = 0 }) => {
  const scaleClassName = classNames('assessment-score-benchmark__score-scale', {
    'assessment-score-benchmark__score-scale--red': score < 33,
    'assessment-score-benchmark__score-scale--orange': score >= 33 && score < 66,
    'assessment-score-benchmark__score-scale--green': score >= 66,
  });

  return (
    <div className={classNames('assessment-score-benchmark', className)}>
      <div className="assessment-score-benchmark__inner-container">
        <div className="assessment-score-benchmark__progress-bar" />
        <div className="assessment-score-benchmark__benchmark" style={{ left: `${benchmark}%` }}>
          <div className="assessment-score-benchmark__benchmark-scale" />
          <div className="assessment-score-benchmark__benchmark-value">{benchmark}</div>
        </div>
        <div className="assessment-score-benchmark__score" style={{ left: `${score}%` }}>
          <div className="assessment-score-benchmark__score-value">{score}%</div>
          <div className={scaleClassName} />
        </div>
      </div>
    </div>
  );
};

AssessmentScoreBenchmark.propTypes = {
  className: PropTypes.string,
  score: PropTypes.number.isRequired,
  benchmark: PropTypes.number,
};

export default AssessmentScoreBenchmark;
