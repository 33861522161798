import React from 'react';
import PropTypes from 'prop-types';

import Card from 'ui/Card';
import enforce2FaIcon from 'assets/svgs/enforce-2fa.svg';
import { translate } from 'utils/index';
import { messages } from './messages';
import './index.scss';

const CardWithEnforce2Fa = ({ children }) => {
  return (
    <div className="card-with-enforce-2fa__wrapper">
      <Card className="card-with-enforce-2fa">
        <div className="card-with-enforce-2fa__left-box">
          <div className="card-with-enforce-2fa__left-box-content">
            <img src={enforce2FaIcon} alt="thunder auto" />
            <p className="card-with-enforce-2fa__left-box-content-text">
              {translate(messages.weEmployToHighestLevelOf)}
            </p>
          </div>
        </div>
        <div className="card-with-enforce-2fa__inner">
          <h1>{translate(messages.select2FaMethod)}</h1>
          {children}
        </div>
      </Card>
    </div>
  );
};

CardWithEnforce2Fa.propTypes = {
  children: PropTypes.any,
};

export default CardWithEnforce2Fa;
