export const messages = {
  excelImportWizardWelcome: {
    key: 'Excel Import Wizard.excelImportWizardWelcome',
    defaultMessage: 'Welcome to our smart mapping wizard',
  },
  excelImportWizardHeaderTitle: {
    key: 'Excel Import Wizard.excelImportWizardHeaderTitle',
    defaultMessage: 'Define each category question and answer cell range',
  },
  excelImportWizardHeaderSubTitle1: {
    key: 'Excel Import Wizard.excelImportWizardHeaderSubTitle1',
    defaultMessage:
      'Please fill in or edit the question and answer range of each category by selecting on the excel sheet. press ',
  },
  excelImportWizardHeaderSubTitle2: {
    key: 'Excel Import Wizard.excelImportWizardHeaderSubTitle2',
    defaultMessage: 'for the next category.',
  },
  excelImportWizardCategoriesDescription: {
    key: 'Excel Import Wizard.excelImportWizardCategoriesDescription',
    defaultMessage: 'Our system predicted the following categories:',
  },
  excelImportWizardCategoriesQuestionsRange: {
    key: 'Excel Import Wizard.excelImportWizardCategoriesQuestionsRange',
    defaultMessage: 'Questions range',
  },
  excelImportWizardCategoriesAnswersRange: {
    key: 'Excel Import Wizard.excelImportWizardCategoriesAnswersRange',
    defaultMessage: 'Answers range',
  },
  excelImportWizardFooterButtonNext: {
    key: 'Excel Import Wizard.excelImportWizardFooterButtonNext',
    defaultMessage: 'create assessment',
  },
  excelImportWizardPlaceholderForCategory: {
    key: 'Excel Import Wizard.excelImportWizardPlaceholderForCategory',
    defaultMessage: 'Enter category title',
  },
  excelImportWizardSubMenuTitleAddDescriptionTop: {
    key: 'Excel Import Wizard.excelImportWizardSubMenuTitleAddDescriptionTop',
    defaultMessage: 'Add top description',
  },
  excelImportWizardSubMenuTitleAddDescriptionBottom: {
    key: 'Excel Import Wizard.excelImportWizardSubMenuTitleAddDescriptionBottom',
    defaultMessage: 'Add bottom description',
  },
  excelImportWizardSubMenuTitleDelete: {
    key: 'Excel Import Wizard.excelImportWizardSubMenuTitleDelete',
    defaultMessage: 'Delete Category',
  },
  excelImportWizardDeleteCategoryPopup: {
    key: 'Excel Import Wizard.excelImportWizardDeleteCategoryPopup',
    defaultMessage: 'Are you Sure you want to delete this Category?',
  },
  excelImportWizardDeleteCategoryPopupButtonText: {
    key: 'Excel Import Wizard.excelImportWizardDeleteCategoryPopupButtonText',
    defaultMessage: 'YES DELETE',
  },
  excelImportWizardAddNewCategory: {
    key: 'Excel Import Wizard.excelImportWizardAddNewCategory',
    defaultMessage: 'Add Category',
  },
  excelImportWizardPleaseFill: {
    key: 'Excel Import Wizard.excelImportWizardPleaseFill',
    defaultMessage: 'Please fill',
  },
  excelImportWizardFooterButtonPreview: {
    key: 'Excel Import Wizard.excelImportWizardFooterButtonPreview',
    defaultMessage: 'Preview',
  },
  excelImportWizardCategoryTopDescriptionPlaceholder: {
    key: 'Excel Import Wizard.excelImportWizardCategoryTopDescriptionPlaceholder',
    defaultMessage: 'Top description (select cell)',
  },
  excelImportWizardCategoryBottomDescriptionPlaceholder: {
    key: 'Excel Import Wizard.excelImportWizardCategoryBottomDescriptionPlaceholder',
    defaultMessage: 'Bottom description (select cell)',
  },
  importWizardModalSuccessTitle: {
    key: 'Excel Import Wizard.importWizardModalSuccessTitle',
    defaultMessage: 'Your assessment has been successfully uploaded.',
  },
  importWizardModalCloseButton: {
    key: 'Excel Import Wizard.importWizardModalCloseButton',
    defaultMessage: 'Continue to edtior',
  },
  excelImportWizardDescription: {
    key: 'Excel Import Wizard.excelImportWizardDescription',
    defaultMessage:
      'Our system automatically maps and predict your assessment file structure:  category, question and answer range. In the next screen you will see our prediction and will be asked to edit or fill in the missing parts.',
  },
  importWizardModalSuccessDescription: {
    key: 'Excel Import Wizard.importWizardModalSuccessDescription',
    defaultMessage:
      'Continue to the editor, where you can edit and use our Answer Repository for a quick assessment response.',
  },
};

export default {
  prefix: 'Excel Import Wizard',
  messages,
};
