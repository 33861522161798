import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'antd';
import { useDispatch } from 'react-redux';

import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { translate } from 'utils/index';
import ROUTES from 'constants/routes';
import { MixpanelAssignAssessmentSource } from 'constants/mixpanel';
import { getProfileVendors, setVendorEnableStatus } from 'api/vendor';
import { getProfileCustomers } from 'api/customers';
import { setCustomerStatus } from 'api/customer';
import { getRecentVendors } from 'api/recent';
import Icon from 'ui/Icon';
import { useRole } from 'hooks/useRole';
import { messages } from './messages';
import { ReactComponent as TrashIcon } from 'assets/svgs/small-trash.svg';
import { ReactComponent as EditIcon } from 'assets/svgs/edit-pen.svg';
import { ReactComponent as AssessmentIcon } from 'assets/svgs/assessment-icon.svg';
import { ReactComponent as ArchiveIcon } from 'assets/svgs/archive-icon.svg';
import { ReactComponent as RestoreIcon } from 'assets/svgs/restore-icon.svg';

import './actions.scss';

const Actions = ({ vendor, isVendorsList, isCustomersList, source, setVendorToRemove }) => {
  const appNavigate = useAppNavigate();
  const { isAuditorRole, isViewerRole, isRestrictedBoSoViewerAuditorRole } = useRole();

  const dispatch = useDispatch();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const onEdit = (event) => {
    event.stopPropagation();
    appNavigate(isMyVendorsTabSelected ? ROUTES.VENDOR_EDIT : ROUTES.CUSTOMER_EDIT_CUSTOMER, {
      [isMyVendorsTabSelected ? 'vendorId' : 'customerId']: vendor.id,
    });
  };

  const onAssign = (event) => {
    event.stopPropagation();
    appNavigate(
      isMyVendorsTabSelected ? ROUTES.VENDOR_ASSIGN_ASSESSMENT : ROUTES.CUSTOMER_ASSIGN_ASSESSMENT,
      {},
      { state: { customerId: vendor.id, source } },
    );
  };

  const onSetVendorStatus = async (event) => {
    event.stopPropagation();
    isMyVendorsTabSelected
      ? await dispatch(setVendorEnableStatus({ id: vendor.id, isActive: !vendor.enabled })).unwrap()
      : await dispatch(setCustomerStatus({ id: vendor.id, isActive: !vendor.enabled })).unwrap();

    if (isVendorsList && isMyVendorsTabSelected) {
      await dispatch(getProfileVendors()).unwrap();
    } else if (isCustomersList) {
      await dispatch(getProfileCustomers()).unwrap();
    } else {
      await dispatch(getRecentVendors()).unwrap();
    }
  };

  const items = [
    {
      label: translate(
        isMyVendorsTabSelected ? messages.editVendorCard : messages.editCustomerCard,
      ),
      icon: <EditIcon />,
      key: 'edit',
      disabled: isAuditorRole || isViewerRole,
      visible: true,
      'data-test': 'vendors-table-actions-edit',
    },
    {
      label: translate(messages.assignAssessment),
      icon: <AssessmentIcon />,
      key: 'assignAssessment',
      disabled: isRestrictedBoSoViewerAuditorRole,
      visible: true,
      'data-test': 'vendors-table-actions-assign-assessment',
    },
    {
      label: translate(messages.archive),
      icon: <ArchiveIcon />,
      key: 'archive',
      disabled: isRestrictedBoSoViewerAuditorRole,
      visible: vendor.enabled,
      'data-test': 'vendors-table-actions-archive',
    },
    {
      label: translate(messages.restore),
      icon: <RestoreIcon />, // <Icon icon="restore" className="vendors-page-actions__icon" />,
      key: 'restore',
      disabled: isRestrictedBoSoViewerAuditorRole,
      visible: !vendor.enabled,
      'data-test': 'vendors-table-actions-restore',
    },
    {
      label: translate(messages.deleteVendor),
      icon: <TrashIcon />,
      key: 'remove',
      disabled: isRestrictedBoSoViewerAuditorRole,
      visible: true,
      className: 'vendors-page-actions__remove',
      'data-test': 'vendors-table-actions-delete',
    },
  ].filter(({ visible }) => visible);

  const onClick = ({ key, domEvent }) => {
    switch (key) {
      case 'edit':
        return onEdit(domEvent);
      case 'assignAssessment':
        return onAssign(domEvent);
      case 'remove':
        return setVendorToRemove(vendor);
      case 'archive':
      case 'restore':
        return onSetVendorStatus(domEvent);
      default:
        return null;
    }
  };

  return (
    <div className="vendors-page-actions">
      <Dropdown menu={{ items, onClick }} trigger="click">
        <Button
          className="vendors-page-actions__action"
          type="link"
          data-test="vendor-actions-three-dots-menu"
        >
          <Icon icon="more2" />
        </Button>
      </Dropdown>
    </div>
  );
};

Actions.propTypes = {
  vendor: PropTypes.object,
  isVendorsList: PropTypes.bool,
  isCustomersList: PropTypes.bool,
  source: PropTypes.oneOf(Object.keys(MixpanelAssignAssessmentSource)),
  setVendorToRemove: PropTypes.func,
};

export default Actions;
