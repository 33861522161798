import React from 'react';
import PropTypes from 'prop-types';

import { AssessmentIcon, BriefcaseIcon, VendorIcon } from 'components/Icons';
import { translate } from 'utils/index';
import { messages } from 'pages/Dashboard/messages';
import classNames from 'classnames';

import './index.scss';

const TotalSummary = ({ chart }) => {
  const { data } = chart;

  const icons = {
    AssessmentIcon: <AssessmentIcon />,
    BriefcaseIcon: <BriefcaseIcon />,
    VendorIcon: <VendorIcon />,
  };

  return (
    <div className="dashboard__chart__total-summary">
      {data?.map((item, index) => {
        return (
          <div
            key={`dashboard__chart__total-summary-item-${item.text?.key || index}`}
            className="dashboard__chart__total-summary__item"
          >
            <div className="dashboard__chart__total-summary__item__icon">{icons[item.icon]}</div>
            <div className="dashboard__chart__total-summary__item__inner">
              <h6>{translate({ key: item.text?.key, defaultMessage: item.text?.value })}</h6>
              <p className="dashboard__chart__total-summary__item__inner__content">
                {item.filteredValue !== undefined ? (
                  <>
                    <span className="dashboard__chart__total-summary__item__inner__content__value">
                      {' '}
                      {item.filteredValue?.toLocaleString()}
                    </span>{' '}
                    {translate(messages.TotalSummaryOfTotal)}
                    {'  '}
                  </>
                ) : null}
                <span
                  className={classNames(
                    'dashboard__chart__total-summary__item__inner__content__total',
                    { 'total-value-unfiltered': !item.filteredValue },
                  )}
                >
                  {item.total?.toLocaleString()}
                </span>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

TotalSummary.propTypes = {
  chart: PropTypes.object,
};

export default TotalSummary;
