import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './completedCheckbox.scss';

const CompletedCheckbox = ({
  autoFocus,
  checked,
  checkedLabel,
  className,
  defaultLabel,
  disabled,
  onChange,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onClick,
  name,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current.focus();
    }
  }, []);

  const checkboxClassName = classNames('ui-completed-checkbox', className, {
    'ui-completed-checkbox--checked': checked,
    'ui-completed-checkbox--disabled': disabled,
  });

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onClick={onClick}
      className={checkboxClassName}
    >
      <label htmlFor={name} className="ui-completed-checkbox__label">
        <input
          id={name}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          ref={ref}
          type="checkbox"
        />
        {checked ? checkedLabel : defaultLabel}
      </label>
    </div>
  );
};

CompletedCheckbox.propTypes = {
  autoFocus: PropTypes.bool,
  checked: PropTypes.bool,
  checkedLabel: PropTypes.string,
  className: PropTypes.string,
  defaultLabel: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
};

export default CompletedCheckbox;
