import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import AssessmentsFilters from 'components/AssessmentsFilters';
import AddFilterButton from 'components/AddFilterButton';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { clearFilters, setFilter } from 'components/AssessmentsFilters/reducers';
import { getOverview } from 'api/overview';
import { translate } from 'utils/index';
import { messages as assessmentsFiltersMessages } from 'components/AssessmentsFilters/messages';

import './filters.scss';

const Filters = () => {
  const dispatch = useDispatch();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  useEffect(() => {
    dispatch(clearFilters());
    dispatch(setFilter({ type: isMyVendorsTabSelected ? 'vendor' : 'customer' }));
    dispatch(setFilter({ type: 'assessment' }));
    dispatch(
      setFilter({ type: 'archive', value: translate(assessmentsFiltersMessages.active), id: 0 }),
    );

    return () => {
      dispatch(clearFilters());
    };
  }, []);

  const setAssessmentsFilter = (type) => {
    dispatch(setFilter({ type }));
  };

  return (
    <div className="overview-filters">
      <AssessmentsFilters
        callback={() => dispatch(getOverview())}
        className="overview-filters__filters"
      />
      <AddFilterButton
        setFilter={setAssessmentsFilter}
        filtersType="assessment"
        className="overview-filters__add-filter"
      />
    </div>
  );
};

export default Filters;
