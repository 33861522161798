import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { establishTrustPartners, rejectTrustPartnerThunk } from 'api/organizations';
import { useTheme } from 'hooks/theme';

import { ReactComponent as ShieldIcon } from 'assets/svgs/shield-icon.svg';
import { ReactComponent as CancelIcon } from 'assets/svgs/close-x-cancel.svg';

import { translate } from 'utils/index';

import Modal from 'ui/Modal';
import Table from 'ui/Table';
import Button from 'ui/Button';
import Select from 'ui/Select';

import { API_STATUS } from 'constants/api';

import { messages } from './messages';

import './pendingTrustedUsersModal.scss';
import { getTrustedPartners } from 'api/app';
import { getCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY } from 'utils/auth';

const PendingTrustedUsersModal = () => {
  const [selectedOrganizations, setSelectedOrganizations] = useState({});
  const [pendingOrganizations, setPendingOrganizations] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { trustedOrganizations, isTrustedOrganizationsLoading } = useSelector((state) => state.app);
  const { organizations } = useSelector((state) => state.organizations);
  const { colorTheme } = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(getTrustedPartners(getCookie(ORG_ID_AUTH_KEY)));
    }
  }, [isOpen]);

  useEffect(() => {
    if (trustedOrganizations?.pending?.length > 0) {
      setIsOpen(true);
      setPendingOrganizations(trustedOrganizations?.pending);
    } else {
      setIsOpen(false);
    }
  }, [trustedOrganizations]);

  const handleCancelModal = () => {
    setIsOpen(false);
  };

  const handleSelectOrganization = (organizationId, preselectedOrganizations) => {
    const clonedSelectedOrganizations = { ...selectedOrganizations };
    clonedSelectedOrganizations[organizationId] = preselectedOrganizations;
    setSelectedOrganizations(clonedSelectedOrganizations);
  };

  const handleRejectRequest = async (id) => {
    const res = await dispatch(rejectTrustPartnerThunk(id)).unwrap();
    if (res.status === API_STATUS.SUCCESS) {
      dispatch(getTrustedPartners(getCookie(ORG_ID_AUTH_KEY)));
    }
  };

  const handleApproveRequest = async () => {
    const res = await dispatch(establishTrustPartners(selectedOrganizations)).unwrap();
    if (res.status === API_STATUS.SUCCESS) {
      setIsOpen(false);
    }
  };
  const columns = [
    {
      title: translate(messages.trustOrganizationColumnReqFrom),
      key: 'corporateName',
      dataIndex: 'corporateName',
    },
    {
      title: translate(messages.trustOrganizationColumnContactEmail),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: translate(messages.trustOrganizationColumnAssignOrg),
      key: 'organizations',
      dataIndex: 'organizations',
      render: (_, { id }) => {
        return (
          <Select
            className="pending-trusted-users-modal__select"
            mode="multiple"
            placeholder={translate(messages.trustOrganizationSelectOrganizations)}
            options={organizations.map((org) => ({
              ...org,
              value: org.id,
            }))}
            value={selectedOrganizations[id]}
            size="large"
            color="gray"
            onChange={(preselectedOrganizations) =>
              handleSelectOrganization(id, preselectedOrganizations)
            }
          />
        );
      },
    },
    {
      title: translate(messages.trustOrganizationColumnRejectReq),
      align: 'center',
      render: (organization) => {
        return (
          <div className="pending-trusted-users-modal__reject-button">
            <Button
              color="black"
              size="sm"
              squared
              onClick={() => {
                handleRejectRequest(organization.id);
              }}
              outline
              data-test="trust-organization-column-reject-button"
            >
              <CancelIcon width={12} height={12} /> {translate(messages.trustOrganizationReject)}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        className="pending-trusted-users-modal"
        open={isOpen}
        onCancel={handleCancelModal}
        width={940}
        footer={
          <div className="pending-trusted-users-modal__footer">
            <Button
              color={colorTheme}
              size="sm"
              outline
              data-test="pending-trusted-users-modal-cancel"
              onClick={handleCancelModal}
            >
              {' '}
              {translate(messages.trustOrganizationCancel)}
            </Button>
            <Button
              disabled={Object.keys(selectedOrganizations)?.length !== pendingOrganizations?.length}
              color={colorTheme}
              size="sm"
              data-test="pending-trusted-users-modal-approve"
              onClick={handleApproveRequest}
            >
              {translate(messages.trustOrganizationApprove)}
            </Button>
          </div>
        }
      >
        <div className="pending-trusted-users-modal__header">
          <ShieldIcon width={18} height={20} /> {translate(messages.trustOrganizationModalHeader)}
        </div>
        <Table
          className="pending-trusted-users-modal__table"
          dataSource={pendingOrganizations}
          columns={columns}
          pagination={false}
          loading={isTrustedOrganizationsLoading}
        />
      </Modal>
    </div>
  );
};

export default PendingTrustedUsersModal;
