import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import MyFilesUploadFile from 'pages/MyFiles/myFilesUploadFile';
import MyFilesUploadedItem from 'pages/MyFiles/myFilesUploadedItem';
import PropTypes from 'prop-types';
import EmptyFilesList from './emptyFilesList';
import { translate } from 'utils/index';
import { messages } from './messages';

import './myFilesList.scss';

const MyFilesList = ({
  files,
  onFileUpload,
  onEditClick,
  handlePreview,
  handleRename,
  handleDelete,
  fetchMyFiles,
  handleChooseFile,
  selectMulti = false,
  selectedFiles,
  setSelectedFiles,
}) => {
  const currentOffset = useSelector((state) => state.myFiles.currentOffset);
  const totalOffset = useSelector((state) => state.myFiles.totalOffset);
  const filesListRef = useRef(null);
  const isLoading = useSelector((state) => state.myFiles.isLoading);

  const handleUnSelectFiles = () => {
    setSelectedFiles([]);
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.addEventListener('click', handleUnSelectFiles);
    }
    return () => {
      if (body) {
        body.removeEventListener('click', handleUnSelectFiles);
      }
    };
  }, []);

  useEffect(() => {
    const listElement = filesListRef?.current;
    const handleScroll = () => {
      if (listElement.scrollTop + listElement.clientHeight >= listElement.scrollHeight) {
        if (currentOffset < totalOffset) {
          fetchMyFiles(currentOffset + 1);
        }
      }
    };

    listElement.addEventListener('scroll', handleScroll);

    return () => {
      listElement.removeEventListener('scroll', handleScroll);
    };
  }, [filesListRef?.current, currentOffset, totalOffset]);

  const handleClickButton = () => {
    document.getElementById('fileInput').click();
  };

  const handleSelectFile = (e, file, itemIndex) => {
    e.stopPropagation();
    setSelectedFiles((prev) => {
      if (selectMulti) {
        return prev.includes(file.id)
          ? prev.filter((selectedFile) => selectedFile !== file.id)
          : [...prev, file.id];
      }
      return [file.id];
    });

    if (e.detail === 2) {
      if (handleChooseFile) {
        handleChooseFile(e, [file.id]);
      } else {
        handlePreview(itemIndex);
      }
    }
  };

  return (
    <div className="my-files-list" ref={filesListRef}>
      {files?.length ? (
        <>
          <MyFilesUploadFile onFileUpload={onFileUpload} />
          {files.map((file, index) => {
            return (
              <MyFilesUploadedItem
                key={file.id}
                file={file}
                isSelected={selectedFiles?.includes(file.id) || false}
                onSelect={handleSelectFile}
                onEditClick={onEditClick}
                handlePreview={handlePreview}
                itemIndex={index}
                handleRename={handleRename}
                handleDelete={handleDelete}
              />
            );
          })}
          {isLoading && (
            <p className="my-files-list__loading-more">{translate(messages.loadingMoreFiles)}</p>
          )}
        </>
      ) : (
        <EmptyFilesList handleClickButton={handleClickButton} onFileUpload={onFileUpload} />
      )}
    </div>
  );
};

MyFilesList.propTypes = {
  files: PropTypes.array,
  onFileUpload: PropTypes.func,
  fetchMyFiles: PropTypes.func,
  onEditClick: PropTypes.func,
  handlePreview: PropTypes.func,
  handleRename: PropTypes.func,
  handleDelete: PropTypes.func,
  handleChooseFile: PropTypes.func,
  selectMulti: PropTypes.bool,
  selectedFiles: PropTypes.array,
  setSelectedFiles: PropTypes.func,
};

export default MyFilesList;
