export const messages = {
  header: {
    key: 'Category Tab Customer.header',
    defaultMessage: 'Business Impact Analysis',
  },
  tabLabel: {
    key: 'Category Tab Customer.tabLabel',
    defaultMessage: 'Inherent risk',
  },
  tabAddButtonText: {
    key: 'Category Tab Customer.tabAddButtonText',
    defaultMessage: 'Add new analysis',
  },
  deleteButton: {
    key: 'Category Tab Customer.deleteButton',
    defaultMessage: 'Delete analysis',
  },
  removeAnalysisText: {
    key: 'Category Tab Customer.removeAnalysisText',
    defaultMessage: 'All analysis information will be lost',
  },
  removeAnalysisTextHeader: {
    key: 'Category Tab Customer.removeAnalysisTextHeader',
    defaultMessage: 'Delete analysis?',
  },
  saveAndClose: {
    key: 'Category Tab Customer.saveAndClose',
    defaultMessage: 'Save and close',
  },
  inputPlaceholder: {
    key: 'Category Tab Customer.inputPlaceholder',
    defaultMessage: 'Enter name',
  },
};

export default {
  prefix: 'Category Tab Customer',
  messages,
};
