import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useFormik } from 'formik';

import { setProfile, getOnboardingProfile } from 'api/profile';
import { API_STATUS } from 'constants/api';
import { getCookie } from 'utils/apiHelpers';
import { translate } from 'utils/index';
import { ORG_ID_AUTH_KEY, TOKEN_AUTH_KEY } from 'utils/auth';

import Form from 'ui/Form';
import Input from 'ui/Input';

import Card from 'ui/Card';
import CardWithLogo from 'components/CardWithLogo';
import LanguageSelect from 'components/LanguageSelect';
import PhoneInput from 'components/PhoneInput';

import { messages } from 'pages/Activation/Onboarding/messages';
import { ONBOARDING_FORM_STAGE } from 'pages/Activation/Onboarding/lib';
import { selectProfile } from 'pages/Profile/selectors';
import { stageTwoInputSchema } from 'pages/Activation/Onboarding/stageTwoSchema';
import { setProfileState } from 'pages/Profile/reducers';

import thunderIcon from 'assets/svgs/thunder-auto.svg';

const StageTwo = ({ setStage }) => {
  const { user } = useSelector(selectProfile);
  const dispatch = useDispatch();
  const { token } = useParams();

  const phoneValues = useRef({
    phone: user?.phone || '',
    corporateGeography: user?.corporateGeography || '',
    nationalNumber: '',
    isError: false,
  });

  useEffect(() => {
    const getAndSetProfileData = async () => {
      const cookieToken = getCookie(TOKEN_AUTH_KEY);
      const getOnboardingProfileResp = await getOnboardingProfile({
        token: cookieToken ? cookieToken : token,
        org_id: getCookie(ORG_ID_AUTH_KEY),
      });
      dispatch(setProfileState({ user: getOnboardingProfileResp?.profile }));
    };

    getAndSetProfileData();
  }, []);

  const onChangePhone = (values) => {
    phoneValues.current = {
      ...values,
    };
  };

  const {
    errors,
    values: { fullname, email, lang },
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      fullname: user?.fullname || '',
      email: user?.email || '',
      mfaMethod: user?.mfa_method || 0,
      lang: user?.lang || 0,
    },

    validationSchema: stageTwoInputSchema(
      `${translate(messages.stageTwoInputEmailError)}`,
      `${translate(messages.stageTwoInputRequiredEmail)}`,
      `${translate(messages.stageTwoInputNameError)}`,
      `${translate(messages.stageTwoInputRequiredName)}`,
    ),

    onSubmit: async () => {
      const userData = {
        ...phoneValues.current,
        fullname,
        email,
        lang,
        corporateName: user.corporateName,
      };

      dispatch(setProfileState({ user: userData }));
      const cookieToken = getCookie(TOKEN_AUTH_KEY);
      const { status } = await dispatch(
        setProfile({ user: userData, token: cookieToken ? cookieToken : token }),
      ).unwrap();

      if (status !== API_STATUS.SUCCESS) {
        return null;
      }

      return setStage(ONBOARDING_FORM_STAGE.STAGE_2FA_METHOD);
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const onClickBack = () => {
    setStage(ONBOARDING_FORM_STAGE.STAGE_1);
  };

  return (
    <Card className="onboarding-card">
      <div className="onboarding-card__left-box">
        <div className="onboarding-card__left-box__content">
          <img src={thunderIcon} alt="thunder auto" />
          <p className="onboarding-card__left-box__content-text">
            {translate(messages.stageTwoLeftBoxContent)}
          </p>
        </div>
      </div>
      <div className="onboarding-card__inner">
        <h1>{translate(messages.stageTwoTitle)}</h1>
        <Form onSubmit={onSubmit}>
          <Form.Item>
            <Input
              block
              name="fullname"
              label={translate(messages.stageTwoInputFullNameLabel)}
              placeholder={translate(messages.stageTwoInputFullNamePlaceholder)}
              onChange={(e) => handleChange(e, 'fullname')}
              value={fullname}
              error={errors.fullname}
              data-test="onboarding-stage-two-input-fullname"
            />
          </Form.Item>
          <Form.Item>
            <Input.Email
              block
              label={translate(messages.stageTwoInputEmailLabel)}
              onChange={(e) => handleChange(e, 'email')}
              value={email}
              error={errors.email}
              data-test="onboarding-stage-two-input-email"
            />
          </Form.Item>

          <PhoneInput onChange={onChangePhone} />

          <Form.Item>
            <Form.Label text={translate(messages.stageTwoInputLanguageLabel)} />
            <LanguageSelect
              value={lang}
              onSelect={(e) => setFieldValue('lang', e, false)}
              error={errors.lang}
            />
          </Form.Item>

          <CardWithLogo.Footer leftButtonOnClick={onClickBack} rightButtonType="submit" />
        </Form>
      </div>
    </Card>
  );
};

StageTwo.propTypes = {
  setStage: PropTypes.func,
  finishOnboarding: PropTypes.func,
};

export default StageTwo;
