import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'antd';
import classNames from 'classnames';
import Icon from 'ui/Icon';
import { translate } from 'utils/index';

import { messages } from 'pages/Editor/messages';
import './triggerMenu.scss';

const TriggerMenu = ({ active, addQuestion, createNewTrigger }) => {
  const menuItems = [
    createNewTrigger && {
      key: 'trigger-menu-item-create-followup',
      onClick: createNewTrigger,
      label: translate(messages.createNewFollowUpQuestion),
      'data-test': 'trigger-menu-item-create-followup',
    },
    addQuestion && {
      key: 'trigger-menu-item-add-question',
      onClick: addQuestion,
      label: translate(messages.addNewQuestion),
      'data-test': 'trigger-menu-item-add-question',
    },
  ];

  return (
    <Dropdown
      menu={{ items: menuItems, className: 'editor-trigger-menu__menu' }}
      trigger="click"
      className="editor-trigger-menu"
    >
      <Button
        data-test={`editor-trigger-menu-button-${active ? 'active' : 'inactive'}`}
        className="editor-trigger-menu__button"
        type="link"
      >
        <Icon
          className={classNames({ 'editor-trigger-menu__icon--active': active })}
          icon="trigger"
        />
      </Button>
    </Dropdown>
  );
};

TriggerMenu.propTypes = {
  active: PropTypes.bool,
  addQuestion: PropTypes.func,
  createNewTrigger: PropTypes.func,
};

export default TriggerMenu;
