import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

import ROUTES from 'constants/routes';
import { ScreenSize } from 'constants/screenSize';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useTheme } from 'hooks/theme';
import { translate } from 'utils/index';
import { messages } from '../messages';

// COMPONENTS:
import Tag from 'ui/Tag';

// STYLE:
import './assessments.scss';

const Assessments = ({ assessments = [] }) => {
  const isVendorSelected = useIsMyVendorsTabSelected();
  const { screenSize } = useTheme();

  const navigate = useAppNavigate();

  const firstAssessments = [ScreenSize.mobile, ScreenSize.desktop].includes(screenSize)
    ? assessments.length === 1
      ? assessments
      : []
    : assessments.slice(0, 2);

  const moreAssessments = useMemo(() => {
    return (
      <div className="more-assessments-popover-container">
        {assessments.map(([id, name], index) => {
          return (
            <Tag
              key={`tag_${index}`}
              className="assessment-tag"
              id={id}
              color="blue"
              name={name}
              onClick={(event) => {
                handleClickAssessment(id);
                event.stopPropagation();
              }}
            />
          );
        })}
      </div>
    );
  }, [assessments]);

  const handleClickAssessment = (id) => {
    navigate(isVendorSelected ? ROUTES.VENDOR_ASSESSMENT : ROUTES.CUSTOMER_ASSESSMENT, {
      assessmentId: id,
    });
  };

  const onPopoverClick = (event) => {
    event.stopPropagation();
  };

  if (assessments.length === 0) {
    return (
      <div className="recent-page-vendors-assessments__assessments-placeholder">
        {translate(messages.noAssessments)}
      </div>
    );
  }

  return (
    <div className="recent-page-vendors-assessments">
      {firstAssessments.map(([id, name]) => (
        <Tag
          key={id}
          className="assessment-tag"
          color="blue"
          name={name}
          textEllipsis
          onClick={(event) => {
            handleClickAssessment(id);
            event.stopPropagation();
          }}
        />
      ))}
      {[ScreenSize.mobile, ScreenSize.desktop].includes(screenSize) && assessments.length > 1 && (
        <Popover trigger="hover" content={moreAssessments} onClick={onPopoverClick}>
          <div>
            <Tag
              className="assessment-tag"
              color="blue"
              name={`+${assessments.length.toString()}`}
            />
          </div>
        </Popover>
      )}
      {[ScreenSize.desktopMedium, ScreenSize.desktopLarge].includes(screenSize) &&
        assessments.length > 2 && (
          <Popover trigger="hover" content={moreAssessments} onClick={onPopoverClick}>
            <div>
              <Tag
                className="assessment-tag"
                color="blue"
                name={`+${(assessments.length - 2).toString()}`}
              />
            </div>
          </Popover>
        )}
    </div>
  );
};

Assessments.propTypes = {
  assessments: PropTypes.array,
};
export default Assessments;
