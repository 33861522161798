import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// COMPONENTS
import Icon from 'ui/Icon';
import Button from 'ui/Button';
import AddFinding from 'pages/Assessment/addFinding';
import { FindingIcon } from 'components/Icons';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'components/UpstreamAI/messages';
import { setDueDeltaToRecommendations } from 'components/UpstreamAI/lib';
import ROUTES from 'constants/routes';
import { useAppNavigate } from 'hooks/navigation';

// REDUX
import { selectAssessment } from 'pages/Assessment/selectors';
import { selectFinding } from 'pages/Assessment/finalized/findings/reducers';

// STYLE
import './auditControlContent.scss';

function AuditControlContent({ overview, findings, control, setUpstreamAiPopover }) {
  const { impact, probability, id: assessmentId } = useSelector(selectAssessment);
  const appNavigate = useAppNavigate();
  const dispatch = useDispatch();
  const [showAddFinding, setShowAddFinding] = useState(false);
  const [selectedFinding, setSelectedFinding] = useState(null);

  const handleAddFinding = (finding) => {
    setUpstreamAiPopover(false);
    const updatedRecommendations = setDueDeltaToRecommendations(finding?.recommendations);
    setSelectedFinding(() => {
      return finding ? { ...finding, recommendations: updatedRecommendations } : null;
    });
    setShowAddFinding(true);
  };

  const handleFindingOnClick = async (finding) => {
    if (!finding.added) return;
    await dispatch(selectFinding(finding.id));
    appNavigate(ROUTES.VENDOR_ASSESSMENT_FINDINGS, { assessmentId });
  };

  const findingsList = useMemo(() => {
    if (!findings?.length) {
      return <div className="audit-control-content__finding">{translate(messages.noFindings)}</div>;
    }
    return findings?.map((finding) => {
      const findingClassNames = classNames('audit-control-content__finding', {
        'audit-control-content__finding-clickable': finding.added,
      });

      return (
        <div
          className={findingClassNames}
          onClick={() => handleFindingOnClick(finding)}
          key={finding.id}
        >
          <div className="audit-control-content__finding-description">{finding.description}</div>
          <div className="audit-control-content__finding-footer">
            <div className="audit-control-content__finding-status-bar">
              <div className="audit-control-content__finding-impact">
                <h5>{translate(messages.impact)}: </h5>
                <span
                  className={`audit-control-content__finding-impact--${
                    finding?.impact && impact[finding?.impact - 1].key.toLowerCase()
                  }`}
                >
                  {finding?.impact ? impact[finding?.impact - 1].key : null}
                </span>
              </div>
              <div className="audit-control-content__finding-probability">
                <h5>{translate(messages.probability)}: </h5>
                <span
                  className={`audit-control-content__finding-probability--${
                    finding?.probability && probability[finding?.probability - 1].key.toLowerCase()
                  }`}
                >
                  {finding?.probability ? probability[finding?.probability - 1].key : null}
                </span>
              </div>
            </div>
            {finding.added ? (
              <Icon icon={'check'} className="audit-control-content__added-icon-finding" />
            ) : (
              <Button
                beforeIcon="plus"
                color="blue"
                link
                className="audit-control-content__add-finding"
                size="xs"
                onClick={() => handleAddFinding(finding)}
              >
                {translate(messages.addFinding)}
              </Button>
            )}
          </div>
        </div>
      );
    });
  }, [findings]);

  return (
    <>
      <div className="audit-control-content">
        <div className="audit-control-content__overview">
          <FindingIcon />
          <p>{overview}</p>
        </div>
        <div className="audit-control-content__findings">
          <div className="audit-control-content__findings-title">
            <Icon icon="flag" />
            <h5>{translate(messages.findings)}:</h5>
          </div>
          <div className="audit-control-content__findings-list">{findingsList}</div>
          <Button
            width={128}
            beforeIcon="plus"
            color="blue"
            link
            className="audit-control-content__add-finding"
            size="xs"
            onClick={() => handleAddFinding(null)}
          >
            {translate(messages.addNewFinding)}
          </Button>
        </div>
      </div>
      {showAddFinding && (
        <AddFinding
          control={control}
          visible={showAddFinding}
          toggleModal={setShowAddFinding}
          finding={selectedFinding}
          audit={selectedFinding}
          setAuditPopoverVisible={setUpstreamAiPopover}
        />
      )}
    </>
  );
}

AuditControlContent.propTypes = {
  overview: PropTypes.string,
  findings: PropTypes.array,
  control: PropTypes.object,
  setUpstreamAiPopover: PropTypes.func,
};

export default AuditControlContent;
