import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import { useTheme } from 'hooks/theme';
import { FilterIcon } from 'components/Icons';
import { translate } from 'utils/index';
import { messages } from '../messages';
import classNames from 'classnames';

import './index.scss';

const FiltersButton = ({ onClick, filtersCount, isOpen }) => {
  const { colorTheme } = useTheme();

  return (
    <Button
      onClick={onClick}
      data-test="filters-button"
      color={colorTheme}
      size="sm"
      outline={true}
      className={classNames('filters-button', { 'filters-button--active': isOpen })}
    >
      <FilterIcon /> {translate(messages.filtersButton)}{' '}
      {filtersCount ? (
        <span
          className={`filters-button__filters-count filters-button__filters-count--color-${colorTheme}`}
        >
          {filtersCount}
        </span>
      ) : (
        ''
      )}
    </Button>
  );
};

FiltersButton.propTypes = {
  onClick: PropTypes.func,
  filtersCount: PropTypes.number,
  isOpen: PropTypes.bool,
};

export default FiltersButton;
