export const messages = {
  groupOfAssessments: {
    key: 'Share showcase tab customer.groupOfAssessments',
    defaultMessage: 'Group of assessments',
  },
  assessmentRequired: {
    key: 'Share showcase tab customer.assessmentRequired',
    defaultMessage: 'Assessments required',
  },
  youCanSelectMoreThanOne: {
    key: 'Share showcase tab customer.youCanSelectMoreThanOne',
    defaultMessage: 'You can select more than one',
  },
  saveAndClose: {
    key: 'Share showcase tab customer.saveAndClose',
    defaultMessage: 'Save and close',
  },
  monthly: {
    key: 'Share showcase tab customer.monthly',
    defaultMessage: 'Monthly',
  },
  never: {
    key: 'Share showcase tab customer.never',
    defaultMessage: 'Never',
  },
  quarterly: {
    key: 'Share showcase tab customer.quarterly',
    defaultMessage: 'Quarterly',
  },
  semiAnnually: {
    key: 'Share showcase tab customer.semiAnnually',
    defaultMessage: 'Semi-annually',
  },
  annually: {
    key: 'Share showcase tab customer.annually',
    defaultMessage: 'Annually',
  },
  autoAssessmentPeriod: {
    key: 'Share showcase tab customer.autoAssessmentPeriod',
    defaultMessage: 'Auto assessment period',
  },
  untitledAssessment: {
    key: 'Share showcase tab customer.untitledAssessment',
    defaultMessage: 'Untitled Assessment',
  },
  createNewCustomer: {
    key: 'Share showcase tab customer.createNewCustomer',
    defaultMessage: 'Create new customer',
  },
  selectShowcase: {
    key: 'Share showcase tab customer.selectShowcase',
    defaultMessage: 'Select showcase',
  },
  sharedUntil: {
    key: 'Share showcase tab customer.sharedUntil',
    defaultMessage: 'Shared until',
  },
  selectShowcaseDescription: {
    key: 'Share showcase tab customer.selectShowcaseDescription',
    defaultMessage: 'Please select from the following list, you can select more than one showcase',
  },
  showcaseEmptyInfo: {
    key: 'Share showcase tab customer.showcaseEmptyInfo',
    defaultMessage: "You don't have any showcases",
  },
};

export default {
  prefix: 'Share showcase tab customer',
  messages,
};
