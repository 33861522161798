import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getProfileOrganizations } from 'api/organizations';
import { getOrganizationIdFromEmailUrl, getOrganizationIndex } from 'utils/organizations';
import { setCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY } from 'utils/auth';
import { useAppNavigate } from 'hooks/navigation';
import ROUTES from 'constants/routes';

const EmailLinksRedirect = () => {
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();

  useEffect(() => {
    dispatch(getProfileOrganizations()).then(({ payload: organizations }) => {
      const orgId = getOrganizationIdFromEmailUrl();
      setCookie(ORG_ID_AUTH_KEY, orgId);

      const { 2: vendorOrCustomer, 4: assessmentId } = window.location.pathname.split('/');

      const path =
        vendorOrCustomer === 'customer' ? ROUTES.CUSTOMER_ASSESSMENT : ROUTES.VENDOR_ASSESSMENT;

      const index = getOrganizationIndex({ organizations, orgId });

      appNavigate(path, { organization: index, assessmentId });
    });
  }, []);

  return null;
};

export default EmailLinksRedirect;
