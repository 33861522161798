import React from 'react';
import { useSelector } from 'react-redux';

import { translate } from 'utils/index';
import Form from 'ui/Form';
import { Sorter } from 'ui/Table/sorter';
import Table from 'ui/Table';
import Icon from 'ui/Icon';
import AzureIcon from 'assets/svgs/azure-icon.svg';
import AwsIcon from 'assets/svgs/aws-icon.svg';
import RiskReconIcon from 'assets/svgs/risk-recon-icon.svg';
import FindingsCloudMonitoringIcon from 'assets/svgs/findings-cloud-monitoring-icon.svg';

import { Apps } from 'constants/apps';

import { selectDefaultApps, selectAppsLoading } from 'pages/Organization/selectors';
import { messages } from 'pages/Organization/messages';
import './defaultApps.scss';

const icons = {
  [Apps.azure]: AzureIcon,
  [Apps.aws]: AwsIcon,
  [Apps.riskRecon]: RiskReconIcon,
  [Apps.cloudMonitoring]: FindingsCloudMonitoringIcon,
};

const DefaultApps = () => {
  const defaultApps = useSelector(selectDefaultApps);
  const loading = useSelector(selectAppsLoading);

  const defaultAppsColumns = [
    {
      title: translate(messages.orgEditTabColTitleName),
      key: 'name',
      dataIndex: 'name',
      render: (name, app) => {
        return (
          <div>
            {icons[app.id] && (
              <img className="apps-tab__service-icon" src={icons[app.id]} alt={name} />
            )}
            {name}
          </div>
        );
      },
      sorter: {
        compare: Sorter.STRING,
      },
      width: 300,
    },
    {
      title: translate(messages.orgEditAppsStatus),
      key: 'status',
      dataIndex: 'status',
      width: 100,
      render: () => {
        return <Icon className="apps-tab__status-icon" icon="ok2" />;
      },
    },
    {
      title: translate(messages.orgEditAppsConfiguration),
      key: 'configuration',
      dataIndex: 'configuration',
      render: () => {
        return (
          <div className="apps-tab__table-configuration">
            {translate(messages.orgEditAppsNoExtraConfiguration)}
          </div>
        );
      },
    },
    {
      title: translate(messages.orgEditAppsCredits),
      key: 'credits',
      dataIndex: 'token',
      width: 100,
      render: (token, app) => {
        if (app.id === Apps.riskRecon) {
          return <>{token?.credits}</>;
        }

        return null;
      },
    },
  ];

  const defaultAppsDataSource = (defaultApps || []).map((app) => ({
    ...app,
    key: app.id,
  }));

  return (
    <div className="apps-tab__default-apps-table-container">
      <Form.Label
        className="apps-tab__table-header"
        text={translate(messages.orgEditAppsDefaultApps)}
      />
      <div className="apps-tab__table-container">
        <Table
          className="apps-tab__table"
          data-test="organization-settings-default-apps-table"
          dataSource={defaultAppsDataSource}
          columns={defaultAppsColumns}
          pagination={false}
          loading={loading}
          rowKey="default-apps-rowKey"
        />
      </div>
    </div>
  );
};

export default DefaultApps;
