import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import PropTypes from 'prop-types';

import Form from 'ui/Form';
import FileUpload from 'ui/FileUpload';
import FileList from 'ui/FileList';
import Loader from 'ui/Loader';
import { translate } from 'utils/index';
import { messages } from 'pages/Editor/messages';
import { removeTemplateReport, uploadTemplateReport } from 'api/editor';
import { API_STATUS } from 'constants/api';
import { useFileUploadProcess } from 'components/FileUploadIndicator/useFileUploadProcess';

const UploadReport = ({
  templateId,
  reportFromTemplate,
  themeColor,
  isTemplate,
  disabled = false,
}) => {
  const [uploadedFile, setUploadedFile] = useState({
    load: false,
    file: [],
  });

  const { abortController, startUploading, finishUploding, renderConfirmationModal } =
    useFileUploadProcess();

  useEffect(() => {
    if (reportFromTemplate)
      setUploadedFile((state) => ({
        ...state,
        file: [{ title: reportFromTemplate.name, id: reportFromTemplate.id }].filter(
          (file) => file.title,
        ),
      }));
  }, [reportFromTemplate]);

  const handleUpload = async (acceptedFiles) => {
    setUploadedFile((state) => ({ ...state, load: true }));
    startUploading();

    const { templateName } = await uploadTemplateReport(acceptedFiles, templateId, abortController);
    finishUploding();
    if (templateName) {
      return setUploadedFile({ file: [{ title: templateName }], load: false });
    }
    return notification.error({ message: translate(messages.settingsUploadFileError) });
  };

  const handleRemove = async () => {
    setUploadedFile((state) => ({ ...state, load: true }));
    const { status } = await removeTemplateReport(templateId);
    if (status === API_STATUS.SUCCESS) {
      setUploadedFile({ file: [], load: false });
    }
  };

  return (
    <Form.Item>
      <Form.Label
        className="editor-settings__report-label"
        text={translate(messages.settingsReport)}
      />
      {uploadedFile.load && <Loader />}
      {uploadedFile.file.length > 0 && (
        <FileList
          isTemplate={isTemplate}
          files={uploadedFile.file}
          handleRemove={handleRemove}
          disabled={disabled}
        />
      )}
      <FileUpload
        className="editor-settings__report-upload-button"
        data-test="upload-report-upload-button"
        text={translate(messages.settingsUploadReport)}
        handleUpload={handleUpload}
        size="sm"
        accept=".doc, .docx"
        buttonColor={themeColor}
        disabled={disabled}
        loading={uploadedFile?.load || false}
      />
      {renderConfirmationModal()}
    </Form.Item>
  );
};

UploadReport.propTypes = {
  templateId: PropTypes.string,
  reportFromTemplate: PropTypes.object,
  themeColor: PropTypes.string,
  isTemplate: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default UploadReport;
