import { generatePath } from 'react-router';
import ROUTES, { ROUTES_TAB } from 'constants/routes';

export const urlRedirectComposer = (params, to) => {
  const { token, assessmentId, organization, organizationId } = params;

  switch (to) {
    case ROUTES.RESET_PASSWORD: {
      return generatePath(ROUTES.RESET_PASSWORD, { token });
    }
    case ROUTES.ACTIVATION_ASSESSMENT: {
      return generatePath(ROUTES.ACTIVATION_ASSESSMENT, { token, assessmentId });
    }
    case ROUTES.VENDOR_ASSESSMENT: {
      return generatePath(ROUTES.VENDOR_ASSESSMENT, { assessmentId, organization });
    }
    case ROUTES.LOGIN: {
      // Specific use case for the old assign to organization route
      return generatePath(`${ROUTES.LOGIN}/*`, {
        '*': `?organization=${organizationId}`,
      });
    }
    default:
      return null;
  }
};

export const transformPathToVendor = (path) => {
  return path.replace(ROUTES_TAB.CUSTOMER, ROUTES_TAB.VENDOR);
};

export const transformPathToCustomer = (path) => {
  return path.replace(ROUTES_TAB.VENDOR, ROUTES_TAB.CUSTOMER);
};
