import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import { useTheme } from 'hooks/theme';
import { translate } from 'utils/index';
import { Apps } from 'constants/apps';
import AwsIcon from 'assets/svgs/aws-icon-2.svg';
import AzureIcon from 'assets/svgs/azure-icon.svg';
import { messages } from 'pages/Assessment/messages';
import { selectCloudMonitoringResultCategory } from 'pages/Assessment/selectors';
import { selectCategory } from 'pages/Assessment/reducers';
import { reviewCloudMonitoring } from 'api/assessment';

import './cloudMonitoringReviewPopup.scss';

const CloudMonitoringReviewPopup = ({ open, setOpen, submit }) => {
  const dispatch = useDispatch();
  const { colorTheme } = useTheme();
  const resultCategory = useSelector(selectCloudMonitoringResultCategory);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setSubmitLoading(true);
    await submit();
    setOpen(false);
    setSubmitLoading(false);
  };
  const goToResultCategory = async () => {
    setLoading(true);
    await dispatch(reviewCloudMonitoring({ categoryId: resultCategory.id })).unwrap();
    dispatch(selectCategory(resultCategory.id));
    setOpen(false);
    setLoading(false);
  };

  const title = (
    <span>
      <img
        className="assessment-finalize-cloud-monitoring-review-popup__icon-title"
        src={resultCategory.app === Apps.azure ? AzureIcon : AwsIcon}
        alt="Cloud Monitoring"
      />
      {translate(messages.submitWithoutCloudMonitoringReview)}
    </span>
  );

  const footer = [
    <Button
      data-test="modal-button-accept"
      key="ACCEPT_BUTTON"
      className="ui-modal__footer-ok-button"
      size="sm"
      color={colorTheme}
      loading={submitLoading}
      onClick={onSubmit}
    >
      {translate(messages.submitNow)}
    </Button>,
    <Button
      data-test="modal-button-cancel"
      key="CANCEL_BUTTON"
      className="ui-modal__footer-cancel-button"
      size="sm"
      color="white"
      loading={loading}
      onClick={goToResultCategory}
    >
      {translate(messages.reviewFirst)}
    </Button>,
  ];

  return (
    <Modal
      className="assessment-finalize-cloud-monitoring-review-popup"
      open={open}
      onOk={onSubmit}
      onCancel={() => setOpen(false)}
      width={650}
      title={title}
      footer={footer}
    >
      <p className="assessment-finalize-cloud-monitoring-review-popup__description">
        {translate(messages.submitWithoutCloudMonitoringReviewDescription)}
      </p>
    </Modal>
  );
};

CloudMonitoringReviewPopup.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  submit: PropTypes.func,
};

export default CloudMonitoringReviewPopup;
