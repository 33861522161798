import React from 'react';
import PropTypes from 'prop-types';
import { sanitizer } from 'utils/sanitizer';
import { boldString } from 'utils/strings';

const BoldedText = ({ find, text }) => {
  const str = boldString(text, find);

  return <div dangerouslySetInnerHTML={{ __html: sanitizer(str) }}></div>;
};

BoldedText.propTypes = {
  find: PropTypes.string,
  text: PropTypes.string,
};

export default BoldedText;
