// cra env:
export const ENV = {
  APP_URL: process.env.REACT_APP_URL,
  API_URL: process.env.REACT_APP_API_URL,
  API_URLS: process.env.REACT_APP_API_URLS,
  NODE_ENV: process.env.NODE_ENV,
  TRUSTED_USERS: process.env.REACT_APP_TRUSTED_USERS,
  ZAPIER_HOOK: process.env.REACT_APP_ZAPIER_HOOK,
  PUSHER_APP_KEY: process.env.REACT_APP_PUSHER_APP_KEY,
  APPCUES_ID: process.env.REACT_APP_APPCUES_ID,
  STONLY_ID: process.env.REACT_APP_STONLY_ID,
  HUBSPOT_ID: process.env.REACT_APP_HUBSPOT_ID,
  ESLINT_NO_DEV_ERRORS: process.env.REACT_APP_ESLINT_NO_DEV_ERRORS,
  MIXPANEL_KEY: process.env.REACT_APP_MIXPANEL_KEY,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  TESTING_DOMAIN: process.env.REACT_APP_TESTING_DOMAIN,
  DATADOG_APPLICATION_ID: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
};

// vite env: TO BE EXPOSED WHEN VITE IS USED
// export const ENV = {
//   APP_URL: import.meta.env.VITE_URL,
//   API_URL: import.meta.env.VITE_API_URL,
//   API_URLS: import.meta.env.VITE_API_URLS,
//   NODE_ENV: process.env.NODE_ENV || import.meta.env.DEV ? 'development' : 'production',
//   TRUSTED_USERS: import.meta.env.VITE_TRUSTED_USERS,
//   ZAPIER_HOOK: import.meta.env.VITE_ZAPIER_HOOK,
//   PUSHER_APP_KEY: import.meta.env.VITE_PUSHER_APP_KEY,
//   APPCUES_ID: import.meta.env.VITE_APPCUES_ID,
//   STONLY_ID: import.meta.env.VITE_STONLY_ID,
//   HUBSPOT_ID: import.meta.env.VITE_HUBSPOT_ID,
//   ESLINT_NO_DEV_ERRORS: import.meta.env.VITE_ESLINT_NO_DEV_ERRORS,
//   MIXPANEL_KEY: import.meta.env.VITE_MIXPANEL_KEY,
//   GOOGLE_CLIENT_ID: import.meta.env.VITE_GOOGLE_CLIENT_ID,
//   TESTING_DOMAIN: import.meta.env.VITE_TESTING_DOMAIN,
//   DATADOG_APPLICATION_ID: import.meta.env.VITE_DATADOG_APPLICATION_ID,
//   DATADOG_CLIENT_TOKEN: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
// };
