export const messages = {
  shareShowcase: {
    key: 'AssignShowcase.shareShowcase',
    defaultMessage: 'Share showcase',
  },
  showcaseType: {
    key: 'AssignShowcase.showcaseType',
    defaultMessage: 'Showcase type',
  },
  showcaseTypeDescription: {
    key: 'AssignShowcase.showcaseTypeDescription',
    defaultMessage: 'Please select from the following list, you may select more than one showcase.',
  },
  createNewAssessment: {
    key: 'AssignShowcase.createNewAssessment',
    defaultMessage: 'CREATE NEW SHOWCASE',
  },
  or: {
    key: 'AssignShowcase.or',
    defaultMessage: 'or',
  },
  sharedUntil: {
    key: 'AssignShowcase.sharedUntil',
    defaultMessage: 'Shared until',
  },
  customers: {
    key: 'AssignShowcase.customers',
    defaultMessage: 'customer(s)',
  },
  self: {
    key: 'AssignShowcase.self',
    defaultMessage: 'self',
  },
  pleaseSelect: {
    key: 'AssignShowcase.pleaseSelect',
    defaultMessage: 'Please select',
  },
  performedBy: {
    key: 'AssignShowcase.performedBy',
    defaultMessage: 'Performed by',
  },
  selectCustomer: {
    key: 'AssignShowcase.selectCustomer',
    defaultMessage: 'Select customer',
  },
  customerText: {
    key: 'AssignShowcase.customerText',
    defaultMessage: 'you can select more than one',
  },
  cancel: {
    key: 'AssignShowcase.cancel',
    defaultMessage: 'CANCEL',
  },
  share: {
    key: 'AssignShowcase.share',
    defaultMessage: 'SHARE',
  },
  showcaseRequired: {
    key: 'AssignShowcase.showcaseRequired',
    defaultMessage: 'Showcase is required',
  },
  customerRequired: {
    key: 'AssignShowcase.customerRequired',
    defaultMessage: 'Customer is required',
  },
  createNewCustomer: {
    key: 'AssignShowcase.createNewCustomer',
    defaultMessage: 'CREATE NEW CUSTOMER',
  },
};

export default {
  prefix: 'AssignShowcase',
  messages,
};
