import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import {
  cancelCloudAnswers,
  generateCloudAnswers,
  getAssessment,
  saveControl,
  saveControlWithTriggers,
} from 'api/assessment';
import { getCategoryUnreadCount } from 'api/messenger';
import { Apps } from 'constants/apps';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { translate } from 'utils/index';
import { messages } from 'pages/Assessment/messages';
import Icon from 'ui/Icon';
import { calculateErrors } from 'pages/Assessment/finalize/lib';
import { setErrors } from 'pages/Assessment/reducers';

import Control from './control';
import ActivateCloudMonitoring from './activateCloudMonitoring';
import { selectCategory, selectAssessment, selectIsCloudMonitoringTriggered } from './selectors';
import { getCategoryControlsWithTriggered, triggerControls } from './lib';
import { useRole } from 'hooks/useRole';
import { ControlType } from 'constants/controlType';
import { API_STATUS } from 'constants/api';
import {
  mixpanelAssessmentAppActivated,
  mixpanelTrackControlAnswered,
  mixpanelTrackControlReviewed,
} from 'utils/mixpanel';
import { ControlStatusEnum } from 'constants/controlStatus';
import './controls.scss';

const cloudMonitoringMainControlId = '60b5dd54-998b-496d-a28f-72a782e29258';

const Controls = ({ showcaseAssessmentPreview }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const category = useSelector(selectCategory);
  const assessment = useSelector(selectAssessment);
  const { isRestrictedBoSoViewerRole } = useRole();
  const [controls, setControls] = useState(category.controls);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const [activateCloudMonitoringLoading, setActivateCloudMonitoringLoading] = useState(false);
  const [cancelCloudMonitoringLoading, setCancelCloudMonitoringLoading] = useState(false);
  const isCloudMonitoringTriggered = useSelector(selectIsCloudMonitoringTriggered);
  const user = useSelector((state) => state?.profile?.user);

  useEffect(() => {
    setControls(category.controls);
    if (category?.id && assessment?.id) {
      dispatch(getCategoryUnreadCount({ categoryId: category.id, assessmentId: assessment.id }));
    }
  }, [category]);

  const onAnswerChange = async (value, controlId) => {
    setControls((state) => {
      const newControls = state.map((control) =>
        control.id === controlId ? { ...control, answer: value } : control,
      );
      const newControl = newControls.find((control) => control.id === controlId);
      const categoryTriggeredControls = triggerControls(newControl, newControls);
      return getCategoryControlsWithTriggered(newControls, categoryTriggeredControls);
    });
    const control = controls.find((control) => control.id === controlId);

    // if it's a file control, no need to call setAssessment api
    if (control['control_answer_type'] === ControlType.FileUpload) {
      return;
    }

    const { payload } = await dispatch(
      saveControlWithTriggers({
        control: { ...control, answer: value },
        _assessment: {
          ...assessment,
          categories: assessment.categories.map((currentCategory) => {
            if (category.id === currentCategory.id) {
              return { ...currentCategory, controls };
            }
            return currentCategory;
          }),
        },
      }),
    );

    if (payload?.status === API_STATUS.SUCCESS) {
      mixpanelTrackControlAnswered({
        relation: assessment?.customerId,
        assessmentName: assessment?.title,
        streamDirection: isMyVendorsTabSelected ? 'downstream' : 'upstream',
        originalControl: control?.title,
        assessmentStatus: assessment?.status,
        assessmentProgress: assessment?.total_score?.completion,
      });
    }
  };

  const onChange = async (field, value, controlId, categoryId) => {
    const newControls = controls.map((control) =>
      control.id === controlId ? { ...control, [field]: value } : control,
    );
    setControls(newControls);

    const payload = {
      categoryId,
      control: {
        id: controlId,
        [field]: value,
      },
    };

    const { status } = await dispatch(saveControl(payload)).unwrap();

    if (field === 'status' && status === API_STATUS.SUCCESS) {
      mixpanelTrackControlReviewed({
        relation: assessment?.customerId,
        assessmentName: assessment?.title,
        originalControl: newControls.find((control) => control.id === controlId)?.title,
        assignedOrganizationName: assessment?.customerName,
        assigneeOrganizationName: user?.current_organization?.label,
        type: ControlStatusEnum[value],
        previousType:
          ControlStatusEnum[controls.find((control) => control.id === controlId)?.status],
      });
    }
  };

  const cloudMonitoringControls = (controls || []).filter(
    (control) =>
      control.apps &&
      (control.apps.includes(Apps.aws) || control.apps.includes(Apps.azure)) &&
      control.base_id !== cloudMonitoringMainControlId,
  );

  const enabledCloudMonitoringControls = cloudMonitoringControls.filter(({ enabled }) => enabled);
  const cloudMonitoringControlsIds = cloudMonitoringControls.map((control) => control.id);
  const showCloudMonitoringActivation = !isMyVendorsTabSelected && !assessment.finalized;
  const isCloudMonitoringCategory = [Apps.aws, Apps.azure].includes(category.app);

  const activateCloudMonitoring = async (control) => {
    const payload = { categories: [{ id: category.id, controls: cloudMonitoringControls }] };
    const errors = calculateErrors(payload);
    if (errors.errorsCount !== 0) {
      await dispatch(setErrors(payload));
      return;
    }

    setActivateCloudMonitoringLoading(true);
    const {
      payload: { status },
    } = await dispatch(generateCloudAnswers());
    if (status === API_STATUS.SUCCESS) {
      mixpanelAssessmentAppActivated({
        relation: assessment?.customerId,
        assessmentName: assessment?.title,
        assigneeOrganizationName: user?.current_organization?.label,
        type: control.answer == 2 ? 'azure' : 'aws',
      });
    }

    await dispatch(getAssessment({ id: params.assessmentId }));
    setActivateCloudMonitoringLoading(false);
  };

  const cloudMonitoringMainControl = (controls || []).find(
    ({ base_id }) => base_id === cloudMonitoringMainControlId,
  );

  const cancelCloudMonitoring = async () => {
    setCancelCloudMonitoringLoading(true);
    await dispatch(cancelCloudAnswers(Apps.aws));
    await dispatch(getAssessment({ id: params.assessmentId }));
    setCancelCloudMonitoringLoading(false);
  };

  const getNextEnabledControlApps = (controlIndex) => {
    if (Number(cloudMonitoringMainControl.answer) === 1) {
      return [Apps.aws];
    } else if (Number(cloudMonitoringMainControl.answer) === 2) {
      return [Apps.azure];
    }
  };

  if (isCloudMonitoringCategory && isMyVendorsTabSelected) {
    return (
      <div className="assessment-controls">
        {(controls || [])
          .filter(({ enabled }) => enabled)
          .filter(({ shareResponse }) => shareResponse)
          .map((control, index) => {
            return (
              <Control
                key={control.id}
                control={control}
                disabled={isRestrictedBoSoViewerRole}
                onChange={onChange}
                onAnswerChange={onAnswerChange}
                index={index}
                showcaseAssessmentPreview={showcaseAssessmentPreview}
                getNextEnabledControlApps={getNextEnabledControlApps}
              />
            );
          })}
        <div className="assessment-controls__not-shared">
          <div className="assessment-controls__not-shared-title">
            <Icon className="assessment-controls__not-shared-title-icon" icon="not-shared" />
            {translate(messages.additionalControlsNotShared)}
          </div>
          {(controls || [])
            .filter(({ enabled }) => enabled)
            .filter(({ shareResponse }) => !shareResponse)
            .map((control, index) => {
              return (
                <Control
                  key={control.id}
                  control={control}
                  disabled={isRestrictedBoSoViewerRole}
                  onChange={onChange}
                  onAnswerChange={onAnswerChange}
                  index={index}
                  showcaseAssessmentPreview={showcaseAssessmentPreview}
                  notShared
                  getNextEnabledControlApps={getNextEnabledControlApps}
                />
              );
            })}
        </div>
      </div>
    );
  }

  return (
    <div className="assessment-controls">
      {(controls || [])
        .filter(
          ({ id }) =>
            !showCloudMonitoringActivation ||
            (showCloudMonitoringActivation && !cloudMonitoringControlsIds.includes(id)),
        )
        .filter(({ enabled }) => enabled)
        .map((control, index) => {
          if (control.base_id === cloudMonitoringMainControlId && showCloudMonitoringActivation) {
            return (
              <>
                <Control
                  key={control.id}
                  control={control}
                  disabled={isRestrictedBoSoViewerRole || isCloudMonitoringTriggered}
                  onChange={onChange}
                  onAnswerChange={onAnswerChange}
                  index={index}
                  showcaseAssessmentPreview={showcaseAssessmentPreview}
                  getNextEnabledControlApps={getNextEnabledControlApps}
                />
                {enabledCloudMonitoringControls.length > 0 && (
                  <div className="assessment-controls__cloud-monitoring-controls">
                    <div className="assessment-controls__cloud-monitoring-controls-title">
                      <Icon
                        className="assessment-controls__cloud-monitoring-controls-title-icon"
                        icon="info"
                      />
                      <span>{translate(messages.afterTriggeringThisApp)}</span>
                    </div>
                    <div className="assessment-controls__cloud-monitoring-controls__wrapper">
                      {enabledCloudMonitoringControls.map((cloudMonitoringControl, index2) => (
                        <Control
                          key={cloudMonitoringControl.id}
                          control={cloudMonitoringControl}
                          disabled={
                            isRestrictedBoSoViewerRole ||
                            activateCloudMonitoringLoading ||
                            isCloudMonitoringTriggered
                          }
                          onChange={onChange}
                          onAnswerChange={onAnswerChange}
                          index={index + index2 + 1}
                          showcaseAssessmentPreview={showcaseAssessmentPreview}
                          getNextEnabledControlApps={getNextEnabledControlApps}
                        />
                      ))}
                    </div>
                    <ActivateCloudMonitoring
                      activate={() => activateCloudMonitoring(control)}
                      cancel={cancelCloudMonitoring}
                      activationLoading={activateCloudMonitoringLoading}
                      cancelLoading={cancelCloudMonitoringLoading}
                      isAzure={control.answer == 2}
                    />
                  </div>
                )}
              </>
            );
          }

          return (
            <Control
              key={control.id}
              control={control}
              disabled={isRestrictedBoSoViewerRole}
              onChange={onChange}
              onAnswerChange={onAnswerChange}
              index={index}
              showcaseAssessmentPreview={showcaseAssessmentPreview}
              getNextEnabledControlApps={getNextEnabledControlApps}
            />
          );
        })}
    </div>
  );
};

Controls.propTypes = {
  showcaseAssessmentPreview: PropTypes.bool,
};

export default Controls;
