import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'antd';

import Icon from 'ui/Icon';
import { translate } from 'utils/index';

import { messages } from './messages';
import './index.scss';

const Expander = ({ children, className, teaserHeight }) => {
  const [expand, setExpand] = useState(false);

  const onClick = () => setExpand((value) => !value);

  return (
    <div className={classNames('ui-expander', className)}>
      <div
        className={classNames('ui-expander__content', {
          'ui-expander__content--expanded': expand,
        })}
        style={{ maxHeight: expand ? 'none' : teaserHeight }}
      >
        {children}
      </div>
      <Button
        type="link"
        data-test="expander-show-more"
        className={classNames('ui-expander__button', {
          'ui-expander__button--expanded': expand,
        })}
        onClick={onClick}
      >
        {translate(expand ? messages.showLess : messages.showMore)}
        <Icon icon="arrow-down2" />
      </Button>
    </div>
  );
};

Expander.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  teaserHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Expander;
