import { useSelector } from 'react-redux';
import { selectAssessment } from 'pages/Assessment/selectors';
import { selectTemplate } from 'pages/Editor/selectors';

export function useGetControlById(isEditor) {
  const { categories = [] } = useSelector(isEditor ? selectTemplate : selectAssessment);

  return (id) => {
    return categories.flatMap(({ controls }) => controls).find((control) => control.id === id);
  };
}
