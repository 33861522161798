export const MFA_METHOD = {
  NONE: 0,
  EMAIL: 1,
  SMS: 2,
};

export const API_STATUS = {
  FAILED: 0,
  SUCCESS: 1,
  OPTION: 3,
  NOT_FOUND: 4,
  UNAUTHORIZED: 5,
};

export const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 10,
  total: 10,
};
