import { useEffect, useState } from 'react';

export function useIsVendorDirty(
  currentVendor,
  contactData,
  assessmentsData,
  assetsData,
  assessmentsDataDefault,
  assetsDataDefault,
  initFormContact,
) {
  const [isVendorDirty, setIsVendorDirty] = useState(false);

  useEffect(async () => {
    const contactDataDirty =
      JSON.stringify(contactData) !== JSON.stringify(initFormContact(currentVendor));
    const assessmentsDataDirty =
      JSON.stringify(assessmentsData) !== JSON.stringify(assessmentsDataDefault);
    const assetsDataDirty = JSON.stringify(assetsData) !== JSON.stringify(assetsDataDefault);

    if (contactDataDirty || assessmentsDataDirty || assetsDataDirty) {
      return setIsVendorDirty(true);
    }
    setIsVendorDirty(false);
  }, [currentVendor, contactData, assessmentsData, assetsData]);

  return [isVendorDirty, setIsVendorDirty];
}
