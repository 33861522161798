import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Input from './input';
import { translate } from 'utils/index';
import { messages } from './messages';

const Search = (props) => {
  return (
    <Input
      {...props}
      data-test="search-input"
      placeholder={props.placeholder || translate(messages.searchPlaceholder)}
      type="search"
      afterIcon="search"
      rounded
      className={classNames('ui-input-control--search', props.className)}
    />
  );
};

Search.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Search;
