import { useSelector } from 'react-redux';
import { selectUserRole } from 'pages/Profile/selectors';
import { Roles } from 'constants/roles';

export const useRole = () => {
  const role = useSelector(selectUserRole);

  return {
    userRole: role,
    userRoleName: Roles.getRoleName(role),
    isAdminRole: role === Roles.Admin,
    isBoRole: role === Roles.BO,
    isSoRole: role === Roles.SO,
    isOwnerRole: role === Roles.Owner,
    isReporterRole: role === Roles.Reporter,
    isViewerRole: role === Roles.Viewer,
    isBoSoRole: role === Roles.BO || role === Roles.SO,
    isAuditorRole: role === Roles.Auditor,
    isAdminOwnerRole: role === Roles.Admin || role === Roles.Owner,
    isRestrictedBoSoViewerRole: role === Roles.BO || role === Roles.SO || role === Roles.Viewer,
    isRestrictedBoSoViewerAuditorRole:
      role === Roles.BO || role === Roles.SO || role === Roles.Viewer || role === Roles.Auditor,
  };
};
