export const messages = {
  invalidFileDimensions: {
    key: 'Upload Logo.invalidFileDimensions',
    defaultMessage: 'Browse to upload a new logo. (dimensions 600w/250h)',
  },
  logoUpdateSuccess: {
    key: 'Upload Logo.logoUpdateSuccess',
    defaultMessage: 'Logo updated successfully',
  },
  logoUpdateFail: {
    key: 'Upload Logo.logoUpdateFail',
    defaultMessage: 'Failed to update logo',
  },
  uploadNewLogo: {
    key: 'Upload Logo.uploadNewLogo',
    defaultMessage: 'Upload new logo',
  },
  removeLogo: {
    key: 'Upload Logo.removeLogo',
    defaultMessage: 'Remove logo',
  },
  uploadLogo: {
    key: 'Upload Logo.uploadLogo',
    defaultMessage: 'Upload Company Logo',
  },
  removeLogoPopup: {
    key: 'Upload Logo.removeLogoPopup',
    defaultMessage: 'Are you sure you want to remove the logo?',
  },
};

export default {
  prefix: 'Upload Logo',
  messages,
};
