import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import ROUTES from 'constants/routes';
import { shouldAppNavigateToContinuousMonitoring } from 'utils/appsUtils';

export function useRedirectToMessage() {
  const isVendorSelected = useIsMyVendorsTabSelected();
  const appNavigate = useAppNavigate();
  const { organization } = useParams();

  return ({ assessmentId, categoryId, controlId, findingId, recommendationId, app }) => {
    if (app && shouldAppNavigateToContinuousMonitoring(app)) {
      return appNavigate(
        isVendorSelected
          ? ROUTES.VENDOR_CONTINUOUS_MONITORING
          : ROUTES.CUSTOMER_CONTINUOUS_MONITORING,
        { assessmentId, appId: app },
        undefined,
        false,
        { findingId, recommendationId },
      );
    }

    const newPath = generatePath(
      isVendorSelected ? ROUTES.VENDOR_ASSESSMENT : ROUTES.CUSTOMER_ASSESSMENT,
      { organization, assessmentId },
    );

    const searchParams = new URLSearchParams();

    if (categoryId) searchParams.append('categoryId', categoryId);
    if (controlId) searchParams.append('controlId', controlId);
    if (findingId) searchParams.append('findingId', findingId);
    if (recommendationId) searchParams.append('recommendationId', recommendationId);

    return appNavigate(`${newPath}/*`, {
      '*': `?${searchParams.toString()}`,
    });
  };
}
