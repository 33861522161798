import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';

import { translate } from 'utils/index';
import Table from 'ui/Table';
import ScoreBenchmark from 'components/AssessmentScoreBenchmark';
import VendorRisk from './vendorRisk';
import { messages } from '../messages';
import { generalMessages } from 'constants/messages';

import './vendorRiskPopover.scss';

const VendorRiskPopover = ({ children, vendor }) => {
  const [popover, setPopover] = useState(false);

  const getRiskValue = (score) => {
    if (score <= 33) {
      return translate(generalMessages.lowRisk);
    }

    if (score > 33 && score < 67) {
      return translate(generalMessages.mediumRisk);
    }

    return translate(generalMessages.highRisk);
  };

  const inherentRiskColumns = [
    {
      title: '',
      dataIndex: 'name',
      width: 250,
    },
    {
      title: '',
      dataIndex: 'score',
      width: 200,
      render: (score) => <VendorRisk value={getRiskValue(score)} />,
    },
  ];

  const assessmentsScoreColumns = [
    {
      title: '',
      dataIndex: 'name',
      width: 250,
    },
    {
      title: '',
      dataIndex: 'score',
      width: 200,
      render: (score, assessment) => (
        <ScoreBenchmark score={score} benchmark={assessment.benchmark} />
      ),
    },
  ];

  const popup = (
    <div
      className="recent-page-vendors-vendor-risk-popover__content"
      onClick={(event) => event.stopPropagation()}
    >
      <div className="recent-page-vendors-vendor-risk-popover__content-title">
        {translate(messages.inherentRisk)}:
      </div>
      <Table
        className="recent-page-vendors-vendor-risk-popover__inherent-risk-table"
        columns={inherentRiskColumns}
        dataSource={vendor.inherentRisk}
        pagination={false}
      />
      <div className="recent-page-vendors-vendor-risk-popover__content-title">
        {translate(messages.assessmentsScore)}:
      </div>
      {vendor.assessmentScores.length > 0 ? (
        <Table
          className="recent-page-vendors-vendor-risk-popover__assessments-score-table"
          columns={assessmentsScoreColumns}
          dataSource={vendor.assessmentScores}
          pagination={false}
        />
      ) : (
        <div className="recent-page-vendors-vendor-risk-popover__empty-state">
          <div className="recent-page-vendors-vendor-risk-popover__empty-state-label">
            {translate(messages.emptyStateLabel)}
          </div>
          <div className="recent-page-vendors-vendor-risk-popover__empty-state-value">
            {translate(messages.emptyStateValue)}
          </div>
        </div>
      )}
    </div>
  );

  if (vendor.inherentRisk.length === 0 && vendor.assessmentScores.length === 0) {
    return children;
  }

  return (
    <Popover content={popup} trigger="hover" open={popover} onOpenChange={setPopover}>
      {children}
    </Popover>
  );
};

VendorRiskPopover.propTypes = {
  children: PropTypes.node,
  vendor: PropTypes.object,
};

export default VendorRiskPopover;
