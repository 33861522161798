import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import AssessmentItem from 'components/AssessmentItem';

// UTILS
import { selectShowCasePreview } from 'pages/Showcases/showcasePreview/selectors';
import { setActiveAssessmentId } from 'pages/Showcases/showcasePreview/reducers';

function AssessmentsList() {
  const dispatch = useDispatch();
  const { activeAssessmentId, assessments } = useSelector(selectShowCasePreview);

  const onSetActiveAssessmentId = useCallback((id) => {
    dispatch(setActiveAssessmentId(id));
  }, []);

  const list = useMemo(() => {
    return Object.keys(assessments).map((id) => (
      <AssessmentItem
        key={id}
        onSetActiveAssessmentId={() => onSetActiveAssessmentId(id)}
        isActive={activeAssessmentId === id}
        name={assessments[id]}
      />
    ));
  }, [assessments, activeAssessmentId]);

  return <div className="showcase-preview__assessments-list">{list}</div>;
}

export default AssessmentsList;
