import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { getOffset } from 'utils/html';
import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';

// COMPONENTS
import Icon from 'ui/Icon';
import Input from 'ui/Input';

// UTILS
import { translate } from 'utils/index';
import { messages } from './messages';

// REDUX
import { selectCategory } from './reducers';
import {
  selectAssessment,
  selectAssessmentErrors,
  selectCategories,
  selectCategory as selectorsSelectCategory,
  selectFirstUnansweredControl,
} from './selectors';

// STYLES
import './categories.scss';
import { getTooltipTrigger } from 'ui/Tooltip/lib';

const Categories = ({ showcaseAssessmentPreview }) => {
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const category = useSelector(selectorsSelectCategory);
  const { total_score: totalScore } = useSelector(selectAssessment);
  const firstUnansweredControl = useSelector(selectFirstUnansweredControl);
  const selectedCategoryId = useSelector((state) => state.assessment?.selectedCategory);
  const { errorsList } = useSelector(selectAssessmentErrors);
  const [searchValue, setSearchValue] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [shouldScrollToFirstUnansweredControl, setShouldScrollToFirstUnansweredControl] =
    useState();

  useEffect(() => {
    if (categories?.length) setFilteredCategories(categories);
  }, [categories?.length, totalScore]);

  useEffect(() => {
    if (categories[0] && !category.id) {
      dispatch(selectCategory(categories[0].id));
    }
  }, [categories]);

  useEffect(() => {
    if (!firstUnansweredControl || !shouldScrollToFirstUnansweredControl) {
      setShouldScrollToFirstUnansweredControl(false);
      return;
    }
    scrollToControl();
  }, [category.id]);

  const scrollToControl = useCallback(() => {
    if (shouldScrollToFirstUnansweredControl) {
      setTimeout(() => {
        const parent = document.querySelector('.assessment__right-panel-inner');
        const controlElement = document.getElementById(`control-${firstUnansweredControl.id}`);

        if (!parent || !controlElement) {
          return;
        }
        const controlElementOffset = getOffset(controlElement);
        parent.scroll({
          top: controlElementOffset.top - 70,
          left: 0,
          behavior: 'smooth',
        });
        setShouldScrollToFirstUnansweredControl(false);
      });
    }
  }, [shouldScrollToFirstUnansweredControl, firstUnansweredControl]);

  const onSelectCategory = (id) => {
    dispatch(selectCategory(id));
    // eslint-disable-next-line no-shadow
    const currentCategory = categories.find((category) => category.id === id);
    if (currentCategory.progress === 0) {
      return;
    }
    setShouldScrollToFirstUnansweredControl(true);
  };

  const onSearch = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (!searchValue) {
      setFilteredCategories(categories);
      return;
    }
    const filtered = categories.filter((category) => {
      return category.title.toLowerCase().includes(searchValue.toLowerCase());
    });
    setFilteredCategories(filtered);
  }, [searchValue]);

  return (
    <div className="assessment-categories">
      <div className="assessment-categories__header">
        <div className="assessment-categories__header-title">
          <span className="assessment-categories__header-title-text">
            {translate(messages.categories)}
          </span>
          {` (${categories?.length})`}
        </div>
        {showcaseAssessmentPreview && (
          <div>
            <Input.Search
              data-test={'assessment-categories-showcase-input-search'}
              onChange={onSearch}
              size="small"
              value={searchValue}
            />
          </div>
        )}
      </div>
      <div className="assessment-categories__categories">
        {filteredCategories.map(({ id, title, progress }, index) => {
          return (
            <Tooltip
              key={`tooltip-assessment-category-title-${id}`}
              title={title}
              trigger={getTooltipTrigger({
                elementId: `category-${id}`,
                triggersOnOverflow: ['hover'],
              })}
            >
              <Button
                key={id}
                className={classNames('assessment-categories__category', {
                  'assessment-categories__category--selected': id === selectedCategoryId,
                })}
                type="link"
                onClick={() => onSelectCategory(id)}
              >
                <div
                  id={`category-${id}`}
                  className={classNames('assessment-categories__category-title', {
                    'assessment-categories__category-title--error': errorsList[id],
                  })}
                >
                  {`${index + 1}. ${title}`}
                </div>
                {progress === 100 ? (
                  <span className="assessment-categories__category-completed">
                    <Icon className="assessment-categories__category-completed-icon" icon="ok" />
                  </span>
                ) : (
                  <span className="assessment-categories__category-progress">{progress}%</span>
                )}
              </Button>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

Categories.propTypes = {
  showcaseAssessmentPreview: PropTypes.bool,
};

export default Categories;
