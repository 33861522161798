import * as yup from 'yup';
import { COMPANY_NAME_VALIDATION_REGEX, CONTACT_NAME_VALIDATION_REGEX } from 'constants/general';

const requiredStringSchema = (reqMsg) => yup.string().required(reqMsg);
const emailSchema = (reqMsg, formatMsg) => yup.string().email(formatMsg).required(reqMsg);

const domainSchema = (reqMsg) => {
  const domainRgx =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

  return yup.string().matches(domainRgx, { message: reqMsg, excludeEmptyString: true });
};

export const contactInformationSchema = (
  requiredCompanyName,
  requiredCompanyContact,
  requiredCompanyEmail,
  companyEmailErrorFormat,
  nameError,
  companyDomainErrorFormat,
  obfuscateDomain,
) => {
  return yup.object({
    corporateName: requiredStringSchema(requiredCompanyName).matches(
      COMPANY_NAME_VALIDATION_REGEX,
      requiredCompanyName,
    ),
    companyContact: requiredStringSchema(requiredCompanyContact).matches(
      CONTACT_NAME_VALIDATION_REGEX,
      nameError,
    ),
    companyDomain: obfuscateDomain ? null : domainSchema(companyDomainErrorFormat),
    email: emailSchema(companyEmailErrorFormat, requiredCompanyEmail),
  });
};
