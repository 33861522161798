import PopupWithForm from 'ui/Popup/popupWithForm';
import ConfirmationPopup from 'ui/Popup/confirmationPopup';
import RemovePopup from 'ui/Popup/removePopup';
import Popup from './Popup';

Popup.WithForm = PopupWithForm;
Popup.Confirmation = ConfirmationPopup;
Popup.Remove = RemovePopup;

export default Popup;
