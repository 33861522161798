import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Empty } from 'antd';
import { translate } from 'utils/index';
import { isEditableAnswerType } from 'utils/controls';
import { messages } from './messages';
import './answers.scss';

const AnswerRepositoryAnswers = ({
  answers,
  answerType,
  selectedControl,
  selectedAnswer = {},
  onSetSelectedAnswer,
}) => {
  if (!selectedControl) {
    return (
      <Empty
        className="answer-repository-answers__empty"
        icon="answers"
        description={translate(messages.emptyContentAnswerSubtitle)}
      />
    );
  }

  if (!answers || answers.length === 0) {
    return (
      <Empty
        className="answer-repository-answers__empty"
        icon="answers"
        description={translate(messages.noAnswerWasFound)}
      />
    );
  }

  const possibleAnswers = (answerType.options || []).map((option) => option.value);

  const isPossibleAnswer = (answer) => {
    if (Array.isArray(answer)) {
      return answer.every((value) => possibleAnswers.includes(value));
    }

    return possibleAnswers.includes(answer);
  };

  return (
    <div className="answer-repository-answers__container">
      {answers.map((title, key) => {
        const disabled = isEditableAnswerType(answerType.type) ? !isPossibleAnswer(title) : false;
        return (
          <div
            key={key} // eslint-disable-line react/no-array-index-key
            title={disabled ? translate(messages.answerDisabled) : ''}
            onClick={() => (!disabled ? onSetSelectedAnswer(title) : () => {})}
            className={classNames('answer-repository-answers__answer', {
              selected: selectedAnswer.value === title,
              disabled: !!disabled,
            })}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
};

AnswerRepositoryAnswers.propTypes = {
  answers: PropTypes.array,
  answerType: PropTypes.object,
  selectedControl: PropTypes.string,
  selectedAnswer: PropTypes.object,
  onSetSelectedAnswer: PropTypes.func,
};

export default AnswerRepositoryAnswers;
