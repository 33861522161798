import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'ui/Button';
import Input from 'ui/Input';
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';

import { messages } from './messages';
import { translate } from 'utils/index';
import './index.scss';

const SearchExpandable = (props) => {
  const [isExpanded, setExpanded] = useState(props.isExpanded);

  const onClick = () => {
    setExpanded(!isExpanded);
  };

  const onCancel = () => {
    props.onCancel();
    onClick();
  };

  const inputClassNames = classNames('search-expandable__input', props.className, {
    'search-expandable--expanded': isExpanded,
  });

  return (
    <div className="search-expandable">
      <div className="search-expandable__input-container">
        <Input
          data-test="search-expandable-input"
          placeholder={props.placeholder || translate(messages.placeholder)}
          type="search"
          value={props.value}
          rounded
          onChange={props.onChange}
          className={inputClassNames}
          {...props}
        />
        <Button
          className={`${isExpanded ? 'expanded' : ''}`}
          onClick={onClick}
          outline
          data-test="search-expandable-input-button"
        >
          <SearchIcon width={24} height={24} />
        </Button>
      </div>

      {isExpanded && (
        <Button onClick={onCancel} link data-test="search-expandable-button">
          {translate(messages.cancelButtonText)}
        </Button>
      )}
    </div>
  );
};

SearchExpandable.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  isExpanded: PropTypes.bool,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  value: PropTypes.string,
};

export default SearchExpandable;
