import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';

import Button from 'ui/Button';
import { translate } from 'utils/index';

import { messages } from './messages';
import { useTheme } from 'hooks/theme';
import { TrashIcon } from 'components/Icons';

import './index.scss';

const RemoveConfirmationModal = ({ confirmLoading, onCancel, onOk, text, open, headerText }) => {
  const { colorTheme } = useTheme();

  const Footer = useMemo(() => {
    return (
      <div className="remove-confirmation-modal__footer">
        <Button data-test="modal-button-cancel" size="sm" color="white" onClick={onCancel}>
          {translate(messages.noText)}
        </Button>
        <Button
          data-test="modal-button-accept"
          size="sm"
          color={colorTheme}
          loading={confirmLoading}
          onClick={onOk}
        >
          {translate(messages.okText)}
        </Button>
      </div>
    );
  }, [colorTheme, onOk, onCancel, confirmLoading]);

  return (
    <Modal
      className="remove-confirmation-modal"
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      onOk={onOk}
      width={460}
      footer={Footer}
      open={open}
    >
      <div className="remove-confirmation-modal__header">
        <TrashIcon />
        {headerText}
      </div>
      <div className="remove-confirmation-modal__text">{text}</div>
    </Modal>
  );
};

RemoveConfirmationModal.propTypes = {
  confirmLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  text: PropTypes.string,
  headerText: PropTypes.string,
  open: PropTypes.bool,
};

export default RemoveConfirmationModal;
