export const messages = {
  indicatorTitle: {
    key: 'file-upload.indicatorTitle',
    defaultMessage: 'Uploading & Scanning file for viruses',
  },
  indicatorSubtitle: {
    key: 'file-upload.indicatorSubtitle',
    defaultMessage: 'It might take a few seconds.',
  },
  indicatorAbortionText: {
    key: 'file-upload.indicatorAbortionText',
    defaultMessage: 'cancel upload',
  },
  modalTitle: {
    key: 'file-upload.modalTitle',
    defaultMessage: 'Exit page?',
  },
  modalDescription: {
    key: 'file-upload.modalDescription',
    defaultMessage: 'This will stop the file uploading process',
  },
  modalStay: {
    key: 'file-upload.modalStay',
    defaultMessage: 'STAY ON PAGE',
  },
  modalExit: {
    key: 'file-upload.modalExit',
    defaultMessage: 'EXIT ANYWAY',
  },
};

export default {
  prefix: 'file-upload',
  messages,
};
