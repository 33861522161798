import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useParams } from 'react-router';
import { urlRedirectComposer } from 'utils/redirects';

const Redirect = ({ to: redirectTo }) => {
  const to = urlRedirectComposer(useParams(), redirectTo);
  if (!to) return null;

  return <Navigate to={to} />;
};

Redirect.propTypes = {
  to: PropTypes.string,
};

export default Redirect;
