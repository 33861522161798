// Dashboard settings:
import colors from 'ui/colors.scss';

export const eDashboardChartTypes = {
  scoreCircularChart: 'scoreCircularChart',
  lineChart: 'lineChart',
  totalSummary: 'totalSummary',
  magicTable: 'magicTable',
  barChart: 'barChart',
  doubleDoughnutChart: 'doubleDoughnutChart',
  multiProgressChart: 'multiProgressChart',
  pieChart: 'pieChart',
};

export const eChartSizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

// chart theme:
export const chartTheme = {
  axis: {
    ticks: {
      line: {
        stroke: colors.gray100,
      },
      text: {
        fill: colors.gray400,
        fontSize: '10px',
      },
    },
    legend: {
      title: {
        text: {
          fill: colors.textColor,
          fontSize: '10px',
        },
      },
    },
  },
  labels: {
    text: {
      fill: colors.white,
    },
  },
  grid: {
    line: {
      stroke: colors.gray100,
    },
  },
  tooltip: {
    container: {
      background: colors.black100,
      color: colors.white,
    },
  },
};

/**
   Types:
  
   chart: {
      title: {key: string, value: string},
      subtitle?: {key: string, value: string},
      chartType: eDashboardChartTypes,
      datatype: string,
      icon: string,
      loading?: boolean,
      size: eChartSizes,
      data?: scoreCircularChartData | lineChartData  | vendorRiskTableData | barChartData | doubleDoughnutChartData | multiProgressBarChartData | pieChartData,
   }

    scoreCircularChartData: {
        score: number
    }

  lineChart: {
    id: string,
    data: {
      x: string | {key: string, value: string},
      y: string | number | {key: string, value: string},
    }[]
  }

    totalSummaryData: {text: {key: string, value: string}, value: number, total?: number,  icon: 'VendorIcon' | 'BriefcaseIcon' | 'AssessmentIcon' }[]

    magicTableData: {
        navRoute: string,
        subjectText: {key: string, value: string},
        rows: {
            titles: string[] | {key: string, value: string}[],
            legend: string | {key: string, value: string}
            filterKey: string
        },
        cols: {
            titles: string[] | {key: string, value: string}[]
            legend: string | {key: string, value: string}
            filterKey: string
        },
        table: {
            color: string,
            value: number
        }[][]
    }

    barChartData: {
        filterKey: string,
        keys: string[],
        indexBy: string,
        legends: {
            x: {key: string, value: string},
            y: {key: string, value: string}
        },
        dataset: {
            [key: string]: string | {key: string, value: string} ,
            color?: string
            labelColor?: string

        }[]
    }

    doubleDoughnutChartData: {
        filterKey: string,
        navRoute: string,
        doughnutLeft: {
            dataset: {
                id: string,
                label: string,
                value: number,
                color: string
            }[]
        },
        doughnutRight: {
            dataset: {
                id: string,
                label: string,
                value: number,
                color: string
            }[]
        }
    }

    multiProgressChartData: {
        filterKey: string,
        navRoute: string,
        subjectText: {key: string, value: string},
        dataset: {
            id: string,
            label: string,
            percentage: number,
            value: number,
            color: string
        }[]
    }

    pieChartData: {
        filterKey: string,
        navRoute: string,
        dataset: {
            id: string,
            label: string,
            value: number,
            color: string
        }[]
    }



   */

// demo data:

export const scoreCircularChartDemoData = { score: 82 };
export const lineChartDemoData = [
  {
    id: 'score',
    color: '#349ed4',
    data: [
      { x: { key: 'metadata.months.jan', value: 'Jan' }, y: '25' },
      { x: { key: 'metadata.months.feb', value: 'Feb' }, y: '30' },
      { x: { key: 'metadata.months.mar', value: 'Mar' }, y: '35' },
      { x: { key: 'metadata.months.apr', value: 'Apr' }, y: '50' },
      { x: { key: 'metadata.months.may', value: 'May' }, y: '55' },
      { x: { key: 'metadata.months.jun', value: 'Jun' }, y: '60' },
      { x: { key: 'metadata.months.jul', value: 'Jul' }, y: '60' },
      { x: { key: 'metadata.months.aug', value: 'Aug' }, y: '50' },
      { x: { key: 'metadata.months.sep', value: 'Sep' }, y: '55' },
      { x: { key: 'metadata.months.oct', value: 'Oct' }, y: '75' },
      { x: { key: 'metadata.months.nov', value: 'Nov' }, y: '60' },
      { x: { key: 'metadata.months.dec', value: 'Dec' }, y: '80' },
    ],
  },
];
export const totalSummaryDemoData = [
  {
    text: { key: 'dashboardChart.totalSummaryDemoData.vendors', value: 'Overall vendors' },
    filteredValue: 465,
    total: 1530,
    icon: 'VendorIcon',
  },
  {
    text: {
      key: 'dashboardChart.totalSummaryDemoData.businessEngagement',
      value: 'Business engagement',
    },
    filteredValue: 897,
    total: 2530,
    icon: 'BriefcaseIcon',
  },
  {
    text: {
      key: 'dashboardChart.totalSummaryDemoData.activeAssessments',
      value: 'Active assessments',
    },
    total: 3120,
    icon: 'AssessmentIcon',
  },
];

export const magicTableDemoData = {
  subjectText: { key: 'BaseLayout.vendors', value: 'Vendors' },
  navRoute: 'VENDOR_VENDORS',
  rows: {
    titles: [
      { key: 'metadata.high', value: 'High' },
      { key: 'metadata.medium', value: 'Medium' },
      { key: 'metadata.low', value: 'Low' },
    ],
    legend: { key: 'metadata.risk', value: 'Risk' },
    filterKey: 'vendorRiskScore',
  },
  cols: {
    titles: [
      { key: 'metadata.na', value: 'N/A' },
      { key: 'metadata.low', value: 'Low' },
      { key: 'metadata.medium', value: 'Medium' },
      { key: 'metadata.high', value: 'High' },
    ],
    legend: { key: 'metadata.businessEngagement', value: 'Business engagement' },
    filterKey: 'vendorEngagementScore',
  },
  table: [
    [
      { color: '#E0DEDE', value: 8 },
      { color: '#FFCB65', value: 327 },
      { color: '#F6A01E', value: 196 },
      { color: '#E45647', value: 12 },
    ],
    [
      { color: '#E0DEDE', value: 26 },
      { color: '#D0EFA7', value: 189 },
      { color: '#FFCB65', value: 126 },
      { color: '#F6A01E', value: 148 },
    ],
    [
      { color: '#E0DEDE', value: 61 },
      { color: '#88CE37', value: 312 },
      { color: '#D0EFA7', value: 144 },
      { color: '#FFCB65', value: 507 },
    ],
  ],
};

export const barChartData = {
  filterKey: 'riskreconGrade',
  navRoute: 'VENDOR_VENDORS',
  keys: ['vendors'],
  indexBy: 'level',
  legends: {
    x: { key: 'continuousMonitoring.riskreconLevels', value: 'Levels' },
    y: { key: 'BaseLayout.vendors', value: 'Vendors' },
  },
  dataset: [
    {
      level: { key: 'metadata.a', value: 'A' },
      vendors: 10,
      color: '#193B57',
      labelColor: '#fff',
    },
    {
      level: { key: 'metadata.b', value: 'B' },
      vendors: 20,
      color: '#193B57',
      labelColor: '#fff',
    },
    {
      level: { key: 'metadata.c', value: 'C' },
      vendors: 30,
      color: '#193B57',
      labelColor: '#fff',
    },
    {
      level: { key: 'metadata.d', value: 'D' },
      vendors: 40,
      color: '#193B57',
      labelColor: '#fff',
    },
    {
      level: { key: 'metadata.e', value: 'E' },
      vendors: 50,
      color: '#193B57',
      labelColor: '#fff',
    },
    {
      level: { key: 'metadata.f', value: 'F' },
      vendors: 60,
      color: '#193B57',
      labelColor: '#fff',
    },
    {
      level: { key: 'metadata.na', value: 'N/A' },
      vendors: 70,
      color: '#E0DEDE',
      labelColor: '#262626',
    },
  ],
};

export const doubleDoughnutChartData = {
  filterKey: 'assessmentStatus',
  navRoute: 'VENDOR_ASSESSMENTS',
  doughnutLeft: {
    dataset: [
      {
        id: 'metadata.notStarted',
        label: 'Not started',
        value: 10,
        color: '#CCCCCC',
      },
      {
        id: 'metadata.inProgress',
        label: 'In progress',
        value: 20,
        color: '#FFA742',
      },
      {
        id: 'metadata.completed',
        label: 'Completed',
        value: 17,
        color: '#88CE37',
      },

      {
        id: 'metadata.forcedSubmitted',
        label: 'Forced submitted',
        value: 22,
        color: '#922D22',
      },
      {
        id: 'metadata.submitted',
        label: 'Submitted',
        value: 8,
        color: '#4C5FBD',
      },
      {
        id: 'metadata.reviewed',
        label: 'Reviewed',
        value: 23,
        color: '#956ee7',
      },
    ],
  },
  doughnutRight: {
    dataset: [
      {
        id: 'metadata.pass',
        label: 'Pass',
        value: 60,
        color: '#88ce38',
      },
      {
        id: 'metadata.fail',
        label: 'Fail',
        value: 40,
        color: '#e45647',
      },
    ],
  },
};

export const multiProgressChartData = {
  filterKey: 'findingsCriticality',
  navRoute: 'VENDOR_FINDINGS',
  subjectText: { key: 'BaseLayout.findings', value: 'Findings' },
  dataset: [
    {
      id: 'metadata.showStopper',
      label: 'Show stopper',
      percentage: 2,
      value: 4,
      color: '#000',
    },
    {
      id: 'metadata.critical',
      label: 'Critical',
      percentage: 5,
      value: 10,
      color: '#922D22',
    },
    {
      id: 'metadata.high',
      label: 'High',
      percentage: 18,
      value: 38,
      color: '#e45647',
    },
    {
      id: 'metadata.medium',
      label: 'Medium',
      percentage: 32,
      value: 67,
      color: '#FFA742',
    },
    {
      id: 'metadata.low',
      label: 'Low',
      percentage: 43,
      value: 90,
      color: '#88CE37',
    },
  ],
};

export const pieChartDemoData = {
  filterKey: 'evidenceExpiration',
  navRoute: 'VENDOR_EVIDENCE',
  dataset: [
    {
      id: 'metadata.valid',
      label: 'Valid',
      value: 60,
      color: '#88CE37',
    },
    {
      id: 'metadata.expiringSoon',
      label: 'Expiring soon',
      value: 30,
      color: '#FFA742',
    },
    {
      id: 'metadata.expired',
      label: 'Expired',
      value: 10,
      color: '#E45647',
    },
  ],
};
