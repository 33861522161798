import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigationType } from 'react-router';

import PageSubheader from 'components/PageSubheader';
import { getRecentEvidences } from 'api/recent';
import { translate } from 'utils/index';
import Table from 'ui/Table';
import { setEvidencesSorter } from './reducers';
import { messages } from './messages';
import { TableSource } from 'ui/Table/lib';
import Button from 'ui/Button';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import ROUTES from 'constants/routes';
import useEvidencesTable from 'pages/Evidences/useEvidencesTable';

import './evidences.scss';

const Evidences = () => {
  const dispatch = useDispatch();
  const { evidences, evidencesLoading, evidencesSorter } = useSelector((state) => state.recent);
  const navigationType = useNavigationType();
  const appNavigate = useAppNavigate();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const { columns, editEvidenceMeta, previewEvidence } = useEvidencesTable({
    evidences,
    evidencesSorter,
    evidencesLoading,
    recent: true,
    fetchEvidences: () => dispatch(getRecentEvidences()),
  });

  useEffect(() => {
    if (navigationType !== 'POP') {
      dispatch(setEvidencesSorter({}));
    }
    dispatch(getRecentEvidences());
  }, []);

  const dataSource = (evidences || []).map((evidence) => ({
    ...evidence,
    key: evidence.evidenceId,
  }));

  const onTableChange = (newPagination, filters, sorter) => {
    const sorterParams = {
      column: sorter.field,
      descending: sorter.order !== 'ascend',
    };
    const params = sorter.column ? sorterParams : {};
    dispatch(setEvidencesSorter(params));
  };

  return (
    <div className="recent-page-evidences">
      <div className="recent-page-evidences__top">
        <PageSubheader className="recent-page-evidences__page-subheader">
          {translate(messages.recentEvidences)}
        </PageSubheader>
        <div className="recent-page-findings__menu">
          <Button
            data-test="recent-page-findings-button-view-all"
            className="recent-page-findings__view-all-button"
            link
            color="black"
            size="xs"
            onClick={() =>
              appNavigate(
                isMyVendorsTabSelected ? ROUTES.VENDOR_EVIDENCES : ROUTES.CUSTOMER_EVIDENCES,
              )
            }
          >
            {translate(messages.viewAll)}
          </Button>
        </div>
      </div>
      <Table
        className="recent-page-evidences__table"
        columns={columns}
        dataSource={dataSource}
        loading={evidencesLoading}
        pagination={false}
        onChange={onTableChange}
        scroll={{
          y: 300,
        }}
        emptyStateSource={TableSource.evidenceRecent}
      />
      {editEvidenceMeta()}
      {previewEvidence()}
    </div>
  );
};

export default Evidences;
