import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import classNames from 'classnames';

import { translate } from 'utils/index';
import Table from 'ui/Table';
import { getRiskValueByScore } from 'utils/vendor';

import { messages } from '../messages';
import './inherentRiskPopover.scss';

const InherentRiskPopover = ({ children, inherentRisk }) => {
  const [popover, setPopover] = useState(false);

  const inherentRiskColumns = [
    {
      title: translate(messages.inherentRiskAnalysis),
      dataIndex: 'name',
      width: 250,
      render: (name, { progress }) => {
        return <div data-test="recent-page-vendors-inherent-risk-popover-name">{name}</div>;
      },
    },
    {
      title: translate(messages.score),
      dataIndex: 'score',
      width: 80,
      render: (score, { progress }) => {
        return (
          <div data-test="recent-page-vendors-inherent-risk-popover-score-value">
            {progress === 0 ? translate(messages.emptyStateValue) : score}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'score',
      width: 200,
      render: (score, { progress }) => {
        const className = classNames('recent-page-vendors-inherent-risk-popover__risk', {
          [`recent-page-vendors-inherent-risk-popover__risk--${getRiskValueByScore(score)}`]: true,
        });

        if (progress === 0) {
          return (
            <div
              data-test="recent-page-vendors-inherent-risk-popover-score-title"
              className={className}
            ></div>
          );
        }

        return (
          <div
            data-test="recent-page-vendors-inherent-risk-popover-score-title"
            className={className}
          >{`${getRiskValueByScore(score)} ${translate(messages.risk)}`}</div>
        );
      },
    },
  ];

  const popup = (
    <div className="recent-page-vendors-inherent-risk-popover__content">
      <Table
        className="recent-page-vendors-inherent-risk-popover__inherent-risk-table"
        columns={inherentRiskColumns}
        dataSource={inherentRisk}
        pagination={false}
      />
    </div>
  );

  if (inherentRisk.length === 0) {
    return children;
  }

  return (
    <Popover
      content={popup}
      trigger="hover"
      open={popover}
      onOpenChange={setPopover}
      className="vendors-inherent-risk-popover"
    >
      <>{children}</>
    </Popover>
  );
};

InherentRiskPopover.propTypes = {
  children: PropTypes.node,
  inherentRisk: PropTypes.array,
};

export default InherentRiskPopover;
