import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getTextByStatusValue } from 'utils/findings';
import { translate } from 'utils/index';
import BlinkingDot from 'ui/BlinkingDot';
import './status.scss';

const Status = ({ status, finding = {} }) => {
  const text = getTextByStatusValue(status);
  const className = classNames(
    'recent-page-findings-status__label',
    `recent-page-findings-status__label--${status}`,
  );

  return (
    <div className="recent-page-findings-status">
      <span className={className}>{translate(text)}</span>
      {finding.recommendationsEdited && <BlinkingDot />}
    </div>
  );
};

Status.propTypes = {
  finding: PropTypes.object,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default Status;
