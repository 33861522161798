import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'utils/index';
import { AssessmentStatus } from 'constants/assessmentStatus';

import { messages } from 'pages/Assessment/messages';
import './status.scss';

const Status = ({ status }) => {
  return (
    <div className="assessment-finalized-status">
      <div className="assessment-finalized-status__wrapper">
        <div className="assessment-finalized-status__line" />
        <div
          className="assessment-finalized-status__line-completed"
          style={{ width: status > AssessmentStatus.reviewed ? '100%' : '50%' }}
        />
        <div
          className="assessment-finalized-status__state-completed"
          data-text={translate(messages.assessment)}
        />
        <div
          className={classNames('assessment-finalized-status__state-finalized', {
            'assessment-finalized-status__state-finalized--done':
              status > AssessmentStatus.reviewed,
          })}
          data-text={translate(messages.submitted)}
        />
        <div
          className={classNames('assessment-finalized-status__state-reviewed', {
            'assessment-finalized-status__state-finalized--active':
              status > AssessmentStatus.reviewed,
          })}
          data-text={translate(messages.customerReview)}
        />
      </div>
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.number,
};

export default Status;
