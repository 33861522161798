import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { getAssessmentsShowcase } from 'api/assessmentsTemplates';
import {
  assignShowcase,
  getShowcaseTemplates,
  getAssignedShowcase,
  getAssignedShowcases,
} from 'api/showcase';

const initialState = {
  sentShowcases: [],
  currentShowcase: undefined,
  showcaseTypes: [],

  isLoading: false,
  isShowcaseAssigning: false,
  isLoadingSent: false,

  templatesShowcases: [],
  currentTemplate: undefined,
  isLoadingTemplates: false,
};

const reducers = createSlice({
  name: 'showcases',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getShowcaseTemplates.pending, (state) => {
        state.isLoadingTemplates = true;
      })
      .addCase(getAssessmentsShowcase.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignShowcase.pending, (state) => {
        state.isShowcaseAssigning = true;
      })
      .addMatcher(isAnyOf(getAssignedShowcase.pending, getAssignedShowcases.pending), (state) => {
        state.isLoadingSent = true;
      })
      .addMatcher(
        isAnyOf(
          getAssignedShowcase.fulfilled,
          getAssignedShowcase.rejected,
          getAssignedShowcases.fulfilled,
          getAssignedShowcases.rejected,
        ),
        (state, data) => {
          state.sentShowcases = data.payload.showcases;
          state.isLoadingSent = false;
        },
      )
      .addMatcher(
        isAnyOf(getShowcaseTemplates.fulfilled, getShowcaseTemplates.rejected),
        (state, data) => {
          state.templatesShowcases = data.payload.showcases;
          state.isLoadingTemplates = false;
        },
      )
      .addMatcher(
        isAnyOf(getAssessmentsShowcase.fulfilled, getAssessmentsShowcase.rejected),
        (state, data) => {
          state.showcaseTypes = data.payload.assessmentsGroups;
          state.isLoading = false;
        },
      )
      .addMatcher(isAnyOf(assignShowcase.fulfilled, assignShowcase.rejected), (state) => {
        state.isShowcaseAssigning = false;
      });
  },
});

export default reducers;
