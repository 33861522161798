import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';

import { translate } from 'utils/index';
import { generalMessages } from 'constants/messages';
import { TrashIcon } from 'components/Icons';

import './actionIcons.scss';

const ActionIcons = ({ removeDisabled, removeOnClick }) => {
  return (
    <div className="organizations-table__actions">
      {!removeDisabled && (
        <Tooltip title={translate(generalMessages.remove)}>
          <Button className="organizations-table__action-icons" type="link" onClick={removeOnClick}>
            <TrashIcon />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

ActionIcons.propTypes = {
  editDisabled: PropTypes.bool,
  editOnclick: PropTypes.func,
  removeDisabled: PropTypes.bool,
  removeOnClick: PropTypes.func,
};

ActionIcons.defaultProps = {
  editDisabled: false,
  removeDisabled: false,
};

export default ActionIcons;
