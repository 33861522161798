import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import { translate } from 'utils/index';
import { useTheme } from 'hooks/theme';
import { Apps } from 'constants/apps';
import AwsIcon from 'assets/svgs/aws-icon-2.svg';
import AzureIcon from 'assets/svgs/azure-icon.svg';
import { reviewCloudMonitoring } from 'api/assessment';

import { selectAssessment, selectCategory } from './selectors';
import { messages } from './messages';
import './cloudMonitoringReviewPopup.scss';

const CloudMonitoringReviewPopup = (open) => {
  const dispatch = useDispatch();
  const [cloudMonitoringReviewPopup, setCloudMonitoringReviewPopup] = useState(false);
  const { colorTheme } = useTheme();
  const category = useSelector(selectCategory);
  const [loading, setLoading] = useState(false);
  const { finalized } = useSelector(selectAssessment);

  const isCloudMonitoringCategory = [Apps.aws, Apps.azure].includes(category.app);

  useEffect(() => {
    if (isCloudMonitoringCategory && !category.reviewed && !finalized) {
      setCloudMonitoringReviewPopup(true);
    }
  }, [category]);

  const review = async () => {
    setLoading(true);
    await dispatch(reviewCloudMonitoring({ categoryId: category.id })).unwrap();
    setCloudMonitoringReviewPopup(false);
    setLoading(false);
  };

  const footer = [
    <Button
      key="button_cloud-monitoring-review_popup_1"
      className="assessment-cloud-monitoring-review-popup__footer-ok-button"
      size="sm"
      color={colorTheme}
      onClick={review}
      data-test="cloud-monitoring-review-button-ok"
      loading={loading}
    >
      {translate(messages.okGotIt)}
    </Button>,
  ];

  const icon = category.app === Apps.azure ? AzureIcon : AwsIcon;

  return (
    <Modal
      className="assessment-cloud-monitoring-review-popup"
      open={cloudMonitoringReviewPopup}
      onOk={review}
      onCancel={() => setCloudMonitoringReviewPopup(false)}
      width={550}
      footer={footer}
    >
      <img
        className="assessment-cloud-monitoring-review-popup__picture"
        src={icon}
        alt="Cloud Monitoring"
      />
      <p className="assessment-cloud-monitoring-review-popup__text">
        {translate(messages.reviewAndShareCloudMonitoring)}
      </p>
      <p className="assessment-cloud-monitoring-review-popup__description">
        {translate(messages.reviewAndShareCloudMonitoringDescription)}
      </p>
      <p className="assessment-cloud-monitoring-review-popup__description-2">
        {translate(messages.reviewAndShareCloudMonitoringDescription2)}
      </p>
    </Modal>
  );
};

export default CloudMonitoringReviewPopup;
