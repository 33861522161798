import axios from 'axios';
import {
  AssignShowcase,
  GetAssignedShowcases,
  GetAssignedShowcase,
  GetShowcasesTemplates,
  AddShowcaseTemplate,
  EditShowcaseTemplate,
} from 'api/endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAssignedShowcaseRequest = async ({ showcaseId }) => {
  const payload = {
    showcaseID: showcaseId,
  };
  return axios.get(GetAssignedShowcase.url, { params: payload });
};
export const getAssignedShowcase = createAsyncThunk(
  'getAssignedShowcase',
  getAssignedShowcaseRequest,
);

export const assignShowcaseRequest = async (args) => {
  const payload = {
    'showcasesIds': args.templates,
    'customerIds': args.customers || null,
    'sharedUntil': args.sharedUntil,
  };

  return axios.post(AssignShowcase.url, payload);
};

export const assignShowcase = createAsyncThunk('assignShowcase', assignShowcaseRequest);

const editShowcaseRequest = async (args) => {
  return axios.post(EditShowcaseTemplate.url, args);
};

export const editShowcase = createAsyncThunk('editShowcase', editShowcaseRequest);

const addShowcaseRequest = async (args) => {
  return axios.post(AddShowcaseTemplate.url, args);
};

export const addShowcase = createAsyncThunk('addShowcase', addShowcaseRequest);

export const getShowcaseTemplatesRequest = async () => {
  return axios.post(GetShowcasesTemplates.url);
};

export const getShowcaseTemplates = createAsyncThunk(
  'getShowcaseTemplates',
  getShowcaseTemplatesRequest,
);

export const getAssignedShowcasesRequest = async () => {
  return axios.post(GetAssignedShowcases.url);
};

export const getAssignedShowcases = createAsyncThunk(
  'getAssignedShowcases',
  getAssignedShowcasesRequest,
);
