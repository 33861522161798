import { createSelector } from '@reduxjs/toolkit';

export const selectRepository = createSelector(
  ({ answerRepository }) => answerRepository.repository,
  (_, answerType) => answerType,
  (_, answerType, hideIncompatible) => hideIncompatible,
  (repository, answerType, hideIncompatible) => {
    return hideIncompatible
      ? repository
          .map((category) => {
            const controls = category.controls.filter(({ type }) => type === answerType.type);
            if (controls.length > 0) {
              return {
                ...category,
                controls,
              };
            }
            return null;
          })
          .filter(Boolean)
      : repository;
  },
);
