import React from 'react';
import PropTypes from 'prop-types';

const FileIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M6.25 2C5.01625 2 4 3.01625 4 4.25V19.75C4 20.9838 5.01625 22 6.25 22H17.75C18.9838 22 20 20.9838 20 19.75V9.25C20 9.0511 19.9209 8.86036 19.7803 8.71973L19.7725 8.71191L13.2803 2.21973C13.1396 2.07907 12.9489 2.00004 12.75 2H6.25ZM6.25 3.5H12V7.75C12 8.98375 13.0162 10 14.25 10H18.5V19.75C18.5 20.1733 18.1732 20.5 17.75 20.5H6.25C5.82675 20.5 5.5 20.1733 5.5 19.75V4.25C5.5 3.82675 5.82675 3.5 6.25 3.5ZM13.5 4.56055L17.4395 8.5H14.25C13.8268 8.5 13.5 8.17325 13.5 7.75V4.56055Z"
      fill={color}
    />
  </svg>
);

FileIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default FileIcon;
