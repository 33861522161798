import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Divider from 'ui/Divider';
import { translate } from 'utils/index';
import { messages } from 'pages/SoBoPages/messages';
import { generalMessages } from 'constants/messages';
import { useTheme } from 'hooks/theme';

import './stageFour.scss';

const StageFour = ({ image, okButtonText, okButtonOnClick, closeButtonClick }) => {
  const { colorTheme } = useTheme();

  return (
    <div className="sobo-pages-stage-four">
      <div className="sobo-pages-stage-four__text">
        <h2> {translate(messages.stageFourTitle)} </h2>
        <p> {translate(messages.stageFourSubTitle)} </p>
        <Button width={210} centered color={colorTheme} size="sm" onClick={okButtonOnClick}>
          {okButtonText}
        </Button>
        <Divider hidden size="sm" />
        <Button link color="black" centered onClick={closeButtonClick}>
          {' '}
          {translate(generalMessages.close)}{' '}
        </Button>
      </div>
      <img src={image} alt="sobo-page-completed" className="sobo-pages-stage-four__illustration" />
    </div>
  );
};

StageFour.propTypes = {
  image: PropTypes.string,
  okButtonText: PropTypes.string,
  okButtonOnClick: PropTypes.func,
  closeButtonClick: PropTypes.func,
};

export default StageFour;
