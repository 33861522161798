/**
 * Mixpanel event helpers
 * for more details about payloads and track keys visit google sheets:
 * https://docs.google.com/spreadsheets/d/11RHTx2k4gCdm5RGFyPfkdQaXuLiYJ8_gg4h7EEvznt4
 * */
import mixpanel from 'mixpanel-browser';
import moment from 'moment';

import { AssessmentPerformedBy } from 'constants/assessmentPerformedBy';
import { Roles } from 'constants/roles';
import { MixpanelAssignAssessmentSource } from 'constants/mixpanel';

import { getCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY } from 'utils/auth';

import { getOrganizationsById } from 'utils/organizations';
import { isProductionDomain } from 'utils/index';
import { ENV } from 'configs/env';

/**
 * Wrapper for Mixpanel functions to check if environment is production
 * **/
const mixpanelWrapper = (func) => {
  return (...args) => {
    if (isProductionDomain()) {
      func(...args);
    }
    return {};
  };
};

/**
 * Initialize mixpanel script with debug mode
 * */
export const initializeMixpanel = mixpanelWrapper(() => {
  mixpanel.init(ENV.MIXPANEL_KEY, {
    debug: false,
    ignore_dnt: true,
  });
});

/**
 * Sets Mixpanel identity which will be a part of every event
 * */
export const setMixpanelIdentity = mixpanelWrapper((profile) => {
  const { email, current_organization } = profile;

  mixpanel.identify(email);
  mixpanel.people.set('name', email);
  mixpanel.people.set('email', email);
  setMixpanelSuperProperties(profile, current_organization?.label || '');
});

export const setMixpanelSuperProperties = mixpanelWrapper((profile, organizationLabel) => {
  const { email, current_organization, hashId, organizations, timestampCreated } = profile;

  const role = Roles.getRoleName(current_organization?.role);
  mixpanel.register({
    organizationName: organizationLabel,
    role,
    email: email,
    userId: hashId,
    numberOfOrganizations: organizations?.length || 0,
    firstLogin: timestampCreated,
  });
});

/**
 * Calculates upstream or downstream flag by source
 * @param {String} source - source is passed in URL or in <Actions /> component
 * */
export const getStreamDirection = (source) => {
  const isVendor =
    source === MixpanelAssignAssessmentSource.NewVendor ||
    source === MixpanelAssignAssessmentSource.RecentVendor ||
    source === MixpanelAssignAssessmentSource.Vendors;

  return isVendor ? 'downstream' : 'upstream';
};

/**
 * Track events for vendor or customer
 * @param {Object} payload - payload from vendor or customer request
 * @param {Boolean} isVendor - flag which helps to pick event name
 * */
export const mixpanelTrackVendorOrCustomer = mixpanelWrapper((payload, isVendor) => {
  const {
    email,
    corporateName,
    companyContact,
    assessments,
    phone,
    assessmentsGroups,
    relation,
    impactAnalysisInfoFilled,
  } = payload;
  const trackKey = isVendor ? 'vendor added' : 'customer added';

  const trackData = {
    companyName: corporateName,
    contactName: companyContact,
    contactEmail: email,
    contactPhone: phone,
    relation,

    impactAnalysisInfoFilled: !impactAnalysisInfoFilled,
    assessmentsAssigned: assessments?.length,
    assessmentsGroupsAssigned: !!assessmentsGroups,
    autoAssessmentPeriod: payload['assessments-period'],
  };

  mixpanel.track(trackKey, trackData);
});

/**
 * Track event of teammate added from organizations panel
 * @property {String} email
 * @property {Number} role
 * @property {Object[]} options - needed to get teammate role label
 * */
export const mixpanelTrackTeammateAdded = mixpanelWrapper(({ email, role, options, source }) => {
  const teammateRole = options.find(({ value }) => value === role)?.label;

  const trackData = {
    teammateEmail: email,
    teammateRole,
  };

  if (source) {
    trackData.source = source;
  }

  mixpanel.track('teammate added', trackData);
});

/**
 * Track event of collaboration request, send from assessment page
 * @param {Object} payload
 * */
export const mixpanelTrackCollaborationRequested = mixpanelWrapper(
  ({
    control_ids: selectedControls,
    controls,
    emails,
    assessment,
    categoryName,
    comment,
    source,
  }) => {
    const orgId = getCookie(ORG_ID_AUTH_KEY);
    const organization = getOrganizationsById(orgId);
    const controlsNames = selectedControls
      .split(',')
      .map((controlId) => controls.find(({ id }) => id === controlId)?.title);

    const trackData = {
      relation: orgId,
      source,
      assessmentName: assessment.title,
      assessmentProgress: assessment.total_score.progress,
      categoryName,
      question: controlsNames,
      comment,
      collaborators: emails,
      numberOfQuestions: controlsNames.length,
      assigneeOrganizationName: organization.label,
    };

    mixpanel.track('collaboration requested', trackData);
  },
);

/**
 * Track event whenever Notification is changed in organization
 * @param {Object} payload - payload from template editor. Old and new notification is required
 * to compose correct event data
 * */
export const mixpanelTrackNotificationEdited = mixpanelWrapper((payload) => {
  const { active, data, defaultRestored = false } = payload;
  const selectedTemplate = data[active.key];

  const trackData = {
    notificationLabel: active.label,
    subjectEdited: active.subject !== selectedTemplate.subject,
    bodyEdited: active.content !== selectedTemplate.content,
    testMessageSent: active.test,
    defaultRestored,
  };

  mixpanel.track('notification edited', trackData);
});

/**
 * Track event when logout is launched. Includes trigger from invalid token, idle state and user action
 * @param {Boolean} forced - tells if logout has been called automaticaly or not
 * @param {String} type - MixpanelLogoutTypes enum
 * */
export const mixpanelTrackLogout = mixpanelWrapper((forced = false, type) => {
  const trackData = {
    type,
    forced,
  };

  mixpanel.track('logout', trackData);
  mixpanel.reset();
});

export const mixpanelTrackLogin = mixpanelWrapper((email) => {
  mixpanel.track('login', { email });
});
/**
 * Track event of Assign Assessment but only from Assign Assessment page
 * @param {String} source - where exactly event has been called
 * @param {Object} payload - payload from assignSelfAssessment/assignAssessment request
 * */
export const mixpanelTrackAssignedAssessment = mixpanelWrapper(
  (
    source,
    { customers, due, templates, allCustomers, performedBy, allTemplates },
    streamDirection,
  ) => {
    const selectedCustomersNames = customers.map(
      (customerId) => allCustomers.find(({ id }) => id === customerId)?.profileCorporateName,
    );

    const selectedTemplatesNames = templates.map(
      (templateId) => allTemplates.find(({ key }) => key === templateId)?.label,
    );

    const trackData = {
      relation: getCookie(ORG_ID_AUTH_KEY),
      source,
      streamDirection,
      assignedOrganizationName: selectedCustomersNames,
      numberOfAssessments: templates.length,
      assessmentName: selectedTemplatesNames,
      selfAssessment: performedBy === AssessmentPerformedBy.self,
      numberOfRecipients: selectedCustomersNames.length,
      dueDate: moment(due).diff(moment(), 'days'),
    };

    mixpanel.track('assessment assigned', trackData);
  },
);

/**
 * Track event of Assign Assessment but only from Vendor/Customer pages (3rd step)
 * @param {String} source - where exactly event has been called
 * @param {Object} payload - payload from setProfileVendor/setProfileCustomer request
 * */
export const mixpanelTrackAssignedAssessmentFromVendorOrCustomer = mixpanelWrapper(
  (source, payload, streamDirection) => {
    const {
      corporateName = '',
      assessmentJson,
      assessments,
      relation,
      selfAssessment = false,
    } = payload;

    const numberOfAssessments = assessments.split(',').length;

    const trackData = {
      relation,
      source,
      streamDirection,
      assignedOrganizationName: corporateName,
      numberOfAssessments,
      assessmentName: assessmentJson.title,
      selfAssessment,
      numberOfRecipients: 1,
      dueDate: null,
    };

    mixpanel.track('assessment assigned', trackData);
  },
);

export const mixpanelTrackEditAssessmentTemplate = mixpanelWrapper((source, payload) => {
  mixpanel.track('assessment edited', {
    source,
    payload,
  });
});

/** UPSTREAM AI **/

/**
 * Track event params used in all three functions:
 * @param {String} relation - the relation ID (vendor-customer pairing)
 * @param {String} assessmentName - the assessment name
 * @param {String} assigneeOrganizationName - the name of the organization that sent the assessment
 * @param {String} originalControl - the original control the user asked about
 * @param {String} wasHelpful - if the user indicated the response was helpful. possible values: good/bad/na
 * @param {Boolean} error - whether we presented the user with a valid response or not
 * @param {Number} timeToResponse - the number of seconds it took for the response to arrive, or if the user clicked “cancel” write “999”
 **/

/**
 * Additional track event params used in example:
 * @param {String} source - from what button this call came from. Possible values: explain/example/improve
 * @param {String} exampleResponse - the response we presented the user
 * @param {Boolean} copied -  if the user clicked on the “copy” button
 **/

export const mixpanelTrackExampleResponseClicked = mixpanelWrapper((payload) => {
  mixpanel.track('example response clicked', payload);
});

/**
 * Additional track event params used in explain:
 * @param {String} explanation - the response we presented the user
 */

export const mixpanelTrackControlExplained = mixpanelWrapper((payload) => {
  mixpanel.track('control explained', payload);
});

/**
 * Additional track event params used in improve:
 * @param {String} improvedResponse - the response we presented the user
 * @param {Boolean} copied -  if the user clicked on the “copy” button
 */

export const mixpanelTrackResponseImproved = mixpanelWrapper((payload) => {
  mixpanel.track('response improved', payload);
});

/**
 * Track third party consent event:
 * @param {String} relation - the relation ID (vendor-customer pairing)
 * @param {String} assessmentName - the assessment name
 * @param {String} assigneeOrganizationName - the name of the organization that sent the assessment
 * @param {String} agreed - whether the user agreed or not to the popup
 */

export const mixpanelTrackThirdPartyConsent = mixpanelWrapper((payload) => {
  mixpanel.track('third party consent', payload);
});

export const mixpanelTrackOnBoarding = mixpanelWrapper((payload) => {
  mixpanel.track('signed up', {
    source: payload.source || 'new vendor',
    daysFromInvite: payload.daysFromInvite || 0,
  });
});

// chat sent:
/**
 * Track chat sent event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} source - the source of the chat chatSources.control | chatSources.recommendation | chatSources.cmRecommendation
 * @param {string} streamDirection - the direction of the chat (upstream | downstream)
 * @param {string} chat - the chat message
 */
export const mixpanelTrackChatSent = mixpanelWrapper((payload) => {
  mixpanel.track('chat sent', payload);
});

// Metadata edited:
/**
 * Track metadata edited event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} streamDirection - the direction of the metadata edit (upstream | downstream)
 * @param {boolean} expiration - whether this field was updated or not
 * @param {string} fileName - name of the edited file
 * @param {string[]} fieldsEdited - the edited fields
 */

export const mixpanelTrackMetadataEdited = mixpanelWrapper((payload) => {
  mixpanel.track('metadata edited', payload);
});

// finding viewed:
/**
 * Track finding viewed event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} streamDirection - the direction of the finding (upstream | downstream)
 * @param {string} source - the source of the finding ( assessment | recent | findings table )
 */

export const mixpanelTrackFindingViewed = mixpanelWrapper((payload) => {
  mixpanel.track('finding viewed', payload);
});

// Showcase created
/**
 * Track showcase created event
 * @param {string} name - showcase name
 * @param {string} assessmentsCount - number of assessments in the showecase
 * @param {string} description - showcase description
 */

export const mixpanelTrackShowcaseCreated = mixpanelWrapper((payload) => {
  mixpanel.track('showcase created', payload);
});

// Showcase shared
/**
 * Track showcase shared event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} name - showcase name
 * @param {string} assessmentsCount - number of assessments in the showecase
 * @param {string} description - showcase description
 * @param {string} source - the source of the showcase ( shared tab | customer card )
 */

export const mixpanelTrackShowcaseShared = mixpanelWrapper((payload) => {
  mixpanel.track('showcase shared', payload);
});

// Control Answered
/**
 * Track control answered event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} assessmentName - assessment name
 * @param {string} streamDirection - upstream/downstream
 * @param {string} originalControl - control value
 * @param {string} assessmentStatus - assessment status
 * @param {number} assessmentProgress - assessment progress
 */
export const mixpanelTrackControlAnswered = mixpanelWrapper((payload) => {
  mixpanel.track('control answered', payload);
});

// Assessment Submitted
/**
 * Track assessment submitted event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {number} assessmentProgress - assessment progress
 * @param {number} dueDate - number of days left until the due date (called dueDelta on our end)
 * @param {bool} forceSubmitted - if was forcefully submitted
 * @param {string} assessmentName - assessment name
 * @param {string} assignedOrganizationName - the recipient organization name
 * @param {string} assigneeOrganizationName - the name of the organization that sent the assessment
 * */
export const mixpanelTrackAssessmentSubmitted = mixpanelWrapper((payload) => {
  mixpanel.track('assessment submitted', payload);
});

// Assessment Force Submitted
/**
 * Track assessment force submitted event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {number} assessmentProgress - assessment progress
 * @param {number} originalDueDate - number of days left until the original due date (called dueDelta on our end)
 * @param {bool} forceSubmitted - if was forcefully submitted
 * @param {string} assessmentName - assessment name
 * @param {string} assignedOrganizationName - the recipient organization name
 * @param {string} assigneeOrganizationName - the name of the organization that sent the assessment
 * @param {string} reason - due date/by user
 * */
export const mixpanelTrackAssessmentForceSubmitted = mixpanelWrapper((payload) => {
  mixpanel.track('assessment force submitted', payload);
});

// Assessment Reverted
/**
 * Track assessment reverted event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {number} assessmentProgress - assessment progress
 * @param {string} assessmentName - assessment name
 * @param {string} assignedOrganizationName - the recipient organization name
 * @param {string} assigneeOrganizationName - the name of the organization that sent the assessment
 * */
export const mixpanelTrackAssessmentReverted = mixpanelWrapper((payload) => {
  mixpanel.track('assessment reverted', payload);
});

// Assessment Reviewed
/**
 * Track assessment reviewed event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} assessmentName - assessment name
 * @param {string} assignedOrganizationName - the recipient organization name
 * @param {string} assigneeOrganizationName - the name of the organization that sent the assessment
 * */
export const mixpanelTrackAssessmentReviewed = mixpanelWrapper((payload) => {
  mixpanel.track('assessment reviewed', payload);
});

// Control Reviewed
/**
 * Track control reviewed event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} assessmentName - assessment name
 * @param {string} originalControl - control value
 * @param {string} assignedOrganizationName - the recipient organization name
 * @param {string} assigneeOrganizationName - the name of the organization that sent the assessment
 * @param {string} type - approved / rejected / deselected
 * @param {string} previousType - approved / rejected / deselected
 * */
export const mixpanelTrackControlReviewed = mixpanelWrapper((payload) => {
  mixpanel.track('control reviewed', payload);
});

// Finding Reviewed
/**
 * Track finding reviewed event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} assessmentName - assessment name
 * @param {string} originalControl - control value
 * @param {string} originalFinding - finding value
 * @param {string} assignedOrganizationName - the recipient organization name
 * @param {string} assigneeOrganizationName - the name of the organization that sent the assessment
 * @param {string} type - approved / rejected / deselected
 * @param {string} previousType - approved / rejected / deselected
 * */
export const mixpanelTrackFindingReviewed = mixpanelWrapper((payload) => {
  mixpanel.track('finding reviewed', payload);
});

// Finding Status Changed
/**
 * Track finding status changed event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} streamDirection - upstream/downstream
 * @param {string} assessmentName - assessment name
 * @param {string} originalControl - control value
 * @param {string} originalFinding - finding value
 * @param {string} assignedOrganizationName - the recipient organization name
 * @param {string} assigneeOrganizationName - the name of the organization that sent the assessment
 * @param {string} type - approved / rejected / deselected
 * @param {string} previousType - approved / rejected / deselected
 * */
export const mixpanelFindingStatusChanged = mixpanelWrapper((payload) => {
  mixpanel.track('finding status changed', payload);
});

// Recommendation Updated
/**
 * Track recommendation updated event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} type - create, update, delete
 * @param {string} assessmentName - assessment name
 * @param {string} originalControl - control value
 * @param {string} originalFinding - finding value
 * @param {string} assignedOrganizationName - the recipient organization name
 * @param {string} description - recommendation description
 * @param {number} numberOfRecommendations - number of recommendations before adding this recommendation
 * */
export const mixpanelRecommendationUpdated = mixpanelWrapper((payload) => {
  mixpanel.track('recommendation updated', payload);
});

// Finding Added
/**
 * Track finding added event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} assessmentName - assessment name
 * @param {string} originalControl - control value
 * @param {string} assignedOrganizationName - the recipient organization name
 * @param {string} description - finding description
 * @param {string} impact - trivial/minor/moderate/major/extreme
 * @param {string} probability - rare/unlikely/moderate/likely/very likely
 * @param {string} source - editor/assessment
 * @param {bool} recommendtions - if recommendation is added
 * */
export const mixpanelFindingAdded = mixpanelWrapper((payload) => {
  mixpanel.track('finding added', payload);
});

// Recommendation Due Date Updated
/**
 * Track recommendation due date updated event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} assessmentName - assessment name
 * @param {string} originalControl - control value
 * @param {string} findingRisk - low/medium/high/critical
 * @param {string} assignedOrganizationName - the recipient organization name
 * @param {number} dueDateChange - number of days added/subtracted (e.g. "4", "-13")
 * */
export const mixpanelRecommendationDueDateUpdated = mixpanelWrapper((payload) => {
  mixpanel.track('recommendation due date updated', payload);
});

// Must Upload Evidence Updated
/**
 * Track must upload evidence updated event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} assessmentName - assessment name
 * @param {string} originalControl - control value
 * @param {string} findingRisk - low/medium/high/critical
 * @param {string} assignedOrganizationName - the recipient organization name
 * @param {bool} newValue - if evidence is required
 * */
export const mixpanelMustUploadEvidenceUpdated = mixpanelWrapper((payload) => {
  mixpanel.track('must upload evidence updated', payload);
});

// Internal Note Updated
/**
 * Track internal note updated event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} source - control, recommendation, vendor
 * @param {string} type - created, updated, deleted
 * @param {string} description - note's content
 * */
export const mixpanelInternalNoteUpdated = mixpanelWrapper((payload) => {
  mixpanel.track('internal note updated', payload);
});

// Answer Repository Opened
/**
 * Track answer repository opened event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} assessmentName - assessment name
 * @param {string} assigneeOrganizationName - the name of the organization that sent the assessment
 * @param {string} originalControl - control value
 * */
export const mixpanelAnswerRepositoryOpened = mixpanelWrapper((payload) => {
  mixpanel.track('answer repository opened', payload);
});

// Answer Repository Response Selected
/**
 * Track answer bank response selected event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} assessmentName - assessment name
 * @param {string} assigneeOrganizationName - the name of the organization that sent the assessment
 * @param {string} originalControl - control value
 * @param {string} response - the selected response - a strings of all the option values combined
 * */
export const mixpanelAnswerRepositoryResponseSelected = mixpanelWrapper((payload) => {
  mixpanel.track('answer bank repository selected', payload);
});

// Assessment App Activated
/**
 * Track assessment app activated event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} assessmentName - assessment name
 * @param {string} assigneeOrganizationName - the name of the organization that sent the assessment
 * @param {string} type - aws/azure
 * */
export const mixpanelAssessmentAppActivated = mixpanelWrapper((payload) => {
  mixpanel.track('assessment app activated', payload);
});

// Filter Applied
/**
 * Track filter applied event
 * @param {string} source - my vendors dashboard, vendors table, my-vendors assessments table, findings table, customers table, my-customers assessments table
 * @param {number} numberOfFilters - the number of filters applied
 * @param {list} filterNames - the name of the organization that sent the assessment
 * @param {number} numberOfFilteredValues - sum of the number of selected filter values from all filters
 * */
export const mixpanelFilterApplied = mixpanelWrapper((payload) => {
  mixpanel.track('filter applied', payload);
});

// Riskrecon View Details Clicked
/**
 * Track riskrecon view details clicked event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} vendorName - vendor name
 * @param {number} numberOfFindings - number of findings
 * @param {string} rating - e.g. A, B, C...
 * */
export const mixpanelRiskreconViewDetailsClicked = mixpanelWrapper((payload) => {
  mixpanel.track('riskrecon view details clicked', payload);
});

// Riskrecon Get Credits Clicked
/**
 * Track riskrecon get credits clicked event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} vendorName - vendor name
 * @param {number} numberOfFindings - number of findings
 * @param {string} rating - e.g. A, B, C...
 * @param {number} creditsBalanceBefore - the number of credits before the action
 * */
export const mixpanelRiskreconGetCreditsClicked = mixpanelWrapper((payload) => {
  mixpanel.track('riskrecon get credits clicked', payload);
});

// Riskrecon Categories Purchased
/**
 * Track riskrecon categories purchased event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} vendorName - vendor name
 * @param {number} numberOfFindings - number of findings
 * @param {string} rating - e.g. A, B, C...
 * @param {number} creditsBalanceBefore - the number of credits before the action
 * @param {number} daysSincePreviousPurchase - the number of days since the previous purchase, "-1" if never purchased before
 * */
export const mixpanelRiskreconCategoriesPurchased = mixpanelWrapper((payload) => {
  mixpanel.track('riskrecon categories purchased', payload);
});

// Riskrecon Report Purchased
/**
 * Track riskrecon report purchased event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} vendorName - vendor name
 * @param {number} numberOfFindings - number of findings
 * @param {string} rating - e.g. A, B, C...
 * @param {number} creditsBalanceBefore - the number of credits before the action
 * @param {number} daysSincePreviousPurchase - the number of days since the previous purchase, "-1" if never purchased before
 * */
export const mixpanelRiskreconReportPurchased = mixpanelWrapper((payload) => {
  mixpanel.track('riskrecon report purchased', payload);
});

// CM Findings Shared With Vendor
/**
 * Track CM findings shared with vendor event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} vendorName - vendor name
 * @param {number} numberOfFindings - number of findings
 * @param {string} type - riskrecon, security scorecard
 * */
export const mixpanelCMfindingsSharedWithVendor = mixpanelWrapper((payload) => {
  mixpanel.track('CM findings shared with vendor', payload);
});

// BO Assignment Updated
/**
 * Track BO assignment updated event
 * @param {string} relation - the relation ID (vendor-customer pairing)
 * @param {string} role - bo/auditor
 * @param {string} type - added / removed
 * @param {string} updatedEmail - the email of the BO
 * @param {string} vendorName - vendor name
 * */
export const mixpanelBOassignmentUpdated = mixpanelWrapper((payload) => {
  mixpanel.track('BO assignment updated', payload);
});
