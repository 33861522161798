import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/Icon';
import './organizationIco.scss';

const OrganizationIco = ({ className, size }) => {
  const iconClassName = classNames('base-layout-profile-menu-organization-ico', className, {
    'base-layout-profile-menu-organization-ico--size-small': size === 'small',
    'base-layout-profile-menu-organization-ico--size-medium': size === 'medium',
  });
  return <Icon className={iconClassName} icon="organization2" />;
};

OrganizationIco.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
};

export default OrganizationIco;
