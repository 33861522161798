import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getOverview } from 'api/overview';

const initialState = {
  overview: {},
  loading: false,
};

const reducers = createSlice({
  name: 'overview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOverview.pending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        isAnyOf(getOverview.fulfilled, getOverview.rejected),
        (state, { payload: { summary } }) => {
          state.overview = summary;
          state.loading = false;
        },
      );
  },
});

export default reducers;
