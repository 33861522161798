import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { translate } from 'utils/index';
import { saveTemplate } from 'api/editor';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';

import {
  getControlsByCategoryToDisable,
  getControlsByCategoryToEnable,
  groupTriggersByCategories,
  mergeCategories,
} from 'pages/Editor/lib';
import { selectTemplate } from 'pages/Editor/selectors';
import { messages } from 'pages/Editor/messages';
import TriggersModal from './triggersModal';
import CategoryTriggers from './categoryTriggers';
import AddQuestion from './addQuestion';
import { EditIcon, TrashIcon } from 'components/Icons';
import './otherTrigger.scss';

const { Panel } = Collapse;

const addQuestionKey = 'addQuestion';

const OtherTrigger = ({ answerType, control, showAddQuestion, setShowAddQuestion }) => {
  const { categories = [] } = useSelector(selectTemplate);
  const dispatch = useDispatch();
  const [activeKeys, setActiveKeys] = useState(['1']);
  const [triggersToRemove, setTriggersToRemove] = useState();
  const [loading, setLoading] = useState();
  const [showTriggersModal, setShowTriggersModal] = useState();
  const [activeAnswer, setActiveAnswer] = useState();

  useEffect(() => {
    if ((control.triggers[showAddQuestion] || []).length === 0) {
      setActiveKeys((state) => [...state, addQuestionKey]);
      return;
    }

    setActiveKeys((state) => [...state, showAddQuestion]);
  }, [showAddQuestion]);

  const expandIcon = ({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />;

  const onControlSave = (key, triggers) => {
    const payload = {
      categories: [
        {
          id: control.categoryId,
          controls: [
            {
              ...control,
              triggers: {
                ...control.triggers,
                [key]: control.triggers[key] ? [...control.triggers[key], ...triggers] : triggers,
              },
            },
          ],
        },
      ],
    };
    dispatch(saveTemplate(payload)).unwrap();
  };

  const onEditTriggers = (key, triggers) => {
    const category = {
      id: control.categoryId,
      controls: [
        {
          ...control,
          triggers: {
            ...control.triggers,
            [key]: triggers,
          },
        },
      ],
    };

    const triggersToEnable = control.triggers[key].filter((id) => !triggers.includes(id));
    const controlsByCategoryToEnable = getControlsByCategoryToEnable(triggersToEnable, categories);
    const controlsByCategoryToDisable = getControlsByCategoryToDisable(triggers, categories);
    const controlsByCategory = mergeCategories(
      controlsByCategoryToEnable,
      controlsByCategoryToDisable,
    );

    const payload = {
      categories: mergeCategories([category], controlsByCategory),
    };

    dispatch(saveTemplate(payload)).unwrap();
  };

  const onEdit = (key) => {
    setActiveAnswer(key);
    setShowTriggersModal(true);
  };

  const onRemove = async () => {
    setLoading(true);
    const triggers = { ...control.triggers };
    delete triggers[triggersToRemove];

    const category = {
      id: control.categoryId,
      controls: [
        {
          ...control,
          triggers,
        },
      ],
    };

    const controlsByCategoryToEnable = getControlsByCategoryToEnable(
      control.triggers[triggersToRemove],
      categories,
    );

    const payload = {
      categories: mergeCategories([category], controlsByCategoryToEnable),
    };

    await dispatch(saveTemplate(payload)).unwrap();
    setTriggersToRemove(null);
    setLoading(false);
  };

  const onAddQuestionSuccess = (controlId) => {
    setShowAddQuestion(null);
    onControlSave(showAddQuestion, [controlId]);
    setActiveKeys((state) => [...state, showAddQuestion]);
  };

  const renderAddQuestion = (className) => {
    return (
      <AddQuestion
        className={classNames('editor-other-trigger__add-question', className)}
        defaultStage="addQuestion"
        defaultValues={{ enabled: false }}
        onSuccess={onAddQuestionSuccess}
        onCancel={() => setShowAddQuestion(null)}
        hideAddButton
      />
    );
  };

  return (
    <div className="editor-other-trigger">
      <div className="editor-other-trigger__header">
        <div className="editor-other-trigger__header-title">
          {translate(messages.followUpQuestions)}
        </div>
      </div>
      <div className="editor-other-trigger__triggers-body">
        <Collapse
          bordered={false}
          activeKey={activeKeys}
          expandIcon={expandIcon}
          className="editor-other-trigger__triggers"
          onChange={(keys) => setActiveKeys(keys)}
        >
          {Object.entries(control.triggers)
            .filter(([key, triggers]) => triggers.length && Number(key) === 3)
            .map(([key, triggers]) => (
              <Panel
                key={key}
                className="editor-other-trigger__trigger"
                header={
                  <div className="editor-other-trigger__trigger-header">
                    <span className="editor-other-trigger__trigger-header-suffix">
                      {translate(messages.if)}{' '}
                    </span>
                    <span>
                      {answerType.options.find((option) => option.key === Number(key)).value}
                    </span>
                  </div>
                }
              >
                <div className="editor-other-trigger__trigger-body">
                  <div className="editor-other-trigger__trigger-body-triggers">
                    {Object.entries(groupTriggersByCategories(triggers, categories)).map(
                      ([categoryId, rest]) => (
                        <CategoryTriggers
                          key={categoryId}
                          category={categories.find(({ id }) => id === categoryId)}
                          controlId={control.id}
                          triggers={rest.map(({ trigger }) => trigger)}
                        />
                      ),
                    )}
                  </div>
                  <div className="editor-other-trigger__actions">
                    <Button
                      className="editor-other-trigger__action"
                      type="link"
                      onClick={() => onEdit(key)}
                    >
                      <EditIcon />
                    </Button>

                    <Button
                      className="editor-other-trigger__action"
                      type="link"
                      onClick={() => setTriggersToRemove(key)}
                    >
                      <TrashIcon />
                    </Button>
                  </div>
                </div>
                {showAddQuestion === Number(key) && renderAddQuestion()}
              </Panel>
            ))}
          {showAddQuestion && (control.triggers[showAddQuestion] || []).length === 0 && (
            <Panel
              key={addQuestionKey}
              className="editor-other-trigger__trigger"
              header={
                <div className="editor-other-trigger__trigger-header">
                  <span className="editor-other-trigger__trigger-header-suffix">
                    {translate(messages.if)}{' '}
                  </span>
                  <span>
                    {
                      answerType.options.find((option) => option.key === Number(showAddQuestion))
                        .value
                    }
                  </span>
                </div>
              }
            >
              {renderAddQuestion('editor-other-trigger__add-question--first-trigger')}
            </Panel>
          )}
        </Collapse>
      </div>
      <RemoveConfirmationModal
        headerText={translate(messages.removeFollowUpQuestionPopupHeader)}
        confirmLoading={loading}
        onOk={onRemove}
        onCancel={() => setTriggersToRemove(null)}
        text={translate(messages.removeFollowUpQuestionPopup)}
        open={!!triggersToRemove}
      />
      <TriggersModal
        controlId={control.id}
        isEdit
        onSelectTriggers={onEditTriggers}
        selectedAnswer={activeAnswer}
        selectedTriggers={control.triggers[activeAnswer] || []}
        toggleModal={setShowTriggersModal}
        open={showTriggersModal}
      />
    </div>
  );
};

OtherTrigger.propTypes = {
  answerType: PropTypes.object,
  control: PropTypes.object,
  onClose: PropTypes.func,
  showAddQuestion: PropTypes.func,
  setShowAddQuestion: PropTypes.func,
};

export default OtherTrigger;
