import { useState, useMemo } from 'react';

// UTILS
import {
  mixpanelTrackControlExplained,
  mixpanelTrackExampleResponseClicked,
  mixpanelTrackResponseImproved,
  mixpanelTrackThirdPartyConsent,
} from 'utils/mixpanel';
import { buttonTypes } from 'components/UpstreamAI/lib';

function useMixpanelUpstreamAiTrack({
  selectedButton,
  buttonType: source,
  relation,
  assessmentName,
  assigneeOrganizationName,
  originalControl,
}) {
  const [copied, setCopy] = useState(false);
  const [wasHelpful, setWasHelpful] = useState('na');
  const [timeToResponse, setTimeToResponse] = useState(999);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState(null);

  const payload = {
    relation,
    assessmentName,
    assigneeOrganizationName,
    originalControl,
  };

  const trackFunction = useMemo(() => {
    const commonDynamicPayload = {
      wasHelpful,
      timeToResponse,
      error,
    };

    switch (selectedButton) {
      case buttonTypes.example:
        return () =>
          mixpanelTrackExampleResponseClicked({
            ...payload,
            ...commonDynamicPayload,
            exampleResponse: response,
            source,
            copied,
          });
      case buttonTypes.explain:
        return () =>
          mixpanelTrackControlExplained({
            ...payload,
            ...commonDynamicPayload,
            explanation: response,
          });
      case buttonTypes.improve:
        return () =>
          mixpanelTrackResponseImproved({
            ...payload,
            ...commonDynamicPayload,
            improvedResponse: response,
            copied,
          });
      default:
        return () => {};
    }
  }, [selectedButton, copied, wasHelpful, timeToResponse, error, response]);

  return {
    trackFunction,
    setCopy,
    setWasHelpful,
    setTimeToResponse,
    setError,
    setResponse,
    trackThirdPartyConsent: (value) =>
      mixpanelTrackThirdPartyConsent({
        relation,
        assessmentName,
        assigneeOrganizationName,
        agreed: value,
      }),
  };
}

export default useMixpanelUpstreamAiTrack;
