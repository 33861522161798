import React, { useState } from 'react';
import classNames from 'classnames';

import Popup from 'ui/Popup';
import { translate } from 'utils/index';
import { messages } from './messages';
import Input from 'ui/Input';
import Select from 'ui/Select';
import { useDispatch } from 'react-redux';
import { Roles } from 'constants/roles';
import { useFormik } from 'formik';
import { newUserSchema } from 'pages/Organization/schema';

import { addOrganizationProfile } from 'api/organizations';
import { mixpanelTrackTeammateAdded } from 'utils/mixpanel';
import { notification } from 'antd';
import Button from 'ui/Button';
import { useSearchParams } from 'react-router-dom';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { getCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY } from 'utils/auth';
import Icon from 'ui/Icon';
import { getOrganizationsById } from 'utils/organizations';
import './inviteTeamMemberButton.scss';

const InviteTeamMemberButton = () => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [searchParams] = useSearchParams();
  const orgIndex = searchParams.get('orgIndex');
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const initValues = { email: '', role: undefined };

  const setPopupOnClick = () => {
    setShowPopup((prev) => !prev);
    setValues(initValues, false);
    setErrors({});
  };

  const options = [
    { label: translate(messages.Admin), value: Roles.Admin, key: 'AdminRoleSelect' },
    { label: translate(messages.BO), value: Roles.BO, key: 'BORoleSelect' },
    { label: translate(messages.SO), value: Roles.SO, key: 'SORoleSelect' },
    { label: translate(messages.Reporter), value: Roles.Reporter, key: 'ReporterRoleSelect' },
    { label: translate(messages.Viewer), value: Roles.Viewer, key: 'ViewerRoleSelect' },
  ];

  const currentOrgId = getCookie(ORG_ID_AUTH_KEY);

  const {
    errors,
    values: { email, role },
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    setErrors,
  } = useFormik({
    validateOnChange: false,

    initialValues: {
      email: '',
      role: undefined,
    },

    validationSchema: newUserSchema(
      translate(messages.inviteTeamMemberEmailErrorFormat),
      translate(messages.inviteTeamMemberEmailRequired),
      translate(messages.inviteTeamMemberRoleRequired),
    ),

    onSubmit: async () => {
      const payload = { email, role, orgId: currentOrgId, orgIndex };
      const resp = await dispatch(addOrganizationProfile(payload));

      setShowPopup((prev) => !prev);
      const source = isMyVendorsTabSelected ? 'upstream header' : 'downstream header';
      mixpanelTrackTeammateAdded({ ...payload, options, source });

      if (resp?.payload?.profiles) {
        return notification.success({ message: translate(messages.inviteTeamMemberSuccess) });
      }

      return notification.error({ message: translate(messages.inviteTeamMemberError) });
    },
  });

  const organization = getOrganizationsById(currentOrgId);

  return (
    <div className="invite-team-member-button">
      <Button
        className={classNames('invite-team-member-button__button', {
          'invite-team-member-button__button--purple': isMyVendorsTabSelected,
        })}
        withHover
        color="dark-blue"
        link
        data-test="invite-team-member-button__button"
        onClick={setPopupOnClick}
      >
        <Icon className="invite-team-member-button__button-icon" icon="invite-member" />{' '}
        {translate(messages.inviteTeamMemberButton)}
      </Button>
      <Popup.WithForm
        className="invite-team-member-button__popup"
        open={showPopup}
        onClickCancel={setPopupOnClick}
        withIconOnTitle="plus"
        title={translate(messages.inviteTeamMemberUserTitle)}
        width={400}
        onClickOk={handleSubmit}
      >
        <div className="invite-team-member-button__popup-subtitle">
          {translate(messages.toOrganization)}:{' '}
          <span className="invite-team-member-button__popup-organization">
            {organization?.label}
          </span>
        </div>
        <Input
          block
          name="email"
          type="text"
          value={email}
          placeholder={translate(messages.inviteTeamMemberEmailPlaceholder)}
          onChange={handleChange}
          error={errors.email}
          data-test="invite-team-member-button-email"
        />
        <Select
          placeholder={translate(messages.inviteTeamMemberRolePlaceholder)}
          options={options}
          size="large"
          color="gray"
          name="role"
          value={role}
          onSelect={(value) => setFieldValue('role', value, false)}
          error={errors.role}
          className="invite-team-member-button__select"
          showSearch={false}
        />
      </Popup.WithForm>
    </div>
  );
};

export default InviteTeamMemberButton;
