import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';

import { translate } from 'utils/index';
import DashboardChart from './dashboardChart';
import Button from 'ui/Button';
import { useTheme } from 'hooks/theme';
import { ExportPdfFileIcon } from 'components/Icons';
import { messages } from './messages';
import { getDashboardLayout } from 'api/dashboard';
import Loader from 'ui/Loader';
import Filters from 'components/Filters';
import { FILTERABLE_KEYS } from 'components/Filters/lib';
import { DASHBOARD_ALLOWED_FILTERS_DOWNSTREAM } from 'components/Filters/allowedFilters';

import './index.scss';

const Dashboard = () => {
  const { colorTheme } = useTheme();
  const dispatch = useDispatch();
  const { charts, loading } = useSelector((state) => state.dashboard);
  const targetRef = useRef();

  const handleDownloadPdfClick = async () => {
    const element = targetRef.current;

    // Get the full dimensions of the content, including any overflow
    const fullWidth = element.scrollWidth;
    const fullHeight = element.scrollHeight;

    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      logging: false,
      width: fullWidth,
      height: fullHeight,
      windowWidth: fullWidth,
      windowHeight: fullHeight,
    });

    const imgData = canvas.toDataURL('image/png');

    // Calculate the PDF dimensions based on the aspect ratio of the content
    const aspectRatio = fullWidth / fullHeight;
    let pdfWidth = 595.28; // A4 width in points
    let pdfHeight = pdfWidth / aspectRatio;

    // If height exceeds A4 height, adjust width to fit A4
    if (pdfHeight > 841.89) {
      // A4 height in points
      pdfHeight = 841.89;
      pdfWidth = pdfHeight * aspectRatio;
    }

    const pdf = new jsPDF({
      orientation: pdfWidth > pdfHeight ? 'landscape' : 'portrait',
      unit: 'pt',
      format: [pdfWidth, pdfHeight],
    });

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(`Findings Dashboard ${moment().format('DD-MM-YYYY HH:mm')}.pdf`);
  };

  useEffect(() => {
    dispatch(getDashboardLayout());
  }, []);

  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <div className="dashboard__header__container">
          <h1>{translate(messages.dashboardPageTitle)}</h1>
          <div className="dashboard__header__container__seperator"></div>
          <Filters
            filterableKey={FILTERABLE_KEYS.DASHBOARD_FILTERS}
            allowedFilters={DASHBOARD_ALLOWED_FILTERS_DOWNSTREAM}
          />
        </div>
        <Button
          color={colorTheme}
          onClick={handleDownloadPdfClick}
          className="dashboard__header__download-button"
          data-test="download-button"
          size="sm"
          loading={loading}
        >
          <ExportPdfFileIcon /> {translate(messages.download)}
        </Button>
      </div>
      <div className="dashboard__charts" ref={targetRef}>
        {charts?.map((row, rowIndex) => (
          <div key={'dashboard-row-' + rowIndex} className="dashboard__charts-row">
            {row?.map((chart, colIndex) => (
              <DashboardChart
                key={'dashboard-chart-' + colIndex}
                rowIndex={rowIndex}
                colIndex={colIndex}
              />
            ))}
          </div>
        ))}
        {loading && <Loader />}
      </div>
    </div>
  );
};

export default Dashboard;
