import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { translate } from 'utils/index';
import Icon from 'ui/Icon';
import Form from 'ui/Form';
import Select from 'ui/Select';
import Button from 'ui/Button';
import Divider from 'ui/Divider';
import Expander from 'ui/Expander';
import DatePicker from 'ui/DatePicker';
import RadioGroup from 'ui/RadioGroup';
import CheckboxGroup from 'ui/CheckboxGroup';
import PageHeader from 'components/PageHeader';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useTheme } from 'hooks/theme';
import ROUTES from 'constants/routes';
import { BACKEND_INPUT_DATE_FORMAT } from 'constants/date';
import { AssessmentPerformedBy } from 'constants/assessmentPerformedBy';
import { selectCustomers } from 'components/AssessmentsFilters/selectors';
import { selectTemplatesOptions } from 'pages/Assessments/templates/selectors';
import { assignAssessment, assignSelfAssessment } from 'api/assessment';
import { getAssessmentsTemplates } from 'api/assessmentsTemplates';
import { getSummaryCustomers } from 'api/summary';
import { generalMessages } from 'constants/messages';
import ImportWizardMenuModal from 'pages/Assessments/importWizardMenuModal';
import { mixpanelTrackAssignedAssessment } from 'utils/mixpanel';
import { API_STATUS } from 'constants/api';

import { assignAssessmentFullSchema } from './schema';
import { setSentAssessmentCustomers, setShowSentAssessmentPopup } from './reducers';
import { messages } from './messages';

import './index.scss';

const AssignAssessment = () => {
  const appNavigate = useAppNavigate();
  const location = useLocation();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { colorTheme } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const templates = useSelector(selectTemplatesOptions)
    .map((template) => ({
      ...template,
      label: template.label || translate(messages.untitledAssessment),
      categoryCount: template.categoryCount,
      dueDelta: template.dueDelta,
    }))
    .filter(({ categoryCount }) => categoryCount > 0);

  const customers = useSelector(selectCustomers);
  const [loading, setLoading] = useState(false);
  const [openWizard, setOpenWizard] = useState(false);

  const initialValues = {
    customers: location.state?.customerId ? [location.state.customerId] : [],
    due: moment().add(location.state?.dueDelta || 14, 'days'),
    performedBy: AssessmentPerformedBy.vendor,
    templates: location.state?.templateId ? [location.state.templateId] : [],
  };

  const { errors, values, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    validationSchema: assignAssessmentFullSchema(
      translate(messages.assessmentTypeRequired),
      translate(messages.vendorRequired),
    ),
    onSubmit: async () => {
      setLoading(true);

      mixpanelTrackAssignedAssessment(
        location?.state?.source,
        {
          ...values,
          allTemplates: templates,
          allCustomers: customers,
        },
        isMyVendorsTabSelected ? 'downstream' : 'upstream',
      );

      let results = null;

      if (values.performedBy === AssessmentPerformedBy.self) {
        results = await Promise.all(
          values.templates.map(async (template) =>
            dispatch(
              assignSelfAssessment({
                id: template,
              }),
            ).unwrap(),
          ),
        );

        if (values?.templates?.length === 1) {
          return appNavigate(ROUTES.CUSTOMER_ASSESSMENT, {
            assessmentId: results[0].customer_assessment,
          });
        }
      } else {
        results = await Promise.all(
          values.templates.flatMap((template) =>
            values.customers.map(async (customer) =>
              dispatch(
                assignAssessment({
                  id: template,
                  customer,
                  due: values.due.format(BACKEND_INPUT_DATE_FORMAT),
                }),
              ).unwrap(),
            ),
          ),
        );
      }

      setLoading(false);
      const customerNames = getCustomerNames();
      if (results.some(({ status }) => status !== API_STATUS.SUCCESS)) {
        return;
      }

      dispatch(setShowSentAssessmentPopup(true));
      dispatch(setSentAssessmentCustomers(customerNames));
      return navigate(-1);
    },
  });

  const onClickAddTemplate = () => setOpenWizard((prevState) => !prevState);

  const handlePopupClose = () => setOpenWizard(false);

  const getCustomerNames = () => {
    const names = customers
      .filter((customer) => values.customers.includes(customer.id))
      .map((customer) => customer.profileCorporateName);

    return names.join(', ');
  };

  useEffect(() => {
    dispatch(getAssessmentsTemplates());
    dispatch(getSummaryCustomers());
  }, []);

  const performedByOptions = [
    {
      key: 'vendor',
      label: translate(messages.customers),
      value: AssessmentPerformedBy.vendor,
    },
    {
      key: 'self',
      label: translate(messages.self),
      value: AssessmentPerformedBy.self,
    },
  ];

  const customersOptions = customers.map(({ profileCorporateName, id }) => ({
    key: id,
    value: id,
    label: profileCorporateName,
  }));

  const onChangeTemplates = (value) => {
    setFieldValue('templates', value);
    if (value.length === 1) {
      const template = templates.find(({ key }) => key === value[0]);
      setFieldValue('due', moment().add(template?.dueDelta || 14, 'days'));
    }
  };

  return (
    <div className="assign-assessment">
      <PageHeader className="assign-assessment__header">
        <Icon icon="plus" />
        {translate(messages.assignAssessment)}
      </PageHeader>
      <Form onSubmit={handleSubmit}>
        <div className="assign-assessment__main">
          <div className="assign-assessment__container">
            <label className="assign-assessment__form-label">
              {translate(messages.selectFramework)}
            </label>
            <p>{translate(messages.selectFrameworkDescription)}</p>
            <Expander className="assign-assessment__templates" teaserHeight="230px">
              <CheckboxGroup
                data-test="assign-assessment-items"
                setItemDataTest={({ label }) => `assign-assessment-items-${label}`}
                options={templates}
                name="assessmentType"
                mode="label"
                value={values.templates}
                onChange={onChangeTemplates}
              />
            </Expander>
            {touched.templates && errors.templates && (
              <p className="assign-assessment__error">{errors.templates}</p>
            )}
            <Divider size="xxs" />
            <div className="assign-assessment__additional-actions">
              <div className="assign-assessment__additional-actions-suffix">
                {translate(messages.or)}
              </div>

              <Button
                data-test="assign-assessment-create-new"
                onClick={() => {
                  appNavigate(
                    isMyVendorsTabSelected
                      ? ROUTES.VENDOR_ASSESSMENT_TEMPLATE
                      : ROUTES.CUSTOMER_ASSESSMENT_TEMPLATE,
                    { templateId: uuidv4() },
                  );
                }}
                link
                className="assign-assessment__new-assessment-button"
              >
                <Icon icon="plus" />
                {translate(messages.createNewFramework)}
              </Button>
              <Button
                onClick={onClickAddTemplate}
                link
                data-test="assign-assessment-create-new-from-file"
                className="assign-assessment__new-assessment-button"
              >
                <Icon icon="plus" />
                {translate(messages.createNewFrameworkFromFile)}
              </Button>
            </div>
            <div>
              {!isMyVendorsTabSelected && (
                <>
                  <label className="assign-assessment__form-label">
                    {translate(messages.performedBy)}
                  </label>
                  <RadioGroup
                    data-test="assign-assessment"
                    className="assign-assessment__performed-by-select"
                    options={performedByOptions}
                    name="performedBy"
                    onChange={(event) =>
                      setFieldValue('performedBy', parseInt(event.target.value, 10))
                    }
                    value={values.performedBy}
                  />
                </>
              )}
              <label className="assign-assessment__form-label-select-vendor">
                {translate(
                  isMyVendorsTabSelected ? messages.selectVendor : messages.selectCustomer,
                )}
              </label>
              <label className="assign-assessment__form-label-select-vendor-description">
                {translate(
                  isMyVendorsTabSelected
                    ? messages.selectVendorDescription
                    : messages.selectCustomerDescription,
                )}
              </label>
              <Select
                data-test="assign-assessment-select-vendor"
                className="assign-assessment__customer-select"
                color="gray"
                disabled={values.performedBy === AssessmentPerformedBy.self}
                error={touched.customers && errors.customers}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                mode="multiple"
                optionFilterProp="label"
                options={customersOptions}
                onChange={(value) => setFieldValue('customers', value)}
                placeholder={translate(messages.pleaseSelect)}
                size="large"
                value={values.customers}
              />
              <Button
                link
                className="assign-assessment__new-customer-button"
                data-test="assign-assessment-create-new-vendor"
                onClick={() =>
                  appNavigate(
                    isMyVendorsTabSelected
                      ? ROUTES.VENDOR_NEW_VENDOR
                      : ROUTES.CUSTOMER_NEW_CUSTOMER,
                  )
                }
              >
                <Icon icon="plus" />
                {translate(
                  isMyVendorsTabSelected ? messages.createNewVendor : messages.createNewCustomer,
                )}
              </Button>
              <label className="assign-assessment__form-label">{translate(messages.dueDate)}</label>
              <DatePicker
                allowClear={false}
                color="gray"
                className="assign-assessment__due-date-picker"
                disabled={values.performedBy === AssessmentPerformedBy.self}
                onChange={(value) => setFieldValue('due', value)}
                size="large"
                value={values.due}
              />
            </div>
          </div>
        </div>
        <div className="assign-assessment__actions">
          <Button
            className="assign-assessment__cancel-button"
            size="sm"
            color="white"
            onClick={() => navigate(-1)}
            fluid
            data-test="assign-assessment-cancel-button"
          >
            {translate(generalMessages.cancel)}
          </Button>
          <Button
            className="assign-assessment__save-button"
            size="sm"
            color={colorTheme}
            loading={loading}
            type="submit"
            data-test="assign-assessment-save-button"
          >
            {translate(messages.assignAndSend)}
          </Button>
        </div>
      </Form>
      <ImportWizardMenuModal popupState={openWizard} onClose={handlePopupClose} />
    </div>
  );
};

export default AssignAssessment;
