export const messages = {
  tabNamePersonalData: {
    key: 'Profile.tabNamePersonalData',
    defaultMessage: 'Personal data',
  },
  tabNamePassword: {
    key: 'Profile.tabNamePassword',
    defaultMessage: 'Password',
  },
  settingsTabTitle: {
    key: 'Profile.settingsTabTitle',
    defaultMessage: 'Profile settings',
  },
  settingsTabPersonalDataTitle: {
    key: 'Profile.settingsTabPersonalDataTitle',
    defaultMessage: 'Personal data',
  },
  settingsTabEmailError: {
    key: 'Profile.settingsTabEmailError',
    defaultMessage: 'Email format is invalid',
  },
  settingsTabRequiredEmail: {
    key: 'Profile.settingsTabRequiredEmail',
    defaultMessage: 'Email is required',
  },
  settingsTabNameLabel: {
    key: 'Profile.settingsTabNameLabel',
    defaultMessage: 'Full name',
  },
  settingsTabNameError: {
    key: 'Profile.settingsTabNameError',
    defaultMessage: 'Only alphabets are allowed',
  },
  settingsTabRequiredName: {
    key: 'Profile.settingsTabRequiredName',
    defaultMessage: 'Full name is required',
  },
  settingsTabCorporateNameLabel: {
    key: 'Profile.settingsTabCorporateNameLabel',
    defaultMessage: 'Company',
  },
  settingsTabLanguageLabel: {
    key: 'Profile.settingsTabLanguageLabel',
    defaultMessage: 'Language',
  },
  settingsTabSubmitButton: {
    key: 'Profile.settingsTabSubmitButton',
    defaultMessage: 'Save changes',
  },
  settingsTabCancelButton: {
    key: 'Profile.settingsTabCancelButton',
    defaultMessage: 'cancel',
  },
  settingsTabUpdated: {
    key: 'Profile.settingsTabUpdated',
    defaultMessage: 'Profile updated successfully',
  },
  settingsTabError: {
    key: 'Profile.settingsTabError',
    defaultMessage: 'Profile failed to update',
  },
  passwordTabTitle: {
    key: 'Profile.passwordTabTitle',
    defaultMessage: 'Password',
  },
  passwordTabInputTitle: {
    key: 'Profile.passwordTabInputTitle',
    defaultMessage: 'Change Password',
  },
  passwordTabResetPasswordButton: {
    key: 'Profile.passwordTabResetPasswordButton',
    defaultMessage: 'Reset Password',
  },
  passwordTabPasswordSuccess: {
    key: 'Profile.passwordTabPasswordSuccess',
    defaultMessage: 'Password is updated',
  },
  passwordTabPasswordFail: {
    key: 'Profile.passwordTabPasswordFail',
    defaultMessage: 'Password update failed',
  },
  passwordTabMfaTitle: {
    key: 'Profile.passwordTabMfaTitle',
    defaultMessage: 'Authentication method',
  },
  passwordTabMfaMethodButton: {
    key: 'Profile.passwordTabMfaMethodButton',
    defaultMessage: 'Change method',
  },
  passwordTabMfaSuccess: {
    key: 'Profile.passwordTabMfaSuccess',
    defaultMessage: 'Second authentication method is updated',
  },
  passwordTabMfaFail: {
    key: 'Profile.passwordTabMfaFail',
    defaultMessage: 'Second authentication method update failed',
  },
  yourAdminRequires2Fa: {
    key: 'Profile.yourAdminRequires2Fa',
    defaultMessage: 'Your admin requires 2FA.',
  },
};

export default {
  prefix: 'Profile',
  messages,
};
