import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import { translate } from 'utils/index';

import { messages } from './messages';
import './index.scss';
import { useTheme } from 'hooks/theme';

const InfoModal = ({ confirmLoading, onOk, text, open, headerText, icon, onCancel }) => {
  const { colorTheme } = useTheme();

  const Footer = useMemo(() => {
    return (
      <div className="info-modal__footer">
        <Button
          data-test="modal-button-accept"
          size="sm"
          color={colorTheme}
          loading={confirmLoading}
          onClick={onOk}
        >
          {translate(messages.okText)}
        </Button>
      </div>
    );
  }, [colorTheme, onOk, confirmLoading]);

  return (
    <Modal
      className="info-modal"
      confirmLoading={confirmLoading}
      onOk={onOk}
      width={460}
      footer={Footer}
      onCancel={onCancel}
      open={open}
    >
      <div className="info-modal__content">
        <div className="info-modal__header">
          {icon}
          {headerText}
        </div>
        <div className="info-modal__text">{text}</div>
      </div>
    </Modal>
  );
};

InfoModal.propTypes = {
  confirmLoading: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.element,
  headerText: PropTypes.string,
  open: PropTypes.bool,
};

export default InfoModal;
