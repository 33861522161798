import React from 'react';

// COMPONENTS
import Icon from 'ui/Icon';

// UTILS
import { messages } from 'pages/Assessment/messages';
import { translate } from 'utils/index';

// STYLES
import './noInternetConnection.scss';

function NoInternetConnection(props) {
  return (
    <div className="no-internet-connection">
      <Icon icon="info2" />
      <span> {translate(messages.errorNoConnection)}</span>
    </div>
  );
}

export default NoInternetConnection;
