import React from 'react';
import { useSelector } from 'react-redux';

// COMPONENTS
import PendingUnassignedAssessments from 'components/PendingUnassignedAssessments';

function PagePopups() {
  const showSentAssessmentPopup = useSelector(
    ({ assignAssessment }) => assignAssessment.showSentAssessmentPopup,
  );

  return <div>{!showSentAssessmentPopup && <PendingUnassignedAssessments />}</div>;
}

export default PagePopups;
