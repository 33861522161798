import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getAssessments } from 'api/assessments';
import { DEFAULT_PAGINATION } from 'constants/api';

const initialState = {
  assessments: [],
  pagination: DEFAULT_PAGINATION,
  search: '',
  sorter: { column: 'timestampCreated', descending: true },
  loading: false,
  archive: 0,
};

const reducers = createSlice({
  name: 'assessments',
  initialState,
  reducers: {
    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
    setSorter: (state, { payload }) => {
      state.sorter = payload || {};
    },
    setSearch: (state, { payload }) => {
      state.search = payload || '';
    },
    setArchive: (state, { payload }) => {
      state.archive = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssessments.pending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        isAnyOf(getAssessments.fulfilled, getAssessments.rejected),
        (state, { payload: { assessments, total } }) => {
          state.assessments = assessments;
          state.pagination = {
            ...state.pagination,
            total,
          };
          state.loading = false;
        },
      );
  },
});

export const { setPagination, setSearch, setSorter, setArchive } = reducers.actions;

export default reducers;
