import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Radio, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import Form from 'ui/Form';
import Divider from 'ui/Divider';
import Input from 'ui/Input';
import TextEditor from 'ui/TextEditor';
import Button from 'ui/Button';
import CardWithLogo from 'components/CardWithLogo';
import { EditIcon } from 'components/Icons';

// UTILS
import { messages } from 'pages/Organization/messages';
import { useAppNavigate } from 'hooks/navigation';
import { translate } from 'utils/index';

// CONSTANTS
import ROUTES from 'constants/routes';
import { generalMessages } from 'constants/messages';
import { API_STATUS } from 'constants/api';

// REDUX and API
import { getOrganizationPages, setOrganizationPage } from 'api/organizations';
import { setEditOrganizationId } from 'pages/Organization/reducers';
import { selectIsMyVendorsTabSelected } from 'pages/App/selectors';

// STYLES
import './soboPages.scss';

const SoBoPages = () => {
  const dispatch = useDispatch();
  const [pages, setPages] = useState({
    bo: {},
    so: {},
    active: 'bo',
  });
  const { organization: orgIndex, organizationId } = useParams();
  const isMyVendorsTabSelected = useSelector(selectIsMyVendorsTabSelected);

  const appNavigate = useAppNavigate();

  const onChangeRadio = ({ target: { value } }) => {
    setPages((state) => ({ ...state, active: value }));
  };

  const onChangeInput = ({ target: { value, name } }) => {
    setPages((state) => ({ ...state, [name]: { ...state[name], title: value } }));
  };

  const onChangeContent = (value) => {
    setPages((state) => ({
      ...state,
      [pages.active]: { ...state[pages.active], description: value },
    }));
  };

  const onSave = async () => {
    const status = await setOrganizationPage(organizationId, {
      ...pages[pages.active],
      page: pages.active,
    });
    if (status === API_STATUS.FAILED) {
      return notification.error({ message: translate(messages.orgEditSoBoPagesSaveError) });
    }
    return notification.success({ message: translate(messages.orgEditSoBoPagesSaveSuccess) });
  };

  const onEditAssessment = () => {
    dispatch(setEditOrganizationId(organizationId));
    const route = isMyVendorsTabSelected
      ? ROUTES.VENDOR_ASSESSMENT_TEMPLATE
      : ROUTES.CUSTOMER_ASSESSMENT_TEMPLATE;
    return appNavigate(route, {
      organization: orgIndex,
      templateId: pages[pages.active].assessmentId,
    });
  };

  useEffect(async () => {
    const data = await getOrganizationPages(organizationId);
    setPages((state) => ({ ...state, ...data }));
  }, []);

  return (
    <div className="sobo-pages-tab">
      <Form.Label text={`${translate(messages.orgEditSoBoPagesChoose)}`} />
      <Divider hidden />
      <Form.Item>
        <Radio.Group name="pages" value={pages.active}>
          <Radio name="pagesOption" value="bo" defaultChecked onChange={onChangeRadio}>
            {translate(messages.orgEditSoBoPagesBO)}
          </Radio>
          <Radio name="pagesOption" value="so" onChange={onChangeRadio}>
            {translate(messages.orgEditSoBoPagesSO)}
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Divider hidden />
      <Form.Item className="sobo-pages-tab__title-area">
        <Input
          block
          name={pages.active}
          label={translate(messages.orgEditSoBoPagesTitle)}
          value={pages[pages.active]?.title || ''}
          inputClassName="sobo-pages-tab__title-input"
          onChange={onChangeInput}
        />
        <Button
          link
          data-test="sobo-pages-tab-edit-button"
          className="sobo-pages-tab__edit-button"
          color="blue"
          size="md"
          onClick={onEditAssessment}
          width={180}
        >
          <EditIcon height={20} />
          {translate(messages.orgEditSoBoPagesEditButton)}
        </Button>
      </Form.Item>
      <Divider hidden />
      <TextEditor content={pages[pages.active]?.description} setContent={onChangeContent} />
      <Divider size="lg" hidden />
      <CardWithLogo.Footer
        rightButtonOnClick={onSave}
        noRightIcon
        rightButtonText={translate(generalMessages.save)}
      />
    </div>
  );
};

export default SoBoPages;
