import { translate } from '.';
import { generalMessages } from 'constants/messages';

export const getRiskValueByScore = (score) => {
  if (score <= 33) {
    return translate(generalMessages.lowRisk);
  }

  if (score > 33 && score < 67) {
    return translate(generalMessages.mediumRisk);
  }

  return translate(generalMessages.highRisk);
};
