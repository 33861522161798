import React from 'react';
import PropTypes from 'prop-types';

const FindingIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M4.25001 3C4.05111 3.00002 3.86035 3.07904 3.71971 3.21969C3.57906 3.36034 3.50003 3.55109 3.50001 3.75V21.25C3.49861 21.3494 3.51697 21.4481 3.55403 21.5403C3.59109 21.6325 3.64611 21.7164 3.71589 21.7872C3.78568 21.858 3.86883 21.9142 3.96052 21.9526C4.05222 21.9909 4.15062 22.0107 4.25001 22.0107C4.34941 22.0107 4.44781 21.9909 4.5395 21.9526C4.6312 21.9142 4.71435 21.858 4.78413 21.7872C4.85392 21.7164 4.90894 21.6325 4.946 21.5403C4.98306 21.4481 5.00142 21.3494 5.00001 21.25V16.5H20.75C20.8893 16.4999 21.0258 16.4611 21.1442 16.3878C21.2627 16.3145 21.3584 16.2097 21.4206 16.0851C21.4829 15.9606 21.5092 15.8211 21.4966 15.6824C21.4841 15.5437 21.4332 15.4112 21.3496 15.2998L17.1875 9.75L21.3496 4.2002C21.4332 4.08879 21.4841 3.95631 21.4966 3.8176C21.5092 3.6789 21.4829 3.53944 21.4206 3.41485C21.3584 3.29026 21.2627 3.18546 21.1442 3.11219C21.0258 3.03892 20.8893 3.00007 20.75 3H4.25001ZM5.00001 4.5H19.25L15.6504 9.2998C15.553 9.42967 15.5003 9.58764 15.5003 9.75C15.5003 9.91236 15.553 10.0703 15.6504 10.2002L19.25 15H5.00001V4.5Z"
      fill={color}
    />
  </svg>
);

FindingIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default FindingIcon;
