import { createSelector } from '@reduxjs/toolkit';

export const selectFilters = createSelector(
  ({ assessmentsFilters }) => assessmentsFilters,
  ({ filters }) => filters,
);

export const selectIsFilteredArchived = createSelector(
  selectFilters,
  (filters) => (filters.find(({ type }) => type === 'archive') || {}).id === 1,
);

export const selectCustomers = createSelector(
  ({ assessmentsFilters }) => assessmentsFilters,
  ({ customers }) => customers,
);

export const selectCustomersLoading = createSelector(
  ({ assessmentsFilters }) => assessmentsFilters,
  ({ customersLoading }) => customersLoading,
);

export const selectAssessmentsTemplates = createSelector(
  ({ assessmentsFilters }) => assessmentsFilters,
  ({ assessmentsTemplates }) => assessmentsTemplates,
);

export const selectAssessmentsTemplatesLoading = createSelector(
  ({ assessmentsFilters }) => assessmentsFilters,
  ({ assessmentsTemplatesLoading }) => assessmentsTemplatesLoading,
);

export const selectGroups = createSelector(
  ({ assessmentsFilters }) => assessmentsFilters,
  ({ groups }) => groups,
);

export const selectGroupsLoading = createSelector(
  ({ assessmentsFilters }) => assessmentsFilters,
  ({ groupsLoading }) => groupsLoading,
);
