export const messages = {
  impact: {
    key: 'ImpactSelect.impact',
    defaultMessage: 'IMPACT',
  },
  choose: {
    key: 'ImpactSelect.choose',
    defaultMessage: 'Choose',
  },
};

export default {
  prefix: 'ImpactSelect',
  messages,
};
