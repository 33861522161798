import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { GetAnswerExample, GetExplainedControl, GetImprovedAnswer } from 'api/endpoints';

export const getExplainedControlRequest = async ({ controlId, assessmentId }) => {
  const payload = {
    controlId,
    assessmentId,
  };
  const { response } = await axios.get(GetExplainedControl.url, { params: payload });
  return { controlId, response, source: 'explain' };
};

export const getExplainedControl = createAsyncThunk(
  'getExplainedControl',
  getExplainedControlRequest,
);

export const getAnswerExampleRequest = async ({ controlId, assessmentId }) => {
  const payload = {
    controlId,
    assessmentId,
  };
  const { response } = await axios.get(GetAnswerExample.url, { params: payload });
  return { controlId, response, source: 'example' };
};

export const getAnswerExample = createAsyncThunk('getAnswerExample', getAnswerExampleRequest);

export const getImprovedAnswerRequest = async ({ controlId, assessmentId }, { getState }) => {
  const {
    upstreamAI: { enableImprove },
  } = getState();

  const payload = {
    controlId,
    assessmentId,
  };

  if (enableImprove) {
    payload['thirdPartyConsent'] = enableImprove;
  }

  const { response } = await axios.get(GetImprovedAnswer.url, { params: payload });
  return { controlId, response, source: 'improve' };
};

export const getImprovedAnswer = createAsyncThunk('getImprovedAnswer', getImprovedAnswerRequest);
