import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppTriggersModal from './appTriggersModal';

const AddAppTrigger = ({ categoryId, onCancel }) => {
  const [isModalOpened, setIsModalOpened] = useState(true);

  const hideModal = () => {
    setIsModalOpened(false);
    setTimeout(() => {
      onCancel();
    }, 200);
  };

  return <AppTriggersModal categoryId={categoryId} hideModal={hideModal} open={isModalOpened} />;
};

AddAppTrigger.propTypes = {
  categoryId: PropTypes.string,
  onCancel: PropTypes.func,
};

export default AddAppTrigger;
