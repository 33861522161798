export const messages = {
  uploadFileAreaTo: {
    key: 'File Upload Area.uploadFileAreaTo',
    defaultMessage: 'to',
  },
  uploadFileAreaText: {
    key: 'File Upload Area.uploadFileAreaText',
    defaultMessage: 'Please click browse to upload your assessment',
  },
  uploadFileAreaTitle: {
    key: 'File Upload Area.uploadFileAreaTitle',
    defaultMessage: 'Upload file',
  },
  uploadFileAreaInvalidType: {
    key: 'File Upload Area.uploadFileAreaInvalidType',
    defaultMessage: 'Invalid type',
  },
  uploadFileAreaSupportedTypes: {
    key: 'File Upload Area.uploadFileAreaSupportedTypes',
    defaultMessage: 'Support: XLSX',
  },
};
export default {
  prefix: 'File Upload Area',
  messages,
};
