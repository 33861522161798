import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProvider, useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { notification } from 'antd';
import classNames from 'classnames';

import Form from 'ui/Form';
import Modal from 'ui/Modal';
import Button from 'ui/Button';
import TextArea from 'ui/TextArea';
import Select from 'ui/Select';
import ImpactSelect from 'components/ImpactSelect';
import ProbabilitySelect from 'components/ProbabilitySelect';
import Risk from 'pages/Recent/findingsColumns/risk';
import Status from 'pages/Recent/findingsColumns/status';
import FindingStatus from 'constants/findingStatus';

import { getRiskValue } from 'pages/Assessment/finalized/findings/lib';
import { translate } from 'utils/index';
import { saveFinding } from 'api/finding';
import { saveTemplateFinding } from 'api/editor';
import { sanitizer } from 'utils/sanitizer';
import { selectTemplate, selectAllTemplateControlsByCategory } from 'pages/Editor/selectors';
import { useTheme } from 'hooks/theme';

import { addFindingSchema } from './addFindingsSchema';
import { flatErrors, getDefaultRecommendation, impactEnum, probabilityEnum } from './lib';
import RemoveFinding from './removeFinding';
import ControlAnswerPreview from './controlAnswerPreview';
import Recommendations from './recommendations';
import { useGetControlById } from './hooks';
import { selectAllControlsByCategory, selectAssessment } from './selectors';
import { messages } from './messages';
import { confirmAuditFinding } from 'api/downstreamAi';
import { API_STATUS } from 'constants/api';
import { messages as messagesAudit } from 'components/UpstreamAI/messages';
import useAuditCss from 'components/UpstreamAI/useAuditCss';

import './addFinding.scss';
import { mixpanelFindingAdded } from 'utils/mixpanel';

const getInitialValues = () => {
  return {
    selectedControlId: undefined,
    finding: {
      description: '',
      comment: '',
      impact: 0,
      probability: 0,
      id: uuidv4(),
      recommendations: [getDefaultRecommendation()],
    },
  };
};

const AddFinding = ({
  control,
  finding,
  visible,
  isEditor,
  toggleModal,
  audit,
  setAuditPopoverVisible,
  app,
}) => {
  const dispatch = useDispatch();
  const {
    risk,
    impact,
    probability,
    id: assessmentId,
    title: assessmentTitle,
    customerId,
  } = useSelector(isEditor ? selectTemplate : selectAssessment);
  const controlsOptions = useSelector(
    isEditor ? selectAllTemplateControlsByCategory : selectAllControlsByCategory,
  );
  const [note, setNote] = useState({});
  const [loading, setLoading] = useState(false);
  const getControlById = useGetControlById(isEditor);
  const { colorTheme } = useTheme();
  const user = useSelector((state) => state.profile.user);

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: addFindingSchema(
      control,
      translate(messages.questionIsRequired),
      translate(messages.descriptionIsRequired),
    ),
    onSubmit: async () => {
      setLoading(true);

      const payload = {
        control: control || getControlById(values.selectedControlId),
        finding: {
          ...values.finding,
          // include recommendations with description only
          recommendations: values.finding?.recommendations.filter(
            (recommendation) => recommendation.description,
          ),
        },
      };

      if (app?.id) {
        payload.finding.app = app.id;
      }

      if (isEditor) {
        payload.finding.triggers = finding ? finding?.triggers : [visible];
        payload.finding.enabled = false;
      }

      if (audit) {
        // confirm finding if description is the one suggested by AI
        if (finding?.description === values.finding?.description) {
          const {
            payload: {
              response: { status },
            },
          } = await dispatch(
            confirmAuditFinding({
              controlId: control.id,
              findingId: finding.id,
              assessmentId: assessmentId,
            }),
          );
          if (status === API_STATUS.FAILED) {
            notification.error({ message: translate(messagesAudit.confirmFindingError) });
          } else {
            notification.success({ message: translate(messagesAudit.confirmFindingSuccess) });
          }
        } else {
          // attach new id to finding with different description
          payload.finding.id = uuidv4();
        }
      }

      const { status } = await dispatch(
        isEditor ? saveTemplateFinding(payload) : saveFinding(payload),
      ).unwrap();

      if (status === API_STATUS.SUCCESS) {
        mixpanelFindingAdded({
          relation: customerId ? customerId : null,
          assessmentName: assessmentTitle,
          originalControl: control?.title,
          assignedOrganizationName: user?.current_organization?.label,
          description: payload?.finding?.description,
          impact: impactEnum[payload?.finding?.impact],
          probability: probabilityEnum[payload?.finding?.probability],
          source: isEditor ? 'editor' : 'assessment',
          recommendations: payload?.finding?.recommendations?.length,
        });
      }

      setLoading(false);
      toggleModal(false);
      resetForm();
      if (audit) {
        setAuditPopoverVisible(true);
      }
    },
  });

  const {
    errors,
    values,
    handleSubmit,
    handleChange,
    isSubmitting,
    isValidating,
    setFieldValue,
    setValues,
    resetForm,
    touched,
  } = formik;

  const auditCss = useAuditCss({ auditFinding: finding, currentFinding: values?.finding, audit });

  useEffect(() => {
    if (!visible || !finding) {
      return;
    }

    setValues({ ...getInitialValues(), finding });

    setNote(
      finding?.recommendations
        ? Object.fromEntries(
            finding?.recommendations?.map((recommendation, index) => [
              index,
              !!recommendation?.comment,
            ]),
          )
        : {},
    );
  }, [visible, finding]);

  // TODO: remove??????????
  useEffect(() => {
    if (!visible) {
      return;
    }
    setNote({});
    setValues(finding ? { ...getInitialValues(), finding } : getInitialValues());
  }, [visible]);

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const errorKeys = Object.keys(flatErrors(errors));
      if (errorKeys.length > 0) {
        const selector = `[name="${errorKeys[0]}"]`;
        const errorElement = document.querySelector(selector);
        if (errorElement) {
          errorElement.focus();
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  const onCancel = () => {
    toggleModal(false);
    resetForm();
  };

  const riskValue = getRiskValue(values.finding?.impact, values.finding?.probability, risk);

  const footer = [
    <Button
      data-test="assessment-add-finding-cancel-button"
      key="button_add_finding_1"
      className="assessment-add-finding__footer-cancel-button"
      size="sm"
      color="white"
      onClick={onCancel}
      fluid
    >
      {translate(messages.cancel)}
    </Button>,
    <Button
      data-test="assessment-add-finding-ok-button"
      key="button_add_finding_2"
      className="assessment-add-finding__footer-ok-button"
      size="sm"
      color={colorTheme}
      onClick={handleSubmit}
      fluid
      type="submit"
      loading={loading}
    >
      {translate(messages.save)}
    </Button>,
  ];

  const descriptionClassNames = classNames('assessment-add-finding__description', {
    [`${auditCss?.description?.class}`]: auditCss?.description?.show,
  });

  const statusClassNames = classNames('assessment-add-finding__statuses', {
    [`${auditCss?.impact?.class}`]: auditCss?.impact?.show,
    [`${auditCss?.probability?.class}`]: auditCss?.probability?.show,
  });

  return (
    <Modal
      className="assessment-add-finding__modal"
      open={!!visible}
      onCancel={onCancel}
      width={685}
      footer={footer}
    >
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <div className="assessment-add-finding__top">
            {control ? (
              <>
                <div className="assessment-add-finding__question-wrapper">
                  <div className="assessment-add-finding__question-label">
                    {translate(messages.question)}:
                  </div>
                  <div className="assessment-add-finding__question">
                    {app ? (
                      <span>{translate(messages.continouosMonitoring)}</span>
                    ) : (
                      <span dangerouslySetInnerHTML={{ __html: sanitizer(control?.title) }} />
                    )}
                  </div>
                </div>
                <div className="assessment-add-finding__answer-wrapper">
                  <div className="assessment-add-finding__answer-label">
                    {translate(messages.answer)}:
                  </div>
                  <div className="assessment-add-finding__answer">
                    {app ? (
                      app.label
                    ) : (
                      <ControlAnswerPreview
                        control={control}
                        isEditor={isEditor}
                        optionKey={visible}
                      />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <Select
                className="assessment-add-finding__question-select"
                error={touched.selectedControlId && errors.selectedControlId}
                label={`${translate(messages.questionLabel)}:`}
                placeholder={translate(messages.selectQuestion)}
                options={controlsOptions}
                value={values.selectedControlId}
                onChange={(value) => setFieldValue('selectedControlId', value)}
                groupBy="category"
              />
            )}

            <div className="assessment-add-finding__finding-header">
              <h4 className="assessment-add-finding__finding-header-text">
                {translate(messages.finding)}:
              </h4>
              {(!audit ? finding?.id : finding?.added) && (
                <RemoveFinding
                  control={control}
                  id={finding?.id}
                  onSuccess={() => toggleModal(false)}
                />
              )}
            </div>
            <div className="assessment-add-finding__description-wrapper">
              <TextArea
                data-test="add-finding-finding-description-textarea"
                className={descriptionClassNames}
                name="finding.description"
                onChange={handleChange}
                placeholder={translate(messages.enterDescription)}
                error={touched.finding?.description && errors.finding?.description}
                value={values.finding?.description}
              />
            </div>
            <div className={statusClassNames}>
              <ImpactSelect
                onChange={(value) => setFieldValue('finding.impact', value)}
                options={impact}
                value={values.finding?.impact}
              />
              <ProbabilitySelect
                onChange={(value) => setFieldValue('finding.probability', value)}
                options={probability}
                value={values.finding?.probability}
              />
              <Risk label={`${translate(messages.risk)}:`} risk={riskValue} />
              <Status status={FindingStatus.open} />
            </div>
          </div>
          <Recommendations
            control={control}
            errors={errors.finding?.recommendations}
            finding={finding}
            handleChange={handleChange}
            note={note}
            recommendations={values.finding?.recommendations}
            setFieldValue={setFieldValue}
            setNote={setNote}
            touched={touched.finding?.recommendations}
            auditCss={auditCss}
            enableRemove={!audit ? Boolean(finding?.id) : Boolean(finding?.added)}
          />
        </Form>
      </FormikProvider>
    </Modal>
  );
};

AddFinding.propTypes = {
  control: PropTypes.object,
  finding: PropTypes.object,
  isEditor: PropTypes.bool,
  toggleModal: PropTypes.func,
  visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  audit: PropTypes.object,
  setAuditPopoverVisible: PropTypes.func,
  app: PropTypes.object, // app object is used to determine if the finding is for continuous monitoring
};

export default AddFinding;
