import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';

import emptyLogo from 'assets/svgs/emptyChart-lineChartOverTime.svg';
import { chartTheme } from 'pages/Dashboard/lib';
import { translate } from 'utils/index';
import colors from 'ui/colors.scss';

import './index.scss';

const LineChart = ({ chart }) => {
  const { data } = chart;

  const mappedData = useMemo(() => {
    return data?.map((dataset) => {
      return {
        ...dataset,
        data: dataset?.data?.map((item) => {
          return {
            x: item?.x?.key
              ? translate({ key: item.x?.key, defaultMessage: item.x?.value })
              : item.x,
            y: item?.y?.key
              ? translate({ key: item.y?.key, defaultMessage: item.y?.value })
              : item.y,
          };
        }),
      };
    });
  }, [data]);

  return (
    <div className="dashboard__chart__line-chart">
      {data ? (
        <ResponsiveLine
          data={mappedData || []}
          theme={chartTheme}
          isInteractive={false}
          margin={{ top: 10, right: 10, bottom: 30, left: 30 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            truncateTickAt: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            truncateTickAt: 0,
          }}
          colors={(d) => {
            return mappedData?.find((dataset) => dataset?.id === d?.id)?.color || colors.textColor;
          }}
          pointColor={(d) => d?.color}
          pointSize={6}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          pointLabel="data.yFormatted"
          pointLabelYOffset={-12}
          enableTouchCrosshair={true}
          useMesh={true}
          legends={[]}
        />
      ) : (
        <img src={emptyLogo} alt="no-data" />
      )}
    </div>
  );
};

LineChart.propTypes = {
  chart: PropTypes.object,
};

export default LineChart;
