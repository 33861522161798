import React from 'react';
import PropTypes from 'prop-types';

import ControlComponent from 'components/ControlComponent';
import { ControlType } from 'constants/controlType';

import Editor from './editor';
import './answer.scss';

const Answer = ({ answerType, control, onValueChange, setAnswerType, type, value }) => {
  const data = {
    payload: {
      onChange: onValueChange,
      value,
      'data-test': `editor-answer-creator-answer-type-${type}`,
    },
  };

  if (type === ControlType.FileUpload) {
    data.payload.disabled = true;
  }

  switch (type) {
    case ControlType.Input:
    case ControlType.TextArea:
    case ControlType.FileUpload:
    case ControlType.DatePicker:
      return (
        <ControlComponent
          className="editor-answer-creator-answer"
          data={data}
          type={type}
          data-test={`editor-answer-creator-answer-${type}`}
        />
      );
    case ControlType.DropDown:
    case ControlType.MultiSelect:
      return (
        <Editor
          answerType={answerType}
          setAnswerType={setAnswerType}
          value={value}
          onChange={onValueChange}
        />
      );
    case ControlType.Checkbox:
      return (
        <Editor
          answerType={answerType}
          control={control}
          controlType={ControlType.Checkbox}
          setAnswerType={setAnswerType}
          value={value}
          onChange={onValueChange}
        />
      );
    case ControlType.RadioGroup:
      return (
        <Editor
          answerType={answerType}
          control={control}
          controlType={ControlType.RadioGroup}
          setAnswerType={setAnswerType}
          value={value}
          onChange={onValueChange}
        />
      );
    default:
      return null;
  }
};

Answer.propTypes = {
  answerType: PropTypes.object,
  control: PropTypes.object,
  onValueChange: PropTypes.func,
  setAnswerType: PropTypes.func,
  type: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
};

export default Answer;
