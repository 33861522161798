import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Card from 'ui/Card';
import './index.scss';

const CardWithLogo = ({
  leftComponent,
  logo,
  className,
  contentClassName,
  leftAreaClassName,
  leftAreaStyle,
  children,
}) => {
  const contentClassNames = classNames('card-with-logo__content', contentClassName);
  const leftAreaClassNames = classNames('card-with-logo__left-area', leftAreaClassName);

  return (
    <div className={`card-with-logo ${className}`}>
      <Card className="card-with-logo__card" noMargin noPadding>
        <div className={leftAreaClassNames} style={leftAreaStyle || {}}>
          <img className="card-with-logo__logo" src={logo} alt="App Logo" />
          {leftComponent}
        </div>
        <div className={contentClassNames}>{children}</div>
      </Card>
    </div>
  );
};

CardWithLogo.propTypes = {
  leftComponent: PropTypes.any,
  logo: PropTypes.string,
  className: PropTypes.string,
  leftAreaClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.any,
  leftAreaStyle: PropTypes.object,
};

CardWithLogo.defaultProps = {
  className: '',
};

export default CardWithLogo;
