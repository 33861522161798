import React, { useCallback, useRef, useState } from 'react';
import { ReactComponent as UploadIcon } from 'assets/svgs/upload-icon.svg';
import AddFilterButton from 'components/AddFilterButton';
import Input from 'ui/Input';
import { translate } from 'utils/index';
import debounce from 'lodash/debounce';
import Button from 'ui/Button';
import { messages } from './messages';
import PropTypes from 'prop-types';
import MyFilesAddedFilters from 'pages/MyFiles/myFilesAddedFilters';
import { useDispatch } from 'react-redux';
import { setFilter } from 'pages/MyFiles/reducers';
import { useTheme } from 'hooks/theme';
import { useRole } from 'hooks/useRole';

import './myFilesFilters.scss';

const MyFilesFilters = ({ onFileUpload, onFiltersChange }) => {
  const dispatch = useDispatch();
  const [currentSearch, setCurrentSearch] = useState('');
  const filterSearchRef = useRef('');
  const { colorTheme } = useTheme();
  const { isRestrictedBoSoViewerRole } = useRole();

  const handleFiltersType = (type) => {
    dispatch(setFilter({ type }));
  };

  const searchDebounced = useCallback(
    debounce(() => {
      onFiltersChange(filterSearchRef.current, 'search');
    }, 500),
    [],
  );

  const handleClickButton = () => {
    document.getElementById('fileInput').click();
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    filterSearchRef.current = value;
    setCurrentSearch(value);
    searchDebounced();
  };

  return (
    <div className="my-files-filter">
      <div className="my-files-filter__filters">
        <div className="my-files-filter__filters-left">
          <Input.Search
            className="my-files-filter__search-input"
            onChange={handleSearch}
            size="medium"
            value={currentSearch}
          />
          <AddFilterButton setFilter={handleFiltersType} filtersType="myFiles" />
        </div>
        <MyFilesAddedFilters />
      </div>
      {onFileUpload && (
        <>
          <input
            type="file"
            id="fileInput"
            style={{ display: 'none' }}
            onChange={onFileUpload}
            disabled={isRestrictedBoSoViewerRole}
          />
          <Button
            color={colorTheme}
            className="my-files-filter__upload-button"
            beforeComponent={<UploadIcon width={24} />}
            size="sm"
            data-test="upload-new-file"
            onClick={handleClickButton}
            disabled={isRestrictedBoSoViewerRole}
          >
            {translate(messages.uploadNewFile)}
          </Button>
        </>
      )}
    </div>
  );
};

MyFilesFilters.propTypes = {
  onFileUpload: PropTypes.func,
  onFiltersChange: PropTypes.func,
};

export default MyFilesFilters;
