export function stripHtml(html) {
  const element = document.createElement('div');
  element.innerHTML = html;
  return element.textContent || element.innerText || '';
}

export const stripHtmlFromText = (value) => {
  const regex = /(<([^>]+)>)/gi;
  return value.replace(regex, '');
};

export function getOffset(element) {
  const bodyRect = document.body.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return {
    top: elementRect.top - bodyRect.top,
    left: elementRect.left - bodyRect.left,
  };
}

export const getTextWidth = (text, fontSize = 12) => {
  const element = document.createElement('canvas');
  const context = element.getContext('2d');
  context.font = `${fontSize}px Montserrat, sans-serif`;
  return context.measureText(text).width;
};
