import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigationType } from 'react-router';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType === 'POP') {
      return false;
    }
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    return true;
  }, [pathname]);

  return null;
};

export default ScrollToTop;
