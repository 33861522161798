import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js/auto';
import './index.scss';

const BarChart = ({ className, color, values, labels }) => {
  const highestValue = Math.max(...values);
  const roundUpNearest10 = (num) => Math.ceil(num / 10) * 10;

  return (
    <div className="ui-bar-chart-container">
      <Bar
        className={className}
        plugins={[ChartDataLabels]}
        options={{
          scales: {
            y: {
              max: roundUpNearest10(highestValue + 10),
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
          },
          borderRadius: 5,
          maintainAspectRatio: false,
        }}
        data={{
          labels: [...labels],
          datasets: [
            {
              data: [...values],
              backgroundColor: [...color],
              barThickness: 40,
            },
          ],
        }}
      />
    </div>
  );
};

BarChart.propTypes = {
  className: PropTypes.string,
  color: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BarChart;
