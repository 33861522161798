import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectTemplate } from 'pages/Editor/selectors';
import { calculateControlKey } from 'pages/Assessment/lib';

function useLinkedQuestions(activeControlAnswerType) {
  const { categories } = useSelector(selectTemplate);

  return useMemo(() => {
    return categories.reduce((acc, subject, subjectIndex) => {
      const answers = subject.controls
        .map((question, questionIndex) => ({
          ...question,
          indexKey: calculateControlKey(subjectIndex + 1, questionIndex),
        }))
        .filter(({ answer_type_idx: answerTypeIdx }) => {
          return answerTypeIdx === activeControlAnswerType;
        });

      return acc.concat(answers);
    }, []);
  }, [categories]);
}

export default useLinkedQuestions;
