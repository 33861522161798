import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'ui/Modal';
import Input from 'ui/Input';
import Button from 'ui/Button';
import { translate } from 'utils/index';
import { messages } from 'components/StrengthenAuth/messages';
import { AttentionIcon } from 'components/Icons';
import { API_STATUS } from 'constants/api';
import { useTheme } from 'hooks/theme';
import { sendMfaViaEmail, setProfileMfaOnSession } from 'api/profile';
import { generalMessages } from 'constants/messages';
import { selectProfile } from 'pages/Profile/selectors';
import './index.scss';

const StrengthenAuth = ({ cleanup, deps, request }) => {
  const [showModal, setShowModal] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { colorTheme } = useTheme();
  const dispatch = useDispatch();
  const {
    user: { email },
  } = useSelector(selectProfile);

  useEffect(async () => {
    setTimeout(async () => {
      const response = await request().unwrap();

      if (response.status === API_STATUS.OPTION) {
        setShowModal(true);
        dispatch(sendMfaViaEmail({ email }));
      }
    });

    if (cleanup) {
      return cleanup();
    }
  }, deps || []);

  const handleSubmit = async () => {
    setLoading(true);
    const response = await dispatch(setProfileMfaOnSession({ otp: otpCode })).unwrap();
    if (response.status !== API_STATUS.SUCCESS) {
      setLoading(false);
      return setError(translate(messages.invalidOtpCode));
    }
    setLoading(false);
    setShowModal(false);
    await request().unwrap();
  };

  const title = (
    <span>
      <AttentionIcon />
      {translate(messages.enterTheSecurityCode)}
    </span>
  );

  const footer = [
    <Button
      data-test="strengthen-auth-submit-button"
      key="submit-button"
      size="sm"
      color={colorTheme}
      onClick={handleSubmit}
      fluid
      type="submit"
      loading={loading}
      disabled={!otpCode}
    >
      {translate(generalMessages.submit)}
    </Button>,
  ];

  return (
    showModal && (
      <Modal
        className="strengthen-auth__modal"
        open={showModal}
        onCancel={() => setShowModal(false)}
        width={685}
        title={title}
        footer={footer}
      >
        <div className="strengthen-auth">
          <div className="strengthen-auth__description">
            {translate(messages.yourCustomerRequiresVendorToStrengthen)}
          </div>
          <Input
            data-test="strengthen-auth-otp-code-input"
            error={error}
            value={otpCode}
            onChange={(event) => setOtpCode(event.target.value)}
            placeholder={translate(messages.enterCode)}
          />
        </div>
      </Modal>
    )
  );
};

StrengthenAuth.propTypes = {
  cleanup: PropTypes.func,
  deps: PropTypes.array,
  request: PropTypes.func,
};

export default StrengthenAuth;
