import React from 'react';
import { useSelector } from 'react-redux';

import OrganizationsList from 'pages/Organization/settings/list';
import NewOrganizationButton from 'pages/Organization/settings/newOrganizationButton';
import PageHeader from 'components/PageHeader';
import { selectProfile } from 'pages/Profile/selectors';
import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';

import './index.scss';

const OrganizationsSettings = () => {
  const { user } = useSelector(selectProfile);

  return (
    <div className="organizations-settings-page">
      <PageHeader className="organizations-settings-page__title">
        {translate(messages.orgSettingsTitle)}
      </PageHeader>

      <div className="organizations-settings-page__content-wrapper">
        <div className="organizations-settings-page__top-row">
          <h3>
            {translate(messages.orgSettingsDefaultOrgLabel)}: {user.corporateName}
          </h3>
          <NewOrganizationButton />
        </div>

        <OrganizationsList defaultOrganization={user.email ? user.current_organization.id : ''} />
      </div>
    </div>
  );
};

export default OrganizationsSettings;
