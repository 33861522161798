import React from 'react';
import PropTypes from 'prop-types';

const DeactivateBlockIcon = ({
  color = 'currentColor',
  height = '24',
  width = '24',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M12 2C6.48603 2 2 6.48604 2 12C2 17.514 6.48603 22 12 22C17.514 22 22 17.514 22 12C22 6.48604 17.514 2 12 2ZM12 3.5C14.08 3.5 15.9572 4.27174 17.4326 5.50684L5.50684 17.4316C4.27203 15.9563 3.5 14.0797 3.5 12C3.5 7.2967 7.29669 3.5 12 3.5ZM18.4932 6.56738C19.7283 8.04278 20.5 9.92003 20.5 12C20.5 16.7033 16.7033 20.5 12 20.5C9.9203 20.5 8.04367 19.728 6.56836 18.4932L18.4932 6.56738Z"
      fill={color}
    />
  </svg>
);

DeactivateBlockIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default DeactivateBlockIcon;
