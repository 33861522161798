import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getUnreadChatThreadsNotifications } from 'api/messenger';

const initialState = {
  threads: [],
  count: 10,
  offset: 1,
  loading: false,
  totalThreads: 0,
  totalOffset: 1,
};

const reducers = createSlice({
  name: 'unreadMessages',
  initialState,
  reducers: {
    setParameter: (state, { key, value }) => {
      state[key] = value;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(getUnreadChatThreadsNotifications.pending), (state) => {
        state.loading = true;
      })
      .addMatcher(
        isAnyOf(getUnreadChatThreadsNotifications.fulfilled),
        (state, { payload: { data, threads_unread: threadsUnread, pages, nextOffset } }) => {
          state.threads = nextOffset > 1 ? state.threads.concat(data) : data;
          state.totalThreads = threadsUnread;
          state.totalOffset = pages;
          state.loading = false;
        },
      );
  },
});

export const { setParameter, resetState } = reducers.actions;

export default reducers;
