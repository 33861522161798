export const messages = {
  header: {
    key: 'Category Tab Vendor.header',
    defaultMessage: 'Business Impact Analysis',
  },
  tabLabel: {
    key: 'Category Tab Vendor.tabLabel',
    defaultMessage: 'Analysis',
  },
  inherentRisk: {
    key: 'Category Tab Vendor.inherentRisk',
    defaultMessage: 'Inherent risk',
  },
  tabAddButtonText: {
    key: 'Category Tab Vendor.tabAddButtonText',
    defaultMessage: 'Add new analysis',
  },
  deleteButton: {
    key: 'Category Tab Vendor.deleteButton',
    defaultMessage: 'Delete analysis',
  },
  removeAnalysisText: {
    key: 'Category Tab Vendor.removeAnalysisText',
    defaultMessage: 'All analysis information will be lost',
  },
  removeAnalysisTextHeader: {
    key: 'Category Tab Vendor.removeAnalysisTextHeader',
    defaultMessage: 'Delete analysis?',
  },
  saveAndClose: {
    key: 'Category Tab Vendor.saveAndClose',
    defaultMessage: 'Save and close',
  },
  createNewVendor: {
    key: 'Category Tab Vendor.createNewVendor',
    defaultMessage: 'Create new vendor',
  },
  inputPlaceholder: {
    key: 'Category Tab Vendor.inputPlaceholder',
    defaultMessage: 'Enter name',
  },
};

export default {
  prefix: 'Category Tab Vendor',
  messages,
};
