import React, { useRef } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';

import Form from 'ui/Form';
import Input from 'ui/Input';
import CardWithLogo from 'components/CardWithLogo';
import Divider from 'ui/Divider';
import PhoneInput from 'components/PhoneInput';
import LanguageSelect from 'components/LanguageSelect';
import { profileSettingsForm } from 'pages/Profile/settings/schema';
import { translate } from 'utils/index';
import { messages } from 'pages/Profile/messages';
import { setProfileState } from 'pages/Profile/reducers';
import { setProfile } from 'api/profile';
import { API_STATUS } from 'constants/api';
import { setTranslations } from 'utils/languages';

import './personalData.scss';

const PersonalData = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickBack = () => {
    return navigate(-1);
  };

  const phoneValues = useRef({
    phone: user.phone || '',
    corporateGeography: user.corporateGeography || '',
    nationalNumber: '',
    isError: false,
  });

  const onChangePhone = (values) => {
    phoneValues.current = {
      ...values,
    };
  };

  const {
    errors,
    values: { fullname, email, lang },
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    validateOnChange: false,
    initialValues: {
      fullname: user.fullname,
      email: user.email,
      lang: user.lang,
    },

    validationSchema: profileSettingsForm(
      translate(messages.settingsTabEmailError),
      translate(messages.settingsTabRequiredEmail),
      translate(messages.settingsTabNameError),
      translate(messages.settingsTabRequiredName),
    ),

    onSubmit: async () => {
      if (!phoneValues.current?.isError) {
        const userData = { ...phoneValues.current, fullname, email, lang };
        dispatch(setProfileState({ user: userData }));

        const { status } = await dispatch(setProfile({ user: userData })).unwrap();

        if (status === API_STATUS.SUCCESS) {
          if (user.lang !== lang) {
            notification.success({ message: translate(messages.settingsTabUpdated) });
            setTranslations(lang, dispatch, () => {
              setTimeout(() => {
                window.location.reload();
              }, 300);
            });
            return;
          }
          return notification.success({ message: translate(messages.settingsTabUpdated) });
        }
      }

      resetForm();
      return notification.error({ message: translate(messages.settingsTabError) });
    },
  });

  return (
    <>
      <Divider hidden size="sm" />
      <Form className="personal-data-tab">
        <div className="personal-data-tab__form-area-left">
          <Form.Item>
            <Input
              block
              name="fullname"
              label={translate(messages.settingsTabNameLabel)}
              value={fullname}
              onChange={handleChange}
              error={errors.fullname}
              data-test="profile-settings-personal-data-input-fullname"
            />
          </Form.Item>
          <Form.Item>
            <Input.Email
              block
              name="email"
              value={email}
              onChange={handleChange}
              error={errors.email}
              data-test="profile-settings-personal-data-input-email"
            />
          </Form.Item>
          <Form.Item>
            <Form.Label text={translate(messages.settingsTabLanguageLabel)} />
            <LanguageSelect
              value={lang}
              onSelect={(e) => setFieldValue('lang', e, false)}
              error={errors.lang}
            />
          </Form.Item>
        </div>

        <div className="personal-data-tab__form-area-right">
          <PhoneInput onChange={onChangePhone} />
        </div>
        <Divider hidden size="lg" />
        <CardWithLogo.Footer
          noRightIcon
          noLeftIcon
          rightButtonText={translate(messages.settingsTabSubmitButton)}
          leftButtonText={translate(messages.settingsTabCancelButton)}
          rightButtonOnClick={handleSubmit}
          leftButtonOnClick={onClickBack}
        />
      </Form>
    </>
  );
};

PersonalData.propTypes = {
  user: PropTypes.object,
};

export default PersonalData;
