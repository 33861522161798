export const messages = {
  enterTheSecurityCode: {
    key: 'StrengthenAuth.enterTheSecurityCode',
    defaultMessage: 'Enter the security code sent to you via email',
  },
  yourCustomerRequiresVendorToStrengthen: {
    key: 'StrengthenAuth.yourCustomerRequiresVendorToStrengthen',
    defaultMessage:
      'Your customer requires to strengthen their authentication before accessing their information (assessment and findings). You will only need to do this once per session.',
  },
  enterCode: {
    key: 'StrengthenAuth.enterCode',
    defaultMessage: 'Enter code',
  },
  invalidOtpCode: {
    key: 'StrengthenAuth.invalidOtpCode',
    defaultMessage: 'Invalid authentication code',
  },
};

export default {
  prefix: 'StrengthenAuth',
  messages,
};
