import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UploadFiles from 'components/UploadFiles';
import { ALLOW_FILES } from 'constants/general';
import { AssessmentType } from 'constants/assessmentType';
import { removeFile, uploadFiles } from 'api/assessment';
import EvidenceMetadataEditor from 'components/EvidenceMetadataEditor';
import { selectAssessment, selectFileLoading } from './selectors';
import { useRole } from 'hooks/useRole';
import { useFileUploadProcess } from 'components/FileUploadIndicator/useFileUploadProcess';
import { selectCurrentOrganization } from 'pages/Profile/selectors';

import './files.scss';

const Files = () => {
  const dispatch = useDispatch();
  const { files, customerId } = useSelector(selectAssessment);
  const { id: organizationId } = useSelector(selectCurrentOrganization);

  const loading = useSelector(selectFileLoading);
  const { isRestrictedBoSoViewerRole } = useRole();
  const [selectedControlFile, selectControlFile] = useState();

  const { abortController, startUploading, finishUploding, renderConfirmationModal } =
    useFileUploadProcess();

  const handleUpload = async (files) => {
    startUploading();
    await dispatch(
      uploadFiles({
        files: files?.length ? files : [files],
        assessmentType: AssessmentType.assessment,
        abortController,
      }),
    ).unwrap();
    finishUploding();
  };

  const handleRemove = (fileId) => {
    dispatch(removeFile({ fileId, assessmentType: AssessmentType.assessment })).unwrap();
  };

  return (
    <div className="assessment-files">
      <UploadFiles
        accept={ALLOW_FILES}
        className="assessment-files__upload-files"
        disabled={isRestrictedBoSoViewerRole}
        uploadedFiles={files}
        handleUpload={handleUpload}
        handleRemove={handleRemove}
        loading={loading}
        browse
        edit={!isRestrictedBoSoViewerRole ? selectControlFile : undefined}
      />
      {selectedControlFile && (
        <EvidenceMetadataEditor
          file={selectedControlFile}
          onHide={() => selectControlFile(null)}
          open={!!selectedControlFile}
          relation={customerId || organizationId}
        />
      )}
      {renderConfirmationModal()}
    </div>
  );
};

export default Files;
