import { generalMessages } from 'constants/messages';
import { translate } from 'utils/index';
import { AppControls } from 'constants/appControls';
import * as yup from 'yup';
import { messages } from 'pages/Assessment/messages';
import {
  AWS_ACCOUNT_VALIDATION_REGEX,
  AZURE_SUBSCRIPTION_ID_VALIDATION_REGEX,
} from 'constants/general';

export const schemas = {
  [AppControls.website]: yup
    .string()
    .url(messages.pleaseEnterValidWebsite)
    .required(messages.pleaseEnterValidWebsite),
  [AppControls.awsAccountId]: yup
    .string()
    .required(messages.pleaseEnterValidAwsAccountId)
    .matches(AWS_ACCOUNT_VALIDATION_REGEX, { message: messages.pleaseEnterValidAwsAccountId }),
  [AppControls.email]: yup
    .string()
    .email(messages.pleaseEnterValidEmail)
    .required(messages.pleaseEnterValidEmail),
  [AppControls.azureSubscriptionId]: yup
    .string()
    .required(messages.pleaseEnterValidAzureSubscriptionId)
    .matches(AZURE_SUBSCRIPTION_ID_VALIDATION_REGEX, {
      message: messages.pleaseEnterValidAzureSubscriptionId,
    }),
};

export const calculateErrors = (assessment) => {
  let errorsCount = 0;
  const errorsList = assessment?.categories
    ?.filter(({ app }) => !app)
    .reduce((accCategories, category) => {
      const unansweredControls = category.controls.reduce((accControls, control) => {
        if (schemas[control?.app_trigger] !== undefined && !control.optional && control.enabled) {
          try {
            schemas[control.app_trigger].validateSync(control.answer);
          } catch ({ message }) {
            errorsCount += 1;
            accControls[control.id] = translate(message);
          }
        } else if (
          (control.answer === '' || control.answer === 0) &&
          !control.optional &&
          control.enabled
        ) {
          errorsCount += 1;
          accControls[control.id] = translate(generalMessages.required);
        }
        return accControls;
      }, {});

      if (Object.keys(unansweredControls)?.length) {
        accCategories[category.id] = unansweredControls;
      }

      return accCategories;
    }, {});

  return {
    errorsList,
    errorsCount,
  };
};
