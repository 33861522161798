import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from 'ui/Button';
import { translate } from 'utils/index';
import FinalizeSvg from 'assets/svgs/finalize.svg';
import { messages } from 'pages/Assessment/messages';
import { selectAssessment } from 'pages/Assessment/selectors';
import { useTheme } from 'hooks/theme';
import './complete.scss';

const steps = { info: 'info', tryFree: 'tryFree' };
const schedulePageUrl = 'https://hubs.ly/H0ppB7N0';

const FinalizeComplete = ({ onClose }) => {
  const [activeStep, setActiveStep] = useState(steps.info);
  const assessment = useSelector(selectAssessment);
  const { colorTheme } = useTheme();

  const finalizeMessage =
    assessment.customerName !== ''
      ? messages.submitCompleteStep1CustomerExist
      : messages.submitCompleteStep1CustomerNotExist;

  const onRedirect = () => {
    window.open(schedulePageUrl, '_blank');
    onClose();
  };

  const onContinue = () => {
    const skipFeaturesDialog = assessment.assessor;
    if (skipFeaturesDialog) {
      return onClose();
    }
    return setActiveStep(steps.tryFree);
  };

  const renderFinalizationMessage = () => (
    <div className="assessment-finalize-complete">
      <img className="assessment-finalize-complete__image" src={FinalizeSvg} alt="Index" />
      <div className="assessment-finalize-complete__text">
        {translate(finalizeMessage, { customerName: assessment.customerName })}
      </div>
      <div className="assessment-finalize-complete__footer">
        <Button
          data-test="assessment-finalize-complete-button-footer-ok"
          className="assessment-finalize-complete__footer-ok-button"
          size="sm"
          color={colorTheme}
          onClick={onContinue}
        >
          {translate(messages.continue)}
        </Button>
      </div>
    </div>
  );

  const renderTryForFreeDialog = () => (
    <div className="assessment-finalize-complete">
      <div className="assessment-finalize-complete__text">
        {translate(messages.saveHoursSentence)}
      </div>
      <div className="assessment-finalize-complete__sentence-container">
        <p>
          {translate(messages.ownAnswersSentence)} <br />
          <strong>{translate(messages.ownAnswersSentence2)}</strong> <br />
          {translate(messages.ownAnswersSentence3)}
        </p>
        <p>{translate(messages.showcaseSentence)}</p>
        <p>
          {translate(messages.customizableSentence)} <br />
          <strong>{translate(messages.customizableSentence2)}</strong> <br />
          {translate(messages.customizableSentence3)}
        </p>
      </div>
      <div className="assessment-finalize-complete__footer">
        <Button
          data-test="assessment-finalize-complete-button-footer-ok"
          className="assessment-finalize-complete__footer-ok-button"
          size="sm"
          color={colorTheme}
          onClick={onRedirect}
        >
          {translate(messages.tryForFree)}
        </Button>
        <Button
          data-test="assessment-finalize-complete-button-footer-cancel"
          className="assessment-finalize-complete__footer-cancel-button"
          size="sm"
          color="gray"
          onClick={onClose}
          link
        >
          {translate(messages.noThanks)}
        </Button>
      </div>
    </div>
  );

  return activeStep === steps.tryFree ? renderTryForFreeDialog() : renderFinalizationMessage();
};

FinalizeComplete.propTypes = {
  onClose: PropTypes.func,
};

export default FinalizeComplete;
