// Authentication
export const Login = {
  url: 'Login',
  nonAuthorized: true,
};

export const Logout = {
  url: 'Logout',
  nonAuthorized: true,
};

export const ResetProfilePassword = {
  url: 'ResetProfilePassword',
  nonAuthorized: true,
};

export const SetProfilePassword = {
  url: 'SetProfilePassword',
};

export const SetNewProfilePassword = {
  url: 'SetNewProfilePassword',
  nonAuthorized: true,
};

// Profile
export const GetProfile = {
  url: 'GetProfile',
};

export const GetOnboardingProfile = {
  url: 'GetProfile',
  nonAuthorized: true,
  key: 'GetOnboardingProfile',
};

export const GetFilteredProfileAssessments = {
  url: 'GetFilteredProfileAssessments',
};

export const GetNewProfileCustomersAssessmentsTemplates = {
  url: 'GetNewProfileCustomersAssessmentsTemplates',
};

export const SetProfileMfa = {
  url: 'SetProfileMfa',
};

export const TestProfileOtp = {
  url: 'TestProfileOtp',
};

export const SetProfile = {
  url: 'SetProfile',
};

export const GetProfileSummaryCustomers = {
  url: 'GetProfileSummaryCustomers',
};

export const GetProfileSummarySelf = {
  url: 'GetProfileSummarySelf',
};

export const GetProfileAssessmentsTemplates = {
  url: 'GetProfileAssessmentsTemplates',
};

export const GetProfileCustomersAssessmentsTemplates = {
  url: 'GetProfileCustomersAssessmentsTemplates',
};

export const GetProfileCustomersGroups = {
  url: 'GetProfileCustomersGroups',
};

export const SetProfileLogo = {
  url: 'SetProfileLogo',
};

// organizations
export const GetProfileOrganizations = {
  url: 'GetProfileOrganizations',
};

export const CreateOrganization = {
  url: 'CreateOrganization',
};

export const CreateOrganizationFromPending = {
  url: 'CreateOrganizationFromPending',
};

export const SetSubOrganization = {
  url: 'SetSubOrganization',
};

export const RemoveProfileOrganization = {
  url: 'RemoveOrganizationProfile',
};

export const GetOrganizationProfiles = {
  url: 'GetOrganizationProfiles',
  nonAuthorized: true,
};

export const RemoveOrganizationProfile = {
  url: 'RemoveOrganizationProfile',
  nonAuthorized: true,
};

export const SetProfileOrganizationRole = {
  url: 'SetProfileOrganizationRole',
  nonAuthorized: true,
};

export const SetOrganizationName = {
  url: 'SetOrganizationName',
};

export const GetProfileNotificationsTemplates = {
  url: 'GetProfileNotificationsTemplates',
  nonAuthorized: true,
};

export const GetOrganizationPages = {
  url: 'GetOrganizationPages',
  nonAuthorized: true,
};

export const GetOrganizationData = {
  url: 'GetOrganizationData',
  nonAuthorized: true,
};

export const SetOrganizationOkta = {
  url: 'SetOrganizationOkta',
  nonAuthorized: true,
};

export const AddOrganizationProfile = {
  url: 'AddOrganizationProfile',
  nonAuthorized: true,
};

export const SetDefaultNotification = {
  url: 'SetDefaultNotification',
  nonAuthorized: true,
};

export const SetProfileNotificationTemplate = {
  url: 'SetProfileNotificationTemplate',
  nonAuthorized: true,
};

export const SetOrganizationPage = {
  url: 'SetOrganizationPage',
  nonAuthorized: true,
};

export const AddOrganizationVendor = {
  url: 'AddOrganizationVendor',
};

export const AddOrganizationCustomer = {
  url: 'AddOrganizationCustomer',
};

// Event Logs
export const GetOrganizationAssessmentsLog = {
  url: 'GetOrganizationAssessmentsLog',
};

// Messages
export const GetProfileChatThreads = {
  url: 'GetProfileChatThreads',
};

export const GetUnreadChatThreads = {
  url: 'GetUnreadChatMsgs',
};

export const MarkAllAsRead = {
  url: 'MarkAsRead',
};

export const TrustPartners = {
  url: 'TrustPartners',
};

export const RequestTrust = {
  url: 'RequestTrust',
};

export const EstablishTrust = {
  url: 'EstablishTrust',
};

export const RejectTrustRequest = {
  url: 'RejectTrustRequest',
};

// Assessment, Recent, Vendors
export const GetFilteredProfileCustomersAssessments = {
  url: 'GetFilteredProfileCustomersAssessments',
};

export const Webconsts = {
  url: 'Webconsts',
  nonAuthorized: true,
};

export const CloneAssessmentTemplate = {
  url: 'CloneAssessmentTemplate',
};

export const RemoveProfileAssessmentsTemplate = {
  url: 'RemoveProfileAssessmentsTemplate',
};

export const LinkFile = {
  url: 'LinkFile',
};

export const LinkFiles = {
  url: 'LinkFiles',
};

export const DownloadFile = {
  url: 'DownloadFile',
};

export const AddProfileVendor = {
  url: 'AddProfileVendor',
};

export const GetProfileVendors = {
  url: 'GetProfileVendors',
};

export const GetProfileVendor = {
  url: 'GetProfileVendor',
};

export const SetProfileVendor = {
  url: 'SetProfileVendor',
};

export const GetRecentAssessments = {
  url: 'GetRecentAssessments',
};

export const GetEvidences = {
  url: 'evidences',
};

export const GetRecentVendors = {
  url: 'GetRecentVendors',
};

export const GetRecentCustomers = {
  url: 'GetRecentCustomers',
};

export const GetRecentFindings = {
  url: 'GetRecentFindings',
};

export const GetAssessment = {
  url: 'GetAssessment',
};

export const SetAssessment = {
  url: 'SetAssessment',
};

export const SetNotes = {
  url: 'SetNotes',
};

export const FinalizeProfileAssessment = {
  url: 'FinalizeProfileAssessment',
};

export const ReviewApprovedProfileAssessment = {
  url: 'ReviewApprovedProfileAssessment',
};

export const UnlinkFile = {
  url: 'UnlinkFile',
};

export const ShareCMResults = {
  url: 'ShareCMResults',
};

export const ReinviteProfileAssessment = {
  url: 'ReinviteProfileAssessment',
};

export const AddProfileCustomerAssessment = {
  url: 'AddProfileCustomerAssessment',
};

export const AddProfileAssessment = {
  url: 'AddProfileAssessment',
};

export const GetProfileAssessmentReport = {
  url: 'GetProfileAssessmentReport',
};

export const RemoveProfileVendor = {
  url: 'RemoveProfileVendor',
};

export const SetProfileVendorStatus = {
  url: 'SetProfileVendorStatus',
};

export const SetProfileVendorEnableStatus = {
  url: 'SetProfileVendorEnableStatus',
};

export const SetProfileCustomerStatus = {
  url: 'SetProfileCustomerStatus',
};

export const CollabControl = {
  url: 'CollabControl',
};

export const GetProfileChatThread = {
  url: 'GetProfileChatThread',
};

export const GetCategoryUnreadCount = {
  url: 'GetCategoryUnreadCount',
};

export const NewProfileChatThread = {
  url: 'NewProfileChatThread',
};

export const AddProfileChatThreadMsg = {
  url: 'AddProfileChatThreadMsg',
};

export const GetProfileAssessmentsTemplate = {
  url: 'GetProfileAssessmentsTemplate',
};

export const SetAssessmentTemplate = {
  url: 'SetAssessmentTemplate',
};

// Import Wizard
export const GetExcelSuggestedQuestions = {
  url: 'GetExcelSuggestedQuestions',
};

export const UploadOfflineAssessment = {
  url: 'UploadOfflineAssessment',
};

export const GetDocumentSuggestedQuestions = {
  url: 'GetDocumentSuggestedQuestions',
};

export const FillDocumentAssessment = {
  url: 'FillDocumentAssessment',
};

export const GetAnswerTypes = {
  url: 'administrator/GetAnswerTypes',
};

export const SetAnswerType = {
  url: 'administrator/SetAnswerType',
};

export const UploadProfileAssessmentsTemplateLogo = {
  url: 'UploadProfileAssessmentsTemplateLogo',
};

export const UploadTemplateReport = {
  url: 'UploadTemplateReport',
};

export const RemoveTemplateReport = {
  url: 'RemoveTemplateReport',
};

export const AddProfileCustomer = {
  url: 'AddProfileCustomer',
};

export const GetProfileCustomers = {
  url: 'GetProfileCustomers',
};

export const GetProfileCustomer = {
  url: 'GetProfileCustomer',
};

export const SetProfileCustomer = {
  url: 'SetProfileCustomer',
};

export const RemoveProfileCustomer = {
  url: 'RemoveProfileCustomer',
};

export const GetAnswerStaticRepository = {
  url: 'GetAnswerStaticRepository',
};

export const EditFileMetaData = {
  url: 'EditFileMetaData',
};

export const GetFileMetaData = {
  url: 'GetFileMetaData',
};

export const AppTriggers = {
  url: 'AppTriggers',
};

export const Apps = {
  url: 'apps/list',
};

export const SetAppsParams = {
  url: 'apps/SetAppsParams',
};

export const ChangeAppState = {
  url: 'apps/ChangeServiceState',
};

export const AppsFilters = {
  url: 'AppsFilters',
};

export const UploadSBOMFile = {
  url: 'UploadSBOMFile',
};

export const RemoveSBOMFile = {
  url: 'RemoveSBOMFile',
};

export const GetProfileAssessmentReports = {
  url: 'GetProfileAssessmentReports',
};

export const GetAssessmentCsv = {
  url: 'GetAssessmentCsv',
};

export const ArchiveProfileAssessment = {
  url: 'ArchiveProfileAssessment',
};

export const SetAssessmentsOrganization = {
  url: 'SetAssessmentsOrganization',
};

export const RestoreProfileAssessment = {
  url: 'RestoreProfileAssessment',
};

export const GetTemplateReport = {
  url: 'GetTemplateReport',
};

// FINDINGS
export const GetNewProfileFindings = {
  url: 'GetNewProfileFindings',
};

export const GetProfileSubjectsFindings = {
  url: 'GetProfileSubjectsFindings',
};

// Showcase

export const GetAssignedShowcase = {
  url: 'GetAssignedShowcase',
};
export const AssignShowcase = {
  url: 'AssignShowcase',
};
export const EditShowcaseTemplate = {
  url: 'EditShowcaseTemplate',
};

export const AddShowcaseTemplate = {
  url: 'AddShowcaseTemplate',
};

export const GetShowcasesTemplates = {
  url: 'GetShowcasesTemplates',
};

export const GetAssignedShowcases = {
  url: 'GetAssignedShowcases',
};

export const MoveControlBetweenCategories = {
  url: 'MoveControlBetweenCategories',
};

export const GetOrganizationAssessment = {
  url: 'GetOrganizationAssessment',
};

export const AddProfileCustomerBulk = {
  url: 'AddProfileCustomerBulk',
};

export const AddAppTrigger = {
  url: 'AddAppTrigger',
};

export const RemoveAppTrigger = {
  url: 'RemoveAppTrigger',
};

export const RevertFinalizeAssessment = {
  url: 'RevertFinalizeAssessment',
};

export const RevertReviewAssessment = {
  url: 'RevertReviewAssessment',
};

// Upstream AI
export const GetExplainedControl = {
  url: 'GetExplainedControl',
};

export const GetAnswerExample = {
  url: 'GetAnswerExample',
};

export const GetImprovedAnswer = {
  url: 'GetImprovedAnswer',
};
export const GetProfileCustomerBulkLog = {
  url: 'GetProfileCustomerBulkLog',
};

// Downstream AI
export const AuditControl = {
  url: 'AuditControl',
};

export const ConfirmAuditFinding = {
  url: 'ConfirmAuditFinding',
};

export const RunContinuousMonitoring = {
  url: 'RunContinuousMonitoring',
};

export const RRFreemiumProgress = {
  url: 'RRFreemiumProgress',
};

export const FreemiumDetails = {
  url: 'FreemiumDetails',
};

export const PurchaseRRTokens = {
  url: 'PurchaseRRTokens',
};

export const RRFull = {
  url: 'RRFull',
};

export const RRPart = {
  url: 'RRPart',
};

export const SetVendorBoAuditorUsers = {
  url: 'SetVendorBoAuditorUsers',
};

export const GetCustomersAssessmentsForShowcase = {
  url: 'GetCustomersAssessmentsForShowcase',
};

// translations
export const SetTranslationFile = {
  url: 'administrator/SetTranslationFile',
};
export const GetTranslationFile = {
  url: 'administrator/GetTranslationFile',
};

export const GenerateCloudAnswers = {
  url: 'GenerateCloudAnswers',
};

export const SaveNoteOnRelation = {
  url: 'SaveNoteOnRelation',
};

export const RemoveCloudAnswers = {
  url: 'RemoveCloudAnswers',
};

// Files
export const GetOrganizationFileGallery = {
  url: 'GetOrganizationFileGallery',
};

export const UploadOrganizationFileGallery = {
  url: 'gallery/file',
};

export const SetFileName = {
  url: 'SetFileName',
};

export const DeleteFile = {
  url: 'DeleteFile',
};

// Filters:
export const GetFiltersOptions = {
  url: 'GetFiltersOptions',
};

export const GetOrganizationSecurity = {
  url: 'GetOrganizationSecurity',
};

export const SetOrganizationSecurity = {
  url: 'SetOrganizationSecurity',
};

// dashboard
export const GetDashboardLayout = {
  url: 'dashboard/layout',
};

export const GetDashboardChartData = {
  url: 'dashboard/chart',
};

export const GetBoAuditor = {
  url: 'GetBoAuditor',
};

export const SetProfileMfaOnSession = {
  url: 'SetProfileMfaOnSession',
};

export const SendMfaViaEmail = {
  url: 'SendMfaViaEmail',
};
