import React from 'react';
import { useParams } from 'react-router';
import { notification } from 'antd';

import ROUTES from 'constants/routes';
import CardWithLogo from 'components/CardWithLogo';
import NewPasswordForm from 'components/NewPasswordForm';
import Form from 'ui/Form';
import Button from 'ui/Button';
import { setNewProfilePassword } from 'api/profile';
import { translate } from 'utils/index';
import { API_STATUS } from 'constants/api';
import { useAppNavigate } from 'hooks/navigation';
import { NOTIFICATION_DURATION } from 'constants/general';
import loginLogo from 'assets/images/login-logo.png';
import { messages } from './messages';

const ResetPassword = () => {
  const appNavigate = useAppNavigate();
  const { token } = useParams();

  const onSubmit = async (password) => {
    const { status } = await setNewProfilePassword({ password, token });
    if (status === API_STATUS.SUCCESS) {
      notification.success({
        message: translate(messages.newPasswordUpdateSuccess),
        duration: NOTIFICATION_DURATION.TWO_SECONDS,
      });

      return appNavigate(ROUTES.LOGIN);
    }

    return notification.error({
      message: translate(messages.newPasswordUpdateFail),
      duration: NOTIFICATION_DURATION.TWO_SECONDS,
    });
  };

  return (
    <CardWithLogo logo={loginLogo}>
      <CardWithLogo.Header
        title={translate(messages.newPasswordTitle)}
        subtitle={translate(messages.newPasswordSubTitle)}
      />
      <NewPasswordForm onSubmit={onSubmit}>
        <Form.Item centered>
          <Button type="submit" name={translate(messages.resetPasswordButton)} color="pink" />
        </Form.Item>
      </NewPasswordForm>
    </CardWithLogo>
  );
};

export default ResetPassword;
