import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { notification } from 'antd';

import { translate } from 'utils/index';
import { AttentionIcon } from 'components/Icons';
import Form from 'ui/Form';
import Input from 'ui/Input';
import PhoneInput from 'components/PhoneInput';
import AuthMethod from 'components/AuthMethod/authMethod';
import CardWithLogo from 'components/CardWithLogo';

import { API_STATUS, MFA_METHOD } from 'constants/api';
import { getCookie } from 'utils/apiHelpers';
import { TOKEN_AUTH_KEY } from 'utils/auth';
import { DEFAULT_ORGANIZATION_INDEX } from 'utils/organizations';
import { useAppNavigate } from 'hooks/navigation';
import { generalMessages } from 'constants/messages';
import { ONBOARDING_FORM_STAGE } from 'pages/Activation/Onboarding/lib';
import { selectProfile } from 'pages/Profile/selectors';
import { setProfileState } from 'pages/Profile/reducers';
import { setProfile } from 'api/profile';
import { mixpanelTrackOnBoarding } from 'utils/mixpanel';
import { useRole } from 'hooks/useRole';
import ROUTES from 'constants/routes';
import colors from 'ui/colors.scss';
import { messages } from 'pages/Activation/Onboarding/messages';
import './stage2FaMethod.scss';

const Stage2FaMethod = ({ setStage, finishOnboarding }) => {
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();
  const [selectedMethod, setSelectedMethod] = useState();
  const [loading, setLoading] = useState(false);
  const {
    user: {
      fullname,
      email,
      lang,
      phone,
      corporateGeography,
      onboarding,
      corporateName,
      forced2fa,
    },
  } = useSelector(selectProfile);
  const [phoneValues, setPhoneValues] = useState({ phone, corporateGeography, isError: !phone });
  const { token, assessmentId } = useParams();
  const { isOwnerRole } = useRole();

  const onChangePhone = (values) => {
    setPhoneValues(values);
  };

  const onClickBack = () => {
    setStage(ONBOARDING_FORM_STAGE.STAGE_2);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const userData = {
      fullname,
      email,
      lang,
      phone,
      corporateGeography,
      corporateName,
      mfaMethod: selectedMethod,
    };

    dispatch(setProfileState({ user: userData }));
    const cookieToken = getCookie(TOKEN_AUTH_KEY);
    const { status, data } = await dispatch(
      setProfile({ user: userData, token: cookieToken ? cookieToken : token }),
    ).unwrap();

    if (status !== API_STATUS.SUCCESS)
      return notification.error({ message: translate(messages.stageTwoMfaSetFail) });

    if (!onboarding) {
      mixpanelTrackOnBoarding({
        source: data?.source,
        daysFromInvite: data?.daysFromInvite,
      });

      if (assessmentId) {
        return appNavigate(ROUTES.CUSTOMER_ASSESSMENT, {
          organization: DEFAULT_ORGANIZATION_INDEX,
          assessmentId,
        });
      }

      return appNavigate(ROUTES.CUSTOMER_RECENT, { organization: DEFAULT_ORGANIZATION_INDEX });
    }

    setLoading(false);

    if (isOwnerRole) {
      return setStage(ONBOARDING_FORM_STAGE.STAGE_3);
    }
    return finishOnboarding();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <div className="onboarding-2fa-method">
      {forced2fa && (
        <div className="onboarding-2fa-method__alert">
          <AttentionIcon color={colors.red600} className="onboarding-2fa-method__alert-icon" />
          {translate(messages.yourAdminRequires2Fa)}
        </div>
      )}
      <Form onSubmit={onSubmit}>
        <Form.Item>
          <AuthMethod
            hideNone={forced2fa}
            title={translate(messages.pleaseSelectYourPreferred)}
            mfaMethod={selectedMethod}
            onClick={(method) => {
              setSelectedMethod(method);
            }}
          />
        </Form.Item>
        {selectedMethod === MFA_METHOD.SMS && (
          <PhoneInput onChange={onChangePhone} phone={phone} prefix={corporateGeography} />
        )}
        {selectedMethod === MFA_METHOD.EMAIL && (
          <Form.Item>
            <Input
              label={translate(messages.workEMail)}
              value={email}
              disabled
              block
              data-test="email-input"
            />
          </Form.Item>
        )}
        <CardWithLogo.Footer
          leftButtonOnClick={onClickBack}
          rightButtonText={translate(generalMessages.saveAndContinue)}
          rightButtonType="submit"
          noRightIcon
          rightButtonAdditionalProps={{
            disabled:
              selectedMethod === undefined ||
              (selectedMethod === MFA_METHOD.SMS &&
                (phoneValues.isError || !phoneValues.corporateGeography)),
            loading,
          }}
        />
      </Form>
    </div>
  );
};

Stage2FaMethod.propTypes = {
  setStage: PropTypes.func,
  finishOnboarding: PropTypes.func,
};

export default Stage2FaMethod;
