import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';

import { useAppNavigate } from 'hooks/navigation';
import ROUTES from 'constants/routes';
import { translate } from 'utils/index';
import { countCategoryFindings } from 'pages/Assessment/lib';
import { messages } from 'pages/Assessment/messages';
import './findings.scss';

const Findings = ({ category }) => {
  const appNavigate = useAppNavigate();
  const { assessmentId } = useParams();

  const findingsLength = countCategoryFindings(category);

  const onClick = (event) => {
    event.stopPropagation();
    if (findingsLength !== 0) {
      appNavigate(ROUTES.VENDOR_ASSESSMENT_FINDINGS, { assessmentId }, { replace: true });
    }
  };

  return (
    <Tooltip title={translate(messages.viewEditFindings)}>
      <Button
        className="assessment-finalized-categories-view-findings"
        type="link"
        onClick={onClick}
      >
        {findingsLength}
      </Button>
    </Tooltip>
  );
};

Findings.propTypes = {
  category: PropTypes.object,
};

export default Findings;
