/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'ui/Select';
import { translate } from 'utils/index';
import { messages } from '../../messages';
import { CloseCancelIcon } from 'components/Icons';
import Button from 'ui/Button';
import { useSelector } from 'react-redux';

const Filter = ({
  filter,
  filterableKey,
  onChangeFilter,
  removeFilter,
  containerIndex,
  addQueryBlock,
  fetchFilterOptions,
}) => {
  // filters options for the non-query filters
  const { filtersOptions } = useSelector((state) => state.filters);

  const filterSelectOptions = useCallback((inputValue, option) => {
    return option?.title?.toLowerCase()?.trim()?.includes(inputValue?.toLowerCase()?.trim());
  }, []);

  const getDependencies = ({ queryBlock, queryBlockItemKey }) => {
    const queryBlockItemIndex = filter.queryBlockKeys.indexOf(queryBlockItemKey);

    const dependencies = {};
    filter.queryBlockKeys.forEach((currentQueryBlockKey, index) => {
      if (index < queryBlockItemIndex) {
        dependencies[currentQueryBlockKey] = queryBlock[currentQueryBlockKey].selected?.map(
          (currentSelected) => currentSelected.key,
        );
      }
    });
    return dependencies;
  };

  const handleQueryFilterChange = (
    value,
    selected,
    querySetIndex,
    queryBlockIndex,
    queryBlockItemKey,
  ) => {
    const queryBlock = filter.selected[querySetIndex][queryBlockIndex];
    const queryBlockItem = queryBlock[queryBlockItemKey];
    const queryBlockItemIndex = filter.queryBlockKeys.indexOf(queryBlockItemKey);

    const selectedKeys = queryBlockItem.multiSelect
      ? selected.map((item) => item.key)
      : [selected.key];
    const selectedArray = queryBlockItem.multiSelect ? selected : [selected];
    const mappedSelected = selectedArray?.map((item) => ({
      key: item.key,
      value: item.value,
    }));

    onChangeFilter({
      filterKey: filter.key,
      selected: mappedSelected,
      querySetIndex,
      queryBlockIndex,
      queryBlockItemKey,
    });
    const hasSelected = mappedSelected.length;

    if (hasSelected) {
      // fetch options for the next query block item in the current queryBlock and set disabled=false, if exists
      const dependencies = {};
      filter.queryBlockKeys.forEach((currentQueryBlockKey, index) => {
        // for each queryBlockKey, for all the previous queryBlockItems in the current queryBlock: set selected values in dependencies object, the next one will fetch the options.
        if (currentQueryBlockKey === queryBlockItemKey) {
          dependencies[currentQueryBlockKey] = selectedKeys;
        }
        if (index < queryBlockItemIndex) {
          dependencies[currentQueryBlockKey] = queryBlock[currentQueryBlockKey].selected.map(
            (selected) => selected.key,
          );
        }
        if (index === queryBlockItemIndex + 1) {
          fetchFilterOptions({
            filterKey: filter.key,
            querySetIndex,
            queryBlockIndex,
            queryBlockItemKey: currentQueryBlockKey,
            dependencies: dependencies,
            initializeOptionsOnPending: true,
          });
        }
      });
    }
  };

  // case of simple single key filter
  if (!filter.multipleKeys && !filter.isQuery) {
    return (
      <div key={filter.key} className="filters-panel__filter-container">
        <div className="filters-panel__filter-container__query-text">
          {translate(containerIndex ? messages.queryTextAnd : messages.queryTextWhere)}
        </div>
        <Select
          loading={filtersOptions[filter.key]?.loading}
          labelKey="value"
          inline={false}
          maxTagCount={undefined}
          value={filter.selected || []}
          style={{ width: '100%' }}
          mode={filter.multiSelect ? 'multiple' : undefined}
          options={filtersOptions[filter.key]?.options || []}
          setItemDataTest={(option) => `filter-${filter.key}-option-${option.value}`}
          onDropdownVisibleChange={(open) => open && fetchFilterOptions({ filterKey: filter.key })}
          valueAsKeyOption={true}
          filterOption={filterSelectOptions}
          onChange={(_value, selected) => {
            onChangeFilter({
              filterKey: filter.key,
              selected: filter.multiSelect
                ? selected.map((option) => ({ key: option.key, value: option.value }))
                : [{ key: selected.key, value: selected.value }],
            });
          }}
          innerLabel={translate(messages[filter.key])}
          className="filters-panel__filter-item"
          data-test={`filter-${filter.key}`}
          size="middle"
        />
        <a
          href="#"
          role="button"
          className="filters-panel__filter__remove"
          onClick={() => removeFilter({ filterKey: filter.key })}
        >
          <CloseCancelIcon />
        </a>
      </div>
    );
  }

  // case of multiple keys filter
  if (filter.multipleKeys && !filter.isQuery) {
    return filter.selected.map((selected, index) => {
      return (
        <div key={filter.key + index} className="filters-panel__filter-container">
          <div className="filters-panel__filter-container__query-text">
            {translate(index || containerIndex ? messages.queryTextAnd : messages.queryTextWhere)}
          </div>
          <Select
            size="middle"
            loading={filtersOptions[filter.key]?.loading}
            onDropdownVisibleChange={(open) =>
              open && fetchFilterOptions({ filterKey: filter.key })
            }
            labelKey="value"
            inline={false}
            maxTagCount={undefined}
            value={selected || []}
            style={{ width: '100%' }}
            mode={filter.multiSelect ? 'multiple' : undefined}
            options={filtersOptions[filter.key]?.options || []}
            setItemDataTest={(option) => `filter-${filter.key}-option-${option.value}`}
            valueAsKeyOption={true}
            filterOption={filterSelectOptions}
            onChange={(_value, selected) => {
              onChangeFilter({
                index,
                filterKey: filter.key,
                selected: filter.multiSelect
                  ? selected.map((option) => ({ key: option.key, value: option.value }))
                  : [{ key: selected.key, value: selected.value }],
              });
            }}
            innerLabel={translate(messages[filter.key])}
            className="filters-panel__filter-item"
            data-test={`filter-${filter.key}-${index}`}
          />
          <a
            href="#"
            role="button"
            className="filters-panel__filter__remove"
            onClick={() => removeFilter({ filterKey: filter.key, index })}
          >
            <CloseCancelIcon />
          </a>
        </div>
      );
    });
  }

  // case of query
  if (filter.isQuery) {
    return (
      <div key={filter.key} className="filters-panel__filter__query">
        {filter.selected?.map((querySet, querySetIndex) => {
          return (
            <div key={filter.key + querySetIndex} className="filters-panel__filter__query__set">
              <div className="filters-panel__filter-container__query-text">
                {translate(
                  querySetIndex || containerIndex ? messages.queryTextAnd : messages.queryTextWhere,
                )}
              </div>
              <div className="filters-panel__filter__query__set-inner">
                {querySet?.map((queryBlock, queryBlockIndex) => {
                  return (
                    <div
                      key={filter.key + querySetIndex + queryBlockIndex}
                      className="filters-panel__filter__query__block"
                    >
                      <div className="filters-panel__filter__query__block-header">
                        <div className="filters-panel__filter__query__block-header--inner">
                          <h4>{translate(messages[filter.key])}</h4>
                          <a
                            href="#"
                            role="button"
                            className="filters-panel__filter__remove"
                            onClick={() =>
                              removeFilter({
                                filterKey: filter.key,
                                querySetIndex,
                                queryBlockIndex,
                              })
                            }
                          >
                            <CloseCancelIcon />
                          </a>
                        </div>
                        {queryBlockIndex === 0 && filter.description ? (
                          <p className="filters-panel__filter__query__block-header-description">
                            {translate(messages[filter.key + 'Description'])}
                          </p>
                        ) : null}
                      </div>

                      {Object.keys(queryBlock)?.map((queryBlockItemKey, queryBlockItemIndex) => {
                        const queryBlockItem = queryBlock[queryBlockItemKey];
                        return (
                          <div
                            key={filter.key + querySetIndex + queryBlockIndex + queryBlockItemIndex}
                            className="filters-panel__filter__query__block__item"
                          >
                            <div className="filters-panel__filter-container__query-text">
                              {translate(
                                queryBlockItemIndex === Object.keys(queryBlock)?.length - 1
                                  ? messages.queryTextSelect
                                  : messages.queryTextIn,
                              )}
                            </div>
                            <Select
                              size="middle"
                              loading={queryBlockItem.loading}
                              onDropdownVisibleChange={(open) =>
                                open &&
                                fetchFilterOptions({
                                  filterKey: filter.key,
                                  querySetIndex,
                                  queryBlockIndex,
                                  queryBlockItemKey,
                                  dependencies: getDependencies({ queryBlock, queryBlockItemKey }),
                                })
                              }
                              labelKey="value"
                              inline={false}
                              maxTagCount={undefined}
                              value={queryBlockItem.selected || []}
                              style={{ width: '100%' }}
                              options={queryBlockItem.options || []}
                              valueAsKeyOption={true}
                              filterOption={filterSelectOptions}
                              setItemDataTest={(option) =>
                                `filter-${filter.key}-option-${option.value}`
                              }
                              mode={queryBlockItem.multiSelect ? 'multiple' : undefined}
                              onChange={(value, selected) => {
                                handleQueryFilterChange(
                                  value,
                                  selected,
                                  querySetIndex,
                                  queryBlockIndex,
                                  queryBlockItemKey,
                                );
                              }}
                              innerLabel={translate(messages[queryBlockItemKey])}
                              className="filters-panel__filter-item"
                              data-test={`filter-${filter.key}-queryIndex-${querySetIndex}-queryBlockIndex-${queryBlockIndex}-queryBlockItemKey-${queryBlockItemKey}`}
                              disabled={queryBlockItem.disabled}
                            />
                          </div>
                        );
                      })}

                      {querySet.length - 1 !== queryBlockIndex ? (
                        <div className="filters-panel__filter__query__block__or">
                          <span>{translate(messages.or)}</span>
                        </div>
                      ) : (
                        <Button
                          // disabled = true if the last query block item selected value is empty
                          disabled={
                            filter.selected[querySetIndex][queryBlockIndex][
                              Object.keys(queryBlock)[Object.keys(queryBlock).length - 1]
                            ].selected?.length === 0
                          }
                          data-test="add-filter-button"
                          color="blue"
                          outline={true}
                          size="sm"
                          squared={true}
                          className="filters-panel__filter__query__block-add-button"
                          onClick={() => addQueryBlock({ filterKey: filter.key, querySetIndex })}
                        >
                          +&nbsp;&nbsp;
                          {translate(messages.add)} {translate(messages[filter.key])}
                        </Button>
                      )}
                    </div>
                  );
                })}
              </div>
              <a
                href="#"
                role="button"
                className="filters-panel__filter__remove"
                onClick={() => removeFilter({ filterKey: filter.key, querySetIndex })}
              >
                <CloseCancelIcon />
              </a>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

Filter.propTypes = {
  filter: PropTypes.object,
  filterableKey: PropTypes.string,
  onChangeFilter: PropTypes.func,
  removeFilter: PropTypes.func,
  containerIndex: PropTypes.number,
  fetchFilterOptions: PropTypes.func,
  addQueryBlock: PropTypes.func,
};

export default Filter;
