import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

// COMPONENTS
import Button from 'ui/Button';
import Icon from 'ui/Icon';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'components/UpstreamAI/messages';

// STYLES
import './copyToClipboard.scss';

function CopyToClipboard({ text, setMixpanelCopy }) {
  const handleCopy = async () => {
    await navigator.clipboard.writeText(text);
    setMixpanelCopy(true);
  };

  return (
    <div>
      <Tooltip title={translate(messages.copy)}>
        <Button
          data-test="copy-to-clipboard-button"
          className="copy-button"
          color="white"
          onClick={handleCopy}
        >
          <Icon icon="copy" />
        </Button>
      </Tooltip>
    </div>
  );
}

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  setMixpanelCopy: PropTypes.func,
};

export default CopyToClipboard;
