export const messages = {
  okText: {
    key: 'RemoveConfirmationModal.okText',
    defaultMessage: 'Yes, Delete',
  },
  noText: {
    key: 'RemoveConfirmationModal.noText',
    defaultMessage: 'Cancel',
  },
};

export default {
  prefix: 'remove confirmation modal',
  messages,
};
