import React, { useEffect, useMemo, useState } from 'react';
import { translate } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
import Modal from 'ui/Modal';
import Tabs from 'ui/Tabs';
import Text from 'ui/Text';
import Icon from 'ui/Icon';
import {
  CategoryTab,
  ContactInformationTab,
  ShareShowcaseTab,
} from 'pages/Customers/CustomerManage/Tabs';
import Loader from 'ui/Loader';
import { useCustomerManageContext } from 'hooks/useCustomerManageContext';
import { resetCurrentCustomer } from 'pages/Customers/reducers';

import { getProfileCustomer } from 'api/customers';
import { messages } from './messages';
import './index.scss';

const PLUS_ICON_SIZE = 14;
const CONTACT_INFORMATION_TAB_KEY = 'Contact Information';
const IMPACT_ANALYSIS_TAB_KEY = 'Impact analysis';
const SHARE_SHOWCASE_TAB_KEY = 'Share Showcase';

const CustomerManage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const {
    customerAssessmentsData,
    currentCustomer,
    currentTab,
    handleTabChange,
    isCustomerDirty,
    sendNewVendor,
  } = useCustomerManageContext();
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const { isCustomerFetching } = useSelector((state) => state.customers);

  useEffect(() => {
    if (customerId) {
      dispatch(getProfileCustomer(customerId));
    } else {
      dispatch(resetCurrentCustomer());
    }
  }, [customerId]);

  useEffect(() => {
    return () => {
      dispatch(resetCurrentCustomer());
    };
  }, []);

  const shouldShowAdditionalInformation = useMemo(() => {
    const vendorDataLength = customerAssessmentsData
      .map((data) => !isEmpty(data))
      .filter(Boolean).length;
    return !!vendorDataLength;
  }, []);

  const prevAction = () => {
    if ((currentCustomer && isCustomerDirty) || !currentCustomer) {
      return setModalOpen(true);
    }
    navigate(-1);
  };

  const onModalOk = () => {
    setModalOpen(false);
  };

  const onModalCancel = () => {
    navigate(-1);
  };

  const title = (
    <span>
      <Icon icon="info" />{' '}
      {currentCustomer ? translate(messages.saveChanges) : translate(messages.exitWithoutSaving)}
    </span>
  );

  return (
    <section className="customer-manage">
      <Text className="customer-manage__header" level={1} color="text">
        {currentCustomer ? (
          <Icon className="customer-manage__header-icon" icon="vendors" />
        ) : (
          <>
            <PlusIcon width={PLUS_ICON_SIZE} height={PLUS_ICON_SIZE} />{' '}
          </>
        )}
        {currentCustomer?.profileCorporateName || translate(messages.createNewCustomer)}
      </Text>
      {isCustomerFetching && <Loader />}
      <div className="customer-manage__tabs">
        <Tabs activeKey={currentTab} onChange={handleTabChange}>
          <Tabs.Pane
            tab={`1. ${translate(messages.contactInformation)}`}
            key={CONTACT_INFORMATION_TAB_KEY}
          >
            <div className="customer-manage__content">
              <ContactInformationTab
                handleTabChange={() => {
                  handleTabChange(IMPACT_ANALYSIS_TAB_KEY);
                }}
                prevAction={prevAction}
              />
            </div>
          </Tabs.Pane>
          {shouldShowAdditionalInformation && (
            <Tabs.Pane
              tab={`2. ${translate(messages.impactAnalysisInformation)}`}
              key={IMPACT_ANALYSIS_TAB_KEY}
            >
              <div className="customer-manage__content">
                <CategoryTab
                  handleTabChange={() => {
                    handleTabChange(SHARE_SHOWCASE_TAB_KEY);
                  }}
                  handleBackTab={() => {
                    handleTabChange(CONTACT_INFORMATION_TAB_KEY);
                  }}
                  prevAction={prevAction}
                />
              </div>
            </Tabs.Pane>
          )}
          <Tabs.Pane
            tab={`${shouldShowAdditionalInformation ? '3' : '2'}. ${translate(
              messages.shareShowcase,
            )}`}
            key={SHARE_SHOWCASE_TAB_KEY}
          >
            <div className="customer-manage__content">
              <ShareShowcaseTab
                addProfileVendor={sendNewVendor}
                handleBackTab={() => {
                  handleTabChange(IMPACT_ANALYSIS_TAB_KEY);
                }}
                prevAction={prevAction}
              />
            </div>
          </Tabs.Pane>
        </Tabs>
      </div>
      <Modal
        className="customer-manage__confirm-modal"
        open={isModalOpen}
        onOk={onModalOk}
        okText={
          currentCustomer
            ? translate(messages.saveChangesButton)
            : translate(messages.completeCustomerButton)
        }
        onCancel={onModalCancel}
        cancelText={
          currentCustomer
            ? translate(messages.dontSaveButton)
            : translate(messages.exitAnywayButton)
        }
        title={title}
        width={650}
        closable={false}
        maskClosable={false}
        keyboard={false}
      >
        {currentCustomer
          ? translate(messages.wouldYouLikeToSaveChanges)
          : translate(messages.allCustomerInformationWillBeLost)}
      </Modal>
    </section>
  );
};

export default CustomerManage;
