export const messages = {
  uploadVendorHeader: {
    key: 'uploadList.uploadVendorHeader',
    defaultMessage: 'Upload vendor list',
  },
  uploadCustomerHeader: {
    key: 'uploadList.uploadCustomerHeader',
    defaultMessage: 'Upload customer list',
  },
  templateFileHeader: {
    key: 'uploadList.templateFileHeader',
    defaultMessage: 'A. Download csv template file',
  },
  templateFileDownload: {
    key: 'uploadList.templateFileDownload',
    defaultMessage: 'Download template',
  },
  uploadFileHeader: {
    key: 'uploadList.uploadFileHeader',
    defaultMessage: 'B. Upload completed template file',
  },
  uploadListTab: {
    key: 'uploadList.uploadListTab',
    defaultMessage: 'Upload list',
  },
  assignAssessmentTab: {
    key: 'uploadList.assignAssessmentTab',
    defaultMessage: 'Assign assessment',
  },
  templateVendorFileDescription: {
    key: 'uploadList.templateVendorFileDescription',
    defaultMessage:
      'Use the following template to upload your vendor list. You can add as many rows as you would like. Do not change the first row or add any extra columns',
  },
  templateCustomerFileDescription: {
    key: 'uploadList.templateCustomerFileDescription',
    defaultMessage:
      'Use the following template to upload your customer list. You can add as many rows as you would like. Do not change the first row or add any extra columns',
  },
};

export default {
  prefix: 'uploadList',
  messages,
};
