import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import CardWithLogo from 'components/CardWithLogo';
import StageDescription from 'pages/SoBoPages/stageDescription';
import Divider from 'ui/Divider';
import DetailsForm from 'pages/SoBoPages/detailsForm';
import { translate } from 'utils/index';
import { messages } from 'pages/SoBoPages/NewVendor/messages';
import { NEW_VENDOR_BO_PAGE } from 'pages/SoBoPages/NewVendor/lib';
import { setPayload } from 'pages/SoBoPages/reducers';

const StageTwoDescription = () => {
  return (
    <StageDescription
      title={translate(messages.stageTwoLeftDescriptionTitle)}
      text={translate(messages.stageTwoLeftDescriptionText)}
    />
  );
};

const StageTwo = ({ setStage }) => {
  const dispatch = useDispatch();

  const onClickBack = () => {
    setStage(NEW_VENDOR_BO_PAGE.STAGE_1);
  };

  const onSubmit = async (fullname, email, company) => {
    await dispatch(setPayload({ fullname, email, company }));
    setStage(NEW_VENDOR_BO_PAGE.STAGE_3);
  };

  return (
    <div>
      <Divider hidden size="md" />
      <DetailsForm onSubmit={onSubmit}>
        <CardWithLogo.Footer leftButtonOnClick={onClickBack} rightButtonType="submit" />
      </DetailsForm>
    </div>
  );
};

StageTwo.Description = StageTwoDescription;

StageTwo.propTypes = {
  setStage: PropTypes.func,
};

export default StageTwo;
