import React, { useCallback, useEffect } from 'react';
import { Select as AntSelect } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'ui/Icon';
import { ReactComponent as DropdownIcon } from 'assets/svgs/dropdown-arrow.svg';
import { getTextWidth } from 'utils/html';

import './index.scss';

const { Option, OptGroup } = AntSelect;

const Dropdown = ({ className, menu }) => <div className={className}>{menu}</div>;

Dropdown.propTypes = {
  className: PropTypes.string,
  menu: PropTypes.any,
};

const Select = (props) => {
  const {
    children,
    className,
    dropdownClassName,
    groupBy,
    options,
    label,
    inline,
    innerLabel,
    error,
    color,
    labelKey,
    value,
    showSearch,
    'data-test': dataTest,
    setItemDataTest,
    dropdownRender,
    showArrow = true,
    rtl,
    valueAsKeyOption,
    ...restProps
  } = props;

  const id = `ui-select-${uuidv4()}`;
  const selectClassNames = classNames('ui-select', className, {
    'ui-select--error': error !== undefined,
    'ui-select--color-gray': color === 'gray',
    'ui-select--inline': inline,
    'ui-select--selected': value,
  });

  useEffect(() => {
    if (innerLabel) {
      const item = document.querySelector(`#${id} .ant-select-selector`);
      if (item) {
        item.setAttribute('data-label', `${innerLabel}:`);

        const fontSize = window.getComputedStyle(item, ':before').getPropertyValue('font-size');
        const labelWidth = getTextWidth(innerLabel, parseFloat(fontSize));
        const search = document.querySelector(`#${id} .ant-select-selection-search`);
        search.style.paddingLeft = `${Math.round(labelWidth) + 8}px`;
        item.style.minWidth = `${item.getBoundingClientRect().width}px`;
      }
    }
  }, [innerLabel]);

  const dropdownRenderDefault = useCallback((menu) => {
    setTimeout(() => {
      const element = document.querySelector('.rc-virtual-list-holder');
      element?.setAttribute('data-test', `${props['data-test']}-rc-virtual-list-holder`);

      const selectElement = document.querySelector(`[data-test="${props['data-test']}"]`);
      const searchElement = selectElement?.querySelector('.ant-select-selection-search-input');
      searchElement?.setAttribute('data-test', `${props['data-test']}-search-input`);
    });

    return (
      <Dropdown menu={menu} className={classNames('ui-select__dropdown', dropdownClassName)} />
    );
  }, []);

  // eslint-disable-next-line no-shadow
  const renderOptions = (options) =>
    options?.map((option) => {
      const dataTest = setItemDataTest ? setItemDataTest(option) : 'dropdown-option-value';
      return (
        <Option
          key={option.key || option?.value || option.label}
          className={`dropdown-option-${option?.value}`}
          {...option}
          data-test={dataTest}
          style={{ direction: rtl ? 'rtl' : 'ltr' }}
          value={valueAsKeyOption ? option.key : option.value}
          title={option[labelKey || 'label']}
        >
          {option.icon &&
            (typeof option.icon === 'string' ? <Icon icon={option.icon} /> : option.icon)}
          {labelKey && option[labelKey]}
          {props.mode !== 'multiple' ? (
            <span className="ui-select__option-label">
              {!labelKey && (option.label !== undefined ? option.label : option?.value)}
            </span>
          ) : (
            !labelKey && (option.label !== undefined ? option.label : option?.value)
          )}
        </Option>
      );
    });

  return (
    <div id={id} className={selectClassNames}>
      <div className="ui-select__label">{label}</div>
      <div className="ui-select__wrapper" data-test={dataTest}>
        <AntSelect
          dropdownRender={dropdownRender || dropdownRenderDefault}
          filterOption={(input, option) => {
            return option[labelKey || 'label']
              ?.toString()
              ?.toLowerCase()
              ?.includes(input?.toLowerCase());
          }}
          maxTagCount={restProps.maxTagCount || 'responsive'}
          suffixIcon={
            !restProps.loading ? <DropdownIcon width={8} height={4} color="#1C1C1C" /> : undefined
          }
          showArrow={showArrow}
          showSearch={showSearch}
          value={value}
          {...restProps}
        >
          {children ||
            (groupBy
              ? props.options?.map((group, index) => (
                  <OptGroup key={`opt_group_${index}`} label={group[groupBy]}>
                    {renderOptions(group.options)}
                  </OptGroup>
                ))
              : renderOptions(props.options))}
        </AntSelect>
      </div>
      <div className="ui-select__error">{error}</div>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }),
  ),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['default', 'gray']),
  dropdownClassName: PropTypes.string,
  groupBy: PropTypes.string,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  inline: PropTypes.bool,
  innerLabel: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.bool]),
  showSearch: PropTypes.bool,
  mode: PropTypes.string,
  dropdownRender: PropTypes.node,
  showArrow: PropTypes.bool,
  'data-test': PropTypes.string,
  setItemDataTest: PropTypes.func,
  rtl: PropTypes.bool,
  valueAsKeyOption: PropTypes.bool,
};

Select.defaultProps = {
  showSearch: true,
};

export default Select;
