import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { translate } from 'utils/index';
import ROUTES from 'constants/routes';
import { useGetPath, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useRole } from 'hooks/useRole';

import { messages } from './messages';
import './vendorName.scss';

const VendorName = ({ name, id }) => {
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { isRestrictedBoSoViewerRole } = useRole();
  const getPath = useGetPath();

  return (
    <div className="assessment-vendor-name">
      <span className="assessment-vendor-name__label">
        {translate(isMyVendorsTabSelected ? messages.vendor : messages.customer)}:
      </span>{' '}
      {!isRestrictedBoSoViewerRole && id && isMyVendorsTabSelected ? (
        <Link
          className="assessment-vendor-name__link"
          to={getPath(ROUTES.VENDOR_EDIT, {
            vendorId: id,
          })}
        >
          {name}
        </Link>
      ) : (
        name
      )}
    </div>
  );
};

VendorName.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
};

export default VendorName;
