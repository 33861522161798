import { cloneDeep } from 'lodash';
import { translate } from 'utils/index';

/**
  ------Documentation, please read if you're maintining the filters feature:------
  * filterKeys is an array of all the filters keys that are available to be added.
  * filtersMetadata is an object that contains all the metadata and settings for each filter, like label, key, dynamic, multiSelect, multipleKeys, options, selected, loading, queryBlockKeys, query.
  * createNewFilter is a function that creates a new filter object based on the filterKey, it will populate the selected values if the filter is dynamic.
  
  * filter options: 
    the flat filters options are stored in the filtersOptions object in store, 
    the query filters options are stored in each filter item inside the specific filterableKey sincer they are dynamic and depend on the selected values of the previous filters.
    if "isMetadata" is true, it means that the options are static and needs to pass through translation function, when option.key is also play as the translationb key, and value is used as a default englih value. 
      since the default value recieved from BE, there is no need to maintain default value in FE. jhust make sure it's appears in the menahel site.
  ** data structure and types: 
      
  * filter metadata type: {
      key: string;
      multiSelect: boolean;
      multipleKeys: boolean;
      options?: { value: string; label: string }[];
      selected?: string[] | string[][];
      loading?: boolean;
      isQuery?: boolean;
      queryBlockKeys?: string[];
      selected?: queryBlock[][];      
      isMetadata?: boolean;
    }

    queryBlock type: {
      [key: string]: {
        key: string;
        multiSelect: boolean;
        dependencies?: string[];
        options?: { value: string; label: string }[];
        selected: string[];
        loading: boolean;
        disabled: boolean;
      };
    }
    
    * example of a filter with a query: 
    ** query includes an array of querySets, each querySet includes an array of queryBlocks.
    ** the render order of a query block item is defined in queryBlockKeys array.

    response: {
    label: 'Response',
    key: 'response',
    description: 'lala ',
    multipleKeys: true,
    queryBlockKeys: ['assessment', 'category', 'control', 'answer'],
    isQuery: true,
    selected: [
      [
        [
          { assessment: {}, category: {}, control: {}, answer: {} },
          { assessment: {}, category: {}, control: {}, answer: {} },
          { assessment: {}, category: {}, control: {}, answer: {} },
        ],
        [
          { assessment: {}, category: {}, control: {}, answer: {} },
          { assessment: {}, category: {}, control: {}, answer: {} },
        ],
      ],
      [
        [
          { assessment: {}, category: {}, control: {}, answer: {} },
          { assessment: {}, category: {}, control: {}, answer: {} },
        ],
        [
          { assessment: {}, category: {}, control: {}, answer: {} },
          { assessment: {}, category: {}, control: {}, answer: {} },
        ],
        [{ assessment: {}, category: {}, control: {}, answer: {} }],
      ],
      [],
    ],
  }

 */

// all the static filters functions are here
// enum of the filter keys strings
export const eFilterKeys = {
  vendorName: 'vendorName',
  customerName: 'customerName',
  vendorGroup: 'vendorGroup',
  vendorStatus: 'vendorStatus',
  vendorRiskScore: 'vendorRiskScore',
  customerRiskScore: 'customerRiskScore',
  vendorEngagementScore: 'vendorEngagementScore',
  businessOwner: 'businessOwner',
  auditor: 'auditor',
  assessmentName: 'assessmentName',
  assessmentScore: 'assessmentScore',
  assessmentStatus: 'assessmentStatus',
  riskreconGrade: 'riskreconGrade',
  riskreconCriticalFindings: 'riskreconCriticalFindings',
  awsCloudMonitoring: 'awsCloudMonitoring',
  azureCloudMonitoring: 'azureCloudMonitoring',
  securityScorecard: 'securityScorecard',
  findingsStatus: 'findingsStatus',
  findingsCriticality: 'findingsCriticality',
  evidenceExpiration: 'evidenceExpiration',
  organizationName: 'organizationName',
  response: 'response',
  assessment: 'assessment',
  category: 'category',
  control: 'control',
  answer: 'answer',
};

// array of all the filter keys that are available to be added
export const filterKeys = [
  eFilterKeys.vendorName,
  eFilterKeys.customerName,
  eFilterKeys.vendorGroup,
  eFilterKeys.vendorStatus,
  eFilterKeys.vendorRiskScore,
  eFilterKeys.customerRiskScore,
  eFilterKeys.vendorEngagementScore,
  eFilterKeys.businessOwner,
  eFilterKeys.auditor,
  eFilterKeys.assessmentName,
  eFilterKeys.assessmentScore,
  eFilterKeys.assessmentStatus,
  eFilterKeys.riskreconGrade,
  eFilterKeys.riskreconCriticalFindings,
  eFilterKeys.awsCloudMonitoring,
  eFilterKeys.azureCloudMonitoring,
  eFilterKeys.securityScorecard,
  eFilterKeys.findingsStatus,
  eFilterKeys.findingsCriticality,
  eFilterKeys.evidenceExpiration,
  eFilterKeys.organizationName,
  eFilterKeys.response,
];

// all the filterable lists that are used in the app
export const FILTERABLE_KEYS = {
  DASHBOARD_FILTERS: 'dashboardFilters',
  ASSESSMENTS_LIST_FILTERS: 'assessmentsListFilters',
  VENDORS_LIST_FILTERS: 'vendorsListFilters',
  CUSTOMERS_LIST_FILTERS: 'customersListFilters',
  FINDINGS_LIST_FILTERS: 'findingsListFilters',
  EVIDENCES_LIST_FILTERS: 'evidencesListFilters',
};

// metadata - settings for each filter key
export const filtersMetadata = {
  [eFilterKeys.vendorName]: {
    key: eFilterKeys.vendorName,
    multiSelect: true,
    multipleKeys: false,
    isMetadata: false,
  },
  [eFilterKeys.customerName]: {
    key: eFilterKeys.customerName,
    multiSelect: true,
    multipleKeys: false,
    isMetadata: false,
  },
  [eFilterKeys.vendorGroup]: {
    key: eFilterKeys.vendorGroup,
    multiSelect: true,
    multipleKeys: true,
    isMetadata: false,
  },
  [eFilterKeys.vendorStatus]: {
    key: eFilterKeys.vendorStatus,
    multiSelect: true,
    multipleKeys: false,
    isMetadata: true,
  },
  [eFilterKeys.vendorRiskScore]: {
    key: eFilterKeys.vendorRiskScore,
    multiSelect: true,
    multipleKeys: false,
    isMetadata: true,
  },
  [eFilterKeys.customerRiskScore]: {
    key: eFilterKeys.customerRiskScore,
    multiSelect: true,
    multipleKeys: false,
    isMetadata: true,
  },
  [eFilterKeys.vendorEngagementScore]: {
    key: eFilterKeys.vendorEngagementScore,
    multiSelect: true,
    multipleKeys: true,
    isMetadata: true,
  },
  [eFilterKeys.businessOwner]: {
    key: eFilterKeys.businessOwner,
    multiSelect: true,
    multipleKeys: true,
    isMetadata: false,
  },
  [eFilterKeys.auditor]: {
    key: eFilterKeys.auditor,
    multiSelect: true,
    multipleKeys: true,
    isMetadata: false,
  },
  [eFilterKeys.assessmentName]: {
    key: eFilterKeys.assessmentName,
    multiSelect: true,
    multipleKeys: true,
    isMetadata: false,
  },
  [eFilterKeys.assessmentScore]: {
    key: eFilterKeys.assessmentScore,
    multiSelect: true,
    multipleKeys: true,
    isMetadata: true,
  },
  [eFilterKeys.assessmentStatus]: {
    key: eFilterKeys.assessmentStatus,
    multiSelect: true,
    multipleKeys: true,
    isMetadata: true,
  },
  [eFilterKeys.riskreconGrade]: {
    key: eFilterKeys.riskreconGrade,
    multiSelect: true,
    multipleKeys: false,
    isMetadata: true,
  },
  [eFilterKeys.riskreconCriticalFindings]: {
    key: eFilterKeys.riskreconCriticalFindings,
    multiSelect: false,
    multipleKeys: false,
    isMetadata: true,
  },
  [eFilterKeys.awsCloudMonitoring]: {
    key: eFilterKeys.awsCloudMonitoring,
    multiSelect: true,
    multipleKeys: false,
    isMetadata: true,
  },
  [eFilterKeys.azureCloudMonitoring]: {
    key: eFilterKeys.azureCloudMonitoring,
    multiSelect: true,
    multipleKeys: false,
    isMetadata: true,
  },
  [eFilterKeys.securityScorecard]: {
    key: eFilterKeys.securityScorecard,
    multiSelect: true,
    multipleKeys: false,
    isMetadata: true,
  },
  [eFilterKeys.findingsStatus]: {
    key: eFilterKeys.findingsStatus,
    multiSelect: true,
    multipleKeys: true,
    isMetadata: true,
  },
  [eFilterKeys.findingsCriticality]: {
    key: eFilterKeys.findingsCriticality,
    multiSelect: true,
    multipleKeys: true,
    isMetadata: true,
  },
  [eFilterKeys.evidenceExpiration]: {
    key: eFilterKeys.evidenceExpiration,
    multiSelect: true,
    multipleKeys: true,
    isMetadata: true,
  },
  [eFilterKeys.organizationName]: {
    key: eFilterKeys.organizationName,
    multiSelect: true,
    multipleKeys: false,
    isMetadata: false,
  },
  [eFilterKeys.response]: {
    key: eFilterKeys.response,
    description: true,
    multipleKeys: true,
    isQuery: true,
    queryBlockKeys: [
      eFilterKeys.assessment,
      eFilterKeys.category,
      eFilterKeys.control,
      eFilterKeys.answer,
    ],
    selected: [], // each query is an array of querySets, each querySet is an array of queryBlocks.
    queryBlockItemsMeta: {
      [eFilterKeys.assessment]: {
        key: eFilterKeys.assessment,
        multiSelect: false,
        isMetadata: false,
      },
      [eFilterKeys.category]: {
        key: eFilterKeys.category,
        multiSelect: false,
        dependencies: [eFilterKeys.assessment],
        isMetadata: false,
      },
      [eFilterKeys.control]: {
        key: eFilterKeys.control,
        multiSelect: false,
        dependencies: [eFilterKeys.assessment, eFilterKeys.category],
        isMetadata: false,
      },
      [eFilterKeys.answer]: {
        key: eFilterKeys.answer,
        multiSelect: true,
        dependencies: [eFilterKeys.assessment, eFilterKeys.category, eFilterKeys.control],
        isMetadata: false,
      },
    },
  },
};

// when user chose a filter to add, it will populate the filter key object in the filterable key reducer with the key's metadata.
export const createNewFilter = ({ filterKey }) => {
  const newFilter = cloneDeep(filtersMetadata[filterKey]);

  // query case:
  if (newFilter.isQuery) {
    const newQueryBlock = createNewQueryBlock(filterKey);
    newFilter.selected.push([newQueryBlock]);

    return newFilter;
  }

  // if multykeys, set selected to [], else undefined. if multipleKeys, set selected to [[]]
  if (newFilter.multiSelect || newFilter.multipleKeys) {
    newFilter.selected = [];
    if (newFilter.multipleKeys) {
      newFilter.selected.push([]);
    }
  } else {
    newFilter.selected = undefined;
  }

  return newFilter;
};

export const createNewQuery = (filterKey) => {
  const newQuery = [];
  const newQueryBlock = createNewQueryBlock(filterKey);
  newQuery.push(newQueryBlock);
  return newQuery;
};

export const createNewQueryBlock = (filterKey) => {
  const newQueryBlock = {};

  filtersMetadata[filterKey].queryBlockKeys.forEach((queryBlockKey, index) => {
    newQueryBlock[queryBlockKey] = {
      ...filtersMetadata[filterKey].queryBlockItemsMeta[queryBlockKey],
      loading: false,
      disabled: Boolean(index),
      selected: [],
    };
  });

  return newQueryBlock;
};

// parse filter options returned from API from string[] to {key: string, value: string}[] to be used as <Select> options
export const parseFilterOptions = (options = [], isMetadata) => {
  const needParse = !options[0]?.key;
  const needTranslation = isMetadata;
  return options.map((option) => {
    const value = needParse
      ? option
      : needTranslation
      ? translate({ key: option.key, defaultMessage: option.value })
      : option.value;

    return {
      key: needParse ? option : option.key,
      value,
    };
  });
};

// count filters with selected values:
export const countFilters = ({ filters = {} }) => {
  let filtersCount = 0;
  Object.keys(filters).forEach((filterKey) => {
    if (filters[filterKey].multipleKeys) {
      filtersCount += filters[filterKey].selected.length;
    } else {
      filtersCount += 1;
    }
  });
  return filtersCount;
};

// clean filters obj from filters with no selected values.
export const cleanFiltersObj = ({ filters = {} }) => {
  const cleanedFilters = cloneDeep(filters);

  Object.keys(cleanedFilters).forEach((filterKey) => {
    const filter = cleanedFilters[filterKey];

    if (filter.isQuery && filter.selected) {
      //  query case
      const lastQueryBlockKey = filter.queryBlockKeys[filter.queryBlockKeys.length - 1];
      cleanedFilters[filterKey].selected?.forEach((querySet, querySetIndex) => {
        querySet.forEach((queryBlock, queryBlockIndex) => {
          if (!queryBlock[lastQueryBlockKey].selected.length) {
            cleanedFilters[filterKey].selected[querySetIndex].splice(queryBlockIndex, 1);
          }
        });
      });
      cleanedFilters[filterKey].selected = cleanedFilters[filterKey].selected.filter((querySet) => {
        return querySet.length;
      });
      if (!cleanedFilters[filterKey].selected.length) {
        delete cleanedFilters[filterKey];
      }
    } else if (!filter.isQuery && filter.multipleKeys) {
      // multipleKeys case
      filter.selected.forEach((selected, index) => {
        if (!selected.length) {
          cleanedFilters[filterKey].selected.splice(index, 1);
        }
      });

      if (!filter.selected.length) {
        delete cleanedFilters[filterKey];
      }
    } else if (!filter.selected.length) {
      delete cleanedFilters[filterKey];
    }
  });

  return { cleanedFilters, updatedFiltersCount: countFilters({ filters: cleanedFilters }) };
};

/**
 * Parse filters object to request object to be sent to the API.
 *  this func removes all the data that is not needed to be sent to the API
 *  (options, loading, label, key, multiselect, subFiltersGroup, allowMultipleGroups, subFiltersGroupMeta, description)
 */
export const parseFiltersToRequest = (filters = {}) => {
  const requestFilters = {};
  Object.keys(filters).forEach((filterKey) => {
    const filter = filters[filterKey];

    // regular filter case
    if (filter.selected) {
      requestFilters[filterKey] = {
        selected: filter.selected,
        multipleKeys: filter.multipleKeys,
      };
    }

    // /query case:
    if (filter.isQuery) {
      // from each query, each querySet, each queryBlock, get the selected values and remove all the other data, and set it in the requestFilters object.

      const selected = filter.selected;
      const requestQuery = [];
      selected.forEach((querySet) => {
        const requestQuerySet = [];
        querySet.forEach((queryBlock) => {
          const requestQueryBlock = {};
          Object.keys(queryBlock).forEach((queryBlockKey) => {
            requestQueryBlock[queryBlockKey] = queryBlock[queryBlockKey].selected;
          });
          requestQuerySet.push(requestQueryBlock);
        });
        requestQuery.push(requestQuerySet);
      });
      requestFilters[filterKey] = {
        selected: requestQuery,
      };
    }
  });
  return requestFilters;
};
