import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button as AntButton, Tooltip } from 'antd';
import classNames from 'classnames';

import { translate } from 'utils/index';
import SecondaryButton from 'ui/SecondaryButton';
import Button from 'ui/Button';
import Icon from 'ui/Icon';
import Loader from 'ui/Loader';
import { selectProfile } from 'pages/Profile/selectors';
import { selectAllApps } from 'pages/Editor/selectors';
import { addAppTrigger } from 'api/editor';
import { messages } from 'pages/Editor/messages';
import { selectAllOrgApps } from 'pages/Organization/selectors';
import { getOrganizationApps } from 'api/organizations';

import './appTriggersModal.scss';

// const getWebsiteControls = (apps) => {
//   const additionalDomainControlId = uuidv4();
//   return [
//     {
//       type: ControlType.Input,
//       title: 'Please enter your domain/website address',
//       description:
//         'Providing a domain/website address allows Findings to activate automatic apps that analyze and provide insights with regards to the assessed company.',
//       app_trigger: '49210d50-f4cc-43db-9be4-ec2cb4c504a8',
//       apps,
//     },
//     {
//       type: ControlType.RadioGroup,
//       answer_type: 'f553914a-4901-40b7-ae80-856edb44be5c',
//       answer_type_idx: 'f553914a-4901-40b7-ae80-856edb44be5c',
//       title: 'Do you have any other relevant domains/websites?',
//       descriptionRight:
//         'A “relevant domain/website” is any digital asset owned by the company that might have any implications to the current business engagement.',
//       triggers: { 1: [additionalDomainControlId] },
//       answerTypeJson: {
//         options: [
//           { key: 1, value: 'Yes', weight: 0 },
//           { key: 2, value: 'No', weight: 0 },
//         ],
//         rtl: false,
//         type: ControlType.RadioGroup,
//       },
//       apps,
//     },
//     {
//       type: ControlType.Input,
//       id: additionalDomainControlId,
//       title: 'Please enter your domain/website address',
//       description:
//         'Providing a domain/website address allows Findings to activate automatic apps that analyze and provide insights with regards to the assessed company.',
//       enabled: false,
//       app_trigger: '49210d50-f4cc-43db-9be4-ec2cb4c504a8',
//       apps,
//     },
//   ];
// };

// const getFindingsControls = () => {
//   const accountRegionControlId = uuidv4();
//   const accountIdControlId = uuidv4();
//   const emailControlId = uuidv4();
//   const subscriptionIdControlId = uuidv4();
//
//   return [
//     {
//       type: ControlType.Checkbox,
//       answer_type: '0c22f0ce-d8f9-4a47-a803-e54590cf8334',
//       answer_type_idx: '0c22f0ce-d8f9-4a47-a803-e54590cf8334',
//       title: 'Who is your cloud provider?',
//       description:
//         'The following questions will allow Findings to connect to the assessed company’s cloud provider instance and VIEW relevant information. This requires the assessed company’s explicit consent within the cloud provider’s console.',
//       triggers: {
//         1: [accountRegionControlId, accountIdControlId, emailControlId],
//         2: [subscriptionIdControlId, emailControlId],
//       },
//       answerTypeJson: {
//         options: [
//           { key: 1, value: 'AWS', weight: 0 },
//           { key: 2, value: 'Azure', weight: 0 },
//         ],
//         rtl: false,
//         type: ControlType.RadioGroup,
//       },
//       apps: [findingsCloudMonitoringId],
//     },
//     {
//       type: ControlType.Input,
//       id: accountRegionControlId,
//       title: 'What is your account’s region?',
//       enabled: false,
//       app_trigger: 'a4c73d9e-403a-4f08-a322-6eec17e14298',
//       apps: [findingsCloudMonitoringId],
//     },
//     {
//       type: ControlType.Input,
//       id: accountIdControlId,
//       title: 'What is your account ID?',
//       enabled: false,
//       app_trigger: 'aa26111f-8820-45c3-8742-dabca01f1cf6',
//       apps: [findingsCloudMonitoringId],
//     },
//     {
//       type: ControlType.Input,
//       id: emailControlId,
//       title: 'What is the email address of your cloud administrator?',
//       descriptionRight:
//         'After submitting this assessment, a handshake request will be sent to the assessed company’s cloud provider console. Findings will also notify the cloud administrator within the assessed company, to ensure this handshake is accepted.',
//       enabled: false,
//       app_trigger: '66e0442e-0d1e-4a48-94c4-c2f0c0376eb8',
//       apps: [findingsCloudMonitoringId],
//     },
//     {
//       type: ControlType.Input,
//       id: subscriptionIdControlId,
//       title: 'What is you subscription ID?',
//       enabled: false,
//       app_trigger: '730e825c-4e90-4790-a737-5896f87be88d',
//       apps: [findingsCloudMonitoringId],
//     },
//   ];
// };

const AppTriggersModal = ({ categoryId, hideModal, open }) => {
  const apps = useSelector(selectAllOrgApps);
  const selectedAllApps = useSelector(selectAllApps);
  // const answerTypes = useSelector(selectAllAnswerTypes);
  const [selectedApps, setSelectedApps] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    user: {
      current_organization: { id: currentOrganizationId },
    },
  } = useSelector(selectProfile);

  useEffect(() => {
    dispatch(getOrganizationApps({ currentOrganizationId }));
  }, []);

  const onCancel = () => {
    hideModal();
  };

  // const onOk = async () => {
  //   const otherApps = selectedApps.filter((app) => app !== findingsCloudMonitoringId);
  //   const isFindingCloudMonitoring = controls.some((control) =>
  //     (control.apps || []).includes(findingsCloudMonitoringId),
  //   );
  //   const otherAppControls = controls.filter((control) =>
  //     otherApps.some((app) => (control.apps || []).includes(app)),
  //   );
  //
  //   if (selectedApps.includes(findingsCloudMonitoringId) && !isFindingCloudMonitoring) {
  //     await createControls(getFindingsControls());
  //   }
  //
  //   if (otherApps.length > 0 && otherAppControls.length === 0) {
  //     await createControls(getWebsiteControls(otherApps));
  //   } else if (otherApps.length > 0) {
  //     await updateWebsiteAppControls(otherAppControls, otherApps);
  //   }
  // };

  const onOk = async () => {
    setLoading(true);
    await Promise.all(
      selectedApps.map(async (appId) => {
        await dispatch(addAppTrigger({ appId, categoryId })).unwrap();
      }),
    );
    setLoading(false);
    hideModal();
  };

  // const createControls = async (controlsWithAnswerTypes) => {
  //   await createAnswerTypes(controlsWithAnswerTypes);
  //
  //   const controls = controlsWithAnswerTypes.map(({ type, answerTypeJson, ...control }) => {
  //     const answerType = answerTypes.find(({ type: selectedType }) => type === selectedType);
  //
  //     const newControl = {
  //       ...control,
  //       id: control.id || uuidv4(),
  //       categoryId,
  //     };
  //
  //     if (!newControl.answer_type) {
  //       return {
  //         ...newControl,
  //         answer_type: answerType.id,
  //         answer_type_idx: answerType.id,
  //       };
  //     }
  //
  //     return newControl;
  //   });
  //
  //   const payload = {
  //     categories: groupControlsByCategory(controls),
  //   };
  //
  //   setLoading(true);
  //   await dispatch(saveTemplate(payload)).unwrap();
  //   // it require double request to save triggers
  //   await dispatch(saveTemplate(payload)).unwrap();
  //   setLoading(false);
  //   hideModal();
  // };

  // const updateWebsiteAppControls = async (appControls, apps) => {
  //   const controlsToSave = appControls.map((control) => ({ ...control, apps }));
  //
  //   const payload = {
  //     categories: groupControlsByCategory(controlsToSave),
  //   };
  //
  //   setLoading(true);
  //   await dispatch(saveTemplate(payload)).unwrap();
  //   setLoading(false);
  //   hideModal();
  // };

  // const createAnswerTypes = async (controlsWithAnswerTypes) => {
  //   const newAnswerTypes = controlsWithAnswerTypes.reduce(
  //     (acc, { answerTypeJson, answer_type }) => {
  //       const answerType = answerTypes.find(({ id }) => id === answer_type);
  //       if (!answerType && answerTypeJson && answer_type) {
  //         acc.push({
  //           ...answerTypeJson,
  //           id: answer_type,
  //         });
  //       }
  //       return acc;
  //     },
  //     [],
  //   );
  //
  //   await Promise.all(
  //     newAnswerTypes.map(async (answerType) => {
  //       await dispatch(saveAnswerType(answerType)).unwrap();
  //     }),
  //   );
  // };

  const footer = [
    <Button
      key="footer_button_app_triggers_1"
      className="editor-app-triggers-modal__footer-cancel-button"
      size="sm"
      color="gray"
      onClick={onCancel}
      link
      data-test={'app-trigger-popup-cancel-button'}
    >
      {translate(messages.cancel)}
    </Button>,
    <SecondaryButton
      key="footer_button_app_triggers_2"
      className="editor-app-triggers-modal__footer-ok-button"
      disabled={selectedApps.length === 0}
      onClick={onOk}
      size="medium"
      type="submit"
      outline
      loading={loading}
      data-test={'app-trigger-popup-select-button'}
    >
      {translate(messages.select)}
    </SecondaryButton>,
  ];

  return (
    <Modal
      className="editor-app-triggers-modal__modal"
      closeIcon={<Icon icon="close" />}
      open={open}
      onCancel={onCancel}
      width={900}
      footer={footer}
    >
      <div className="editor-app-triggers-modal__header">
        <h3 className="editor-app-triggers-modal__header-title">
          {translate(messages.addAppTriggerTitle)}
        </h3>
        <div className="editor-app-triggers-modal__header-description">
          {translate(messages.pleaseSelectApp)}
        </div>
      </div>
      <div className="editor-app-triggers-modal__services">
        {apps?.map((app, index) => {
          const isAppSelected = selectedApps?.includes(app.id);
          const isAppSelectedInAll = selectedAllApps?.includes(app.id);
          const isAppActive = !!app.state;
          return (
            <Tooltip
              key={`button_apps_tooltip_${index}`}
              className="editor-app-triggers-modal__service-tooltip"
              placement="bottom"
              title={
                !isAppActive
                  ? translate(messages.appTriggerDisabledTooltipAppNotActivated)
                  : isAppSelectedInAll
                  ? translate(messages.appTriggerDisabledTooltipAlreadySelected)
                  : translate(messages.appTriggerSelectTooltip)
              }
            >
              <>
                <AntButton
                  key={`button_apps_${index}`}
                  className={classNames('editor-app-triggers-modal__service', {
                    'editor-app-triggers-modal__service--selected': isAppSelected,
                  })}
                  disabled={isAppSelectedInAll || !isAppActive}
                  onClick={() => {
                    if (isAppSelected) {
                      return setSelectedApps((services) =>
                        services.filter((service) => service !== app.id),
                      );
                    }
                    setSelectedApps([...selectedApps, app.id]);
                  }}
                  type="link"
                  data-test={`add-app-trigger-button-${app.name}`}
                >
                  {app.icon && (
                    <div className="editor-app-triggers-modal__service-icon-wrap">
                      <img
                        className={`editor-app-triggers-modal__service-icon editor-app-triggers-modal__service-${app.name}-icon`}
                        src={app.icon}
                        alt={app.name}
                      />
                    </div>
                  )}
                  <div className="editor-app-triggers-modal__service-name">{app.name}</div>
                </AntButton>
              </>
            </Tooltip>
          );
        })}
      </div>
      {loading && <Loader />}
    </Modal>
  );
};

AppTriggersModal.propTypes = {
  categoryId: PropTypes.string,
  hideModal: PropTypes.func,
  open: PropTypes.bool,
};

export default AppTriggersModal;
