import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetProfileSummaryCustomers, GetProfileSummarySelf } from 'api/endpoints';

const getOverviewRequest = async (args, { getState }) => {
  const { app, assessmentsFilters } = getState();
  const url = app.isMyVendorsTabSelected
    ? GetProfileSummaryCustomers.url
    : GetProfileSummarySelf.url;

  const payload = {};

  assessmentsFilters.filters.forEach((filter) => {
    if (filter.id !== undefined) {
      if (app.isMyVendorsTabSelected) {
        payload[filter.type === 'vendor' ? 'customer' : filter.type] = filter.id;
      } else {
        payload[`get-summary-self-${filter.type}`] = filter.id;
      }
    }
  });

  return axios.post(url, payload);
};

export const getOverview = createAsyncThunk('getOverview', getOverviewRequest);
