/* eslint-disable no-case-declarations */

import { ControlType } from 'constants/controlType';
import moment from 'moment';

export const controlsMap = (assessment) =>
  assessment?.categories
    .flatMap((category) => {
      return category.controls;
    })
    .map((control) => {
      const answerData = assessment?.answer_types[control.answer_type_idx];

      return {
        ...control,
        answerData,
      };
    })
    .reduce((acc, control) => {
      const { categoryId } = control;
      const currentControls = acc.get(categoryId);

      if (currentControls) {
        currentControls.push(control);
        acc.set(categoryId, currentControls);
      } else {
        acc.set(categoryId, [control]);
      }

      return acc;
    }, new Map());

export const categoriesWithMappedControls = (assessment, mappedControls) =>
  assessment.categories.map((category) => {
    const mappedControl = mappedControls.get(category.id);
    if (mappedControl) {
      return {
        ...category,
        controls: mappedControl,
      };
    }
    return category;
  });

export const parsePayload = (
  control,
  values,
  currentUploadedFiles,
  onChange,
  handleUpload,
  handleRemove,
) => {
  const { type } = control.answerData;
  const controlValue = values[control.id] || '';

  switch (type) {
    case ControlType.DatePicker:
      return {
        name: control.id,
        onChange: (value) => {
          onChange(control.id, value);
        },
        value: controlValue ? moment(controlValue) : '',
      };

    case ControlType.FileUpload:
      return {
        accept: '',
        edit: undefined,
        browse: false,
        handleUpload: (event) => {
          handleUpload(event, control.id);
        },
        handleRemove: (fileId) => {
          handleRemove(fileId, control.id);
        },
        uploadedFiles: [...controlValue, ...currentUploadedFiles],
      };
    case ControlType.EmptySpace:
      return {};
    default:
      return null;
  }
};
