import React from 'react';
import PropTypes from 'prop-types';

const ClockIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M12 2C6.48603 2 2 6.48604 2 12C2 17.514 6.48603 22 12 22C17.514 22 22 17.514 22 12C22 6.48604 17.514 2 12 2ZM12 3.5C16.7033 3.5 20.5 7.2967 20.5 12C20.5 16.7033 16.7033 20.5 12 20.5C7.29669 20.5 3.5 16.7033 3.5 12C3.5 7.2967 7.29669 3.5 12 3.5ZM11.2383 5.98926C11.0395 5.99236 10.8502 6.07423 10.7117 6.21686C10.5733 6.3595 10.4971 6.55125 10.5 6.75V12.25C10.5 12.4489 10.5791 12.6396 10.7197 12.7803L13.2197 15.2803C13.2888 15.3523 13.3716 15.4097 13.4632 15.4493C13.5548 15.4889 13.6534 15.5098 13.7532 15.5108C13.8529 15.5118 13.9519 15.4929 14.0443 15.4552C14.1367 15.4175 14.2206 15.3617 14.2912 15.2912C14.3617 15.2206 14.4175 15.1367 14.4552 15.0443C14.4929 14.9519 14.5118 14.8529 14.5108 14.7532C14.5098 14.6534 14.4889 14.5548 14.4493 14.4632C14.4097 14.3716 14.3523 14.2888 14.2803 14.2197L12 11.9395V6.75C12.0014 6.64962 11.9827 6.54997 11.9449 6.45695C11.9071 6.36394 11.8511 6.27946 11.78 6.20852C11.709 6.13758 11.6245 6.08161 11.5314 6.04395C11.4383 6.00629 11.3387 5.98769 11.2383 5.98926Z"
      fill={color}
    />
  </svg>
);

ClockIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ClockIcon;
