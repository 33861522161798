export const messages = {
  header: {
    key: 'MyFilesPage.header',
    defaultMessage: 'My Files',
  },

  //list
  emptyListMessage: {
    key: 'MyFilesPage.emptyListMessage',
    defaultMessage:
      'Keep all your important files in one place and never lose track of expiration dates again! Use My Files for certificates, policies, evidence, and any other files you need to store.',
  },
  maxSizeInfo: {
    key: 'MyFilesPage.maxSizeInfo',
    defaultMessage: 'Max file size: 50MB',
  },
  uploadNewFile: {
    key: 'MyFilesPage.uploadNewFile',
    defaultMessage: 'UPLOAD NEW FILE',
  },
  loadingMoreFiles: {
    key: 'MyFilesPage.loadingMoreFiles',
    defaultMessage: 'Loading more files...',
  },

  //item
  uploadFile: {
    key: 'MyFilesPage.uploadFile',
    defaultMessage: 'Upload file',
  },
  expiredStatus: {
    key: 'MyFilesPage.expiredStatus',
    defaultMessage: 'Expired',
  },
  validateStatus: {
    key: 'MyFilesPage.validateStatus',
    defaultMessage: 'Valid',
  },
  noExpiration: {
    key: 'MyFilesPage.noExpiration',
    defaultMessage: 'N/A',
  },
  expiringSoonLong: {
    key: 'MyFilesPage.expiringSoonLong',
    defaultMessage: 'Expiring soon: In {{days}} days',
  },
  expiringSoonShort: {
    key: 'MyFilesPage.expiringSoonShort',
    defaultMessage: 'Expiring soon',
  },
  expiringToday: {
    key: 'MyFilesPage.expiringToday',
    defaultMessage: 'Expiring Today',
  },
  menuPreview: {
    key: 'MyFilesPage.menuPreview',
    defaultMessage: 'Preview',
  },
  menuEdit: {
    key: 'MyFilesPage.menuEdit',
    defaultMessage: 'Edit file info',
  },
  menuRename: {
    key: 'MyFilesPage.menuRename',
    defaultMessage: 'Rename',
  },
  menuDownload: {
    key: 'MyFilesPage.menuDownload',
    defaultMessage: 'Download',
  },
  noPreview: {
    key: 'MyFilesPage.noPreview',
    defaultMessage: 'NO PREVIEW',
  },
  filePreviewDefaultTitle: {
    key: 'MyFilesPage.filePreviewDefaultTitle',
    defaultMessage: 'Preview',
  },
  downloadError: {
    key: 'MyFilesPage.downloadError',
    defaultMessage: 'Could not download the file. Please try again later.',
  },
  renameFileTitle: {
    key: 'MyFilesPage.renameFileTitle',
    defaultMessage: 'Rename file',
  },
  renameFilePlaceholder: {
    key: 'MyFilesPage.renameFilePlaceholder',
    defaultMessage: 'Enter new file name',
  },
  closeRenameModal: {
    key: 'MyFilesPage.closeRenameModal',
    defaultMessage: 'CANCEL',
  },
  removeFileTitle: {
    key: 'MyFilesPage.removeFileTitle',
    defaultMessage: 'Remove file',
  },
  deleteFileTitle: {
    key: 'MyFilesPage.deleteFileTitle',
    defaultMessage: 'Delete file',
  },
  removeFileDescription: {
    key: 'MyFilesPage.removeFileDescription',
    defaultMessage:
      "Please note, this file has been previously uploaded to an assessment. Removing it from 'My File' gallery will not remove it from the control/finding it was attached to.",
  },
  deleteFileDescription: {
    key: 'MyFilesPage.removeFileDescription',
    defaultMessage: 'Are you sure you want to permanently delete this file?',
  },
  closeRemoveDeleteModal: {
    key: 'MyFilesPage.closeRemoveModal',
    defaultMessage: 'CANCEL',
  },
  deleteFileOk: {
    key: 'MyFilesPage.deleteFileOk',
    defaultMessage: 'DELETE FILE',
  },
  removeFileOk: {
    key: 'MyFilesPage.removeFileOk',
    defaultMessage: 'REMOVE FILE',
  },
  saveFileName: {
    key: 'MyFilesPage.saveFileName',
    defaultMessage: 'SAVE',
  },
  renameFileLabel: {
    key: 'MyFilesPage.renameFileLabel',
    defaultMessage: 'File name',
  },

  menuDeleteLabel: {
    key: 'MyFilesPage.menuDeleteLabel',
    defaultMessage: 'Delete',
  },

  //filters
  formatType: {
    key: 'MyFilesPage.formatType',
    defaultMessage: 'Format type',
  },
  expiration: {
    key: 'MyFilesPage.expiration',
    defaultMessage: 'Expiration',
  },
};

export default {
  prefix: 'MyFilesPage',
  messages,
};
