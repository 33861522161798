import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

const Radio = ({
  checked,
  className,
  disabled,
  onChange,
  children,
  name,
  value,
  onClick,
  rtl,
  ...otherProps
}) => {
  const labelClassNames = classNames('ui-radio__label', {
    'ui-radio__label--disabled': disabled,
    'ui-radio__label--checked': checked,
    'ui-radio--rtl': rtl,
  });

  return (
    <div className={classNames('ui-radio', className)}>
      <label className={labelClassNames} data-test={otherProps['data-test']}>
        <span>
          <input
            value={value}
            onClick={onClick}
            name={name}
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            type="radio"
          />
        </span>
        {children}
      </label>
    </div>
  );
};

Radio.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  rtl: PropTypes.bool,
};

export default Radio;
