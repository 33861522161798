import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigationType } from 'react-router';
import moment from 'moment';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { getRecentVendors } from 'api/recent';
import { translate } from 'utils/index';
import { createSorterByString } from 'utils/strings';
import ROUTES from 'constants/routes';
import { MixpanelAssignAssessmentSource } from 'constants/mixpanel';
import { DATE_FORMAT } from 'constants/date';
import {
  getNavPathWithFilters,
  useAppNavigate,
  useGetPath,
  useIsMyVendorsTabSelected,
} from 'hooks/navigation';
import { useTheme } from 'hooks/theme';
import PageSubheader from 'components/PageSubheader';
import Button from 'ui/Button';
import Table from 'ui/Table';
import { setFromRecent } from 'pages/Vendors/reducers';
import { Sorter } from 'ui/Table/sorter';
import Status from 'pages/Vendors/VendorsList/status';
import Actions from 'pages/Vendors/VendorsList/actions';

import { removeVendor } from 'api/vendor';
import { TableSource } from 'ui/Table/lib';
import { selectIsViewAggregated } from 'pages/Organization/selectors';

import InherentRiskPopover from './vendorsColumns/inherentRiskPopover';
import InherentRisk from './vendorsColumns/inherentRisk';
import AverageScorePopover from './vendorsColumns/averageScorePopover';
import VendorStatus from './vendorsColumns/vendorStatus';
import BusinessOwner from './vendorsColumns/businessOwner';
import Assessments from './vendorsColumns/assessments';
import VendorRisk from './vendorsColumns/vendorRisk';

import { setVendorsSorter } from './reducers';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import colors from 'ui/colors.scss';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import { ReactComponent as FlagIcon } from 'assets/svgs/flag-icon.svg';
import { eFilterKeys } from 'components/Filters/lib';

import './vendors.scss';

const Vendors = () => {
  const dispatch = useDispatch();
  const getPath = useGetPath();
  const { screenSize } = useTheme();

  const appNavigate = useAppNavigate();
  const { isAuditorRole, isViewerRole } = useRole();
  const isViewAggregated = useSelector(selectIsViewAggregated);
  const [loading, setLoading] = useState(false);
  const [vendorToRemove, setVendorToRemove] = useState();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const navigationType = useNavigationType();
  const { colorTheme } = useTheme();
  const { vendors, vendorsLoading, vendorsSorter } = useSelector((state) => state.recent);

  useEffect(() => {
    if (navigationType !== 'POP') {
      dispatch(setVendorsSorter({}));
    }
    dispatch(getRecentVendors());
  }, []);

  const sortOrder = vendorsSorter.descending ? 'descend' : 'ascend';

  const columns = [
    {
      title: translate(messages.date),
      dataIndex: 'timestampCreated',
      width: 130,
      render: (timestampCreated) => (
        <div style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '100%' }}>
          {moment(timestampCreated).format(DATE_FORMAT)}
        </div>
      ),
      className: 'vendors-list__table-column-centered',
      sorter: true,
      minWidth: 90,
      flex: 90,
      sortOrder: vendorsSorter.column === 'timestampCreated' ? sortOrder : null,
    },
    {
      title: translate(messages.name),
      minWidth: 190,
      flex: 260,
      dataIndex: 'profileCorporateName',
      render: (name, vendor) => {
        const link = isMyVendorsTabSelected
          ? getPath(ROUTES.VENDOR_EDIT, { vendorId: vendor.id || '' })
          : getPath(ROUTES.CUSTOMER_EDIT_CUSTOMER, { customerId: vendor.id || '' });

        return (
          <div>
            {vendor.parentOrganizationCorporateName && isViewAggregated && (
              <div className="recent-page-vendors__orgname">
                {vendor.parentOrganizationCorporateName}
              </div>
            )}
            {vendor.onboarding && (
              <span
                className="recent-page-vendors__onboarding-not-completed"
                title={translate(messages.onboardingNotCompleted)}
              />
            )}
            {isViewerRole ? (
              <span>{name}</span>
            ) : (
              <Link className="recent-page-vendors__table-link" to={link}>
                {name}
              </Link>
            )}
          </div>
        );
      },
      sorter: createSorterByString('profileCorporateName'),
      sortOrder: vendorsSorter.column === 'profileCorporateName' ? sortOrder : null,
    },
    {
      title: translate(messages.activeAssessments),
      dataIndex: 'assessments',
      render: (assessments) => <Assessments assessments={assessments} />,
      sorter: false,
      minWidth: 90,
    },
    {
      title:
        screenSize === 'desktop' ? translate(messages.findings) : translate(messages.openFindings),
      dataIndex: 'openFindings',
      minWidth: 90,
      flex: 90,
      render: (findings, vendor) => {
        if (findings === 0) {
          return (
            <div className="recent-page-vendors__findings-empty-link">
              <FlagIcon hight={18} width={18} />
              <span className="recent-page-vendors__findings-link-findings">0</span>
            </div>
          );
        }

        const selected = [{ key: vendor.profileCorporateName, value: vendor.profileCorporateName }];

        const link = isMyVendorsTabSelected
          ? getNavPathWithFilters(getPath(ROUTES.VENDOR_FINDINGS), {
              [eFilterKeys.vendorName]: { selected },
            })
          : getNavPathWithFilters(getPath(ROUTES.CUSTOMER_FINDINGS), {
              [eFilterKeys.customerName]: { selected },
            });

        return (
          <Link className="recent-page-vendors__findings-link" to={link}>
            <FlagIcon hight={18} width={18} />
            <span className="recent-page-vendors__findings-link-findings">{findings}</span>
          </Link>
        );
      },
      sorter: {
        compare: Sorter.DEFAULT,
      },
      sortOrder: vendorsSorter.column === 'openFindings' ? sortOrder : null,
    },
    {
      title: translate(messages.inherentRisk),
      dataIndex: 'inherentRisk',
      minWidth: 90,
      flex: 90,
      render: (inherentRisk) => {
        const highestInherentRisk = inherentRisk
          .filter(({ progress }) => progress !== 0)
          .reduce((prev, current) => {
            return prev && prev.score > current.score ? prev : current;
          }, {});

        if (inherentRisk.length === 0 || isEmpty(highestInherentRisk)) {
          return (
            <div
              style={{ color: colors.gray400 }}
              className="recent-page-vendors__empty-inherent-risk"
            >
              {translate(messages.emptyStateValue)}
            </div>
          );
        }

        return (
          <InherentRiskPopover inherentRisk={inherentRisk}>
            <InherentRisk score={highestInherentRisk.score} />
          </InherentRiskPopover>
        );
      },
      sorter: {
        compare: Sorter.DEFAULT,
      },
      sortOrder: vendorsSorter.column === 'inherentRisk' ? sortOrder : null,
    },
    {
      title: translate(messages.averageScore),
      dataIndex: 'assessmentScore',
      render: (assessmentScore, vendor) => {
        if (vendor.assessmentScores.length === 0 && vendor.appsScores.length === 0) {
          return <div style={{ color: colors.gray400 }}>{translate(messages.emptyStateValue)}</div>;
        }

        return (
          <AverageScorePopover vendor={vendor} score={assessmentScore.averageAssessmentScore}>
            {Math.round((assessmentScore.averageAssessmentScore + Number.EPSILON) * 100) / 100}%
          </AverageScorePopover>
        );
      },
      minWidth: 85,
      flex: 85,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      sortOrder: vendorsSorter.column === 'assessmentScore' ? sortOrder : null,
    },
    {
      title: translate(isMyVendorsTabSelected ? messages.vendorRisk : messages.customerRisk),
      dataIndex: 'vendorRisk',
      minWidth: 100,
      flex: 100,
      render: (vendorRisk) => <VendorRisk value={vendorRisk} />,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      sortOrder: vendorsSorter.column === 'vendorRisk' ? sortOrder : null,
    },
    {
      title: translate(messages.trustIndicator),
      dataIndex: 'profileStatus',
      minWidth: 110,
      flex: 190,
      maxWidth: 140,
      render: (_, vendor) => <Status vendor={vendor} />,
      sorter: false,
    },
    {
      title: translate(messages.vendorStatus),
      dataIndex: 'vendorStatus',
      minWidth: 100,
      flex: 240,
      render: (vendorStatus, vendor) => {
        return (
          <VendorStatus
            status={vendorStatus}
            vendorId={vendor.id}
            disabled={isViewerRole || isAuditorRole}
          />
        );
      },
      sorter: {
        compare: Sorter.DEFAULT,
      },
      sortOrder: vendorsSorter.column === 'vendorStatus' ? sortOrder : null,
    },
    {
      title: translate(messages.businessOwner),
      dataIndex: 'boAndAuditorUsers',
      minWidth: 105,
      flex: 105,
      render: (boAndAuditorUsers, vendor) => {
        return <BusinessOwner boUsers={boAndAuditorUsers} vendor={vendor} />;
      },
      sorter: false,
    },
    {
      dataIndex: 'actions',
      minWidth: 50,
      flex: 50,
      width: 75,
      maxWidth: 75,
      render: (_, vendor) => (
        <Actions
          isVendorsList
          source={
            isMyVendorsTabSelected
              ? MixpanelAssignAssessmentSource.RecentVendor
              : MixpanelAssignAssessmentSource.RecentCustomer
          }
          vendor={vendor}
          setVendorToRemove={setVendorToRemove}
        />
      ),
    },
  ];

  const dataSource = (vendors || []).map((vendor) => ({
    ...vendor,
    key: vendor.id,
  }));

  const onClickAddVendor = () => {
    dispatch(setFromRecent(true));
    return appNavigate(
      isMyVendorsTabSelected ? ROUTES.VENDOR_NEW_VENDOR : ROUTES.CUSTOMER_NEW_CUSTOMER,
    );
  };

  const onRemove = async (event) => {
    event.stopPropagation();
    setLoading(true);
    await dispatch(removeVendor({ id: vendorToRemove.id })).unwrap();
    await dispatch(getRecentVendors()).unwrap();

    setVendorToRemove(null);
    setLoading(false);
  };

  const onTableChange = (newPagination, filters, sorter) => {
    const sorterParams = {
      column: sorter.field,
      descending: sorter.order !== 'ascend',
    };
    const params = sorter.column ? sorterParams : {};
    dispatch(setVendorsSorter(params));
    dispatch(getRecentVendors({ column: sorter?.field, descending: sorter?.order === 'descend' }));
  };

  return (
    <div className="recent-page-vendors">
      <div className="recent-page-vendors__top">
        <PageSubheader className="recent-page-vendors__page-subheader">
          {translate(isMyVendorsTabSelected ? messages.recentVendors : messages.recentCustomers)}
        </PageSubheader>
        <div className="recent-page-vendors__menu">
          <Button
            data-test="recent-vendors-button-view-all"
            className="recent-page-vendors__view-all-button"
            link
            color="black"
            size="xs"
            onClick={() =>
              appNavigate(
                isMyVendorsTabSelected ? ROUTES.VENDOR_VENDORS : ROUTES.CUSTOMER_CUSTOMERS,
              )
            }
          >
            {translate(messages.viewAll)}
          </Button>

          <Button
            data-test="recent-vendors-button-add-vendor"
            className="recent-page-vendors__assign-button"
            disabled={isViewerRole || isAuditorRole}
            beforeIcon="plus"
            color={colorTheme}
            size="sm"
            onClick={onClickAddVendor}
          >
            {translate(isMyVendorsTabSelected ? messages.vendor : messages.customer)}
          </Button>
        </div>
      </div>
      <Table
        className="recent-page-vendors__table"
        columns={columns}
        dataSource={dataSource}
        loading={vendorsLoading}
        pagination={false}
        onChange={onTableChange}
        scroll={{
          y: 300,
          x: 1160,
        }}
        sortable
        emptyStateSource={
          isMyVendorsTabSelected ? TableSource.vendorsRecent : TableSource.customersRecent
        }
        rowKey="recent-vendor-table"
      />
      <RemoveConfirmationModal
        confirmLoading={loading}
        onCancel={() => setVendorToRemove(null)}
        onOk={onRemove}
        text={`${translate(messages.removeVendorText, {
          vendor: vendorToRemove?.profileCorporateName,
        })}`}
        open={!!vendorToRemove}
        headerText={`${translate(messages.removeVendorHeader, {
          vendor: vendorToRemove?.profileCorporateName,
        })}`}
      />
    </div>
  );
};

export default Vendors;
