import React from 'react';
import { translate } from 'utils/index';
import Icon from 'ui/Icon';

import './passwordRulesBlock.scss';
import { messages } from './messages';

const PasswordRulesBlock = () => {
  return (
    <div className="password-rules-block">
      <div className="password-rules-block__icon">
        <Icon icon="info" />
      </div>
      <div className="password-rules-block__info-text">
        {translate(messages.passwordRulesBlock)}
      </div>
    </div>
  );
};

export default PasswordRulesBlock;
