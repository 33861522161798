import { createSlice } from '@reduxjs/toolkit';
import { locales } from 'configs/initializeTranslations';
import { getTrustedPartners, getWebConstants } from 'api/app';
import { rejectTrustPartnerThunk, establishTrustPartners } from 'api/organizations';

const initialState = {
  language: locales.en,
  constants: {},
  isMyVendorsTabSelected: true,
  trustedOrganizations: {},
  isTrustedOrganizationsLoading: false,
  isTranslationsLoaded: false,
};

const reducers = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeLanguage: (state, { payload: language }) => {
      state.language = language;
    },
    setIsMyVendorsTab: (state, { payload }) => {
      state.isMyVendorsTabSelected = payload;
    },
    setIsTranslationsLoaded: (state) => {
      state.isTranslationsLoaded = true;
    },
    clearStore: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWebConstants.fulfilled, (state, { payload: { consts } }) => {
        state.constants = consts;
      })
      .addCase(getTrustedPartners.pending, (state) => {
        state.isTrustedOrganizationsLoading = true;
      })
      .addCase(getTrustedPartners.fulfilled, (state, { payload }) => {
        state.trustedOrganizations = payload || {};
        state.isTrustedOrganizationsLoading = false;
      })
      .addCase(rejectTrustPartnerThunk.pending, (state) => {
        state.isTrustedOrganizationsLoading = true;
      })
      .addCase(rejectTrustPartnerThunk.fulfilled, (state, { payload }) => {
        state.trustedOrganizations = payload || {};
        state.isTrustedOrganizationsLoading = false;
      })
      .addCase(establishTrustPartners.pending, (state) => {
        state.isTrustedOrganizationsLoading = true;
      })
      .addCase(establishTrustPartners.fulfilled, (state, { payload }) => {
        state.trustedOrganizations = payload || {};
        state.isTrustedOrganizationsLoading = false;
      });
  },
});

export const { clearStore, changeLanguage, setIsMyVendorsTab, setIsTranslationsLoaded } =
  reducers.actions;

export default reducers;
