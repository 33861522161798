import React from 'react';
import PropTypes from 'prop-types';

const FilterIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M4.68045 3.5C4.03652 3.5 3.49988 4.03664 3.49988 4.68057V6.13969C3.49988 7.22424 3.99831 8.25008 4.85016 8.92142L9.63887 12.6946V19.7919C9.63893 19.922 9.67481 20.0496 9.74257 20.1606C9.81034 20.2717 9.90736 20.3619 10.023 20.4214C10.1387 20.481 10.2685 20.5075 10.3982 20.4982C10.528 20.4888 10.6527 20.4439 10.7586 20.3684L14.0642 18.0072C14.156 17.9417 14.2308 17.8552 14.2825 17.755C14.3342 17.6547 14.3611 17.5436 14.3612 17.4308V12.6937L19.1499 8.92142C20.0017 8.25008 20.5002 7.22424 20.5002 6.13969V4.68057C20.5002 4.03664 19.9635 3.5 19.3196 3.5H4.68045ZM4.91657 4.91669H19.0835V6.13969C19.0835 6.79106 18.7856 7.40472 18.2737 7.80818L13.5523 11.5279H10.4477L5.72637 7.80818C5.21442 7.40472 4.91657 6.79106 4.91657 6.13969V4.91669ZM11.0556 12.9446H12.9445V17.0665L11.0556 18.4158V12.9446Z"
      fill={color}
    />
  </svg>
);

FilterIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default FilterIcon;
