export const messages = {
  companyName: {
    key: 'Contact information tab customer.companyName',
    defaultMessage: 'Company name',
  },
  enterCompanyName: {
    key: 'Contact information tab customer.enterCompanyName',
    defaultMessage: 'Enter company name',
  },
  contactName: {
    key: 'Contact information tab customer.contactName',
    defaultMessage: 'Contact name',
  },
  enterContactName: {
    key: 'Contact information tab customer.enterContactName',
    defaultMessage: 'Enter contact name',
  },
  contactEmail: {
    key: 'Contact information tab customer.contactEmail',
    defaultMessage: 'Contact email',
  },
  enterContactEmail: {
    key: 'Contact information tab customer.enterContactEmail',
    defaultMessage: 'Enter contact email',
  },
  contactPhone: {
    key: 'Contact information tab customer.contactPhone',
    defaultMessage: 'Contact phone',
  },
  groups: {
    key: 'Contact information tab customer.groups',
    defaultMessage: 'Groups',
  },
  enterContactPhoneNumber: {
    key: 'Contact information tab customer.enterContactPhoneNumber',
    defaultMessage: 'Enter contact phone number',
  },
  selectGroups: {
    key: 'Contact information tab customer.selectGroups',
    defaultMessage: 'Select groups',
  },
  saveAndClose: {
    key: 'Contact information tab customer.saveAndClose',
    defaultMessage: 'Save and close',
  },
};

export default {
  prefix: 'Contact information tab customer',
  messages,
};
