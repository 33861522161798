import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigationType } from 'react-router';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { getNavPathWithFilters, useAppNavigate, useGetPath } from 'hooks/navigation';
import { translate } from 'utils/index';
import ROUTES from 'constants/routes';
import { MixpanelAssignAssessmentSource } from 'constants/mixpanel';
import PageHeader from 'components/PageHeader';
import Table from 'ui/Table';
import Button from 'ui/Button';
import { DATE_FORMAT } from 'constants/date';
import {
  setArchive,
  setFromRecent,
  setPagination,
  setSearch,
  setSorter,
} from 'pages/Vendors/reducers';
import { selectIsViewAggregated } from 'pages/Organization/selectors';
import VendorRisk from 'pages/Recent/vendorsColumns/vendorRisk';
import InherentRisk from 'pages/Recent/vendorsColumns/inherentRisk';
import Assessments from 'pages/Recent/vendorsColumns/assessments';
import InherentRiskPopover from 'pages/Recent/vendorsColumns/inherentRiskPopover';
import AverageScorePopover from 'pages/Recent/vendorsColumns/averageScorePopover';
import VendorStatus from 'pages/Recent/vendorsColumns/vendorStatus';
import BusinessOwner from 'pages/Recent/vendorsColumns/businessOwner';
import { TableSource } from 'ui/Table/lib';
import { useRole } from 'hooks/useRole';
import { getProfileVendors, removeVendor } from 'api/vendor';
import Status from './status';
import Search from './search';
import Actions from './actions';
import { messages } from './messages';
import colors from 'ui/colors.scss';
import { useTheme } from 'hooks/theme';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import { ReactComponent as FlagIcon } from 'assets/svgs/flag-icon.svg';
import Filters from 'components/Filters';
import { eFilterKeys, FILTERABLE_KEYS } from 'components/Filters/lib';
import { VENDORS_LIST_ALLOWED_FILTERS } from 'components/Filters/allowedFilters';
import ArchiveToggle from 'components/ArchiveToggle';

import './index.scss';

const VendorsList = () => {
  const dispatch = useDispatch();
  const getPath = useGetPath();
  const { screenSize } = useTheme();
  const appNavigate = useAppNavigate();
  const navigationType = useNavigationType();
  const { isViewerRole, isAuditorRole } = useRole();
  const isViewAggregated = useSelector(selectIsViewAggregated);
  const [loading, setLoading] = useState(false);
  const [vendorToRemove, setVendorToRemove] = useState();
  const { vendors, isVendorsFetching, pagination, sorter, archive } = useSelector(
    (state) => state.vendors,
  );
  const { filtersToApi } = useSelector(
    (state) => state.filters.allFilters[FILTERABLE_KEYS.VENDORS_LIST_FILTERS],
  );

  useEffect(() => {
    if (navigationType !== 'POP') {
      dispatch(setPagination({ current: 1 }));
      dispatch(setSearch());
      const sorter = {
        column: 'timestampCreated',
        descending: true,
      };
      dispatch(setSorter(sorter));
    }
    dispatch(getProfileVendors()).unwrap();
  }, [filtersToApi]);

  const onTableChange = (newPagination, _filters, sorter) => {
    dispatch(setPagination(newPagination));
    const filterParams = {
      column: sorter.field,
      descending: sorter.order !== 'ascend',
    };
    const params = sorter.column ? filterParams : {};

    dispatch(setSorter(params));
    dispatch(getProfileVendors()).unwrap();
  };

  const sortOrder = sorter.descending ? 'descend' : 'ascend';

  const columns = [
    {
      title: translate(messages.date),
      dataIndex: 'timestampCreated',
      render: (timestampCreated) => (
        <div style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '100%' }}>
          {moment(timestampCreated).format(DATE_FORMAT)}
        </div>
      ),
      className: 'vendors-list__table-column-centered',
      sorter: true,
      minWidth: 90,
      flex: 90,
      sortOrder: sorter.column === 'timestampCreated' ? sortOrder : null,
    },
    {
      title: translate(messages.name),
      dataIndex: 'profileCorporateName',
      minWidth: 190,
      flex: 260,
      fixed: 'left',
      render: (name, vendor) => {
        return (
          <div>
            {vendor.parentOrganizationCorporateName && isViewAggregated && (
              <div className="vendors-list__orgname">{vendor.parentOrganizationCorporateName}</div>
            )}
            {vendor.onboarding && (
              <span
                className="vendors-list__onboarding-not-completed"
                title={translate(messages.onboardingNotCompleted)}
              />
            )}
            {isViewerRole ? (
              <span>{name}</span>
            ) : (
              <Link
                className="vendors-list__table-link"
                to={getPath(ROUTES.VENDOR_EDIT, {
                  vendorId: vendor.id || '',
                })}
              >
                {name}
              </Link>
            )}
          </div>
        );
      },
      sorter: true,
      sortOrder: sorter.column === 'profileCorporateName' ? sortOrder : null,
    },
    {
      title: translate(messages.activeAssessments),
      minWidth: 90,
      flex: 340,
      dataIndex: 'assessments',
      render: (assessments, vendor) => (
        <Assessments key={vendor.id} assessments={assessments || []} />
      ),
      sorter: false,
    },
    {
      title:
        screenSize === 'desktop'
          ? translate(messages.openFindingsSmall)
          : translate(messages.openFindings),
      dataIndex: 'openFindings',
      minWidth: 90,
      flex: 90,
      render: (findings, vendor) => {
        return findings > 0 ? (
          <Link
            className="vendors-list__findings-link"
            to={getNavPathWithFilters(getPath(ROUTES.VENDOR_FINDINGS), {
              [eFilterKeys.vendorName]: {
                selected: [
                  { key: vendor.profileCorporateName, value: vendor.profileCorporateName },
                ],
              },
            })}
          >
            <FlagIcon hight={18} width={18} />
            <span className="vendors-list__findings-link-findings">{findings}</span>
          </Link>
        ) : (
          <div className="vendors-list__findings-empty-link">
            <FlagIcon hight={18} width={18} />
            <span className="vendors-list__findings-link-findings">0</span>
          </div>
        );
      },
      sorter: true,
      sortOrder: sorter.column === 'openFindings' ? sortOrder : null,
    },
    {
      title: translate(messages.inherentRisk),
      dataIndex: 'inherentRisk',
      minWidth: 90,
      flex: 90,
      render: (inherentRisk) => {
        const highestInherentRisk = inherentRisk
          .filter(({ progress }) => progress !== 0)
          .reduce((prev, current) => {
            return prev && prev.score > current.score ? prev : current;
          }, {});

        if (inherentRisk.length === 0 || isEmpty(highestInherentRisk)) {
          return (
            <div style={{ color: colors.gray400 }} className="vendors-list__empty-inherent-risk">
              {translate(messages.emptyStateValue)}
            </div>
          );
        }

        return (
          <InherentRiskPopover inherentRisk={inherentRisk}>
            <InherentRisk score={highestInherentRisk.score} />
          </InherentRiskPopover>
        );
      },
      sorter: true,
      sortOrder: sorter.column === 'inherentRisk' ? sortOrder : null,
    },
    {
      title: translate(messages.averageScore),
      dataIndex: 'assessmentScore',
      minWidth: 85,
      flex: 85,
      render: (assessmentScore, vendor) => {
        if (vendor.assessmentScores.length === 0 && vendor.appsScores.length === 0) {
          return <div style={{ color: colors.gray400 }}>{translate(messages.emptyStateValue)}</div>;
        }

        return (
          <AverageScorePopover vendor={vendor} score={assessmentScore.averageAssessmentScore}>
            {Math.round((assessmentScore.averageAssessmentScore + Number.EPSILON) * 100) / 100}%
          </AverageScorePopover>
        );
      },
      sorter: true,
      sortOrder: sorter.column === 'assessmentScore' ? sortOrder : null,
    },
    {
      title: translate(messages.vendorRisk),
      dataIndex: 'vendorRisk',
      minWidth: 100,
      flex: 100,
      render: (vendorRisk) => <VendorRisk value={vendorRisk} />,
      sorter: true,
      sortOrder: sorter.column === 'vendorRisk' ? sortOrder : null,
    },
    {
      title: translate(messages.status),
      minWidth: 110,
      maxWidth: 140,
      flex: 190,
      dataIndex: 'profileStatus', // trustIndicator
      render: (_, vendor) => <Status vendor={vendor} />,
      sorter: false,
    },
    {
      title: translate(messages.vendorStatus),
      dataIndex: 'vendorStatus',
      minWidth: 100,
      flex: 240,
      render: (vendorStatus, vendor) => {
        return (
          <VendorStatus
            status={vendorStatus}
            vendorId={vendor.id}
            disabled={isViewerRole || isAuditorRole}
          />
        );
      },
      sorter: true,
      sortOrder: sorter.column === 'vendorStatus' ? sortOrder : null,
    },
    {
      title: translate(messages.businessOwner),
      dataIndex: 'boAndAuditorUsers',
      minWidth: 105,
      flex: 105,
      render: (boAndAuditorUsers, vendor) => {
        return <BusinessOwner boUsers={boAndAuditorUsers} vendor={vendor} />;
      },
      sorter: false,
    },
    {
      dataIndex: 'actions',
      minWidth: 50,
      maxWidth: 75,
      flex: 50,
      render: (vendorRisk, vendor) => (
        <Actions
          isVendorsList
          source={MixpanelAssignAssessmentSource.Vendors}
          vendor={vendor}
          setVendorToRemove={setVendorToRemove}
        />
      ),
    },
  ];

  const onClickAddVendor = (event) => {
    dispatch(setFromRecent(false));
    return appNavigate(ROUTES.VENDOR_NEW_VENDOR);
  };

  const onRemove = async (event) => {
    event.stopPropagation();
    setLoading(true);
    await dispatch(removeVendor({ id: vendorToRemove.id })).unwrap();
    await dispatch(getProfileVendors()).unwrap();
    setVendorToRemove(null);
    setLoading(false);
  };

  const dataSource = (vendors || [])?.map((vendor) => ({
    ...vendor,
    key: vendor.id,
  }));

  return (
    <div className="vendors-list">
      <PageHeader className="vendors-list__header">{translate(messages.vendorHeader)}</PageHeader>
      <div className="vendors-list__wrapper">
        <div className="vendors-list__actions">
          <Search />
          <Filters
            filterableKey={FILTERABLE_KEYS.VENDORS_LIST_FILTERS}
            allowedFilters={VENDORS_LIST_ALLOWED_FILTERS}
          />
          <div className="vendors-list__actions-right">
            {isAuditorRole ? null : (
              <ArchiveToggle
                value={archive || 0}
                onChange={(value) => dispatch(setArchive(value))}
                fetchData={() => dispatch(getProfileVendors())}
              />
            )}
            <Button
              dataTest="vendors-list-button-add-vendor"
              className="vendors-list__button"
              disabled={isViewerRole || isAuditorRole}
              beforeIcon="plus"
              data-test="vendor-list-button"
              color="pink"
              size="sm"
              onClick={onClickAddVendor}
            >
              {translate(messages.vendor)}
            </Button>
          </div>
        </div>
        <Table
          className="vendors-list__table"
          data-test="vendor-list-table"
          columns={columns}
          tableLayout="fixed"
          dataSource={dataSource}
          loading={isVendorsFetching}
          pagination={pagination}
          onChange={onTableChange}
          emptyStateSource={TableSource.vendorsList}
          rowKey="vendor-table"
          scroll={{ x: 1160 }}
        />
      </div>
      <RemoveConfirmationModal
        confirmLoading={loading}
        onCancel={() => setVendorToRemove(null)}
        onOk={onRemove}
        text={`${translate(messages.removeVendorText, {
          vendor: vendorToRemove?.profileCorporateName,
        })}`}
        open={!!vendorToRemove}
        headerText={`${translate(messages.removeVendorHeader, {
          vendor: vendorToRemove?.profileCorporateName,
        })}`}
      />
    </div>
  );
};

export default VendorsList;
