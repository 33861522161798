import React from 'react';
import PropTypes from 'prop-types';

const FindingFilledIcon = ({
  color = 'currentColor',
  height = '24',
  width = '24',
  ...otherProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M4.81868 3C4.61977 3.00002 4.42902 3.07904 4.28837 3.21969C4.14772 3.36034 4.0687 3.55109 4.06868 3.75V21.25C4.06727 21.3494 4.08563 21.4481 4.12269 21.5403C4.15975 21.6325 4.21478 21.7164 4.28456 21.7872C4.35434 21.858 4.4375 21.9142 4.52919 21.9526C4.62088 21.9909 4.71928 22.0107 4.81868 22.0107C4.91807 22.0107 5.01647 21.9909 5.10817 21.9526C5.19986 21.9142 5.28302 21.858 5.3528 21.7872C5.42258 21.7164 5.4776 21.6325 5.51466 21.5403C5.55172 21.4481 5.57008 21.3494 5.56868 21.25V16.5H21.3187C21.458 16.4999 21.5945 16.4611 21.7129 16.3878C21.8313 16.3145 21.927 16.2097 21.9893 16.0851C22.0515 15.9606 22.0779 15.8211 22.0653 15.6824C22.0528 15.5437 22.0019 15.4112 21.9183 15.2998L17.7562 9.75L21.9183 4.2002C22.0019 4.08879 22.0528 3.95631 22.0653 3.8176C22.0779 3.6789 22.0515 3.53944 21.9893 3.41485C21.927 3.29026 21.8313 3.18546 21.7129 3.11219C21.5945 3.03892 21.458 3.00007 21.3187 3H4.81868Z"
      fill={color}
    />
  </svg>
);

FindingFilledIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default FindingFilledIcon;
