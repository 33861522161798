import React from 'react';

// COMPONENTS
import FileBrowser from 'components/FileBrowser';

// UTILS
import { messages } from './messages';
import { translate } from 'utils/index';

// REDUX
import { useSelector } from 'react-redux';
import { selectAssessment } from 'pages/Assessment/selectors';

// STYLES
import './index.scss';

function UploadedFilesPreview() {
  const { id, files } = useSelector(selectAssessment);

  return (
    <div className="uploaded-files-preview">
      <span>{translate(messages.titleUploadFilesPreview)}</span>
      {files?.length > 0 && (
        <div className="upload-files__items">
          <FileBrowser files={files} assessmentIdProp={id} browse disabled />
        </div>
      )}
    </div>
  );
}

export default UploadedFilesPreview;
