import { createSlice } from '@reduxjs/toolkit';
import { getAssignedShowcase } from 'api/showcase';

const initialState = {
  title: '',
  description: '',
  sendTo: '',
  sendFrom: '',
  assessments: [],
  activeAssessmentId: '',
};

const reducers = createSlice({
  name: 'showcasePreview',
  initialState,
  reducers: {
    setActiveAssessmentId: (state, { payload: id }) => {
      state.activeAssessmentId = id;
    },
    resetAssignedShowcasePreview: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAssignedShowcase.fulfilled, (state, { payload }) => {
      if (payload?.showcase) {
        const { title, description, sendTo, sendFrom, assignedAssessments } = payload.showcase;
        state.title = title || '';
        state.description = description || '';
        state.sendTo = sendTo || '';
        state.sendFrom = sendFrom || '';
        state.assessments = assignedAssessments || [];
        state.activeAssessmentId = Object.keys(assignedAssessments)[0] || '';
      }
    });
  },
});

export const { setActiveAssessmentId, resetAssignedShowcasePreview } = reducers.actions;

export default reducers;
