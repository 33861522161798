import { useSelectedPath } from 'hooks/navigation';
import ROUTES from 'constants/routes';
import { tabs } from './lib';

export function useTabsActiveKey() {
  const selectedPath = useSelectedPath();
  switch (selectedPath) {
    case ROUTES.VENDOR_ASSESSMENT_QUESTIONNAIRE:
    case ROUTES.CUSTOMER_ASSESSMENT_QUESTIONNAIRE:
      return tabs.questionnaire;
    case ROUTES.VENDOR_ASSESSMENT_FINDINGS:
    case ROUTES.CUSTOMER_ASSESSMENT_FINDINGS:
      return tabs.findings;
    case ROUTES.VENDOR_ASSESSMENT_SECURED_EVIDENCE:
    case ROUTES.CUSTOMER_ASSESSMENT_SECURED_EVIDENCE:
      return tabs.securedEvidence;
    default:
      return tabs.overview;
  }
}
