import React from 'react';
import { generatePath, useLocation } from 'react-router';

import ROUTES from 'constants/routes';
import { DEFAULT_ORGANIZATION_INDEX, getOrganizationsById } from 'utils/organizations';
import Loader from 'ui/Loader';

const MANAGE_VENDORS_URL = 'manage_vendors1';
const MANAGE_CUSTOMERS_URL = 'manage_customers1';

const pathMapping = {
  [MANAGE_VENDORS_URL]: {
    1: (organization) =>
      generatePath(ROUTES.VENDOR_VENDORS, {
        organization: organization?.index || DEFAULT_ORGANIZATION_INDEX,
      }),
    2: (vendorId, organization) =>
      generatePath(ROUTES.VENDOR_EDIT, {
        organization: organization?.index || DEFAULT_ORGANIZATION_INDEX,
        vendorId,
      }),
  },
  [MANAGE_CUSTOMERS_URL]: {
    1: (organization) =>
      generatePath(ROUTES.CUSTOMER_CUSTOMERS, {
        organization: organization?.index || DEFAULT_ORGANIZATION_INDEX,
      }),
    2: (customerId, organization) =>
      generatePath(ROUTES.CUSTOMER_EDIT_CUSTOMER, {
        organization: organization?.index || DEFAULT_ORGANIZATION_INDEX,
        customerId,
      }),
  },
};

const OldRoutesPreloader = () => {
  const { hash } = useLocation();
  const [type, ...params] = hash.replace('#', '').split('/');

  let redirectPath = '';
  const organization = getOrganizationsById(params[0]);

  if (type === MANAGE_VENDORS_URL || type === MANAGE_CUSTOMERS_URL) {
    redirectPath = pathMapping[type]?.[params.length]?.(...params);
  } else {
    redirectPath = generatePath(ROUTES.CUSTOMER_ASSESSMENT, {
      organization: organization?.index || DEFAULT_ORGANIZATION_INDEX,
      assessmentId: type,
    });
  }
  window.location.replace(redirectPath || '');

  return <Loader />;
};

export default OldRoutesPreloader;
