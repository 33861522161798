import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigationType } from 'react-router';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import moment from 'moment';

import PageSubheader from 'components/PageSubheader';
import ScoreBenchmark from 'components/AssessmentScoreBenchmark';
import Status from 'pages/Assessments/columns/assessmentStatusColumn';
import Actions from 'pages/Assessments/columns/actions';
import { getRecentAssessments } from 'api/recent';
import { useAppNavigate, useGetPath, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useTheme } from 'hooks/theme';
import { createSorterByString } from 'utils/strings';
import { translate } from 'utils/index';
import { downloadReportDisableState } from 'utils/assessment';
import { archive, exportToCsv, fetchZipReports } from 'api/assessments';
import { selectIsViewAggregated } from 'pages/Organization/selectors';

import Table from 'ui/Table';
import Icon from 'ui/Icon';
import UiButton from 'ui/Button';

import { DATE_FORMAT } from 'constants/date';
import ROUTES from 'constants/routes';

import { setAssessmentsSorter } from './reducers';
import { TableSource } from 'ui/Table/lib';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import { ExportPdfFileIcon, ExportCsvFileIcon, ExternalLinkIcon } from 'components/Icons';
import { shouldAppNavigateToContinuousMonitoring } from 'utils/appsUtils';
import './assessments.scss';

const Assessments = () => {
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { isViewerRole, isRestrictedBoSoViewerAuditorRole } = useRole();
  const isViewAggregated = useSelector(selectIsViewAggregated);
  const { colorTheme } = useTheme();
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();
  const { assessments, assessmentsLoading, assessmentsSorter } = useSelector(
    (state) => state.recent,
  );
  const getPath = useGetPath();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType !== 'POP') {
      dispatch(setAssessmentsSorter({}));
    }
    dispatch(getRecentAssessments());
  }, []);

  const onGenerateZipReport = async (assessmentId) => {
    if (selectedRowKeys.length === 0 && !assessmentId) return;

    const assessmentIds = assessmentId ? [assessmentId] : selectedRowKeys;

    setIsLoading(true);
    await fetchZipReports(assessmentIds);
    setIsLoading(false);
  };

  const onExport = async (assessmentId) => {
    if (selectedRowKeys.length === 0 && !assessmentId) return;

    setIsLoading(true);

    const assessmentIds = assessmentId ? [assessmentId] : selectedRowKeys;

    await Promise.all(
      assessmentIds.map(async (selectedItem) => {
        await dispatch(exportToCsv(selectedItem)).unwrap();
      }),
    );

    setIsLoading(false);
  };

  const onArchive = async (assessmentId) => {
    if (selectedRowKeys.length === 0 && !assessmentId) return;

    const assessmentIds = assessmentId ? [assessmentId] : selectedRowKeys;

    setIsLoading(true);
    await dispatch(archive(assessmentIds)).unwrap();
    await dispatch(getRecentAssessments()).unwrap();
    setSelectedRowKeys([]);
    setIsLoading(false);
  };

  const sortOrder = assessmentsSorter.descending ? 'descend' : 'ascend';

  const columns = [
    {
      title: translate(messages.date),
      dataIndex: 'timestampEdited',
      width: 120,
      render: (timestampEdited) => moment(timestampEdited).format(DATE_FORMAT),
      sorter: (a, b) => new Date(a.timestampEdited) - new Date(b.timestampEdited),
      sortOrder: assessmentsSorter.column === 'timestampEdited' ? sortOrder : null,
    },
    {
      title: isMyVendorsTabSelected ? translate(messages.vendor) : translate(messages.customer),
      dataIndex: isMyVendorsTabSelected ? 'organizationName' : 'parentOrganizationCorporateName',
      render: (name, assessment) => {
        if (isViewerRole || !isMyVendorsTabSelected) {
          return name;
        }

        return (
          <Link
            className="recent-page-assessments__table-link"
            to={getPath(ROUTES.VENDOR_EDIT, {
              vendorId: assessment?.organizationCustomer_id || '',
            })}
          >
            {name}
          </Link>
        );
      },
      sorter: createSorterByString('organizationName'),
      sortOrder: assessmentsSorter.column === 'organizationName' ? sortOrder : null,
    },
    {
      title: translate(messages.type),
      dataIndex: 'assessmentName',
      render: (assessmentName, assessment) => {
        const { showcaseName, app } = assessment;
        return (
          <>
            {showcaseName ? (
              <p className="showcase-name-indicator">{translate(messages.showcaseIndicator)}</p>
            ) : null}
            <Link
              className="recent-page-assessments__table-link"
              to={
                app && shouldAppNavigateToContinuousMonitoring(app)
                  ? getPath(
                      isMyVendorsTabSelected
                        ? ROUTES.VENDOR_CONTINUOUS_MONITORING
                        : ROUTES.CUSTOMER_CONTINUOUS_MONITORING,
                      { assessmentId: assessment.id, appId: assessment.app },
                    )
                  : getPath(
                      isMyVendorsTabSelected
                        ? ROUTES.VENDOR_ASSESSMENT
                        : ROUTES.CUSTOMER_ASSESSMENT,
                      { assessmentId: assessment.id },
                    )
              }
              target="_blank"
            >
              <ExternalLinkIcon height={'20'} width={'20'} />
              {showcaseName || assessmentName}
            </Link>
          </>
        );
      },
      sorter: createSorterByString('assessmentName'),
      sortOrder: assessmentsSorter.column === 'assessmentName' ? sortOrder : null,
    },
    {
      title: translate(messages.due),
      dataIndex: 'assessmentDueDate',
      width: 120,
      render: (due, { assessmentStatus, showcaseName }) => {
        const isExpired = moment().isAfter(moment(due)) && assessmentStatus < 4;
        const className = classNames('recent-page-assessments__due-date', {
          'recent-page-assessments__due-date--expired': isExpired,
        });
        return showcaseName ? (
          <span className="empty-score">{translate(messages.emptyStateValue)}</span>
        ) : (
          <div className={className}>
            <Icon icon="date" className="recent-page-assessments__due-date-ico" />
            {due ? moment(due).format(DATE_FORMAT) : 'N/A'}
          </div>
        );
      },
      sorter: (a, b) => new Date(a.assessmentDueDate) - new Date(b.assessmentDueDate),
      sortOrder: assessmentsSorter.column === 'assessmentDueDate' ? sortOrder : null,
    },
    {
      title: translate(messages.score),
      dataIndex: 'assessmentScore',
      align: 'center',
      width: 190,
      render: (score, assessment) => {
        return assessment.showcaseName ? (
          <span className="empty-score">{translate(messages.emptyStateValue)}</span>
        ) : (
          <ScoreBenchmark score={score} benchmark={assessment.assessmentBenchmark} />
        );
      },
      sorter: (a, b) => a.assessmentScore - b.assessmentScore,
      sortOrder: assessmentsSorter.column === 'assessmentScore' ? sortOrder : null,
    },
    {
      title: translate(messages.status),
      dataIndex: 'assessmentStatus',
      render: (status, assessment) =>
        assessment.app ? (
          'N/A'
        ) : (
          <Status assessment={assessment} scoreBenchmarkStatus={assessment.scoreBenchmarkStatus} />
        ),
      sorter: (a, b) => a.assessmentStatus - b.assessmentStatus,
      sortOrder: assessmentsSorter.column === 'assessmentStatus' ? sortOrder : null,
    },
    {
      title: translate(messages.openFindings),
      dataIndex: 'findingsCount',
      sorter: (a, b) => a.findingsCount - b.findingsCount,
      sortOrder: assessmentsSorter.column === 'findingsCount' ? sortOrder : null,
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_, assessment) => (
        <Actions
          assessmentId={assessment.id}
          assessmentStatus={assessment.assessmentStatus}
          className="recent-page-assessments__actions"
          onArchive={onArchive}
          onExport={onExport}
          onGenerateZipReport={onGenerateZipReport}
        />
      ),
      width: 130,
    },
  ];

  if (isViewAggregated) {
    const column = {
      title: translate(messages.organization),
      dataIndex: 'parentOrganizationCorporateName',
      sorter: createSorterByString('parentOrganizationCorporateName'),
      sortOrder: assessmentsSorter.column === 'parentOrganizationCorporateName' ? sortOrder : null,
    };
    columns.splice(1, 0, column);
  }

  const onRow = (assessment) => ({
    onClick: (event) => {
      if (event.target.classList.contains('recent-page-assessments__table-link')) {
        return;
      }
      if (assessment.showcaseName) {
        const url = isMyVendorsTabSelected
          ? ROUTES.VENDOR_SHOWCASE_PREVIEW
          : ROUTES.CUSTOMER_SHOWCASE_PREVIEW;
        return appNavigate(url, { showcaseId: assessment.customerAssessmentGroup });
      }
      if (assessment.app && shouldAppNavigateToContinuousMonitoring(assessment.app)) {
        return appNavigate(
          isMyVendorsTabSelected
            ? ROUTES.VENDOR_CONTINUOUS_MONITORING
            : ROUTES.CUSTOMER_CONTINUOUS_MONITORING,
          { assessmentId: assessment.id, appId: assessment.app },
        );
      }
      const url = isMyVendorsTabSelected ? ROUTES.VENDOR_ASSESSMENT : ROUTES.CUSTOMER_ASSESSMENT;
      return appNavigate(url, { assessmentId: assessment.id });
    },
  });

  const dataSource = (assessments || []).map((assessment) => ({
    ...assessment,
    key: assessment.id,
  }));

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const disableOfReportDownload = useMemo(() => {
    return downloadReportDisableState(assessments, selectedRowKeys);
  }, [selectedRowKeys[selectedRowKeys.length - 1]]);

  const onTableChange = (newPagination, filters, sorter) => {
    const sorterParams = {
      column: sorter.field,
      descending: sorter.order !== 'ascend',
    };
    const params = sorter.column ? sorterParams : {};
    dispatch(setAssessmentsSorter(params));
  };

  return (
    <div className="recent-page-assessments">
      <div className="recent-page-assessments__top">
        <PageSubheader className="recent-page-assessments__page-subheader">
          {translate(messages.recentAssessments)}
        </PageSubheader>
        <div className="recent-page-assessments__menu">
          <Tooltip title={translate(messages.downloadReport)}>
            <Button
              className="recent-page-assessments__action"
              type="link"
              onClick={() => onGenerateZipReport()}
              disabled={disableOfReportDownload}
            >
              <ExportPdfFileIcon />
            </Button>
          </Tooltip>
          <Tooltip title={translate(messages.exportCsv)}>
            <Button
              className="recent-page-assessments__action"
              type="link"
              onClick={() => onExport()}
              disabled={selectedRowKeys.length === 0}
            >
              <ExportCsvFileIcon />
            </Button>
          </Tooltip>
          <Tooltip title={translate(messages.sendToArchive)}>
            <Button
              className="recent-page-assessments__action"
              type="link"
              onClick={() => onArchive()}
              disabled={selectedRowKeys.length === 0 || isRestrictedBoSoViewerAuditorRole}
            >
              <Icon icon="archive" />
            </Button>
          </Tooltip>
          <UiButton
            data-test="recent-page-assessments-button-view-all"
            className="recent-page-assessments__view-all-button"
            link
            color="black"
            size="xs"
            onClick={() =>
              appNavigate(
                isMyVendorsTabSelected ? ROUTES.VENDOR_ASSESSMENTS : ROUTES.CUSTOMER_ASSESSMENTS,
              )
            }
          >
            {translate(messages.viewAll)}
          </UiButton>
          <UiButton
            data-test="recent-page-assessments-button-assign"
            className="recent-page-assessments__assign-button"
            disabled={isRestrictedBoSoViewerAuditorRole}
            beforeIcon="plus"
            color={colorTheme}
            size="sm"
            onClick={() =>
              appNavigate(
                isMyVendorsTabSelected
                  ? ROUTES.VENDOR_ASSIGN_ASSESSMENT
                  : ROUTES.CUSTOMER_ASSIGN_ASSESSMENT,
              )
            }
          >
            {translate(messages.assessment)}
          </UiButton>
        </div>
      </div>
      <Table
        className="recent-page-assessments__table"
        columns={columns}
        dataSource={dataSource}
        loading={assessmentsLoading || isLoading}
        pagination={false}
        rowSelection={rowSelection}
        onChange={onTableChange}
        onRow={onRow}
        scroll={{
          y: 300,
          x: 1160,
        }}
        emptyStateSource={TableSource.assessmentsRecent}
      />
    </div>
  );
};

export default Assessments;
