export const messages = {
  search: {
    key: 'Input.search',
    defaultMessage: 'Search',
  },
  password: {
    key: 'Input.password',
    defaultMessage: 'Password',
  },
  emailLabel: {
    key: 'Input.emailLabel',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    key: 'Input.emailPlaceholder',
    defaultMessage: 'Enter Email',
  },
  emailErrorRequired: {
    key: 'Input.emailErrorRequired',
    defaultMessage: 'Email is required',
  },
  searchPlaceholder: {
    key: 'Input.searchPlaceholder',
    defaultMessage: 'Search',
  },
};

export default {
  prefix: 'Input',
  messages,
};
