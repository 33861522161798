import React, { useEffect, useState } from 'react';
import { Collapse as CollapseAnt } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';

const { Panel } = CollapseAnt;

const Collapse = ({
  accordion,
  panels,
  className,
  separatedPanels,
  rounded,
  ...additionalProps
}) => {
  const collapseClassNames = classNames('ui-collapse', className, {
    'ui-collapse--separated-panels': separatedPanels,
    'ui-collapse--rounded': rounded,
  });

  const [panelsComponents, setPanelsComponent] = useState([]);

  useEffect(() => {
    setPanelsComponent(() =>
      panels.map((panel) => ({
        id: panel.id,
        header: panel.header,
        additionalProps: panel.additionalProps,
        children: panel.children,
      })),
    );
  }, [panels]);

  return (
    <CollapseAnt className={collapseClassNames} accordion={accordion} {...additionalProps}>
      {panelsComponents.map((panel) => (
        <Panel key={panel.id} header={panel.header} {...panel.additionalProps}>
          {panel.children}
        </Panel>
      ))}
    </CollapseAnt>
  );
};

Collapse.propTypes = {
  accordion: PropTypes.bool,
  panels: PropTypes.array,
  additionalProps: PropTypes.any,
  className: PropTypes.string,
  separatedPanels: PropTypes.bool,
  rounded: PropTypes.bool,
};

Collapse.defaultProps = {
  additionalProps: {},
};

export default Collapse;
