import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'ui/Modal';
import { translate } from 'utils/index';
import Icon from 'ui/Icon';
import { messages } from './messages';
import './index.scss';

const UpdateAnswerConfirmationModal = ({ open, onCancel, onOk }) => {
  const updateAnswerTitle = (
    <span>
      <Icon icon="info" /> {translate(messages.updateAnswer)}
    </span>
  );

  return (
    <Modal
      className="components-update-answer-confirmation-modal"
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      okText={translate(messages.updateAnswerButton)}
      cancelText={translate(messages.noThanks)}
      title={updateAnswerTitle}
      width={685}
    >
      {translate(messages.wouldYouLikeToUpdateAnswer)}
    </Modal>
  );
};
UpdateAnswerConfirmationModal.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  open: PropTypes.bool,
};
export default UpdateAnswerConfirmationModal;
