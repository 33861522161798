export const messages = {
  assessmentsRecent: {
    key: 'Table EmptyStates.assessmentsRecent',
    defaultMessage: 'No Assessments',
  },
  assessmentsAssigned: {
    key: 'Table EmptyStates.assessmentsAssigned',
    defaultMessage: `You don't have any assigned assessments.`,
  },
  assessmentsTemplates: {
    key: 'Table EmptyStates.assessmentsTemplates',
    defaultMessage: `You don't have any frameworks.`,
  },
  assessmentTemplateCreate: {
    key: 'Table EmptyStates.assessmentTemplateCreate',
    defaultMessage: '+create new framework',
  },
  assessmentAssignNew: {
    key: 'Table EmptyStates.assessmentAssignNew',
    defaultMessage: '+assign new assessment',
  },
  vendorsRecent: {
    key: 'Table EmptyStates.vendorsRecent',
    defaultMessage: 'No Vendors',
  },
  vendorListCreate: {
    key: 'Table EmptyStates.vendorListCreate',
    defaultMessage: '+create new vendor',
  },
  vendorsList: {
    key: 'Table EmptyStates.vendorsList',
    defaultMessage: "You don't have any vendors.",
  },
  customersRecent: {
    key: 'Table EmptyStates.customersRecent',
    defaultMessage: 'No customers',
  },
  customerListCreate: {
    key: 'Table EmptyStates.customerListCreate',
    defaultMessage: '+create new customer',
  },
  findingsRecent: {
    key: 'Table EmptyStates.findingsRecent',
    defaultMessage: 'No Findings',
  },
  evidenceRecent: {
    key: 'Table EmptyStates.evidenceRecent',
    defaultMessage: 'No Evidence',
  },
  customersList: {
    key: 'Table EmptyStates.customersList',
    defaultMessage: "You don't have any customers.",
  },
  findingsList: {
    key: 'Table EmptyStates.findingsList',
    defaultMessage: `This where you can see all your findings and their status in one place.\n You don't have any findings at the moment.`,
  },
};

export default {
  prefix: 'Table EmptyStates',
  messages,
};
