import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import PropTypes from 'prop-types';

// COMPONENTS
import Filter from 'ui/Filter';

// UTILS
import { getSummaryCustomers } from 'api/summary';
import { createSorterByString } from 'utils/strings';
import { translate } from 'utils/index';
import { messages } from 'components/AddFilterButton/messages';
import { selectCustomers, selectCustomersLoading } from './selectors';

const { Option } = Select;

const FilterCustomer = ({ fetchData, filter, ...otherProps }) => {
  const loading = useSelector(selectCustomersLoading);
  const customers = useSelector(selectCustomers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (customers.length === 0) {
      dispatch(getSummaryCustomers());
    }
  }, []);

  const sortedCustomers = useMemo(() =>
    customers.slice().sort(createSorterByString('profileCorporateName'), [customers]),
  );

  const innerLabel = filter.type === 'vendor' ? messages.vendorFilter : messages.customerFilter;

  return (
    <Filter
      filter={filter}
      fetchData={fetchData}
      loading={loading}
      {...otherProps}
      innerLabel={translate(innerLabel)}
    >
      {sortedCustomers.map(({ id, profileCorporateName }) => (
        <Option key={id} id={id} value={profileCorporateName}>
          {profileCorporateName}
        </Option>
      ))}
    </Filter>
  );
};

FilterCustomer.propTypes = {
  fetchData: PropTypes.func,
  filter: PropTypes.object,
};

export default FilterCustomer;
