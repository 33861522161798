export const messages = {
  deselect: {
    key: 'UiApproveAnswer.deselect',
    defaultMessage: 'Deselect',
  },
  approve: {
    key: 'UiApproveAnswer.approve',
    defaultMessage: 'Approve',
  },
  disapprove: {
    key: 'UiApproveAnswer.disapprove',
    defaultMessage: 'rejected',
  },
};

export default {
  prefix: 'UiApproveAnswer',
  messages,
};
