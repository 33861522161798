import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ReactComponent as ArrowIconRight } from 'assets/svgs/arrowRight.svg';

import ControlComponent from 'components/ControlComponent';
import NewVendorFooter from 'components/NewVendorFooter';
import { selectGroups } from 'components/AssessmentsFilters/selectors';

import { ControlType } from 'constants/controlType';
import { VendorStatus } from 'constants/vendorStatus';
import { generalMessages } from 'constants/messages';

import { useVendorManageContext } from 'hooks/useVendorManageContext';

import { translate } from 'utils/index';
import { validPayload } from 'pages/Vendors/VendorManage/lib';
import { getCustomersGroups } from 'api/customersGroup';

import Form from 'ui/Form';

import { messages } from './messages';

import './index.scss';

const ContactInformationTab = ({ disabled, handleTabChange, prevAction }) => {
  const { vendorId } = useParams();
  const groups = useSelector(selectGroups);
  const { isLoading } = useSelector((state) => state.vendors);
  const dispatch = useDispatch();
  const {
    currentVendor,
    vendorContactData,
    isContactFormDirty,
    contactFormErrors,
    onChangeContactFrom,
    updateVendor,
  } = useVendorManageContext();
  const [currentErrors, setCurrentErrors] = useState(contactFormErrors);
  const [touched, setTouched] = useState({});

  useEffect(() => {
    if (groups.length === 0) {
      dispatch(getCustomersGroups());
    }
  }, []);

  useEffect(() => {
    setCurrentErrors(contactFormErrors);
  }, [contactFormErrors]);

  const onSubmit = (event) => {
    event.preventDefault();
    validAsync(vendorContactData).then();
    setTouched((state) => ({ ...state, corporateName: true, companyContact: true, email: true }));

    currentVendor ? updateVendor() : handleTabChange();
  };

  const validAsync = useCallback(async (payload) => {
    const currentError = await validPayload(payload);
    setCurrentErrors(currentError);
  }, []);

  useEffect(() => {
    if (isContactFormDirty.current) {
      validAsync(vendorContactData).then();
    }
  }, [vendorContactData]);

  const vendorStatuses = [
    { label: translate(messages.pendingRequest), value: VendorStatus.PendingRequest },
    { label: translate(messages.assessmentInProgress), value: VendorStatus.AssessmentInProgress },
    { label: translate(messages.BORequestNotApproved), value: VendorStatus.BORequestNotApproved },
    { label: translate(messages.securityTeamApproved), value: VendorStatus.SecurityTeamApproved },
    { label: translate(messages.securityTeamDeclined), value: VendorStatus.SecurityTeamDeclined },
  ];

  return (
    <>
      <Form onSubmit={onSubmit}>
        <ControlComponent
          type={ControlType.Input}
          debounceTime={250}
          data={{
            label: `${translate(messages.companyName)}*`,
            payload: {
              disabled: disabled,
              'data-test': 'vendor-corporate-name-input',
              value: vendorContactData.corporateName,
              name: 'corporateName',
              error: touched.corporateName && currentErrors?.corporateName,
              onChange: (value) => {
                onChangeContactFrom(value, 'corporateName');
              },
              onBlur: () => {
                setTouched((state) => ({ ...state, corporateName: true }));
              },
              placeholder: translate(messages.enterCompanyName),
            },
          }}
        />
        <ControlComponent
          type={ControlType.Input}
          debounceTime={250}
          data={{
            label: `${translate(messages.companyDomain)}`,
            payload: {
              disabled: disabled,
              'data-test': 'vendor-company-domain-input',
              value: vendorContactData.companyDomain,
              name: 'companyDomain',
              error: touched?.companyDomain && currentErrors?.companyDomain,
              onChange: (value) => {
                onChangeContactFrom(value, 'companyDomain');
              },
              onBlur: () => {
                setTouched((state) => ({ ...state, companyDomain: true }));
              },
              placeholder: translate(messages.enterDomain),
            },
          }}
        />
        <ControlComponent
          type={ControlType.Input}
          debounceTime={250}
          data={{
            label: `${translate(messages.contactName)}*`,
            payload: {
              disabled: disabled,
              'data-test': 'vendor-company-contact-input',
              value: vendorContactData.companyContact,
              name: 'companyContact',
              error: touched.companyContact && currentErrors?.companyContact,
              onChange: (value) => {
                onChangeContactFrom(value, 'companyContact');
              },
              onBlur: () => {
                setTouched((state) => ({ ...state, companyContact: true }));
              },
              placeholder: translate(messages.enterContactName),
            },
          }}
        />
        <ControlComponent
          type={ControlType.Input}
          debounceTime={250}
          data={{
            label: `${translate(messages.contactEmail)}*`,
            payload: {
              'data-test': 'vendor-email-input',
              value: vendorContactData.email,
              name: 'email',
              disabled: (vendorId && currentVendor?.activated) || disabled,
              error: touched.email && currentErrors?.email,
              onChange: (value) => {
                onChangeContactFrom(value, 'email');
              },
              onBlur: () => {
                setTouched((state) => ({ ...state, email: true }));
              },
              type: 'email',
              placeholder: translate(messages.enterContactEmail),
            },
          }}
        />
        <ControlComponent
          type={ControlType.Input}
          debounceTime={250}
          data={{
            label: translate(messages.contactPhone),
            payload: {
              disabled: disabled,
              'data-test': 'vendor-phone-input',
              value: vendorContactData.phone,
              name: 'phone',
              onChange: (value) => {
                onChangeContactFrom(value, 'phone');
              },
              placeholder: translate(messages.enterContactPhoneNumber),
            },
          }}
        />
        <ControlComponent
          type={ControlType.DropDown}
          isSimple
          data={{
            label: translate(messages.vendorStatus),
            payload: {
              disabled: disabled,
              'data-test': 'vendor-status-input',
              value: vendorContactData.vendorStatus,
              name: 'vendorStatus',
              options: vendorStatuses,
              onChange: (value) => {
                onChangeContactFrom(value, 'vendorStatus');
              },
              placeholder: translate(generalMessages.notSpecified),
            },
          }}
        />
        <ControlComponent
          type={ControlType.MultiSelect}
          isSimple
          data={{
            label: translate(messages.groups),
            payload: {
              disabled: disabled,
              'data-test': 'group-input',
              value: vendorContactData['setVendorGroups'] || undefined,
              name: 'setVendorGroups',
              options: groups.map(({ id, label }) => ({ label, value: id })),
              onChange: (value) => {
                onChangeContactFrom(value, 'setVendorGroups');
              },
              placeholder: translate(generalMessages.notSpecified),
            },
          }}
        />
        <NewVendorFooter
          nextAfterComponent={!currentVendor && <ArrowIconRight width={13} height={14} />}
          nextAction={onSubmit}
          prevAction={prevAction}
          nextLabel={
            currentVendor ? translate(messages.saveAndClose) : translate(generalMessages.next)
          }
          prevLabel={translate(generalMessages.cancel)}
          isNextLoading={isLoading}
          isNextDisabled={disabled}
          dataTestNext="contact-information-tab-button-footer-next"
          dataTestPrev="contact-information-tab-button-footer-back"
        />
      </Form>
    </>
  );
};

ContactInformationTab.propTypes = {
  disabled: PropTypes.bool,
  handleTabChange: PropTypes.func,
  prevAction: PropTypes.func,
};

export default ContactInformationTab;
