import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import fileDownload from 'js-file-download';
import { notification } from 'antd';

import { translate } from 'utils/index';
import { API_STATUS } from 'constants/api';
import { truncateFilename } from 'pages/MyFiles/lib';
import MyFilesUploadItemDropdown from 'pages/MyFiles/myFilesUploadItemDropdown';
import { downloadFile } from 'api/myFiles';
import { messages } from './messages';
import FileIcon from 'ui/FileIcon';
import { getFileExtension } from 'utils/files';
import MyFilesExpiration from './myFilesExpiration';

import './myFilesUploadedItem.scss';

const MyFilesUploadedItem = ({
  file,
  isSelected,
  onSelect,
  icon,
  onEditClick,
  handlePreview,
  itemIndex,
  handleRename,
  showImagePreviewIcon,
  handleDelete,
}) => {
  const isImageFile = file && file.type && file.type.startsWith('image');
  const composedClassNames = classNames('my-files-upload-item', {
    'my-files-upload-item--selected': isSelected,
  });
  const type = getFileExtension(file.path);

  const renderIcon = () => {
    if (icon) {
      return icon;
    }

    if (isImageFile && showImagePreviewIcon) {
      return <img src={URL.createObjectURL(file)} alt="file-preview" />;
    }
    return (
      <div className="my-files-popup__file-image">
        <FileIcon type={type} />
      </div>
    );
  };

  const handleDownload = async () => {
    const { path, status } = await downloadFile({
      fileId: file.id,
    });

    if (path && status === API_STATUS.SUCCESS) {
      fetch(path, { credentials: 'same-origin' })
        .then((res) => res.blob())
        .then((data) => {
          const dataFile = new File([data], 'file', { type: data.type });
          fileDownload(dataFile, file.title);
        });
    } else {
      notification.error({ message: translate(messages.downloadError) });
    }
  };

  return (
    <>
      <div
        className={composedClassNames}
        onClick={(event) => {
          onSelect(event, file, itemIndex);
        }}
      >
        <div className="my-files-upload-item__file-preview">
          <MyFilesUploadItemDropdown
            fileId={file.id}
            handleMetadata={() => {
              onEditClick(file);
            }}
            handlePreview={() => handlePreview(itemIndex)}
            handleDownload={handleDownload}
            handleRename={() => handleRename(itemIndex)}
            handleDelete={() => handleDelete(itemIndex)}
          />
          {renderIcon()}
        </div>
        <MyFilesExpiration expirationDate={file.metadata.expiresOn} />
        <div className="my-files-upload-item__file-info">{truncateFilename(file)}</div>
      </div>
    </>
  );
};

MyFilesUploadedItem.propTypes = {
  file: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  icon: PropTypes.node,
  onEditClick: PropTypes.func,
  handlePreview: PropTypes.func,
  itemIndex: PropTypes.number,
  handleRename: PropTypes.func,
  showImagePreviewIcon: PropTypes.bool,
  handleDelete: PropTypes.func,
};

export default MyFilesUploadedItem;
