import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spin from 'ui/Spin';
import Icon from 'ui/Icon';
import './input.scss';

const Input = ({
  autoComplete,
  afterIcon,
  block,
  className,
  error,
  inline,
  label,
  loading,
  name,
  onChange,
  onIconClick,
  placeholder,
  rounded,
  type,
  value,
  inputClassName,
  afterIconClassName,
  helperComponentClassName,
  textHelperComponent,
  size,
  inputRef,
  ...inputProps
}) => {
  const inputClassNames = classNames('ui-input', className, {
    'ui-input--block': block,
    'ui-input--inline': inline,
    'ui-input--filled': value,
  });

  const inputControlClassNames = classNames('ui-input-control', inputClassName, {
    'ui-input-control--error': error,
    'ui-input-control--rounded': rounded,
    'ui-input-control--icon': !!afterIcon,
    'ui-input-control--block': block,
    'ui-input-control--size-medium': size === 'medium',
    'ui-input-control--size-small': size === 'small',
    'ui-input-control--inline': inline,
  });

  const afterIconClassNames = classNames('ui-input__after-icon', afterIconClassName, {
    'ui-input__after-icon--search': type === 'search',
    'ui-input__after-icon--size-medium': size === 'medium',
    'ui-input__after-icon--size-small': size === 'small',
  });

  const handleOnChange = (event) => {
    onChange(event);
  };

  return (
    <div className={inputClassNames}>
      {label && <div className="ui-input__label">{label}</div>}
      <div className="ui-input__wrapper">
        <input
          autoComplete={autoComplete}
          {...inputProps}
          name={name}
          value={value}
          onChange={handleOnChange}
          className={inputControlClassNames}
          placeholder={placeholder}
          type={type}
          ref={inputRef}
        />
        {afterIcon && (
          <div className={afterIconClassNames} onClick={onIconClick}>
            <Icon icon={afterIcon} />
          </div>
        )}
        {loading && <Spin className="ui-input__loader" />}
      </div>
      <div
        className={`ui-input__error ${type === 'checkbox' && 'ui-input__error-checkbox'}`}
        data-test={inputProps['data-test-error']}
      >
        {error}
      </div>
      {textHelperComponent}
    </div>
  );
};

Input.propTypes = {
  autoComplete: PropTypes.string,
  afterIcon: PropTypes.string,
  afterIconClassName: PropTypes.string,
  block: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  textHelperComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  helperComponentClassName: PropTypes.string,
  inline: PropTypes.bool,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onIconClick: PropTypes.func,
  placeholder: PropTypes.string,
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf([
    'email',
    'file',
    'hidden',
    'number',
    'password',
    'search',
    'text',
    'checkbox',
  ]),
  value: PropTypes.string,
  inputRef: PropTypes.object,
  'data-test': PropTypes.string.isRequired,
  'data-test-error': PropTypes.string,
};

Input.defaultProps = {
  autoComplete: 'on',
  onChange: () => {},
  onIconClick: () => {},
};

export default Input;
