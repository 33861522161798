import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import FileUpload from 'ui/FileUpload';
import FileList from 'ui/FileList';
import { ALLOW_FILES } from 'constants/general';
import { translate } from 'utils/index';
import { removeFile, uploadFiles } from 'api/assessment';
import { AssessmentType } from 'constants/assessmentType';
import { calculateControlKey } from 'pages/Assessment/lib';
import { sanitizer } from 'utils/sanitizer';
import EvidenceMetadataEditor from 'components/EvidenceMetadataEditor';
import { useTheme } from 'hooks/theme';
import { messages } from 'pages/Assessment/messages';
import { selectAnswerTypes, selectAssessment, selectFileLoading } from 'pages/Assessment/selectors';
import { hasControlFiles } from './lib';
import { useRole } from 'hooks/useRole';
import { useFileUploadProcess } from 'components/FileUploadIndicator/useFileUploadProcess';

import './securedEvidence.scss';

const SecuredEvidence = ({ relation }) => {
  const dispatch = useDispatch();
  const { files, categories } = useSelector(selectAssessment);
  const loading = useSelector(selectFileLoading);
  const answerTypes = useSelector(selectAnswerTypes);
  const { isRestrictedBoSoViewerRole } = useRole();
  const [selectedControlFile, selectControlFile] = useState();
  const { colorTheme } = useTheme();
  const { abortController, startUploading, finishUploding, renderConfirmationModal } =
    useFileUploadProcess();

  const handleUpload = async (files) => {
    startUploading();
    await dispatch(
      uploadFiles({
        files: files?.length ? files : [files],
        assessmentType: AssessmentType.assessment,
        abortController,
      }),
    ).unwrap();
    finishUploding();
    selectControlFile(files?.length ? files[0] : files);
  };

  const handleRemove = async (fileId) => {
    await dispatch(removeFile({ fileId, assessmentType: AssessmentType.assessment })).unwrap();
  };

  const renderCategory = (controls, title, index) => {
    return (
      <div className="assessment-finalized-secured-evidence__category">
        <div className="assessment-finalized-secured-evidence__category-title">
          {index + 1}. {title}
        </div>
        <div className="assessment-finalized-secured-evidence__category-controls">
          {controls.map(
            (control, controlIndex) =>
              hasControlFiles(control, answerTypes) && (
                <div
                  key={`assessment-finalized-secured-evidence__control-wrapper-${controlIndex}`}
                  className="assessment-finalized-secured-evidence__control-wrapper"
                >
                  <div className="assessment-finalized-secured-evidence__control-key">
                    {calculateControlKey(index + 1, controlIndex)}
                  </div>
                  <div>
                    <div className="assessment-finalized-secured-evidence__control-title">
                      <span dangerouslySetInnerHTML={{ __html: sanitizer(control.title) }} />
                    </div>
                    <FileList edit={selectControlFile} files={control.answer} />
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="assessment-finalized-secured-evidence">
      <div className="assessment-finalized-secured-evidence__general-files">
        <div className="assessment-finalized-secured-evidence__general-files-header">
          {translate(messages.generalFiles)}:
          <FileUpload
            accept={ALLOW_FILES}
            buttonColor={colorTheme}
            className="assessment-finalized-secured-evidence__upload-button"
            disabled={isRestrictedBoSoViewerRole}
            handleUpload={handleUpload}
            openMyFiles
            size="sm"
            text={translate(messages.browse)}
            loading={loading || false}
          />
        </div>
        <FileList
          edit={selectControlFile}
          files={files}
          handleRemove={handleRemove}
          loading={loading}
        />
      </div>
      <div className="assessment-finalized-secured-evidence__question-files">
        <div className="assessment-finalized-secured-evidence__question-files-header">
          {translate(messages.filesAssociatedToQuestions)}:
        </div>
        {categories.map(({ controls, title }, index) => {
          const hasFiles = controls.find((control) => hasControlFiles(control, answerTypes));

          return hasFiles && renderCategory(controls, title, index);
        })}
      </div>
      {selectedControlFile && (
        <EvidenceMetadataEditor
          file={selectedControlFile}
          onHide={() => selectControlFile(null)}
          open={!!selectedControlFile}
          relation={relation}
        />
      )}
      {renderConfirmationModal()}
    </div>
  );
};

SecuredEvidence.propTypes = {
  relation: PropTypes.string,
};

export default SecuredEvidence;
