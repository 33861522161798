export const messages = {
  vendorHeader: {
    key: 'VendorList.vendorHeader',
    defaultMessage: 'Vendors',
  },
  date: {
    key: 'VendorList.date',
    defaultMessage: 'DATE',
  },
  organization: {
    key: 'VendorList.organization',
    defaultMessage: 'ORGANIZATION',
  },
  name: {
    key: 'VendorList.name',
    defaultMessage: 'NAME',
  },
  email: {
    key: 'VendorList.email',
    defaultMessage: 'EMAIL',
  },
  phone: {
    key: 'VendorList.phone',
    defaultMessage: 'PHONE',
  },
  status: {
    key: 'VendorList.status',
    defaultMessage: 'TRUST INDICATOR',
  },
  vendorStatus: {
    key: 'VendorList.vendorStatus',
    defaultMessage: 'STATUS',
  },
  businessOwner: {
    key: 'VendorList.businessOwner',
    defaultMessage: 'BUSINESS OWNER',
  },
  activeAssessments: {
    key: 'VendorList.activeAssessments',
    defaultMessage: 'ACTIVE ASSESSMENTS',
  },
  openFindings: {
    key: 'VendorList.openFindings',
    defaultMessage: 'OPEN FINDINGS',
  },
  openFindingsSmall: {
    key: 'VendorList.openFindingsSmall',
    defaultMessage: 'FINDINGS',
  },
  inherentRisk: {
    key: 'VendorList.inherentRisk',
    defaultMessage: 'INHERENT RISK',
  },
  internalRisk: {
    key: 'VendorList.internalRisk',
    defaultMessage: 'INTERNAL RISK',
  },
  averageScore: {
    key: 'VendorList.averageScore',
    defaultMessage: 'AVERAGE SCORE',
  },
  assessmentScore: {
    key: 'VendorList.assessmentScore',
    defaultMessage: 'ASSESSMENT SCORE',
  },
  customerRisk: {
    key: 'VendorList.customerRisk',
    defaultMessage: 'CUSTOMER RISK',
  },
  onboardingNotCompleted: {
    key: 'VendorList.onboardingNotCompleted',
    defaultMessage: 'Organization in onboarding process (No one member complete onboarding).',
  },
  notSpecified: {
    key: 'VendorList.notSpecified',
    defaultMessage: 'Not specified',
  },
  vendor: {
    key: 'VendorList.vendor',
    defaultMessage: 'VENDOR',
  },
  vendorRisk: {
    key: 'VendorList.vendorRisk',
    defaultMessage: 'VENDOR RISK',
  },
  statusTooltip: {
    key: 'VendorList.statusTooltip',
    defaultMessage: 'Last updated: <strong>{{date}}</strong>',
  },
  emptyStateValue: {
    key: 'VendorList.emptyStateValue',
    defaultMessage: 'N/A',
  },
  removeVendorText: {
    key: 'VendorList.removeVendorText',
    defaultMessage: 'All {{vendor}} information will be lost',
  },
  addFilter: {
    key: 'VendorList.addFilter',
    defaultMessage: 'Add filter',
  },
  filterCompany: {
    key: 'VendorList.filterCompany',
    defaultMessage: 'Company',
  },
  filterOrganization: {
    key: 'VendorList.filterOrganization',
    defaultMessage: 'Organization',
  },
  filterAssessment: {
    key: 'VendorList.filterAssessment',
    defaultMessage: 'Assessment',
  },
  filterVendorRisk: {
    key: 'VendorList.filterVendorRisk',
    defaultMessage: 'Vendor risk',
  },
  filterGroup: {
    key: 'VendorList.filterGroup',
    defaultMessage: 'Group',
  },
  filterVendorStatus: {
    key: 'VendorList.filterVendorStatus',
    defaultMessage: 'Vendor status',
  },
  filterParentOrganization: {
    key: 'VendorList.filterParentOrganization',
    defaultMessage: 'Organization',
  },
  filterPlaceholder: {
    key: 'VendorList.filterPlaceholder',
    defaultMessage: 'Select value',
  },
  low: {
    key: 'VendorList.low',
    defaultMessage: 'Low',
  },
  medium: {
    key: 'VendorList.medium',
    defaultMessage: 'Medium',
  },
  high: {
    key: 'VendorList.high',
    defaultMessage: 'High',
  },
  restore: {
    key: 'VendorList.restore',
    defaultMessage: 'Restore from archive',
  },
  archive: {
    key: 'VendorList.archive',
    defaultMessage: 'Archive',
  },
  editVendorCard: {
    key: 'VendorList.editVendorCard',
    defaultMessage: 'Edit vendor card',
  },
  assignAssessment: {
    key: 'VendorList.assignAssessment',
    defaultMessage: 'Assign assessment',
  },
  deleteVendor: {
    key: 'VendorList.deleteVendor',
    defaultMessage: 'Delete vendor',
  },
  removeVendorHeader: {
    key: 'VendorList.removeVendorHeader',
    defaultMessage: 'Delete {{vendor}}?',
  },
};

export default {
  prefix: 'VendorList',
  messages,
};
