import React from 'react';
import { Menu } from 'antd';

import ROUTES from 'constants/routes';
import { useAppNavigate, useIsMyVendorsTabSelected, useSelectedMenuItems } from 'hooks/navigation';

import { translate } from 'utils/index';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import {
  AssessmentIcon,
  DashboardIcon,
  FindingIcon,
  RecentIcon,
  ShowcaseIcon,
  VendorIcon,
  FileIcon,
  EvidenceIcon,
} from 'components/Icons';
import './menu.scss';

const MainMenu = () => {
  const appNavigate = useAppNavigate();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const selectedMenuItems = useSelectedMenuItems();
  const { isRestrictedBoSoViewerRole } = useRole();

  const menuItems = [
    {
      icon: <RecentIcon />,
      name: messages.recent,
      path: isMyVendorsTabSelected ? ROUTES.VENDOR_RECENT : ROUTES.CUSTOMER_RECENT,
      'data-test': 'main-menu-item-recent',
    },
    {
      icon: <DashboardIcon />,
      name: messages.overview,
      path: isMyVendorsTabSelected ? ROUTES.VENDOR_DASHBOARD : ROUTES.CUSTOMER_OVERVIEW,
      'data-test': 'main-menu-item-overview',
    },
    {
      icon: <VendorIcon />,
      name: isMyVendorsTabSelected ? messages.vendors : messages.customers,
      path: isMyVendorsTabSelected ? ROUTES.VENDOR_VENDORS : ROUTES.CUSTOMER_CUSTOMERS,
      'data-test': 'main-menu-item-vendors-customers',
    },
    {
      icon: <AssessmentIcon />,
      name: messages.assessments,
      path: isMyVendorsTabSelected ? ROUTES.VENDOR_ASSESSMENTS : ROUTES.CUSTOMER_ASSESSMENTS,
      'data-test': 'main-menu-item-assessments-templates',
    },
    {
      icon: <FindingIcon />,
      name: messages.findings,
      path: isMyVendorsTabSelected ? ROUTES.VENDOR_FINDINGS : ROUTES.CUSTOMER_FINDINGS,
      'data-test': 'main-menu-item-findings',
    },
  ];

  if (isMyVendorsTabSelected) {
    menuItems.push({
      icon: <EvidenceIcon />,
      name: messages.evidences,
      path: ROUTES.VENDOR_EVIDENCES,
      'data-test': 'main-menu-item-evidences',
    });
  }

  if (!isMyVendorsTabSelected) {
    menuItems.push({
      icon: <ShowcaseIcon />,
      name: messages.showcases,
      path: ROUTES.CUSTOMER_SHOWCASES,
      'data-test': 'main-menu-item-showcases',
    });
  }
  if (!isRestrictedBoSoViewerRole) {
    menuItems.push({
      icon: <FileIcon />,
      name: messages.myFiles,
      path: isMyVendorsTabSelected ? ROUTES.VENDOR_MY_FILES : ROUTES.CUSTOMER_MY_FILES,
      'data-test': 'main-menu-item-my-files',
    });
  }

  const menuItemsForRender = menuItems.map(({ icon, name, path, ...restFromMenuItems }) => {
    const onNavigate = () => appNavigate(path);

    return {
      key: path,
      className: 'base-layout-main-menu__menu-item',
      icon: icon,
      label: translate(name),
      onClick: onNavigate,
      'data-test': restFromMenuItems['data-test'],
    };
  });

  return (
    <Menu
      className="base-layout-main-menu"
      theme={isMyVendorsTabSelected ? 'white' : 'dark'}
      selectedKeys={selectedMenuItems}
      mode="inline"
      data-test="main-menu"
      items={menuItemsForRender}
    />
  );
};

export default MainMenu;
