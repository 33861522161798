import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import set from 'lodash/set';

import Input from 'ui/Input';
import Divider from 'ui/Divider';
import Form from 'ui/Form';
import CardWithLogo from 'components/CardWithLogo';
import { setOrganizationOkta } from 'api/organizations';
import { generalMessages } from 'constants/messages';
import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';
import { selectProfile } from 'pages/Profile/selectors';
import { API_STATUS } from 'constants/api';

import oktaLogo from 'assets/images/okta-logo.png';
import './okta.scss';

const OktaForm = () => {
  const [formData, setFormData] = useState({
    applicationClientId: '',
    organizationUrl: '',
    groups: {
      admin: '',
      businessOwner: '',
      salesOwner: '',
    },
  });
  const {
    user: { okta },
  } = useSelector(selectProfile);
  const { organizationId } = useParams();

  const onChange = ({ target: { name, value } }) => {
    const data = set({}, name, value);
    setFormData((state) => ({ ...state, ...data }));
  };

  const onSave = async () => {
    const status = await setOrganizationOkta(organizationId, formData);
    if (status === API_STATUS.FAILED) {
      return notification.error({
        message: translate(messages.orgEditOktaUpdateFail),
      });
    }
    return notification.success({
      message: translate(messages.orgEditOktaUpdateSuccess),
    });
  };

  useEffect(() => {
    setFormData(() => ({
      ...okta,
    }));
  }, [okta]);

  const generalInputs = [
    <Input
      block
      name="applicationClientId"
      placeholder={translate(messages.orgEditOktaApplicationClientIDPlaceholder)}
      label={translate(messages.orgEditOktaApplicationClientID)}
      key="organization-edit-okta-client-id"
      value={formData?.applicationClientId}
      onChange={onChange}
    />,
    <Input
      block
      name="organizationUrl"
      placeholder={translate(messages.orgEditOktaOrganizationUrlPlaceholder)}
      label={translate(messages.orgEditOktaOrganizationUrl)}
      key="organization-edit-okta-url"
      value={formData?.organizationUrl}
      onChange={onChange}
    />,
  ];

  const groupsInputs = [
    <Input
      block
      name="groups.admin"
      placeholder={translate(messages.orgEditOktaAdminPlaceholder)}
      label={translate(messages.orgEditOktaAdmin)}
      key="organization-edit-okta-admin"
      value={formData?.groups?.admin}
      onChange={onChange}
    />,
    <Input
      block
      name="groups.salesOwner"
      placeholder={translate(messages.orgEditOktaSalesOwnerPlaceholder)}
      label={translate(messages.orgEditOktaSalesOwner)}
      key="organization-edit-okta-so"
      value={formData?.groups?.salesOwner}
    />,
    <Input
      block
      name="groups.businessOwner"
      placeholder={translate(messages.orgEditOktaBusinessOwnerPlaceholder)}
      label={translate(messages.orgEditOktaBusinessOwner)}
      key="organization-edit-okta-bo"
      value={formData?.groups?.businessOwner}
    />,
  ];

  return (
    <div className="okta-tab">
      <div className="okta-tab__top">
        <img src={oktaLogo} alt="okta" />
        <h3 className="okta-tab__title">{translate(messages.orgEditOktaTitle)}</h3>
        <p className="okta-tab__description">
          {`1. ${translate(messages.orgEditOktaDescription1)}`} <br />
          {`2. ${translate(messages.orgEditOktaDescription2)}`} <br />
          {`3. ${translate(messages.orgEditOktaDescription3)}`} <br />
        </p>
      </div>
      <div className="okta-tab__forms-wrapper">
        <div className="okta-tab__left-form">
          <h5>{translate(messages.orgEditOktaGeneral)}</h5>
          <Divider size="sm" hidden />
          {generalInputs.map((input) => (
            <Form.Item key={input.props.name}>{input}</Form.Item>
          ))}
        </div>
        <div className="okta-tab__right-form">
          <h5>{translate(messages.orgEditOktaGroups)}</h5>
          <Divider size="sm" hidden />
          {groupsInputs.map((input) => (
            <Form.Item key={input.props.name}>{input}</Form.Item>
          ))}
          <Divider size="lg" hidden />
          <CardWithLogo.Footer
            rightButtonOnClick={onSave}
            noRightIcon
            rightButtonText={translate(generalMessages.save)}
          />
        </div>
      </div>
    </div>
  );
};

export default OktaForm;
