import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'antd';
import { useNavigate } from 'react-router';
import Icon from 'ui/Icon';

import './index.scss';

const PageSubHeader = ({ children, className, withBackArrow }) => {
  const navigate = useNavigate();

  const isBackAction = window.history.length > 2;

  const headerClassName = classNames('page-subheader', className, {
    'page-subheader--with-back-arrow': withBackArrow && isBackAction,
  });
  return (
    <div className={headerClassName}>
      {withBackArrow && isBackAction && (
        <Button type="link" className="page-subheader__back-arrow" onClick={() => navigate(-1)}>
          <Icon icon="left-arrow" />
        </Button>
      )}
      <h2 className="page-subheader__title">{children}</h2>
    </div>
  );
};

PageSubHeader.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  withBackArrow: PropTypes.bool,
};

export default PageSubHeader;
