import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// COMPONENTS
import Table from 'ui/Table';

// CONSTANTS
import { TableSource } from 'ui/Table/lib';

// HOOKS
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useFindingsTable } from 'pages/Findings/useFindingsTable';
import useFindingsRow from 'hooks/useFindingsRow';

// REDUX-API
import { selectFindingsPage } from 'pages/Findings/selectors';
import { setParam, setSorter } from 'pages/Findings/reducers';
import { getProfileFindings } from 'api/finding';

const FindingsList = () => {
  const { pagination, loading } = useSelector(selectFindingsPage);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { columns, dataSource } = useFindingsTable();
  const dispatch = useDispatch();
  const onRow = useFindingsRow();

  const onTableChange = (newPagination, _filters, sorter) => {
    if (newPagination) dispatch(setParam({ key: 'pagination', value: newPagination }));

    const payload = {
      upstream: !isMyVendorsTabSelected,
      pagination: newPagination,
    };

    const field = sorter.field === 'assessmentName' ? 'assessment_name' : sorter.field;
    const sorterParams = {
      column: field,
      descending: sorter.order !== 'ascend',
    };
    const params = sorter.column ? sorterParams : {};
    dispatch(setSorter(params));
    dispatch(getProfileFindings(payload));
  };

  return (
    <Table
      className="findings-page__list"
      dataSource={dataSource}
      columns={columns}
      pagination={pagination}
      onChange={onTableChange}
      loading={loading}
      sortable
      onRow={onRow}
      emptyStateSource={TableSource.findingsList}
      scroll={{
        x: 1160,
      }}
    />
  );
};

export default FindingsList;
