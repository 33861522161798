import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

import { translate } from 'utils/index';
import TextArea from 'ui/TextArea';
import { DATE_TIME_FORMAT } from 'constants/date';

import { messages } from './messages';
import './note.scss';
import SecondaryButton from 'ui/SecondaryButton';

const Note = ({ editMode, description, date, setEditMode, onSave, 'data-test': dataTest }) => {
  const [value, setValue] = useState(description);
  const [loading, setLoading] = useState(false);

  const onNoteSave = async () => {
    if (onSave) {
      setLoading(true);
      await onSave(value || '');
      setLoading(false);
    }
    setEditMode(false);
  };

  return editMode ? (
    <div className="assessment-controls-control-note">
      <div className="assessment-controls-control-note__title">{translate(messages.noteTitle)}</div>
      <div className="assessment-controls-control-note__description">
        {translate(messages.noteDescription)}
      </div>

      <TextArea
        data-test={`${dataTest}-textarea`}
        className="assessment-controls-control-note__textarea"
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
      />

      <SecondaryButton
        className="assessment-controls-control-note__save-button"
        data-test={`${dataTest}-save-button`}
        onClick={onNoteSave}
        outline
        size="medium"
        type="submit"
        uppercase
        loading={loading}
      >
        {translate(messages.saveAndClose)}
      </SecondaryButton>
    </div>
  ) : (
    <div
      className="assessment-controls-control-note assessment-controls-control-note--view-mode"
      onClick={() => setEditMode(true)}
    >
      <div className="assessment-controls-control-note__view-mode-title-wrapper">
        <span className="assessment-controls-control-note__view-mode-title">
          {translate(messages.noteTitle)}
        </span>{' '}
        {translate(messages.lastUpdated)}: {moment(date).format(DATE_TIME_FORMAT)}
        <span className="assessment-controls-control-note__internal">
          {translate(messages.internal)}
        </span>
      </div>
      <div className="assessment-controls-control-note__view-mode-description">{description}</div>
    </div>
  );
};

Note.propTypes = {
  editMode: PropTypes.bool,
  description: PropTypes.string,
  date: PropTypes.string,
  setEditMode: PropTypes.func,
  onSave: PropTypes.func,
  'data-test': PropTypes.string,
};

export default Note;
