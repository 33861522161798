import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';

export const NavCollapsedContext = createContext({});

const LOCAL_STORAGE_KEY = 'navCollapsed';

export const NavCollapsedContextProvider = ({ children }) => {
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const isNavCollapsed = localStorage.getItem(LOCAL_STORAGE_KEY) === 'true';
  const [collapsed, setCollapsed] = useState(isNavCollapsed || !isMyVendorsTabSelected);

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
    localStorage.setItem(LOCAL_STORAGE_KEY, !collapsed);
  };

  const value = useMemo(
    () => ({
      collapsed,
      handleCollapsed,
    }),
    [collapsed],
  );

  return <NavCollapsedContext.Provider value={value}>{children}</NavCollapsedContext.Provider>;
};

NavCollapsedContextProvider.propTypes = {
  children: PropTypes.node,
};
