import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import Icon from 'ui/Icon';
import { translate } from 'utils/index';
import { messages } from 'pages/Editor/messages';
import './duplicateFindingButton.scss';

const DuplicateFindingButton = ({ onClose }) => {
  return (
    <div className="editor-duplicate-finding">
      <div className="editor-duplicate-finding__wrapper">
        <div className="editor-duplicate-finding__action">
          <span className="editor-duplicate-finding__text">
            {translate(messages.dragFindingToDuplicate)}
          </span>
          <Button className="editor-duplicate-finding__close-button" onClick={onClose} type="link">
            <Icon icon="close" />
          </Button>
        </div>
      </div>
    </div>
  );
};

DuplicateFindingButton.propTypes = {
  onClose: PropTypes.func,
};

export default DuplicateFindingButton;
