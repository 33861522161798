import axios from 'axios';
import * as ExcelJS from 'exceljs';

export const convertExcelToJson = async (url) => {
  const options = {
    url,
    method: 'GET',
    responseType: 'arraybuffer',
  };

  try {
    const axiosResponse = await axios(options);

    // Ensuring that the response is an ArrayBuffer
    const arrayBuffer = axiosResponse.data;
    if (!(arrayBuffer instanceof ArrayBuffer)) {
      throw new Error('The response is not an ArrayBuffer');
    }

    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(arrayBuffer);

    return workbook.worksheets.map((worksheet) => {
      const sheetName = worksheet.name;
      const json = [];

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        const rowValues = row.values;
        rowValues.shift(); // Remove the first element which is an empty value due to the way ExcelJS handles row indexing
        const rowObject = {};
        rowValues.forEach((value, colIndex) => {
          rowObject[`column${colIndex}`] = value;
        });
        json.push(rowObject);
      });

      const jsonSheetHeadings = json.reduce((acc, curr) => {
        const keys = Object.keys(curr);
        if (keys.length > acc.length) {
          return [keys.map((key) => ({ value: key }))];
        }
        return acc;
      }, []);

      const jsonSheetData = json.map((row) => {
        return Object.keys(row).map((key) => {
          return { value: row[key] };
        });
      });

      return { sheetName, sheetData: jsonSheetHeadings.concat(jsonSheetData) };
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error converting Excel to JSON:', error);
    throw error;
  }
};

export const convertDocToBlob = async (url) => {
  const options = {
    url,
    method: 'GET',
    responseType: 'blob',
  };
  const axiosResponse = await axios(options);
  const link = document.createElement('a');
  link.href = URL.createObjectURL(axiosResponse);

  return link;
};
