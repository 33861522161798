import { configureStore, combineReducers } from '@reduxjs/toolkit';

const loadReducers = async () => {
  const context = await require.context('../../src', true, /reducers\.js$/);
  const reducers = {};

  context.keys().forEach((path) => {
    const { default: { name, reducer } = {} } = context(path) || {};
    if (!name || !reducer) return;

    reducers[name] = reducer;
  });

  return reducers;
};

const getRootReducer = (reducers) => (state, action) => {
  const combinedReducer = combineReducers(reducers);

  if (action.type === 'app/clearStore') {
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

const initializeReduxStore = async () => {
  const reducers = await loadReducers();
  const reducer = getRootReducer(reducers);

  return configureStore({
    reducer,
  });
};

export default initializeReduxStore;
