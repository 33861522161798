/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useParams } from 'react-router';
import moment from 'moment';
import { Tooltip, notification } from 'antd';

import PageSubheader from 'components/PageSubheader';
import { AssessmentStatus } from 'constants/assessmentStatus';
import VendorName from 'pages/Assessment/vendorName';
import Loader from 'ui/Loader';
import { selectAssessment } from 'pages/Assessment/selectors';
import { getAssessment, shareCMResultsRequest } from 'api/assessment';
import { clearAssessment } from 'pages/Assessment/reducers';
import Findings from './findings';
import Finding from './findings/finding';
import Recommendations from './recommendations';
import { selectAppById } from 'pages/Profile/selectors';
import { translate } from 'utils/index';
import { messages } from './messages';
import ScoreChip from 'ui/ScoreChip';
import { getAppsFilters } from 'api/finding';
import { setAppId } from './findings/reducers';
import Button from 'ui/Button';
import { useTheme } from 'hooks/theme';
import { DATE_FORMAT } from 'constants/date';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { API_STATUS } from 'constants/api';

import './index.scss';
import { mixpanelCMfindingsSharedWithVendor } from 'utils/mixpanel';

const ContinuousMonitoring = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { status, customerName, customerId, total_score, findings } = useSelector(selectAssessment);
  const [loading, setLoading] = useState(true);
  const currentApp = useSelector(selectAppById(params?.appId));
  const [lastSentToVendor, setLastSentToVendor] = useState(undefined);
  const [isSendToVendorLoading, setIsSendToVendorLoading] = useState(false);
  const { colorTheme } = useTheme();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  useEffect(() => {
    getInitialAssesment();
    return () => {
      dispatch(clearAssessment());
    };
  }, [params.assessmentId]);

  useEffect(() => {
    if (params.appId) {
      dispatch(setAppId(params.appId));
      dispatch(getAppsFilters(params.appId));
    }
  }, [params?.appId]);

  const getInitialAssesment = async () => {
    setLoading(true);

    const assessment = await dispatch(
      getAssessment({
        id: params.assessmentId,
        preview: false,
      }),
    ).unwrap();
    const currentAppCategory = assessment?._assessment?.categories?.find(
      (category) => category?.app === params.appId,
    );

    setLastSentToVendor(currentAppCategory?.sentToVendor || undefined);
    setLoading(false);
  };

  const handleSendToVendor = () => {
    setIsSendToVendorLoading(true);
    shareCMResultsRequest({
      appId: params?.appId,
      assessmentId: params?.assessmentId,
    })
      .then((resp) => {
        if (resp?.status === API_STATUS.SUCCESS) {
          setLastSentToVendor(new Date());
          notification.success({ message: translate(messages.lastSentToVendorSuccess) });
          mixpanelCMfindingsSharedWithVendor({
            relation: customerId,
            vendorName: customerName,
            numberOfFindings: findings['all-findings'],
            type: currentApp?.name,
          });
        } else {
          notification.error({ message: translate(messages.lastSentToVendorError) });
        }
      })
      .finally(() => {
        setIsSendToVendorLoading(false);
      });
  };

  if (loading) {
    return <Loader className="assessment__loader" />;
  }

  return (
    <div
      className={classNames('assessment-finalized continuous-monitoring-findings', {
        'assessment-finalized--is-for-review': AssessmentStatus.reviewed === status,
      })}
    >
      <div className="assessment-finalized__header">
        <div className="assessment-finalized__header-left">
          <PageSubheader withBackArrow className="assessment__page_header">
            <img src={currentApp?.icon} alt="risk recon icon" className="subheader-image" />{' '}
            {currentApp?.label} {translate(messages.title)}
            {total_score?.score !== null ? <ScoreChip value={total_score.score || 0} /> : null}
          </PageSubheader>
          <div className="assessment-finalized__details">
            <VendorName name={customerName} id={customerId} />
          </div>
        </div>
        <div className="assessment-finalized__header-right">
          {isMyVendorsTabSelected ? (
            <Tooltip
              title={
                lastSentToVendor ? (
                  <>
                    <p>
                      {translate(messages.lastSentToVendorTooltipSentOn)}{' '}
                      {moment(lastSentToVendor).format(DATE_FORMAT)}
                    </p>
                    <a role="button" onClick={handleSendToVendor}>
                      {translate(messages.lastSentToVendorTooltipResend)}
                    </a>
                  </>
                ) : null
              }
            >
              <Button
                onClick={lastSentToVendor ? null : handleSendToVendor}
                data-test="send-to-vendor-button"
                color={colorTheme}
                size="sm"
                afterIcon={lastSentToVendor ? 'ok4' : null}
                beforeIcon={lastSentToVendor ? null : 'send'}
                className={classNames('assessment-finalized__send-to-vendor-button', {
                  'sent-to-vendor-state': lastSentToVendor,
                })}
                loading={isSendToVendorLoading}
                confirmed={Boolean(lastSentToVendor)}
              >
                {lastSentToVendor
                  ? translate(messages.sentToVendor)
                  : translate(messages.notSentToVendor)}
              </Button>
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div className="assessment-finalized__findings_wrapper">
        <div className="assessment-finalized__findings" id="assessment-finalized-findings">
          <div className="assessment-finalized__findings-left">
            <Findings app={currentApp} />
          </div>
          <div className="assessment-finalized__findings-right">
            <Finding />
            <Recommendations sharedCM={Boolean(lastSentToVendor)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinuousMonitoring;
