import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Selectors
import {
  selectAnswerExample,
  selectAuditControl,
  selectControlExplanation,
  selectEnableImprove,
  selectImprovedAnswer,
  selectIsLoadingResponse,
} from 'components/UpstreamAI/selectors';
import { selectProfile } from 'pages/Profile/selectors';

// API
import { getAnswerExample, getExplainedControl, getImprovedAnswer } from 'api/upstreamAi';
import { auditControl } from 'api/downstreamAi';

function useUpstreamAi({ controlId, assessmentId }) {
  const {
    user: {
      current_organization: { third_party_consent: thirdPartyConsent },
    },
  } = useSelector(selectProfile);
  const loading = useSelector(selectIsLoadingResponse);
  const enableImprove = useSelector(selectEnableImprove);
  const dispatch = useDispatch();

  const approvedAI = useMemo(
    () => enableImprove || thirdPartyConsent,
    [enableImprove, thirdPartyConsent],
  );

  // AI DATA
  const controlExplainedResponse = useSelector((state) =>
    selectControlExplanation(state, controlId),
  );
  const answerExampleResponse = useSelector((state) => selectAnswerExample(state, controlId));
  const improvedAnswerResponse = useSelector((state) => selectImprovedAnswer(state, controlId));
  const auditControlResponse = useSelector((state) => selectAuditControl(state, controlId));

  const wrapResponse = (response) => {
    return {
      payload: {
        response,
      },
    };
  };

  // API CALLS

  // explain
  const getExplainedControlData = useCallback(() => {
    if (!controlExplainedResponse && !loading)
      return dispatch(getExplainedControl({ controlId, assessmentId }));

    return wrapResponse(controlExplainedResponse);
  }, [controlExplainedResponse, loading]);

  // example
  const getAnswerExampleData = useCallback(() => {
    if (!answerExampleResponse && !loading)
      return dispatch(getAnswerExample({ controlId, assessmentId }));

    return wrapResponse(answerExampleResponse);
  }, [answerExampleResponse, loading]);

  // improve
  const getImprovedAnswerData = useCallback(() => {
    if (!improvedAnswerResponse && !loading && approvedAI)
      return dispatch(getImprovedAnswer({ controlId, assessmentId }));

    return wrapResponse(improvedAnswerResponse);
  }, [improvedAnswerResponse, loading]);

  // audit
  const getAuditControlData = useCallback(() => {
    if (!loading && approvedAI) return dispatch(auditControl({ controlId, assessmentId }));

    return wrapResponse(auditControlResponse);
  }, [auditControlResponse, loading]);

  return {
    explain: {
      getFunction: getExplainedControlData,
      contentText: controlExplainedResponse,
    },
    example: {
      getFunction: getAnswerExampleData,
      contentText: answerExampleResponse,
    },
    improve: {
      getFunction: getImprovedAnswerData,
      contentText: improvedAnswerResponse,
    },
    audit: {
      getFunction: getAuditControlData,
      contentText: auditControlResponse?.overview,
      findings: auditControlResponse?.findings,
    },
  };
}

export default useUpstreamAi;
