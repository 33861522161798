export const messages = {
  openStatus: {
    key: 'Utils.openStatus',
    defaultMessage: 'OPEN',
  },
  closedStatus: {
    key: 'Utils.closedStatus',
    defaultMessage: 'CLOSED',
  },
  approvedStatus: {
    key: 'Utils.approvedStatus',
    defaultMessage: 'APPROVED',
  },
  pendingStatus: {
    key: 'Utils.pendingStatus',
    defaultMessage: 'PENDING',
  },
};

export default {
  prefix: 'Utils',
  messages,
};
