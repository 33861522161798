import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import {
  getAssessment,
  removeFile,
  removeControlFile,
  saveControl,
  saveControlWithTriggers,
  saveControlsWithTriggers,
  uploadControlFiles,
  uploadFiles,
  revertFinalizedAssessment,
  revertReviewedAssessment,
  reviewCloudMonitoring,
} from 'api/assessment';
import { saveFinding } from 'api/finding';
import { removeEvidence, saveNote, saveRecommendation } from 'api/recommendation';
import { calculateErrors } from 'pages/Assessment/finalize/lib';
import { setAssessmentControlsOrder } from 'utils/assessment';
import { API_STATUS } from 'constants/api';

const initialState = {
  assessment: {},
  collaborate: false,
  collaboratePopup: false,
  collaborateQuestions: [],
  loadingAssessment: false,
  loading: false,
  selectedCategory: null,
  fileLoading: false,
  fileRemoveLoading: false,
  isFileUploading: false,
  saveControlRequestId: null,
  errors: {
    errorsList: {},
    errorsCount: 0,
    selectedErrorIndex: 0,
    selectedErrorId: '',
  },
  isEditingAnswer: false,
};

const reducers = createSlice({
  name: 'assessment',
  initialState,
  reducers: {
    clearAssessment: (state) => {
      state.assessment = {};
    },
    selectCategory: (state, { payload }) => {
      state.selectedCategory = payload;
    },
    setCollaborate: (state, { payload }) => {
      state.collaborate = payload;
    },
    setCollaboratePopup: (state, { payload }) => {
      state.collaboratePopup = payload;
    },
    setCollaborateQuestions: (state, { payload }) => {
      state.collaborateQuestions = payload;
    },
    setShowCasePreview: (state, { payload }) => {
      state.showCasePreview = payload;
    },
    setErrors: (state, { payload }) => {
      state.errors = { ...state.errors, ...calculateErrors(payload || state.assessment) };
    },
    setCurrentErrorIndex: (state, { payload: { number } }) => {
      if (number >= 0 && number < state.errors.errorsCount) {
        state.errors.selectedErrorIndex = number;
      }
    },
    setCurrentErrorId: (state, { payload: { controlId } }) => {
      state.errors.selectedErrorId = controlId;
    },
    resetErrors: (state) => {
      state.errors = initialState.errors;
    },
    removeError: (state, { payload: { categoryId, controlId } }) => {
      if (state.errors.errorsList[categoryId][controlId])
        state.errors.errorsCount = state.errors.errorsCount - 1;
      const categoryErrors = omit(state.errors.errorsList[categoryId], [controlId]);

      if (!isEmpty(categoryErrors)) state.errors.errorsList[categoryId] = categoryErrors;
      else state.errors.errorsList = omit(state.errors.errorsList, [categoryId]);
    },
    setIsEditingAnswer: (state, { payload }) => {
      state.isEditingAnswer = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssessment.pending, (state) => {
        state.loadingAssessment = true;
      })
      .addCase(saveControlWithTriggers.pending, (state, { meta: { requestId } }) => {
        state.loading = true;
        state.saveControlRequestId = requestId;
      })
      .addCase(saveControl.pending, (state, { meta: { requestId } }) => {
        state.loading = true;
        state.saveControlRequestId = requestId;
      })
      .addCase(uploadControlFiles.pending, (state) => {
        state.isFileUploading = true;
      })
      .addMatcher(
        isAnyOf(getAssessment.fulfilled, getAssessment.rejected, reviewCloudMonitoring.fulfilled),
        (state, { payload: { _assessment }, meta: { arg } }) => {
          if (!arg.silent) {
            if (_assessment?.templateId === '9c47b278-c4ce-4840-8cd5-400d75043504') {
              state.assessment = getAssessmentWitApps();
            } else {
              const assessmentWithSuggestion = getAssessmentWithSuggestion(_assessment);
              state.assessment = setAssessmentControlsOrder(assessmentWithSuggestion);
            }
          }
          state.loadingAssessment = false;
        },
      )
      .addMatcher(
        isAnyOf(
          saveControlWithTriggers.fulfilled,
          saveControlWithTriggers.rejected,
          saveControl.fulfilled,
          saveControl.rejected,
        ),
        (state, { payload: { _assessment }, meta: { requestId } }) => {
          if (state.saveControlRequestId === requestId) {
            const assessmentWithSuggestion = getAssessmentWithSuggestion(_assessment);
            state.assessment = setAssessmentControlsOrder(assessmentWithSuggestion);
          }
          state.loading = false;
        },
      )
      .addMatcher(
        isAnyOf(saveControlsWithTriggers.fulfilled, saveControlsWithTriggers.rejected),
        (state, { payload: { _assessment } }) => {
          const assessmentWithSuggestion = getAssessmentWithSuggestion(_assessment);
          state.assessment = setAssessmentControlsOrder(assessmentWithSuggestion);
          state.loading = false;
        },
      )
      .addMatcher(isAnyOf(uploadFiles.pending), (state) => {
        state.fileLoading = true;
      })
      .addMatcher(isAnyOf(uploadFiles.fulfilled, uploadFiles.rejected), (state) => {
        state.fileLoading = false;
      })
      .addMatcher(isAnyOf(uploadFiles.fulfilled), (state, { payload, meta }) => {
        const files = meta?.arg?.files;
        if (files && payload?.status === API_STATUS.SUCCESS) {
          state.assessment = {
            ...state.assessment,
            files: [...state.assessment.files, ...files],
          };
        }
      })
      .addMatcher(isAnyOf(removeFile.pending), (state) => {
        state.fileRemoveLoading = true;
      })
      .addMatcher(isAnyOf(removeFile.fulfilled, removeFile.rejected), (state) => {
        state.fileRemoveLoading = false;
      })
      .addMatcher(
        isAnyOf(
          removeFile.fulfilled,
          removeControlFile.fulfilled,
          removeEvidence.fulfilled,
          revertFinalizedAssessment.fulfilled,
          revertReviewedAssessment.fulfilled,
        ),
        (state, { payload: { _assessment } }) => {
          state.assessment = setAssessmentControlsOrder(_assessment);
        },
      )
      .addMatcher(
        isAnyOf(saveFinding.fulfilled, saveRecommendation.fulfilled, saveNote.fulfilled),
        (state, { payload: { _assessment } }) => {
          state.assessment = setAssessmentControlsOrder(_assessment);
        },
      )
      .addMatcher(isAnyOf(uploadControlFiles.fulfilled, uploadControlFiles.rejected), (state) => {
        state.isFileUploading = false;
      });
  },
});

function getAssessmentWithSuggestion(assessment) {
  switch (assessment?.id) {
    case '5f70be10-e2a2-4adb-a018-3f3a61e59970':
      return {
        ...assessment,
        categories: assessment?.categories?.map((category) => {
          if (category.id === 'bacb8b9d-5eac-4cc0-93ce-733e37faa4a7') {
            return {
              ...category,
              controls: category.controls.map((control) => {
                switch (control.id) {
                  case '42c7b4bd-bfe9-41ba-8641-d489a7f46176':
                    return {
                      ...control,
                      suggest: 1,
                    };
                  case '1c8cc997-26bd-40f5-8a19-2445538bc9b8':
                    return {
                      ...control,
                      suggest: 2,
                    };
                  case 'cc81441e-e777-4528-9f90-1bfaa21c90d8':
                    return {
                      ...control,
                      suggest: '54',
                    };
                  default:
                    return control;
                }
              }),
            };
          }

          return category;
        }),
      };
    case 'd95aeed2-a16d-487a-b276-77a0ece29aee':
      return {
        ...assessment,
        categories: assessment?.categories?.map((category) => {
          if (category.id === '8a2071f8-60b2-44f0-9c29-9d4e14c66473') {
            return {
              ...category,
              controls: category.controls.map((control) => {
                switch (control.id) {
                  case 'a1dcf19b-3ece-434a-9373-fce25ac2515e':
                    return {
                      ...control,
                      suggest: 'Textarea suggestion',
                    };
                  case 'be8ff11a-c326-4d59-bbef-f0cd03878739':
                    return {
                      ...control,
                      suggest: 2,
                    };
                  case 'b842687a-1148-4dbf-8628-a2f67902cf2d':
                    return {
                      ...control,
                      suggest: [1, 2],
                    };
                  case 'e9ba3b6b-666d-46ff-9e83-a69a31a55967':
                    return {
                      ...control,
                      suggest: 1,
                    };
                  default:
                    return control;
                }
              }),
            };
          }

          return category;
        }),
      };
    case 'd13478b4-25f4-45cb-a664-48af1e351e93':
      return {
        ...assessment,
        categories: assessment?.categories?.map((category) => {
          if (category.id === '70f0dd33-86e9-43f2-a71f-640f6be89416') {
            return {
              ...category,
              controls: category.controls.map((control) => {
                switch (control.id) {
                  case 'a13a86b4-6ccb-47ca-b8be-bc45db7fa169':
                    return {
                      ...control,
                      suggest: 1,
                    };
                  case '4e70f084-d252-47ad-84c6-5edea316407c':
                    return {
                      ...control,
                      suggest: 1,
                    };
                  default:
                    return control;
                }
              }),
            };
          }

          return category;
        }),
      };
    default:
      return assessment;
  }
}

const getAssessmentWitApps = () => {
  const response = {
    '_assessment': {
      'categories': [
        {
          'id': '19b6011f-28f5-48a2-a389-6f0ef0461045',
          'title': 'Category 1',
          'score': 0,
          'status': 0,
          'findings': 0,
          'position': 0,
          'progress': 0,
          'overview': '',
          'benchmark': 0.0,
          'weight': 0.0,
          'app': null,
          'controls': [
            {
              'id': '15d97198-cbd9-461f-88f2-52da4a9e35df',
              'categoryId': '19b6011f-28f5-48a2-a389-6f0ef0461045',
              'answer_type_idx': '0dbfcb50-cd1a-444f-9b0e-9b82170ea950',
              'title': 'Question 2',
              'answer': '',
              'status': 0,
              'enabled': true,
              'base_id': 'f33fa9b7-724d-4056-82b5-bb2b60f0f8fc',
              'triggers': {
                '1': ['2ebfec88-f633-4799-b76b-d2dd5dc22f5d'],
              },
              'comment': '',
              'suggestions': '',
              'description': '',
              'descriptionRight': '',
              'optional': false,
              'pending': false,
              'readonly': false,
              'emailTrigger': false,
              'app_trigger': null,
              'gaps': [],
              'findings': [],
            },
            {
              'id': '2ebfec88-f633-4799-b76b-d2dd5dc22f5d',
              'categoryId': '19b6011f-28f5-48a2-a389-6f0ef0461045',
              'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
              'title': 'Question 1',
              'answer': '',
              'status': 0,
              'enabled': true,
              'base_id': '78c68091-22d8-46fd-b774-8556eaa3a59f',
              'triggers': {},
              'comment': '',
              'suggestions': '',
              'description': '',
              'descriptionRight': '',
              'optional': false,
              'pending': false,
              'readonly': false,
              'emailTrigger': false,
              'app_trigger': '49210d50-f4cc-43db-9be4-ec2cb4c504a8',
              'gaps': [],
              'findings': [],
            },
            {
              'id': '2d8df5b4-d99c-46b0-b7fa-834f97a97baa',
              'categoryId': '19b6011f-28f5-48a2-a389-6f0ef0461045',
              'answer_type_idx': '0c22f0ce-d8f9-4a47-a803-e54590cf8334',
              'title': 'Who is your cloud provider?',
              'answer': '',
              'status': 0,
              'enabled': true,
              'base_id': '54fb9bd9-4b0f-4c96-8b36-f9f140363705',
              'triggers': {
                '1': [
                  'f8e1d4fc-0166-4400-8059-d1752d5871d4',
                  '67daf786-7d77-42f5-ae98-4d325ceb9e39',
                  '86473b79-11bd-4421-b25f-2358cd24acbf',
                ],
                '2': [
                  '131bbe38-0a07-43e6-b425-fc8f6de6ca23',
                  '86473b79-11bd-4421-b25f-2358cd24acbf',
                ],
              },
              'comment': '',
              'suggestions': '',
              'description':
                'The following questions will allow Findings to connect to the assessed company\u2019s cloud provider instance and VIEW relevant information. This requires the assessed company\u2019s explicit consent within the cloud provider\u2019s console.',
              'descriptionRight': '',
              'optional': false,
              'pending': false,
              'readonly': false,
              'emailTrigger': false,
              'app_trigger': null,
              'gaps': [],
              'findings': [],
              apps: ['d0de70e6-8b6b-47a0-a847-5b8379a91f94'],
            },
            {
              'id': '131bbe38-0a07-43e6-b425-fc8f6de6ca23',
              'categoryId': '19b6011f-28f5-48a2-a389-6f0ef0461045',
              'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
              'title': 'What is you subscription ID?',
              'answer': '',
              'status': 0,
              'enabled': false,
              'base_id': '84c63d2b-29a0-4a46-8ee1-1c653b17adf4',
              'triggers': {},
              'comment': '',
              'suggestions': '',
              'description': '',
              'descriptionRight': '',
              'optional': false,
              'pending': false,
              'readonly': false,
              'emailTrigger': false,
              'app_trigger': null,
              'gaps': [],
              'findings': [],
              apps: ['d0de70e6-8b6b-47a0-a847-5b8379a91f94'],
            },
            {
              'id': '67daf786-7d77-42f5-ae98-4d325ceb9e39',
              'categoryId': '19b6011f-28f5-48a2-a389-6f0ef0461045',
              'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
              'title': 'What is your account ID?',
              'answer': '',
              'status': 0,
              'enabled': false,
              'base_id': 'cf77bbcf-e512-4c67-a904-42569e71e329',
              'triggers': {},
              'comment': '',
              'suggestions': '',
              'description': '',
              'descriptionRight': '',
              'optional': false,
              'pending': false,
              'readonly': false,
              'emailTrigger': false,
              'app_trigger': null,
              'gaps': [],
              'findings': [],
              apps: ['d0de70e6-8b6b-47a0-a847-5b8379a91f94'],
            },
            {
              'id': '86473b79-11bd-4421-b25f-2358cd24acbf',
              'categoryId': '19b6011f-28f5-48a2-a389-6f0ef0461045',
              'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
              'title': 'What is the email address of your cloud administrator?',
              'answer': '',
              'status': 0,
              'enabled': false,
              'base_id': 'f974aeef-809f-4b60-8f53-bb931903a065',
              'triggers': {},
              'comment': '',
              'suggestions': '',
              'description': '',
              'descriptionRight':
                'After submitting this assessment, a handshake request will be sent to the assessed company\u2019s cloud provider console. Findings will also notify the cloud administrator within the assessed company, to ensure this handshake is accepted.',
              'optional': false,
              'pending': false,
              'readonly': false,
              'emailTrigger': false,
              'app_trigger': null,
              'gaps': [],
              'findings': [],
              apps: ['d0de70e6-8b6b-47a0-a847-5b8379a91f94'],
            },
            {
              'id': 'f8e1d4fc-0166-4400-8059-d1752d5871d4',
              'categoryId': '19b6011f-28f5-48a2-a389-6f0ef0461045',
              'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
              'title': 'What is your account\u2019s region?',
              'answer': '',
              'status': 0,
              'enabled': false,
              'base_id': 'ad484818-0b64-445c-88ea-1a99474ad35c',
              'triggers': {},
              'comment': '',
              'suggestions': '',
              'description': '',
              'descriptionRight': '',
              'optional': false,
              'pending': false,
              'readonly': false,
              'emailTrigger': false,
              'app_trigger': null,
              'gaps': [],
              'findings': [],
              apps: ['d0de70e6-8b6b-47a0-a847-5b8379a91f94'],
            },
            {
              'id': 'ad2fe950-24ab-4940-a7b2-a9036dcb36ac',
              'categoryId': '19b6011f-28f5-48a2-a389-6f0ef0461045',
              'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
              'title': 'Please enter your domain/website address',
              'answer': '',
              'status': 0,
              'enabled': true,
              'base_id': '9a57669f-eb07-4336-aa7c-4e948fe5e2fc',
              'triggers': {},
              'comment': '',
              'suggestions': '',
              'description':
                'Providing a domain/website address allows Findings to activate automatic apps that analyze and provide insights with regards to the assessed company.',
              'descriptionRight': '',
              'optional': false,
              'pending': false,
              'readonly': false,
              'emailTrigger': false,
              'app_trigger': '49210d50-f4cc-43db-9be4-ec2cb4c504a8',
              'gaps': [],
              'findings': [],
              apps: [
                'a55c3cd5-1974-4392-8338-33bdb71f1ce8',
                'f4a59108-9edf-406d-a362-273f6d6c20ef',
              ],
            },
            {
              'id': '3f1e020a-6e83-4256-9cf0-6b1c2db2b586',
              'categoryId': '19b6011f-28f5-48a2-a389-6f0ef0461045',
              'answer_type_idx': '0381df95-3bbb-42cd-86d5-d8820ca1c989',
              'title': 'Do you have any other relevant domains/websites?',
              'answer': '',
              'status': 0,
              'enabled': true,
              'base_id': '9846cb9c-d4df-4ac4-8f96-b21b164cfdec',
              'triggers': {
                '1': ['08c88297-0e91-4000-940b-3ce38fdd6734'],
              },
              'comment': '',
              'suggestions': '',
              'description': '',
              'descriptionRight':
                'A \u201crelevant domain/website\u201d is any digital asset owned by the company that might have any implications to the current business engagement.',
              'optional': false,
              'pending': false,
              'readonly': false,
              'emailTrigger': false,
              'app_trigger': null,
              'gaps': [],
              'findings': [],
              apps: [
                'a55c3cd5-1974-4392-8338-33bdb71f1ce8',
                'f4a59108-9edf-406d-a362-273f6d6c20ef',
              ],
            },
            {
              'id': '08c88297-0e91-4000-940b-3ce38fdd6734',
              'categoryId': '19b6011f-28f5-48a2-a389-6f0ef0461045',
              'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
              'title': 'Please enter your domain/website address',
              'answer': '',
              'status': 0,
              'enabled': false,
              'base_id': '9a57669f-eb07-4336-aa7c-4e948fe5e2fc',
              'triggers': {},
              'comment': '',
              'suggestions': '',
              'description':
                'Providing a domain/website address allows Findings to activate automatic apps that analyze and provide insights with regards to the assessed company.',
              'descriptionRight': '',
              'optional': false,
              'pending': false,
              'readonly': false,
              'emailTrigger': false,
              'app_trigger': '49210d50-f4cc-43db-9be4-ec2cb4c504a8',
              'gaps': [],
              'findings': [],
              apps: [
                'a55c3cd5-1974-4392-8338-33bdb71f1ce8',
                'f4a59108-9edf-406d-a362-273f6d6c20ef',
              ],
            },
          ],
        },
        {
          'id': 'fe08bc0e-40d1-443a-9abd-307443ad066d',
          'title': 'Category 2',
          'score': 0,
          'status': 0,
          'findings': 0,
          'position': 1,
          'progress': 0,
          'overview': '',
          'benchmark': 0.0,
          'weight': 0.0,
          'app': null,
          'controls': [
            {
              'id': '5ff793d5-13a6-4f72-a14a-f1bd85f64959',
              'categoryId': 'fe08bc0e-40d1-443a-9abd-307443ad066d',
              'answer_type_idx': '08648ee7-07e7-41b3-99c0-8b63905d029f',
              'title': 'question 1',
              'answer': '',
              'status': 0,
              'enabled': true,
              'base_id': 'ef620c54-2c33-4890-b9af-18a042213290',
              'triggers': {},
              'comment': '',
              'suggestions': '',
              'description': '',
              'descriptionRight': '',
              'optional': false,
              'pending': false,
              'readonly': false,
              'emailTrigger': false,
              'app_trigger': null,
              'gaps': [],
              'findings': [],
            },
          ],
        },
      ],
      'answer_types': {
        '0381df95-3bbb-42cd-86d5-d8820ca1c989': {
          'id': '0381df95-3bbb-42cd-86d5-d8820ca1c989',
          'type': 2,
          'options': [
            {
              'key': 1,
              'value': 'Yes',
              'weight': 0,
            },
            {
              'key': 2,
              'value': 'No',
              'weight': 0,
            },
          ],
          'weight': 0,
          'rtl': false,
        },
        '08648ee7-07e7-41b3-99c0-8b63905d029f': {
          'id': '08648ee7-07e7-41b3-99c0-8b63905d029f',
          'type': 0,
          'options': null,
          'weight': 0,
          'rtl': false,
        },
        '0c22f0ce-d8f9-4a47-a803-e54590cf8334': {
          'id': '0c22f0ce-d8f9-4a47-a803-e54590cf8334',
          'type': 2,
          'options': [
            {
              'key': 1,
              'value': 'AWS',
              'weight': 0,
            },
            {
              'key': 2,
              'value': 'Azure',
              'weight': 0,
            },
          ],
          'weight': 0,
          'rtl': false,
        },
        '0dbfcb50-cd1a-444f-9b0e-9b82170ea950': {
          'id': '0dbfcb50-cd1a-444f-9b0e-9b82170ea950',
          'type': 2,
          'options': [
            {
              'key': 1,
              'value': '1',
              'weight': 0,
            },
            {
              'key': 2,
              'value': '2',
              'weight': 3,
            },
          ],
          'weight': 0,
          'rtl': false,
        },
      },
      'status': 0,
      'finalized': false,
      'files': [],
      'id': '7b980166-31ca-4310-873f-d8fe9140bcd1',
      'title': 'dawid 25 - app triggers',
      'impact': [
        {
          'key': 'Trivial',
          'value': 1,
        },
        {
          'key': 'Minor',
          'value': 2,
        },
        {
          'key': 'Moderate',
          'value': 3,
        },
        {
          'key': 'Major',
          'value': 4,
        },
        {
          'key': 'Extreme',
          'value': 5,
        },
      ],
      'probability': [
        {
          'key': 'Rare',
          'value': 1,
        },
        {
          'key': 'Unlikely',
          'value': 2,
        },
        {
          'key': 'Moderate',
          'value': 3,
        },
        {
          'key': 'Likely',
          'value': 4,
        },
        {
          'key': 'Very Likely',
          'value': 5,
        },
      ],
      'risk': [
        'Low',
        'Low',
        'Low',
        'Low',
        'Low',
        'Low',
        'Low',
        'Low',
        'Medium',
        'Medium',
        'Medium',
        'Medium',
        'Medium',
        'Medium',
        'Medium',
        'High',
        'High',
        'High',
        'High',
        'Critical',
        'Critical',
        'Critical',
        'Critical',
        'Critical',
        'Show Stopper',
      ],
      'upstream': false,
      'customer_logo': '0ab30ffa-b3dc-47af-8d0b-b5ae6969d477.png',
      'rtl': false,
      'findings': {
        'all-findings': 0,
        'open-findings': 0,
        'open-recs': 0,
        'overdue-recs': 0,
      },
      'total_score': {
        'assessmenttemplate': null,
        'customerassessments': null,
        'score': 0.0,
        'status': 0.0,
        'benchmark': 0.0,
        'completion': 0.0,
        'progress': 0.0,
        'assessmentAdviceNumerator': 0,
        'assessmentAdviceDenominator': 0,
      },
      'due': 'None',
      'templateId': '9c47b278-c4ce-4840-8cd5-400d75043504',
      'assessor': true,
      'assesse': true,
      'customerId': null,
      'customerName': 'Findings',
    },
    'status': 1,
    'msg': 'Success',
  };

  return response._assessment;
};

export const {
  clearAssessment,
  selectCategory,
  setCollaborate,
  setCollaboratePopup,
  setCollaborateQuestions,
  setShowCasePreview,
  setErrors,
  setCurrentErrorIndex,
  resetErrors,
  removeError,
  setIsEditingAnswer,
  setCurrentErrorId,
} = reducers.actions;

export default reducers;
