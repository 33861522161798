export const messages = {
  active: {
    key: 'AssessmentsFilters.active',
    defaultMessage: 'Active',
  },
  archived: {
    key: 'AssessmentsFilters.archived',
    defaultMessage: 'Archived',
  },
};

export default {
  prefix: 'AssessmentsFilters',
  messages,
};
