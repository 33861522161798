import { messages } from 'components/AddFilterButton/messages';
import { Status } from 'constants/status';
import { VendorStatus } from 'constants/vendorStatus';

export const statuses = [
  {
    name: messages.notStarted,
    value: Status.NotStarted,
  },
  {
    name: messages.completed,
    value: Status.Completed,
  },
  {
    name: messages.inProgress,
    value: Status.InProgress,
  },
];

export const vendorStatuses = [
  {
    name: messages.pendingRequest,
    value: VendorStatus.PendingRequest,
  },
  {
    name: messages.assessmentInProgress,
    value: VendorStatus.AssessmentInProgress,
  },
  {
    name: messages.BORequestNotApproved,
    value: VendorStatus.BORequestNotApproved,
  },
  {
    name: messages.securityTeamApproved,
    value: VendorStatus.SecurityTeamApproved,
  },
  {
    name: messages.securityTeamDeclined,
    value: VendorStatus.SecurityTeamDeclined,
  },
];

export const archiveStatuses = [
  {
    label: messages.active,
    value: 0,
  },
  {
    label: messages.archived,
    value: 1,
  },
];

export const myFilesFormats = [
  {
    label: messages.all,
    value: '',
  },
  {
    label: messages.docx,
    value: 'docx',
  },
  {
    label: messages.doc,
    value: 'doc',
  },
  {
    label: messages.xlsx,
    value: 'xlsx',
  },
  {
    label: messages.pdf,
    value: 'pdf',
  },
  {
    label: messages.csv,
    value: 'csv',
  },
  {
    label: messages.jpg,
    value: 'jpg',
  },
  {
    label: messages.jpeg,
    value: 'jpeg',
  },
  {
    label: messages.png,
    value: 'png',
  },
];
export const myFilesExpirationStatuses = [
  {
    label: messages.all,
    value: '',
  },
  {
    label: messages.valid,
    value: 1,
  },
  {
    label: messages.expiredSoon,
    value: 2,
  },
  {
    label: messages.expired,
    value: 3,
  },
];

export const assessmentAvailableFilters = (isVendorTabSelected, isViewAggregated) => {
  const filters = [];

  if (isVendorTabSelected) {
    filters.push(
      {
        type: 'vendor',
        name: messages.vendorFilter,
      },
      {
        type: 'group',
        name: messages.groupFilter,
      },
      {
        type: 'vendorStatus',
        name: messages.vendorStatusFilter,
      },
    );
  } else {
    filters.push({
      type: 'customer',
      name: messages.customerFilter,
    });
  }

  filters.push(
    {
      type: 'assessment',
      name: messages.assessmentTypeFilter,
    },
    {
      type: 'status',
      name: messages.statusFilter,
    },
  );

  if (isViewAggregated) {
    filters.push({
      type: 'parent-organization',
      name: messages.parentOrganizationFilter,
    });
  }

  return filters;
};

export const messagesAvailableFilters = (isVendorTabSelected) => {
  const filters = [];

  if (isVendorTabSelected) {
    filters.push({
      type: 'vendor',
      name: messages.vendorFilter,
    });
  } else {
    filters.push({
      type: 'customer',
      name: messages.customerFilter,
    });
  }

  filters.push({
    type: 'assessment',
    name: messages.assessmentFilter,
  });

  return filters;
};

export const findingsAvailableFilters = (isVendorTabSelected, isViewAggregated) => {
  const filters = [];

  if (isVendorTabSelected) {
    filters.push({
      type: 'vendor',
      name: messages.vendorFilter,
    });
  } else {
    filters.push({
      type: 'customer',
      name: messages.customerFilter,
    });
  }

  filters.push({
    type: 'assessmentName',
    name: messages.assessmentsFilter,
  });

  filters.push({
    type: 'subject',
    name: messages.subjectsFilter,
  });

  filters.push({
    type: 'risk',
    name: messages.riskFilter,
  });

  filters.push({
    type: 'findingStatus',
    name: messages.statusFilter,
  });

  if (isViewAggregated) {
    filters.push({
      type: 'parent-organization',
      name: messages.parentOrganizationFilter,
    });
  }

  return filters;
};

export const myFilesAvailableFilters = () => {
  return [
    {
      key: 'type',
      type: 'type',
      name: messages.myFilesFormatFilter,
    },
    {
      key: 'expiration',
      type: 'expiration',
      name: messages.myFilesExpirationFilter,
    },
  ];
};
