import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { saveFinding, getAppsFilters } from 'api/finding';

const initialState = {
  selectedFinding: null,
  findingLoading: false,
  filterParameter: null,
  appId: null,
};

const reducers = createSlice({
  name: 'assessmentFindings',
  initialState,
  reducers: {
    selectFinding: (state, { payload }) => {
      state.selectedFinding = payload;
    },
    setAppId: (state, { payload }) => {
      state.appId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveFinding.pending, (state) => {
        state.findingLoading = true;
      })
      .addMatcher(isAnyOf(saveFinding.fulfilled, saveFinding.rejected), (state) => {
        state.findingLoading = false;
      })
      .addMatcher(
        isAnyOf(getAppsFilters.fulfilled, getAppsFilters.rejected),
        (state, { payload }) => {
          state.filterParameter = payload?.filterParameter || null;
        },
      );
  },
});

export const { selectFinding, setAppId } = reducers.actions;

export default reducers;
