import React from 'react';
import PropTypes from 'prop-types';
import { Modal as AntModal } from 'antd';
import classNames from 'classnames';
import Icon from 'ui/Icon';
import Button from 'ui/Button';
import { useTheme } from 'hooks/theme';
import './index.scss';

const Modal = ({
  children,
  className,
  cancelText,
  confirmLoading,
  onCancel,
  onOk,
  okDisabled = false,
  okText,
  footer,
  customCloseIcon = true,
  ...props
}) => {
  const { colorTheme } = useTheme();

  const customFooter = [
    <Button
      data-test="modal-button-accept"
      key="ACCEPT_BUTTON"
      className="ui-modal__footer-ok-button"
      size="sm"
      color={colorTheme}
      loading={confirmLoading}
      onClick={onOk}
      disabled={okDisabled}
    >
      {okText || 'Yes'}
    </Button>,
    <Button
      data-test="modal-button-cancel"
      key="CANCEL_BUTTON"
      className="ui-modal__footer-cancel-button"
      size="sm"
      color="white"
      onClick={onCancel}
    >
      {cancelText || 'No'}
    </Button>,
  ];

  return (
    <AntModal
      className={classNames('ui-modal', className)}
      closeIcon={customCloseIcon && <Icon icon="close2" />}
      footer={footer === null ? null : footer || customFooter}
      onCancel={onCancel}
      {...props}
    >
      {children}
    </AntModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  cancelText: PropTypes.string,
  confirmLoading: PropTypes.bool,
  footer: PropTypes.node,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  okText: PropTypes.string,
  okDisabled: PropTypes.bool,
  customCloseIcon: PropTypes.bool,
};

export default Modal;
