import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { getAssessmentReport } from 'api/assessment';
import AppPreloader from 'ui/Loader/appPreloader';
import { translate } from 'utils/index';

import { messages } from '../messages';
import './showReport.scss';

const ShowReport = () => {
  const [pdf, setPdf] = useState();
  const [loading, setLoading] = useState(true);
  const { assessmentId } = useParams();

  useEffect(() => {
    const getPdf = async () => {
      try {
        const response = await getAssessmentReport({ assessmentId });
        if (response && response.status !== 0) {
          setPdf(response);
        }
        setLoading(false);
      } finally {
        setTimeout(() => window.stop(), 5000);
      }
    };

    getPdf();
  }, []);

  if (loading) {
    return <AppPreloader />;
  }

  if (!pdf) {
    return (
      <h5 className="assessment-show-report__viewer-not-found">
        {translate(messages.reportNotFound)}
      </h5>
    );
  }

  return (
    <div className="assessment-show-report__viewer">
      <iframe
        title="assessment-show-report__viewer"
        className="assessment-show-report__pdf-container"
        src={`data:application/pdf;base64,${pdf}`}
        type="application/pdf"
        height="100%"
        width="100%"
      />
    </div>
  );
};

export default ShowReport;
