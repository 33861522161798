import { ControlType } from 'constants/controlType';

export const getIconForAnswerType = (answerType) => {
  switch (answerType) {
    case ControlType.Input:
      return 'single-line-text';
    case ControlType.TextArea:
      return 'multi-line-text';
    case ControlType.RadioGroup:
      return 'radio-button';
    case ControlType.Checkbox:
      return 'multi-select';
    case ControlType.DropDown:
      return 'drop-down';
    case ControlType.MultiSelect:
      return 'drop-down';
    case ControlType.FileUpload:
      return 'upload2';
    case ControlType.DatePicker:
      return 'date-outline';
    default:
      return null;
  }
};
