import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, notification } from 'antd';

import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useTheme } from 'hooks/theme';
import { translate } from 'utils/index';
import { API_STATUS } from 'constants/api';
import {
  resendInvitation,
  revertFinalizedAssessment,
  revertReviewedAssessment,
} from 'api/assessment';
import Button from 'ui/Button';
import Icon from 'ui/Icon';
import ROUTES from 'constants/routes';
import { getOrganizationIndex } from 'utils/organizations';
import { selectProfile } from 'pages/Profile/selectors';
import { selectOrganizationsExtended } from 'pages/Organization/selectors';
import { AssessmentStatus } from 'constants/assessmentStatus';
import ForceSubmitModal from 'pages/Assessment/forceSubmitModal';
import { ReactComponent as ForceSubmitIcon } from 'assets/svgs/force-submit.svg';

import { setCollaborate } from './reducers';
import { selectAssessment } from './selectors';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import { mixpanelTrackAssessmentReverted } from 'utils/mixpanel';

import './actions.scss';

const Actions = ({ className }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [forceSubmitModalVisible, setForceSubmitModalVisible] = useState(false);
  const {
    assessor,
    finalized,
    id: assessmentId,
    status,
    customerName,
    customerId,
    total_score,
    title,
  } = useSelector(selectAssessment);
  const {
    user: {
      current_organization: { id: currentOrganizationId, label: currentOrganizationLabel },
    },
  } = useSelector(selectProfile);
  const { organizations } = useSelector(selectOrganizationsExtended);
  const appNavigate = useAppNavigate();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { colorTheme } = useTheme();
  const { isRestrictedBoSoViewerRole } = useRole();

  const forceSubmitModalHide = () => {
    setForceSubmitModalVisible(false);
  };

  const onResendInvitation = async () => {
    setLoading(true);
    const response = await dispatch(resendInvitation()).unwrap();
    if (response.status === API_STATUS.SUCCESS) {
      notification.success({ message: translate(messages.invitationHasBeenSentSuccessfully) });
    }

    setLoading(false);
  };

  const collaborate = () => {
    dispatch(setCollaborate(true));
  };

  const shareWithTeammate = () => {
    return appNavigate(`${ROUTES.ORGANIZATION_EDIT}/*`, {
      baseOrganization: currentOrganizationId,
      type: 'customer',
      organizationId: currentOrganizationId,
      '*': `?orgIndex=${getOrganizationIndex({
        organizations,
        orgId: currentOrganizationId,
      })}&assessment=${assessmentId}`,
    });
  };

  const editAssessment = () => {
    return appNavigate(`${ROUTES.VENDOR_ASSESSMENT_TEMPLATE}/*`, {
      templateId: assessmentId,
      '*': '?assessment=true',
    });
  };

  const revertToInProgress = async () => {
    setLoading(true);
    const {
      payload: { status },
    } = await dispatch(revertFinalizedAssessment());

    if (status === API_STATUS.SUCCESS) {
      mixpanelTrackAssessmentReverted({
        relation: customerId,
        assessmentProgress: total_score?.completion,
        assessmentName: title,
        assignedOrganizationName: customerName,
        assigneeOrganizationName: currentOrganizationLabel,
      });
    }
    setLoading(false);
  };

  const revertToSubmitted = async () => {
    setLoading(true);
    await dispatch(revertReviewedAssessment()).unwrap();
    setLoading(false);
  };

  const actions = [
    {
      action: onResendInvitation,
      enabled: assessor,
      icon: 'back',
      title: translate(messages.resendInvitation),
      dataTest: 'assessment-actions-resend-invitation-button',
    },
    {
      action: collaborate,
      enabled: !finalized && !isMyVendorsTabSelected,
      icon: 'share',
      title: translate(messages.shareMultipleControls),
      dataTest: 'assessment-actions-share-button',
    },
  ];

  if (!isMyVendorsTabSelected) {
    actions.push({
      action: shareWithTeammate,
      enabled: !finalized,
      icon: 'add-user',
      title: translate(messages.shareWithTeammate),
      dataTest: 'assessment-actions-share-with-teammate-button',
    });
  }

  if (assessor) {
    actions.push({
      action: editAssessment,
      enabled:
        (isMyVendorsTabSelected || (!isMyVendorsTabSelected && !finalized)) &&
        !isRestrictedBoSoViewerRole,
      icon: 'edit',
      title: translate(messages.editAssessment),
      dataTest: 'assessment-actions-edit-assessment-button',
    });

    actions.push({
      action: revertToInProgress,
      enabled: finalized && status === AssessmentStatus.reviewed,
      icon: 'revert',
      title: translate(messages.revertToInProgressState),
      dataTest: 'assessment-actions-revert-to-in-progress-button',
    });

    actions.push({
      action: revertToSubmitted,
      enabled: finalized && status === AssessmentStatus.disabled,
      icon: 'revert',
      title: translate(messages.revertToSubmittedState),
      dataTest: 'assessment-actions-revert-to-submitted-button',
    });
  }

  if (isMyVendorsTabSelected) {
    actions.push({
      action: () => {
        setForceSubmitModalVisible(true);
      },
      enabled: !finalized,
      icon: <ForceSubmitIcon />,
      title: translate(messages.forceSubmitAssessment),
      dataTest: 'assessment-actions-force-submit-button',
    });
  }

  const menuItems = useMemo(() => {
    return actions.map(
      ({ action, dataTest, enabled, icon, title }) =>
        enabled && {
          key: title,
          label: title,
          icon: typeof icon === 'string' ? <Icon icon={icon} /> : icon,
          onClick: action,
          'data-test': dataTest,
        },
    );
  }, [actions]);

  return (
    <>
      {actions.filter((action) => action.enabled).length > 0 && (
        <Dropdown
          trigger="click"
          placement="bottomRight"
          disabled={isRestrictedBoSoViewerRole}
          menu={{ items: menuItems, className: 'assessment-actions__menu' }}
        >
          <Button
            data-test="assessment-actions-button-more"
            className={classNames('assessment-actions__button', className)}
            afterIcon="arrow-down"
            name={translate(messages.more)}
            color={colorTheme}
            size="sm"
            outline
            fluid
            loading={loading}
            disabled={isRestrictedBoSoViewerRole}
          />
        </Dropdown>
      )}
      <ForceSubmitModal onCancel={forceSubmitModalHide} open={forceSubmitModalVisible} />
    </>
  );
};

Actions.propTypes = {
  className: PropTypes.string,
};

export default Actions;
