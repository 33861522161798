import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import PageHeader from 'components/PageHeader';
import { translate } from 'utils/index';
import MyFilesFilters from 'pages/MyFiles/myFilesFilters';
import MyFilesList from 'pages/MyFiles/MyFilesList';
import { messages } from './messages';
import { getMyFiles, uploadMyFiles } from 'api/myFiles';
import Loader from 'ui/Loader';
import EvidenceMetadataEditor from 'components/EvidenceMetadataEditor';
import FilePreview from './filePreview';
import RenameFile from './renameFile';
import RemoveFile from './removeFile';
import { API_STATUS } from 'constants/api';
import { useFileUploadProcess } from 'components/FileUploadIndicator/useFileUploadProcess';
import { FIRST_OFFSET } from './constants';

import './index.scss';

const MyFiles = () => {
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const [isMyFileUpload, setIsMyFileUpload] = useState(false);
  const [search, setSearch] = useState('');
  const [fileToEditMeta, setFileToEditMeta] = useState(null);
  const [fileIndexToPreview, setFileIndexToPreview] = useState(null);
  const [fileIndexToRename, setFileIndexToRename] = useState(null);
  const [fileIndexToDelete, setFileIndexToDelete] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { abortController, startUploading, finishUploding, renderConfirmationModal } =
    useFileUploadProcess();

  const filters = useSelector((state) => {
    return state.myFiles.filters;
  });
  const isMyFilesLoading = useSelector((state) => {
    return state.myFiles.loading;
  });

  const files = useSelector((state) => {
    return state.myFiles.myFiles;
  });

  const fetchMyFiles = async (offset = FIRST_OFFSET) => {
    const parsedFilters = filters.reduce((acc, filter) => {
      if (filter.value) {
        acc[filter.type] = filter.id;
      }
      return acc;
    }, {});

    dispatch(
      getMyFiles({
        offset,
        search,
        ...parsedFilters,
      }),
    );
  };

  useEffect(() => {
    fetchMyFiles();
  }, [filters, search]);

  const onFileUpload = async (e) => {
    setIsMyFileUpload(true);
    startUploading();
    const response = await dispatch(
      uploadMyFiles({
        organizationId,
        abortController,
        file: e.target.files[0],
      }),
    ).unwrap();
    finishUploding();
    fetchMyFiles();
    setIsMyFileUpload(false);
    if (response?.file && response?.status === API_STATUS.SUCCESS) {
      setFileToEditMeta(response.file);
    }
  };

  const onFiltersChange = (value) => {
    setSearch(value);
  };

  if (isMyFilesLoading || isMyFileUpload) {
    return <Loader />;
  }

  return (
    <div className="my-files">
      <PageHeader className="my-files__header">{translate(messages.header)}</PageHeader>
      <div className="my-files__wrapper">
        <div className="my-files__filters">
          <MyFilesFilters onFiltersChange={onFiltersChange} onFileUpload={onFileUpload} />
        </div>
        <MyFilesList
          files={files}
          fetchMyFiles={fetchMyFiles}
          onEditClick={(file) => setFileToEditMeta(file)}
          handlePreview={(fileIndex) => setFileIndexToPreview(fileIndex)}
          handleRename={(fileIndex) => setFileIndexToRename(fileIndex)}
          handleDelete={(fileIndex) => setFileIndexToDelete(fileIndex)}
          onFileUpload={onFileUpload}
          selectMulti={false}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
        {fileToEditMeta && (
          <EvidenceMetadataEditor
            file={fileToEditMeta}
            onHide={() => setFileToEditMeta(null)}
            open={!!fileToEditMeta}
          />
        )}
      </div>
      {fileIndexToPreview !== null && (
        <FilePreview
          fileData={files[fileIndexToPreview]}
          open={fileIndexToPreview !== null}
          onClose={() => setFileIndexToPreview(null)}
          onClickNext={() =>
            setFileIndexToPreview((prev) => (prev < files?.length - 1 ? prev + 1 : 0))
          }
          onClickPrev={() =>
            setFileIndexToPreview((prev) => (prev > 0 ? prev - 1 : files?.length - 1))
          }
        />
      )}
      {fileIndexToRename !== null && (
        <RenameFile
          open={fileIndexToRename !== null}
          onClose={() => setFileIndexToRename(null)}
          file={files[fileIndexToRename] ? files[fileIndexToRename] : undefined}
        />
      )}
      {fileIndexToDelete !== null && (
        <RemoveFile
          open={fileIndexToDelete !== null}
          onClose={() => setFileIndexToDelete(null)}
          file={files[fileIndexToDelete] ? files[fileIndexToDelete] : undefined}
        />
      )}
      {renderConfirmationModal()}
    </div>
  );
};

MyFiles.propsTypes = {
  isInModal: PropTypes.bool,
};

export default MyFiles;
