/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';

import { translate } from 'utils/index';
import { DATE_FORMAT } from 'constants/date';
import { useGetPath, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { selectIsViewAggregated } from 'pages/Organization/selectors';
import { useRole } from 'hooks/useRole';
import { Link } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { createSorterByString } from 'utils/strings';
import FileComponent from 'pages/Evidences/evidencesColumns/fileComponent';
import Status from 'pages/Evidences/evidencesColumns/status';
import { messages } from './messages';
import { getAppIconById, shouldAppNavigateToContinuousMonitoring } from 'utils/appsUtils';
import Tag from 'ui/Tag';
import MyFilesExpiration from 'pages/MyFiles/myFilesExpiration';
import EvidenceMetadataEditor from 'components/EvidenceMetadataEditor';
import FilePreview from 'pages/MyFiles/filePreview';

import './index.scss';

const useEvidencesTable = ({ evidences, evidencesSorter, fetchEvidences, recent }) => {
  const [evidenceFileToEditMeta, setEvidenceFileToEditMeta] = useState(null);
  const [evidenceFileTopreview, setEvidenceFileToPreview] = useState(null);

  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const getPath = useGetPath();
  const isViewAggregated = useSelector(selectIsViewAggregated);
  const { isViewerRole } = useRole();
  const sortOrder = evidencesSorter?.descending ? 'descend' : 'ascend';
  const columns = useMemo(() => {
    const allColumns = [
      {
        title: translate(messages.lastModified),
        dataIndex: 'lastModified',
        width: 120,
        render: (lastModified) => (
          <span className="evidence-column__last-modified">
            {moment(lastModified).format(DATE_FORMAT)}
          </span>
        ),
        sorter: (a, b) => new Date(a.lastModified) - new Date(b.lastModified),
        sortOrder: evidencesSorter.column === 'lastModified' ? sortOrder : null,
      },
      {
        title: isMyVendorsTabSelected ? translate(messages.vendor) : translate(messages.customer),
        dataIndex: 'profileCorporateName',
        width: 180,
        render: (name, evidence) =>
          isMyVendorsTabSelected && !isViewerRole ? (
            <Link
              className="evidence-column__table-link"
              data-test="vendor-link"
              to={getPath(ROUTES.VENDOR_EDIT, {
                vendorId: evidence?.corporateId,
              })}
            >
              {name}
            </Link>
          ) : (
            name
          ),
        sorter: createSorterByString('profileCorporateName'),
        sortOrder: evidencesSorter.column === 'profileCorporateName' ? sortOrder : null,
      },
      {
        title: translate(messages.assessment),
        dataIndex: 'assessmentTitle',
        render: (assessmentTitle, evidence) => {
          return (
            <div className="evidence-column__assessment-container">
              <Link
                className="evidence-column__table-link"
                data-test="assessment-link"
                to={`${getPath(
                  isMyVendorsTabSelected ? ROUTES.VENDOR_ASSESSMENT : ROUTES.CUSTOMER_ASSESSMENT,
                  { assessmentId: evidence.assessmentId },
                )}/secured-evidence`}
              >
                {evidence.app && shouldAppNavigateToContinuousMonitoring(evidence.app) ? (
                  <Tag
                    icon={<img src={getAppIconById(evidence.app)} alt="" />}
                    name={assessmentTitle}
                    color="blue"
                  />
                ) : (
                  <Tag name={assessmentTitle} color="blue" />
                )}
              </Link>
            </div>
          );
        },
        sorter: createSorterByString('assessmentTitle'),
        sortOrder: evidencesSorter.column === 'assessmentTitle' ? sortOrder : null,
      },
      {
        title: translate(messages.fileTitle),
        dataIndex: 'fileTitle',
        render: (_, { fileTitle, assessmentId, evidenceId }) => {
          return (
            <FileComponent
              fileTitle={fileTitle}
              assessmentId={assessmentId}
              evidenceId={evidenceId}
              onClick={() =>
                setEvidenceFileToPreview({ id: evidenceId, title: fileTitle, path: fileTitle })
              }
            />
          );
        },
        sorter: createSorterByString('fileTitle'),
        sortOrder: evidencesSorter.column === 'fileTitle' ? sortOrder : null,
      },
      {
        title: translate(messages.status),
        dataIndex: 'evidenceStatus',
        width: 150,
        render: (status) => <Status status={status} />,
        sorter: (a, b) => a.evidenceStatus - b.evidenceStatus,
        sortOrder: evidencesSorter.column === 'evidenceStatus' ? sortOrder : null,
      },
      {
        title: translate(messages.expiration),
        dataIndex: 'expiryDate',
        width: 160,
        render: (expiryDate, { issueDate, evidenceId, fileTitle }) => {
          const issueDateFormatted = moment(issueDate).format(DATE_FORMAT);

          const now = moment();
          const formattedExpirationDate = moment(expiryDate);
          const daysDiff = formattedExpirationDate.diff(now, 'days') + 1;
          const isExpired = daysDiff <= 0;

          return (
            <Tooltip
              title={
                <div className="evidence-column__expiration__tooltip">
                  <span>
                    {translate(messages.issuedOn)} {issueDateFormatted}
                  </span>
                  <br />
                  <span>
                    {isExpired
                      ? translate(messages.expiredDaysAgo, { days: daysDiff * -1 })
                      : translate(messages.expiringInDays, { days: daysDiff })}
                  </span>
                  <br />
                  <a
                    data-test="edit-evidence-metadata"
                    role="button"
                    onClick={() => {
                      setEvidenceFileToEditMeta({
                        metadata: { issueDate, expiryDate },
                        id: evidenceId,
                        path: fileTitle,
                      });
                    }}
                  >
                    {translate(messages.editEvidenceMetadata)}
                  </a>
                </div>
              }
            >
              <div className="evidence-column__expiration">
                <MyFilesExpiration expirationDate={expiryDate} showDaysExpiringSoon={false} />
              </div>
            </Tooltip>
          );
        },
        sorter: (a, b) => new Date(a.expiryDate) - new Date(b.expiryDate),
        sortOrder: evidencesSorter.column === 'expiryDate' ? sortOrder : null,
      },
    ];

    if (isViewAggregated) {
      const column = {
        title: translate(messages.organization),
        dataIndex: 'parentOrganizationCorporateName',
        sorter: createSorterByString('parentOrganizationCorporateName'),
        sortOrder: evidencesSorter.column === 'parentOrganizationCorporateName' ? sortOrder : null,
      };
      allColumns.splice(1, 0, column);
    }

    return allColumns;
  }, [evidences]);

  const editEvidenceMeta = useCallback(() => {
    return evidenceFileToEditMeta ? (
      <EvidenceMetadataEditor
        file={evidenceFileToEditMeta}
        onHide={() => setEvidenceFileToEditMeta(null)}
        handleSave={() => fetchEvidences()}
        open={!!evidenceFileToEditMeta}
      />
    ) : null;
  }, [evidenceFileToEditMeta]);

  const previewEvidence = useCallback(() => {
    return (
      <FilePreview
        fileData={evidenceFileTopreview}
        open={evidenceFileTopreview !== null}
        onClose={() => setEvidenceFileToPreview(null)}
        showNextPrev={false}
      />
    );
  }, [evidenceFileTopreview]);

  return {
    columns,
    editEvidenceMeta,
    previewEvidence,
  };
};

useEvidencesTable.propTypes = {
  evidences: PropTypes.object,
  evidencesSorter: PropTypes.object,
  recent: PropTypes.bool,
  fetchEvidences: PropTypes.func,
};

export default useEvidencesTable;
