import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Input from 'ui/Input';
import { translate } from 'utils/index';
import { messages } from './messages';
import Modal from 'ui/Modal';
import Icon from 'ui/Icon';
import { ReactComponent as RenameIcon } from 'assets/svgs/rename-icon.svg';
import Loader from 'ui/Loader';
import { setFileName } from 'api/myFiles';

import './renameFile.scss';

const RenameFile = ({ open, onClose, file }) => {
  const [title, setTitle] = useState(file?.title || '');
  const dispatch = useDispatch();
  const isFileNameLoading = useSelector((state) => {
    return state.myFiles.isFileNameLoading;
  });

  useEffect(() => {
    if (file?.title) {
      const parsedTitle = file.title.split('.')?.[0];
      setTitle(parsedTitle || file?.title || '');
    }
  }, [file]);

  const handleRename = () => {
    dispatch(setFileName({ fileId: file.id, title }));
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="rename-file-modal"
      title={
        <>
          <RenameIcon />
          {translate(messages.renameFileTitle)}
        </>
      }
      onCancel={onClose}
      cancelText={translate(messages.closeRenameModal)}
      onOk={handleRename}
      okText={translate(messages.saveFileName)}
      closeIcon={<Icon icon="close" />}
    >
      {isFileNameLoading ? <Loader /> : null}
      <Input
        label={translate(messages.renameFileLabel)}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder={translate(messages.renameFilePlaceholder)}
        data-test="rename-file-input"
      />
    </Modal>
  );
};

RenameFile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  file: PropTypes.object,
};

export default RenameFile;
