import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import UploadFiles from 'components/UploadFiles';
import EvidenceMetadataEditor from 'components/EvidenceMetadataEditor';
import { removeAssessmentFile, saveTemplate, uploadAssessmentFiles } from 'api/editor';
import { API_STATUS } from 'constants/api';
import { selectTemplate } from 'pages/Editor/selectors';
import { useFileUploadProcess } from 'components/FileUploadIndicator/useFileUploadProcess';
import { ALLOW_FILES } from 'constants/general';
import { Roles } from 'constants/roles';
import { selectCurrentOrganization } from 'pages/Profile/selectors';

const Files = ({ templateId }) => {
  const dispatch = useDispatch();
  const { abortController, startUploading, finishUploding, renderConfirmationModal } =
    useFileUploadProcess();
  const { role } = useSelector(selectCurrentOrganization);
  const template = useSelector(selectTemplate);
  const [uploadedFiles, setUploadedFiles] = useState({
    load: false,
    files: [],
  });
  const [selectedControlFile, selectControlFile] = useState();

  const setFiles = (files) => {
    setUploadedFiles((state) => ({ ...state, files }));
  };

  const setLoad = (load) => {
    setUploadedFiles((state) => ({ ...state, load }));
  };

  useEffect(() => {
    if (!isEmpty(template)) setFiles(template.files);
  }, [template]);

  const handleRemove = async (fileId) => {
    setLoad(true);
    await dispatch(removeAssessmentFile(fileId));
    setLoad(false);
  };

  const handleUpload = async (files) => {
    setLoad(true);

    if (!template.title) {
      dispatch(saveTemplate({ ...template }));
    }
    startUploading();
    const { status } = await uploadAssessmentFiles(files, templateId, abortController);
    finishUploding();
    if (status === API_STATUS.SUCCESS) {
      return setUploadedFiles((state) => ({ files: [...state.files, ...files], load: false }));
    }
    return setLoad(false);
  };

  return (
    <div className="editor-settings__upload-files">
      <UploadFiles
        accept={ALLOW_FILES}
        className="assessment-files__upload-files"
        disabled={Roles.Viewer === role}
        uploadedFiles={uploadedFiles.files}
        handleUpload={handleUpload}
        handleRemove={handleRemove}
        loading={uploadedFiles.load}
        browse
        edit={Roles.Viewer !== role ? selectControlFile : undefined}
      />
      {selectedControlFile && (
        <EvidenceMetadataEditor
          file={selectedControlFile}
          onHide={() => selectControlFile(null)}
          open={!!selectedControlFile}
        />
      )}
      {renderConfirmationModal()}
    </div>
  );
};

Files.propTypes = {
  templateId: PropTypes.string,
  themeColor: PropTypes.string,
};

export default Files;
