import React from 'react';
import PropTypes from 'prop-types';

import Text from 'ui/Text';
import Form from 'ui/Form';
import Button from 'ui/Button';
import Divider from 'ui/Divider';
import { translate } from 'utils/index';

import { generalMessages } from 'constants/messages';
import { messages } from 'pages/Login/messages';
import { LOGIN_FORM_MODE } from 'pages/Login/lib';

const ForgotPasswordSuccess = ({ setMode }) => {
  const onBack = () => setMode(LOGIN_FORM_MODE.LOGIN);
  const onResend = () => setMode(LOGIN_FORM_MODE.FORGOT_PASSWORD);

  return (
    <div>
      <Text level={1} text={translate(messages.forgotPasswordSuccessMessageTitle)} />
      <Text
        level={3}
        data-test="forgot-password-success-success-message"
        text={translate(messages.forgotPasswordSuccessMessage)}
        light
      />
      <Divider size="md" hidden />
      <Form>
        <Form.Item>
          <Button
            color="pink"
            name={translate(generalMessages.back)}
            onClick={onBack}
            data-test="forgot-password-success-button-back"
          />
        </Form.Item>
      </Form>
      <Divider size="md" hidden />
      <div className="forgot-password-form__footer">
        <Text>{translate(messages.didNotReceiveEmail)}</Text>{' '}
        <Button
          link
          onClick={onResend}
          color="pink"
          name={translate(messages.resend)}
          data-test="forgot-password-form-resend-button"
        />
      </div>
    </div>
  );
};

ForgotPasswordSuccess.propTypes = {
  setMode: PropTypes.func,
};

export default ForgotPasswordSuccess;
