import React from 'react';
import PropTypes from 'prop-types';
import NotFound from 'pages/NotFound';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.error('ErrorBoundary didCatch an error: ', error, info);

    window?.SessionStack?.trackEvent('ErrorBoundary Event', {
      message_str: 'ErrorBoundary didCatch an error event',
      componentStack_str: info?.componentStack,
      error_str: error?.message,
    });
  }

  render() {
    if (this.state.hasError) {
      return <NotFound />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};
