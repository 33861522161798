import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import CardWithLogo from 'components/CardWithLogo';
import Loader from 'ui/Loader';
import { NEW_CUSTOMER_SO_PAGE } from 'pages/SoBoPages/NewCustomer/lib';
import { getOrganizationDataForSoPage } from 'api/organizations';
import StageOne from 'pages/SoBoPages/NewCustomer/stageOne';
import StageTwo from 'pages/SoBoPages/NewCustomer/stageTwo';
import StageThree from 'pages/SoBoPages/NewCustomer/stageThree';
import StageFour from 'pages/SoBoPages/stageFour';
import { translate } from 'utils/index';
import { messages } from 'pages/SoBoPages/NewCustomer/messages';
import { LOGO_BASE_URL } from 'constants/general';
import { selectSoBoPages } from 'pages/SoBoPages/selectors';
import { resetState } from 'pages/SoBoPages/reducers';
import { useAppNavigate } from 'hooks/navigation';
import ROUTES from 'constants/routes';

import manWithKite from 'assets/svgs/man-with-kite.svg';
import findingsLogo from 'assets/svgs/logo-white.svg';
import './index.scss';

const NewCostumerSoPage = () => {
  const dispatch = useDispatch();
  const {
    additionalData: { logo, status },
  } = useSelector(selectSoBoPages);
  const { organizationId } = useParams();
  const [stage, setStage] = useState(NEW_CUSTOMER_SO_PAGE.STAGE_1);
  const appNavigate = useAppNavigate();
  const getData = async () => {
    await dispatch(resetState());
    await dispatch(getOrganizationDataForSoPage(organizationId));
  };

  useEffect(() => {
    getData().then();
  }, []);

  const logoPath = useMemo(() => `${LOGO_BASE_URL}${logo}`, [logo]);

  const textComponent = useMemo(() => {
    switch (stage) {
      case NEW_CUSTOMER_SO_PAGE.STAGE_1:
        return <StageOne.Description />;
      case NEW_CUSTOMER_SO_PAGE.STAGE_2:
        return <StageTwo.Description />;
      case NEW_CUSTOMER_SO_PAGE.STAGE_3:
        return <StageThree.Description />;
      default:
        return <div />;
    }
  }, [stage]);

  const cardContentComponent = useMemo(() => {
    switch (stage) {
      case NEW_CUSTOMER_SO_PAGE.STAGE_1:
        return <StageOne setStage={setStage} />;
      case NEW_CUSTOMER_SO_PAGE.STAGE_2:
        return <StageTwo setStage={setStage} />;
      case NEW_CUSTOMER_SO_PAGE.STAGE_3:
        return <StageThree setStage={setStage} />;
      default:
        return <div />;
    }
  }, [stage]);

  const onClickOkStageFour = async () => {
    await getData();
    setStage(NEW_CUSTOMER_SO_PAGE.STAGE_1);
  };

  const onClickCloseStageFour = async () => {
    await getData();
    setStage(NEW_CUSTOMER_SO_PAGE.STAGE_1);
    appNavigate(ROUTES.CUSTOMER_RECENT);
  };

  return status ? (
    <div className="so-page">
      {stage === NEW_CUSTOMER_SO_PAGE.STAGE_4 ? (
        <StageFour
          image={manWithKite}
          setStage={setStage}
          okButtonText={translate(messages.stageFourOkButton)}
          okButtonOnClick={onClickOkStageFour}
          closeButtonClick={onClickCloseStageFour}
        />
      ) : (
        <CardWithLogo
          logo={findingsLogo}
          className="so-page__card"
          leftAreaClassName="so-page__card-left-area"
          leftComponent={textComponent}
        >
          <img src={logoPath} alt="logo" width="200" height="auto" />
          {cardContentComponent}
        </CardWithLogo>
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default NewCostumerSoPage;
