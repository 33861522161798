import React from 'react';
import PropTypes from 'prop-types';

const NavigateIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M5.75 3C4.24011 3 3 4.24011 3 5.75V18.25C3 19.7599 4.24011 21 5.75 21H18.25C19.7599 21 21 19.7599 21 18.25V5.75C21 4.24011 19.7599 3 18.25 3H5.75ZM5.75 4.5H18.25C18.9491 4.5 19.5 5.05089 19.5 5.75V18.25C19.5 18.9491 18.9491 19.5 18.25 19.5H5.75C5.05089 19.5 4.5 18.9491 4.5 18.25V5.75C4.5 5.05089 5.05089 4.5 5.75 4.5ZM17.2354 5.99316C17.2082 5.99397 17.1812 5.99626 17.1543 6H11.75C11.6506 5.99859 11.5519 6.01696 11.4597 6.05402C11.3675 6.09108 11.2836 6.1461 11.2128 6.21588C11.142 6.28566 11.0858 6.36882 11.0474 6.46051C11.0091 6.5522 10.9893 6.65061 10.9893 6.75C10.9893 6.84939 11.0091 6.9478 11.0474 7.03949C11.0858 7.13118 11.142 7.21434 11.2128 7.28412C11.2836 7.3539 11.3675 7.40892 11.4597 7.44598C11.5519 7.48304 11.6506 7.50141 11.75 7.5H15.4395L7.71973 15.2197C7.64775 15.2888 7.59028 15.3716 7.5507 15.4632C7.51111 15.5548 7.4902 15.6534 7.48918 15.7532C7.48817 15.8529 7.50707 15.9519 7.54479 16.0443C7.58251 16.1367 7.63828 16.2206 7.70883 16.2912C7.77939 16.3617 7.86332 16.4175 7.9557 16.4552C8.04808 16.4929 8.14706 16.5118 8.24684 16.5108C8.34662 16.5098 8.4452 16.4889 8.53679 16.4493C8.62839 16.4097 8.71116 16.3523 8.78027 16.2803L16.5 8.56055V12.25C16.4986 12.3494 16.517 12.4481 16.554 12.5403C16.5911 12.6325 16.6461 12.7164 16.7159 12.7872C16.7857 12.858 16.8688 12.9142 16.9605 12.9526C17.0522 12.9909 17.1506 13.0107 17.25 13.0107C17.3494 13.0107 17.4478 12.9909 17.5395 12.9526C17.6312 12.9142 17.7143 12.858 17.7841 12.7872C17.8539 12.7164 17.9089 12.6325 17.946 12.5403C17.983 12.4481 18.0014 12.3494 18 12.25V6.84473C18.0148 6.73682 18.0059 6.62699 17.9739 6.52287C17.942 6.41875 17.8877 6.32283 17.815 6.24179C17.7422 6.16076 17.6527 6.09654 17.5526 6.0536C17.4525 6.01066 17.3442 5.99004 17.2354 5.99316Z"
      fill={color}
    />
  </svg>
);

NavigateIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default NavigateIcon;
