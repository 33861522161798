export const messages = {
  modalTitle: {
    key: 'EvidenceMetadataEditor.modalTitle',
    defaultMessage: 'Edit Metadata',
  },
  title: {
    key: 'EvidenceMetadataEditor.title',
    defaultMessage: 'Edit metadata for:',
  },
  save: {
    key: 'EvidenceMetadataEditor.save',
    defaultMessage: 'SAVE',
  },
  cancel: {
    key: 'EvidenceMetadataEditor.cancel',
    defaultMessage: 'CANCEL',
  },
  remove: {
    key: 'EvidenceMetadataEditor.remove',
    defaultMessage: 'REMOVE METADATA',
  },
  removeMetadataConfirmationDialogText: {
    key: 'EvidenceMetadataEditor.removeMetadataConfirmationDialogText',
    defaultMessage: 'Do you want to clear all metadata for this file?',
  },
  expiresOn: {
    key: 'EvidenceMetadataEditor.expiresOn',
    defaultMessage: 'Expires On',
  },
  issuedOn: {
    key: 'EvidenceMetadataEditor.issuedOn',
    defaultMessage: 'Issued On',
  },
  version: {
    key: 'EvidenceMetadataEditor.version',
    defaultMessage: 'Version',
  },
  issuedToName: {
    key: 'EvidenceMetadataEditor.issuedToName',
    defaultMessage: 'Issued to Name',
  },
  issuedToOrganization: {
    key: 'EvidenceMetadataEditor.issuedToOrganization',
    defaultMessage: 'Issued to Organization',
  },
  issuedToOrganizationUnit: {
    key: 'EvidenceMetadataEditor.issuedToOrganizationUnit',
    defaultMessage: 'Issued to Organization Unit',
  },
  issuedToEmail: {
    key: 'EvidenceMetadataEditor.issuedToEmail',
    defaultMessage: 'Issued to Email',
  },
  issuedByName: {
    key: 'EvidenceMetadataEditor.issuedByName',
    defaultMessage: 'Issued by Name',
  },
  issuedByOrganization: {
    key: 'EvidenceMetadataEditor.issuedByOrganization',
    defaultMessage: 'Issued by Organization',
  },
  issuedByOrganizationUnit: {
    key: 'EvidenceMetadataEditor.issuedByOrganizationUnit',
    defaultMessage: 'Issued by Organization Unit',
  },
  issuedByEmail: {
    key: 'EvidenceMetadataEditor.issuedByEmail',
    defaultMessage: 'Issued by Email',
  },
  errorLoadingMetadata: {
    key: 'EvidenceMetadataEditor.errorLoadingMetadata',
    defaultMessage: 'Failed to load metadata',
  },
};

export default {
  prefix: 'EvidenceMetadataEditor',
  messages,
};
