import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { translate } from 'utils/index';
import SecondaryButton from 'ui/SecondaryButton';
import CollabEmails from 'pages/Assessment/collabEmails';

import { setCollaborate, setCollaboratePopup, setCollaborateQuestions } from './reducers';
import { messages } from './messages';
import './shareButton.scss';

const ShareButton = ({ controlId, emailsList, 'data-test': dataTest }) => {
  const dispatch = useDispatch();

  const onCollaborate = () => {
    dispatch(setCollaborate(true));
    dispatch(setCollaborateQuestions([controlId]));
    dispatch(setCollaboratePopup(true));
  };

  return (
    <SecondaryButton
      data-test={dataTest}
      type="link"
      className="assessment-share-button"
      onClick={onCollaborate}
      icon={emailsList.length === 0 ? 'share' : undefined}
      iconComponent={emailsList.length > 0 ? <CollabEmails emailsList={emailsList} /> : undefined}
      iconComponentWidth={40}
      tooltip={translate(messages.share)}
    />
  );
};

ShareButton.propTypes = {
  controlId: PropTypes.string,
  emailsList: PropTypes.array,
  'data-test': PropTypes.string,
};

export default ShareButton;
