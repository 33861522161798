export const messages = {
  oldPasswordLabel: {
    key: 'New Password Form.oldPasswordLabel',
    defaultMessage: 'Current password',
  },
  newPasswordLabel: {
    key: 'New Password Form.newPasswordLabel',
    defaultMessage: 'New password',
  },
  passwordPlaceholder: {
    key: 'New Password Form.passwordPlaceholder',
    defaultMessage: 'Enter Password',
  },
  confirmPasswordLabel: {
    key: 'New Password Form.confirmPasswordLabel',
    defaultMessage: 'Confirm Password',
  },
  submitButton: {
    key: 'New Password Form.submitButton',
    defaultMessage: 'Reset password',
  },
  passwordLengthValidation: {
    key: 'New Password Form.passwordLengthValidation',
    defaultMessage: 'Password should contain at least 8 characters',
  },
  passwordSpecialCharsQtyValidation: {
    key: 'New Password Form.passwordSpecialCharsQtyValidation',
    defaultMessage: 'Password should contain at least 1 special character',
  },
  passwordNumbersQtyValidation: {
    key: 'New Password Form.passwordNumbersQtyValidation',
    defaultMessage: 'Password should contain at least 1 number',
  },
  passwordUpperValidation: {
    key: 'New Password Form.passwordUpperValidation',
    defaultMessage: 'Password should contain at least 1 Uppercase letter',
  },
  passwordLowerValidation: {
    key: 'New Password Form.passwordLowerValidation',
    defaultMessage: 'Password should contain at least 1 Lowercase letter',
  },
  passwordRequired: {
    key: 'New Password Form.passwordRequired',
    defaultMessage: 'Required field',
  },
  passwordConfirmationMatch: {
    key: 'New Password Form.passwordConfirmationMatch',
    defaultMessage: 'Password don’t match',
  },
  passwordConfirmationRequired: {
    key: 'New Password Form.passwordConfirmationRequired',
    defaultMessage: 'Passwords confirmation is required',
  },
  termsAndConditionsLabel1: {
    key: 'New Password Form.termsAndConditionsLabel1',
    defaultMessage: 'I agree to',
  },
  termsAndConditionsLabel2: {
    key: 'New Password Form.termsAndConditionsLabel2',
    defaultMessage: 'Findings Terms and Conditions',
  },
  termsAndConditionsCheck: {
    key: 'New Password Form.termsAndConditionsCheck',
    defaultMessage: 'You must accept the term & conditions ',
  },
  passwordRulesBlock: {
    key: 'New Password Form.passwordRulesBlock',
    defaultMessage:
      'New password should contain at least:\n' +
      '8 Characters \n' +
      '1 Special Character \n' +
      '1 Number \n' +
      '1 Uppercase letter \n' +
      '1 Lowercase letter',
  },
};

export default {
  prefix: 'New Password Form',
  messages,
};
