export class AssessmentMode {
  static StartAssessment = 0;
  static ResumeAssessment = 1;
  static ReviewAndFinalizeLocked = 2;
  static ReviewAndFinalizeWizard = 3;
  static ReviewAndFinalizeWizardEditable = 4;
  static ReviewAndFinalizeDisabled = 5;
  static MarkAsReviewed = 6;
  static ReviewAssessment = 7;
  static MarkAsReviewedDisabled = 8;
  static ShowCase = 9;
}
