export const messages = {
  addFilter: {
    key: 'addFilters.addFilter',
    defaultMessage: 'Add filter',
  },
  vendorFilter: {
    key: 'addFilters.vendorFilter',
    defaultMessage: 'Vendor',
  },
  customerFilter: {
    key: 'addFilters.customerFilter',
    defaultMessage: 'Customer',
  },
  assessmentTypeFilter: {
    key: 'addFilters.assessmentTypeFilter',
    defaultMessage: 'Type',
  },
  statusFilter: {
    key: 'addFilters.statusFilter',
    defaultMessage: 'Status',
  },
  parentOrganizationFilter: {
    key: 'addFilters.parentOrganizationFilter',
    defaultMessage: 'Organization',
  },
  groupFilter: {
    key: 'addFilters.groupFilter',
    defaultMessage: 'Group',
  },
  vendorStatusFilter: {
    key: 'addFilters.vendorStatusFilter',
    defaultMessage: 'Vendor status',
  },
  assessmentsFilter: {
    key: 'addFilters.assessmentsFilter',
    defaultMessage: 'Assessments',
  },
  subjectsFilter: {
    key: 'addFilters.subjectsFilter',
    defaultMessage: 'Subjects',
  },
  riskFilter: {
    key: 'addFilters.riskFilter',
    defaultMessage: 'Risk',
  },
  userFilter: {
    key: 'addFilters.userFilter',
    defaultMessage: 'User',
  },
  assessmentFilter: {
    key: 'addFilters.assessmentFilter',
    defaultMessage: 'Assessment',
  },
  questionFindingFilter: {
    key: 'addFilters.questionFindingFilter',
    defaultMessage: 'Question/Finding',
  },
  messageFilter: {
    key: 'addFilters.messageFilter',
    defaultMessage: 'Message',
  },
  subjectFilter: {
    key: 'addFilters.subjectFilter',
    defaultMessage: 'Subject',
  },
  notStarted: {
    key: 'addFilters.notStarted',
    defaultMessage: 'Not started',
  },
  completed: {
    key: 'addFilters.completed',
    defaultMessage: 'Completed',
  },
  inProgress: {
    key: 'addFilters.inProgress',
    defaultMessage: 'In progress',
  },
  pendingRequest: {
    key: 'addFilters.pendingRequest',
    defaultMessage: 'Pending request',
  },
  assessmentInProgress: {
    key: 'addFilters.assessmentInProgress',
    defaultMessage: 'Assessment in progress',
  },
  BORequestNotApproved: {
    key: 'addFilters.BORequestNotApproved',
    defaultMessage: 'BO request Not approved',
  },
  securityTeamApproved: {
    key: 'addFilters.securityTeamApproved',
    defaultMessage: 'Security team - Approved',
  },
  securityTeamDeclined: {
    key: 'addFilters.securityTeamDeclined',
    defaultMessage: 'Security team - Declined',
  },
  open: {
    key: 'addFilters.open',
    defaultMessage: 'Open',
  },
  closed: {
    key: 'addFilters.closed',
    defaultMessage: 'Closed',
  },
  pending: {
    key: 'addFilters.pending',
    defaultMessage: 'Pending',
  },
  filterPlaceholder: {
    key: 'addFilters.filterPlaceholder',
    defaultMessage: 'Select value',
  },
  myFilesFormatFilter: {
    key: 'addFilters.myFilesFormatFilter',
    defaultMessage: 'Format',
  },
  myFilesExpirationFilter: {
    key: 'addFilters.myFilesExpirationFilter',
    defaultMessage: 'Expiration',
  },
  all: {
    key: 'addFilters.all',
    defaultMessage: 'All',
  },
  docx: {
    key: 'addFilters.docx',
    defaultMessage: 'docx',
  },
  doc: {
    key: 'addFilters.doc',
    defaultMessage: 'doc',
  },
  xlsx: {
    key: 'addFilters.xlsx',
    defaultMessage: 'xlsx',
  },
  pdf: {
    key: 'addFilters.pdf',
    defaultMessage: 'pdf',
  },
  csv: {
    key: 'addFilters.csv',
    defaultMessage: 'csv',
  },
  jpg: {
    key: 'addFilters.jpg',
    defaultMessage: 'jpg',
  },
  jpeg: {
    key: 'addFilters.jpeg',
    defaultMessage: 'jpeg',
  },
  png: {
    key: 'addFilters.png',
    defaultMessage: 'png',
  },

  valid: {
    key: 'addFilters.valid',
    defaultMessage: 'Valid',
  },
  expiredSoon: {
    key: 'addFilters.expiredSoon',
    defaultMessage: 'Expiring soon',
  },
  expired: {
    key: 'addFilters.expired',
    defaultMessage: 'Expired',
  },
};

export default {
  prefix: 'addFilters',
  messages,
};
