import { createSelector } from '@reduxjs/toolkit';
import { Apps } from 'constants/apps';

import QualysIcon from 'assets/svgs/qualys-icon.svg';
import SSCIcon from 'assets/svgs/ssc-icon.svg';
import IBMIcon from 'assets/svgs/ibm-x-force-icon.svg';
import SbomIcon from 'assets/svgs/sbom-icon.svg';
import CloudhawkIcon from 'assets/svgs/cloudhawk-icon.svg';
import AzureIcon from 'assets/svgs/azure-icon.svg';
import AwsIcon from 'assets/svgs/aws-icon.svg';
import RiskReconIcon from 'assets/svgs/risk-recon-icon.svg';
import FindingsCloudMonitoringIcon from 'assets/svgs/findings-cloud-monitoring-icon.svg';

const icons = {
  [Apps.ssc]: {
    icon: SSCIcon,
  },
  [Apps.cloudhawk]: {
    icon: CloudhawkIcon,
  },
  [Apps.ibm]: {
    icon: IBMIcon,
  },
  [Apps.qualys]: {
    icon: QualysIcon,
  },
  [Apps.sbom]: {
    icon: SbomIcon,
  },
  [Apps.riskRecon]: {
    icon: RiskReconIcon,
  },
  [Apps.azure]: {
    icon: AzureIcon,
  },
  [Apps.aws]: {
    icon: AwsIcon,
  },
  [Apps.cloudMonitoring]: {
    icon: FindingsCloudMonitoringIcon,
  },
};

export const selectOrganizationsExtended = createSelector(
  ({ organizations }) => organizations,
  (state) => state,
);

export const selectDefaultApps = createSelector(
  ({ organizations }) => organizations.defaultApps,
  (defaultApps) => defaultApps,
);
export const selectApps = createSelector(
  ({ organizations }) => organizations.apps,
  (apps) => apps,
);

export const selectAllOrgApps = createSelector(
  ({ organizations }) => organizations.defaultApps.concat(organizations.apps),
  (apps) => apps.map((app) => ({ ...app, ...icons[app.id] })),
);

export const selectAppsLoading = createSelector(
  ({ organizations }) => organizations.appsLoading,
  (loading) => loading,
);

export const selectSbomFileLoading = createSelector(
  ({ organizations }) => organizations.sbomFileLoading,
  (sbomFileLoading) => sbomFileLoading,
);
export const selectIsViewAggregated = createSelector(
  ({ organizations }) => organizations.organizations.some((org) => org.sub_organization),
  (defaultApps) => defaultApps,
);

export const selectAggregatedOrganizations = createSelector(
  (state) => {
    return state.organizations?.organizations.filter(
      (org) => org.sub_organization || org.id === state.profile?.user?.current_organization?.id,
    );
  },
  (defaultApps) => defaultApps,
);

export const selectSecuritySettings = createSelector(
  ({ organizations }) => organizations,
  ({ enforce2fa, strengthenAuthentication }) => ({ enforce2fa, strengthenAuthentication }),
);
