import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Alert as AntAlert } from 'antd';
import Icon from 'ui/Icon';
import './index.scss';

const Alert = ({ className, ...props }) => {
  const getIcon = () => {
    switch (props.type) {
      case 'success':
        return <Icon icon="ok2" />;
      case 'warning':
      case 'error':
        return <Icon icon="exclamation" />;
      default:
        return null;
    }
  };

  return <AntAlert className={classNames('ui-alert', className)} icon={getIcon()} {...props} />;
};

Alert.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

export default Alert;
