export const messages = {
  authMsgEmail: {
    key: 'Login.authMsgEmail',
    defaultMessage:
      "We've sent a code to <strong>{{email}}</strong>.<br /> Please enter the code below.",
  },
  authMsgSms: {
    key: 'Login.authMsgSms',
    defaultMessage:
      "We've sent a code to <strong>{{phone}}</strong>.<br /> Please enter the code below.",
  },
  didntReceiveEmail: {
    key: 'Login.didntReceiveEmail',
    defaultMessage:
      "Didn't receive the email? Try searching your inbox for <strong>'your security token is'</strong>, or go back to resend the code.",
  },
  yourAdminRequires2Fa: {
    key: 'Login.yourAdminRequires2Fa',
    defaultMessage: 'Your admin requires 2FA.',
  },
  pleaseSelectYourPreferred: {
    key: 'Login.pleaseSelectYourPreferred',
    defaultMessage: 'Please select your preferred method:',
  },
  didntReceiveCode: {
    key: 'Login.didntReceiveCode',
    defaultMessage: "Didn't receive the code?",
  },
  resendViaEmail: {
    key: 'Login.resendViaEmail',
    defaultMessage: 'RESEND VIA EMAIL',
  },
  didNotReceiveEmail: {
    key: 'Login.didNotReceiveEmail',
    defaultMessage: 'Didn’t receive the email?',
  },
  emailErrorFormat: {
    key: 'Login.emailErrorFormat',
    defaultMessage: 'Email format is invalid',
  },
  emailInputLabel: {
    key: 'Login.emailInputLabel',
    defaultMessage: 'Work Email',
  },
  forgotPasswordLinkTitle: {
    key: 'Login.forgotPasswordLinkTitle',
    defaultMessage: 'Forgot your password?',
  },
  forgotPasswordSubtitle: {
    key: 'Login.forgotPasswordSubtitle',
    defaultMessage: 'Please enter your work email address associate with your account',
  },
  forgotPasswordSuccessMessage: {
    key: 'Login.forgotPasswordSuccessMessage',
    defaultMessage: 'Password reset instructions have been emailed',
  },
  forgotPasswordSuccessMessageTitle: {
    key: 'Login.forgotPasswordSuccessMessageTitle',
    defaultMessage: 'Password has been reset',
  },
  forgotPasswordTitle: {
    key: 'Login.forgotPasswordTitle',
    defaultMessage: 'Forgot password?',
  },
  invalidOtpCode: {
    key: 'Login.invalidOtpCode',
    defaultMessage: 'Invalid authentication code',
  },
  inputPassword: {
    key: 'Login.inputPassword',
    defaultMessage: 'Enter Password',
  },
  labelPassword: {
    key: 'Login.labelPassword',
    defaultMessage: 'Password',
  },
  loginButton: {
    key: 'Login.loginButton',
    defaultMessage: 'Log In',
  },
  loginTitle: {
    key: 'Login.loginTitle',
    defaultMessage: 'Log in to continue',
  },
  requiredEmail: {
    key: 'Login.requiredEmail',
    defaultMessage: 'Email is required',
  },
  requiredPassword: {
    key: 'Login.requiredPassword',
    defaultMessage: 'Password is Required',
  },
  resend: {
    key: 'Login.resend',
    defaultMessage: 'Resend',
  },
  secondAuthenticationTitle: {
    key: 'Login.secondAuthenticationTitle',
    defaultMessage: 'Enter verification code',
  },
  welcome: {
    key: 'Login.welcome',
    defaultMessage: 'Welcome to Findings!',
  },
  loginOrHrText: {
    key: 'Login.loginOrHrText',
    defaultMessage: 'OR',
  },
};

export default {
  prefix: 'Login',
  messages,
};
