import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// COMPONENTS
import Text from 'ui/Text';
import Button from 'ui/Button';
import OrganizationLogo from 'ui/OrganizationLogo';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Showcases/showcasePreview/messages';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';

// ASSETS
import { ReactComponent as ArrowLeft } from 'assets/svgs/arrowLeft.svg';
import { trimMessage } from 'components/Messenger/lib';
import { selectShowCasePreview } from 'pages/Showcases/showcasePreview/selectors';
import { selectProfile } from 'pages/Profile/selectors';
import DueDate from 'pages/Assessment/dueDate';
import { selectAssessment } from 'pages/Assessment/selectors';
import Divider from 'ui/Divider';

function PreviewHeader() {
  const { title, description, sendTo, sendFrom } = useSelector(selectShowCasePreview);
  const [readMore, setReadMore] = useState(false);
  const navigate = useNavigate();
  const { user } = useSelector(selectProfile);
  const { due } = useSelector(selectAssessment);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const trimmedDescription = useMemo(() => {
    const trimmed = trimMessage(description);
    return trimmed;
  }, [description]);

  const onClickReadMore = useCallback(() => {
    setReadMore((prevState) => !prevState);
  }, [readMore]);

  return (
    <div className="showcase-preview__header">
      <div className="showcase-preview__header-left">
        <div className="showcase-preview__header-arrow-back" onClick={() => navigate(-1)}>
          <ArrowLeft width={15} height={12} />{' '}
        </div>
        <div className="showcase-preview__header-content">
          <Text level={1} color="text">
            {title}
          </Text>
          <div className="showcase-preview__header-description">
            {readMore ? description : trimmedDescription}
          </div>
          {description.length > 150 && !readMore && (
            <Button link className="showcase-preview__header-read-more" onClick={onClickReadMore}>
              {translate(messages.readMore)}
            </Button>
          )}
          <div className="showcase-preview__header-info">
            <div className="showcase-preview__header-info-item">
              <DueDate due={due} />
              <Divider vertical />
              {isMyVendorsTabSelected ? (
                <>
                  <div className="showcase-preview__header-info-label">
                    {translate(messages.vendor)}:
                  </div>
                  {sendFrom}
                </>
              ) : (
                <>
                  <div className="showcase-preview__header-info-label">
                    {translate(messages.customer)}:
                  </div>
                  {sendTo}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="showcase-preview__header-right">
        {user?.corporateLogo && <OrganizationLogo src={user?.corporateLogo} size="md" />}
      </div>
    </div>
  );
}

export default PreviewHeader;
