import React from 'react';
import Spreadsheet from 'react-spreadsheet';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

const ExcelSheet = ({ sheetData, className, onSelect }) => {
  // sheetData should be a matrix
  const excelSheetClasses = classNames('ui-excel-sheet', className);

  return (
    <div className={excelSheetClasses}>
      <Spreadsheet data={sheetData} onSelect={onSelect} />
    </div>
  );
};

ExcelSheet.propTypes = {
  sheetData: PropTypes.array,
  className: PropTypes.string,
  onSelect: PropTypes.func,
};

export default ExcelSheet;
