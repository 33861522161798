import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Button as AntButton } from 'antd';

import { ReactComponent as SendIcon } from 'assets/svgs/send-ico.svg';
import Button from 'ui/Button';
import Loader from 'ui/Loader';
import Icon from 'ui/Icon';
import TextArea from 'ui/TextArea';
import { translate } from 'utils/index';

import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import useChat from './useChat';
import { setThreadAsRead } from './reducers';
import { messages } from './messages';
import { MAX_CHAT_MESSAGE_LENGTH } from 'components/Chat/lib';
import { mixpanelTrackChatSent } from 'utils/mixpanel';
import './index.scss';

const Chat = ({
  className,
  close,
  disabled,
  threadId,
  threadTitle,
  controlId,
  findingId,
  recommendationId,
  categoryId,
  newMessagePlaceholder,
  assessmentId,
  source,
  relation,
}) => {
  const [message, setMessage] = useState('');
  const chatRef = useRef(null);
  const [isToLongMessageError, setIsToLongMessageError] = useState('');
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { chatThread, sendMessage, loading } = useChat({
    threadId,
    chatRef,
    threadTitle,
    controlId,
    findingId,
    recommendationId,
    categoryId,
    assessmentId,
  });
  const onChange = (event) => {
    if (event.target.value.length >= MAX_CHAT_MESSAGE_LENGTH) {
      setIsToLongMessageError(translate(messages.toLongMessageError));
    }

    if (isToLongMessageError && event.target.value.length < MAX_CHAT_MESSAGE_LENGTH) {
      setIsToLongMessageError('');
    }
    setMessage(event.target.value);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setThreadAsRead({ threadId }));
    chatRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!message) {
      return;
    }
    setMessage('');
    await sendMessage(message);

    mixpanelTrackChatSent({
      chat: message,
      streamDirection: isMyVendorsTabSelected ? 'downstream' : 'upstream',
      source,
      relation,
    });

    chatRef.current.scroll({
      top: chatRef.current.scrollHeight,
      left: 0,
    });
  };

  return (
    <div className={classnames('chat', className)} id="chat">
      <div className="chat__container-wrapper" ref={chatRef}>
        {chatThread.map(([date, thread], key) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="chat-container" key={key}>
            <hr className="chat-container__divider" />
            <div className="chat-container__date">{date}</div>
            <div className="chat-container__thread">
              {thread.map(
                ({
                  msg,
                  id,
                  self,
                  timeDifferenceRepresentation,
                  profileFullname,
                  profileEmail,
                }) => {
                  const direction = `chat-bubble--${self ? 'me' : 'you'}`;
                  const msgs = msg.split('\n');

                  return (
                    <>
                      <div className={classnames('chat-bubble', direction)} key={id}>
                        <span className="chat-bubble__author">
                          {profileFullname || profileEmail}
                        </span>
                        <div className="chat-bubble__content">
                          <span className="chat-bubble__msg">
                            {msgs?.map((msg, index) => {
                              return (
                                <>
                                  {msg}
                                  {index < msgs.length - 1 && <br />}
                                </>
                              );
                            })}
                          </span>
                        </div>
                        <span className="chat-bubble__time">{timeDifferenceRepresentation}</span>
                      </div>
                    </>
                  );
                },
              )}
            </div>
          </div>
        ))}
        {loading && (
          <div className="chat__loader">
            <Loader />
          </div>
        )}
      </div>
      <form className="chat-input" onSubmit={onSubmit}>
        <TextArea
          error={isToLongMessageError}
          maxLength={MAX_CHAT_MESSAGE_LENGTH}
          autoResize={true}
          data-test="chat-input"
          className="chat-input__input"
          disabled={disabled}
          placeholder={
            newMessagePlaceholder ?? translate(messages.newMessageInputDefaultPlaceholder)
          }
          onChange={onChange}
          size="medium"
          value={message}
        />
        <Button
          data-test="chat-input-button-send"
          className="chat-input__send-button"
          disabled={!message || disabled || isToLongMessageError}
          onClick={onSubmit}
          fluid
          link
        >
          <SendIcon className="chat-input__send-icon" width={35} height={35} />
        </Button>
      </form>
      <AntButton className="chat__close-button" type="link" onClick={close}>
        <Icon icon="close" />
      </AntButton>
    </div>
  );
};

Chat.propTypes = {
  className: PropTypes.string,
  close: PropTypes.func,
  disabled: PropTypes.bool,
  threadId: PropTypes.string,
  threadTitle: PropTypes.string,
  categoryId: PropTypes.string,
  controlId: PropTypes.string,
  findingId: PropTypes.string,
  recommendationId: PropTypes.string,
  newMessagePlaceholder: PropTypes.string,
  assessmentId: PropTypes.string,
  source: PropTypes.string,
  relation: PropTypes.string,
};

export default Chat;
