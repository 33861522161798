import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import Icon from 'ui/Icon';
import { translate } from 'utils/index';
import { selectAssessment } from 'pages/Assessment/selectors';
import { messages } from './messages';
import './vendorDueDate.scss';

const VendorDueDate = ({ isScrolled }) => {
  const { due } = useSelector(selectAssessment);
  const days = moment(due).diff(moment(), 'days');
  const { pathname } = useLocation();
  const isPreview = pathname.includes('preview');

  const renderDueDate = () => {
    if (isPreview) {
      return `30 ${translate(messages.days)}`;
    }

    if (days === 0) {
      return translate(messages.today);
    }

    if (days !== 0) {
      return `${moment(due).diff(moment(), 'days')} ${translate(messages.days)}`;
    }
  };

  return (
    <div
      className={classNames('assessment-vendor-due-date', {
        'assessment-vendor-due-date--today': days < 1,
        'due-date--scrolled': isScrolled,
      })}
    >
      <Icon className="assessment-vendor-due-date__icon" icon="clock" />
      <div className="assessment-vendor-due-date__right">
        <div className="assessment-vendor-due-date__label">{translate(messages.dueIn)}</div>
        <div className="assessment-vendor-due-date__time">{renderDueDate()}</div>
      </div>
    </div>
  );
};

VendorDueDate.propTypes = {
  isScrolled: PropTypes.bool,
};

export default VendorDueDate;
