import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { translate } from 'utils/index';

import Filter from 'ui/Filter';
import { removeFilter, setFilter } from 'pages/MyFiles/reducers';
import { messages } from './messages';
import { myFilesExpirationStatuses, myFilesFormats } from 'components/AddFilterButton/lib';
import './myFilesAddedFilters.scss';

const { Option } = Select;

const MyFilesAddedFilters = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => {
    return state.myFiles.filters;
  });

  if (filters.length === 0) {
    return null;
  }

  const renderStatus = (statusTypes) => {
    return statusTypes.map(({ label, value }) => (
      <Option key={value} value={translate(label)} id={value}>
        {translate(label)}
      </Option>
    ));
  };

  const onChange = (value, id, type) => {
    dispatch(setFilter({ type, value, id: id }));
  };

  const onRemove = (fetchData, type) => {
    dispatch(removeFilter({ type }));
  };

  return (
    <div className="my-files-added-filters">
      {filters.map((filter) => {
        const generalProps = {
          key: filter.type,
          filter,
          fetchData: () => {},
          onChange,
          onRemove: () => onRemove(() => {}, filter.type),
        };
        switch (filter.type) {
          case 'type':
            return (
              <Filter {...generalProps} innerLabel={translate(messages.formatType)}>
                {renderStatus(myFilesFormats)}
              </Filter>
            );
          case 'expiration':
            return (
              <Filter {...generalProps} innerLabel={translate(messages.expiration)}>
                {renderStatus(myFilesExpirationStatuses)}
              </Filter>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

MyFilesAddedFilters.propTypes = {
  callback: PropTypes.func,
  className: PropTypes.string,
};

export default MyFilesAddedFilters;
