import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';

import colors from 'ui/colors.scss';
import { translate } from 'utils/index';
import { messages } from '../../messages';

import './index.scss';

const ScoreCircularChart = ({ chart }) => {
  const { data } = chart;

  const mappedData = useMemo(() => {
    const chartData = {
      color: colors.gray200,
      text: translate(messages.scoreCircularChartEmpty),
    };

    if (data?.score >= 0 && data?.score <= 33) {
      chartData.color = colors.red500;
      chartData.text = translate(messages.scoreCircularChartHigh);
    } else if (data?.score > 33 && data?.score <= 66) {
      chartData.color = colors.orange300;
      chartData.text = translate(messages.scoreCircularChartMedium);
    } else if (data?.score > 66 && data?.score <= 100) {
      chartData.color = colors.green400;
      chartData.text = translate(messages.scoreCircularChartLow);
    }

    return chartData;
  }, [data?.score]);

  return (
    <div className="dashboard__chart__score-circular-chart">
      <Progress
        type="circle"
        percent={data?.score || 0}
        strokeColor={mappedData.color}
        strokeWidth={6}
        width={150}
        trailColor={colors.gray200}
        format={() => {
          return (
            <div className="dashboard__chart__score-sircular-chart__content">
              {data ? <h5>{data.score || 0}</h5> : null}
              <p>{mappedData.text}</p>
            </div>
          );
        }}
      />
    </div>
  );
};

ScoreCircularChart.propTypes = {
  chart: PropTypes.object,
};

export default ScoreCircularChart;
