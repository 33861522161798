import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';

import { getCustomersGroups } from 'api/customersGroup';
import Filter from 'ui/Filter';
import { translate } from 'utils/index';
import { messages } from 'components/AddFilterButton/messages';
import { selectGroups, selectGroupsLoading } from './selectors';
import './index.scss';

const { Option } = Select;

const FilterGroup = ({ fetchData, filter, ...otherProps }) => {
  const loading = useSelector(selectGroupsLoading);
  const groups = useSelector(selectGroups);
  const dispatch = useDispatch();

  useEffect(() => {
    if (groups.length === 0) {
      dispatch(getCustomersGroups());
    }
  }, []);

  return (
    <Filter
      filter={filter}
      fetchData={fetchData}
      loading={loading}
      innerLabel={translate(messages.groupFilter)}
      {...otherProps}
    >
      {groups.map(({ id, label }) => (
        <Option key={id} value={label} id={id}>
          {label}
        </Option>
      ))}
    </Filter>
  );
};

FilterGroup.propTypes = {
  fetchData: PropTypes.func,
  filter: PropTypes.object,
};

export default FilterGroup;
