import { parseTimestampToDateString } from 'utils/parseTimestampToDateString';
import { OrgStatusType } from './orgStatusType';

export const parseResponseToTrustedOrg = (trustRelations) => {
  const { established, pending, waiting_approval: waitingApproval, rejected } = trustRelations;

  const relationsArray = [];

  established.forEach(({ id, timestampCreated, email, corporateName, corporateLogo }) => {
    relationsArray.push({
      key: id,
      name: corporateName,
      email,
      date: parseTimestampToDateString(timestampCreated),
      status: OrgStatusType.ESTABLISHED,
      logo: corporateLogo,
    });
  });

  pending.forEach(({ id, timestampCreated, email, corporateName, corporateLogo }) => {
    relationsArray.push({
      key: id,
      name: corporateName,
      email,
      date: parseTimestampToDateString(timestampCreated),
      status: OrgStatusType.PENDING,
      logo: corporateLogo,
    });
  });

  waitingApproval.forEach(({ id, timestampCreated, email, corporateName, corporateLogo }) => {
    relationsArray.push({
      key: id,
      name: corporateName,
      email,
      date: parseTimestampToDateString(timestampCreated),
      status: OrgStatusType.WAITING_APPROVAL,
      logo: corporateLogo,
    });
  });

  rejected.forEach(({ id, timestampCreated, email, corporateName, corporateLogo }) => {
    relationsArray.push({
      key: id,
      name: corporateName,
      email,
      date: parseTimestampToDateString(timestampCreated),
      status: OrgStatusType.REJECTED,
      logo: corporateLogo,
    });
  });

  return relationsArray.sort((a, b) => new Date(b.date) - new Date(a.date));
};
