import { createSelector } from '@reduxjs/toolkit';

export const selectUnreadMessages = createSelector(
  ({ unreadMessages }) => unreadMessages,
  (state) => state,
);

export const selectAllMessages = createSelector(
  ({ allMessages }) => allMessages,
  (state) => state,
);

export const selectActiveFilters = createSelector(
  ({ allMessages }) => allMessages,
  ({ filters }) => filters,
);

export const selectAllAssessments = createSelector(
  ({ allMessages }) => allMessages,
  ({ allAssessments }) => allAssessments,
);

export const selectAllParticipants = createSelector(
  ({ allMessages }) => allMessages,
  ({ allParticipants }) => allParticipants,
);
