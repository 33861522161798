import { createSelector } from '@reduxjs/toolkit';
import { stripHtml } from 'utils/html';
import { getAssessmentMode } from 'utils/getAssessmentMode';
import { AssessmentMode } from 'constants/assessmentMode';
import { ControlType } from 'constants/controlType';
import { Apps } from 'constants/apps';

export const selectAssessment = createSelector(
  ({ assessment }) => assessment?.assessment,
  (assessment) => assessment || {},
);

export const selectCategory = createSelector(
  ({ assessment }) => assessment?.assessment?.categories,
  ({ assessment }) => assessment.selectedCategory,
  (categories, selectedCategory) => {
    const category = (categories || []).find(({ id }) => selectedCategory === id);
    return category || {};
  },
);

export const selectFirstUnansweredControl = createSelector(selectCategory, (category) => {
  return (category.controls || [])
    .filter(
      (control) => control.enabled && control.type !== ControlType.EmptySpace && !control.optional,
    )
    .find((control) => control.answer === '' || control.answer === 0);
});

export const selectCategories = createSelector(
  ({ assessment }) => assessment?.assessment?.categories,
  (categories) => {
    return (categories || []).filter(
      (category) => category.controls.filter((control) => control.enabled)?.length,
    );
  },
);

export const selectCategoryKey = createSelector(
  selectCategories,
  ({ assessment }) => assessment.selectedCategory,
  (categories, selectedCategory) => {
    const categoryIndex = (categories || []).findIndex(({ id }) => selectedCategory === id);

    if (categoryIndex !== undefined) {
      return categoryIndex + 1;
    }

    return 0;
  },
);

export const selectAnswerTypes = createSelector(
  ({ assessment }) => assessment.assessment.answer_types,
  (answerTypes) => answerTypes,
);

export const selectCategoryNavigation = createSelector(
  selectCategories,
  ({ assessment }) => assessment.selectedCategory,
  (categories, selectedCategory) => {
    const categoryIndex = (categories || []).findIndex(({ id }) => selectedCategory === id);
    if (categoryIndex === undefined || !categories) {
      return {
        prev: null,
        next: null,
      };
    }
    const previousCategory = categories[categoryIndex - 1];
    const nextCategory = categories[categoryIndex + 1];

    return {
      prev: previousCategory || null,
      next: nextCategory || null,
    };
  },
);

export const selectFileLoading = createSelector(
  ({ assessment }) => assessment?.fileLoading,
  ({ assessment }) => assessment?.fileRemoveLoading,
  (fileLoading, fileRemoveLoading) => fileLoading || fileRemoveLoading,
);

export const selectIsAssessmentLoading = createSelector(
  ({ assessment }) => assessment?.loadingAssessment,
  (loading) => loading,
);

export const selectControls = (assessment) => {
  return assessment.categories
    .filter((category) => category.controls.filter((control) => control.enabled)?.length)
    .map((category) => ({
      category: category.title,
      options: category.controls
        .filter((control) => control.enabled && !control.apps?.includes(Apps.sbom))
        .map((control) => ({
          label: stripHtml(control.title),
          value: control.id,
          apps: control.apps,
        })),
    }));
};

export const selectAllControlsByCategory = createSelector(
  ({ assessment }) => assessment?.assessment,
  selectControls,
);

export const selectIsAssessmentDisabled = createSelector(selectAssessment, (assessment) => {
  const assessmentMode = getAssessmentMode({
    ...assessment,
    progress: assessment.total_score?.progress || 0,
  });

  return [
    AssessmentMode.ReviewAndFinalizeWizard,
    AssessmentMode.ReviewAndFinalizeDisabled,
    AssessmentMode.MarkAsReviewedDisabled,
    AssessmentMode.ReviewAssessment,
  ].includes(assessmentMode);
});

export const selectAllControls = createSelector(
  ({ assessment }) => assessment?.assessment?.categories || [],
  (categories) => categories.flatMap((category) => category.controls),
);

export const selectCategoryControls = createSelector(
  selectCategory,
  (category) => category.controls || [],
);

export const selectControlsWithSuggestions = (controls) => {
  const enabledControls = controls.filter(({ enabled }) => enabled);

  return enabledControls.filter(
    ({ suggest, answer }) =>
      suggest &&
      (Array.isArray(suggest)
        ? JSON.stringify(suggest) !== JSON.stringify(answer)
        : suggest !== answer),
  );
};

export const selectAllControlsWithUnAppliedSuggestion = createSelector(
  selectAllControls,
  selectControlsWithSuggestions,
);

export const selectControlsWithUnAppliedSuggestion = createSelector(
  selectCategoryControls,
  selectControlsWithSuggestions,
);

export const selectAssessmentErrors = createSelector(
  ({ assessment: { errors } }) => errors,
  (errors) => errors,
);

export const selectIsCloudMonitoring = createSelector(selectCategories, (categories) => {
  return categories
    .flatMap(({ controls }) => controls)
    .some(
      (control) =>
        control.apps && (control.apps.includes(Apps.aws) || control.apps.includes(Apps.azure)),
    );
});

export const selectIsCloudMonitoringTriggered = createSelector(selectCategories, (categories) => {
  return categories.some((category) => [Apps.aws, Apps.azure].includes(category.app));
});

export const selectIsCloudMonitoringResults = createSelector(selectCategories, (categories) => {
  return categories.some(
    (category) =>
      [Apps.aws, Apps.azure].includes(category.app) &&
      category.controls.some((control) => control.answer),
  );
});

export const selectIsCloudMonitoringReviewed = createSelector(selectCategories, (categories) => {
  return !categories.some(
    (category) => [Apps.aws, Apps.azure].includes(category.app) && !category.reviewed,
  );
});

export const selectCloudMonitoringResultCategory = createSelector(
  selectCategories,
  (categories) => {
    return categories.find((category) => [Apps.aws, Apps.azure].includes(category.app)) || {};
  },
);
