import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';

import emptyLogo from 'assets/svgs/emptyChart-doughnut.svg';
import { chartTheme } from 'pages/Dashboard/lib';
import colors from 'ui/colors.scss';
import { translate } from 'utils/index';
import CustomNivoChartTooltip from '../customNivoChartTooltip';
import { getNavPathWithFilters, useAppNavigate, useGetPath } from 'hooks/navigation';
import ROUTES from 'constants/routes';
import { FILTERABLE_KEYS, filtersMetadata } from 'components/Filters/lib';
import { useSelector } from 'react-redux';

import './index.scss';

const DoubleDoughnutChart = ({ chart }) => {
  const { data } = chart;
  const appNavigate = useAppNavigate();
  const getPath = useGetPath();
  const { filtersToApi } = useSelector(
    (state) => state.filters.allFilters[FILTERABLE_KEYS.DASHBOARD_FILTERS],
  );

  const leftDoughnutMappedData = useMemo(() => {
    return data?.doughnutLeft?.dataset?.map((item) => ({
      ...item,
      label: translate({ key: item.id, defaultMessage: item.label }),
    }));
  }, [data?.doughnutLeft]);

  const rightDoughnutMappedData = useMemo(() => {
    return data?.doughnutRight?.dataset?.map((item) => ({
      ...item,
      label: translate({ key: item.id, defaultMessage: item.label }),
    }));
  }, [data?.doughnutRight]);

  const handleClick = (clickData) => {
    if (!ROUTES[data.navRoute] || !data?.filterKey || !clickData) return;
    const isMultipleKeys = filtersMetadata[data.filterKey].multipleKeys;
    const selected = [
      clickData.id === clickData.label
        ? clickData.id
        : { key: clickData.id, value: clickData.label },
    ];

    const filters = {
      ...filtersToApi,
      [data.filterKey]: {
        selected: isMultipleKeys ? [selected] : selected,
      },
    };

    appNavigate(getNavPathWithFilters(getPath(ROUTES[data.navRoute]), filters));
  };

  return (
    <div className="dashboard__chart__double-doughnut-chart">
      {data && data?.doughnutLeft && data.doughnutRight ? (
        <div className="dashboard__chart__double-doughnut-chart__doughnuts-container">
          <div className="dashboard__chart__double-doughnut-chart__doughnuts-item">
            <ResponsivePie
              data={leftDoughnutMappedData || []}
              onClick={handleClick}
              enableArcLabels={true}
              arcLabelsSkipAngle={10}
              theme={chartTheme}
              margin={{ top: 10, right: -60, bottom: 55, left: 20 }}
              innerRadius={0.6}
              activeOuterRadiusOffset={5}
              borderWidth={1}
              height={250}
              width={310}
              colors={({ id, data }) => data.color}
              borderColor={{
                from: 'color',
                modifiers: [['opacity', 0.2]],
              }}
              enableArcLinkLabels={false}
              onMouseEnter={(_datum, event) => {
                if (ROUTES[data?.navRoute] && data?.filterKey) {
                  event.currentTarget.style.cursor = 'pointer';
                }
              }}
              legends={[
                {
                  anchor: 'bottom-left',
                  direction: 'column',
                  justify: false,
                  translateX: -20,
                  translateY: 55,
                  itemsSpacing: 0,
                  itemWidth: 150,
                  itemHeight: 16,
                  itemTextColor: colors.textColor,
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 10,
                  symbolShape: 'square',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000',
                      },
                    },
                  ],
                },
              ]}
              tooltip={({ datum }) => (
                <CustomNivoChartTooltip data={datum} chartType={chart.chartType} />
              )}
            />
          </div>
          <div className="dashboard__chart__double-doughnut-chart__doughnuts-separator"></div>
          <div className="dashboard__chart__double-doughnut-chart__doughnuts-item-small">
            <ResponsivePie
              data={rightDoughnutMappedData || []}
              onClick={handleClick}
              enableArcLabels={true}
              arcLabelsSkipAngle={10}
              theme={chartTheme}
              margin={{ top: 10, right: 0, bottom: 55, left: 30 }}
              innerRadius={0.55}
              activeOuterRadiusOffset={5}
              borderWidth={1}
              height={160}
              width={150}
              colors={({ id, data }) => data.color}
              borderColor={{
                from: 'color',
                modifiers: [['opacity', 0.2]],
              }}
              enableArcLinkLabels={false}
              onMouseEnter={(_datum, event) => {
                if (ROUTES[data?.navRoute] && data?.filterKey) {
                  event.currentTarget.style.cursor = 'pointer';
                }
              }}
              legends={[
                {
                  anchor: 'bottom-left',
                  direction: 'column',
                  justify: false,
                  translateX: -30,
                  translateY: 55,
                  itemsSpacing: 0,
                  itemWidth: 100,
                  itemHeight: 16,
                  itemTextColor: colors.textColor,
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 10,
                  symbolShape: 'square',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemTextColor: '#000',
                      },
                    },
                  ],
                },
              ]}
              tooltip={({ datum }) => (
                <CustomNivoChartTooltip data={datum} chartType={chart.chartType} />
              )}
            />
          </div>
        </div>
      ) : (
        <img src={emptyLogo} alt="empty chart" />
      )}
    </div>
  );
};

DoubleDoughnutChart.propTypes = {
  chart: PropTypes.object,
};

export default DoubleDoughnutChart;
