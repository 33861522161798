import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  getRecentAssessments,
  getRecentFindings,
  getRecentVendors,
  getRecentEvidences,
} from 'api/recent';

const initialState = {
  vendors: [],
  vendorsLoading: false,
  vendorsSorter: {},
  assessments: [],
  assessmentsLoading: false,
  assessmentsSorter: {},
  findings: [],
  findingsLoading: false,
  findingsSorter: {},
  evidences: [],
  evidencesLoading: false,
  evidencesSorter: {},
  displayAmount: 7,
};

const reducers = createSlice({
  name: 'recent',
  initialState,
  reducers: {
    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
    setDisplayAmount: (state, { payload }) => {
      state.displayAmount = payload;
    },
    setAssessmentsSorter: (state, { payload }) => {
      state.assessmentsSorter = payload || {};
    },
    setVendorsSorter: (state, { payload }) => {
      state.vendorsSorter = payload || {};
    },
    setFindingsSorter: (state, { payload }) => {
      state.findingsSorter = payload || {};
    },
    setEvidencesSorter: (state, { payload }) => {
      state.evidencesSorter = payload || {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecentVendors.pending, (state) => {
        state.vendorsLoading = true;
      })
      .addCase(getRecentAssessments.pending, (state) => {
        state.assessmentsLoading = true;
      })
      .addCase(getRecentFindings.pending, (state) => {
        state.findingsLoading = true;
      })
      .addCase(getRecentEvidences.pending, (state) => {
        state.evidencesLoading = true;
      })
      .addMatcher(
        isAnyOf(getRecentVendors.fulfilled, getRecentVendors.rejected),
        (state, { payload }) => {
          state.vendors = payload?.vendors || payload?.customers;
          state.vendorsLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(getRecentAssessments.fulfilled, getRecentAssessments.rejected),
        (state, { payload }) => {
          state.assessments = payload?.assessments;
          state.assessmentsLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(getRecentFindings.fulfilled, getRecentFindings.rejected),
        (state, { payload }) => {
          state.findings = payload?.findings;
          state.findingsLoading = false;
        },
      )
      .addMatcher(
        isAnyOf(getRecentEvidences.fulfilled, getRecentEvidences.rejected),
        (state, { payload }) => {
          state.evidences = payload?.evidences;
          state.evidencesLoading = false;
        },
      );
  },
});

export const {
  setPagination,
  setSearch,
  setAssessmentsSorter,
  setVendorsSorter,
  setFindingsSorter,
  setEvidencesSorter,
  setDisplayAmount,
} = reducers.actions;

export default reducers;
