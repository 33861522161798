import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// COMPONENTS
import Button from 'ui/Button';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'components/UpstreamAI/messages';
import { buttonTypes, titleStructures } from 'components/UpstreamAI/lib';

function TitleButton({ titleText, onClick, buttonType, active }) {
  const buttonClasses = classNames('upstream-ai__title-button', {
    [` upstream-ai__title-button--${buttonType}`]: buttonType,
    [` upstream-ai__title-button--${buttonType}--active`]: !!active,
  });

  return (
    <Button
      data-test={`tile-button-${titleText}`}
      onClick={onClick}
      className={buttonClasses}
      size="xs"
      squared
      beforeIcon="sparkle"
    >
      {titleText}
    </Button>
  );
}

function PopoverTitle({ titleStructure, selectedButton, setSelectedButton, mixpanelTrack }) {
  const handleClick = (buttonType) => {
    // send event to mixpanel of previous selected button on tab change
    mixpanelTrack();
    setSelectedButton(buttonType);
  };

  const explainButton = {
    titleText: translate(messages.explainButtonInner),
    onClick: () => handleClick(buttonTypes.explain),
    buttonType: buttonTypes.explain,
  };
  const exampleButton = {
    titleText: translate(messages.exampleButtonInner),
    onClick: () => handleClick(buttonTypes.example),
    buttonType: buttonTypes.example,
  };
  const improveButton = {
    titleText: translate(messages.improveButtonInner),
    onClick: () => handleClick(buttonTypes.improve),
    buttonType: buttonTypes.improve,
  };
  const auditButton = {
    titleText: translate(messages.auditButtonInner),
    onClick: () => handleClick(buttonTypes.audit),
    buttonType: buttonTypes.audit,
  };

  const buttonsList = useMemo(() => {
    switch (titleStructure) {
      case titleStructures.explainOnly:
        return [explainButton];
      case titleStructures.explainAndExample:
        return [explainButton, exampleButton];
      case titleStructures.exampleOnly:
        return [exampleButton];
      case titleStructures.improveAndExample:
        return [improveButton, exampleButton];
      case titleStructures.auditOnly:
        return [auditButton];
      default:
        return [];
    }
  }, [titleStructure, selectedButton]);

  return (
    <div className="upstream-ai__title">
      <div className="upstream-ai__title-buttons-list">
        {buttonsList?.map((button, index) => (
          <div key={index}>
            <TitleButton
              titleText={button.titleText}
              onClick={button.onClick}
              buttonType={button.buttonType}
              active={selectedButton === button.buttonType}
            />
          </div>
        ))}
      </div>

      <div data-test="upstream-ai-title-text" className="upstream-ai__title-text">
        {translate(messages.aiPowered)}
      </div>
    </div>
  );
}

PopoverTitle.propTypes = {
  titleStructure: PropTypes.string,
  selectedButton: PropTypes.string,
  setSelectedButton: PropTypes.func,
  mixpanelTrack: PropTypes.func,
};

TitleButton.propTypes = {
  titleText: PropTypes.string,
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
  active: PropTypes.bool,
};

export default PopoverTitle;
