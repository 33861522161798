import { compareDataSource } from 'pages/Editor/lib';

export const weightDataSource = Array.from(Array(101).keys()).map((number) => ({
  value: number,
  label: `${number}%`,
}));

export function getWeightDataSource(currentValue) {
  const valueExistInDataSource = weightDataSource.find(({ value }) => value === currentValue);

  if (currentValue && currentValue !== 'Exclude' && !valueExistInDataSource) {
    return [...weightDataSource, { value: currentValue, label: `${currentValue}%` }].sort(
      compareDataSource,
    );
  }

  return weightDataSource;
}
