import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { translate } from 'utils/index';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import NewVendorFooter from 'components/NewVendorFooter';
import { ReactComponent as ArrowIconRight } from 'assets/svgs/arrowRight.svg';
import { ReactComponent as ArrowIconLeft } from 'assets/svgs/arrowLeft.svg';
import { generalMessages } from 'constants/messages';
import { ReactComponent as TrashIcon } from 'assets/svgs/small-trash.svg';
import Tabs from 'ui/Tabs';
import TabAddButton from 'ui/Tabs/tab-add-button';
import Text from 'ui/Text';
import Loader from 'ui/Loader';
import { useCustomerManageContext } from 'hooks/useCustomerManageContext';

import CategoryTabLabel from './categoryTabLabel';
import { messages } from './messages';
import CategoryForm from './categoryForm';
import './index.scss';
import RemoveConfirmationModal from 'components/RemoveConfirmationModal';

const CategoryTab = ({ handleTabChange, handleBackTab, prevAction }) => {
  const {
    currentCustomer,
    customerAssessmentsData,
    updateCustomer,
    onChangeTitle,
    onDeleteCategory,
    isAddingAnalysis,
    isRemovingAnalysis,
  } = useCustomerManageContext();
  const { isLoading } = useSelector((state) => state.customers);
  const { customerId } = useParams();
  const [tabs, setTabs] = useState(customerAssessmentsData);
  const [isModalOpen, setModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(tabs[0]?.id || []);
  const [editingTabs, setEditingTabs] = useState([]);

  useEffect(() => {
    setTabs(customerAssessmentsData);
  }, [customerAssessmentsData]);

  const mapControls = useMemo(() => {
    const result = new Map();
    if (tabs.length === 0) {
      return result;
    }
    tabs.forEach((assessment) => {
      result.set(
        assessment.id,
        assessment.categories
          .flatMap((category) => category.controls)
          .map((categoryControl) => ({
            ...categoryControl,
            answerData: assessment.answer_types[categoryControl.answer_type_idx],
          }))
          .reduce((acc, categoryControl) => {
            const { categoryId } = categoryControl;
            const control = acc.get(categoryId);
            if (control) {
              control.push(categoryControl);
              acc.set(categoryId, control);
            } else {
              acc.set(categoryId, [categoryControl]);
            }
            return acc;
          }, new Map()),
      );
    });

    return result;
  }, [tabs]);

  const categories = useMemo(() => {
    const result = [];
    if (tabs.length === 0) {
      return result;
    }

    tabs.forEach((assessment) => {
      result.push(
        assessment?.categories.map((category) => {
          const control = mapControls.get(assessment.id);
          const mappedControl = control.get(category.id);
          if (mappedControl) {
            return {
              ...category,
              controls: mappedControl,
            };
          }

          return category;
        }),
      );
    });

    return result;
  }, [mapControls, tabs]);

  const onSubmit = (event) => {
    event.preventDefault();
    currentCustomer ? updateCustomer() : handleTabChange();
  };

  const addTab = () => {
    updateCustomer(true);
  };

  const toggleRemoveModal = () => {
    setModalOpen(!isModalOpen);
  };

  const editTabAction = (event, id) => {
    event.stopPropagation();
    const copyEditingTabs = [...editingTabs];

    copyEditingTabs.push(id);

    setEditingTabs([...copyEditingTabs]);
  };

  const cancelTabAction = (id) => {
    setEditingTabs((prev) => {
      return prev.filter((editingId) => editingId !== id);
    });
  };

  const handleOkModal = () => {
    toggleRemoveModal();
    onDeleteCategory(activeTab);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="category-tab">
        <Text className="category-tab__header" level={2} color="text">
          {translate(messages.header)}
        </Text>

        {tabs.length > 0 && customerId && (
          <div className="category-tab__delete-tab" onClick={toggleRemoveModal}>
            <TrashIcon /> {translate(messages.deleteButton)}
          </div>
        )}

        <Tabs
          tabBarExtraContent={
            customerId && {
              right: (
                <TabAddButton
                  loading={isLoading}
                  onClick={addTab}
                  text={translate(messages.tabAddButtonText)}
                />
              ),
            }
          }
          inner
          onChange={(tab) => {
            setActiveTab(tab);
          }}
        >
          {tabs.map((assessment, index) => {
            return (
              <Tabs.Pane
                tab={
                  <CategoryTabLabel
                    saveAction={(value) => {
                      onChangeTitle(value, assessment.id);
                      cancelTabAction(assessment.id);
                    }}
                    cancelAction={() => {
                      cancelTabAction(assessment.id);
                    }}
                    editAction={(event) => {
                      editTabAction(event, assessment.id);
                    }}
                    isEditing={editingTabs.some((editingId) => editingId === assessment.id)}
                    text={assessment.title || `${messages.tabLabel}`}
                  />
                }
                key={assessment.id}
                activeTabKey={tabs[0].id}
              >
                {categories[index].map((category, index) => {
                  return (
                    <CategoryForm
                      key={`category_form_${index}`}
                      assessmentId={assessment.id}
                      category={category}
                    />
                  );
                })}
              </Tabs.Pane>
            );
          })}
        </Tabs>
      </div>
      {customerId ? null : (
        <NewVendorFooter
          prevBeforeComponent={!currentCustomer && <ArrowIconLeft width={13} height={14} />}
          nextAfterComponent={!currentCustomer && <ArrowIconRight width={13} height={14} />}
          nextAction={onSubmit}
          prevAction={currentCustomer ? prevAction : handleBackTab}
          nextLabel={
            currentCustomer ? translate(messages.saveAndClose) : translate(generalMessages.next)
          }
          prevLabel={
            currentCustomer ? translate(generalMessages.cancel) : translate(generalMessages.back)
          }
          isNextLoading={isLoading && !isAddingAnalysis && !isRemovingAnalysis}
          isNextDisabled={isRemovingAnalysis || isAddingAnalysis}
          dataTestNext="customer-category-tab-button-footer-next"
          dataTestPrev="customer-category-tab-button-footer-back"
        />
      )}
      <RemoveConfirmationModal
        onCancel={toggleRemoveModal}
        onOk={handleOkModal}
        open={isModalOpen}
        text={translate(messages.removeAnalysisText)}
        headerText={translate(messages.removeAnalysisTextHeader)}
      />
    </>
  );
};

CategoryTab.propTypes = {
  handleTabChange: PropTypes.func,
  handleBackTab: PropTypes.func,
  prevAction: PropTypes.func,
};

export default CategoryTab;
