export const messages = {
  createNewCustomer: {
    key: 'CustomerManage.createNewCustomer',
    defaultMessage: 'Create new customer',
  },
  assignAssessment: {
    key: 'CustomerManage.assignAssessment',
    defaultMessage: 'Assign assessment',
  },
  contactInformation: {
    key: 'CustomerManage.contactInformation',
    defaultMessage: 'Contact Information',
  },
  customerHasBeenAdded: {
    key: 'CustomerManage.customerHasBeenAdded',
    defaultMessage: 'Customer has been added!',
  },
  customerHasBeenUpdated: {
    key: 'CustomerManage.customerHasBeenUpdated',
    defaultMessage: 'Customer has been updated!',
  },
  requiredCompanyName: {
    key: 'CustomerManage.requiredCompanyName',
    defaultMessage: 'Company name is required',
  },
  requiredCompanyContact: {
    key: 'CustomerManage.requiredCompanyContact',
    defaultMessage: 'Company contact is required',
  },
  requiredCompanyEmail: {
    key: 'CustomerManage.requiredCompanyEmail',
    defaultMessage: 'Email is required',
  },
  companyEmailErrorFormat: {
    key: 'CustomerManage.companyEmailErrorFormat',
    defaultMessage: 'Email format is invalid',
  },
  impactAnalysisInformation: {
    key: 'CustomerManage.impactAnalysisInformation',
    defaultMessage: 'Impact Analysis information',
  },
  shareShowcase: {
    key: 'CustomerManage.shareShowcase',
    defaultMessage: 'Share Showcase',
  },
  exitWithoutSaving: {
    key: 'CustomerManage.exitWithoutSaving',
    defaultMessage: 'Exit without saving?',
  },
  saveChanges: {
    key: 'CustomerManage.saveChanges',
    defaultMessage: 'Save changes?',
  },
  allCustomerInformationWillBeLost: {
    key: 'CustomerManage.allCustomerInformationWillBeLost',
    defaultMessage: 'All customer information will be lost',
  },
  wouldYouLikeToSaveChanges: {
    key: 'CustomerManage.wouldYouLikeToSaveChanges',
    defaultMessage: 'Would you like to save changes before exit?',
  },
  saveChangesButton: {
    key: 'CustomerManage.saveChangesButton',
    defaultMessage: 'SAVE CHANGES',
  },
  completeCustomerButton: {
    key: 'CustomerManage.completeCustomerButton',
    defaultMessage: 'COMPLETE CUSTOMER',
  },
  exitAnywayButton: {
    key: 'CustomerManage.exitAnywayButton',
    defaultMessage: 'EXIT ANYWAY',
  },
  dontSaveButton: {
    key: 'CustomerManage.dontSaveButton',
    defaultMessage: "DON'T SAVE",
  },
};

export default {
  prefix: 'CustomerManage',
  messages,
};
