export const messages = {
  placeholder: {
    key: 'LanguageSelect.placeholder',
    defaultMessage: 'Choose language',
  },
};

export default {
  prefix: 'LanguageSelect',
  messages,
};
