export const MixpanelAssignAssessmentSource = {
  RecentCustomer: 'RecentCustomer',
  RecentVendor: 'RecentVendor',

  NewVendor: 'NewVendor',
  NewCustomer: 'NewCustomer',

  Vendors: 'Vendors',
  Customers: 'Customers',

  CustomerAssessments: 'CustomerAssessments',
  VendorAssessments: 'VendorAssessments',

  Editor: 'Editor',
};

export const MixpanelLogoutTypes = {
  Redirect: 'Redirect', // when backend sends `redirect` param in request response
  InvalidToken: 'InvalidToken',
  UserAction: 'UserAction',
  Idle: 'idle',
};
