import * as yup from 'yup';

export const addFindingSchema = (control, questionError, descriptionError) =>
  yup.object({
    selectedControlId: control ? yup.string().notRequired() : yup.string().required(questionError),
    finding: yup.object({
      description: yup.string().required(descriptionError),
      recommendations: yup
        .array()
        .of(yup.object({ description: yup.string().required(descriptionError) })),
    }),
  });
