import React from 'react';
import PropTypes from 'prop-types';

const DuplicateIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M7.25 3.5C5.74011 3.5 4.5 4.74011 4.5 6.25V15.25C4.5 16.7599 5.74011 18 7.25 18H19.25C20.7599 18 22 16.7599 22 15.25V6.25C22 4.74011 20.7599 3.5 19.25 3.5H7.25ZM7.25 5H19.25C19.9491 5 20.5 5.55089 20.5 6.25V15.25C20.5 15.9491 19.9491 16.5 19.25 16.5H7.25C6.55089 16.5 6 15.9491 6 15.25V6.25C6 5.55089 6.55089 5 7.25 5ZM3.5 6L2.89062 6.40625C2.33412 6.77725 2 7.40181 2 8.07031V15.75C2 18.3735 4.1265 20.5 6.75 20.5H17.4297C18.0987 20.5 18.7232 20.1659 19.0938 19.6094L19.5 19H6.75C4.955 19 3.5 17.545 3.5 15.75V6ZM13.2383 7.48926C13.0395 7.49236 12.8502 7.57423 12.7117 7.71686C12.5733 7.8595 12.4971 8.05125 12.5 8.25V10H10.75C10.6506 9.99859 10.5519 10.017 10.4597 10.054C10.3675 10.0911 10.2836 10.1461 10.2128 10.2159C10.142 10.2857 10.0858 10.3688 10.0474 10.4605C10.0091 10.5522 9.98932 10.6506 9.98932 10.75C9.98932 10.8494 10.0091 10.9478 10.0474 11.0395C10.0858 11.1312 10.142 11.2143 10.2128 11.2841C10.2836 11.3539 10.3675 11.4089 10.4597 11.446C10.5519 11.483 10.6506 11.5014 10.75 11.5H12.5V13.25C12.4986 13.3494 12.517 13.4481 12.554 13.5403C12.5911 13.6325 12.6461 13.7164 12.7159 13.7872C12.7857 13.858 12.8688 13.9142 12.9605 13.9526C13.0522 13.9909 13.1506 14.0107 13.25 14.0107C13.3494 14.0107 13.4478 13.9909 13.5395 13.9526C13.6312 13.9142 13.7143 13.858 13.7841 13.7872C13.8539 13.7164 13.9089 13.6325 13.946 13.5403C13.983 13.4481 14.0014 13.3494 14 13.25V11.5H15.75C15.8494 11.5014 15.9481 11.483 16.0403 11.446C16.1325 11.4089 16.2164 11.3539 16.2872 11.2841C16.358 11.2143 16.4142 11.1312 16.4526 11.0395C16.4909 10.9478 16.5107 10.8494 16.5107 10.75C16.5107 10.6506 16.4909 10.5522 16.4526 10.4605C16.4142 10.3688 16.358 10.2857 16.2872 10.2159C16.2164 10.1461 16.1325 10.0911 16.0403 10.054C15.9481 10.017 15.8494 9.99859 15.75 10H14V8.25C14.0014 8.14962 13.9827 8.04997 13.9449 7.95695C13.9071 7.86394 13.8511 7.77946 13.78 7.70852C13.709 7.63758 13.6245 7.58161 13.5314 7.54395C13.4383 7.50629 13.3387 7.48769 13.2383 7.48926Z"
      fill={color}
    />
  </svg>
);

DuplicateIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default DuplicateIcon;
