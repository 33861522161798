import React, { useEffect, useMemo, useState } from 'react';
import { translate } from 'utils/index';
import NewVendorFooter from 'components/NewVendorFooter';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { ReactComponent as ArrowIconLeft } from 'assets/svgs/arrowLeft.svg';
import { useVendorManageContext } from 'hooks/useVendorManageContext';
import ControlComponent from 'components/ControlComponent';
import { ControlType } from 'constants/controlType';
import Form from 'ui/Form';
import { generalMessages } from 'constants/messages';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';

import './index.scss';

const AssignAssessmentTab = ({ handleBackTab, prevAction }) => {
  const { isLoading } = useSelector((state) => state.vendors);
  const { currentVendor, updateVendor, onChangeAssets } = useVendorManageContext();
  const [checkedAssessmentRequired, setCheckedAssessmentRequired] = useState([]);
  const [checkedAssessmentPeriod, setCheckedAssessmentPeriod] = useState(null);
  const { isBoSoRole } = useRole();

  const { assessmentsRequired } = useSelector(({ assessmentsTemplates }) => {
    // Templates without categories (empty) needs to be hidden
    const assessmentsRequired = assessmentsTemplates?.templates?.filter(
      ({ categoryCount }) => categoryCount > 0,
    );
    return {
      assessmentsRequired,
      assessmentGroups: assessmentsTemplates.groups || [],
    };
  });

  const assessmentsPeriods = useMemo(
    () => [
      { label: translate(messages.never), value: 0 },
      { label: translate(messages.monthly), value: 1 },
      { label: translate(messages.quarterly), value: 2 },
      { label: translate(messages.semiAnnually), value: 3 },
      { label: translate(messages.annually), value: 4 },
    ],
    [],
  );

  useEffect(() => {
    onChangeAssets({
      checkedAssessmentRequired,
      checkedAssessmentPeriod,
    });
  }, [checkedAssessmentRequired, checkedAssessmentPeriod]);

  const onChange = (value, field) => {
    setCheckedAssessmentRequired(value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    updateVendor();
  };

  return (
    <div className="assign-assessment-tab">
      <div className="assign-assessment-tab__header">
        <h3 className="assign-assessment-tab__header-title">
          {translate(messages.assignAssessment)}
        </h3>
        <p>{translate(messages.pleaseSelect)}</p>
      </div>
      <Form className="assign-assessment-tab__form" onSubmit={onSubmit}>
        {assessmentsRequired?.length > 0 && (
          <ControlComponent
            type={ControlType.Checkbox}
            isSimple
            data={{
              payload: {
                'data-test': 'vendor-assessment-required',
                mode: 'none',
                disabled: isBoSoRole,
                options: assessmentsRequired?.map((assessment) => ({
                  ...assessment,
                  label: assessment.name || translate(messages.untitledAssessment),
                  value: assessment.id,
                })),
                onChange: (value) => {
                  onChange(value, 'required');
                },
                name: 'required',
                value: checkedAssessmentRequired,
                setItemDataTest: ({ label }) => `vendor-assessment-${label}`,
              },
            }}
          />
        )}
        <ControlComponent
          type={ControlType.DropDown}
          isSimple
          className="assign-assessment-tab__vendor-auto-assessment-period"
          data={{
            label: translate(messages.autoAssessmentPeriod),
            payload: {
              options: assessmentsPeriods,
              disabled: isBoSoRole,
              onChange: (value) => {
                setCheckedAssessmentPeriod(value);
              },
              name: 'periods',
              value: checkedAssessmentPeriod,
              'data-test': 'vendor-auto-assessment-period',
            },
          }}
        />

        <NewVendorFooter
          prevBeforeComponent={!currentVendor && <ArrowIconLeft width={13} height={14} />}
          nextAction={onSubmit}
          prevAction={currentVendor ? prevAction : handleBackTab}
          nextLabel={
            currentVendor ? translate(messages.saveAndClose) : translate(messages.createNewVendor)
          }
          prevLabel={
            currentVendor ? translate(generalMessages.cancel) : translate(generalMessages.back)
          }
          isNextLoading={isLoading}
          dataTestPrev="vendor-assign-assessment-back"
          dataTestNext="vendor-assign-assessment-save"
        />
      </Form>
    </div>
  );
};

AssignAssessmentTab.propTypes = {
  handleBackTab: PropTypes.func,
  prevAction: PropTypes.func,
};

export default AssignAssessmentTab;
