import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import Checkbox from 'ui/Checkbox';
import Radio from 'ui/Radio';
import { ControlType } from 'constants/controlType';
import BoldedText from './boldedText';
import './answerType.scss';

const AnswerType = ({ answerType = {}, find, select, selected, onOk }) => {
  const { rtl } = useSelector((state) => state.editor.template);

  const filteredOptions = (answerType.options || []).filter(
    (option) => option.value !== '' || option.value !== 0,
  );

  const onSelect = () => {
    select(answerType.id);
  };

  const className = classNames('editor-answer-type', {
    'editor-answer-type--selected': selected,
  });

  const renderType = (index) => {
    switch (answerType.type) {
      case ControlType.Checkbox:
        return <Checkbox name="checkbox" mode="checkbox" disabled />;
      case ControlType.RadioGroup:
        return (
          <Radio
            className={`editor-answer-type__radio ${rtl && 'editor-answer-type__radio--rtl'}`}
            name="radio"
            disabled
          />
        );
      default:
        return <span>{index + 1}.</span>;
    }
  };

  const options = useMemo(() => {
    return filteredOptions.map((option, index) => {
      const weightClassName = classNames('editor-answer-type__weight', {
        'editor-answer-type__weight--red': option.weight < 33,
        'editor-answer-type__weight--orange': option.weight >= 33 && option.weight < 66,
        'editor-answer-type__weight--green': option.weight >= 66,
      });
      return (
        <div key={option.key} className="editor-answer-type__option">
          <div className="editor-answer-type__key">{renderType(index)}</div>
          <div className="editor-answer-type__value">
            <BoldedText text={option.value} find={find} />
          </div>
          <div className={weightClassName}>
            {option.weight === undefined
              ? '-'
              : `${option.weight}${option.weight !== 'Exclude' ? '%' : ''}`}
          </div>
        </div>
      );
    });
  }, [answerType]);

  return (
    <div className={className} onClick={onSelect} onDoubleClick={onOk}>
      {options}
    </div>
  );
};

AnswerType.propTypes = {
  answerType: PropTypes.object,
  find: PropTypes.string,
  onOk: PropTypes.func.isRequired,
  select: PropTypes.func,
  selected: PropTypes.string,
};

export default AnswerType;
