export const messages = {
  welcomeLeft: {
    key: 'NewUserActivation.welcomeLeft',
    defaultMessage: 'Welcome to',
  },
  welcomeAuditAi: {
    key: 'NewUserActivation.welcomeAuditAi',
    defaultMessage: 'Audit-AI',
  },
  welcomeNextButton: {
    key: 'NewUserActivation.welcomeNextButton',
    defaultMessage: 'NEXT',
  },
  welcomeTitle: {
    key: 'NewUserActivation.welcomeTitle',
    defaultMessage: 'Welcome to Audit-AI',
  },
  welcomeContentSecurityTitle: {
    key: 'NewUserActivation.welcomeContentSecurityTitle',
    defaultMessage: 'Data security',
  },
  welcomeContentPrivacyTitle: {
    key: 'NewUserActivation.welcomeContentPrivacyTitle',
    defaultMessage: 'Privacy for all involved',
  },
  welcomeFooterText: {
    key: 'NewUserActivation.welcomeFooterText',
    defaultMessage: 'We are here to be your trusted tool for the job and make your life easier.',
  },
  welcomeSubtitle: {
    key: 'NewUserActivation.welcomeSubtitle',
    defaultMessage:
      'In just a moment, we will walk you through the process of creating your account and setting up a secure password. At Findings, your security is our top priority:',
  },
  welcomeContentSecurityText: {
    key: 'NewUserActivation.welcomeContentSecurityText',
    defaultMessage:
      'Our platform upholds the highest industry standards and meticulously complies with all relevant regulations.',
  },
  welcomeContentPrivacyText: {
    key: 'NewUserActivation.welcomeContentPrivacyText',
    defaultMessage:
      'We employ the highest levels of protection to safeguard the information of all parties.',
  },
};

export default {
  prefix: 'NewUserActivation',
  messages,
};
