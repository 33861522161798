import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import Input from 'ui/Input';
import { setPagination, setSearch } from './reducers';
import { getEvidences } from 'api/evidences';

const Search = () => {
  const dispatch = useDispatch();
  const { search, pagination } = useSelector((state) => state.evidences);

  const searchDebounced = useCallback(
    debounce(async () => {
      dispatch(setPagination({ ...pagination, current: 1 }));
      dispatch(getEvidences());
    }, 500),
    [],
  );

  const handleSearch = (event) => {
    dispatch(setSearch({ search: event.target.value }));
    searchDebounced();
  };

  return (
    <Input.Search
      onChange={handleSearch}
      size="medium"
      value={search}
      className="findings-page__input-search"
      data-test="evidences-search-input"
    />
  );
};

export default Search;
