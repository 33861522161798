import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Select from 'ui/Select';
import { translate } from 'utils/index';
import { messages } from 'pages/Vendors/VendorManage/Tabs/ContactInformationTab/messages';
import { VendorStatus as Status } from 'constants/vendorStatus';
import { changeVendorStatus } from 'api/vendor';

import './vendorStatus.scss';

const VendorStatus = ({ disabled, status, vendorId }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(status);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setValue(status);
  }, [status]);

  const vendorStatuses = [
    {
      icon: 'not-specified',
      label: translate(messages.notSpecified),
      value: 0,
    },
    {
      icon: 'vendor-pending-request',
      label: translate(messages.pendingRequest),
      value: Status.PendingRequest,
    },
    {
      icon: 'vendor-assessment-in-progress',
      label: translate(messages.assessmentInProgress),
      value: Status.AssessmentInProgress,
    },
    {
      icon: 'vendor-bo-request-declined',
      label: translate(messages.BORequestNotApproved),
      value: Status.BORequestNotApproved,
    },
    {
      icon: 'ok4',
      label: translate(messages.securityTeamApproved),
      value: Status.SecurityTeamApproved,
    },
    {
      icon: 'close4',
      label: translate(messages.securityTeamDeclined),
      value: Status.SecurityTeamDeclined,
    },
  ];

  const onChangeStatus = async (value) => {
    setLoading(true);
    await dispatch(changeVendorStatus({ vendorId, vendorStatus: value || 0 }));
    setLoading(false);
  };

  return (
    <Select
      className="recent-page-vendors-vendor-status__status-selector"
      dropdownClassName="recent-page-vendors-vendor-status__status-dropdown"
      disabled={disabled}
      placeholder={translate(messages.selectStatusPlaceholder)}
      options={vendorStatuses}
      showSearch={false}
      value={value || 0}
      onChange={(value) => {
        setValue(value);
        onChangeStatus(value);
      }}
      data-test="vendor-status-selector"
      loading={loading}
    />
  );
};

VendorStatus.propTypes = {
  disabled: PropTypes.bool,
  status: PropTypes.number,
  vendorId: PropTypes.string,
};

export default VendorStatus;
