import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const Card = ({ children, className, noMargin, noPadding, squared, transparent, ...restProps }) => {
  const cardClassNames = classNames('ui-card', className, {
    'ui-card--no-padding': noPadding,
    'ui-card--no-margin': noMargin,
    'ui-card--squared': squared,
    'ui-card--transparent': transparent,
  });

  return (
    <div {...restProps} className={cardClassNames}>
      {children}
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  noPadding: PropTypes.bool,
  noMargin: PropTypes.bool,
  squared: PropTypes.bool,
  transparent: PropTypes.bool,
};

export default Card;
