import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { notification } from 'antd';

import Card from 'ui/Card';
import CardWithLogo from 'components/CardWithLogo';
import NewPasswordForm from 'components/NewPasswordForm';
import ROUTES from 'constants/routes';
import { useAppNavigate } from 'hooks/navigation';
import { translate } from 'utils/index';
import { ONBOARDING_FORM_STAGE } from 'pages/Activation/Onboarding/lib';
import { setNewProfilePassword } from 'api/profile';
import Divider from 'ui/Divider';
import { API_STATUS } from 'constants/api';
import { setCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY, TOKEN_AUTH_KEY } from 'utils/auth';
import { messages } from './messages';
import AiIcon from 'assets/svgs/ai-icon.svg';

const StageOne = ({ setStage }) => {
  const appNavigate = useAppNavigate();
  const { token } = useParams();

  const onClickBack = () => {
    appNavigate(ROUTES.ACTIVATION, { token });
  };

  const onSubmit = async (password) => {
    const { status, organizations } = await setNewProfilePassword({ password, token });

    if (status === API_STATUS.FAILED) {
      return notification.error({ message: translate(messages.stageOnePasswordError) });
    }

    // at onboarding organizations always contain one value
    if (status === API_STATUS.SUCCESS) {
      const orgId = organizations[0]?.id;
      setCookie(ORG_ID_AUTH_KEY, orgId);
      setCookie(TOKEN_AUTH_KEY, token);

      return setStage(ONBOARDING_FORM_STAGE.STAGE_2);
    }
  };

  return (
    <Card className="onboarding-card">
      <div className="onboarding-card__left-box">
        <div className="onboarding-card__left-box__content">
          <img src={AiIcon} alt="ai" />
          <p>{translate(messages.stageOneLeftBoxContent)}</p>
        </div>
      </div>
      <div className="onboarding-card__inner">
        <h1>{translate(messages.stageOneTitle)}</h1>
        <NewPasswordForm onSubmit={onSubmit} checkTermsAndConditions={false}>
          <CardWithLogo.Footer leftButtonOnClick={onClickBack} rightButtonType="submit" />
        </NewPasswordForm>
        <Divider hidden size="md" />
      </div>
    </Card>
  );
};

StageOne.propTypes = {
  setStage: PropTypes.func,
};

export default StageOne;
