import React from 'react';
import { validate } from 'uuid';

import PrivateElement from 'components/PrivateElement';
import LoggedUser from 'components/LoggedUser';

import ShowReport from './showReport';
import { setCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY } from 'utils/auth';
import { useParams } from 'react-router';

const Report = () => {
  const { organization } = useParams();

  if (validate(organization)) {
    setCookie(ORG_ID_AUTH_KEY, organization);
  }

  return (
    <PrivateElement>
      <LoggedUser>
        <ShowReport />
      </LoggedUser>
    </PrivateElement>
  );
};

export default Report;
