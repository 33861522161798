import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import * as yup from 'yup';
import classNames from 'classnames';

import { translate } from 'utils/index';
import Icon from 'ui/Icon';
import Form from 'ui/Form';
import Select from 'ui/Select';
import Button from 'ui/Button';
import Divider from 'ui/Divider';
import DatePicker from 'ui/DatePicker';
import CheckboxGroup from 'ui/CheckboxGroup';
import Loader from 'ui/Loader';
import ROUTES from 'constants/routes';
import PageHeader from 'components/PageHeader';

import { ShowcasePerformedBy } from 'constants/showcasePerformedBy';
import ShowCaseEditor from 'pages/Showcases/showcaseEditor';
import { selectCustomers } from 'components/AssessmentsFilters/selectors';
import { selectProfile } from 'pages/Profile/selectors';
import { getAssessmentsShowcase } from 'api/assessmentsTemplates';
import { getSummaryCustomers } from 'api/summary';
import { assignShowcase, getShowcaseTemplates } from 'api/showcase';
import { useAppNavigate } from 'hooks/navigation';

import showcaseEs from 'assets/svgs/templates-showcases-es.svg';
import { messages } from './messages';
import { mixpanelTrackShowcaseShared } from 'utils/mixpanel';
import './index.scss';

const AssignShowcase = () => {
  const appNavigate = useAppNavigate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [createShowcase, setCreateShowcase] = useState(false);
  const { templatesShowcases } = useSelector((state) => state.showcases);
  const customers = useSelector(selectCustomers);
  const { user } = useSelector(selectProfile);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!templatesShowcases.length) {
      dispatch(getShowcaseTemplates());
    }
  }, []);

  const showcaseTypesMapped = useMemo(
    () =>
      templatesShowcases.map((type) => ({
        label: type.title,
        key: type.id,
        value: type.id,
      })),
    [templatesShowcases],
  );

  const handleCreateShowcase = () => {
    setCreateShowcase((state) => !state);
  };

  useEffect(() => {
    dispatch(getAssessmentsShowcase());
    dispatch(getSummaryCustomers());
  }, []);

  const customersOptions = customers.map(({ profileCorporateName, profileEmail, id }) => ({
    key: id,
    value: id,
    label: profileCorporateName || profileEmail,
  }));

  const initialValues = {
    customers: location.state?.customerId ? [location.state.customerId] : [],
    sharedUntil: moment().add(1, 'month'),
    performedBy: ShowcasePerformedBy.customer,
    templates: location.state?.templates ? location.state.templates : [],
  };

  const { errors, values, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    validationSchema: yup.object({
      templates: yup.array().of(yup.string()).min(1, translate(messages.showcaseRequired)),
      customers: yup.array().of(yup.string()).min(1, translate(messages.customerRequired)),
    }),
    onSubmit: async () => {
      setLoading(true);
      await dispatch(assignShowcase(values));

      if (values?.templates?.length) {
        values?.templates?.forEach((sentShowcase) => {
          const templatesShowcase = templatesShowcases.find(
            (currentTempShoecase) => currentTempShoecase.id === sentShowcase,
          );
          const mixpanelData = {
            relation: customers,
            name: templatesShowcase?.title || '',
            assessmentsCount: templatesShowcase.assessments?.length || 0,
            description: templatesShowcase?.description || '',
            source: 'shared tab',
          };
          mixpanelTrackShowcaseShared(mixpanelData);
        });
      }

      setLoading(false);

      appNavigate(ROUTES.CUSTOMER_SHOWCASES);
    },
  });

  return (
    <div className="assign-showcase">
      <PageHeader className="assign-showcase__header">
        <Icon icon="share" />
        {translate(messages.shareShowcase)}
      </PageHeader>
      {loading && <Loader />}
      <Form onSubmit={handleSubmit}>
        <div className="assign-showcase__main">
          <div className="assign-showcase__container">
            <label className="assign-showcase__form-label">
              {translate(messages.showcaseType)}
            </label>
            <p>{translate(messages.showcaseTypeDescription)}</p>
            {showcaseTypesMapped.length ? (
              <>
                <CheckboxGroup
                  data-test="assign-showcase-items"
                  setItemDataTest={({ label }) => `assign-showcase-items-${label}`}
                  options={showcaseTypesMapped}
                  name="assessmentType"
                  mode="label"
                  value={values.templates}
                  onChange={(value) => setFieldValue('templates', value)}
                />
                {touched.templates && errors.templates && (
                  <p className="assign-showcase__error">{errors.templates}</p>
                )}
                <Divider size="sm" />
                <div className="assign-showcase__additional-actions">
                  <div className="assign-showcase__additional-actions-suffix">
                    {translate(messages.or)}
                  </div>

                  <Button
                    data-test="assign-showcase-create-new"
                    onClick={handleCreateShowcase}
                    link
                    className="assign-showcase__new-showcase-button"
                  >
                    <Icon icon="plus" />
                    {translate(messages.createNewAssessment)}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div
                  className={classNames('assign-showcase__empty-state', {
                    'assign-showcase__empty-state--error': touched.templates && errors.templates,
                  })}
                >
                  <img src={showcaseEs} alt="empty-state" height="100" />
                  <div className="assign-showcase__empty-state-text">
                    {translate(messages.emptyState)}
                    <Button
                      data-test="assign-showcase-create-new"
                      onClick={handleCreateShowcase}
                      link
                      className="assign-showcase__new-showcase-button"
                    >
                      <Icon icon="plus" />
                      {translate(messages.createNewAssessment)}
                    </Button>
                  </div>
                </div>
                {touched.templates && errors.templates && (
                  <p className="assign-showcase__empty-state-error">{errors.templates}</p>
                )}
              </>
            )}

            <div className="assign-showcase__form">
              <label className="assign-showcase__form-label">
                {translate(messages.selectCustomer)}
              </label>
              <label className="assign-showcase__form-label-select-vendor">
                <div> {translate(messages.customerText)}</div>
              </label>
              <Select
                data-test="assign-showcase-select-vendor"
                className="assign-showcase__customer-select"
                color="gray"
                error={touched.customers && errors.customers}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                mode="multiple"
                optionFilterProp="label"
                options={customersOptions}
                onChange={(value) => setFieldValue('customers', value)}
                placeholder={translate(messages.pleaseSelect)}
                size="large"
                value={values.customers}
              />
              <Button
                link
                className="assign-showcase__new-customer-button"
                data-test="assign-showcase-create-new-vendor"
                onClick={() =>
                  appNavigate(ROUTES.CUSTOMER_NEW_CUSTOMER, null, {
                    state: {
                      from: ROUTES.CUSTOMER_SHOWCASES_ASSIGN_SHOWCASE,
                      templates: values.templates,
                    },
                  })
                }
              >
                <Icon icon="plus" />
                {translate(messages.createNewCustomer)}
              </Button>
              <Divider size="sm" hidden />
              <label className="assign-showcase__form-label">
                {translate(messages.sharedUntil)}
              </label>
              <DatePicker
                allowClear={false}
                color="gray"
                className="assign-showcase__due-date-picker"
                onChange={(value) => setFieldValue('sharedUntil', value)}
                size="large"
                value={values.sharedUntil}
              />
            </div>
          </div>
        </div>
        <div className="assign-showcase__actions">
          <Button
            data-test="assign-showcase-cancel-button"
            className="assign-showcase__cancel-button"
            size="sm"
            color="white"
            onClick={() => {
              navigate(-1);
            }}
            fluid
          >
            {translate(messages.cancel)}
          </Button>
          <Button
            data-test="assign-showcase-save-button"
            className="assign-showcase__save-button"
            size="sm"
            color="green"
            type="submit"
          >
            {translate(messages.share)}
          </Button>
        </div>
      </Form>
      <ShowCaseEditor
        isOpen={createShowcase}
        onClose={handleCreateShowcase}
        newShowCase
        corporateLogo={user?.corporateLogo || ''}
      />
    </div>
  );
};

export default AssignShowcase;
