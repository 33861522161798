import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Select from 'ui/Select';
import { translate } from 'utils/index';
import { messages } from 'pages/Editor/messages';
import { selectSaveLoading } from 'pages/Editor/selectors';
import './requiredSwitcher.scss';

const RequiredSwitcher = ({ defaultValue, onSave }) => {
  const [value, setValue] = useState(defaultValue);
  const loading = useSelector(selectSaveLoading);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const options = [
    {
      label: translate(messages.required),
      value: false,
    },
    {
      label: translate(messages.optional),
      value: true,
    },
  ];

  const onChange = (currentValue) => {
    setValue(currentValue);
    onSave(currentValue);
  };

  return (
    <Select
      bordered={false}
      className="editor-required-switcher"
      options={options}
      value={value}
      onChange={onChange}
      loading={loading && value !== defaultValue}
      showSearch={false}
    />
  );
};

RequiredSwitcher.propTypes = {
  defaultValue: PropTypes.bool,
  onSave: PropTypes.func,
};

export default RequiredSwitcher;
