import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from 'antd';

import Button from 'ui/Button';
import Text from 'ui/Text';
import { translate } from 'utils/index';
import { messages } from './messages';

import './authMethod.scss';

const AuthMethod = ({ mfaMethod, onClick, title, hideNone, disableSms }) => {
  const buttonProps = {
    width: 100,
    size: 'md',
    squared: true,
    outline: true,
    color: 'gray',
  };

  const buttonText = [
    translate(messages.authMethodButtonNone),
    translate(messages.authMethodButtonEmail),
    translate(messages.authMethodButtonSMS),
  ];

  return (
    <div className="onboarding-authentication">
      <Text text={title} className="onboarding-authentication__title" />
      <div className="onboarding-authentication__buttons-block">
        {buttonText.map((text, index) => {
          if (hideNone && index === 0) return null;

          if (disableSms && index === 2) {
            return (
              <Tooltip
                key={`onboarding-authentication__button-${text}`}
                title={translate(messages.toUseSmsFor2Fa)}
              >
                <div>
                  <Button
                    data-test={`onboarding-stage-two-auth-method-button-${text}`}
                    {...buttonProps}
                    className={classNames('onboarding-authentication__button', {
                      selected: mfaMethod === index,
                    })}
                    disabled
                  >
                    {text}
                  </Button>
                </div>
              </Tooltip>
            );
          }

          return (
            <Button
              data-test={`onboarding-stage-two-auth-method-button-${text}`}
              key={`onboarding-authentication__button-${text}`}
              {...buttonProps}
              className={classNames('onboarding-authentication__button', {
                selected: mfaMethod === index,
              })}
              onClick={() => onClick(index)}
            >
              {text}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

AuthMethod.propTypes = {
  disableSms: PropTypes.bool,
  hideNone: PropTypes.bool,
  mfaMethod: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
};

AuthMethod.defaultProps = {
  title: '',
};

export default AuthMethod;
