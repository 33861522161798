export const messages = {
  sharedUntil: {
    key: 'ShowcasesPreview.sharedUntil',
    defaultMessage: 'Shared until',
  },
  customer: {
    key: 'ShowcasesPreview.customer',
    defaultMessage: 'Customer',
  },
  vendor: {
    key: 'ShowcasesPreview.vendor',
    defaultMessage: 'Vendor',
  },
  readMore: {
    key: 'ShowcasesPreview.readMore',
    defaultMessage: 'READ MORE',
  },
};

export default {
  prefix: 'ShowcasesPreview',
  messages,
};
