import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import ROUTES from 'constants/routes';
import { API_STATUS } from 'constants/api';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { translate } from 'utils/index';
import { cloneTemplate } from 'api/assessmentsTemplates';
import { useRole } from 'hooks/useRole';
import { messages } from './messages';
import { AssessmentIcon, DuplicateIcon, EditIcon, TrashIcon } from 'components/Icons';

import './actions.scss';

const Actions = ({ onRemove, template }) => {
  const appNavigate = useAppNavigate();
  const dispatch = useDispatch();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { isRestrictedBoSoViewerRole } = useRole();

  const onAssign = (event) => {
    event.stopPropagation();
    appNavigate(
      isMyVendorsTabSelected ? ROUTES.VENDOR_ASSIGN_ASSESSMENT : ROUTES.CUSTOMER_ASSIGN_ASSESSMENT,
      {},
      { state: { templateId: template?.id, dueDelta: template?.dueDelta } },
    );
  };

  const onEdit = (event) => {
    event.stopPropagation();
    const path = isMyVendorsTabSelected
      ? ROUTES.VENDOR_ASSESSMENT_TEMPLATE
      : ROUTES.CUSTOMER_ASSESSMENT_TEMPLATE;

    appNavigate(path, { templateId: template.id });
  };

  const onClone = async (event) => {
    event.stopPropagation();
    const newId = uuidv4();
    const payload = {
      cloned: template.id,
      new: newId,
    };

    const result = await dispatch(cloneTemplate(payload));
    if (result.payload?.status === API_STATUS.SUCCESS) {
      appNavigate(ROUTES.VENDOR_ASSESSMENT_TEMPLATE, { templateId: newId });
    }
  };

  return (
    <div className="assessments-page-templates-actions">
      <Tooltip title={translate(messages.assignTemplate)}>
        <Button
          className="assessments-page-templates-actions__action"
          disabled={isRestrictedBoSoViewerRole}
          type="link"
          onClick={onAssign}
        >
          <AssessmentIcon />
        </Button>
      </Tooltip>

      <Tooltip title={translate(messages.edit)}>
        <Button
          className="assessments-page-templates-actions__action"
          disabled={isRestrictedBoSoViewerRole}
          type="link"
          onClick={onEdit}
        >
          <EditIcon />
        </Button>
      </Tooltip>

      <Tooltip title={translate(messages.cloneTemplate)}>
        <Button
          className="assessments-page-templates-actions__action"
          disabled={isRestrictedBoSoViewerRole}
          type="link"
          onClick={onClone}
        >
          <DuplicateIcon />
        </Button>
      </Tooltip>

      <Tooltip title={translate(messages.remove)}>
        <Button
          className="assessments-page-templates-actions__action"
          disabled={isRestrictedBoSoViewerRole}
          type="link"
          onClick={onRemove}
        >
          <TrashIcon />
        </Button>
      </Tooltip>
    </div>
  );
};

Actions.propTypes = {
  onRemove: PropTypes.func,
  template: PropTypes.object,
};

export default Actions;
