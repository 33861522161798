import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { notification } from 'antd';
import PropTypes from 'prop-types';
import { GoogleLogin } from '@react-oauth/google';

// COMPONENTS
import CardWithLogo from 'components/CardWithLogo';
import ApiUrlSwitch from 'components/ApiUrlSwitch';
import Button from 'ui/Button';
import Divider from 'ui/Divider';
import Form from 'ui/Form';
import Input from 'ui/Input';
import HrWithCildren from 'ui/HrWithCildren';

// CONSTANTS
import { API_STATUS, MFA_METHOD } from 'constants/api';
import { LOGIN_FORM_MODE } from 'pages/Login/lib';

// API
import { loginUser } from 'api/authentication';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Login/messages';
import usePostLogin from 'pages/Login/usePostLogin';

// REDUX
import { selectLogin } from 'pages/Login/selectors';
import { setLoginFormData, setLoginSSO } from 'pages/Login/reducers';

// VALIDATION
import { loginFullSchema } from 'pages/Login/schema';

// STYLES
import './login.scss';

const LoginForm = ({ setMode }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector(selectLogin);
  const postLogin = usePostLogin();

  // handle use case: assignment to new organization
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get('organization');

  const onSubmit = async (credentialResponse) => {
    let sso_setup = null;
    // Handle Google SSO login
    if (Object.keys(credentialResponse).includes('clientId')) {
      sso_setup = { sso: true, ssoToken: credentialResponse?.credential };
      dispatch(setLoginSSO(sso_setup));
    }

    const {
      payload: {
        role,
        mfa_method: mfaMethod = MFA_METHOD.NONE,
        forced2fa,
        status,
        redirect,
        org_id: orgIdFromLogin,
        phone,
        view,
      },
    } = await dispatch(loginUser(sso_setup || { email, password }));

    if (status === API_STATUS.FAILED) return null;

    if (forced2fa) {
      dispatch(setLoginFormData({ key: 'forced2fa', value: forced2fa }));
    }

    if (mfaMethod !== MFA_METHOD.NONE) {
      dispatch(setLoginFormData({ key: 'phone', value: phone }));
      return setMode(LOGIN_FORM_MODE.OTP);
    }

    await postLogin({
      organizationId,
      orgIdFromLogin,
      role,
      redirect,
      view,
    });
  };

  const onChange = (event, key) => {
    dispatch(setLoginFormData({ key, value: event.target.value }));
    handleChange(event);
  };

  const {
    errors,
    values: { email, password },
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: loginFullSchema(
      translate(messages.emailErrorFormat),
      translate(messages.requiredEmail),
      translate(messages.requiredPassword),
    ),

    onSubmit,
  });

  const onShowResetPasswordForm = () => setMode(LOGIN_FORM_MODE.FORGOT_PASSWORD);

  return (
    <div className="login-form">
      <CardWithLogo.Header title={translate(messages.welcome)} />
      <div className="google-sso-login-wrapper">
        <GoogleLogin
          ux_mode="popup"
          onSuccess={onSubmit}
          locale="en-us"
          width={'354'}
          onError={() => {
            notification.error('Unable to login with google.');
          }}
        />
      </div>
      <HrWithCildren>{translate(messages.loginOrHrText)}</HrWithCildren>
      <Form onSubmit={handleSubmit}>
        <Form.Item>
          <Input.Email
            block
            label={translate(messages.emailInputLabel)}
            onChange={(e) => onChange(e, 'email')}
            value={email}
            error={errors.email}
            data-test="login-form-email-input"
            data-test-error="login-form-email-error"
          />
        </Form.Item>
        <Form.Item>
          <Input.Password
            block
            name="password"
            label={translate(messages.labelPassword)}
            placeholder={translate(messages.inputPassword)}
            onChange={(e) => onChange(e, 'password')}
            value={password}
            error={errors.password}
            data-test="login-form-password-input"
            data-test-error="login-form-password-password-error"
            textHelperComponent={
              <Button
                className="login-form__forget-password-link"
                color="pink"
                name={translate(messages.forgotPasswordLinkTitle)}
                onClick={onShowResetPasswordForm}
                data-test="login-form-reset-password-link"
                link
              />
            }
          />
        </Form.Item>

        <ApiUrlSwitch />

        <Divider hidden />
        <Form.Item>
          <Button
            centered
            data-test="login-form-login-button"
            loading={loading}
            width={230}
            type="submit"
            name={translate(messages.loginButton)}
            color="pink"
          />
        </Form.Item>
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  setMode: PropTypes.func.isRequired,
};

export default LoginForm;
