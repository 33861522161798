import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectFinding } from 'pages/Assessment/finalized/findings/selectors';
import Recommendation from './recommendation';
import './index.scss';

const Recommendations = ({ sharedCM }) => {
  const { recommendations = [] } = useSelector(selectFinding);

  return (
    <div className="assessment-finalized-recommendations">
      {recommendations.map((recommendation, index) => (
        <Recommendation
          key={recommendation.id}
          recommendation={recommendation}
          index={index}
          sharedCM={sharedCM}
        />
      ))}
    </div>
  );
};

Recommendations.propTypes = {
  sharedCM: PropTypes.bool,
};

export default Recommendations;
