import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { translate } from 'utils/index';
import { messages } from './messages';
import Modal from 'ui/Modal';
import Icon from 'ui/Icon';
import { ReactComponent as TrashIcon } from 'assets/svgs/small-trash.svg';
import Loader from 'ui/Loader';
import { deleteFile } from 'api/myFiles';

import './removeFile.scss';

const RemoveFile = ({ open, onClose, file }) => {
  const dispatch = useDispatch();

  const isDeleteFileLoading = useSelector((state) => {
    return state.myFiles.isDeleteFileLoading;
  });

  const handleDeleteFile = () => {
    dispatch(deleteFile({ fileId: file.id }));
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="remove-file-modal"
      title={
        <>
          <TrashIcon />
          {translate(file.linked ? messages.removeFileTitle : messages.deleteFileTitle)}
        </>
      }
      onCancel={onClose}
      cancelText={translate(messages.closeRemoveDeleteModal)}
      onOk={handleDeleteFile}
      okText={translate(file.linked ? messages.removeFileOk : messages.deleteFileOk)}
      closeIcon={<Icon icon="close" />}
    >
      {isDeleteFileLoading ? <Loader /> : null}
      <p>
        {translate(file.linked ? messages.removeFileDescription : messages.deleteFileDescription)}
      </p>
    </Modal>
  );
};

RemoveFile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  file: PropTypes.object,
};

export default RemoveFile;
