import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'ui/Select';
import { generalMessages } from 'constants/messages';
import FindingStatus from 'constants/findingStatus';
import RecommendationStatus from 'constants/recommendationStatus';
import { stripHtml } from 'utils/html';
import { translate } from 'utils/index';
import { saveFinding } from 'api/finding';
import { selectFinding } from 'pages/Assessment/finalized/findings/selectors';
import { getStatusFromRecommendations } from 'utils/findings';
import { messages as utilsMessages } from 'utils/messages';
import TextArea from 'ui/TextArea';
import Modal from 'ui/Modal';
import Icon from 'ui/Icon';

import { messages } from './messages';
import UpdateAnswerConfirmationModal from 'components/UpdateAnswerConfirmationModal';
import { useRole } from 'hooks/useRole';
import { API_STATUS } from 'constants/api';
import { mixpanelFindingStatusChanged } from 'utils/mixpanel';
import { selectAssessment } from 'pages/Assessment/selectors';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import './statusSelect.scss';

const StatusSelect = ({ editAnswer }) => {
  const dispatch = useDispatch();
  const finding = useSelector(selectFinding);
  const assessment = useSelector(selectAssessment);
  const [status, setStatus] = useState(getStatusFromRecommendations(finding));
  const { isRestrictedBoSoViewerRole } = useRole();
  const [popupOpened, setPopup] = useState(false);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const [editAnswerPopupOpened, setEditAnswerPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.profile);

  useEffect(() => {
    setStatus(getStatusFromRecommendations(finding));
  }, [finding]);

  const onChange = (value) => {
    if (value === 1) {
      setPopup(true);
    }
  };

  const options = [
    {
      label: translate(utilsMessages.openStatus),
      value: 0,
      disabled: true,
    },
    {
      label: translate(utilsMessages.closedStatus),
      value: 1,
    },
    {
      label: translate(utilsMessages.pendingStatus),
      value: 2,
      disabled: true,
    },
  ];

  const { errors, values, touched, handleSubmit, handleChange, resetForm } = useFormik({
    initialValues: { comment: '' },
    validationSchema: yup.object({
      comment: yup.string().required(translate(messages.noteRequired)),
    }),
    onSubmit: async () => {
      setLoading(true);
      const recommendations = finding.recommendations
        .filter(({ status }) =>
          [
            RecommendationStatus.open,
            RecommendationStatus.approved,
            RecommendationStatus.reject,
          ].includes(status),
        )
        .map(({ id, status }) => ({
          id,
          status: RecommendationStatus.closed,
          comment: values.comment,
        }));

      const payload = {
        control: finding.control,
        finding: {
          id: finding.id,
          recommendations,
        },
      };

      const { status: apiStatus } = await dispatch(saveFinding(payload)).unwrap();

      if (apiStatus === API_STATUS.SUCCESS) {
        mixpanelFindingStatusChanged({
          relation: assessment?.customerId,
          streamDirection: isMyVendorsTabSelected ? 'downstream' : 'upstream',
          assessmentName: assessment?.title,
          originalControl: finding?.control?.title,
          originalFinding: finding?.description,
          assignedOrganizationName: assessment?.customerName,
          assigneeOrganizationName: user?.current_organization?.label,
          type: 'closed',
          previousType: status === 0 ? 'open' : 'pending',
        });
      }

      setLoading(false);
      setPopup(false);
      resetForm();
      setEditAnswerPopup(true);
    },
  });

  const updateAnswer = () => {
    editAnswer();
    setEditAnswerPopup(false);
  };

  const title = (
    <span>
      <Icon icon="info" /> {translate(messages.closeFindingTitle)}
    </span>
  );

  return (
    <>
      <Select
        className={classNames('findings-status-select', `findings-status-select--${status}`)}
        dropdownClassName="findings-status-select__dropdown"
        options={options}
        value={status}
        placeholder={translate(generalMessages.choose)}
        onChange={onChange}
        disabled={status === FindingStatus.closed || isRestrictedBoSoViewerRole}
      />
      <Modal
        className="findings-status-select__confirmation-modal"
        confirmLoading={loading}
        open={popupOpened}
        onCancel={() => setPopup(false)}
        onOk={handleSubmit}
        okText={translate(messages.yesClose)}
        cancelText={translate(generalMessages.cancel)}
        title={title}
        width={685}
      >
        {translate(messages.closeFindingText)}
        <TextArea
          className="findings-status-select__note"
          error={touched.comment && errors.comment}
          name="comment"
          onChange={handleChange}
          placeholder={translate(messages.enterNote)}
          value={stripHtml(values.comment)}
        />
      </Modal>
      <UpdateAnswerConfirmationModal
        onCancel={() => setEditAnswerPopup(false)}
        onOk={updateAnswer}
        open={editAnswerPopupOpened}
      />
    </>
  );
};

StatusSelect.propTypes = {
  editAnswer: PropTypes.func,
};

export default StatusSelect;
