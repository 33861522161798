import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router';
import debounce from 'lodash/debounce';

// COMPONENTS
import FindingStatuses from './findingStatuses';
import RemoveFinding from './removeFinding';
import StatusSelect from './statusSelect';
import TextArea from 'ui/TextArea';

// REDUX
import { selectFinding } from './selectors';
import { selectAssessment } from 'pages/Assessment/selectors';
import { selectAppById } from 'pages/Profile/selectors';

// UTILS
import { messages } from './messages';
import { translate } from 'utils/index';
import { Apps } from 'constants/apps';
import { useRole } from 'hooks/useRole';
import { stripHtml } from 'utils/html';
import { saveFinding } from 'api/finding';

// STYLES
import './finding.scss';

const Finding = () => {
  const params = useParams();
  const finding = useSelector(selectFinding);
  const { assessor } = useSelector(selectAssessment);
  const currentApp = useSelector(selectAppById(params.appId));
  const { isRestrictedBoSoViewerRole } = useRole();
  const dispatch = useDispatch();

  const findingLoading = useSelector(({ assessmentFindings }) => assessmentFindings.findingLoading);
  const [description, setDescription] = useState(finding.description);

  useEffect(() => {
    if (finding?.description !== description) {
      setDescription(finding.description);
    }
  }, [finding]);

  const onSaveDebounced = useCallback(
    debounce((newValue) => {
      const payload = {
        control: finding.control,
        finding: {
          id: finding.id,
          description: newValue,
        },
      };
      dispatch(saveFinding(payload));
    }, 1000),
    [finding],
  );

  const onDescriptionChange = ({ target: { value } }) => {
    setDescription(value);
    onSaveDebounced(value);
  };

  const FindingContent = useMemo(() => {
    switch (currentApp?.id) {
      case Apps.riskRecon:
        return (
          <div className="assessment-finalized-finding__description--custom">
            <div>
              <strong>{finding?.description}</strong>
            </div>
            <br />
            <div>
              <strong>{translate(messages.hostName)}:</strong> {finding.custom_data?.host_name}
            </div>
            <div>
              <strong>{translate(messages.ipAddress)}:</strong> {finding.custom_data?.ip_address}
            </div>
            <div>
              <strong>{translate(messages.firstSeen)}:</strong> {finding.custom_data?.first_seen}
            </div>
          </div>
        );
      case Apps.ssc:
        return (
          <div className="assessment-finalized-finding__description--custom">
            {finding?.custom_data?.ssc_finding_data || finding?.description}
          </div>
        );
      default:
        return (
          <div className="assessment-finalized-finding__description--custom">
            {finding?.description}
          </div>
        );
    }
  }, [currentApp?.id, finding]);

  if (isEmpty(finding)) {
    return null;
  }

  return (
    <div className="assessment-finalized-finding">
      <div className="assessment-finalized-finding__question-wrapper">
        <div className="assessment-finalized-finding__question-label">
          {translate(messages.question)}:
        </div>
        <div className="assessment-finalized-finding__question">
          {translate(messages.continuousMonitoring)}
        </div>
      </div>
      <div className="assessment-finalized-finding__answer-wrapper">
        <div className="assessment-finalized-finding__answer-label">
          {translate(messages.answer)}:
        </div>
        <div
          className="assessment-finalized-finding__answer"
          id="assessment-finalized-finding-answer"
        >
          {currentApp?.label} {translate(messages.app)}
        </div>
      </div>
      <div className="assessment-finalized-finding__finding-header">
        <h4 className="assessment-finalized-finding__finding-header-text">
          {translate(messages.finding)}:
        </h4>
        {assessor && <StatusSelect />}
      </div>
      {!finding?.custom_data || !Object.keys(finding?.custom_data).length ? (
        <div className="assessment-finalized-finding__description">
          <TextArea
            className="assessment-finalized-finding__description"
            disabled={isRestrictedBoSoViewerRole}
            value={stripHtml(description)}
            onChange={onDescriptionChange}
            loading={findingLoading && finding?.description !== description}
          />
        </div>
      ) : (
        FindingContent
      )}
      <FindingStatuses />
      {assessor && <RemoveFinding />}
    </div>
  );
};

export default Finding;
