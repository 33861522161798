import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'utils/index';
import { AssessmentStatus } from 'constants/assessmentStatus';

import { messages } from './messages';
import './status.scss';

const Status = ({ completion, status }) => {
  return (
    <div className="assessment-status">
      <div className="assessment-status__wrapper">
        <div className="assessment-status__line" />
        <div className="assessment-status__line-completed" />
        <div
          className="assessment-status__line-in-progress"
          style={{
            width:
              status >= AssessmentStatus.finalized
                ? `${completion * 0.75}%`
                : `calc(${completion * 0.75}% - 18px)`,
          }}
        />
        {status === AssessmentStatus.notStarted && (
          <div
            className="assessment-status__state-not-started"
            data-text={translate(messages.notStarted)}
          />
        )}
        {[AssessmentStatus.inProgress, AssessmentStatus.completed].includes(status) && (
          <>
            <div
              className="assessment-status__state-in-progress"
              style={{ left: `calc(${completion * 0.75}% - 18px)` }}
              data-percentage={`${completion}%`}
            />
            <div className="assessment-status__label-in-progress">
              {translate(messages.inProgress)}
            </div>
          </>
        )}
        <div
          className={classNames('assessment-status__state-finalized', {
            'assessment-status__state-finalized--active': status === AssessmentStatus.reviewed,
          })}
          data-text={translate(messages.submitted)}
        />
        <div
          className="assessment-status__state-reviewed"
          data-text={translate(messages.customerReview)}
        />
      </div>
    </div>
  );
};

Status.propTypes = {
  completion: PropTypes.number,
  status: PropTypes.number,
};

export default Status;
