import { GetTranslationFile } from 'api/endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getBaseUrl } from 'utils/index';

const uninterceptedAxiosInstance = axios.create();

const getTranslationsRequest = async (language = 'en') => {
  uninterceptedAxiosInstance.defaults.baseURL = getBaseUrl();
  const res = await uninterceptedAxiosInstance(`/${GetTranslationFile.url}?language=${language}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (res.status === 200) {
    const translationRes = await uninterceptedAxiosInstance(res.data.path, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return translationRes.data;
  }
};
export const getTranslations = createAsyncThunk('getTranslations', getTranslationsRequest);
