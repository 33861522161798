import { datadogRum } from '@datadog/browser-rum';
import { ENV } from './env';

export const createDataDogConfig = async () => {
  await datadogRum.init({
    applicationId: ENV.DATADOG_APPLICATION_ID,
    clientToken: ENV.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'findingsprod',
    env: 'production',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: ['https://app.findings.co/'],
  });
};
