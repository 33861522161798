import React from 'react';
import PropTypes from 'prop-types';

const ChatFilledIcon = ({ color = 'currentColor', height = '24', width = '24', ...otherProps }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M12.5687 2C7.05466 2 2.56866 6.486 2.56866 12C2.56866 13.593 2.95366 15.1715 3.68466 16.586L2.61516 20.4145C2.49416 20.848 2.61666 21.3155 2.93516 21.634C3.17266 21.8715 3.49266 22 3.82066 22C3.93266 22 4.04516 21.985 4.15566 21.954L7.98616 20.8845C9.39966 21.615 10.9772 22 12.5687 22C18.0827 22 22.5687 17.514 22.5687 12C22.5687 6.486 18.0827 2 12.5687 2Z"
      fill={color}
    />
  </svg>
);

ChatFilledIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ChatFilledIcon;
