import React from 'react';
import PropTypes from 'prop-types';

import Text from 'ui/Text';
import Divider from 'ui/Divider';
import Icon from 'ui/Icon';
import Button from 'ui/Button';

const Header = ({ title, subtitle, icon, backButtonOnClick, beforeComponent, className }) => {
  return (
    <div className={className}>
      {beforeComponent && beforeComponent}
      <h1 className="card-with-logo__title">
        <Icon icon={icon || ''} className="card-with-logo__icon-title" /> {title}
      </h1>
      <Text level={4} noMargin light>
        {subtitle}
      </Text>
      <Divider size="sm" hidden />
      {backButtonOnClick && (
        <Button
          link
          color="gray"
          beforeIcon="left-arrow"
          name="back"
          data-test="header_back-button"
          className="card-with-logo__back-button"
          beforeIconClassName="card-with-logo__back-icon"
          onClick={backButtonOnClick}
        />
      )}
    </div>
  );
};

Header.propTypes = {
  beforeComponent: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  backButtonOnClick: PropTypes.func,
};

export default Header;
