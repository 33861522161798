import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uploadInProgress: false,
  navigateTo: null,
};

const reducers = createSlice({
  name: 'fileUploadInProgress',
  initialState,
  reducers: {
    toggleFileUploading: (state, { payload }) => {
      state.uploadInProgress = payload?.uploadInProgress || false;
    },
    setNavigateTo: (state, { payload }) => {
      state.navigateTo = payload || null;
    },
  },
});

export const { toggleFileUploading, setNavigateTo } = reducers.actions;

export default reducers;
