export const generalMessages = {
  // Actions
  save: {
    defaultMessage: 'Save',
    key: 'generalMessages.save',
  },
  saveAndContinue: {
    defaultMessage: 'Save and Continue',
    key: 'generalMessages.saveAndContinue',
  },
  ok: {
    defaultMessage: 'Ok',
    key: 'generalMessages.ok',
  },
  yes: {
    defaultMessage: 'Yes',
    key: 'generalMessages.yes',
  },
  yesRemove: {
    defaultMessage: 'YES, REMOVE',
    key: 'generalMessages.YesRemove',
  },
  cancel: {
    defaultMessage: 'Cancel',
    key: 'generalMessages.cancel',
  },
  delete: {
    defaultMessage: 'Delete',
    key: 'generalMessages.delete',
  },
  done: {
    defaultMessage: 'Done',
    key: 'generalMessages.done',
  },
  edit: {
    defaultMessage: 'Edit',
    key: 'generalMessages.edit',
  },
  next: {
    defaultMessage: 'Next',
    key: 'generalMessages.next',
  },
  previous: {
    defaultMessage: 'Previous',
    key: 'generalMessages.previous',
  },
  back: {
    defaultMessage: 'Back',
    key: 'generalMessages.back',
  },
  send: {
    defaultMessage: 'Send',
    key: 'generalMessages.send',
  },
  submit: {
    defaultMessage: 'Submit',
    key: 'generalMessages.submit',
  },
  close: {
    defaultMessage: 'Close',
    key: 'generalMessages.close',
  },
  browse: {
    defaultMessage: 'Browse',
    key: 'generalMessages.browse',
  },
  remove: {
    defaultMessage: 'Remove',
    key: 'generalMessages.remove',
  },
  download: {
    defaultMessage: 'Download',
    key: 'generalMessages.download',
  },
  search: {
    defaultMessage: 'Search',
    key: 'generalMessages.search',
  },
  select: {
    defaultMessage: 'Select',
    key: 'generalMessages.select',
  },
  // risk
  lowRisk: {
    defaultMessage: 'Low',
    key: 'generalMessages.lowRisk',
  },
  mediumRisk: {
    defaultMessage: 'Medium',
    key: 'generalMessages.mediumRisk',
  },
  highRisk: {
    defaultMessage: 'High',
    key: 'generalMessages.highRisk',
  },
  na: {
    defaultMessage: 'N/A',
    key: 'generalMessages.na',
  },
  criticalRisk: {
    defaultMessage: 'Critical',
    key: 'generalMessages.criticalRisk',
  },
  showstopperRisk: {
    defaultMessage: 'Showstopper',
    key: 'generalMessages.showstopperRisk',
  },

  // Other
  error: {
    defaultMessage: 'Error',
    key: 'generalMessages.error',
  },
  unauthorized: {
    defaultMessage: 'Unauthorized action',
    key: 'generalMessages.unauthorized',
  },
  noResponse: {
    defaultMessage: 'Response object is empty',
    key: 'generalMessages.noResponse',
  },
  uploadCompanyLogo: {
    defaultMessage: 'Upload Company Logo',
    key: 'generalMessages.uploadCompanyLogo',
  },
  categories: {
    defaultMessage: 'Categories',
    key: 'generalMessages.categories',
  },
  required: {
    defaultMessage: 'Required',
    key: 'generalMessages.required',
  },
  none: {
    defaultMessage: 'None',
    key: 'generalMessages.none',
  },
  notSpecified: {
    defaultMessage: 'Not specified',
    key: 'generalMessages.notSpecified',
  },
  choose: {
    defaultMessage: 'Choose',
    key: 'generalMessages.choose',
  },
  unknown: {
    defaultMessage: 'Unknown',
    key: 'generalMessages.unknown',
  },
};

export default {
  prefix: 'GeneralMessages',
  messages: generalMessages,
};
