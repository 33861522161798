import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getAllParticipants, getProfileChatThreads, getUnreadChatThreads } from 'api/messenger';
import { getMessagesAssessmentFilter } from 'api/filtersData';
import { removeFilterReducer, setFilterReducer } from 'components/AddFilterButton/filterReducers';

const initialState = {
  threads: [],
  count: 10,
  offset: 1,
  loading: false,
  totalThreads: 0,
  totalOffset: 1,
  filters: [],
  searchValue: '',
  allAssessments: [],
  allParticipants: [],
  showLoader: false,
};

const reducers = createSlice({
  name: 'allMessages',
  initialState,
  reducers: {
    setParameter: (state, { payload: { key, value } }) => {
      state[key] = value;
    },
    setFilter: (state, { payload: { type, value, id } }) => {
      return setFilterReducer(state, type, value, id);
    },
    removeFilter: (state, { payload: { type } }) => removeFilterReducer(state, type),
    clearFilters: (state) => {
      state.filters = [];
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllParticipants.fulfilled, (state, { payload: { vendors, customers } }) => {
        state.allParticipants = vendors || customers;
      })
      .addCase(
        getMessagesAssessmentFilter.fulfilled,
        (state, { payload: { assessment_names: assessmentsTemplates } }) => {
          state.allAssessments = assessmentsTemplates;
        },
      )
      .addMatcher(isAnyOf(getProfileChatThreads.pending, getUnreadChatThreads.pending), (state) => {
        state.loading = true;
      })
      .addMatcher(
        isAnyOf(getProfileChatThreads.fulfilled, getUnreadChatThreads.fulfilled),
        (state, { payload: { threads, data, threads_unread, pages, total } }) => {
          state.threads =
            state?.offset > 1 ? state.threads.concat(data || threads || []) : data || threads || [];
          state.totalThreads = threads_unread || 0;
          state.totalOffset = pages || 0;
          state.total = total || 0;
          state.loading = false;
        },
      );
  },
});

export const { setParameter, setFilter, resetState, removeFilter } = reducers.actions;

export default reducers;
