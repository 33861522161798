import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigationType } from 'react-router';
import moment from 'moment';
import classNames from 'classnames';

import { getRecentVendors } from 'api/recent';
import { translate } from 'utils/index';
import { createSorterByString } from 'utils/strings';
import ROUTES from 'constants/routes';
import { MixpanelAssignAssessmentSource } from 'constants/mixpanel';
import { DATE_FORMAT } from 'constants/date';
import { useAppNavigate } from 'hooks/navigation';
import { useTheme } from 'hooks/theme';
import PageSubheader from 'components/PageSubheader';
import Button from 'ui/Button';
import Table from 'ui/Table';
import { setFromRecent } from 'pages/Vendors/reducers';
import { Sorter } from 'ui/Table/sorter';
import Status from 'pages/Vendors/VendorsList/status';
import Modal from 'ui/Modal';
import Trash from 'assets/svgs/trash.svg';
import { removeVendor } from 'api/vendor';
import { TableSource } from 'ui/Table/lib';
import { selectIsViewAggregated } from 'pages/Organization/selectors';

import VendorRiskPopover from './vendorsColumns/vendorRiskPopover';
import Assessments from './vendorsColumns/assessments';
import VendorRisk from './vendorsColumns/vendorRisk';
import Actions from './vendorsColumns/actions';

import { setVendorsSorter } from './reducers';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import './customers.scss';

const Customers = () => {
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();
  const { isViewerRole } = useRole();
  const isViewAggregated = useSelector(selectIsViewAggregated);
  const [loading, setLoading] = useState(false);
  const [vendorToRemove, setVendorToRemove] = useState();
  const navigationType = useNavigationType();
  const { colorTheme } = useTheme();
  const { vendors, vendorsLoading, vendorsSorter } = useSelector((state) => state.recent);

  useEffect(() => {
    if (navigationType !== 'POP') {
      dispatch(setVendorsSorter({}));
    }
    dispatch(getRecentVendors());
  }, []);

  const sortOrder = vendorsSorter.descending ? 'descend' : 'ascend';

  const columns = [
    {
      title: translate(messages.date),
      dataIndex: 'timestampCreated',
      width: 130,
      render: (timestampCreated) => (
        <div style={{ textAlign: 'center' }}>{moment(timestampCreated).format(DATE_FORMAT)}</div>
      ),
      sorter: (a, b) => new Date(a.timestampCreated) - new Date(b.timestampCreated),
      sortOrder: vendorsSorter.column === 'timestampCreated' ? sortOrder : null,
    },
    {
      title: translate(messages.company),
      dataIndex: 'profileCorporateName',
      render: (name, vendor) => {
        return (
          <div>
            {vendor.onboarding && (
              <span
                className="recent-page-vendors__onboarding-not-completed"
                title={translate(messages.onboardingNotCompleted)}
              />
            )}
            <span className="recent-page-vendors__company-name">{name}</span>
          </div>
        );
      },
      sorter: createSorterByString('profileCorporateName'),
      sortOrder: vendorsSorter.column === 'profileCorporateName' ? sortOrder : null,
    },
    {
      title: translate(messages.name),
      dataIndex: 'profileFullname',
      sorter: createSorterByString('profileFullname'),
      sortOrder: vendorsSorter.column === 'profileFullname' ? sortOrder : null,
    },
    {
      title: translate(messages.email),
      dataIndex: 'profileEmail',
      render: (value) => <span>{value}</span>,
      sorter: createSorterByString('profileEmail'),
      sortOrder: vendorsSorter.column === 'profileEmail' ? sortOrder : null,
      ellipsis: { showTitle: false },
    },
    {
      title: translate(messages.phone),
      dataIndex: 'profilePhone',
      render: (phone) => phone || translate(messages.notSpecified),
      sorter: createSorterByString('profilePhone'),
      sortOrder: vendorsSorter.column === 'profilePhone' ? sortOrder : null,
      width: 140,
    },
    {
      title: translate(messages.activeAssessments),
      dataIndex: 'assessments',
      render: (assessments) => <Assessments assessments={assessments} />,
      sorter: (a, b) => a.assessments.length - b.assessments.length,
      sortOrder: vendorsSorter.column === 'assessments' ? sortOrder : null,
    },
    {
      title: translate(messages.customerRisk),
      dataIndex: 'vendorRisk',
      render: (vendorRisk, vendor) => (
        <div>
          <VendorRiskPopover vendor={vendor}>
            <div>
              <VendorRisk value={vendorRisk} />
            </div>
          </VendorRiskPopover>
        </div>
      ),
      sorter: {
        compare: Sorter.DEFAULT,
      },
      sortOrder: vendorsSorter.column === 'vendorRisk' ? sortOrder : null,
      width: 140,
    },
    {
      title: translate(messages.trustIndicator),
      dataIndex: 'profileStatus',
      render: (_, vendor) => <Status vendor={vendor} />,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      sortOrder: vendorsSorter.column === 'profileStatus' ? sortOrder : null,
      width: 140,
    },
    {
      dataIndex: 'actions',
      render: (_, vendor) => (
        <Actions
          setVendorToRemove={setVendorToRemove}
          source={MixpanelAssignAssessmentSource.RecentCustomer}
          vendor={vendor}
        />
      ),
      width: 150,
    },
  ];

  if (isViewAggregated) {
    const column = {
      title: translate(messages.organization),
      dataIndex: 'parentOrganizationCorporateName',
      sorter: createSorterByString('parentOrganizationCorporateName'),
      sortOrder: vendorsSorter.column === 'parentOrganizationCorporateName' ? sortOrder : null,
    };
    columns.splice(1, 0, column);
  }

  const dataSource = (vendors || []).map((vendor) => ({
    ...vendor,
    key: vendor.id,
  }));

  const onClickAddVendor = () => {
    dispatch(setFromRecent(true));
    return appNavigate(ROUTES.CUSTOMER_NEW_CUSTOMER);
  };

  const onRemove = async (event) => {
    event.stopPropagation();
    setLoading(true);
    await dispatch(removeVendor({ id: vendorToRemove.id })).unwrap();
    await dispatch(getRecentVendors()).unwrap();

    setVendorToRemove(null);
    setLoading(false);
  };

  const onRow = (customer) => ({
    onClick: (event) => {
      event.stopPropagation();
      const classes = event.target.getAttribute('class') || '';
      if (classes.includes('icon') || classes.includes('ant-popover') || isViewerRole) {
        return;
      }

      appNavigate(ROUTES.CUSTOMER_EDIT_CUSTOMER, { customerId: customer?.id });
    },
  });

  const onTableChange = (newPagination, filters, sorter) => {
    const sorterParams = {
      column: sorter.field,
      descending: sorter.order !== 'ascend',
    };
    const params = sorter.column ? sorterParams : {};
    dispatch(setVendorsSorter(params));
  };

  return (
    <div className="recent-page-customers">
      <div className="recent-page-customers__top">
        <PageSubheader className="recent-page-customers__page-subheader">
          {translate(messages.recentCustomers)}
        </PageSubheader>
        <div className="recent-page-customers__menu">
          <Button
            data-test="recent-vendors-button-view-all"
            className="recent-page-customers__view-all-button"
            link
            color="black"
            size="xs"
            onClick={() => appNavigate(ROUTES.CUSTOMER_CUSTOMERS)}
          >
            {translate(messages.viewAll)}
          </Button>

          <Button
            data-test="recent-vendors-button-add-vendor"
            className="recent-page-customers__assign-button"
            disabled={isViewerRole}
            beforeIcon="plus"
            color={colorTheme}
            size="sm"
            onClick={onClickAddVendor}
          >
            {translate(messages.customer)}
          </Button>
        </div>
      </div>
      <Table
        className={classNames('recent-page-customers__table', {
          'recent-page-customers__table--disable-row-click': isViewerRole,
        })}
        columns={columns}
        dataSource={dataSource}
        loading={vendorsLoading}
        pagination={false}
        onChange={onTableChange}
        scroll={{
          y: 300,
          x: 1160,
        }}
        onRow={onRow}
        emptyStateSource={TableSource.customersRecent}
      />
      <Modal
        className="upload-files__confirm-modal"
        confirmLoading={loading}
        open={!!vendorToRemove}
        onOk={onRemove}
        onCancel={() => setVendorToRemove(null)}
        width={460}
      >
        <img className="upload-files__confirm-modal-trash" src={Trash} alt="Trash" />
        <br />
        {translate(messages.removeVendorText)}
      </Modal>
    </div>
  );
};

export default Customers;
