import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import Icon from 'ui/Icon';
import { translate } from 'utils/index';
import { generalMessages } from 'constants/messages';

import { messages } from '../messages';
import './transferOwnershipModal.scss';

const TransferOwnershipModal = ({ open, setOpen, userName, onOk }) => {
  const [loading, setLoading] = useState(false);

  const transferOwnership = async () => {
    setLoading(true);
    await onOk();
    setOpen(false);
    setLoading(false);
  };

  const footer = [
    <Button
      key="cancel-button"
      className="user-management-tab-transfer-ownership-modal__footer-cancel-button"
      size="sm"
      color="white"
      onClick={() => setOpen(false)}
      fluid
      data-test="user-management-tab-transfer-ownership-cancel-button"
    >
      {translate(generalMessages.cancel)}
    </Button>,
    <Button
      key="ok-button"
      className="user-management-tab-transfer-ownership-modal__footer-ok-button"
      size="sm"
      color="red"
      onClick={transferOwnership}
      fluid
      type="submit"
      loading={loading}
      data-test="user-management-tab-transfer-ownership-ok-button"
    >
      {translate(messages.orgEditTransferOwnershipButton)}
    </Button>,
  ];

  const title = (
    <span>
      <Icon icon="info" /> {translate(messages.orgEditTransferOwnership)}
    </span>
  );

  return (
    <Modal
      className="user-management-tab-transfer-ownership-modal"
      onCancel={() => setOpen(false)}
      width={645}
      open={open}
      title={title}
      footer={footer}
    >
      {translate(messages.orgEditTransferOwnershipDescription, { userName })}
      <br />
      <span className="user-management-tab-transfer-ownership-modal__description-line-2">
        {translate(messages.orgEditTransferOwnershipDescription2)}
      </span>
    </Modal>
  );
};

TransferOwnershipModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  userName: PropTypes.string,
  onOk: PropTypes.func,
};

export default TransferOwnershipModal;
