import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './index.scss';

const BlinkingDot = ({ color, className, size, title, isStatic, animation }) => {
  const composedClassName = classnames(
    'blinking-dot',
    `blinking-dot--${color}`,
    `blinking-dot--${size}`,
    {
      'blinking-dot--static': isStatic,
      'blinking-dot--animation': animation,
    },
    className,
  );

  return <span className={composedClassName} title={title} />;
};

BlinkingDot.propTypes = {
  animation: PropTypes.bool,
  color: PropTypes.oneOf(['green', 'orange', 'blue', 'red', 'gray', 'lightblue', 'white']),
  className: PropTypes.any,
  size: PropTypes.oneOf(['sm', 'md']),
  isStatic: PropTypes.bool,
  title: PropTypes.string,
};

BlinkingDot.defaultProps = {
  color: 'red',
  size: 'sm',
  animation: true,
};

export default BlinkingDot;
