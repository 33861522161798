export const messages = {
  labelCode: {
    key: 'SecondAuthForm.labelCode',
    defaultMessage: 'Code',
  },
  inputCode: {
    key: 'SecondAuthForm.inputCode',
    defaultMessage: 'Enter Code',
  },
};

export default {
  prefix: 'SecondAuthForm',
  messages,
};
