import { createSelector } from '@reduxjs/toolkit';

const selectControls = (state) => {
  return state?.upstreamAI?.controls;
};

// Explain
export const selectControlExplanation = createSelector(
  [selectControls, (_, controlId) => controlId],
  (controls, controlId) => {
    return controls?.[controlId]?.explain;
  },
);

// Example
export const selectAnswerExample = createSelector(
  [selectControls, (_, controlId) => controlId],
  (controls, controlId) => {
    return controls?.[controlId]?.example;
  },
);

// Improve
export const selectImprovedAnswer = createSelector(
  [selectControls, (_, controlId) => controlId],
  (controls, controlId) => {
    return controls?.[controlId]?.improve;
  },
);

// Audit
export const selectAuditControl = createSelector(
  [selectControls, (_, controlId) => controlId],
  (controls, controlId) => {
    return controls?.[controlId]?.audit;
  },
);

// Loading
export const selectIsLoadingResponse = createSelector(
  ({ upstreamAI }) => upstreamAI.loading,
  (state) => state,
);

// Enable Improve
export const selectEnableImprove = createSelector(
  ({ upstreamAI }) => upstreamAI.enableImprove,
  (state) => state,
);
