import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { translate } from 'utils/index';
import EvidenceStatus from 'constants/evidenceStatus';
import { messages } from 'pages/Evidences/messages';
import { CheckedApprovedIcon, ErrorIcon, PendingIcon } from 'components/Icons';
import './status.scss';

const Status = ({ status }) => {
  const content = useMemo(() => {
    switch (status) {
      case EvidenceStatus.pending:
        return (
          <>
            <PendingIcon /> {translate(messages.pending)}
          </>
        );
      case EvidenceStatus.rejected:
        return (
          <>
            <ErrorIcon /> {translate(messages.rejected)}
          </>
        );
      case EvidenceStatus.approved:
        return (
          <>
            <CheckedApprovedIcon /> {translate(messages.approved)}
          </>
        );
      default:
        return '';
    }
  }, [status]);

  const className = classNames(
    'recent-page-evidences-status__label',
    `recent-page-evidences-status__label--${status}`,
  );

  return (
    <div className="recent-page-evidences-status">
      <span className={className}>{content}</span>
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.number,
};

export default Status;
