import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Select from 'ui/Select';
import { translate } from 'utils/index';
import { messages } from 'pages/Editor/messages';
import { selectSaveLoading } from 'pages/Editor/selectors';
import './visibleSwitcher.scss';

const VisibleSwitcher = ({ defaultValue, onSave, setOpen, ...otherProps }) => {
  const [value, setValue] = useState(defaultValue);
  const loading = useSelector(selectSaveLoading);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const options = [
    {
      label: translate(messages.visibleText),
      value: true,
    },
    {
      label: translate(messages.hidden),
      value: false,
    },
  ];

  const onChange = (currentValue) => {
    setValue(currentValue);
    onSave(currentValue);
  };

  const className = classNames('editor-visible-switcher', {
    'editor-visible-switcher--visible': value,
    'editor-visible-switcher--hidden': !value,
  });

  return (
    <Select
      bordered={false}
      className={className}
      dropdownClassName="editor-visible-switcher__dropdown"
      options={options}
      value={value}
      onChange={onChange}
      loading={loading && value !== defaultValue}
      showSearch={false}
      onClick={() => setOpen()}
      onBlur={() => setOpen(false)}
      {...otherProps}
    />
  );
};

VisibleSwitcher.propTypes = {
  defaultValue: PropTypes.bool,
  onSave: PropTypes.func,
  setOpen: PropTypes.func,
};

export default VisibleSwitcher;
