import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';
import { removeOrganizationProfile } from 'api/organizations';
import ActionIcons from 'pages/Organization/actionIcons';
import NewRemoveConfirmationModal from 'components/NewRemoveConfirmationModal';

import './index.scss';

const OrgEditActions = ({ orgId, profileId, orgIndex, userName, ...otherProps }) => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState({
    remove: false,
  });
  const [loading, setLoading] = useState(false);

  const onClickCancel = () => {
    setShowPopup(() => ({
      remove: false,
    }));
  };

  const setPopupState = (action) => {
    setShowPopup((state) => ({ ...state, [action]: !state[action] }));
  };

  const removeUser = async () => {
    setLoading(true);
    await dispatch(removeOrganizationProfile({ profileId, orgId, orgIndex }));
    setLoading(false);
  };

  return (
    <>
      <ActionIcons removeOnClick={() => setPopupState('remove')} {...otherProps} />
      <div className="organization-edit-page__actions">
        <NewRemoveConfirmationModal
          open={showPopup.remove}
          text={translate(messages.orgSettingsRemoveUserDescription, { userName })}
          title={translate(messages.orgSettingsRemoveUserTitle, { userName })}
          onCancel={onClickCancel}
          onOk={removeUser}
          confirmLoading={loading}
        />
      </div>
    </>
  );
};

OrgEditActions.propTypes = {
  orgId: PropTypes.string,
  orgIndex: PropTypes.string,
  profileId: PropTypes.string,
  userName: PropTypes.string,
};

export default OrgEditActions;
