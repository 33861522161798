import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetDashboardChartData, GetDashboardLayout } from './endpoints';
import { FILTERABLE_KEYS } from 'components/Filters/lib';

export const getDashboardLayoutRequest = async () => {
  return axios.get(GetDashboardLayout.url);
};

export const getDashboardLayout = createAsyncThunk('getDashboardLayout', getDashboardLayoutRequest);

const getDashboardChartDataRequest = async ({ chartType, dataType, upstream }, { getState }) => {
  const { filters } = getState();

  const payload = {
    chartType,
    dataType,
    upstream,
    filters: JSON.stringify(
      filters.allFilters[FILTERABLE_KEYS.DASHBOARD_FILTERS].filtersToApi || {},
    ),
  };

  return axios.get(GetDashboardChartData.url, { params: payload });
};

export const getDashboardChartData = createAsyncThunk(
  'getDashboardChartData',
  getDashboardChartDataRequest,
);
