import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import Icon from 'ui/Icon';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';

// STYLE
import './logoEmptyState.scss';

function LogoEmptyState({ handleClick }) {
  return (
    <div className="upload-logo-empty-state" onClick={handleClick}>
      <Icon icon="image" className="upload-logo-empty-state__icon" />
      <span className="upload-logo-empty-state__text">
        {translate(messages.orgEditCompanyLogo)}
      </span>
    </div>
  );
}

LogoEmptyState.propTypes = {
  handleClick: PropTypes.func,
};

export default LogoEmptyState;
