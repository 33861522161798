import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RemoveProfileCustomer, SetProfileCustomerStatus } from 'api/endpoints';

const setCustomerStatusRequest = async ({ id, isActive }) => {
  const payload = {
    'set-customer-status-customer-token': id,
    'set-customer-status-customer-status': isActive,
  };

  return axios.post(SetProfileCustomerStatus.url, payload);
};

export const setCustomerStatus = createAsyncThunk('setCustomerStatus', setCustomerStatusRequest);

const removeCustomerRequest = async ({ id }) => {
  const payload = {
    'remove-customer-profile-customer-id': id,
  };

  return axios.post(RemoveProfileCustomer.url, payload);
};

export const removeCustomer = createAsyncThunk('removeCustomer', removeCustomerRequest);
