import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from 'antd';
import { useLocation } from 'react-router-dom';

import FiltersButton from './FiltersButton';
import FiltersPopup from './FiltersPopup';
import { translate } from 'utils/index';
import { messages } from './messages';
import { createNewFilter, eFilterKeys, filtersMetadata, parseFiltersToRequest } from './lib';
import useHandleApplyFilters from 'components/Filters/useHandleApplyFilters';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { getFilterOptions } from 'api/filters';
import { selectIsViewAggregated } from 'pages/Organization/selectors';
import {
  addMultipleFilterKey,
  addQueryBlock,
  onChangeFilter,
  removeFilter,
  setFilter,
  setFilters,
} from './reducers';

const Filters = ({ filterableKey, allowedFilters, applyFilters }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { filtersOptions } = useSelector((state) => state.filters);
  const { filters, filtersCount } = useSelector((state) => state.filters.allFilters[filterableKey]);
  const isViewAggregated = useSelector(selectIsViewAggregated);
  const handleApplyFilters = useHandleApplyFilters({
    filterableKey,
    filters,
    filtersCount,
    applyFilters,
  });

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [optionalFilterItems, setOptionalFilterItems] = useState([]);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const fetchFilterOptions = (data) => {
    dispatch(getFilterOptions({ ...data, filterableKey, upstream: !isMyVendorsTabSelected }));
  };

  useEffect(() => {
    // this effect we will parse the filters from the search params and set them in the store.

    const searchParams = new URLSearchParams(location.search);
    const filtersFromSearchParams = searchParams.get('filters');
    if (!filtersFromSearchParams) return;

    const rawFiltersObj = JSON.parse(filtersFromSearchParams);
    // build filters object from search params and set it in the store:
    const newFilters = {};
    let newFiltersCount = 0;

    Object.keys(rawFiltersObj)?.forEach((filterKey) => {
      const newFilter = createNewFilter({ filterKey });
      newFilter.selected = rawFiltersObj[filterKey].selected;

      if (newFilter.isQuery) {
        newFiltersCount += newFilter.selected.length;
        newFilter.selected.forEach((querySet, querySetIndex) => {
          querySet.forEach((queryBlock, queryBlockIndex) => {
            newFilter.queryBlockKeys.forEach((queryBlockItemKey, queryBlockItemIndex) => {
              const selected = queryBlock[queryBlockItemKey];
              queryBlock[queryBlockItemKey] = newFilter.queryBlockItemsMeta[queryBlockItemKey];
              queryBlock[queryBlockItemKey].selected = selected;
              const dependencies = {};
              newFilter.queryBlockItemsMeta[queryBlockItemKey]?.dependencies?.forEach((dep) => {
                dependencies[dep] = queryBlock[dep].selected.map((item) => item.key);
              });
              const optionsPayload = {
                filterKey,
                upstream: !isMyVendorsTabSelected,
                querySetIndex,
                queryBlockIndex,
                queryBlockItemKey,
                dependencies,
              };
              fetchFilterOptions(optionsPayload);
            });
          });
        });
        newFilters[filterKey] = newFilter;
        return;
      }

      // fetch options if needed
      if (filtersOptions[filterKey]) {
        newFilter.options = filtersOptions[filterKey].options;
      } else {
        fetchFilterOptions({ filterKey });
      }

      // update filters count
      if (newFilter.multipleKeys) {
        newFiltersCount += newFilter.selected.length;
      } else {
        newFiltersCount += 1;
      }

      newFilters[filterKey] = newFilter;
    });

    const filtersToApi = parseFiltersToRequest(newFilters);

    dispatch(
      setFilters({
        filters: newFilters,
        filterableKey,
        filtersCount: newFiltersCount,
        filtersToApi: filtersToApi,
      }),
    );
  }, []);

  useEffect(() => {
    // this effect will update the optional filter items in the filters selection dropdown
    setOptionalFilterItems(() => {
      return allowedFilters
        ?.filter(
          (allowedFilterKey) =>
            !(allowedFilterKey === eFilterKeys.organizationName && !isViewAggregated),
        )
        ?.map((filterKey, index) => {
          const disabled = filters[filterKey] && !filters[filterKey]?.multipleKeys;

          return {
            key: filterKey,
            label: translate(messages[filterKey]),
            disabled: disabled,
            index: index,
            onClick: () => handleAddFilter(filterKey),
            'data-test': `add-filter-dropdown-option-${filterKey}`,
          };
        });
    });
    return () => setOptionalFilterItems([]);
  }, [filters]);

  const handleAddFilter = useCallback(
    (filterKey) => {
      // case of adding filter with existing key and multipleKeys
      if ((filters[filterKey] && filters[filterKey]?.multipleKeys) || filters[filterKey]?.isQuery) {
        dispatch(addMultipleFilterKey({ filterKey, filterableKey }));
        return;
      }

      // case of adding filterKey not existing in filters
      const newFilter = createNewFilter({ filterKey });
      dispatch(setFilter({ filter: newFilter, filterableKey }));

      // fetch options
      if (!filtersOptions[filterKey]) {
        const optionsPayload = { filterKey, upstream: !isMyVendorsTabSelected };
        if (newFilter.isQuery) {
          // for query type filters, fetch options for the first query block item (the other blocks depend on the selection of the first block item)
          optionsPayload.querySetIndex = 0;
          optionsPayload.queryBlockIndex = 0;
          optionsPayload.queryBlockItemKey = newFilter.queryBlockKeys[0];
        }
        fetchFilterOptions(optionsPayload);
      }

      // in case filter doesnt allow multipleKeys, disable it in filter selection dropdown items
      if (!filtersMetadata[filterKey]?.multipleKeys && !filtersMetadata[filterKey]?.isQuery) {
        setOptionalFilterItems((state) => {
          return state.map((item) => {
            if (item?.key === filterKey) {
              return { ...item, disabled: true };
            }
            return item;
          });
        });
      }
    },
    [filters],
  );

  return (
    <div className="filters">
      <Popover
        popoverClassName="filters-pop"
        trigger="click"
        placement="bottomLeft"
        showArrow={false}
        overlayClassName="filters-pop"
        open={isFiltersOpen}
        content={
          <FiltersPopup
            isOpen={isFiltersOpen}
            onClose={() => setIsFiltersOpen((state) => !state)}
            optionalFilterItems={optionalFilterItems}
            filters={filters}
            filtersCount={filtersCount}
            clearAllFilters={() => handleApplyFilters(true)}
            applyFilters={handleApplyFilters}
            removeFilter={(data) => dispatch(removeFilter({ ...data, filterableKey }))}
            onChangeFilter={(data) => dispatch(onChangeFilter({ ...data, filterableKey }))}
            fetchFilterOptions={fetchFilterOptions}
            addQueryBlock={(data) => dispatch(addQueryBlock({ ...data, filterableKey }))}
            filterableKey={filterableKey}
          />
        }
      >
        <FiltersButton
          onClick={() => setIsFiltersOpen((state) => !state)}
          filtersCount={filtersCount || 0}
          isOpen={isFiltersOpen}
        />
      </Popover>
    </div>
  );
};

Filters.propTypes = {
  filterableKey: PropTypes.string.isRequired,
  clearAllFilters: PropTypes.func,
  applyFilters: PropTypes.func,
  allowedFilters: PropTypes.array,
};

export default Filters;
