import { createSelector } from '@reduxjs/toolkit';
import { isFindingTriggeredByTrigger } from 'pages/Assessment/lib';

export const selectAllFindings = createSelector(
  [
    ({ assessment }) => assessment?.assessment?.categories,
    ({ assessmentFindings }) => assessmentFindings.appId,
  ],
  (categories, appId) => {
    const findings = [];
    const appCategory = categories?.find((category) => category?.app === appId);
    appCategory?.controls?.forEach((control) => {
      control.findings?.forEach((finding) => {
        if (
          finding.enabled ||
          isFindingTriggeredByTrigger(finding?.triggers, finding.control?.answer)
        ) {
          findings.push({ ...finding, control, app: appId });
        }
      });
    });

    return findings;
  },
);

export const selectFinding = createSelector(
  selectAllFindings,
  ({ assessmentFindings }) => assessmentFindings.selectedFinding,
  (findings, selectedFinding) => {
    const finding = (findings || []).find(({ id }) => selectedFinding === id);
    return finding || {};
  },
);

export const selectPreviousFinding = createSelector(
  selectAllFindings,
  ({ assessmentFindings }) => assessmentFindings.selectedFinding,
  (findings, selectedFindingId) => {
    const index = findings.findIndex((finding) => finding.id === selectedFindingId);
    return findings[index - 1] || null;
  },
);

export const selectAppFilterParameter = createSelector(
  ({ assessmentFindings }) => assessmentFindings.filterParameter,
  (filterParameter) => filterParameter,
);

export const selectAppFindingsFilterParametersOptions = createSelector(
  [selectAllFindings, selectAppFilterParameter],
  (findings, filterParameter) => {
    if (findings?.length && filterParameter) {
      const filterParameterOptions = [];
      findings?.forEach((finding) => {
        if (
          finding?.custom_data?.[filterParameter] &&
          !filterParameterOptions.includes(finding.custom_data[filterParameter])
        ) {
          const parameter = finding?.custom_data?.[filterParameter];
          filterParameterOptions.push(parameter);
        }
      });

      return filterParameterOptions?.map((option) => ({ label: option, value: option })) || [];
    }
  },
);
