import moment from 'moment';
import { FileExpirationStatus } from 'constants/fileExpirationStatus';

export function getFileExtension(filename) {
  return filename?.split('.').pop();
}

export function getExpirationStatus(expiresOn) {
  const now = moment();
  const expirationDate = moment(expiresOn);
  const diff = expirationDate.diff(now, 'days');

  if (diff < 0) {
    return FileExpirationStatus.expired;
  } else if (diff < 60) {
    return FileExpirationStatus.expireSoon;
  } else {
    return FileExpirationStatus.valid;
  }
}

export function extractFileIdsFromFiles(files) {
  return files?.map((file) => file?.id).join(',') || '';
}
