import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS:
import Tag from 'ui/Tag';

// STYLE AND ASSETS:
import notificationMessageLogo from 'assets/svgs/notification-message-logo.svg';
import './notification.scss';

const Notification = ({ nameOfParticipant, assessmentName, message, date, onClick }) => {
  return (
    <div className="messenger-notification" onClick={onClick}>
      <div className="messenger-notification__heading">
        <div>
          <img src={notificationMessageLogo} width="auto" alt="messenger-notification-logo" />
          <span className="messenger-notification__vendor-name">{nameOfParticipant}</span>
        </div>
        <span className="messenger-notification__date">{date}</span>
      </div>
      <div className="messenger-notification__content">
        <Tag
          className="messenger-notification__tag"
          name={assessmentName}
          ellipsis
          size="small"
          color="blue"
          textEllipsis
        />
        <br />
        <span className="messenger-notification__text">{message}</span>
      </div>
    </div>
  );
};

Notification.propTypes = {
  nameOfParticipant: PropTypes.string,
  assessmentName: PropTypes.string,
  message: PropTypes.string,
  date: PropTypes.string,
  onClick: PropTypes.func,
};

export default Notification;
