import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import FileUploadItem from 'components/UploadFiles/fileUploadItem';
import Icon from 'ui/Icon';
import Button from 'ui/Button';

// REDUX
import { setFile } from 'components/ImportWizard/excel/reducers';

// CONSTANTS
import { generalMessages } from 'constants/messages';
import { ASSESSMENT_FILE_UPLOAD } from 'constants/assessmentFileUpload';

// UTILS
import { translate } from 'utils/index';
import { messages as AssessmentsMessages } from 'pages/Assessments/messages';
import { messages } from './messages';

// STYLE
import './index.scss';

const UploadFileArea = ({ uploadedFile, setUploadedFile }) => {
  const dispatch = useDispatch();

  const onFileRemove = () => {
    setUploadedFile(undefined);
  };

  const onDrop = (acceptedFile) => {
    let type = acceptedFile[0]?.name?.split('.')?.[1];

    if (!type) {
      return null;
    }

    if (type === 'doc' || type === 'docx') {
      type = ASSESSMENT_FILE_UPLOAD.WORD.type;
    }
    if (type === 'xls' || type === 'xlsx') {
      type = ASSESSMENT_FILE_UPLOAD.EXCEL.type;
    }

    dispatch(
      setFile({
        // FIND-2284 temporary view of doc files implemented for all types
        type: ASSESSMENT_FILE_UPLOAD[ASSESSMENT_FILE_UPLOAD.EXCEL.type.toUpperCase()].type,
        name: acceptedFile[0].name,
      }),
    );

    return setUploadedFile(acceptedFile[0]);
  };

  const uploadedFileType = useMemo(
    () => uploadedFile && uploadedFile.name.split('.')[1],
    [uploadedFile],
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
  });
  return (
    <div className="upload-file-area">
      <div className="upload-file-area__title">
        <h2>{translate(AssessmentsMessages.importWizardMenuModalTitle)}</h2>
      </div>
      <div className="upload-file-area__body">
        <p className="upload-file-area__upper-text">{translate(messages.uploadFileAreaText)}</p>
        <div
          className={`upload-file-area__drop-area ${isDragActive && 'dragged-area'}`}
          {...getRootProps()}
        >
          <div
            className={`upload-file-area__drop-area-content ${isDragActive && 'dragged-content'}`}
          >
            <input {...getInputProps()} multiple={false} accept=".xlsx" />
            <Icon icon="upload-file" className="upload-file-area__icon" />
            <h3>{translate(messages.uploadFileAreaTitle)}: </h3>
            <span className="upload-file-area__supported-types">
              {translate(messages.uploadFileAreaSupportedTypes)}
            </span>
            {uploadedFile && (
              <FileUploadItem
                name={uploadedFile.name}
                type={uploadedFileType}
                handleRemove={onFileRemove}
                className="upload-file-area__file-item"
              />
            )}
            <Button
              width={120}
              data-test="upload-file-area-button-browse"
              beforeIcon="upload2"
              outline
              onClick={open}
              size="sm"
              name={translate(generalMessages.browse)}
              className="upload-file-area__browse-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

UploadFileArea.propTypes = {
  uploadedFile: PropTypes.any,
  setUploadedFile: PropTypes.func,
};

export default UploadFileArea;
