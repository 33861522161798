import React from 'react';
import PropType from 'prop-types';
import classNames from 'classnames';

import Button from 'ui/Button';
import { translate } from 'utils/index';
import { generalMessages as messages } from 'constants/messages';
import { useTheme } from 'hooks/theme';

import './footer.scss';

const Footer = ({
  leftComponent,
  leftButtonText,
  leftButtonOnClick,
  rightComponent,
  rightButtonText,
  rightButtonOnClick,
  rightButtonWidth,
  rightButtonType,
  noRightIcon,
  noLeftIcon,
  className,
  dynamicColor,
  leftButtonAdditionalProps,
  rightButtonAdditionalProps,
}) => {
  const { colorTheme } = useTheme();
  const composedClassName = classNames('card-with-logo-footer', className);

  return (
    <div className={composedClassName}>
      {leftComponent}
      {leftButtonOnClick && (
        <Button
          data-test="card-with-logo-footer-button-left"
          color="gray"
          size="sm"
          link
          beforeIcon={!noLeftIcon ? 'left-arrow' : ''}
          beforeIconClassName="card-with-logo-footer__left-button-icon"
          className="card-with-logo-footer__left-button"
          onClick={leftButtonOnClick}
          {...leftButtonAdditionalProps}
        >
          {leftButtonText || translate(messages.back)}
        </Button>
      )}
      <div>
        {(rightButtonOnClick || rightButtonType) && (
          <Button
            data-test="card-with-logo-footer-button-right"
            type={rightButtonType}
            color={dynamicColor ? colorTheme : 'pink'}
            size="sm"
            afterIcon={!noRightIcon ? 'right-arrow' : ''}
            width={rightButtonWidth}
            afterIconClassName="card-with-logo-footer__right-button-icon"
            className="card-with-logo-footer__right-button"
            onClick={rightButtonOnClick}
            noShadow
            {...rightButtonAdditionalProps}
          >
            {rightButtonText || translate(messages.next)}
          </Button>
        )}
        {rightComponent}
      </div>
    </div>
  );
};

Footer.propTypes = {
  leftComponent: PropType.any,
  leftButtonText: PropType.string,
  leftButtonOnClick: PropType.func,
  rightComponent: PropType.any,
  rightButtonText: PropType.string,
  rightButtonOnClick: PropType.func,
  rightButtonWidth: PropType.number,
  rightButtonType: PropType.string,
  noRightIcon: PropType.bool,
  noLeftIcon: PropType.bool,
  className: PropType.string,
  dynamicColor: PropType.bool,
  leftButtonAdditionalProps: PropType.object,
  rightButtonAdditionalProps: PropType.object,
};

Footer.defaultProps = {
  rightButtonWidth: 100,
  noRightIcon: false,
  noLeftIcon: false,
};

export default Footer;
