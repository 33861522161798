import { useContext } from 'react';
import { CustomerManageContext } from 'context/customerManageContext';

export function useCustomerManageContext() {
  const context = useContext(CustomerManageContext);
  if (!context) {
    throw new Error('CustomerManageContext not defined');
  }
  return context;
}
