export const messages = {
  okButtonText: {
    key: 'Word Import Wizard.okButtonText',
    defaultMessage: 'ok, got it',
  },
  text: {
    key: 'Word Import Wizard.text',
    defaultMessage:
      'We’re working on it, might take up to 48 hours, and we’ll notify you when it’s ready',
  },
};

export default {
  prefix: 'Word Import Wizard',
  messages,
};
