import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso';
import { v4 as uuidv4 } from 'uuid';

import { translate } from 'utils/index';

import { messages } from 'pages/Editor/messages';
import AnswerType from './answerType';
import './list.scss';

const List = ({ groupCounts, rows, search, select, selectedType, selected, onOk }) => {
  const [key, setKey] = useState(uuidv4());

  useEffect(() => {
    setKey(uuidv4());
  }, [selectedType, search]);

  const groups = [
    translate(messages.radioButton),
    translate(messages.checkbox),
    translate(messages.dropDown),
    translate(messages.multiSelect),
  ];

  const renderGroupContent = (index) => {
    return (
      <div className="editor-answer-bank-list__group">
        <div className="editor-answer-bank-list__group-title">{groups[index]}:</div>
        <div className="editor-answer-bank-list__group-weight">{translate(messages.weight)}</div>
      </div>
    );
  };

  const renderItemContent = (index) => {
    return (
      <AnswerType
        onOk={onOk}
        answerType={rows[index]}
        find={search}
        select={select}
        selected={selected && selected === rows[index]?.id}
      />
    );
  };

  return (
    <div className="editor-answer-bank-list">
      {groupCounts ? (
        <GroupedVirtuoso
          key={key}
          style={{ height: '100%' }}
          groupCounts={groupCounts}
          groupContent={renderGroupContent}
          itemContent={renderItemContent}
        />
      ) : (
        <Virtuoso
          key={key}
          style={{ height: '100%' }}
          totalCount={rows.length}
          data={rows}
          itemContent={renderItemContent}
        />
      )}
    </div>
  );
};

List.propTypes = {
  groupCounts: PropTypes.array,
  rows: PropTypes.array,
  search: PropTypes.string,
  onOk: PropTypes.func.isRequired,
  select: PropTypes.func,
  selected: PropTypes.string,
  selectedType: PropTypes.number,
};

export default List;
