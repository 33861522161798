import React, { useState } from 'react';

import loginLogo from 'assets/images/login-logo.png';
import CardWithLogo from 'components/CardWithLogo';

import ForgotPasswordSuccess from './forms/forgotPasswordSuccess';
import { LOGIN_FORM_MODE } from './lib';
import ForgotPasswordForm from './forms/forgotPasswordForm';
import SecondAuthentication from './forms/secondAuthentication';
import LoginForm from './forms/loginForm';

const Login = () => {
  const [mode, setMode] = useState(LOGIN_FORM_MODE.LOGIN);

  return (
    <div className="login-page">
      {mode === LOGIN_FORM_MODE.LOGIN && (
        <CardWithLogo logo={loginLogo}>
          <LoginForm setMode={setMode} />
        </CardWithLogo>
      )}

      {mode === LOGIN_FORM_MODE.FORGOT_PASSWORD && (
        <CardWithLogo logo={loginLogo}>
          <ForgotPasswordForm setMode={setMode} />
        </CardWithLogo>
      )}

      {mode === LOGIN_FORM_MODE.OTP && (
        <CardWithLogo logo={loginLogo}>
          <SecondAuthentication setMode={setMode} />
        </CardWithLogo>
      )}

      {mode === LOGIN_FORM_MODE.FORGOT_PASSWORD_SUCCESS && (
        <CardWithLogo logo={loginLogo}>
          <ForgotPasswordSuccess setMode={setMode} />
        </CardWithLogo>
      )}
    </div>
  );
};

export default Login;
