import excelLogo from 'assets/svgs/excel-logo.svg';
import wordLogo from 'assets/svgs/word-logo.svg';
import csvLogo from 'assets/svgs/csv-logo.svg';

export const ASSESSMENT_FILE_UPLOAD = {
  EXCEL: {
    type: 'EXCEL',
    title: 'Excel',
    imagePath: excelLogo,
    extensions: ['xls', 'xlsx'],
  },
  WORD: {
    type: 'WORD',
    title: 'Word',
    imagePath: wordLogo,
    extensions: ['doc', 'docx'],
  },
  CSV: {
    type: 'CSV',
    title: 'CSV',
    imagePath: csvLogo,
    extensions: ['csv'],
  },
};
