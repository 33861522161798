import i18next from 'i18next';
import { API_STATUS } from 'constants/api';
import { KeyCodes } from 'constants/keyCodes';
import { ENV } from 'configs/env';

export const isDevelopment = () => ENV.NODE_ENV === 'development';

export const isProduction = () => ENV.NODE_ENV === 'production';

export const isProductionDomain = () => window.location.hostname === 'app.findings.co';

export const isTesting = () => ENV.NODE_ENV === 'test';

export const isRequestFailed = (status) => status === API_STATUS.FAILED;

export const DEV_MODE_LS_KEY = 'devMode';

export const TRANSLATIONS_LS_KEY = 'translations';

export const translate = (messageObj, options) => {
  if (!messageObj) {
    return 'ERROR';
  }
  if (localStorage.getItem(DEV_MODE_LS_KEY)) {
    return i18next.t(messageObj.key, options);
  }
  const translationData = localStorage.getItem(TRANSLATIONS_LS_KEY)
    ? JSON.parse(localStorage.getItem(TRANSLATIONS_LS_KEY))
    : null;

  if (!translationData || !translationData[messageObj?.key]) {
    return i18next.t(messageObj.defaultMessage, options);
  }

  return i18next.t(translationData[messageObj.key], options);
};

export const isKeyCodeAnEnterCode = ({ key }) => key === KeyCodes.Enter;

export const getBaseUrl = () => {
  if (isProduction()) {
    return `${window.location.origin}/api`;
  }

  return ENV.API_URL;
};

export const composeRequest = ({ endpoint, useNewUrl = true }) => {
  const baseUrl = getBaseUrl();
  const fullRequestUrl = `${baseUrl}/${endpoint}`;

  if (useNewUrl) {
    return new URL(fullRequestUrl);
  }

  return fullRequestUrl;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
