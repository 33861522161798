import { Apps } from 'constants/apps';
import { AppStatus } from 'constants/appStatus';

import AzureIcon from 'assets/svgs/azure-icon.svg';
import AwsIcon from 'assets/svgs/aws-icon-2.svg';
import RiskReconIcon from 'assets/svgs/risk-recon-icon.svg';
import SSCIcon from 'assets/svgs/ssc-icon.svg';
import IBMIcon from 'assets/svgs/ibm-x-force-icon.svg';
import QualysIcon from 'assets/svgs/qualys-icon.svg';

/* CONSTANTS */

export const appIcons = {
  [Apps.azure]: AzureIcon,
  [Apps.aws]: AwsIcon,
  [Apps.riskRecon]: RiskReconIcon,
  [Apps.ssc]: SSCIcon,
  [Apps.ibm]: IBMIcon,
  [Apps.qualys]: QualysIcon,
};

export const appsStatuses = {
  [AppStatus.inactive]: 'inactive',
  [AppStatus.inProgress]: 'active',
  [AppStatus.active]: 'active',
  [AppStatus.noResults]: 'active',
};

export const appsNames = {
  [Apps.azure]: 'azure',
  [Apps.aws]: 'aws',
  [Apps.riskRecon]: 'risc-recon',
  [Apps.ssc]: 'ssc',
  [Apps.ibm]: 'ibm',
  [Apps.qualys]: 'qualys',
};
