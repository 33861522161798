import { createSelector } from '@reduxjs/toolkit';
import { isFindingTriggeredByTrigger } from 'pages/Assessment/lib';

export const seletCategoriesWithFindings = createSelector(
  ({ assessment }) => assessment?.assessment?.categories,
  (categories) => {
    const categoriesWithFindings = [];
    categories?.forEach((category) => {
      if (category.controls?.some((control) => control.findings?.length > 0)) {
        categoriesWithFindings.push({ value: category.id, label: category.title });
      }
    });

    return categoriesWithFindings;
  },
);

export const selectAllFindings = createSelector(
  ({ assessment }) => assessment?.assessment?.categories,
  (categories) => {
    const findings = [];

    categories?.forEach((category) => {
      category.controls?.forEach((control) => {
        control.findings?.forEach((finding) => {
          if (
            finding.enabled ||
            isFindingTriggeredByTrigger(finding?.triggers, finding.control?.answer)
          ) {
            findings.push({
              ...finding,
              categoryId: category.id,
              categoryName: category.title,
              control,
            });
          }
        });
      });
    });

    return findings;
  },
);

export const selectFinding = createSelector(
  selectAllFindings,
  ({ assessmentFindings }) => assessmentFindings.selectedFinding,
  (findings, selectedFinding) => {
    const finding = (findings || []).find(({ id }) => selectedFinding === id);
    return finding || {};
  },
);

export const selectPreviousFinding = createSelector(
  selectAllFindings,
  ({ assessmentFindings }) => assessmentFindings.selectedFinding,
  (findings, selectedFindingId) => {
    const index = findings.findIndex((finding) => finding.id === selectedFindingId);
    return findings[index - 1] || null;
  },
);
