import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTemplate } from 'pages/Editor/selectors';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { mixpanelTrackEditAssessmentTemplate } from 'utils/mixpanel';

const parseCategoryChanges = (currentCategories, previousCategories) => {
  const categoriesDiff = new Map();
  const controlsDiff = new Map();

  currentCategories.forEach((currentCategory) => {
    let prevCategory = previousCategories.find((prev) => prev.id === currentCategory.id);

    if (!prevCategory) {
      // DIFF FOR NEW CATEGORY ADDED
      categoriesDiff.set(currentCategory.id, {
        id: currentCategory.id,
        title: currentCategory.title,
      });
    } else {
      currentCategory.controls.forEach((currentControl) => {
        let prevControl = prevCategory.controls.find((prev) => prev.id === currentControl.id);

        if (!prevControl) {
          // DIFF FOR NEW CONTROL ADDED
          controlsDiff.set(currentControl.id, {
            id: currentControl.id,
            title: currentControl.title,
            answer_type_idx: currentControl.answer_type_idx,
            answer: currentControl.answer,
            categoryId: currentControl.categoryId,
          });
        } else {
          // DIFF FOR CHANGES IN CONTROL
          if (
            currentControl.answer !== prevControl.answer ||
            currentControl.title !== prevControl.title
          ) {
            const diffControl = {
              id: currentControl.id,
              title: currentControl.title,
              previous_title:
                currentControl.title !== prevControl.title ? prevControl.title : undefined,
              answer_type_idx: currentControl.answer_type_idx,
              answer: currentControl.answer,
              categoryId: currentControl.categoryId,
              previous_answer: prevControl.answer,
            };
            controlsDiff.set(currentControl.id, diffControl);
          }
        }
      });

      // DIFF FOR REMOVED CONTROLS
      prevCategory.controls.forEach((prevControl) => {
        let currentControl = currentCategory.controls.find((curr) => curr.id === prevControl.id);
        if (!currentControl) {
          controlsDiff.set(prevControl.id, {
            answer: prevControl.answer,
            id: prevControl.id,
            title: prevControl.title,
            answer_type_idx: prevControl.answer_type_idx,
            removed: true,
          });
        }
      });

      // DIFF FOR CHANGES IN CATEGORY
      if (currentCategory.title !== prevCategory.title) {
        const categoryDiff = {
          id: currentCategory.id,
          title: currentCategory.title,
          previous_title:
            currentCategory.title !== prevCategory.title ? prevCategory.title : undefined,
        };
        categoriesDiff.set(currentCategory.id, categoryDiff);
      }
    }
  });

  // DIFF FOR REMOVED CATEGORIES
  previousCategories.forEach((prevCategory) => {
    let currentCategory = currentCategories.find((curr) => curr.id === prevCategory.id);
    if (!currentCategory) {
      categoriesDiff.set(prevCategory.id, {
        id: prevCategory.id,
        title: prevCategory.title,
        controls: prevCategory.controls,
        removed: true,
      });
    }
  });
  return [...categoriesDiff.values(), ...controlsDiff.values()];
};

export const useMixpanelEditAssessmentTrack = () => {
  const { categories = [] } = useSelector(selectTemplate);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const categoriesBaseRef = useRef(null);
  const [changesToTrack, setChangesToTrack] = useState([]);

  useEffect(() => {
    if (categories.length === 0) return;

    if (categoriesBaseRef.current === null) {
      categoriesBaseRef.current = categories;
    } else {
      const changes = parseCategoryChanges(categories, categoriesBaseRef.current);
      if (changes.length > 0) {
        setChangesToTrack((prevChanges) => {
          const filteredChanges = prevChanges.filter((change) => {
            return change.id !== changes[0].id;
          });
          return [...filteredChanges, ...changes];
        });
        categoriesBaseRef.current = categories;
      }
    }
  }, [categories]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (changesToTrack.length > 0) {
        mixpanelTrackEditAssessmentTemplate(
          isMyVendorsTabSelected ? 'vendors' : 'customer',
          changesToTrack,
        );
        setChangesToTrack([]);
      }
    }, 15000);

    return () => clearInterval(intervalId);
  }, [changesToTrack]);
};
