import React from 'react';
import { useSelector } from 'react-redux';

// PAGES & COMPONENTS:
import ScoreBenchmark from 'components/AssessmentScoreBenchmark';
import AssessmentStatus from 'pages/Assessments/columns/assessmentStatusColumn';

// UTILS:
import { isAssessmentPending } from 'utils/assessment';
import { translate } from 'utils/index';
import { isAssessmentFinalized } from 'utils/isAssessmentFinalized';

// IMAGES:
import openFindingsIcon from 'assets/images/open-findings.svg';
import recommendationsIcon from 'assets/images/recommendations.svg';
import overdueRecommendationsIcon from 'assets/images/overdue-recommendations.svg';

// REDUX:
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { selectAssessment } from 'pages/Assessment/selectors';

// TRANSLATIONS:
import { messages } from 'pages/Assessment/messages';

// STYLES:
import './statusBar.scss';

const StatusBar = () => {
  const assessment = useSelector(selectAssessment);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const { total_score: totalScore, findings = {} } = assessment;
  return (
    <div className="assessment-finalized-status-bar">
      {!(isMyVendorsTabSelected && isAssessmentFinalized(assessment.status)) && (
        <div className="assessment-finalized-status-bar__item assessment-finalized-status-bar__item--score-benchmark">
          <div className="assessment-finalized-status-bar__item-label">
            {translate(messages.score)}
            {isAssessmentPending(assessment) ? ' (pending)' : ''}:
          </div>
          <div className="assessment-finalized-status-bar__item-content">
            <ScoreBenchmark
              className="assessment-finalized-status-bar__score-benchmark"
              score={totalScore?.score}
              benchmark={totalScore?.benchmark}
            />
          </div>
        </div>
      )}
      <div className="assessment-finalized-status-bar__item">
        <div className="assessment-finalized-status-bar__item-label">
          {translate(messages.status)}:
        </div>
        <div className="assessment-finalized-status-bar__item-content">
          <AssessmentStatus assessment={assessment} totalScore={totalScore} />
        </div>
      </div>
      <div className="assessment-finalized-status-bar__item assessment-finalized-status-bar__item--overdue-findings">
        <div className="assessment-finalized-status-bar__counter-wrapper">
          <img src={openFindingsIcon} alt="Open findings" />
          <div className="assessment-finalized-status-bar__counter-label">
            {translate(messages.openFindings)}:
            <div className="assessment-finalized-status-bar__item-content">
              <div className="assessment-finalized-status-bar__open-findings">
                {findings['open-findings']}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="assessment-finalized-status-bar__item">
        <div className="assessment-finalized-status-bar__counter-wrapper">
          <img src={recommendationsIcon} alt="Recommendations" />
          <div className="assessment-finalized-status-bar__counter-label">
            {translate(messages.recommendations)}:
            <div className="assessment-finalized-status-bar__item-content">
              <div className="assessment-finalized-status-bar__open-findings">
                {findings['open-recs']}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="assessment-finalized-status-bar__item assessment-finalized-status-bar__item--overdue-recommendations">
        <div className="assessment-finalized-status-bar__counter-wrapper">
          <img src={overdueRecommendationsIcon} alt="Overdue recommendations" />
          <div className="assessment-finalized-status-bar__counter-label">
            {translate(messages.overdueRecommendations)}:
            <div className="assessment-finalized-status-bar__item-content">
              <div className="assessment-finalized-status-bar__open-findings">
                {findings['overdue-recs']}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusBar;
