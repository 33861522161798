import groupBy from 'lodash/groupBy';
import { getOffset } from 'utils/html';
import { groupControlsByCategory } from 'utils/triggers';

export const weightDataSource = Array.from(Array(101).keys()).map((number) => ({
  value: number / 100,
  label: `${number}%`,
}));

export function compareDataSource(a, b) {
  const valueA = a.value;
  const valueB = b.value;

  let comparison = 0;

  if (valueA > valueB) {
    comparison = 1;
  } else if (valueA < valueB) {
    comparison = -1;
  }

  return comparison;
}

export function getWeightDataSource(currentValue) {
  const valueExistInDataSource = weightDataSource.find(({ value }) => value === currentValue);

  if (currentValue && !valueExistInDataSource) {
    return [...weightDataSource, { value: currentValue, label: `${currentValue}%` }].sort(
      compareDataSource,
    );
  }

  return weightDataSource;
}

export const benchmarkDataSource = Array.from(Array(101).keys()).map((number) => ({
  value: number,
  label: `${number}`,
}));

export function getBenchmarkDataSource(currentValue) {
  const valueExistInDataSource = benchmarkDataSource.find(({ value }) => currentValue === value);

  if (currentValue && !valueExistInDataSource) {
    return [...benchmarkDataSource, { value: currentValue, label: `${currentValue}` }].sort(
      compareDataSource,
    );
  }

  return benchmarkDataSource;
}

export function getControlCategoryId(controlId, categories) {
  const control = categories.flatMap(({ controls }) => controls).find(({ id }) => id === controlId);

  return control.categoryId;
}

export function groupTriggersByCategories(triggers, categories) {
  const categoriesWithTriggers = triggers.map((trigger) => ({
    trigger,
    categoryId: getControlCategoryId(trigger, categories),
  }));

  return groupBy(categoriesWithTriggers, 'categoryId');
}

export function scrollToControl(controlId) {
  setTimeout(() => {
    const controlElement = document.getElementById(`control-${controlId}`);
    const controlElementOffset = getOffset(controlElement);

    window.scroll({
      top: controlElementOffset.top - 70,
      left: 0,
      behavior: 'smooth',
    });
  });
}

export function mergeCategories(targetCategories, sourceCategories) {
  const categories = sourceCategories.reduce((acc, category) => {
    const targetCategory = targetCategories.find(({ id }) => id === category.id);

    if (targetCategory) {
      const newCategory = {
        ...category,
        controls: category.controls.concat(targetCategory.controls),
      };
      acc.push(newCategory);
      return acc;
    }
    acc.push(category);
    return acc;
  }, []);

  const filteredTargetCategories = targetCategories.filter(
    ({ id }) => !categories.some((category) => category.id === id),
  );

  return categories.concat(filteredTargetCategories);
}

export function getControlsByCategoryToEnable(controlIds, categories) {
  const allControls = categories.flatMap((category) => category.controls);
  const controls = controlIds.map((id) => {
    const { categoryId } = allControls.find((control) => control.id === id);
    return {
      id,
      categoryId,
      enabled: true,
    };
  });

  return groupControlsByCategory(controls);
}

export function getControlsByCategoryToDisable(controlIds, categories) {
  const allControls = categories.flatMap((category) => category.controls);
  const controls = controlIds.map((id) => {
    const { categoryId } = allControls.find((control) => control.id === id);
    return {
      id,
      categoryId,
      enabled: false,
    };
  });

  return groupControlsByCategory(controls);
}

export const findControlIndex = (categories, categoryId, id) => {
  return categories
    .find((category) => category.id === categoryId)
    .controls.findIndex((control) => control.id === id);
};
