export const messages = {
  active: {
    key: 'archiveToggle.active',
    defaultMessage: 'Active',
  },
  archived: {
    key: 'archiveToggle.archived',
    defaultMessage: 'Archived',
  },
};

export default {
  prefix: 'archiveToggle',
  messages,
};
