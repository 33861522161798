import React from 'react';
import PropType from 'prop-types';

import Select from 'ui/Select';
import { LANGUAGES } from 'utils/languages';
import { checkIfFindingsUser } from 'utils/isFindingsUser';
import { useSelector } from 'react-redux';
import { selectProfile } from 'pages/Profile/selectors';
import { translate } from 'utils/index';
import { messages } from './messages';

import './index.scss';

const LanguageSelect = ({ value, onSelect }) => {
  const {
    user: { email },
  } = useSelector(selectProfile);
  const filteredLanguages = LANGUAGES.filter((lang) => {
    if (!checkIfFindingsUser(email)) {
      return lang.code !== 'fi';
    }
    return true;
  });

  return (
    <div className="language-select">
      <Select
        name="lang"
        value={value}
        placeholder={translate(messages.placeholder)}
        id="lang-select"
        onSelect={onSelect}
        options={filteredLanguages}
      />
    </div>
  );
};

LanguageSelect.propTypes = {
  value: PropType.number,
  onSelect: PropType.func,
};

export default LanguageSelect;
