import React from 'react';
import Icon from 'ui/Icon';
import classNames from 'classnames';
import { LOGO_BASE_URL } from 'constants/general';
import PropTypes from 'prop-types';

import './index.scss';

const OrganizationLogo = ({ defaultLogo, path, size, className, isFullPath, circle }) => {
  const orgLogoClasses = classNames('organization-logo', className, {
    [`organization-logo--${size}`]: true,
    'organization-logo--img-bg': path,
    'organization-logo--circle': circle,
  });

  const source = path ? `${LOGO_BASE_URL}${path}` : '';
  if (!path && !defaultLogo) {
    return null;
  }
  return (
    <div
      data-test={`organization-logo-div-logo-image-${path}`}
      className={orgLogoClasses}
      style={{ backgroundImage: `url(${isFullPath ? path : source})` }}
    >
      {defaultLogo && <Icon icon="organization2" className="organization-logo__default-icon" />}
    </div>
  );
};

OrganizationLogo.propTypes = {
  defaultLogo: PropTypes.bool,
  path: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'none']),
  className: PropTypes.string,
  isFullPath: PropTypes.bool,
  circle: PropTypes.bool,
};

OrganizationLogo.defaultProps = {
  size: 'sm',
};

export default OrganizationLogo;
