import React from 'react';
import PropTypes from 'prop-types';

import FileIcon from 'ui/FileIcon';
import { getFileExtension } from 'utils/files';

const FileComponent = ({ evidenceId, fileTitle, onClick }) => {
  const renderIcon = () => {
    const type = getFileExtension(fileTitle);

    return (
      <div className="evidence-column__file__icon">
        <FileIcon type={type} />
      </div>
    );
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      role="button"
      className="evidence-column__file"
      onClick={onClick}
      data-test="open-file-preview"
    >
      {renderIcon()}
      {fileTitle}
    </a>
  );
};

FileComponent.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  evidenceId: PropTypes.string.isRequired,
  fileTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default FileComponent;
