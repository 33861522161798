import { translate } from 'utils/index';
import { messages } from './messages';

const calculateRiskValue = (impact, probability) => {
  const calculatedRisk = Number(impact) * Number(probability);
  return calculatedRisk ? calculatedRisk - 1 : 0;
};

export const getRiskValue = (impact, probability, riskArray) => {
  if (!riskArray) return null;
  const riskIndex = calculateRiskValue(impact, probability);
  return riskArray[riskIndex] || translate(messages.riskNotCalculated);
};

export const getRiskData = (impact, probability, riskArray) => {
  if (!riskArray) return null;
  const riskIndex = calculateRiskValue(impact, probability);
  return { riskValue: riskArray[riskIndex] || translate(messages.riskNotCalculated), riskIndex };
};
