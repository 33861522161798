import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Divider from 'ui/Divider';
import Form from 'ui/Form';
import { addFiles, onChangeControl, removeFile } from 'pages/SoBoPages/reducers';
import { selectSoBoPages } from 'pages/SoBoPages/selectors';
import { getCategoryControlsWithTriggered, triggerControls } from 'pages/Assessment/lib';
import Control from 'pages/Assessment/control';
import { parsePayload } from 'pages/SoBoPages/lib';

const AssessmentCategory = ({ category, answerTypes }) => {
  const dispatch = useDispatch();
  const {
    additionalData: { currentUploadedFiles },
  } = useSelector(selectSoBoPages);

  const [controlValues, setControlValues] = useState({});
  const [controls, setControls] = useState([]);

  useEffect(() => {
    setControls(category.controls);
  }, []);

  useEffect(() => {
    setControlValues(
      category.controls.reduce(
        (acc, control) => ({
          ...acc,
          [control.id]: control.answer,
        }),
        {},
      ),
    );
  }, [category]);

  const onChange = (name, value) => {
    setControlValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    dispatch(onChangeControl({ categoryId: category.id, controlId: name, answerValue: value }));
  };

  const handleUpload = async (files, controlId) => {
    dispatch(addFiles({ files, controlId, categoryId: category.id }));
  };

  const handleRemove = async (fileId, controlId) => {
    dispatch(removeFile({ fileId, categoryId: category.id, controlId }));
  };

  const onAnswerChange = (value, controlId) => {
    dispatch(
      onChangeControl({ categoryId: category.id, controlId: controlId, answerValue: value }),
    );

    return setControls((state) => {
      const newControls = state.map((control) =>
        control.id === controlId ? { ...control, answer: value } : control,
      );
      const newControl = newControls.find((control) => control.id === controlId);
      const categoryTriggeredControls = triggerControls(newControl, newControls);
      const newControlsWithTriggered = getCategoryControlsWithTriggered(
        newControls,
        categoryTriggeredControls,
      );
      return newControlsWithTriggered;
    });
  };

  return (
    <div key={category.id}>
      <h2 key={category.title}>{category.title}</h2>
      <Divider hidden />
      {controls
        .filter(({ enabled }) => enabled)
        .map((control, index) => {
          const payload = parsePayload(
            control,
            controlValues,
            currentUploadedFiles,
            onChange,
            handleUpload,
            handleRemove,
          );
          return (
            <Form.Item key={`form-item-${control.id}`}>
              <Control
                key={control.id}
                control={control}
                disabled={false}
                onAnswerChange={onAnswerChange}
                index={index}
                showcaseAssessmentPreview={false}
                customAnswerTypes={answerTypes}
                customPayload={payload}
                hideChat
              />
            </Form.Item>
          );
        })}
      <Divider key={`divider-${category.title}`} size="md" />
    </div>
  );
};

AssessmentCategory.propTypes = {
  category: PropTypes.object,
  answerTypes: PropTypes.object,
};
export default AssessmentCategory;
