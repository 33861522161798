import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { loginUser } from 'api/authentication';
import { MFA_METHOD } from 'constants/api';
import { mixpanelTrackLogin } from 'utils/mixpanel';

const initialState = {
  email: null,
  password: null,
  mfaMethod: MFA_METHOD.NONE,
  loading: false,
  sso: false,
  ssoToken: '',
  phone: null,
  forced2fa: false,
};

const reducers = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoginFormData: (state, { payload: { key, value } }) => {
      state[key] = value;
    },
    setLoginSSO: (state, { payload }) => {
      state.sso = payload?.sso || false;
      state.ssoToken = payload?.ssoToken || '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addMatcher(
        isAnyOf(loginUser.fulfilled, loginUser.rejected),
        (state, { payload: { status, mfa_method: mfaMethod, forced2fa } }) => {
          state.status = status;
          if (mfaMethod) {
            state.mfaMethod = mfaMethod;
          }
          state.loading = false;
          mixpanelTrackLogin(state.email);
          if (mfaMethod === undefined && !forced2fa && status !== 0) state.password = null;
        },
      );
  },
});

export const { setLoginFormData, setLoginSSO } = reducers.actions;

export default reducers;
