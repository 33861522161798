export const messages = {
  titleUploadFilesPreview: {
    key: 'UploadFilesPreview.titleUploadFilesPreview',
    defaultMessage: 'Upload files:',
  },
};

export default {
  prefix: 'UploadFilesPreview',
  messages,
};
