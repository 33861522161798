import { selectFinding } from 'pages/Assessment/finalized/findings/reducers';
import { mixpanelTrackFindingViewed } from 'utils/mixpanel';
import ROUTES from 'constants/routes';
import { shouldAppNavigateToContinuousMonitoring } from 'utils/appsUtils';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { useDispatch } from 'react-redux';

function useFindingsRow() {
  const dispatch = useDispatch();
  const appNavigate = useAppNavigate();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  return ({ id: findingId, assessmentId, app, customerAssessmentGroup, relation }) => ({
    onClick: async () => {
      await dispatch(selectFinding(findingId));

      mixpanelTrackFindingViewed({
        relation: relation || 'none',
        source: 'findings table',
        streamDirection: isMyVendorsTabSelected ? 'downstream' : 'upstream',
      });

      if (customerAssessmentGroup) {
        return appNavigate(
          isMyVendorsTabSelected
            ? ROUTES.VENDOR_SHOWCASE_PREVIEW
            : ROUTES.CUSTOMER_SHOWCASE_PREVIEW,
          {
            showcaseId: customerAssessmentGroup,
          },
        );
      }

      if (shouldAppNavigateToContinuousMonitoring(app)) {
        return appNavigate(
          isMyVendorsTabSelected
            ? ROUTES.VENDOR_CONTINUOUS_MONITORING
            : ROUTES.CUSTOMER_CONTINUOUS_MONITORING,
          { assessmentId, appId: app },
        );
      }

      return appNavigate(
        isMyVendorsTabSelected
          ? ROUTES.VENDOR_ASSESSMENT_FINDINGS
          : ROUTES.CUSTOMER_ASSESSMENT_FINDINGS,
        { assessmentId },
        undefined,
        false,
        { findingId: findingId },
      );
    },
  });
}

export default useFindingsRow;
