import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/Icon';
import ColoredResult from 'components/AppsMonitoring/coloredResult';
import { Apps } from 'constants/apps';

function AppResults({ results, appId }) {
  const resultsView = useMemo(() => {
    const values = Object.keys(results).map((key) => ({
      name: key,
      value: results[key],
    }));

    return values.map((result) => {
      const isRiskReconRating = appId === Apps.riskRecon && result.name === 'rating';

      return (
        <div key={result.name} className="continuous-monitoring-tab__app-property-wrapper">
          <label className="continuous-monitoring-tab__app-property-label">{result.name}:</label>
          <div className="continuous-monitoring-tab__app-property" data-test={`app-${result.name}`}>
            {isRiskReconRating && results?.rating && results?.rating[1] !== '' ? (
              <ColoredResult results={result} />
            ) : (
              result.value || '-'
            )}
            {result.name === 'findings' && (
              <Icon className="continuous-monitoring-tab__app-findings-icon" icon="flag" />
            )}
          </div>
        </div>
      );
    });
  }, [results, appId]);

  return <>{resultsView}</>;
}

AppResults.propTypes = {
  results: PropTypes.object,
  appId: PropTypes.string,
};

export default AppResults;
