import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

import NewUserButton from 'pages/Organization/edit/newUserButton';
import RoleSelect from 'pages/Organization/edit/roleSelect';
import Input from 'ui/Input';
import Table from 'ui/Table';
import OrgEditActions from 'pages/Organization/edit/actions';
import { translate } from 'utils/index';
import { messages } from 'pages/Organization/messages';
import { selectProfile } from 'pages/Profile/selectors';
import { DATE_FORMAT } from 'constants/date';
import { Roles } from 'constants/roles';
import { selectOrganizationsExtended } from 'pages/Organization/selectors';
import { Sorter } from 'ui/Table/sorter';

import './userManagement.scss';

const UserManagement = () => {
  const { organizationId } = useParams();
  const [searchParams] = useSearchParams();
  const { organizations, loading } = useSelector(selectOrganizationsExtended);
  const { user } = useSelector(selectProfile);
  const [dataSource, setDataSource] = useState({
    data: [],
    searchValue: '',
  });

  const assessmentId = searchParams.get('assessment');
  const orgIndex = searchParams.get('orgIndex');

  const organizationOwner = useMemo(() => {
    const profiles = organizations[orgIndex].profiles || [];
    return profiles.length > 0
      ? profiles.find((profile) => {
          return profile.role === Roles.Owner;
        })
      : {};
  }, [organizations[orgIndex]?.profiles]);

  const currentUserRole = useMemo(() => {
    const profiles = organizations[orgIndex].profiles || [];
    return profiles.length > 0
      ? profiles.find((profile) => {
          return profile.email === user.email;
        }).role
      : {};
  }, [organizations[orgIndex]?.profiles]);

  const onChangeSearch = ({ target: { value } }) => {
    setDataSource((state) => ({
      ...state,
      searchValue: value,
    }));
  };

  useEffect(() => {
    setDataSource((state) => ({
      ...state,
      data: (organizations[orgIndex].profiles || []).map((profile) => ({
        id: profile.id,
        timestampCreated: profile.timestampCreated,
        fullname: profile.fullname,
        email: profile.email,
        role: profile.role,
        key: profile.id,
      })),
    }));
  }, [organizations[orgIndex]?.profiles]);

  const rolesSwitch = (role) => {
    switch (role) {
      case Roles.Admin:
        return <div> {translate(messages.Admin)} </div>;
      case Roles.BO:
        return <div> {translate(messages.BO)} </div>;
      case Roles.SO:
        return <div> {translate(messages.SO)} </div>;
      case Roles.Owner:
        return <div> {translate(messages.Owner)} </div>;
      case Roles.Reporter:
        return <div> {translate(messages.Reporter)} </div>;
      case Roles.Viewer:
        return <div> {translate(messages.Viewer)} </div>;
      case Roles.Auditor:
        return <div> {translate(messages.Auditor)} </div>;
      default:
        return <div />;
    }
  };

  const filteredData = useCallback(() => {
    return filter(dataSource.data, (profile) => {
      return (
        includes(profile.fullname.toLowerCase(), dataSource.searchValue.toLowerCase()) ||
        includes(profile.email.toLowerCase(), dataSource.searchValue.toLowerCase())
      );
    });
  }, [dataSource.searchValue, dataSource.data]);

  const columns = [
    {
      title: translate(messages.orgEditTabColTitleDate),
      key: 'timestampCreated',
      dataIndex: 'timestampCreated',
      width: 80,
      render: (timestampCreated) => moment(timestampCreated).format(DATE_FORMAT),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      title: translate(messages.orgEditTabColTitleName),
      key: 'name',
      dataIndex: 'fullname',
      width: 200,
      render: (fullname) => fullname,
      sorter: {
        compare: Sorter.STRING,
      },
    },
    {
      title: translate(messages.orgEditTabColTitleEmail),
      key: 'email',
      dataIndex: 'email',
      width: 100,
      render: (email) => email,
      sorter: {
        compare: Sorter.STRING,
      },
    },
    {
      title: translate(messages.orgEditTabColTitleRole),
      key: 'role',
      dataIndex: 'role',
      width: 90,
      render: (role, { id, email, fullname }) =>
        [Roles.Owner, Roles.Admin].includes(currentUserRole) && role !== Roles.Owner ? (
          <RoleSelect
            currentUserRole={currentUserRole}
            role={role}
            profileId={id}
            orgId={organizationId}
            orgIndex={orgIndex}
            userName={fullname || email}
          />
        ) : (
          rolesSwitch(role)
        ),
      sorter: {
        compare: Sorter.DEFAULT,
      },
    },
    {
      key: 'id',
      dataIndex: 'id',
      width: 120,
      render: (id, { fullname, email }) =>
        id !== organizationOwner?.id && (
          <OrgEditActions
            orgId={organizationId}
            userName={fullname || email}
            profileId={id}
            orgIndex={orgIndex}
            removeDisabled={currentUserRole !== Roles.Admin && currentUserRole !== Roles.Owner}
          />
        ),
    },
  ];

  return (
    <div className="user-management-tab">
      <div className="user-management-tab__top-section">
        <div style={{ width: 300 }}>
          <Input.Search value={dataSource.search} onChange={onChangeSearch} />
        </div>
        <NewUserButton orgId={organizationId} orgIndex={orgIndex} assessmentId={assessmentId} />
      </div>
      <div className="user-management-tab__table">
        <Table
          className="organizations-table"
          dataSource={filteredData()}
          columns={columns}
          sortDirections={['ascend', 'descend', 'ascend']}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default UserManagement;
