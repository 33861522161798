import React, { Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Apps } from 'constants/apps';

const LazyViewRiskRecon = React.lazy(() => import('./RiskRecon/riskReconModal'));
const LazyViewQualys = React.lazy(() => import('./qualysModal'));

function ViewModal({ app, isVisible, setVisibility }) {
  const displayModal = useMemo(() => {
    switch (app.id) {
      case Apps.riskRecon:
        return <LazyViewRiskRecon app={app} isVisible={isVisible} setVisibility={setVisibility} />;
      case Apps.qualys:
        return <LazyViewQualys app={app} isVisible={isVisible} setVisibility={setVisibility} />;
      default:
        return null;
    }
  }, [isVisible]);

  return <Suspense fallback={<div />}>{displayModal}</Suspense>;
}

ViewModal.propTypes = {
  app: PropTypes.object,
  isVisible: PropTypes.bool,
  setVisibility: PropTypes.func,
};

export default ViewModal;
