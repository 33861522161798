import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import './findingsList.scss';

const FindingsList = ({ children, onDrop }) => {
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: 'finding',
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const className = classNames('editor-findings-list', {
    'editor-findings-list--over': isOver,
    'editor-findings-list--active': onDrop && canDrop,
  });

  return (
    <div className={className} ref={onDrop ? dropRef : undefined}>
      {children}
    </div>
  );
};

FindingsList.propTypes = {
  children: PropTypes.node,
  onDrop: PropTypes.func,
};

export default FindingsList;
