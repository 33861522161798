export const messages = {
  findings: {
    key: 'ContinuousMonitoringAssessmentFindings.findings',
    defaultMessage: 'Findings',
  },
  continuousMonitoring: {
    key: 'ContinuousMonitoringAssessmentFindings.continuousMonitoring',
    defaultMessage: 'Continuous Monitoring',
  },
  description: {
    key: 'ContinuousMonitoringAssessmentFindings.description',
    defaultMessage: 'DESCRIPTION',
  },
  risk: {
    key: 'ContinuousMonitoringAssessmentFindings.risk',
    defaultMessage: 'RISK',
  },
  status: {
    key: 'ContinuousMonitoringAssessmentFindings.status',
    defaultMessage: 'STATUS',
  },
  recommendations: {
    key: 'ContinuousMonitoringAssessmentFindings.recommendations',
    defaultMessage: 'RECOMMENDATIONS',
  },
  noDescription: {
    key: 'ContinuousMonitoringAssessmentFindings.noDescription',
    defaultMessage: 'No description',
  },
  riskNotCalculated: {
    key: 'ContinuousMonitoringAssessmentFindings.riskNotCalculated',
    defaultMessage: 'Not Calculated',
  },
  finding: {
    key: 'ContinuousMonitoringAssessmentFindings.finding',
    defaultMessage: 'Finding',
  },
  question: {
    key: 'ContinuousMonitoringAssessmentFindings.question',
    defaultMessage: 'QUESTION',
  },
  answer: {
    key: 'ContinuousMonitoringAssessmentFindings.answer',
    defaultMessage: 'ANSWER',
  },
  impact: {
    key: 'ContinuousMonitoringAssessmentFindings.impact',
    defaultMessage: 'IMPACT',
  },
  probability: {
    key: 'ContinuousMonitoringAssessmentFindings.probability',
    defaultMessage: 'PROBABILITY',
  },
  choose: {
    key: 'ContinuousMonitoringAssessmentFindings.choose',
    defaultMessage: 'Choose',
  },
  goToQuestion: {
    key: 'ContinuousMonitoringAssessmentFindings.goToQuestion',
    defaultMessage: 'Go to question',
  },
  removeFinding: {
    key: 'ContinuousMonitoringAssessmentFindings.removeFinding',
    defaultMessage: 'Remove finding',
  },
  removeFindingPopupText: {
    key: 'AssessmentFindings.removeFindingPopupText',
    defaultMessage: 'All finding information will be lost',
  },
  removeFindingPopupHeaderText: {
    key: 'AssessmentFindings.removeFindingPopupHeaderText',
    defaultMessage: 'Delete finding?',
  },
  notSpecified: {
    key: 'ContinuousMonitoringAssessmentFindings.notSpecified',
    defaultMessage: 'Not specified',
  },
  addFinding: {
    key: 'ContinuousMonitoringAssessmentFindings.addFinding',
    defaultMessage: 'ADD FINDING',
  },
  closeFindingTitle: {
    key: 'ContinuousMonitoringAssessmentFindings.closeFindingTitle',
    defaultMessage: 'Are you sure you want to close the finding?',
  },
  yesClose: {
    key: 'ContinuousMonitoringAssessmentFindings.yesClose',
    defaultMessage: 'Yes, close',
  },
  enterNote: {
    key: 'ContinuousMonitoringAssessmentFindings.enterNote',
    defaultMessage: 'Enter note',
  },
  noteRequired: {
    key: 'ContinuousMonitoringAssessmentFindings.noteRequired',
    defaultMessage: 'Note required',
  },
  domainName: {
    key: 'ContinuousMonitoringAssessmentFindings.domainName',
    defaultMessage: 'Domain name',
  },
  hostName: {
    key: 'ContinuousMonitoringAssessmentFindings.hostName',
    defaultMessage: 'Host name',
  },
  ipAddress: {
    key: 'ContinuousMonitoringAssessmentFindings.ipAddress',
    defaultMessage: 'IP address',
  },
  dataDescription: {
    key: 'ContinuousMonitoringAssessmentFindings.dataDescription',
    defaultMessage: 'Data description',
  },
  dataValue: {
    key: 'ContinuousMonitoringAssessmentFindings.dataValue',
    defaultMessage: 'Data value',
  },
  dataContext: {
    key: 'ContinuousMonitoringAssessmentFindings.dataContext',
    defaultMessage: 'Context',
  },
  firstSeen: {
    key: 'ContinuousMonitoringAssessmentFindings.firstSeen',
    defaultMessage: 'First seen',
  },
  id: {
    key: 'ContinuousMonitoringAssessmentFindings.id',
    defaultMessage: 'ID',
  },
  app: {
    key: 'ContinuousMonitoringAssessmentFindings.app',
    defaultMessage: 'app',
  },
  closeFindingText: {
    key: 'ContinuousMonitoringAssessmentFindings.closeFindingText',
    defaultMessage:
      'There are open recommendations to this finding, closing the finding will close all recommendations. Please indicate way the finding was closed (this is an internal note, only shared in your organization.',
  },
  categorySelectLabel: {
    key: 'AssessmentFindings.categorySelectLabel',
    defaultMessage: 'Category',
  },
};

export default {
  prefix: 'ContinuousMonitoringAssessmentFindings',
  messages,
};
