import React from 'react';
import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';
import NewUserActivation from 'pages/Activation';
import BaseLayoutPlaceholder from 'components/BaseLayout/placeholder';
import ROUTES from 'constants/routes';
import Onboarding from 'pages/Activation/Onboarding';

export const PUBLIC_ROUTES = {
  LOGIN: {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  LOGOUT: {
    path: ROUTES.LOGOUT,
    element: <Login />,
  },
  RESET_PASSWORD: {
    path: ROUTES.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  ACTIVATION: {
    path: ROUTES.ACTIVATION,
    element: <NewUserActivation />,
  },
  ONBOARD: {
    path: ROUTES.ONBOARD,
    element: <Onboarding />,
  },
  ACTIVATION_ASSESSMENT: {
    path: ROUTES.ACTIVATION_ASSESSMENT,
    element: <NewUserActivation />,
  },
  ONBOARD_ASSESSMENT: {
    path: ROUTES.ONBOARD_ASSESSMENT,
    element: <Onboarding />,
  },
  BUSINESS_OWNER_PAGE: {
    path: ROUTES.BUSINESS_OWNER_PAGE,
    element: <BaseLayoutPlaceholder>BUSINESS OWNER PAGE</BaseLayoutPlaceholder>,
  },
  SALES_OWNER_PAGE: {
    path: ROUTES.SALES_OWNER_PAGE,
    element: <BaseLayoutPlaceholder>SALES OWNER PAGE</BaseLayoutPlaceholder>,
  },
};
