import PropTypes from 'prop-types';
import { Dropdown } from 'antd';

import { ReactComponent as DownloadIcon } from 'assets/svgs/download-icon.svg';
import { ReactComponent as EditIcon } from 'assets/svgs/edit-icon.svg';
import { ReactComponent as PreviewIcon } from 'assets/svgs/preview-icon.svg';
import { ReactComponent as RenameIcon } from 'assets/svgs/rename-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/svgs/small-trash.svg';
import { KebabIcon } from 'components/Icons';

import { translate } from 'utils/index';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';
import './myFilesUploadItemDropdown.scss';

const MyFilesUploadItemDropdown = ({
  fileId,
  handleMetadata,
  handleDownload,
  handlePreview,
  handleRename,
  handleDelete,
}) => {
  const { isRestrictedBoSoViewerRole } = useRole();

  const items = [
    {
      key: `file-item-${fileId}-preview`,
      label: translate(messages.menuPreview),
      icon: <PreviewIcon />,
      onClick: handlePreview,
      'data-test': 'file-item-menu-preview',
    },
    {
      key: `file-item-${fileId}-edit`,
      label: translate(messages.menuEdit),
      icon: <EditIcon />,
      onClick: handleMetadata,
      disabled: isRestrictedBoSoViewerRole,
      'dsta-test': 'file-item-menu-edit',
    },
    {
      key: `file-item-${fileId}-rename`,
      label: translate(messages.menuRename),
      icon: <RenameIcon />,
      onClick: handleRename,
      disabled: isRestrictedBoSoViewerRole,
      'data-test': 'file-item-menu-rename',
    },
    {
      key: `file-item-${fileId}-download`,
      label: translate(messages.menuDownload),
      icon: <DownloadIcon />,
      onClick: handleDownload,
      'data-test': 'file-item-menu-download',
    },
    {
      key: `file-item-${fileId}-delete`,
      label: translate(messages.menuDeleteLabel),
      icon: <TrashIcon />,
      onClick: handleDelete,
      'data-test': 'file-item-menu-delete',
    },
  ];

  return (
    <Dropdown menu={{ items, className: 'my-files-upload-item-dropdown__menu' }} trigger="click">
      <button className="my-files-upload-item-dropdown__button" data-test="file-item-menu-trigger">
        <KebabIcon />
      </button>
    </Dropdown>
  );
};

MyFilesUploadItemDropdown.propTypes = {
  file: PropTypes.object,
  handleMetadata: PropTypes.func,
  fileId: PropTypes.string,
  handleDownload: PropTypes.func,
  handlePreview: PropTypes.func,
  handleRename: PropTypes.func,
  handleDelete: PropTypes.func,
};

export default MyFilesUploadItemDropdown;
