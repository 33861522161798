import React from 'react';
import { Button, Dropdown } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import Icon from 'ui/Icon';
import ROUTES from 'constants/routes';
import { translate } from 'utils/index';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';
import { selectProfile } from 'pages/Profile/selectors';
import { messages } from './messages';
import { useRole } from 'hooks/useRole';

import './addButton.scss';

const AddButton = () => {
  const appNavigate = useAppNavigate();
  const { isViewerRole, isBoRole, isBoSoRole } = useRole();
  const {
    user: {
      current_organization: { id: currentOrganizationId },
    },
  } = useSelector(selectProfile);

  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const menuItems = [
    {
      icon: 'vendors',
      name: isMyVendorsTabSelected ? messages.createVendor : messages.createCustomer,
      url: isMyVendorsTabSelected ? ROUTES.VENDOR_NEW_VENDOR : ROUTES.CUSTOMER_NEW_CUSTOMER,
      dataTest: 'base-layout-add-vendor-button',
    },
    {
      icon: 'upload2',
      name: isMyVendorsTabSelected ? messages.uploadVendorList : messages.uploadCustomersList,
      url: isMyVendorsTabSelected ? ROUTES.VENDOR_UPLOAD_VENDORS : ROUTES.CUSTOMER_UPLOAD_CUSTOMERS,
      dataTest: 'base-layout-upload-vendor-button',
    },
    {
      icon: 'checklist',
      name: messages.assignAssessment,
      url: isMyVendorsTabSelected
        ? ROUTES.VENDOR_ASSIGN_ASSESSMENT
        : ROUTES.CUSTOMER_ASSIGN_ASSESSMENT,
      dataTest: 'base-layout-assign-assessment-button',
    },
    {
      icon: 'edit2',
      name: messages.createNewTemplate,
      url: isMyVendorsTabSelected
        ? ROUTES.VENDOR_ASSESSMENT_TEMPLATE
        : ROUTES.CUSTOMER_ASSESSMENT_TEMPLATE,
      param: { templateId: uuidv4() },
      dataTest: 'base-layout-create-new-template-button',
    },
  ];

  const menuItemsRender = menuItems.map(({ dataTest, icon, name, url, param }) => {
    const onNavigate = () => appNavigate(url, param);

    return {
      key: url,
      label: translate(name),
      className: 'base-layout-add-button__menu-item',
      onClick: onNavigate,
      icon: <Icon icon={icon} className="base-layout-add-button__menu-item-ico" />,
      'data-test': dataTest,
    };
  });

  const handleBOSOClick = () => {
    const path = isBoRole ? ROUTES.BUSINESS_OWNER_PAGE : ROUTES.SALES_OWNER_PAGE;
    return appNavigate(path, { organizationId: currentOrganizationId });
  };

  return (
    <div className="base-layout-add-button">
      {isBoSoRole ? (
        <Button type="link" className="base-layout-add-button__button" onClick={handleBOSOClick}>
          <Icon className="base-layout-add-button__ico" icon="plus" />
        </Button>
      ) : (
        <Dropdown
          menu={{ items: menuItemsRender, className: 'base-layout-add-button__menu' }}
          trigger="click"
          disabled={isViewerRole}
        >
          <Button type="link" className="base-layout-add-button__button">
            <Icon className="base-layout-add-button__ico" icon="plus" />
          </Button>
        </Dropdown>
      )}
    </div>
  );
};

export default AddButton;
