import { isProductionDomain } from 'utils/index';

export const LOGOUT_TIMER = 30 * 60 * 1000;

export const LOGO_BASE_URL = isProductionDomain()
  ? 'https://uploads.idrra.com/logos/'
  : 'https://uploads.findings-dev.co/logos/';

export const DEFAULT_ASSESSMENT_LOGO = 'https://findings.co/wp-content/uploads/2018/09/logo.png';

export const ALLOW_FILES = 'jpg,png,svg,pdf,xlsx,csv,docx';
export const NOTIFICATION_DURATION = {
  ONE_SECOND: 1,
  TWO_SECONDS: 2,
};

export const NAME_VALIDATION_REGEX = /^[a-zA-Z\u0590-\u05FF\u200f\u200e ]+$/i;
export const CONTACT_NAME_VALIDATION_REGEX = /^(?!(\s*$|.*\d+.*$))/i;
export const COMPANY_NAME_VALIDATION_REGEX = /^(?!(^\s*$))/i;
export const AWS_ACCOUNT_VALIDATION_REGEX = /^\d{12}$/;
export const AZURE_SUBSCRIPTION_ID_VALIDATION_REGEX = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/;

export const NO_INTERNET_CHECK_INTERVAL = 10000;

export const MASKED_VALUE = '*********';
