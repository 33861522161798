export const messages = {
  description: {
    key: 'NotFound.description',
    defaultMessage: 'Sorry we can’t find the page your looking for',
  },
  buttonText: {
    key: 'NotFound.buttonText',
    defaultMessage: 'BACK TO HOME',
  },
};

export default {
  prefix: 'NotFound',
  messages,
};
