import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';

// COMPONENTS
import Button from 'ui/Button';
import Icon from 'ui/Icon';
import Modal from 'ui/Modal';
import Table from 'ui/Table';
import Form from 'ui/Form';
import Select from 'ui/Select';
import SecondaryButton from 'ui/SecondaryButton';
import AddOrganizationModal from './addOrganizationModal';

// UTILS
import { translate } from 'utils/index';
import { generalMessages } from 'constants/messages';
import { assignAssessmentsToOrganization } from 'api/assessments';
import { messages } from './messages';
import { dictionaryToSelect } from 'utils/arrays';
import { API_STATUS } from 'constants/api';

// REDUX
import { selectPendingAssessments } from 'pages/Profile/selectors';
import { selectOrganizationsExtended } from 'pages/Organization/selectors';
import { getProfile } from 'api/profile';

// HOOKS
import { useTheme } from 'hooks/theme';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';

import './index.scss';

const PendingUnassignedAssessments = () => {
  const dispatch = useDispatch();
  const [visible, toggleModal] = useState(false);
  const [addOrganizationVisible, setAddOrganizationVisible] = useState(false);
  const [showPendingAssessments, setShowPendingAssessments] = useState(true);
  const [assessmentsListForOrgModal, setAssessmentsListForOrgModal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState(false);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { organizations } = useSelector(selectOrganizationsExtended);
  const { colorTheme } = useTheme();
  const assessments = useSelector((state) => selectPendingAssessments(state));

  useEffect(() => {
    if (showPendingAssessments && assessments.length > 0) {
      toggleModal(true);
      setShowPendingAssessments(false);
      return null;
    }

    if (assessments.length === 0) {
      toggleModal(false);
    }
  }, [assessments]);

  useEffect(() => {
    if (assessments.length === 1) {
      const pendingInstance = assessments ? assessments[0] : {};
      const pendingAssessments = pendingInstance?.assessments || [];
      const firstPendingAssessment = pendingAssessments[0] || {};
      const assessmentId = Object.keys(firstPendingAssessment)[0];
      if (values[assessmentId]) {
        assign().then();
      }
    }
    if (Object.keys(errors).length) {
      validate();
    }
  }, [values]);

  const assign = useCallback(async () => {
    const errors = validate();

    if (Object.keys(errors).length) {
      return;
    }

    const organizationsToAssessments = Object.entries(values).reduce(
      (acc, [assessmentId, organizationId]) => {
        if (acc[organizationId]) {
          acc[organizationId].push(assessmentId);
          return acc;
        }

        return {
          ...acc,
          [organizationId]: [assessmentId],
        };
      },
      {},
    );
    setLoading(true);
    const response = await dispatch(
      assignAssessmentsToOrganization(organizationsToAssessments),
    ).unwrap();

    if (response?.status === API_STATUS.FAILED) {
      setGeneralError(response?.msg);
      setErrorToAllAssessments();
    }

    if (response?.status === API_STATUS.SUCCESS) {
      await dispatch(getProfile({ upstream: !isMyVendorsTabSelected })).unwrap();
      toggleModal(false);
    }

    setLoading(false);
  }, [values, assessments]);

  const validate = () => {
    const allAssessments = assessments.flatMap(({ assessments }) => assessments);

    const errors = allAssessments.reduce((acc, assessment) => {
      const [[id]] = Object.entries(assessment);
      if (!values[id]) {
        return {
          ...acc,
          [id]: translate(messages.organizationRequired),
        };
      }
      return acc;
    }, {});

    setErrors(errors);
    return errors;
  };

  const setErrorToAllAssessments = () => {
    const allAssessments = assessments.flatMap(({ assessments }) => assessments);

    const errors = allAssessments.reduce((acc, assessment) => {
      const [[id]] = Object.entries(assessment);
      return {
        ...acc,
        [id]: '',
      };
    }, {});

    setErrors(errors);
    return errors;
  };

  if (!visible) {
    return null;
  }

  const onCancel = () => {
    toggleModal(false);
  };

  const clickOnAddNewOrganization = (assessmentsFromDataSource) => {
    setAddOrganizationVisible(true);
    setAssessmentsListForOrgModal(assessmentsFromDataSource);
  };

  const dropdownRender = (menu, assessmentsFromDataSource) => {
    return (
      <>
        {menu}
        <Divider style={{ margin: '0 0' }} />
        <SecondaryButton
          className="pending-unassigned-assessments__add-organization-button"
          link
          onClick={() => clickOnAddNewOrganization(assessmentsFromDataSource)}
          data-test="add-new-organization-button-create-new-organization"
        >
          + {translate(messages.createNewOrganization)}
        </SecondaryButton>
      </>
    );
  };

  const setOrganizationToAssessments = (selectedOrgId, assessmentsFromDataSource = []) => {
    setValues((state) => {
      // set all pending assessments of this sender to the same organization
      const ids = assessmentsFromDataSource.reduce((acc, assessment) => {
        const [[id]] = Object.entries(assessment);
        return {
          ...acc,
          [id]: selectedOrgId,
        };
      }, {});
      return { ...state, ...ids };
    });
  };

  const columns = [
    {
      title: isMyVendorsTabSelected ? translate(messages.toCustomer) : translate(messages.toVendor),
      dataIndex: 'assignedOrg',
      width: 150,
    },
    {
      title: isMyVendorsTabSelected
        ? translate(messages.fromVendor)
        : translate(messages.fromCustomer),
      dataIndex: 'customer',
      width: 150,
    },
    {
      title: translate(messages.contactEmail),
      dataIndex: 'email',
      width: 150,
    },
    {
      title: translate(messages.assessments),
      dataIndex: 'assessments',
      width: 300,
      render: (assessments) => {
        const assessmentList = assessments.map((assessment) => {
          const [[assessmentId, name]] = Object.entries(assessment);
          return { assessmentId, name };
        });
        return (
          <ul className="pending-unassigned-assessments__assessment-list">
            {assessmentList.map(({ assessmentId, name }) => (
              <li key={assessmentId}>{name}</li>
            ))}
          </ul>
        );
      },
    },
    {
      title: translate(messages.assignOrganization),
      dataIndex: 'availableOrgs',
      width: 250,
      render: (availableOrgs, { assessments }) => (
        <Select
          className="pending-unassigned-assessments__organization-selector"
          dropdownRender={(menu) => dropdownRender(menu, assessments)}
          placeholder={translate(messages.selectOrganization)}
          options={dictionaryToSelect(availableOrgs)}
          value={values[Object.keys(assessments[0])[0]]}
          error={errors[Object.keys(assessments[0])[0]]}
          onChange={(value) => setOrganizationToAssessments(value, assessments)}
          data-test="pending-unassigned-assessments-organization-selector"
        />
      ),
    },
  ];

  const footer = [
    <Button
      key="cancel-button"
      className="pending-unassigned-assessments__footer-cancel-button"
      size="sm"
      color="white"
      onClick={onCancel}
      fluid
      data-test="pending-unassigned-assessments-cancel-button"
    >
      {translate(generalMessages.cancel)}
    </Button>,
    <Button
      key="ok-button"
      className="pending-unassigned-assessments__footer-ok-button"
      size="sm"
      color={colorTheme}
      onClick={assign}
      fluid
      type="submit"
      loading={loading}
      data-test="pending-unassigned-assessments-ok-button"
    >
      {translate(messages.assign)}
    </Button>,
  ];

  const title = (
    <div>
      <span>
        <Icon icon="checklist" /> {translate(messages.youHaveGotNewAssessments)}
      </span>
      <p className="pending-unassigned-assessments__sub-title">{translate(messages.subTitle)}</p>
    </div>
  );

  return (
    <>
      <Modal
        className="pending-unassigned-assessments__modal"
        open={visible && !addOrganizationVisible && organizations.length > 0}
        onCancel={onCancel}
        width={1170}
        title={title}
        footer={footer}
      >
        <Form onSubmit={assign}>
          <Table
            className="pending-unassigned-assessments__table"
            columns={columns}
            dataSource={assessments}
            loading={loading}
            pagination={false}
            rowKey="pending-unassigned-assessment"
          />
          {Object.keys(errors).length > 0 && generalError && (
            <div className="pending-unassigned-assessments__general-error">{generalError}</div>
          )}
        </Form>
      </Modal>
      <AddOrganizationModal
        assessments={assessments}
        open={addOrganizationVisible}
        onCancel={() => setAddOrganizationVisible(false)}
        assessmentsListForOrgModal={assessmentsListForOrgModal}
        selectOrganization={setOrganizationToAssessments}
        closeAssessmentsModal={onCancel}
        assign={assign}
      />
    </>
  );
};

export default PendingUnassignedAssessments;
