import { messages } from './messages';

export const metadataControls = [
  {
    title: 'General',
    items: [
      { key: 'expiresOn', text: messages.expiresOn, date: true },
      { key: 'issuedOn', text: messages.issuedOn, date: true, allowPastSelection: true },
      { key: 'version', text: messages.version, placeholder: 'Ex. 1.0.0 | 1.0 | 1 etc.' },
    ],
  },

  {
    title: 'Issued To',
    items: [
      { key: 'issuedToName', text: messages.issuedToName },
      { key: 'issuedToEmail', text: messages.issuedToEmail },
      { key: 'issuedToOrganization', text: messages.issuedToOrganization },
      { key: 'issuedToOrganizationUnit', text: messages.issuedToOrganizationUnit },
    ],
  },

  {
    title: 'Issued By',
    items: [
      { key: 'issuedByName', text: messages.issuedByName },
      { key: 'issuedByEmail', text: messages.issuedByEmail },
      { key: 'issuedByOrganization', text: messages.issuedByOrganization },
      { key: 'issuedByOrganizationUnit', text: messages.issuedByOrganizationUnit },
    ],
  },
];
