import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { saveFinding } from 'api/finding';

const initialState = {
  selectedFinding: null,
  findingLoading: false,
};

const reducers = createSlice({
  name: 'assessmentFindings',
  initialState,
  reducers: {
    selectFinding: (state, { payload }) => {
      state.selectedFinding = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveFinding.pending, (state) => {
        state.findingLoading = true;
      })
      .addMatcher(isAnyOf(saveFinding.fulfilled, saveFinding.rejected), (state) => {
        state.findingLoading = false;
      });
  },
});

export const { selectFinding } = reducers.actions;

export default reducers;
