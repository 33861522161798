import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import './tab-add-button.scss';

const TabAddButton = ({ text, onClick, loading }) => (
  <Button
    data-test="tab-add-button"
    link
    color="white"
    size="sm"
    loading={loading}
    onClick={onClick}
    className="tab-add-button"
  >
    + {text}
  </Button>
);

TabAddButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default TabAddButton;
