import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import classNames from 'classnames';

import Icon from 'ui/Icon';

import './index.scss';

const Popup = ({
  className,
  children,
  open,
  onCancel,
  withIconOnTitle,
  width,
  fullscreen,
  ...popupProps
}) => {
  const popupClassNames = classNames('ui-popup', className, {
    'ui-popup--no-title-margin': withIconOnTitle === undefined,
    'ui-popup--fullscreen': fullscreen,
  });

  return (
    <Modal
      className={popupClassNames}
      open={open}
      onCancel={onCancel}
      width={width}
      closeIcon={<Icon icon="close2" />}
      {...popupProps}
    >
      {children}
    </Modal>
  );
};

Popup.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onCancel: PropTypes.func,
  open: PropTypes.bool.isRequired,
  withIconOnTitle: PropTypes.string,
  width: PropTypes.number,
  fullscreen: PropTypes.bool,
};

export default Popup;
