import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';

import { selectActiveQuestion } from 'pages/Editor/selectors';
import Icon from 'ui/Icon';

const DraggableQuestion = ({
  control,
  children,
  index,
  onDrop,
  onClickControl,
  reorderQuestions,
  questionsRef,
  dragable = true,
}) => {
  const ref = useRef(null);
  const activeQuestion = useSelector(selectActiveQuestion);

  const [{ handlerId }, dropRef] = useDrop({
    accept: 'question',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref?.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref?.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      reorderQuestions(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop() {
      onDrop();
    },
  });

  const [{ opacity, isDragging }, dragRef, dragPreviewRef] = useDrag({
    type: 'question',
    item: { ...control, index },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
      isDragging: monitor.isDragging(),
    }),
  });

  const isActive = activeQuestion === control.id;

  const questionClassName = classNames('editor-questions__question', {
    'editor-questions__question--active': isActive,
    'editor-questions__question--is-dragging': isDragging,
  });

  dragPreviewRef(dropRef(ref));

  return (
    <div ref={dragable ? ref : null} style={{ opacity }}>
      <div className={questionClassName} id={`control-${control.id}`} onClick={onClickControl}>
        <div className="editor-questions__question-wrapper">
          {dragable ? (
            <div
              ref={dragRef}
              className="editor-questions__drag-icon-wrapper"
              data-test={`editor-question-drag-icon-${index}`}
            >
              <Icon
                ref={dragRef}
                data-handler-id={handlerId}
                icon="drag"
                className="editor-questions__drag-icon"
              />
            </div>
          ) : null}
          <div
            ref={(element) => {
              questionsRef.current[index] = element;
            }}
            className="editor-questions__question-box"
            data-test={`editor-question-question-box`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DraggableQuestion.propTypes = {
  control: PropTypes.object,
  children: PropTypes.node,
  index: PropTypes.number,
  onDrop: PropTypes.func,
  onClickControl: PropTypes.func,
  renderActiveQuestion: PropTypes.func,
  questions: PropTypes.array,
  reorderQuestions: PropTypes.func,
  questionsRef: PropTypes.object,
  dragable: PropTypes.bool,
};

export default DraggableQuestion;
