import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Switch, Tooltip } from 'antd';
import { translate } from 'utils/index';
import Icon from 'ui/Icon';
import Loader from 'ui/Loader';
import { getOrganizationSecurity, setOrganizationSecurity } from 'api/organizations';
import { selectSecuritySettings } from 'pages/Organization/selectors';
import { messages } from 'pages/Organization/messages';
import { QuestionIcon } from 'components/Icons';
import colors from 'ui/colors.scss';
import './security.scss';

const Security = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const settings = useSelector(selectSecuritySettings);
  const [securitySettings, setSecuritySettings] = useState(settings || {});

  useEffect(async () => {
    setLoading(true);
    dispatch(getOrganizationSecurity(organizationId));
    setLoading(false);
  }, []);

  useEffect(() => {
    setSecuritySettings(settings);
  }, [settings]);

  const changeSettings = (property, value) => {
    setSecuritySettings({ ...securitySettings, [property]: value });

    dispatch(setOrganizationSecurity({ organizationId, settings: { [property]: value } }));
  };

  return (
    <div className="organization-security-tab">
      <div className="organization-security-tab__title">
        <Icon icon="lock" className="organization-security-tab__title-icon" />
        {translate(messages.orgEditTabSecurity2FaPolicy)}
      </div>
      <div className="organization-security-tab__settings">
        <label className="organization-security-tab__label">
          <span className="organization-security-tab__label-inner">
            {translate(messages.orgEditTabSecurityEnforce2Fa)}
          </span>
          <Switch
            size="small"
            checked={securitySettings.enforce2fa}
            onChange={(value) => changeSettings('enforce2fa', value)}
          />
        </label>
        <label className="organization-security-tab__label">
          <span className="organization-security-tab__label-inner">
            {translate(messages.orgEditTabSecurityRequireStrengthenAuth)}
            <Tooltip title={translate(messages.orgEditTabSecurityRequireStrengthenAuthTooltip)}>
              <span className="organization-security-tab__label-tooltip-wrapper">
                <QuestionIcon
                  color={colors.blue500}
                  className="organization-security-tab__label-tooltip-text"
                />
              </span>
            </Tooltip>
          </span>
          <Switch
            size="small"
            checked={securitySettings.strengthenAuthentication}
            onChange={(value) => changeSettings('strengthenAuthentication', value)}
          />
        </label>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default Security;
