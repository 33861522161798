import React from 'react';
import TrustedIcon from 'assets/svgs/trusted.svg';
import { translate } from 'utils/index';
import { ReactComponent as RejectedIcon } from 'assets/svgs/close-x-cancel.svg';
import { OrgStatusType } from 'pages/Organization/edit/trustedOrganizationsUtils/orgStatusType';
import { messages } from '../../messages';

export const renderStatus = (status) => {
  switch (status) {
    case OrgStatusType.WAITING_APPROVAL:
      return (
        <span className="trusted-organizations-tab__status-awaiting-approval">
          {translate(messages.orgEditTrustedOrganizationsStatusAwaitingApproval)}
        </span>
      );
    case OrgStatusType.PENDING:
      return (
        <span className="trusted-organizations-tab__status-pending">
          {translate(messages.orgEditTrustedOrganizationsStatusPending)}
        </span>
      );
    case OrgStatusType.REJECTED:
      return (
        <span className="trusted-organizations-tab__status-rejected">
          <RejectedIcon width={15} height={15} />{' '}
          {translate(messages.orgEditTrustedOrganizationsStatusRejected)}
        </span>
      );
    case OrgStatusType.ESTABLISHED:
      return (
        <span className="trusted-organizations-tab__status-trusted">
          <img src={TrustedIcon} alt="Trusted" />
          {translate(messages.orgEditTrustedOrganizationsStatusTrusted)}
        </span>
      );
    default:
      return null;
  }
};
