export const messages = {
  myFiles: {
    key: 'MyFiles.myFiles',
    defaultMessage: 'My Files',
  },
  selectFileOrClickToUpload: {
    key: 'MyFiles.selectFileOrClickToUpload',
    defaultMessage: 'Select file or click to upload a new file',
  },
  uploadFile: {
    key: 'MyFiles.uploadFile',
    defaultMessage: 'UPLOAD FILE',
  },
  maxFileSize: {
    key: 'MyFiles.maxFileSize',
    defaultMessage: 'Max file size: 50mb',
  },
  uploading: {
    key: 'MyFiles.uploading',
    defaultMessage: 'Uploading',
  },
  valid: {
    key: 'MyFiles.valid',
    defaultMessage: 'VALID',
  },
  expireSoon: {
    key: 'MyFiles.expireSoon',
    defaultMessage: 'EXPIRE SOON: In {{days}} days',
  },
  expired: {
    key: 'MyFiles.expired',
    defaultMessage: 'EXPIRED',
  },
  fileAlreadyExists: {
    key: 'MyFiles.fileAlreadyExists',
    defaultMessage: 'File already exists',
  },
};

export default {
  prefix: 'MyFiles',
  messages,
};
