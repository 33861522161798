import { useEffect, useState } from 'react';

function useAuditCss({ auditFinding, currentFinding, audit }) {
  const [auditCss, setAuditCss] = useState({
    description: {
      show: false,
      class: 'assessment-add-finding__description--audit',
    },
    impact: {
      show: false,
      class: 'assessment-add-finding__statuses--impact-audit',
    },
    probability: {
      show: false,
      class: 'assessment-add-finding__statuses--probability-audit',
    },
    recommendations: {},
  });

  useEffect(() => {
    if (!auditFinding) return;

    // set initial state for recommendations
    setAuditCss((prev) => {
      const recommendations = auditFinding?.recommendations;
      return {
        ...prev,
        recommendations: recommendations?.map((recommendation, index) => {
          return {
            showDescription: false,
            showForceEvidence: false,
            showDueDate: false,
            classDescription: 'assessment-recommendations__recommendation-description--audit',
            classForceEvidence: 'assessment-recommendations__recommendation-force-evidence--audit',
            classDueDate: 'assessment-recommendations__recommendation-due-date--audit',
          };
        }),
      };
    });
  }, [auditFinding]);

  const updateInnerState = (key) => {
    if (!auditFinding) return;
    return (prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        show: currentFinding[key] === auditFinding[key] && auditFinding[key],
      },
    });
  };

  useEffect(() => {
    setAuditCss(updateInnerState('description'));
  }, [currentFinding.description]);

  useEffect(() => {
    setAuditCss(updateInnerState('impact'));
  }, [currentFinding.impact]);

  useEffect(() => {
    setAuditCss(updateInnerState('probability'));
  }, [currentFinding.probability]);

  // set audit css per recommendation
  useEffect(() => {
    auditFinding?.recommendations?.forEach((recommendation, index) => {
      setAuditCss((prev) => {
        const recommendations = prev.recommendations;
        const prevRecommendation = prev.recommendations[index];
        const currentFindingRecommendation = currentFinding?.recommendations[index];
        const auditFindingRecommendation = auditFinding?.recommendations[index];

        recommendations[index] = {
          ...prevRecommendation,
          showDescription:
            currentFindingRecommendation?.description === auditFindingRecommendation?.description &&
            auditFindingRecommendation?.description,
          showForceEvidence:
            currentFindingRecommendation?.force_evidence ===
              auditFindingRecommendation?.force_evidence &&
            auditFindingRecommendation?.force_evidence,
          showDueDate:
            currentFindingRecommendation?.due_delta === auditFindingRecommendation?.due_delta &&
            auditFindingRecommendation?.due_delta,
        };

        return {
          ...prev,
          recommendations,
        };
      });
    });
  }, [currentFinding.recommendations]);

  return audit ? auditCss : {};
}

export default useAuditCss;
