export const messages = {
  resetPasswordButton: {
    key: 'New Password Page.resetPasswordButton',
    defaultMessage: 'Reset Password',
  },
  newPasswordTitle: {
    key: 'New Password Page.newPasswordTitle',
    defaultMessage: 'Create new password',
  },
  newPasswordSubTitle: {
    key: 'New Password Page.newPasswordSubTitle',
    defaultMessage: 'Your new password must be different from the previously used password.',
  },
  newPasswordUpdateSuccess: {
    key: 'New Password Page.newPasswordUpdateSuccess',
    defaultMessage: 'Password updated successfully',
  },
  newPasswordUpdateFail: {
    key: 'New Password Page.newPasswordUpdateFail',
    defaultMessage: 'Password failed to update',
  },
};

export default {
  prefix: 'New Password Page',
  messages,
};
