import React from 'react';
import PropTypes from 'prop-types';
import './placeholder.scss';

const BaseLayoutPlaceholder = ({ children }) => {
  return <div className="base-layout-placeholder">{children}</div>;
};

BaseLayoutPlaceholder.propTypes = {
  children: PropTypes.node,
};

export default BaseLayoutPlaceholder;
