import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getFilterOptions } from 'api/filters';
import {
  filtersMetadata,
  parseFilterOptions,
  createNewQuery,
  createNewQueryBlock,
  FILTERABLE_KEYS,
} from 'components/Filters/lib';

const filterInitial = {
  filters: {},
  filtersCount: 0,
  filtersToApi: null,
};

const initialState = {
  filtersOptions: {},
  allFilters: {
    [FILTERABLE_KEYS.DASHBOARD_FILTERS]: { ...filterInitial },
    [FILTERABLE_KEYS.ASSESSMENTS_LIST_FILTERS]: { ...filterInitial },
    [FILTERABLE_KEYS.VENDORS_LIST_FILTERS]: { ...filterInitial },
    [FILTERABLE_KEYS.CUSTOMERS_LIST_FILTERS]: { ...filterInitial },
    [FILTERABLE_KEYS.FINDINGS_LIST_FILTERS]: { ...filterInitial },
    [FILTERABLE_KEYS.EVIDENCES_LIST_FILTERS]: { ...filterInitial },
  },
};

const reducers = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const filterableKey = action.payload.filterableKey;
      if (!filterableKey) return;
      state.allFilters[filterableKey].filters[action.payload.filter?.key] = action.payload.filter;
    },
    setFilters: (state, action) => {
      const filterableKey = action.payload.filterableKey;
      if (!filterableKey) return;
      state.allFilters[filterableKey].filters = action.payload?.filters;
      state.allFilters[filterableKey].filtersCount = action.payload?.filtersCount;
      state.allFilters[filterableKey].filtersToApi = action.payload?.filtersToApi;
    },
    addMultipleFilterKey: (state, action) => {
      const filterKey = action.payload.filterKey;
      const filterableKey = action.payload.filterableKey;

      const filter = state.allFilters[filterableKey].filters[filterKey];
      if (filter?.isQuery) {
        const newQuery = createNewQuery(filterKey);
        const firstQueryBlockKey = filtersMetadata[filterKey].queryBlockKeys[0];

        newQuery[0][firstQueryBlockKey].options =
          state.allFilters[filterableKey].filters[filterKey].selected[0][0][
            firstQueryBlockKey
          ].options;

        filter.selected.push(newQuery);
      } else {
        filter.selected.push([]);
      }
    },
    addQueryBlock: (state, action) => {
      const filterKey = action.payload.filterKey;
      const filterableKey = action.payload.filterableKey;
      const querySetIndex = action.payload.querySetIndex;
      if (!filterKey || !filterableKey || querySetIndex === undefined || querySetIndex === null)
        return;

      const newQueryBlock = createNewQueryBlock(filterKey);
      const firstQueryBlockKey = filtersMetadata[filterKey].queryBlockKeys[0];
      // add the options of the first query block item to the new query block
      newQueryBlock[firstQueryBlockKey].options =
        state.allFilters[filterableKey].filters[filterKey].selected[0][0][
          firstQueryBlockKey
        ].options;

      state.allFilters[filterableKey].filters[filterKey].selected[querySetIndex].push(
        newQueryBlock,
      );
    },
    onChangeFilter: (state, action) => {
      const filterKey = action.payload.filterKey;
      const filterableKey = action.payload.filterableKey;
      if (!filterKey || !filterableKey) return;

      if (state.allFilters[filterableKey].filters[filterKey]) {
        if (state.allFilters[filterableKey].filters[filterKey].isQuery) {
          const { querySetIndex, queryBlockIndex, queryBlockItemKey } = action.payload;
          const queryBlockItem =
            state.allFilters[filterableKey].filters[filterKey].selected[querySetIndex][
              queryBlockIndex
            ][queryBlockItemKey];
          queryBlockItem.selected = action.payload.selected;

          const hasSelected = action.payload.selected?.length;
          const queryBlockItemIndex =
            state.allFilters[filterableKey].filters[filterKey].queryBlockKeys.indexOf(
              queryBlockItemKey,
            );
          if (hasSelected) {
            // fetch options for the next query block item in the current queryBlock and set disabled=false, if exists
            state.allFilters[filterableKey].filters[filterKey].queryBlockKeys.forEach(
              (queryBlockKey, index) => {
                if (index > queryBlockItemIndex) {
                  const currentQueryBlockItem =
                    state.allFilters[filterableKey].filters[filterKey].selected[querySetIndex][
                      queryBlockIndex
                    ][queryBlockKey];

                  if (index === queryBlockItemIndex + 1) {
                    currentQueryBlockItem.disabled = false;
                  } else {
                    currentQueryBlockItem.disabled = true;
                    currentQueryBlockItem.options = [];
                  }
                  currentQueryBlockItem.selected = [];
                }
              },
            );
          } else {
            // for all next query block items in the current querryBlock (in order of filter.queryBlockKeys array): set disabled to true, clear selected values:

            state.allFilters[filterableKey].filters[filterKey].queryBlockKeys.forEach(
              (queryBlockKey, index) => {
                if (index > queryBlockItemIndex) {
                  const currentQueryBlockItem =
                    state.allFilters[filterableKey].filters[filterKey].selected[querySetIndex][
                      queryBlockIndex
                    ][queryBlockKey];
                  if (currentQueryBlockItem.disabled) {
                    return;
                  }
                  currentQueryBlockItem.selected = [];
                  currentQueryBlockItem.disabled = true;
                  currentQueryBlockItem.options = [];
                }
              },
            );
          }

          return;
        }

        if (state.allFilters[filterableKey].filters[filterKey].multipleKeys) {
          state.allFilters[filterableKey].filters[filterKey].selected[action.payload.index] =
            action.payload.selected;
        } else {
          state.allFilters[filterableKey].filters[filterKey].selected = action.payload.selected;
        }
      }
    },
    removeFilter: (state, action) => {
      const filterKey = action.payload.filterKey;
      const filterableKey = action.payload.filterableKey;
      if (filterKey && filterableKey) {
        if (state.allFilters[filterableKey].filters[filterKey]) {
          if (state.allFilters[filterableKey].filters[filterKey]?.isQuery) {
            const { querySetIndex, queryBlockIndex } = action.payload;

            // remove query set from query, if it's the last query set, remove the filter
            if (queryBlockIndex === undefined) {
              state.allFilters[filterableKey].filters[filterKey].selected.splice(querySetIndex, 1);
              if (!state.allFilters[filterableKey].filters[filterKey].selected.length) {
                delete state.allFilters[filterableKey].filters[filterKey];
              }
              return;
            }

            // remove query block from a query set, if it's the last block, remove the query set, if it's the last query set, remove the filter
            state.allFilters[filterableKey].filters[filterKey].selected[querySetIndex].splice(
              queryBlockIndex,
              1,
            );
            if (
              !state.allFilters[filterableKey].filters[filterKey].selected[querySetIndex].length
            ) {
              state.allFilters[filterableKey].filters[filterKey].selected.splice(querySetIndex, 1);
            }
            if (!state.allFilters[filterableKey].filters[filterKey].selected.length) {
              delete state.allFilters[filterableKey].filters[filterKey];
            }
            return;
          }

          if (state.allFilters[filterableKey].filters[filterKey]?.multipleKeys) {
            state.allFilters[filterableKey].filters[filterKey]?.selected?.splice(
              action.payload.index,
              1,
            );
            if (!state.allFilters[filterableKey].filters[filterKey]?.selected?.length) {
              delete state.allFilters[filterableKey].filters[filterKey];
            }
          } else {
            delete state.allFilters[filterableKey].filters[filterKey];
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(getFilterOptions.pending), (state, action) => {
        const filterKey = action.meta.arg?.filterKey;
        const filterableKey = action.meta.arg?.filterableKey;
        const initializeOptionsOnPending = action.meta.arg?.initializeOptionsOnPending;

        if (filterKey) {
          const filter = state.allFilters[filterableKey].filters[action.meta.arg?.filterKey];
          if (filter?.isQuery) {
            const { querySetIndex, queryBlockIndex, queryBlockItemKey } = action.meta.arg;
            filter.selected[querySetIndex][queryBlockIndex][queryBlockItemKey].loading = true;
            if (initializeOptionsOnPending) {
              filter.selected[querySetIndex][queryBlockIndex][queryBlockItemKey].options = [];
            }
          } else {
            if (state.filtersOptions[filterKey]) {
              state.filtersOptions[filterKey].loading = true;
              if (initializeOptionsOnPending) {
                state.filtersOptions[filterKey].options = [];
              }
            } else {
              state.filtersOptions[filterKey] = { loading: true, options: [] };
            }
          }
        }
      })
      .addMatcher(
        isAnyOf(getFilterOptions.fulfilled, getFilterOptions.rejected),
        (state, action) => {
          const filterKey = action.meta.arg?.filterKey;
          const filterableKey = action.meta.arg?.filterableKey;

          if (filterableKey) {
            const filter = state.allFilters[filterableKey].filters[filterKey];
            const parsedOptions = parseFilterOptions(action.payload?.options, filter.isMetadata);

            if (filter?.isQuery) {
              const { querySetIndex, queryBlockIndex, queryBlockItemKey } = action.meta.arg;
              const queryBlockItem =
                filter.selected[querySetIndex][queryBlockIndex][queryBlockItemKey];
              queryBlockItem.loading = false;
              const hasOptionsChanged = JSON.stringify(parsedOptions) !== queryBlockItem.options;
              queryBlockItem.options = hasOptionsChanged ? parsedOptions : queryBlockItem.options;
            } else {
              const currentOptions = state.filtersOptions[filterKey]?.options || [];
              const hasOptionsChanged =
                JSON.stringify(parsedOptions) !== JSON.stringify(currentOptions);

              state.filtersOptions[filterKey].loading = false;

              if (hasOptionsChanged) {
                state.filtersOptions[filterKey].options = parsedOptions;
              }
            }
          }
        },
      );
  },
});

export const {
  setFilter,
  setFilters,
  addMultipleFilterKey,
  addQueryBlock,
  onChangeFilter,
  removeFilter,
  clearAllFilters,
} = reducers.actions;

export default reducers;
