import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { API_STATUS, DEFAULT_PAGINATION } from 'constants/api';
import {
  addProfileCustomer,
  setProfileCustomer,
  getProfileCustomer,
  getProfileCustomers,
} from 'api/customers';

const initialState = {
  customers: [],
  pagination: DEFAULT_PAGINATION,
  sorter: { column: 'timestampCreated', descending: true },
  search: '',
  filters: [],
  currentCustomer: undefined,
  isLoading: false,
  isCustomersFetching: false,
  isCustomerFetching: false,
};

const reducers = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    resetCurrentCustomer: (state) => {
      state.currentCustomer = undefined;
    },
    setSearch: (state, { payload }) => {
      state.search = payload || '';
    },
    setFilter: (state, { payload: { type, value, id } }) => {
      const selectedFilter = state.filters.find((filter) => filter.type === type);

      if (selectedFilter) {
        state.filters = state.filters.map((filter) => {
          return filter.type === type
            ? { type, value: value === undefined ? '' : value, id: id !== undefined ? id : '' }
            : filter;
        });
        return;
      }

      state.filters.push({ type, value, id });
    },
    removeFilter: (state, { payload: { type } }) => {
      state.filters = state.filters.filter((filter) => filter.type !== type);
    },
    clearFilters: (state) => {
      state.filters = [];
    },
    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
    setSorter: (state, { payload }) => {
      state.sorter = payload || {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProfileCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setProfileCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProfileCustomers.pending, (state) => {
        state.isCustomersFetching = true;
      })
      .addMatcher(
        isAnyOf(getProfileCustomers.fulfilled, getProfileCustomers.rejected),
        (state, data) => {
          state.isCustomersFetching = false;
          const { status, customers, total } = data.payload;
          if (status !== API_STATUS.FAILED) {
            state.customers = customers;
            state.pagination = {
              ...state.pagination,
              total,
            };
          }
        },
      )
      .addMatcher(
        isAnyOf(
          addProfileCustomer.fulfilled,
          addProfileCustomer.rejected,
          setProfileCustomer.fulfilled,
          setProfileCustomer.rejected,
        ),
        (state, data) => {
          state.isLoading = false;
          if (data.payload?.status !== API_STATUS.FAILED) {
            state.currentCustomer = data.payload?.customer || state.currentCustomer;
          }
        },
      )
      .addMatcher(
        isAnyOf(getProfileCustomer.fulfilled, getProfileCustomer.rejected),
        (state, data) => {
          state.isCustomerFetching = false;
          const { status, customer } = data.payload;
          if (status !== API_STATUS.FAILED) {
            state.currentCustomer = customer;
          }
        },
      );
  },
});

export const {
  clearFilters,
  setPagination,
  setSorter,
  setSearch,
  setFilter,
  removeFilter,
  resetCurrentCustomer,
} = reducers.actions;

export default reducers;
