import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Select from 'ui/Select';
import { translate } from 'utils/index';
import { messages } from './messages';
import './index.scss';

const ImpactSelect = ({ disabled, loading, onChange, options, value }) => {
  return (
    <Select
      className={classNames('impact-select', `impact-select--${value}`)}
      dropdownClassName="impact-select__impact-dropdown"
      disabled={disabled}
      innerLabel={translate(messages.impact)}
      options={options}
      labelKey="key"
      value={value || undefined}
      placeholder={translate(messages.choose)}
      onChange={onChange}
      loading={loading}
      showSearch={false}
    />
  );
};

ImpactSelect.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.number,
};

export default ImpactSelect;
