import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './item.scss';

const Item = ({ className, centered, children, ...props }) => {
  const itemClassNames = classNames('ui-form-item', className, {
    'ui-form-item--centered': centered,
  });
  return (
    <div className={itemClassNames} {...props}>
      {children}
    </div>
  );
};

Item.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  centered: PropTypes.bool,
};

export default Item;
