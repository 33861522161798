import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import Input from 'ui/Input';
import { getAssessments } from 'api/assessments';
import { setPagination, setSearch } from './reducers';

const Search = () => {
  const dispatch = useDispatch();
  const searchValue = useSelector((state) => state.assessments.search);

  const searchDebounced = useCallback(
    debounce(() => {
      dispatch(setPagination({ current: 1 }));
      dispatch(getAssessments());
    }, 500),
    [],
  );

  const search = (event) => {
    dispatch(setSearch(event.target.value));
    searchDebounced();
  };

  return <Input.Search onChange={search} size="medium" value={searchValue} />;
};

export default Search;
