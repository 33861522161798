import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { cloneTemplate, getAssessmentsTemplates, removeTemplate } from 'api/assessmentsTemplates';

const initialState = {
  templates: [],
  groups: [],
  loading: false,
  cloneTemplateLoading: false,
  removeTemplateLoading: false,
};

const reducers = createSlice({
  name: 'assessmentsTemplates',
  initialState,
  reducers: {
    setPagination: (state, { payload }) => {
      state.pagination = { ...state.pagination, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssessmentsTemplates.pending, (state) => {
        state.loading = true;
      })
      .addCase(cloneTemplate.pending, (state) => {
        state.cloneTemplateLoading = true;
      })
      .addCase(removeTemplate.pending, (state) => {
        state.removeTemplateLoading = true;
      })
      .addMatcher(
        isAnyOf(getAssessmentsTemplates.fulfilled, getAssessmentsTemplates.rejected),
        (state, { payload: { assessmentsTemplates, assessmentsGroups } }) => {
          state.templates = assessmentsTemplates;
          state.groups = assessmentsGroups;
          state.loading = false;
        },
      )
      .addMatcher(isAnyOf(cloneTemplate.fulfilled, cloneTemplate.rejected), (state) => {
        state.cloneTemplateLoading = false;
      })
      .addMatcher(isAnyOf(removeTemplate.fulfilled, removeTemplate.rejected), (state) => {
        state.removeTemplateLoading = false;
      });
  },
});

export const { setPagination } = reducers.actions;

export default reducers;
