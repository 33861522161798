import React from 'react';
import ROUTES, { OLD_ROUTES as OLD_ROUTES_PATHS } from 'constants/routes';
import Redirect from 'components/Redirect';

export const OLD_ROUTES = {
  RESET_PASSWORD_OLD: {
    path: OLD_ROUTES_PATHS.RESET_PASSWORD,
    element: <Redirect to={ROUTES.RESET_PASSWORD} />,
  },
  ACTIVATION_ASSESSMENT_OLD: {
    path: OLD_ROUTES_PATHS.ACTIVATION_ASSESSMENT,
    element: <Redirect to={ROUTES.ACTIVATION_ASSESSMENT} />,
  },
  ASSIGN_TO_ORGANIZATION: {
    path: OLD_ROUTES_PATHS.ASSIGN_TO_ORGANIZATION,
    element: <Redirect to={ROUTES.LOGIN} />,
  },
};
