/* eslint-disable no-param-reassign */
import axios from 'axios';
import { notification } from 'antd';
import { convertObjectToFormData, getCookie } from 'utils/apiHelpers';
import { getBaseUrl, isDevelopment, translate } from 'utils';
import {
  ORG_ID_AUTH_KEY,
  setAuthenticationTokens,
  TOKEN_AUTH_KEY,
  WS_TOKEN_AUTH_KEY,
} from 'utils/auth';
import { mixpanelTrackLogout } from 'utils/mixpanel';
import { API_STATUS } from 'constants/api';
import { generalMessages } from 'constants/messages';
import { Login } from 'api/endpoints';
import { logout, LOGOUT_TIMEOUT } from 'utils/logout';
import * as endpoints from 'api/endpoints';
import { MixpanelLogoutTypes } from 'constants/mixpanel';
import ROUTES from 'constants/routes';
import { setThirdPartyConfigs } from 'utils/initConfig';

const createAxiosConfig = () => {
  axios.defaults.baseURL = getBaseUrl();

  axios.interceptors.request.use(
    ({ url, data = {}, ...restFromConfig }) => {
      // Do something before request is sent
      // Get endpoint by its key/url/name
      const { formData = true, nonAuthorized } = endpoints[restFromConfig.key || url] || {};

      // Attach auth params
      if (!nonAuthorized) {
        if (isDevelopment()) {
          data.token =
            restFromConfig?.forcedAuthorization?.token || data.token || getCookie(TOKEN_AUTH_KEY);
          data.ws_token = data.wsToken || getCookie(WS_TOKEN_AUTH_KEY);
        }
        data.org_id =
          restFromConfig?.forcedAuthorization?.org_id ||
          data.organizationId ||
          getCookie(ORG_ID_AUTH_KEY);
      }
      if (restFromConfig.method === 'get') {
        return { ...restFromConfig, url, params: { ...restFromConfig.params, ...data } };
      }

      data = formData ? convertObjectToFormData(data) : data;
      return { ...restFromConfig, url, data };
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use((response) => {
    if (!response.data) {
      notification.error({
        message: isDevelopment() ? response.config.url : translate(generalMessages.error),
        description: translate(generalMessages.noResponse),

        // required for testing purposes
        className: 'ui-notification-error',
      });
    }

    const {
      data: { status, msg, dbg, redirect },
      config: { url },
    } = response;

    if (status === API_STATUS.FAILED && status !== API_STATUS.NOT_FOUND) {
      notification.error({
        message: isDevelopment() ? url : translate(generalMessages.error),
        description: isDevelopment() ? dbg || msg : msg,

        // required for testing purposes
        className: 'ui-notification-error',
      });

      if (redirect) {
        if (redirect === ROUTES.LOGOUT) {
          mixpanelTrackLogout(true, MixpanelLogoutTypes.Redirect);
          logout().then();
        } else {
          window.location.replace(redirect);
        }

        return response.data;
      }

      if (msg === 'Invalid token') {
        setTimeout(() => {
          logout().then();
          mixpanelTrackLogout(true, MixpanelLogoutTypes.InvalidToken);
        }, LOGOUT_TIMEOUT);
      }
      if (msg === 'Invalid credentials' && url !== undefined && url !== Login.url) {
        mixpanelTrackLogout(true, MixpanelLogoutTypes.InvalidToken);
        logout().then();
      }
    }

    if (status === API_STATUS.UNAUTHORIZED) {
      notification.error({ message: translate(generalMessages.unauthorized) });
    }

    if (url === Login.url) setAuthenticationTokens(response.data);

    return response.data;
  });
};

const initializeConfig = async () => {
  await createAxiosConfig();
  await setThirdPartyConfigs();
};

export default initializeConfig;
