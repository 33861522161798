import React from 'react';
import PropTypes from 'prop-types';

// Utils
import { getAssessmentMode } from 'utils/getAssessmentMode';
import { AssessmentMode } from 'constants/assessmentMode';
import { generalMessages } from 'constants/messages';
import { translate } from 'utils/index';

const FindingsColumn = ({ assessment, totalScore }) => {
  const { assessmentAdviceDenominator, assessmentAdviceNumerator } = assessment;
  const formattedValue =
    assessmentAdviceNumerator === 0 && assessmentAdviceDenominator === 0
      ? translate(generalMessages.none)
      : `${assessmentAdviceNumerator} of ${assessmentAdviceDenominator}`;

  const { assessmentProgress, finalized, status, upstream, assessor, assesse } = assessment;
  const calculatedAssessmentMode = getAssessmentMode({
    progress: assessmentProgress || totalScore?.progress,
    finalized,
    status,
    upstream,
    assessor,
    assesse,
  });

  return calculatedAssessmentMode >= AssessmentMode.MarkAsReviewed ? (
    <div>{formattedValue}</div>
  ) : (
    <div>{translate(generalMessages.none)}</div>
  );
};

FindingsColumn.propTypes = {
  totalScore: PropTypes.object,
  assessment: PropTypes.object,
};

export default FindingsColumn;
