import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { notification } from 'antd';
import map from 'lodash/map';
import {
  getProfileNotificationsTemplates,
  setDefaultNotification,
  setProfileNotificationTemplate,
} from 'api/organizations';
import Form from 'ui/Form';
import Select from 'ui/Select';
import Button from 'ui/Button';
import Input from 'ui/Input';
import CardWithLogo from 'components/CardWithLogo';
import Divider from 'ui/Divider';
import TextEditor from 'ui/TextEditor';
import Checkbox from 'ui/Checkbox';
import Popup from 'ui/Popup';
import { generalMessages } from 'constants/messages';
import { API_STATUS } from 'constants/api';
import { translate } from 'utils/index';
import { mixpanelTrackNotificationEdited } from 'utils/mixpanel';
import { messages } from 'pages/Organization/messages';

import resetIcon from 'assets/svgs/reset-default.svg';
import bellCrossIcon from 'assets/svgs/bell-cross-icon.svg';

import './notifications.scss';

const Notifications = () => {
  const { organizationId } = useParams();
  const [displayPopup, setDisplayPopup] = useState(false);
  const [templates, setTemplates] = useState({
    data: {},
    keys: [],
    active: {
      key: '',
      label: '',
      content: '',
      test: false,
      allowDisabling: false,
      disabled: false,
    },
  });
  const [showError, setShowError] = useState(false);
  const validateLabel = () => {
    if (!templates.active.key) {
      setShowError(true);
      return;
    }
  };

  const getTemplates = async () => {
    const data = await getProfileNotificationsTemplates({ orgId: organizationId });
    setTemplates((state) => {
      const newState = {
        ...state,
        data,
        keys: Object.keys(data),
      };
      if (newState.active.key) {
        newState.active = {
          ...data[newState.active.key],
          test: state.active.test,
          key: newState.active.key,
        };
      }
      return newState;
    });
  };

  const labels = useMemo(
    () =>
      map(templates.data, (template, i) => {
        return { label: template.label, value: i, key: `${template.label}${i}` };
      }),
    [templates],
  );

  const onSelect = useCallback(
    (value) => {
      if (showError) {
        setShowError(false);
      }
      setTemplates((state) => ({
        ...state,
        active: {
          ...state.data[value],
          test: state.active.test,
          key: value,
          allowDisabling: state.data[value].allowDisabling || false,
          disabled: state.data[value].disabled || false,
        },
      }));
    },
    [templates, showError],
  );

  const onChange = (value, key) => {
    validateLabel();
    setTemplates((state) => ({
      ...state,
      active: {
        ...state.active,
        [key]: value,
      },
    }));
  };

  const onChangeSubject = ({ target: { value } }) => {
    if (!templates.active.key) {
      setShowError(true);
      return;
    }
    onChange(value, 'subject');
  };

  const setContent = (value) => {
    if (!templates.active.key) {
      setShowError(true);
      return;
    }
    setTemplates((state) => ({
      ...state,
      active: {
        ...state.active,
        content: value,
      },
    }));
  };

  const setPopup = () => {
    if (!templates.active.key) {
      setShowError(true);
      return;
    }
    setDisplayPopup((state) => !state);
  };

  const resetDefaultConfirmationPopup = async () => {
    const status = await setDefaultNotification(organizationId, { key: templates.active.key });

    mixpanelTrackNotificationEdited({ ...templates, defaultRestored: true });

    if (status === API_STATUS.FAILED) {
      return notification.error({
        message: translate(messages.orgEditNotificationsResetDefaultFail),
      });
    }
    setPopup();
    await getTemplates();
    return notification.success({
      message: translate(messages.orgEditNotificationsResetDefaultSuccess),
    });
  };

  const onSave = async () => {
    if (!templates.active.key) {
      setShowError(true);
      return;
    }

    const status = await setProfileNotificationTemplate(organizationId, templates.active);

    mixpanelTrackNotificationEdited(templates);

    if (status === API_STATUS.SUCCESS && templates.active.test) {
      return notification.success({
        message: translate(messages.orgEditNotificationsSavedAndSent),
      });
    }
    if (status === API_STATUS.FAILED) {
      return notification.error({ message: translate(messages.orgEditNotificationsSaveFail) });
    }

    await getTemplates();

    return notification.success({ message: translate(messages.orgEditNotificationsSaved) });
  };

  useEffect(async () => {
    await getTemplates();
  }, []);

  const sendEmail = (
    <Checkbox
      mode="checkbox"
      name="send-email-after-save"
      className="notifications-tab__checkbox"
      value={templates.active.test}
      onChange={() =>
        setTemplates((state) => ({
          ...state,
          active: { ...state.active, test: !state.active.test },
        }))
      }
    >
      {translate(messages.orgEditNotificationsCheckbox)}
    </Checkbox>
  );

  const actionButtons = (
    <div className="notifications-tab__action-buttons">
      <Button
        outline
        size="sm"
        color="pink"
        className="notifications-tab__button"
        onClick={setPopup}
      >
        {translate(messages.orgEditNotificationsRestoreDefault)}
      </Button>
      <Button size="sm" color="pink" onClick={onSave}>
        {translate(generalMessages.save)}
      </Button>
    </div>
  );

  return (
    <div className="notifications-tab">
      <Popup.Confirmation
        open={displayPopup}
        width={350}
        okButtonText={translate(generalMessages.yes)}
        onClickCancel={setPopup}
        onClickOk={resetDefaultConfirmationPopup}
      >
        <div className="notifications-tab__reset-default-popup">
          <img src={resetIcon} alt="reset-default" />
          <Divider hidden />
          <h3>{translate(messages.orgEditNotificationsResetDefaultText)}</h3>
        </div>
      </Popup.Confirmation>
      <Form.Item>
        <Select
          className="notifications-tab__select"
          label={translate(messages.orgEditNotificationsLabel)}
          placeholder={translate(messages.orgEditNotificationsPlaceholderLabel)}
          options={labels}
          size="large"
          color="gray"
          onChange={onSelect}
          error={showError && translate(messages.orgEditNotificationsSelectError)}
        />
      </Form.Item>
      <Form.Item>
        <Input
          block
          label={translate(messages.orgEditNotificationsSubject)}
          className="notifications-tab__input"
          value={templates.active.subject || ''}
          onChange={onChangeSubject}
        />
      </Form.Item>
      {templates.active.allowDisabling && (
        <Form.Item>
          <div className="notifications-tab__disable-notification">
            <img src={bellCrossIcon} alt="disable-nodification-icon" />

            <Checkbox
              mode="checkbox"
              name="disable-notification"
              className="notifications-tab__checkbox"
              value={templates.active.disabled || false}
              data-test="disable-notification-checkbox"
              onChange={() =>
                setTemplates((state) => ({
                  ...state,
                  active: { ...state.active, disabled: !state.active.disabled },
                }))
              }
            >
              {translate(messages.orgEditNotificationsDisableNotification)}
            </Checkbox>
          </div>
        </Form.Item>
      )}
      <Divider hidden />
      <TextEditor content={templates.active.content} setContent={setContent} />
      <Divider size="lg" hidden />
      <CardWithLogo.Footer rightComponent={actionButtons} leftComponent={sendEmail} />
    </div>
  );
};

export default Notifications;
