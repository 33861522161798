import React, { useCallback, useEffect, useState } from 'react';
import FileSaver from 'file-saver';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useViewAssessment from 'components/AppsMonitoring/useViewAssessment';
import ViewModal from 'components/AppsMonitoring/viewModal';

import { Apps } from 'constants/apps';
import { translate } from 'utils/index';
import { messages } from 'pages/Vendors/VendorManage/Tabs/ContinuousMonitoring/messages';
import { downloadFile } from 'api/myFiles';
import SecondaryButton from 'ui/SecondaryButton';
import { useRole } from 'hooks/useRole';

function ActionButton({ app, disabled, onClick, text }) {
  const buttonClassNames = classNames('continuous-monitoring-tab__app-button', {
    'continuous-monitoring-tab__app-button--link': app.id !== Apps.riskRecon,
  });

  return (
    <SecondaryButton
      outline={app.id === Apps.riskRecon}
      className={buttonClassNames}
      color="blue"
      disabled={disabled}
      size="sm"
      squared
      width={150}
      onClick={onClick}
      link={app.id !== Apps.riskRecon}
      data-test={`continuous-monitoring-tab-app-button-${app.name}`}
    >
      {text}
    </SecondaryButton>
  );
}

function AppLink({ app, currentVendor }) {
  const viewAssessment = useViewAssessment();
  const [buttonData, setButtonData] = useState({
    text: '',
    onClick: () => {},
  });
  const [isVisible, setIsVisible] = useState(false);
  const { isAuditorRole } = useRole();

  const downloadSscReport = useCallback(async () => {
    if (!currentVendor || !currentVendor.continuousMonitoring) {
      return null;
    }
    const { allResults } =
      currentVendor?.continuousMonitoring.find(({ id }) => id === Apps.ssc) || {};
    const reportLink = allResults?.controls?.[0];
    const { id, title } = reportLink?.answer[0] ?? {};
    const { path } = await downloadFile({
      fileId: id,
    });

    FileSaver.saveAs(path, title);
  }, [currentVendor, app.id]);

  useEffect(() => {
    setButtonData(() => {
      switch (app.id) {
        case Apps.riskRecon:
          return {
            disabled: isAuditorRole,
            text: translate(messages.viewDetails),
            onClick: () => setIsVisible(true),
          };
        case Apps.qualys:
          return {
            text: translate(messages.viewMore),
            onClick: () => setIsVisible(true),
          };
        case Apps.aws:
        case Apps.azure:
        case Apps.ibm:
          return {
            text: translate(messages.viewMore),
            onClick: () => viewAssessment({ appId: app.id, currentVendor }),
          };
        case Apps.ssc:
          return {
            text: translate(messages.downloadReport),
            onClick: downloadSscReport,
          };
        default:
          return '';
      }
    });
  }, [app.id]);

  return (
    <>
      <div className="continuous-monitoring-tab__app-footer">
        <ActionButton app={app} {...buttonData} />
      </div>
      {isVisible && <ViewModal app={app} isVisible={isVisible} setVisibility={setIsVisible} />}
    </>
  );
}

ActionButton.propTypes = {
  app: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

AppLink.propTypes = {
  app: PropTypes.object,
  currentVendor: PropTypes.object,
};

export default AppLink;
