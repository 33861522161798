import { ReactComponent as UploadIcon } from 'assets/svgs/upload-icon.svg';
import { translate } from 'utils/index';
import { messages } from './messages';

import './myFilesUploadFile.scss';
import Button from 'ui/Button';
import PropTypes from 'prop-types';

const MyFilesUploadFile = ({ onFileUpload }) => {
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleClickButton = () => {
    document.getElementById('fileInput').click();
  };

  return (
    <div
      className="my-files-upload-file"
      onDragOver={handleDragOver}
      onDrop={(e) => onFileUpload(e)}
    >
      <input type="file" id="fileInput" style={{ display: 'none' }} onChange={onFileUpload} />
      <Button
        onClick={handleClickButton}
        beforeComponent={<UploadIcon width={27} height={27} />}
        color="blue"
        link
        data-test={'upload-new-file-button'}
      >
        {translate(messages.uploadFile)}
      </Button>

      <p className="max-size-info">{translate(messages.maxSizeInfo)}</p>
    </div>
  );
};

MyFilesUploadFile.propTypes = {
  onFileUpload: PropTypes.func,
};

export default MyFilesUploadFile;
