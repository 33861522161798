import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

// COMPONENTS
import CardWithLogo from 'components/CardWithLogo';
import Button from 'ui/Button';
import Form from 'ui/Form';
import SecondAuthForm from 'components/SecondAuthForm';
import SecondaryButton from 'ui/SecondaryButton';
import { AttentionIcon } from 'components/Icons';

// CONSTANTS
import { generalMessages } from 'constants/messages';
import { API_STATUS, MFA_METHOD } from 'constants/api';
import { LOGIN_FORM_MODE } from 'pages/Login/lib';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Login/messages';
import usePostLogin from 'pages/Login/usePostLogin';
import { sanitizer } from 'utils/sanitizer';

// API
import { loginUser } from 'api/authentication';
import { sendMfaViaEmail } from 'api/profile';

// REDUX
import { selectLogin } from 'pages/Login/selectors';

// STYLE
import colors from 'ui/colors.scss';
import './secondAuthentication.scss';

const SecondAuthentication = ({ setMode }) => {
  const [otpCode, setOtpCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [setEmailMfaLoading, setSetEmailMfaLoading] = useState(false);
  const [setEmailMfa, setSetEmailMfa] = useState(false);
  const [error, setError] = useState('');
  const { mfaMethod, email, password, sso, ssoToken, phone } = useSelector(selectLogin);
  const dispatch = useDispatch();
  const postLogin = usePostLogin();

  // handle use case: assignment to new organization
  const [searchParams] = useSearchParams();
  const organizationId = searchParams.get('organization');

  const onClickBack = () => {
    setMode(LOGIN_FORM_MODE.LOGIN);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!otpCode || (otpCode && otpCode.toString().length < 6)) {
      return setError(translate(messages.invalidOtpCode));
    }

    setLoading(true);
    const loginBody = {
      otpCode,
    };
    if (sso) {
      loginBody.sso = true;
      loginBody.ssoToken = ssoToken;
    } else {
      loginBody.email = email;
      loginBody.password = password;
    }

    if (setEmailMfa) {
      loginBody.setEmailMfa = true;
    }

    const {
      payload: { role, status, redirect, org_id: orgIdFromLogin, view },
    } = await dispatch(loginUser(loginBody));
    setLoading(false);

    if (status === API_STATUS.SUCCESS) {
      await postLogin({
        organizationId,
        orgIdFromLogin,
        role,
        redirect,
        view,
      });
    }

    return null;
  };

  const resendViaEmail = async () => {
    setSetEmailMfaLoading(true);
    setSetEmailMfa(true);
    await dispatch(sendMfaViaEmail({ email }));
    setSetEmailMfaLoading(false);
  };

  const onChangeOtpCode = (code) => {
    setError('');

    if (!Number.isNaN(+code)) {
      setOtpCode(code);
    }
  };

  const hashedEmail = email ? `${email[0]}XXX${email?.slice(email?.indexOf('@'))}` : '';

  return (
    <Form onSubmit={onSubmit} className="login-second-authentication">
      <CardWithLogo.Header
        className="login-second-authentication__header"
        icon="lock"
        title={translate(messages.secondAuthenticationTitle)}
        subtitle={
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                translate(
                  mfaMethod === MFA_METHOD.EMAIL ? messages.authMsgEmail : messages.authMsgSms,
                  {
                    email: hashedEmail,
                    phone,
                  },
                ),
              ),
            }}
          />
        }
        backButtonOnClick={onClickBack}
        beforeComponent={
          <div className="login-second-authentication__alert">
            <AttentionIcon
              color={colors.red600}
              className="login-second-authentication__alert-icon"
            />
            {translate(messages.yourAdminRequires2Fa)}
          </div>
        }
      />
      <SecondAuthForm error={error} otpCode={otpCode} setOtpCode={onChangeOtpCode}>
        <div className="login-second-authentication__footer">
          {mfaMethod === MFA_METHOD.EMAIL ? (
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizer(translate(messages.didntReceiveEmail)),
              }}
            />
          ) : (
            <>
              <span>{translate(messages.didntReceiveCode)}</span>
              <SecondaryButton link onClick={resendViaEmail} loading={setEmailMfaLoading}>
                {translate(messages.resendViaEmail)}
              </SecondaryButton>
            </>
          )}
        </div>
        <Form.Item>
          <Button
            centered
            type="submit"
            loading={loading}
            name={translate(generalMessages.submit)}
            color="pink"
            onClick={onSubmit}
            data-test="second-authentication-form-submit-button"
          />
        </Form.Item>
      </SecondAuthForm>
    </Form>
  );
};

SecondAuthentication.propTypes = {
  setMode: PropTypes.func,
};

export default SecondAuthentication;
