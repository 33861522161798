import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Utils
import { translate } from 'utils/index';
import { messages } from 'components/UpstreamAI/messages';

// Assets
import errorImage from 'assets/svgs/ai-error.svg';

function ErrorMessage({ setMixpanelError }) {
  useEffect(() => {
    setMixpanelError(true);
  }, []);
  return (
    <div className="upstream-ai__error">
      <img src={errorImage} alt="error" />
      <div>{translate(messages.error)}</div>
    </div>
  );
}

ErrorMessage.propTypes = {
  setMixpanelError: PropTypes.func.isRequired,
};

export default ErrorMessage;
