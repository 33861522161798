import { createSelector } from '@reduxjs/toolkit';

import { ControlType } from 'constants/controlType';
import { Apps } from 'constants/apps';

import { selectControls } from 'pages/Assessment/selectors';
import { EMPTY_CATEGORY } from 'pages/Editor/subjects/lib';

import { v4 as uuidv4 } from 'uuid';

const getEmptyCategoryItem = () => ({
  ...EMPTY_CATEGORY,
  title: '',
  id: uuidv4(),
});

export const selectTemplate = createSelector(
  ({ editor }) => editor?.template,
  (template) => {
    const newTemplate = { ...template } || {};
    if (newTemplate.categories?.length === 0) {
      newTemplate.categories = [getEmptyCategoryItem()];
    }
    return newTemplate;
  },
);

export const selectSaveLoading = createSelector(
  ({ editor }) => editor.saveLoading,
  (saveLoading) => saveLoading,
);

export const selectSubject = createSelector(
  ({ editor }) => editor?.template?.categories,
  ({ editor }) => editor.selectedSubject,
  (subjects, selectedSubject) => {
    let subject = (subjects || []).find(({ id }) => selectedSubject === id);
    let mappedSubject = {
      ...subject,
      controls: subject?.controls?.map((control, index) => ({ ...control, counter: index })) || [],
    };
    if (subject) {
      return mappedSubject;
    }
    return subjects?.[0] || getEmptyCategoryItem();
  },
);

export const selectActiveQuestion = createSelector(
  ({ editor }) => editor.activeQuestion,
  (activeQuestion) => activeQuestion,
);

export const selectBackToFollowUpQuestion = createSelector(
  ({ editor }) => editor.backToFollowUpQuestion,
  (backToFollowUpQuestion) => backToFollowUpQuestion,
);

export const selectSubjectKey = createSelector(
  ({ editor }) => editor.template?.categories,
  ({ editor }) => editor?.selectedSubject,
  (subjects, selectedSubject) => {
    const subjectIndex = (subjects || []).findIndex(({ id }) => selectedSubject === id);
    if (subjectIndex > -1) {
      return subjectIndex + 1;
    }

    return 1;
  },
);

export const selectAnswerTypes = createSelector(
  ({ editor }) => editor?.template?.answer_types,
  (answerTypes) => answerTypes,
);

export const selectAllAnswerTypes = createSelector(
  ({ editor }) => editor.answerTypes,
  (answerTypes) => answerTypes || [],
);

const filterAnswerTypes = (answerType) => {
  return [
    ControlType.RadioGroup,
    ControlType.Checkbox,
    ControlType.DropDown,
    ControlType.MultiSelect,
  ].includes(answerType.type);
};

export const selectEditableAnswerTypes = createSelector(
  ({ editor }) => editor.answerTypes,
  (answerTypes) => (answerTypes || []).filter(filterAnswerTypes),
);

export const selectAllTemplateControlsByCategory = createSelector(
  ({ editor }) => editor?.template,
  selectControls,
);

export const selectAllAppControls = createSelector(
  selectSubject,
  (_, apps) => apps,
  (subject, apps) => {
    return (
      subject.controls?.map((control, index) => ({ ...control, counter: index })) || []
    ).filter((control) =>
      (control.apps || []).some(
        (app) => apps.includes(app) || (apps.includes(Apps.aws) && app === Apps.azure),
      ),
    );
  },
);

export const selectAllApps = createSelector(
  ({ editor }) => editor?.template,
  (template) => {
    const allControls = template.categories.flatMap(({ controls }) => controls);
    const allApps = allControls.reduce((acc, control) => {
      if (control?.apps && control?.apps?.length > 0) {
        return acc.concat(control.apps);
      }
      return acc;
    }, []);

    // uniqueApps
    return [...new Set(allApps)];
  },
);
