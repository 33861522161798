import React from 'react';
import { ReactComponent as NotSupportedOnMobileIcon } from 'assets/svgs/not-supported-on-mobile.svg';
import { ReactComponent as CrossIcon } from 'assets/svgs/cross-circle.svg';
import { translate } from 'utils/index';

import { messages } from './messages';
import './index.scss';

const NotSupportedOnMobile = () => {
  return (
    <section className="not-found-supported-on-mobile">
      <div className="not-found-supported-on-mobile__container">
        <div className="not-found-supported-on-mobile__details">
          <div className="not-found-supported-on-mobile__camera-dot"></div>
          <div className="not-found-supported-on-mobile__info">
            <CrossIcon />
            <div className="not-found-supported-on-mobile__title">{translate(messages.title)}</div>
            <div className="not-found-supported-on-mobile__yet">{translate(messages.yet)}</div>
          </div>
        </div>
        <div className="not-found-supported-on-mobile__not-supported-icon">
          <NotSupportedOnMobileIcon />
        </div>
        <div className="not-found-supported-on-mobile__background"></div>
      </div>
    </section>
  );
};

export default NotSupportedOnMobile;
