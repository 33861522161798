export const messages = {
  messages: {
    key: 'Messenger Notifications.messages',
    defaultMessage: 'Messages',
  },
  readAll: {
    key: 'Messenger Notifications.readAll',
    defaultMessage: 'Mark all as read',
  },
  errorMarkAllAsRead: {
    key: 'Messenger Notifications.errorMarkAllAsRead',
    defaultMessage: 'Unable to mark all messages as read',
  },
  date: {
    key: 'Messenger Notifications.date',
    defaultMessage: 'date',
  },
  vendor: {
    key: 'Messenger Notifications.vendor',
    defaultMessage: 'vendor',
  },
  customer: {
    key: 'Messenger Notifications.customer',
    defaultMessage: 'customer',
  },
  assessment: {
    key: 'Messenger Notifications.assessment',
    defaultMessage: 'assessment',
  },
  message: {
    key: 'Messenger Notifications.message',
    defaultMessage: 'message',
  },
  user: {
    key: 'Messenger Notifications.user',
    defaultMessage: 'user',
  },
  questionFinding: {
    key: 'Messenger Notifications.questionFinding',
    defaultMessage: 'question/finding',
  },
  view: {
    key: 'Messenger Notifications.view',
    defaultMessage: 'view',
  },
  viewAll: {
    key: 'Messenger Notifications.viewAll',
    defaultMessage: 'view all',
  },
  items: {
    key: 'Messenger Notifications.items',
    defaultMessage: 'items',
  },
  noMessages: {
    key: 'Messenger Notifications.noMessages',
    defaultMessage: 'You have no unread messages',
  },
  onlyShowUnread: {
    key: 'Messenger Notifications.onlyShowUnread',
    defaultMessage: 'Only show unread',
  },
  noData: {
    key: 'Messenger Notifications.noData',
    defaultMessage: 'You dont have any massages',
  },
};

export default {
  prefix: 'Messenger Notifications',
  messages,
};
