import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils/index';
import { ReactComponent as AssessmentSentIcon } from 'assets/svgs/assessment-sent.svg';
import { ReactComponent as ShowcaseIcon } from 'assets/svgs/showcase-sent.svg';

import { setShowSentAssessmentPopup } from './reducers';
import { messages } from './messages';
import './assessmentSentPopup.scss';
import InfoModal from 'components/InfoModal';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';

const AssessmentSentPopup = () => {
  const dispatch = useDispatch();
  const isVendorSelected = useIsMyVendorsTabSelected();

  const showSentAssessmentPopup = useSelector(
    ({ assignAssessment }) => assignAssessment.showSentAssessmentPopup,
  );

  const onOk = () => dispatch(setShowSentAssessmentPopup(false));
  const onCancel = () => dispatch(setShowSentAssessmentPopup(false));

  const headerText = isVendorSelected
    ? translate(messages.assessmentSent)
    : translate(messages.showcaseSent);
  const text = isVendorSelected
    ? translate(messages.vendorNoticed)
    : translate(messages.customerNoticed);
  const icon = isVendorSelected ? <AssessmentSentIcon /> : <ShowcaseIcon />;
  return (
    <InfoModal
      onOk={onOk}
      onCancel={onCancel}
      headerText={headerText}
      icon={icon}
      text={text}
      open={showSentAssessmentPopup}
    />
  );
};

export default AssessmentSentPopup;
