import { Apps } from 'constants/apps';

import RiskReconIcon from 'assets/svgs/risk-recon-icon.svg';
import AzureIcon from 'assets/svgs/azure-icon.svg';
import AwsIcon from 'assets/svgs/aws-icon.svg';
import QualysIcon from 'assets/svgs/qualys-icon.svg';
import SSCIcon from 'assets/svgs/ssc-icon.svg';
import IBMIcon from 'assets/svgs/ibm-x-force-icon.svg';
import SbomIcon from 'assets/svgs/sbom-icon.svg';
import CloudhawkIcon from 'assets/svgs/cloudhawk-icon.svg';

export const getAppIconById = (appId) => {
  switch (appId) {
    case Apps.azure:
      return AzureIcon;
    case Apps.aws:
      return AwsIcon;
    case Apps.riskRecon:
      return RiskReconIcon;
    case Apps.qualys:
      return QualysIcon;
    case Apps.ssc:
      return SSCIcon;
    case Apps.ibm:
      return IBMIcon;
    case Apps.cloudhawk:
      return CloudhawkIcon;
    case Apps.sbom:
      return SbomIcon;
    default:
      return null;
  }
};

export const shouldAppNavigateToContinuousMonitoring = (appId) => {
  // currently all apps findings/recent findings should navigate to continuous monitoring pge, only SBOM, aws and azure should not
  return [Apps.riskRecon, Apps.qualys, Apps.ssc, Apps.ibm, Apps.cloudhawk].includes(appId);
};
