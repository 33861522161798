export const messages = {
  uploadFiles: {
    key: 'UploadFiles.uploadFiles',
    defaultMessage: 'Upload files',
  },
  support: {
    key: 'UploadFiles.support',
    defaultMessage: 'Support',
  },
  maxSize: {
    key: 'UploadFiles.maxSize',
    defaultMessage: 'Max size',
  },
  browse: {
    key: 'UploadFiles.browse',
    defaultMessage: 'Upload',
  },
  removeFileText: {
    key: 'UploadFiles.removeFileText',
    defaultMessage: 'This file will no longer be part of the assessment',
  },
  removeFileTextHeader: {
    key: 'UploadFiles.removeFileTextHeader',
    defaultMessage: 'Remove file?',
  },
};

export default {
  prefix: 'UploadFiles',
  messages,
};
