const defaultSort = (a, b) => {
  if (a === '' || b === '') {
    if (a) return a;
    if (b) return b;
  }
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
};

const stringSort = (a, b) => {
  if (a === '' || b === '') {
    if (a) return a;
    if (b) return b;
  }
  const nameA = a.toLowerCase();
  const nameB = b.toLowerCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

const riskSort = (a, b) => {
  const riskA = a.risk;
  const riskB = b.risk;
  if (riskA < riskB) return -1;
  if (riskB < riskA) return 1;
  return 0;
};

export const Sorter = {
  DEFAULT: defaultSort,
  STRING: stringSort,
  RISK: riskSort,
};
