import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import { getAssessmentsPageTypeFilter } from 'api/filtersData';
import { createSorterByString } from 'utils/strings';
import Filter from 'ui/Filter';
import { translate } from 'utils/index';
import { messages } from 'components/AddFilterButton/messages';
import {
  selectAssessmentsTemplates,
  selectAssessmentsTemplatesLoading,
  selectIsFilteredArchived,
} from './selectors';
import './index.scss';

const { Option } = Select;

const FilterType = ({ fetchData, filter, ...otherProps }) => {
  const loading = useSelector(selectAssessmentsTemplatesLoading);
  const types = useSelector(selectAssessmentsTemplates);
  const dispatch = useDispatch();
  const isFilteredArchived = useSelector(selectIsFilteredArchived);

  useEffect(() => {
    dispatch(getAssessmentsPageTypeFilter({ archive: isFilteredArchived }));
  }, [isFilteredArchived]);

  const sortedTypes = useMemo(() => types.slice().sort(createSorterByString('name'), [types]));

  return (
    <Filter
      filter={filter}
      fetchData={fetchData}
      loading={loading}
      assessmentType
      innerLabel={translate(messages.assessmentFilter)}
      {...otherProps}
    >
      {sortedTypes.map(({ name, id }) => (
        <Option key={id} value={name} id={id}>
          {name}
        </Option>
      ))}
    </Filter>
  );
};

FilterType.propTypes = {
  fetchData: PropTypes.func,
  filter: PropTypes.object,
};

export default FilterType;
