import React from 'react';
import './indicator.scss';

const LoaderIndicator = () => (
  <div className="ui-loader-indicator">
    <div className="ui-loader-indicator__dot ui-loader-indicator__first" />
    <div className="ui-loader-indicator__dot ui-loader-indicator__second" />
    <div className="ui-loader-indicator__dot" />
  </div>
);

export default LoaderIndicator;
