import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popover, Tooltip } from 'antd';

import { translate } from 'utils/index';
import { stripHtml } from 'utils/html';
import { calculateControlKey } from 'pages/Assessment/lib';
import { useGetControlById } from 'pages/Assessment/hooks';
import Icon from 'ui/Icon';

import { scrollToControl } from 'pages/Editor/lib';
import { selectTemplate } from 'pages/Editor/selectors';
import { messages } from 'pages/Editor/messages';
import { selectSubject, setActiveQuestion, setBackToFollowUpQuestion } from 'pages/Editor/reducers';
import './categoryTriggers.scss';

const CategoryTriggers = ({ category, controlId, triggers }) => {
  const { categories = [] } = useSelector(selectTemplate);
  const getControlById = useGetControlById(true);
  const dispatch = useDispatch();

  return (
    <div className="editor-category-triggers">
      <div className="editor-category-triggers__title">{category.title}</div>
      <div className="editor-category-triggers__triggers">
        <span>{translate(messages.questions)}: </span>
        {triggers.map((trigger, index) => {
          const currentControl = getControlById(trigger);
          const subjectIndex = categories.findIndex(({ id }) => currentControl.categoryId === id);
          const controlIndex = categories
            .find(({ id }) => id === currentControl.categoryId)
            .controls.findIndex(({ id }) => trigger === id);

          const controlKey = calculateControlKey(subjectIndex + 1, controlIndex);

          const isLast = triggers.length === index + 1;

          const goToQuestion = () => {
            dispatch(selectSubject(currentControl.categoryId));
            dispatch(setActiveQuestion(currentControl.id));
            dispatch(setBackToFollowUpQuestion(controlId));

            scrollToControl(currentControl.id);
          };

          const content = (
            <div className="editor-category-triggers__trigger-popover">
              <span className="editor-category-triggers__trigger-popover-suffix">{controlKey}</span>
              {stripHtml(currentControl.title)}
              <Tooltip title={translate(messages.goToQuestion)}>
                <Button
                  className="editor-category-triggers__trigger-popover-link"
                  type="link"
                  onClick={goToQuestion}
                >
                  <Icon icon="symlink" />
                </Button>
              </Tooltip>
            </div>
          );

          return (
            <Popover key={`popover_${trigger}`} content={content}>
              <span key={trigger} className="editor-category-triggers__trigger">
                {controlKey}
                {isLast ? '' : ', '}
              </span>
            </Popover>
          );
        })}
      </div>
    </div>
  );
};

CategoryTriggers.propTypes = {
  category: PropTypes.object,
  controlId: PropTypes.string,
  triggers: PropTypes.array,
};

export default CategoryTriggers;
