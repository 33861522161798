import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FileTypeButton from 'ui/Button/fileTypeButton';
import Icon from 'ui/Icon';
import ButtonLoader from 'ui/Button/buttonLoader';
import './index.scss';

const Button = ({
  afterComponent,
  afterIcon,
  afterIconClassName,
  beforeComponent,
  beforeIcon,
  beforeIconClassName,
  block,
  children,
  className,
  color,
  disabled,
  fluid,
  iconClassName,
  link,
  loading,
  lowercase,
  name,
  outline,
  size,
  squared,
  type,
  width,
  centered,
  noShadow,
  uppercase,
  withHover,
  confirmed,
  ...otherProps
}) => {
  const composedClassName = classNames(className, 'ui-button', {
    [`ui-button--${size}`]: true,
    [`ui-button--${color}-button`]: !outline,
    [`ui-button--${color}-outline`]: outline,
    [`ui-button--${color}-link`]: !!link,
    [`ui-button--${color}-confirmed`]: confirmed,
    'ui-button--disabled': disabled,
    'ui-button--loading': loading,
    'ui-button--lowercase': lowercase,
    'ui-button--uppercase': uppercase,
    'ui-button--squared': squared,
    'ui-button--block': block,
    'ui-button--fluid': fluid,
    'ui-button--centered': centered,
    'ui-button--no-shadow': noShadow,
    'ui-button--with-hover': withHover,
  });

  return (
    <button
      style={{ minWidth: width, width }}
      type={type}
      className={composedClassName}
      disabled={disabled || loading}
      {...otherProps}
    >
      {beforeIcon && (
        <span className={classNames('ui-button__before-icon', iconClassName, beforeIconClassName)}>
          <Icon icon={beforeIcon} />
        </span>
      )}
      {beforeComponent && beforeComponent}
      <span className="ui-button__name">{name || children}</span>
      {afterIcon && (
        <span className={classNames('ui-button__after-icon', afterIconClassName)}>
          <Icon icon={afterIcon} />
        </span>
      )}
      {afterComponent && afterComponent}
      {loading && <ButtonLoader size={size} color={color} />}
    </button>
  );
};

Button.propTypes = {
  afterComponent: PropTypes.node,
  afterIcon: PropTypes.string,
  afterIconClassName: PropTypes.string,
  beforeComponent: PropTypes.node,
  beforeIcon: PropTypes.string,
  beforeIconClassName: PropTypes.string,
  block: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  color: PropTypes.oneOf([
    'pink',
    'purple',
    'green',
    'blue',
    'black',
    'white',
    'gray',
    'dark-blue',
    'red',
  ]),
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  hideUnderline: PropTypes.bool,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  link: PropTypes.bool,
  loading: PropTypes.bool,
  lowercase: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  outline: PropTypes.bool,
  simple: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  squared: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  underline: PropTypes.bool,
  uppercase: PropTypes.bool,
  width: PropTypes.number,
  centered: PropTypes.bool,
  noShadow: PropTypes.bool,
  'data-test': PropTypes.string.isRequired,
  withHover: PropTypes.bool,
  confirmed: PropTypes.bool,
};

Button.defaultProps = {
  block: false,
  color: 'green',
  disabled: false,
  fluid: false,
  lowercase: false,
  size: 'lg',
  type: 'button',
  confirmed: false,
};

Button.FileType = FileTypeButton;

export default Button;
