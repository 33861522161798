import { createSelector } from '@reduxjs/toolkit';
import RiskReconIcon from 'assets/svgs/risk-recon-icon.svg';
import SSCIcon from 'assets/svgs/ssc-icon.svg';
import CloudhawkIcon from 'assets/svgs/cloudhawk-icon.svg';
import IBMIcon from 'assets/svgs/ibm-x-force-icon.svg';
import SbomIcon from 'assets/svgs/sbom-icon.svg';
import QualysIcon from 'assets/svgs/qualys-icon.svg';
import AwsIcon from 'assets/svgs/aws-icon.svg';
import AzureIcon from 'assets/svgs/azure-icon.svg';
import FindingsCloudMonitoringIcon from 'assets/svgs/findings-cloud-monitoring-icon.svg';

import { Apps } from 'constants/apps';

export const selectProfile = createSelector(
  ({ profile }) => profile,
  (state) => state,
);

export const selectUserRole = createSelector(
  ({ profile }) => profile,
  (state) => state?.user?.current_organization?.role,
);

export const selectCurrentOrganization = createSelector(
  selectProfile,
  ({ user }) => user.current_organization,
);

export const selectOrganizations = createSelector(
  ({ profile }) => profile,
  (state) => state?.user?.organizations || [],
);

export const selectPendingAssessments = createSelector(selectProfile, ({ user }) => {
  const { unifiedPendingAssessments = [] } = user;
  return unifiedPendingAssessments;
});

const defaultApps = [
  {
    id: Apps.aws,
    label: 'Findings Cloud Monitoring - AWS',
    name: 'findings-cloud-monitoring-aws',
    icon: AwsIcon,
  },
  {
    id: Apps.azure,
    label: 'Findings Cloud Monitoring - Azure',
    name: 'findings-cloud-monitoring-azure',
    icon: AzureIcon,
  },
  {
    id: Apps.cloudMonitoring,
    label: 'Findings Cloud Monitoring',
    name: 'findings-cloud-monitoring',
    icon: FindingsCloudMonitoringIcon,
  },
  {
    id: Apps.azure,
    label: 'Findings Cloud Monitoring',
    name: 'findings-cloud-monitoring',
    icon: AzureIcon,
  },
  {
    id: Apps.riskRecon,
    label: 'Riskrecon',
    name: 'risk-recon',
    icon: RiskReconIcon,
  },
  {
    id: Apps.sbom,
    label: 'SBOM',
    name: 'sbom',
    icon: SbomIcon,
  },
];

const appIcons = {
  [Apps.ssc]: {
    icon: SSCIcon,
    name: 'ssc',
  },
  [Apps.cloudhawk]: {
    icon: CloudhawkIcon,
    name: 'cloudhawk',
  },
  [Apps.ibm]: {
    icon: IBMIcon,
    name: 'ibm',
  },
  [Apps.qualys]: {
    icon: QualysIcon,
    name: 'qualys',
  },
  [Apps.sbom]: {
    icon: SbomIcon,
    name: 'sbom',
  },
};

export const selectApps = createSelector(selectProfile, (profile) => {
  const {
    user: { apps = [] },
  } = profile;
  const userApps = apps
    .filter((app) => ![Apps.aws, Apps.azure, Apps.riskRecon].includes(app.id))
    .map((app) => ({
      ...app,
      ...appIcons[app.id],
    }));

  return [...defaultApps, ...userApps];
});

export const selectAppById = (appId) =>
  createSelector(selectApps, (apps) => apps.find((app) => app.id === appId));
