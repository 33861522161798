import { createSlice } from '@reduxjs/toolkit';
import { getEvidences } from 'api/evidences';
import { DEFAULT_PAGINATION } from 'constants/api';

const initialState = {
  evidences: [],
  loading: false,
  pagination: DEFAULT_PAGINATION,
  search: '',
  sorter: {},
};

const reducers = createSlice({
  name: 'evidences',
  initialState,
  reducers: {
    setSorter: (state, { payload }) => {
      state.sorter = payload || {};
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload || {};
    },
    setSearch: (state, { payload }) => {
      state.search = payload.search || '';
    },
    setParam: (state, { payload: { key, value } }) => {
      state[key] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvidences.pending, (state) => {
        state.loading = true;
      })

      .addCase(getEvidences.fulfilled, (state, action) => {
        const total = action.payload?.total || 0;
        const evidences = action.payload?.evidences || [];
        const column = action.payload?.column || [];
        const descending = action.payload?.descending || false;

        return {
          ...state,
          evidences,
          column,
          descending,
          totalEvidences: total,
          loading: false,
          pagination: {
            ...state?.pagination,
            total,
          },
        };
      });
  },
});

export const { setSorter, setPagination, setParam, setSearch } = reducers.actions;

export default reducers;
