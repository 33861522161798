import React from 'react';
import PropTypes from 'prop-types';

import Form from 'ui/Form';
import Divider from 'ui/Divider';
import AssessmentCategory from 'pages/SoBoPages/assessmentCategory';

const AssessmentForm = ({ assessment, categories, onSubmit, children }) => {
  return (
    <div>
      <Divider hidden />
      <Form onSubmit={onSubmit}>
        {categories.length !== 0 &&
          categories.map((category) => (
            <AssessmentCategory
              key={category.id}
              category={category}
              answerTypes={assessment['answer_types']}
            />
          ))}
        {children}
      </Form>
    </div>
  );
};

AssessmentForm.propTypes = {
  categories: PropTypes.array,
  onSubmit: PropTypes.func,
  children: PropTypes.any,
  assessment: PropTypes.object,
  setFileObjects: PropTypes.func,
  removeFileObjects: PropTypes.func,
};

export default AssessmentForm;
