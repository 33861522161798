import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import Icon from 'ui/Icon';
import Button from 'ui/Button';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'components/UpstreamAI/messages';
import { buttonTypes, titleStructures } from 'components/UpstreamAI/lib';

// ACTIONS
import { setEnableAI } from 'components/UpstreamAI/reducers';
import { getImprovedAnswer } from 'api/upstreamAi';

// STYLE
import './thirdPartyConsent.scss';
import { auditControl } from 'api/downstreamAi';

function ThirdPartyConsent({
  source,
  setTitleStructure,
  setSelectedButton,
  getFunctionData,
  setMixpanelConsent,
  setMixpanelResponseTime,
  setOpen,
}) {
  const dispatch = useDispatch();

  const handleAgree = () => {
    const beforeCall = Date.now();
    dispatch(setEnableAI(true));

    if (source === buttonTypes.improve) {
      dispatch(getImprovedAnswer(getFunctionData));
    }

    if (source === buttonTypes.audit) {
      dispatch(auditControl(getFunctionData));
    }

    const afterCall = Date.now();
    setMixpanelResponseTime(afterCall - beforeCall);
    setMixpanelConsent(true);
  };

  const handleDisagree = () => {
    dispatch(setEnableAI(false));
    setMixpanelConsent(false);

    if (source === buttonTypes.improve) {
      setTitleStructure(titleStructures.exampleOnly);
      setSelectedButton(buttonTypes.example);
    }

    if (source === buttonTypes.audit) {
      setOpen(false);
    }
  };

  return (
    <div className="third-party-consent">
      <div className="third-party-consent__title">
        <Icon icon="info" />
        <span> {translate(messages.thirdPartyConsentTitle)}</span>
      </div>
      <div className="third-party-consent__content">
        {translate(messages.thirdPartyConsentContent)}
      </div>
      <div className="third-party-consent__footer">
        <Button
          outline
          size="sm"
          color={'gray'}
          className={'third-party-consent__footer-button-left'}
          onClick={handleDisagree}
          data-test={'third-party-consent-footer-button-left'}
        >
          {translate(messages.thirdPartyConsentButtonDisagree)}
        </Button>
        <Button
          data-test={'third-party-consent-footer-button-right'}
          onClick={handleAgree}
          beforeIcon={'sparkle'}
          size="sm"
          className={'third-party-consent__footer-button-right'}
        >
          {translate(messages.thirdPartyConsentButtonAgree)}
        </Button>
      </div>
    </div>
  );
}

ThirdPartyConsent.propTypes = {
  source: PropTypes.string.isRequired,
  setOpen: PropTypes.func,
  setMixpanelConsent: PropTypes.func,
  setMixpanelResponseTime: PropTypes.func,
  setTitleStructure: PropTypes.func.isRequired,
  setSelectedButton: PropTypes.func.isRequired,
  getFunctionData: PropTypes.object.isRequired,
};

export default ThirdPartyConsent;
