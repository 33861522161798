import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { WS_TOKEN_AUTH_KEY } from 'utils/auth';

import ROUTES from 'constants/routes';
import { getCookie } from 'utils/apiHelpers';

const PrivateElement = ({ children }) => {
  const wsToken = getCookie(WS_TOKEN_AUTH_KEY);
  const originalPath = window.location.pathname;

  if (!wsToken) {
    return (
      <Navigate
        to={ROUTES.LOGIN}
        state={!originalPath.includes(ROUTES.LOGIN) ? originalPath : ''}
      />
    );
  }

  return children;
};

PrivateElement.propTypes = {
  children: PropTypes.any,
};

export default PrivateElement;
