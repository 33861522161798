export const messages = {
  recentHeader: {
    key: 'Recent.recentHeader',
    defaultMessage: 'Recent',
  },
  recentVendors: {
    key: 'Recent.recentVendors',
    defaultMessage: 'Recent added vendors:',
  },
  recentCustomers: {
    key: 'Recent.recentCustomers',
    defaultMessage: 'Recent added customers:',
  },
  recentAssessments: {
    key: 'Recent.recentAssessments',
    defaultMessage: 'Recent assessments:',
  },
  recentFindings: {
    key: 'Recent.recentFindings',
    defaultMessage: 'Recent findings',
  },
  recentEvidences: {
    key: 'Recent.recentEvidences',
    defaultMessage: 'Recent evidences',
  },
  week: {
    key: 'Recent.week',
    defaultMessage: 'Week',
  },
  month: {
    key: 'Recent.month',
    defaultMessage: 'Month',
  },
  company: {
    key: 'Recent.company',
    defaultMessage: 'COMPANY',
  },
  name: {
    key: 'Recent.name',
    defaultMessage: 'NAME',
  },
  email: {
    key: 'Recent.email',
    defaultMessage: 'EMAIL',
  },
  phone: {
    key: 'Recent.phone',
    defaultMessage: 'PHONE',
  },
  activeAssessments: {
    key: 'Recent.activeAssessments',
    defaultMessage: 'ACTIVE ASSESSMENTS',
  },
  internalRisk: {
    key: 'Recent.internalRisk',
    defaultMessage: 'INTERNAL RISK',
  },
  assessmentScore: {
    key: 'Recent.assessmentScore',
    defaultMessage: 'ASSESSMENT SCORE',
  },
  vendorRisk: {
    key: 'Recent.vendorRisk',
    defaultMessage: 'VENDOR RISK',
  },
  customerRisk: {
    key: 'Recent.customerRisk',
    defaultMessage: 'CUSTOMER RISK',
  },
  businessOwner: {
    key: 'Recent.businessOwner',
    defaultMessage: 'BUSINESS OWNER',
  },
  trustIndicator: {
    key: 'Recent.trustIndicator',
    defaultMessage: 'TRUST INDICATOR',
  },
  vendorStatus: {
    key: 'Recent.vendorStatus',
    defaultMessage: 'STATUS',
  },
  date: {
    key: 'Recent.date',
    defaultMessage: 'DATE',
  },
  organization: {
    key: 'Recent.organization',
    defaultMessage: 'ORGANIZATION',
  },
  vendor: {
    key: 'Recent.vendor',
    defaultMessage: 'VENDOR',
  },
  customer: {
    key: 'Recent.customer',
    defaultMessage: 'CUSTOMER',
  },
  type: {
    key: 'Recent.type',
    defaultMessage: 'TYPE',
  },
  due: {
    key: 'Recent.due',
    defaultMessage: 'DUE',
  },
  score: {
    key: 'Recent.score',
    defaultMessage: 'SCORE',
  },
  status: {
    key: 'Recent.status',
    defaultMessage: 'STATUS',
  },
  openFindings: {
    key: 'Recent.openFindings',
    defaultMessage: 'OPEN FINDINGS',
  },
  findings: {
    key: 'Recent.findings',
    defaultMessage: 'FINDINGS',
  },
  showcaseIndicator: {
    key: 'Recent.showcaseIndicator',
    defaultMessage: 'SHOWCASE',
  },
  downloadReport: {
    key: 'Recent.downloadReport',
    defaultMessage: 'Download report',
  },
  exportCsv: {
    key: 'Recent.exportCsv',
    defaultMessage: 'Export (csv)',
  },
  sendToArchive: {
    key: 'Recent.sendToArchive',
    defaultMessage: 'Send to archive',
  },
  viewAll: {
    key: 'Recent.viewAll',
    defaultMessage: 'VIEW ALL',
  },
  assessment: {
    key: 'Recent.assessment',
    defaultMessage: 'ASSESSMENT',
  },
  description: {
    key: 'Recent.description',
    defaultMessage: 'DESCRIPTION',
  },
  impact: {
    key: 'Recent.impact',
    defaultMessage: 'IMPACT',
  },
  probability: {
    key: 'Recent.probability',
    defaultMessage: 'PROBABILITY',
  },
  risk: {
    key: 'Recent.risk',
    defaultMessage: 'RISK',
  },
  subject: {
    key: 'Recent.subject',
    defaultMessage: 'SUBJECT',
  },
  lastModified: {
    key: 'Recent.lastModified',
    defaultMessage: 'LAST MODIFIED',
  },
  title: {
    key: 'Recent.title',
    defaultMessage: 'TITLE',
  },
  issued: {
    key: 'Recent.issued',
    defaultMessage: 'ISSUED',
  },
  expiration: {
    key: 'Recent.expiration',
    defaultMessage: 'EXPIRATION',
  },
  pending: {
    key: 'Recent.pending',
    defaultMessage: 'PENDING',
  },
  unapproved: {
    key: 'Recent.unapproved',
    defaultMessage: 'UNAPPROVED',
  },
  verified: {
    key: 'Recent.verified',
    defaultMessage: 'VERIFIED',
  },
  noDescription: {
    key: 'Recent.noDescription',
    defaultMessage: 'No description',
  },
  notSpecified: {
    key: 'Recent.notSpecified',
    defaultMessage: 'Not specified',
  },
  openStatus: {
    key: 'Recent.openStatus',
    defaultMessage: 'OPEN',
  },
  closedStatus: {
    key: 'Recent.closedStatus',
    defaultMessage: 'CLOSED',
  },
  approvedStatus: {
    key: 'Recent.approvedStatus',
    defaultMessage: 'APPROVED',
  },
  edit: {
    key: 'Recent.edit',
    defaultMessage: 'Edit',
  },
  assignAssessment: {
    key: 'Recent.assignAssessment',
    defaultMessage: 'Assign assessment',
  },
  activate: {
    key: 'Recent.activate',
    defaultMessage: 'Activate',
  },
  deactivate: {
    key: 'Recent.deactivate',
    defaultMessage: 'Deactivate',
  },
  remove: {
    key: 'Recent.remove',
    defaultMessage: 'Remove',
  },
  onboardingNotCompleted: {
    key: 'Recent.onboardingNotCompleted',
    defaultMessage: 'Organization in onboarding process (No one member complete onboarding).',
  },
  noAssessments: {
    key: 'Recent.noAssessments',
    defaultMessage: 'None',
  },
  inherentRisk: {
    key: 'Recent.inherentRisk',
    defaultMessage: 'INHERENT RISK',
  },
  inherentRiskAnalysis: {
    key: 'Recent.inherentRiskAnalysis',
    defaultMessage: 'INHERENT RISK ANALYSIS',
  },
  assessmentsScore: {
    key: 'Recent.assessmentsScore',
    defaultMessage: 'ASSESSMENTS SCORE',
  },
  apps: {
    key: 'Recent.apps',
    defaultMessage: 'APPS',
  },
  averageScore: {
    key: 'Recent.averageScore',
    defaultMessage: 'AVERAGE SCORE',
  },
  basedOn: {
    key: 'Recent.basedOn',
    defaultMessage: 'based on',
  },
  selectBO: {
    key: 'Recent.selectBO',
    defaultMessage: 'Select BO',
  },
  inviteTeamMember: {
    key: 'Recent.inviteTeamMember',
    defaultMessage: 'INVITE TEAM MEMBER',
  },
  removeVendorText: {
    key: 'Recent.removeVendorText',
    defaultMessage: 'All vendor information will be lost',
  },
  emptyStateLabel: {
    key: 'Recent.emptyStateLabel',
    defaultMessage: 'No assessments score',
  },
  noAppsScore: {
    key: 'Recent.noAppsScore',
    defaultMessage: 'No apps score',
  },
  emptyStateValue: {
    key: 'Recent.emptyStateValue',
    defaultMessage: 'N/A',
  },
  removeVendorHeader: {
    key: 'Recent.removeVendorHeader',
    defaultMessage: 'Delete {{vendor}}?',
  },
};

export default {
  prefix: 'Recent',
  messages,
};
