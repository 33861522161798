import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'antd';
import PropTypes from 'prop-types';

// COMPONENTS
import Button from 'ui/Button';

// UTILS
import { translate } from 'utils/index';

// HOOKS
import { useTheme } from 'hooks/theme';
import { useAvailableFilters } from 'components/AddFilterButton/useAvailableFilters';
import { messages } from './messages';

// STYLES
import './index.scss';

const AddFilterButton = ({ filtersType, setFilter, className }) => {
  const { colorTheme } = useTheme();
  const availableFilters = useAvailableFilters(filtersType);

  const menuItems = useMemo(() => {
    return (
      availableFilters.map(({ name, type }) => {
        return {
          key: `add-filter-item-${type}`,
          label: translate(name),
          onClick: () => setFilter(type),
          'data-test': `add-filter-item-${type}`,
        };
      }) || []
    );
  }, [availableFilters]);

  return (
    availableFilters?.length && (
      <Dropdown
        className={classNames('add-filter-button', className)}
        menu={{ items: menuItems, className: 'add-filter-button__menu' }}
        trigger="click"
      >
        <Button
          className="add-filter-button__button"
          beforeIcon="filter"
          afterIcon="arrow-down2"
          name={translate(messages.addFilter)}
          color={colorTheme}
          size="md"
          data-test="add-filter-button-dropdown"
          outline
          lowercase
        />
      </Dropdown>
    )
  );
};

AddFilterButton.propTypes = {
  setFilter: PropTypes.func.isRequired,
  filtersType: PropTypes.oneOf(['assessment', 'messages', 'findings', 'myFiles']).isRequired,
  className: PropTypes.string,
};

export default AddFilterButton;
