import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// COMPONENTS
import Button from 'ui/Button';
import UpstreamAiPopover from 'components/UpstreamAI/upstreamAiPopover';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'components/UpstreamAI/messages';
import { buttonTypes, getTitleStructure } from 'components/UpstreamAI/lib';

// STYLES
import './upstreamAiButton.scss';

function UpstreamAiButton({
  controlAnswerType,
  controlId,
  assessmentId,
  buttonType,
  control,
  dataForMixpanel,
}) {
  const [open, setOpen] = useState(false);

  const buttonClassNames = classNames('upstream-ai-button', {
    [`upstream-ai-button--${buttonType}`]: buttonType,
    'upstream-ai-button--open': open,
  });

  const titleStructure = useMemo(() => {
    return getTitleStructure({ controlAnswerType, sourceButton: buttonType });
  }, [controlAnswerType, buttonType]);

  return (
    <UpstreamAiPopover
      open={open}
      setOpen={setOpen}
      titleStructure={titleStructure}
      buttonType={buttonType}
      controlId={controlId}
      assessmentId={assessmentId}
      dataForMixpanel={dataForMixpanel}
      control={control}
    >
      <Button
        className={buttonClassNames}
        outline
        beforeIcon="sparkle"
        size="xs"
        data-test={`upstream-ai-button--${buttonType}`}
      >
        {translate(messages[`${buttonType}Button`])}
      </Button>
    </UpstreamAiPopover>
  );
}

UpstreamAiButton.propTypes = {
  controlAnswerType: PropTypes.string.isRequired,
  controlId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  buttonType: PropTypes.oneOf(Object.values(buttonTypes)),
  dataForMixpanel: PropTypes.object,
  control: PropTypes.object,
};

export default UpstreamAiButton;
