import React, { useState } from 'react';

import { getCookie } from 'utils/apiHelpers';
import { ORG_ID_AUTH_KEY } from 'utils/auth';
import { translate } from 'utils/index';

import Table from 'ui/Table';
import Popup from 'ui/Popup';

import { useTrustedPartners } from 'hooks/useTrustedPartners';
import { StatusType } from 'constants/statusType';

import LoadMorePopupContent from 'pages/Organization/edit/loadMorePopupContent';
import { messages } from 'pages/Organization/messages';

import { columns } from './trustedOrganizationsUtils/columns';
import AddOrganizationButton from './trustedOrganizationsUtils/addOrganizationButton';
import './trustedOrganizations.scss';

const TrustedOrganizations = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [refetchOrganizations, setRefetchOrganizations] = useState(false);
  const { organizations, status: getOrganizationsStatus } = useTrustedPartners(
    getCookie(ORG_ID_AUTH_KEY),
    refetchOrganizations,
  );

  const onClosePopup = () => {
    setShowPopup(false);
  };

  const onOpenPopup = () => {
    setShowPopup(true);
  };

  const loading =
    getOrganizationsStatus === StatusType.PENDING || getOrganizationsStatus === StatusType.IDLE;

  return (
    <div className="trusted-organizations-tab__table-container">
      <div className="trusted-organizations-tab__actions">
        <div className="trusted-organizations-tab__actions-left">
          {translate(messages.orgEditTrustedOrganizationsInfo)}
          <span className="trusted-organizations-tab__learn-more" onClick={onOpenPopup}>
            {translate(messages.orgEditTrustedOrganizationsLearnMore)}
          </span>
          <Popup
            className="trusted-organizations-tab__popup"
            open={showPopup}
            title=""
            onCancel={onClosePopup}
            footer={<div />}
            width={776}
          >
            <LoadMorePopupContent />
          </Popup>
        </div>{' '}
        <div className="trusted-organizations-tab__actions-right">
          <AddOrganizationButton
            refetchOrganizations={() => setRefetchOrganizations((prev) => !prev)}
          />
        </div>
      </div>
      <Table
        className="trusted-organizations-tab__table"
        dataSource={organizations}
        columns={columns(() => setRefetchOrganizations((prev) => !prev))}
        pagination={false}
        loading={loading}
      />
    </div>
  );
};

export default TrustedOrganizations;
