import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'ui/Button/index';

import './fileTypeButton.scss';

const FileTypeButton = ({ title, image, type, onClick, selected, className }) => {
  const composedClassName = classNames('file-type-button', className, {
    'file-type-button--selected': type === selected,
  });

  return (
    <Button outline className={composedClassName} color="gray" onClick={() => onClick(type)}>
      <div className="file-type-button__content">
        <img src={image} alt={`file-type-button-${title}`} />
        {title}
      </div>
    </Button>
  );
};

FileTypeButton.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.string,
  className: PropTypes.string,
};

export default FileTypeButton;
