/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Switch, notification } from 'antd';
import classNames from 'classnames';
import { CheckOutlined } from '@ant-design/icons';

import { translate } from 'utils/index';
import Form from 'ui/Form';
import { Sorter } from 'ui/Table/sorter';
import Table from 'ui/Table';
import Input from 'ui/Input';
import Button from 'ui/Button';
import Icon from 'ui/Icon';
import {
  addOrganizationParams,
  changeOrganizationState,
  getOrganizationApps,
  removeSBOMFile,
  uploadSBOMFile,
} from 'api/organizations';
import { Apps as AppsConstants } from 'constants/apps';
import SSCIcon from 'assets/svgs/ssc-icon.svg';
import CloudhawkIcon from 'assets/svgs/cloudhawk-icon.svg';
import IBMIcon from 'assets/svgs/ibm-x-force-icon.svg';
import QualysIcon from 'assets/svgs/qualys-icon.svg';
import SbomIcon from 'assets/svgs/sbom-icon.svg';

import { API_STATUS } from 'constants/api';
import { selectApps, selectAppsLoading, selectSbomFileLoading } from 'pages/Organization/selectors';
import { messages } from 'pages/Organization/messages';
import DefaultApps from './defaultApps';
import UploadFiles from 'components/UploadFiles';
import './apps.scss';

const icons = {
  [AppsConstants.ssc]: SSCIcon,
  [AppsConstants.ibm]: IBMIcon,
  [AppsConstants.qualys]: QualysIcon,
  [AppsConstants.cloudhawk]: CloudhawkIcon,
  [AppsConstants.sbom]: SbomIcon,
};

const Apps = () => {
  const dispatch = useDispatch();
  const apps = useSelector(selectApps);
  const loading = useSelector(selectAppsLoading);
  const sbomFileLoading = useSelector(selectSbomFileLoading);
  const { organizationId } = useParams();
  const [checked, setChecked] = useState({});
  const [loader, setLoader] = useState(false);
  const [params, setParams] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    dispatch(getOrganizationApps({ organizationId }));
  }, [organizationId]);

  useEffect(() => {
    if (!apps || !apps.length) {
      return null;
    }
    const ibmApp = apps.find(({ id }) => id === AppsConstants.ibm);
    const qualysApp = apps.find(({ id }) => id === AppsConstants.qualys);
    const sscApp = apps.find(({ id }) => id === AppsConstants.ssc);
    const sbomApp = apps.find(({ id }) => id === AppsConstants.sbom);

    if (!ibmApp || !qualysApp || !sscApp) {
      return null;
    }

    setParams((state) => ({
      ...state,
      sscApiKey: sscApp?.token?.['api-key'],
      ibmUsername: ibmApp?.token?.username,
      ibmPassword: ibmApp?.token?.password,
      sscState: sscApp?.state,
      qualysState: qualysApp?.state,
      ibmState: ibmApp?.state,
      sbomState: sbomApp?.state,
      sbomFile: sbomApp?.['sbom_file'],
    }));

    apps.forEach((app) => {
      setChecked((state) => ({ ...state, [app.id]: app.state }));
    });
  }, [apps]);

  const onStateChange = async (event, value, appId) => {
    event.stopPropagation();
    setChecked((state) => ({ ...state, [appId]: value }));
    setLoader(true);

    const params = {
      'app-id': appId,
      'organization_id': organizationId,
      state: value ? 1 : 0,
    };

    const { payload } = await dispatch(changeOrganizationState(params));
    setChecked((state) => ({ ...state, [appId]: payload?.new_state }));
    setExpandedRowKeys((keys) => [...keys, appId]);
    setLoader(false);
  };

  const columns = [
    {
      title: translate(messages.orgEditTabColTitleName),
      key: 'name',
      dataIndex: 'name',
      render: (name, app) => {
        return (
          <div>
            {icons[app.id] && (
              <img className="apps-tab__service-icon" src={icons[app.id]} alt={name} />
            )}
            {name}
          </div>
        );
      },
      sorter: {
        compare: Sorter.STRING,
      },
      width: 300,
    },
    {
      title: translate(messages.orgEditAppsStatus),
      key: 'state',
      dataIndex: 'state',
      width: 100,
      render: (state, app, rowIndex) => {
        return (
          <Switch
            className={classNames({
              'apps-tab__status-enabled': checked[app.id] === 2,
            })}
            data-test={`apps-table-${rowIndex}-col-1-switch-btn`}
            size="small"
            checked={checked[app.id]}
            onChange={(value, event) => onStateChange(event, value, app.id)}
            checkedChildren={checked[app.id] === 2 && <CheckOutlined />}
          />
        );
      },
    },
    {
      title: translate(messages.orgEditAppsConfiguration),
      key: 'configuration',
      dataIndex: 'configuration',
      render: (_, app) => {
        return (
          <div className="apps-tab__table-configuration">
            {[AppsConstants.ssc, AppsConstants.ibm, AppsConstants.sbom].includes(app.id)
              ? translate(messages.orgEditAppsConfigurationRequired)
              : translate(messages.orgEditAppsNoExtraConfiguration)}
          </div>
        );
      },
    },
  ];

  const addSscParam = async () => {
    const payload = {
      'app-id': AppsConstants.ssc,
      'ssc-api-key': params.sscApiKey,
    };
    setLoader(true);
    const response = await dispatch(addOrganizationParams(payload)).unwrap();

    if (response.status === API_STATUS.SUCCESS) {
      await dispatch(getOrganizationApps({ organizationId }));
      setExpandedRowKeys((keys) => keys.filter((key) => key !== AppsConstants.ssc));
    }
    setLoader(false);
  };

  const renderSscConfiguration = (row) => {
    return (
      <Form autoComplete="off" className="apps-tab__form">
        <Input
          autoComplete="off"
          data-test="ssc-api-key-input"
          className="apps-tab__ssc-api-key-input"
          placeholder={translate(messages.orgEditAppsKey)}
          onChange={({ target: { value } }) =>
            setParams((state) => ({ ...state, sscApiKey: value }))
          }
          size="medium"
          value={params.sscApiKey}
          onFocus={() => {
            if (params.sscApiKey?.includes('*')) {
              setParams((state) => ({ ...state, sscApiKey: '' }));
            }
          }}
        />
        <Button
          data-test="api-key-button-send"
          className="apps-tab__send-button"
          onClick={addSscParam}
          size="sm"
          squared
          color="blue"
          disabled={loader || !params.sscApiKey || params.sscApiKey?.includes('*')}
        >
          Run
        </Button>
      </Form>
    );
  };

  const addIbmParams = async () => {
    const payload = {
      'app-id': AppsConstants.ibm,
      'ibm-username': params.ibmUsername,
      'ibm-password': params.ibmPassword,
    };
    setLoader(true);
    const response = await dispatch(addOrganizationParams(payload)).unwrap();

    if (response.status === API_STATUS.SUCCESS) {
      await dispatch(getOrganizationApps({ organizationId }));
      setExpandedRowKeys((keys) => keys.filter((key) => key !== AppsConstants.ibm));
    }

    setLoader(false);
  };

  const handleUploadSbomFile = async (file) => {
    const res = await dispatch(uploadSBOMFile({ file, org_id: organizationId }));

    if (res?.payload?.status && res.payload.status === API_STATUS.SUCCESS && res?.payload?.file) {
      notification.success({ message: translate(messages.orgEditAppsUploadSbomSuccess) });
      setParams((state) => ({ ...state, sbomFile: res.payload.file }));
    } else {
      notification.error({ message: translate(messages.orgEditAppsUploadSbomFail) });
    }
  };

  const handleRemoveSbomFile = async (file) => {
    const res = await dispatch(removeSBOMFile({ org_id: organizationId }));

    if (res?.payload?.status && res.payload.status === API_STATUS.SUCCESS) {
      notification.success({ message: translate(messages.orgEditAppsRemoveSbomSuccess) });
      setParams((state) => ({ ...state, sbomFile: undefined }));
    } else {
      notification.error({ message: translate(messages.orgEditAppsRemoveSbomFail) });
    }
  };

  const renderSbomConfiguration = (row) => {
    return (
      <div className="apps-tab__form">
        <UploadFiles
          accept={'cyclonedx, spdx'}
          title={translate(messages.orgEditAppsUploadSbomTitle)}
          multiple={false}
          className="upload-app-file"
          uploadedFiles={params?.sbomFile ? [params?.sbomFile] : []}
          handleUpload={handleUploadSbomFile}
          handleRemove={handleRemoveSbomFile}
          loading={sbomFileLoading}
          edit={false}
          browse={true}
        />
      </div>
    );
  };

  const renderIbmConfiguration = (row) => {
    return (
      <Form className="apps-tab__form" autocomplete="off">
        <Input
          autoComplete="off"
          data-test="ibm-username-input"
          className="apps-tab__ibm-username-input"
          placeholder={translate(messages.orgEditAppsUsername)}
          name="ibm-x-force-username"
          onChange={({ target: { value } }) =>
            setParams((state) => ({ ...state, ibmUsername: value }))
          }
          size="medium"
          value={params.ibmUsername}
          autocomplete="off"
          onFocus={() => {
            if (params.ibmUsername?.includes('*')) {
              setParams((state) => ({ ...state, ibmUsername: '' }));
            }
          }}
        />
        <Input
          autoComplete="off"
          data-test="ibm-password-input"
          className="apps-tab__ibm-password-input"
          name="ibm-x-force-password"
          placeholder={translate(messages.orgEditAppsPassword)}
          onChange={({ target: { value } }) =>
            setParams((state) => ({ ...state, ibmPassword: value }))
          }
          size="medium"
          value={params.ibmPassword}
          type="password"
          autocomplete="new-password"
          onFocus={() => {
            if (params.ibmPassword?.includes('*')) {
              setParams((state) => ({ ...state, ibmPassword: '' }));
            }
          }}
        />
        <Button
          data-test="ibm-button-send"
          className="apps-tab__send-button"
          onClick={addIbmParams}
          size="sm"
          squared
          color="blue"
          disabled={
            loader ||
            !params.ibmUsername ||
            params.ibmUsername.includes('*') ||
            !params.ibmPassword ||
            params.ibmPassword.includes('*')
          }
        >
          Run
        </Button>
      </Form>
    );
  };

  const dataSource = (apps || []).map((app) => ({
    ...app,
    key: app.id,
  }));

  return (
    <div>
      <DefaultApps />
      <div>
        <Form.Label
          className="apps-tab__table-header"
          text={translate(messages.orgEditAppsAdditionalApps)}
        />
        <div className="apps-tab__table-container">
          <Table
            data-test="organization-settings-apps-table"
            className="apps-tab__table"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            loading={loading || loader}
            expandable={{
              expandedRowRender: (row) => {
                if (row.id === AppsConstants.ssc && checked[AppsConstants.ssc]) {
                  return renderSscConfiguration(row);
                }

                if (row.id === AppsConstants.ibm && checked[AppsConstants.ibm]) {
                  return renderIbmConfiguration(row);
                }

                // uncomment when requested to show SBOM whitelist
                // if (row.id === AppsConstants.sbom && checked[AppsConstants.sbom]) {
                //   return renderSbomConfiguration(row);
                // }

                return null;
              },
              expandedRowKeys,
              onExpandedRowsChange: (expandedRows) => {
                setExpandedRowKeys(expandedRows);
              },
              rowExpandable: (row) => {
                if (row.id === AppsConstants.ssc && checked[AppsConstants.ssc]) {
                  return true;
                }
                if (row.id === AppsConstants.ibm && checked[AppsConstants.ibm]) {
                  return true;
                }
                // uncomment when requested to show SBOM whitelist
                // if (row.id === AppsConstants.sbom && checked[AppsConstants.sbom]) {
                //   return true;
                // }
                return false;
              },
              defaultExpandedRowKeys: [AppsConstants.ssc, AppsConstants.ibm],
              expandRowByClick: true,
              expandIcon: ({ expanded, onExpand, record }) => {
                if (record.id === AppsConstants.qualys || record.id === AppsConstants.sbom) {
                  return null;
                }

                return expanded ? (
                  <Icon icon="arrow-up" onClick={(e) => onExpand(record, e)} />
                ) : (
                  <Icon icon="arrow-down-2" onClick={(e) => onExpand(record, e)} />
                );
              },
            }}
            rowKey="apps-table"
          />
        </div>
      </div>
    </div>
  );
};

export default Apps;
