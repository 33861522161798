import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CardWithLogo from 'components/CardWithLogo';
import { sanitizer } from 'utils/sanitizer';
import StageDescription from 'pages/SoBoPages/stageDescription';
import { NEW_CUSTOMER_SO_PAGE } from 'pages/SoBoPages/NewCustomer/lib';
import { translate } from 'utils/index';
import { messages } from 'pages/SoBoPages/NewCustomer/messages';
import { selectSoBoPages } from 'pages/SoBoPages/selectors';

import manWithCard from 'assets/svgs/man-with-card.svg';
import './stageOne.scss';

const StageOneDescription = () => {
  return (
    <>
      <StageDescription
        title={translate(messages.stageOneLeftDescriptionTitle)}
        text={translate(messages.stageOneLeftDescriptionText)}
      />
      <img src={manWithCard} alt="man-with-card" className="so-page-stage-one__illustration" />
    </>
  );
};

const StageOne = ({ setStage }) => {
  const {
    additionalData: { salesGeneralDescriptionTitle, salesGeneralDescription },
  } = useSelector(selectSoBoPages);

  const onClick = () => {
    setStage(NEW_CUSTOMER_SO_PAGE.STAGE_2);
  };

  return (
    <div className="so-page-stage-one">
      <div className="so-page-stage-one__content">
        {salesGeneralDescriptionTitle && <h2>{salesGeneralDescriptionTitle}</h2>}
        {!salesGeneralDescriptionTitle && (
          <div className="so-page-stage-one__title">
            <h1>{translate(messages.stageOneDefaultTitle)}</h1>
            <h3>{translate(messages.stageOneDefaultSubTitle)}</h3>
          </div>
        )}
        {salesGeneralDescription && (
          <div dangerouslySetInnerHTML={{ __html: sanitizer(salesGeneralDescription) }} />
        )}
        {!salesGeneralDescription && (
          <div className="so-page-stage-one__description">
            <p>{translate(messages.stageOneDefaultDescriptionPart1)}</p>
            <p>
              {translate(messages.stageOneDefaultDescriptionPart2)}{' '}
              <a href="mailto:support@findings.co">support@findings.co</a>
            </p>
          </div>
        )}
      </div>
      <CardWithLogo.Footer rightButtonOnClick={onClick} />
    </div>
  );
};

StageOne.Description = StageOneDescription;

StageOne.propTypes = {
  setStage: PropTypes.func,
};

export default StageOne;
