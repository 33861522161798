export const messages = {
  pageTitle: {
    id: 'Evidences.pageTitle',
    defaultMessage: 'Evidences',
  },
  lastModified: {
    key: 'Evidences.lastModified',
    defaultMessage: 'LAST MODIFIED',
  },
  vendor: {
    key: 'Evidences.vendor',
    defaultMessage: 'VENDOR',
  },
  customer: {
    key: 'Evidences.customer',
    defaultMessage: 'CUSTOMER',
  },
  assessment: {
    key: 'Evidences.assessment',
    defaultMessage: 'ASSESSMENT',
  },
  fileTitle: {
    key: 'Evidences.fileTitle',
    defaultMessage: 'FILE',
  },
  status: {
    key: 'Evidences.status',
    defaultMessage: 'STATUS',
  },
  issued: {
    key: 'Evidences.issued',
    defaultMessage: 'ISSUED',
  },
  expiration: {
    key: 'Evidences.expiration',
    defaultMessage: 'EXPIRATION',
  },
  organization: {
    key: 'Evidences.organization',
    defaultMessage: 'ORGANIZATION',
  },
  pending: {
    key: 'Evidences.pending',
    defaultMessage: 'pending',
  },
  rejected: {
    key: 'Evidences.rejected',
    defaultMessage: 'rejected',
  },
  approved: {
    key: 'Evidences.approved',
    defaultMessage: 'approved',
  },
  issuedOn: {
    key: 'Evidences.issuedOn',
    defaultMessage: 'Issued on',
  },
  expiredDaysAgo: {
    key: 'Evidences.expiredDaysAgo',
    defaultMessage: 'Expired {{days}} days ago',
  },
  expiringInDays: {
    key: 'Evidences.expiringInDays',
    defaultMessage: 'Expiring in {{days}} days',
  },
  editEvidenceMetadata: {
    key: 'Evidences.editEvidenceMetadata',
    defaultMessage: 'EDIT FILE INFO',
  },
  exportCsv: {
    key: 'Evidences.exportCsv',
    defaultMessage: 'Export Table to CSV',
  },
};

export default {
  prefix: 'Evidences',
  messages,
};
