export const messages = {
  probability: {
    key: 'ProbabilitySelect.probability',
    defaultMessage: 'PROBABILITY',
  },
  choose: {
    key: 'ProbabilitySelect.choose',
    defaultMessage: 'Choose',
  },
};

export default {
  prefix: 'ProbabilitySelect',
  messages,
};
