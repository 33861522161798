import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/Modal';
import Button from 'ui/Button';
import Icon from 'ui/Icon';
import { useTheme } from 'hooks/theme';
import { translate } from 'utils/index';
import { generalMessages } from 'constants/messages';

import { messages } from '../messages';
import './downgradeRoleModal.scss';

const roles = {
  0: 'Admin',
  1: 'BO',
  2: 'SO',
  3: 'Owner',
  4: 'Reporter',
  5: 'Viewer',
};

const DowngradeRoleModal = ({ open, setOpen, userName, onOk, newRole }) => {
  const [loading, setLoading] = useState(false);
  const { colorTheme } = useTheme();

  const transferOwnership = async () => {
    setLoading(true);
    await onOk();
    setOpen(false);
    setLoading(false);
  };

  const footer = [
    <Button
      key="cancel-button"
      className="user-management-tab-downgrade-role-modal__footer-cancel-button"
      size="sm"
      color="white"
      onClick={() => setOpen(false)}
      fluid
      data-test="user-management-tab-downgrade-role-cancel-button"
    >
      {translate(generalMessages.cancel)}
    </Button>,
    <Button
      key="ok-button"
      className="user-management-tab-downgrade-role-modal__footer-ok-button"
      size="sm"
      color={colorTheme}
      onClick={transferOwnership}
      fluid
      type="submit"
      loading={loading}
      data-test="user-management-tab-downgrade-role-ok-button"
    >
      {translate(messages.orgEditDowngradeButton)}
    </Button>,
  ];

  const title = (
    <span>
      <Icon icon="info" /> {translate(messages.orgEditDowngradeRole, { userName })}
    </span>
  );

  return (
    <Modal
      className="user-management-tab-downgrade-role-modal"
      onCancel={() => setOpen(false)}
      width={645}
      open={open}
      title={title}
      footer={footer}
    >
      {translate(messages.orgEditDowngradeRoleDescription, { newRole: roles[newRole] })}
    </Modal>
  );
};

DowngradeRoleModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  userName: PropTypes.string,
  onOk: PropTypes.func,
  newRole: PropTypes.number,
};

export default DowngradeRoleModal;
