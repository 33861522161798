import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';

import RemoveConfirmationModal from 'components/RemoveConfirmationModal';
import { translate } from 'utils/index';
import { saveTemplateFinding } from 'api/editor';
import { messages } from './messages';
import { TrashIcon } from 'components/Icons';

import './removeFinding.scss';

const RemoveFinding = ({ control, id, onSuccess }) => {
  const dispatch = useDispatch();
  const [popupOpened, setPopupOpened] = useState(false);
  const [loading, setLoading] = useState();

  const onRemove = async () => {
    setLoading(true);
    const payload = {
      control,
      finding: {
        id,
      },
    };

    await dispatch(saveTemplateFinding(payload)).unwrap();
    setLoading(false);
    setPopupOpened(false);
    onSuccess();
  };

  return (
    <div>
      <Tooltip title={translate(messages.removeFinding)}>
        <Button
          className="assessment-remove-finding"
          type="link"
          onClick={() => setPopupOpened(true)}
        >
          <TrashIcon />
        </Button>
      </Tooltip>
      <RemoveConfirmationModal
        confirmLoading={loading}
        onOk={onRemove}
        onCancel={() => setPopupOpened(false)}
        text={translate(messages.removeFindingPopup)}
        open={!!popupOpened}
      />
    </div>
  );
};

RemoveFinding.propTypes = {
  control: PropTypes.object,
  id: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default RemoveFinding;
