import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Popover } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import Feedback from 'components/UpstreamAI/feedback';
import PopoverTitle from 'components/UpstreamAI/popoverTitle';
import Loader from 'ui/Loader';
import CopyToClipboard from 'components/UpstreamAI/copyToClipboard';
import ErrorMessage from 'components/UpstreamAI/errorMessage';
import ThirdPartyConsent from 'components/UpstreamAI/thirdPartyConsent';

// HOOKS
import useUpstreamAi from 'components/UpstreamAI/useUpstreamAi';

// REDUX
import { selectEnableImprove, selectIsLoadingResponse } from 'components/UpstreamAI/selectors';
import { selectProfile } from 'pages/Profile/selectors';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'components/UpstreamAI/messages';
import { buttonTypes } from 'components/UpstreamAI/lib';
import useMixpanelUpstreamAiTrack from 'components/UpstreamAI/useMixpanelUpstreamAiTrack';

// STYLES
import './index.scss';
import AuditControlContent from 'components/UpstreamAI/auditControlContent';

function UpstreamAiPopover({
  titleStructure: titleStructureProp,
  buttonType,
  children,
  controlId,
  assessmentId,
  setOpen,
  open,
  dataForMixpanel,
  control,
}) {
  // local states
  const [selectedButton, setSelectedButton] = useState(buttonType);
  const [titleStructure, setTitleStructure] = useState(titleStructureProp);

  // ref
  const popupContentTextRef = useRef(null);

  // redux states
  const {
    user: {
      current_organization: { third_party_consent: thirdPartyConsent },
    },
  } = useSelector(selectProfile);
  const enableImprove = useSelector(selectEnableImprove);
  const loading = useSelector(selectIsLoadingResponse);

  // hooks
  const upstreamAiData = useUpstreamAi({
    controlId,
    assessmentId,
  });
  const mixpanelUpstreamAi = useMixpanelUpstreamAiTrack({
    selectedButton,
    buttonType,
    ...dataForMixpanel,
  });

  useEffect(() => {
    setTitleStructure(titleStructureProp);
  }, [titleStructureProp]);

  const handleGetFunction = useCallback(async () => {
    if (selectedButton === buttonTypes.improve && thirdPartyConsent === false) {
      return;
    }
    const beforeApi = Date.now();
    const {
      payload: { response },
    } = await upstreamAiData[selectedButton].getFunction();
    const afterApi = Date.now();
    mixpanelUpstreamAi.setResponse(response);
    mixpanelUpstreamAi.setTimeToResponse(afterApi - beforeApi);
  }, [selectedButton, thirdPartyConsent]);

  const handleOpenChange = () => {
    setOpen((state) => {
      const newState = !state;
      if (newState === true) {
        handleGetFunction().then();
      }
      if (newState === false) {
        setSelectedButton(buttonType);
        setTitleStructure(titleStructureProp);
        // send mixpanel event on close
        mixpanelUpstreamAi.trackFunction();
      }
      popupContentTextRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });

      return newState;
    });
  };

  useEffect(() => {
    if (open && !upstreamAiData[selectedButton]?.contentText) {
      handleGetFunction().then();
    }
    popupContentTextRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    // relevant for improve button case - after first consent
    mixpanelUpstreamAi.setResponse(upstreamAiData[selectedButton]?.contentText);
  }, [selectedButton, upstreamAiData[selectedButton]?.contentText, enableImprove]);

  const preContent = useMemo(() => {
    return (
      <>
        {loading && <Loader />}
        {!loading && !upstreamAiData[selectedButton]?.contentText && (
          <ErrorMessage setMixpanelError={mixpanelUpstreamAi.setError} />
        )}
      </>
    );
  }, [selectedButton, loading, upstreamAiData[selectedButton]?.contentText]);

  const content = useMemo(() => {
    return (
      <div className={'upstream-ai__popover-content'} data-test="upstream-ai-popover-content-text">
        {buttonType === buttonTypes.audit ? (
          <>
            {preContent}
            {upstreamAiData[selectedButton]?.contentText && (
              <AuditControlContent
                overview={upstreamAiData[selectedButton]?.contentText}
                findings={upstreamAiData[selectedButton]?.findings}
                control={control}
                setUpstreamAiPopover={handleOpenChange}
              />
            )}
          </>
        ) : (
          <div className={'upstream-ai__popover-content-text'} ref={popupContentTextRef}>
            {preContent}
            {selectedButton !== buttonTypes.explain &&
              !loading &&
              upstreamAiData[selectedButton]?.contentText && (
                <div style={{ marginBottom: '10px', fontSize: '11px' }}>
                  <span style={{ color: '#E45647' }}>{translate(messages.popoverNote1)}</span>
                  {': '}
                  <span>{translate(messages.popoverNote2)}</span>
                </div>
              )}
            {upstreamAiData[selectedButton]?.contentText}
          </div>
        )}

        {upstreamAiData[selectedButton]?.contentText && (
          <div className={'upstream-ai__popover-content-actions'}>
            <Feedback
              selectedButton={selectedButton}
              setMixpanelFeedback={mixpanelUpstreamAi.setWasHelpful}
            />
            {selectedButton !== buttonTypes.explain && selectedButton !== buttonTypes.audit && (
              <CopyToClipboard
                text={upstreamAiData[selectedButton]?.contentText}
                setMixpanelCopy={mixpanelUpstreamAi.setCopy}
              />
            )}
          </div>
        )}
      </div>
    );
  }, [selectedButton, loading, upstreamAiData[selectedButton]?.contentText]);

  const displayedContent = useMemo(() => {
    const buttonsRequiringConsent = [buttonTypes.improve, buttonTypes.audit];
    if (!buttonsRequiringConsent.includes(selectedButton) || enableImprove || thirdPartyConsent) {
      return content;
    }
    return (
      <ThirdPartyConsent
        source={buttonType}
        setTitleStructure={setTitleStructure}
        setSelectedButton={setSelectedButton}
        getFunctionData={{ controlId, assessmentId }}
        setMixpanelConsent={mixpanelUpstreamAi.trackThirdPartyConsent}
        setMixpanelResponseTime={mixpanelUpstreamAi.setTimeToResponse}
        setOpen={setOpen}
      />
    );
  }, [selectedButton, loading, enableImprove]);

  return (
    <Popover
      open={open}
      placement={'bottomLeft'}
      title={
        <PopoverTitle
          titleStructure={titleStructure}
          selectedButton={selectedButton}
          setSelectedButton={setSelectedButton}
          mixpanelTrack={mixpanelUpstreamAi.trackFunction}
        />
      }
      content={displayedContent}
      overlayClassName={'upstream-ai__popover'}
      trigger="click"
      showArrow={false}
      onOpenChange={handleOpenChange}
    >
      {children}
    </Popover>
  );
}

UpstreamAiPopover.propTypes = {
  titleStructure: PropTypes.object.isRequired,
  buttonType: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  controlId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dataForMixpanel: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
};

export default UpstreamAiPopover;
