import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FileBrowser from 'components/FileBrowser';
import { translate } from 'utils/index';
import Modal from 'ui/Modal';
import Loader from 'ui/Loader';
import Trash from 'assets/svgs/trash.svg';
import { messages } from './messages';
import './index.scss';

const FileList = ({ className, edit, files, handleRemove, loading, isTemplate }) => {
  const [fileToRemove, setFileToRemove] = useState(null);

  const onRemoveFile = () => {
    handleRemove(fileToRemove.id);
    setFileToRemove(null);
  };

  return (
    <div className={classNames('ui-file-list', className)}>
      <div className="ui-file-list__list">
        <FileBrowser
          isTemplate={isTemplate}
          files={files}
          edit={edit}
          browse
          setFileToRemove={setFileToRemove}
        />
      </div>
      {loading && <Loader className="upload-files__loader" />}
      <Modal
        className="upload-files__confirm-modal"
        open={!!fileToRemove}
        onOk={onRemoveFile}
        onCancel={() => setFileToRemove(null)}
        width={460}
      >
        <img className="upload-files__confirm-modal-trash" src={Trash} alt="Trash" />
        <br />
        {translate(messages.removeFileText)}
      </Modal>
    </div>
  );
};

FileList.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.func,
  files: PropTypes.array,
  handleRemove: PropTypes.func,
  loading: PropTypes.bool,
  isTemplate: PropTypes.bool,
};

export default FileList;
