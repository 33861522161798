import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

// COMPONENTS
import Input from 'ui/Input';

// REDUX
import { selectFindingsPage } from 'pages/Findings/selectors';
import { setParam } from 'pages/Findings/reducers';
import { getProfileFindings } from 'api/finding';

// HOOKS
import { useIsMyVendorsTabSelected } from 'hooks/navigation';

const Search = () => {
  const dispatch = useDispatch();
  const { search, pagination } = useSelector(selectFindingsPage);
  const isVendorSelected = useIsMyVendorsTabSelected();

  const searchDebounced = useCallback(
    debounce(async () => {
      dispatch(setParam({ key: 'pagination', value: { ...pagination, current: 1 } }));
      dispatch(getProfileFindings({ upstream: !isVendorSelected }));
    }, 500),
    [],
  );

  const handleSearch = (event) => {
    dispatch(setParam({ key: 'search', value: event.target.value }));
    searchDebounced();
  };

  return (
    <Input.Search
      onChange={handleSearch}
      size="medium"
      value={search}
      className="findings-page__input-search"
    />
  );
};

export default Search;
