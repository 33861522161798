import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.scss';

const Loader = ({ className, withBackdrop }) => {
  return withBackdrop ? (
    <div className={classNames('loader', 'loader--with-backdrop', className)}>
      <div className="preloader">
        <div className="inner-loader" />
      </div>
    </div>
  ) : (
    <div className={classNames('loader', className)}>
      <div className="inner-loader" />
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  withBackdrop: PropTypes.bool,
};

Loader.defaultProps = {
  withBackdrop: true,
};

export default Loader;
