import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';

import { translate } from 'utils/index';
import SecondaryButton from 'ui/SecondaryButton';
import Checkbox from 'ui/Checkbox';
import Button from 'ui/Button';
import Icon from 'ui/Icon';
import Input from 'ui/Input';
import { stripHtml } from 'utils/html';
import { calculateControlKey } from 'pages/Assessment/lib';

import { selectTemplate } from 'pages/Editor/selectors';
import { messages } from 'pages/Editor/messages';
import Answer from './answer';
import './triggersModal.scss';

const TriggersModal = ({
  controlId,
  isEdit,
  onSelectTriggers,
  selectedAnswer,
  selectedTriggers,
  toggleModal,
  open,
}) => {
  const { categories = [] } = useSelector(selectTemplate);
  const [search, setSearch] = useState('');
  const filteredCategories = useMemo(
    () =>
      categories
        .filter(
          (category) => category.controls.filter((control) => control.id !== controlId).length > 0,
        )
        .map((category) => ({
          ...category,
          controls: category.controls
            .filter((control) => control.id !== controlId)
            .filter((control) => control.title.toLowerCase().includes(search.toLowerCase())),
        })),
    [categories, controlId, search],
  );

  const [selectedSubject, setSelectedSubject] = useState(filteredCategories[0]?.id || '');
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const subjectIndex = categories.findIndex(({ id }) => selectedSubject === id);

  const filteredControls = filteredCategories.find(
    (category) => category.id === selectedSubject,
  )?.controls;

  useEffect(() => {
    if (!open) {
      setSelectedQuestions([]);
      setSelectedSubject(filteredCategories[0]?.id || '');
      setSearch('');
    }
  }, [open]);

  useEffect(() => {
    if (open && isEdit) {
      setSelectedQuestions(selectedTriggers);
    }
  }, [open]);

  const onCancel = () => {
    toggleModal(false);
  };

  const onOk = () => {
    onSelectTriggers(selectedAnswer, selectedQuestions);
    toggleModal(false);
  };

  const findControlIndex = (id) => {
    return categories
      .find((category) => category.id === selectedSubject)
      .controls.findIndex((control) => control.id === id);
  };

  const onSearch = ({ target: { value } }) => {
    setSearch(value);
  };

  const setSelectedQuestion = (id) => {
    if (selectedQuestions.some((selectedQuestionId) => id === selectedQuestionId)) {
      setSelectedQuestions(
        selectedQuestions.filter((selectedQuestionId) => id !== selectedQuestionId),
      );
    } else {
      setSelectedQuestions([...selectedQuestions, id]);
    }
  };

  const footer = [
    <Button
      key="footer_button_triggers_modal_1"
      className="editor-triggers-modal__footer-cancel-button"
      size="sm"
      color="gray"
      onClick={onCancel}
      link
      data-test="editor-triggers-modal-button-cancel"
    >
      {translate(messages.cancel)}
    </Button>,
    <SecondaryButton
      key="footer_button_triggers_modal_2"
      className="editor-triggers-modal__footer-ok-button"
      onClick={onOk}
      size="medium"
      type="submit"
      outline
    >
      {translate(messages.select)}
    </SecondaryButton>,
  ];

  return (
    <Modal
      className="editor-triggers-modal__modal"
      closeIcon={<Icon icon="close" />}
      open={open}
      onCancel={onCancel}
      width={900}
      footer={footer}
    >
      <div className="editor-triggers-modal__header">
        <h3 className="editor-triggers-modal__header-title">{translate(messages.findQuestions)}</h3>
        <div className="editor-triggers-modal__header-description">
          {translate(messages.searchQuestionOrPick)}
        </div>
        <Input.Search
          className="editor-triggers-modal__search"
          onChange={onSearch}
          value={search}
        />
        <div className="editor-triggers-modal__categories">
          {filteredCategories.map((category, index) => (
            <Button
              key={`footer_${index}`}
              data-test="editor-triggers-modal-button-category-title"
              afterComponent={
                <span className="editor-triggers-modal__controls-counter">
                  {category.controls.length}
                </span>
              }
              className="editor-triggers-modal__category-button"
              color={selectedSubject === category.id ? 'blue' : 'gray'}
              disabled={category.controls.length === 0}
              fluid
              lowercase
              outline
              onClick={() => setSelectedSubject(category.id)}
              size="md"
            >
              {category.title}
            </Button>
          ))}
        </div>
      </div>
      <div className="editor-triggers-modal__list-wrapper">
        <div className="editor-triggers-modal__list-header">{translate(messages.results)}:</div>
        <div className="editor-triggers-modal__list">
          {(filteredControls || []).map((control) => (
            <div key={control.id} className="editor-triggers-modal__question">
              <Checkbox
                className="editor-triggers-modal__question-checkbox"
                onChange={() => setSelectedQuestion(control.id)}
                checked={
                  isEdit
                    ? selectedQuestions.includes(control.id)
                    : selectedQuestions.includes(control.id) ||
                      selectedTriggers.includes(control.id)
                }
                disabled={!isEdit && selectedTriggers.includes(control.id)}
                name={`question-${control.id}`}
                mode="checkbox"
              />
              <div className="editor-triggers-modal__question-wrapper">
                <div className="editor-triggers-modal__question-title">
                  <span className="editor-triggers-modal__question-key">
                    {calculateControlKey(subjectIndex + 1, findControlIndex(control.id))}
                  </span>
                  {stripHtml(control.title)}
                </div>
                <Answer control={control} disabled />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

TriggersModal.propTypes = {
  controlId: PropTypes.string,
  isEdit: PropTypes.bool,
  onSelectTriggers: PropTypes.func,
  selectedAnswer: PropTypes.number,
  selectedTriggers: PropTypes.array,
  toggleModal: PropTypes.func,
  open: PropTypes.bool,
};

export default TriggersModal;
