import axios from 'axios';
import { SaveNoteOnRelation } from 'api/endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';

const saveNoteOnRelationRequest = async (args) => {
  const payload = {
    note: JSON.stringify(args.note),
    relation_id: args.relation_id,
  };
  return axios.post(SaveNoteOnRelation.url, payload);
};

export const saveNoteOnRelation = createAsyncThunk('saveNoteOnRelation', saveNoteOnRelationRequest);
