import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from 'ui/Button';
import './answerTypeButton.scss';

const AnswerTypeButton = ({ count, icon, onClick, selected, text }) => {
  const afterComponent = useMemo(() => {
    if (count !== undefined)
      return <span className="editor-answer-bank-answer-type-button__counter">{count}</span>;
    return undefined;
  }, [count]);

  return (
    <Button
      afterComponent={afterComponent}
      beforeIcon={icon}
      className="editor-answer-bank-answer-type-button"
      color={selected ? 'blue' : 'gray'}
      disabled={count === 0}
      onClick={onClick}
      size="md"
      data-test="answer-bank-category-button"
      fluid
      lowercase
      outline
    >
      {text}
    </Button>
  );
};

AnswerTypeButton.propTypes = {
  count: PropTypes.number,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  text: PropTypes.string,
};

export default AnswerTypeButton;
