import React from 'react';
import PropTypes from 'prop-types';

function ColoredResult({ results, fromModal }) {
  return (
    <div
      className={`
      continuous-monitoring-tab__app-property__status 
      continuous-monitoring-tab__app-property__status--${results?.value[1]?.toUpperCase()}
      continuous-monitoring-tab__app-property__status--${fromModal ? 'border' : ''}`}
    >
      <span> {results?.value[1]}</span>
      {results?.value[0]}
    </div>
  );
}

ColoredResult.propTypes = {
  results: PropTypes.object,
  fromModal: PropTypes.bool,
};

export default ColoredResult;
