import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ControlComponent from 'components/ControlComponent';
import { getAnswerType } from 'utils/controls';
import { removeControlFile, uploadControlFiles } from 'api/editor';
import { ControlType } from 'constants/controlType';
import { useFileUploadProcess } from 'components/FileUploadIndicator/useFileUploadProcess';
import { selectAnswerTypes, selectSaveLoading } from 'pages/Editor/selectors';
import Loader from 'ui/Loader';
import { API_STATUS } from 'constants/api';
import './answer.scss';

const Answer = ({ control, disabled, onSave }) => {
  const [fileLoading, setFileLoading] = useState();
  const loading = useSelector(selectSaveLoading);
  const answerTypes = useSelector(selectAnswerTypes);
  const dispatch = useDispatch();
  const { abortController, startUploading, finishUploding, renderConfirmationModal } =
    useFileUploadProcess();

  const { categoryId, id, answer_type_idx: answerTypeIdx, answer } = control;

  const answerType = getAnswerType(answerTypeIdx, answerTypes);

  const handleUpload = async (files) => {
    const payload = {
      files,
      controlId: id,
      categoryId,
      abortController,
    };
    setFileLoading(true);
    startUploading();
    const linkFilesResp = await dispatch(uploadControlFiles(payload)).unwrap();
    finishUploding();
    if (linkFilesResp?.status === API_STATUS.SUCCESS) {
      const value = answer ? [...answer, ...files] : files;
      onSave(value);
    }
    setFileLoading(false);
  };

  const handleRemove = async (fileId) => {
    setFileLoading(true);
    await dispatch(removeControlFile({ fileId, objectId: id })).unwrap();
    setFileLoading(false);
  };
  const data = useMemo(() => {
    return {
      payload: {
        disabled,
        loading,
        options: (answerType.options || []).map((option) => ({
          key: option.key,
          value: option.key,
          label: option.value,
        })),
        name: `control-${id}`,
        onChange: onSave,
        value: answer,
      },
    };
  }, [disabled, loading, answerType, id, answer, onSave]);

  if (answerType.type === ControlType.FileUpload) {
    data.payload.handleUpload = handleUpload;
    data.payload.handleRemove = handleRemove;
    data.payload.uploadedFiles = answer;
    data.payload.loading = fileLoading;
  }

  if (answerType.type === undefined) {
    return null;
  }

  const onClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div onClick={onClick} data-test={`editor-control-answer-${answerType.type}-${control.id}`}>
      {loading && <Loader />}
      <ControlComponent className="editor-answer" data={data} type={answerType.type} />
      {renderConfirmationModal()}
    </div>
  );
};

Answer.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
  onSave: PropTypes.func,
};

export default Answer;
