import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Button from 'ui/Button';
import { translate } from 'utils/index';
import { useTheme } from 'hooks/theme';
import EmptyComponent from 'components/EmptyComponent';

import { selectAssessment, selectCategoryNavigation } from './selectors';
import Finalize from './finalize';
import { selectCategory } from './reducers';
import { messages } from './messages';
import './categoryNavigation.scss';

const CategoryNavigation = () => {
  const dispatch = useDispatch();
  const assessment = useSelector(selectAssessment);
  const { prev, next } = useSelector(selectCategoryNavigation);
  const { colorTheme } = useTheme();

  const shouldShowNavigation = !isEmpty(assessment) && (prev || next || !assessment.upstream);
  if (!shouldShowNavigation) return <EmptyComponent />;

  return (
    <div className="assessment-category-navigation">
      {prev && (
        <Button
          className="assessment-category-navigation__back"
          beforeIcon="left-arrow"
          outline
          size="sm"
          color="gray"
          onClick={() => dispatch(selectCategory(prev.id))}
          fluid
        >
          {translate(messages.back)}
        </Button>
      )}
      {next && (
        <Button
          className="assessment-category-navigation__next"
          afterIcon="right-arrow"
          outline
          size="sm"
          color={colorTheme}
          onClick={() => dispatch(selectCategory(next.id))}
        >
          {translate(messages.nextCategory)}
        </Button>
      )}
      {!assessment.upstream && <Finalize />}
    </div>
  );
};

export default CategoryNavigation;
