export const messages = {
  cancelButtonText: {
    key: 'Search expendable.cancelButtonText',
    defaultMessage: 'Cancel',
  },
  placeholder: {
    key: 'Search expendable.placeholder',
    defaultMessage: 'Search',
  },
};

export default {
  prefix: 'Search expendable',
  messages,
};
