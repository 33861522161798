import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Assessment/messages';

// ASSETS
import CollabIcon from 'assets/svgs/control-collabed-icon.svg';
import './collabEmails.scss';

function CollabEmails({ emailsList }) {
  const emails = useMemo(() => {
    return (
      <div className="collab-emails-list">
        <div className="collab-emails-list__col-left">
          <span className="collab-emails-list__title">{translate(messages.collabSharedWith)}:</span>
          <div className="collab-emails-list__emails">
            {emailsList?.map((data) => (
              <div key={data?.email || 'emailsList-email'}>{data?.email}</div>
            ))}
          </div>
        </div>
        <div className="collab-emails-list__col-right">
          <span className="collab-emails-list__title">{translate(messages.collabOn)}:</span>
          <div className="collab-emails-list__dates">
            {emailsList?.map((data) => (
              <div key={data?.sentOn || 'emailsList-sentOn'}>{data?.sentOn}</div>
            ))}
          </div>
        </div>
      </div>
    );
  }, [emailsList?.length]);

  return (
    <Tooltip
      title={emails}
      className="collab-emails-list"
      trigger="hover"
      overlayInnerStyle={{ width: 'fit-content', borderRadius: '5px' }}
    >
      <div>
        <img src={CollabIcon} alt={'collaborated-control'} height={20} />
      </div>
    </Tooltip>
  );
}

CollabEmails.propTypes = {
  emailsList: PropTypes.array,
};

export default CollabEmails;
