import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import Icon from 'ui/Icon';

// REDUX
import { selectAssessmentErrors } from 'pages/Assessment/selectors';
import { selectCategory, setCurrentErrorId, setCurrentErrorIndex } from 'pages/Assessment/reducers';

function ErrorsCounter() {
  const dispatch = useDispatch();
  const errors = useSelector(selectAssessmentErrors);
  const selectedCategory = useSelector((state) => state.assessment.selectedCategory);
  const { errorsList, selectedErrorId, selectedErrorIndex } = errors;

  const errorsArray = Object.keys(errorsList).reduce((acc, key) => {
    Object.keys(errorsList[key]).forEach((item) => {
      acc.push({
        categoryId: key,
        controlId: item,
      });
    });
    return acc;
  }, []);

  const scrollToControl = (controlId, categoryId) => {
    if (categoryId !== selectedCategory) {
      dispatch(selectCategory(categoryId));
    }

    setTimeout(() => {
      const parent = document.querySelector('.assessment__right-panel-inner');
      const controlElement = document.getElementById(`control-${controlId}`);
      const controlElementOffsetTop = controlElement.offsetTop;

      parent.scroll({
        top: controlElementOffsetTop - 70,
        left: 0,
        behavior: 'smooth',
      });
    }, 100);
  };

  const onClickNext = useCallback(() => {
    const number =
      errors.selectedErrorIndex + 1 >= errorsArray.length ? 0 : errors.selectedErrorIndex + 1;
    dispatch(setCurrentErrorIndex({ number }));
    const error = errorsArray[number];
    dispatch(setCurrentErrorId({ controlId: error.controlId }));
  }, [errorsArray]);

  const onClickPrevious = useCallback(() => {
    const number =
      errors.selectedErrorIndex - 1 < 0 ? errorsArray.length - 1 : errors.selectedErrorIndex - 1;
    const error = errorsArray[number];
    dispatch(setCurrentErrorIndex({ number }));
    dispatch(setCurrentErrorId({ controlId: error.controlId }));
  }, [errorsArray]);

  useEffect(() => {
    if (selectedErrorId) {
      const error = errorsArray[selectedErrorIndex];
      scrollToControl(selectedErrorId, error.categoryId);
    }
  }, [selectedErrorId]);

  return (
    <div className="errors-navigation__counter">
      {errors.selectedErrorIndex + 1}/{errors.errorsCount}
      <div className="errors-navigation__counter-arrows">
        <Icon icon="left-arrow2" onClick={onClickPrevious} />
        <Icon icon="right-arrow2" onClick={onClickNext} />
      </div>
    </div>
  );
}

export default ErrorsCounter;
