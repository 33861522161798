export const messages = {
  chooseAnswer: {
    key: 'ControlType.chooseAnswer',
    defaultMessage: 'Choose answer',
  },
};

export default {
  prefix: 'ControlType',
  messages,
};
