import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import './text.scss';

const { Text: AntText, Title } = Typography;

const Text = ({ light, noMargin, children, className, color, text, level, ...restProps }) => {
  const textClassNames = classNames('ui-text', className, {
    [`ui-text--${color}-text`]: color,
    'ui-text--light': light,
    'ui-text--no-margin': noMargin,
  });

  const renderComponent = () => {
    const textToRender = children || text;
    const TextComponent = level ? Title : AntText;

    return (
      <TextComponent className={textClassNames} level={level} {...restProps}>
        {textToRender}
      </TextComponent>
    );
  };

  return renderComponent();
};

Text.propTypes = {
  color: PropTypes.oneOf(['text', 'gray', 'purple-dark', 'pink']),
};

export default Text;
