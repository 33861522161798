import React from 'react';
import { useDispatch } from 'react-redux';

// COMPONENTS
import ErrorsCounter from 'pages/Assessment/validation/errorsCounter';
import Icon from 'ui/Icon';
import Button from 'ui/Button';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'pages/Assessment/messages';
import { resetErrors } from 'pages/Assessment/reducers';

// STYLE
import './errorsNavigation.scss';

function ErrorsNavigation() {
  const dispatch = useDispatch();
  const closeValidationView = () => dispatch(resetErrors());

  return (
    <div className="errors-navigation">
      <div className="errors-navigation__left">
        <Icon icon="info2" />
        <span className="errors-navigation__text">{translate(messages.errorNavigationText)}</span>
      </div>
      <div className="errors-navigation__right">
        <span className="errors-navigation__required">
          {translate(messages.errorNavigationRequired)}:
        </span>
        <ErrorsCounter />
        <Button
          data-test="errors-navigation-button-close"
          link
          color="white"
          className="errors-navigation__close-button"
        >
          <Icon icon="close" onClick={closeValidationView} />
        </Button>
      </div>
    </div>
  );
}

export default ErrorsNavigation;
