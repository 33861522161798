export const messages = {
  deselect: {
    key: 'UiApprove2.deselect',
    defaultMessage: 'Deselect',
  },
  approve: {
    key: 'UiApprove2.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    key: 'UiApprove2.reject',
    defaultMessage: 'Reject',
  },
};

export default {
  prefix: 'UiApprove2',
  messages,
};
