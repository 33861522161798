export const messages = {
  groupOfAssessments: {
    key: 'Assign assessment tab.groupOfAssessments',
    defaultMessage: 'Group of assessments',
  },
  assessmentRequired: {
    key: 'Assign assessment tab.assessmentRequired',
    defaultMessage: 'Assessments required',
  },
  youCanSelectMoreThanOne: {
    key: 'Assign assessment tab.youCanSelectMoreThanOne',
    defaultMessage: 'You can select more than one',
  },
  saveAndClose: {
    key: 'Assign assessment tab.saveAndClose',
    defaultMessage: 'Save and close',
  },
  monthly: {
    key: 'Assign assessment tab.monthly',
    defaultMessage: 'Monthly',
  },
  never: {
    key: 'Assign assessment tab.never',
    defaultMessage: 'Never',
  },
  quarterly: {
    key: 'Assign assessment tab.quarterly',
    defaultMessage: 'Quarterly',
  },
  semiAnnually: {
    key: 'Assign assessment tab.semiAnnually',
    defaultMessage: 'Semi-annually',
  },
  annually: {
    key: 'Assign assessment tab.annually',
    defaultMessage: 'Annually',
  },
  autoAssessmentPeriod: {
    key: 'Assign assessment tab.autoAssessmentPeriod',
    defaultMessage: 'Auto assessment period',
  },
  untitledAssessment: {
    key: 'Assign assessment tab.untitledAssessment',
    defaultMessage: 'Untitled Assessment',
  },
  assignAssessment: {
    key: 'Assign assessment tab.assignAssessment',
    defaultMessage: 'Assign assessment',
  },
  pleaseSelect: {
    key: 'Assign assessment tab.pleaseSelect',
    defaultMessage:
      'Please select from the following list, you may select more than one assessment.',
  },
  createNewVendor: {
    key: 'Assign assessment tab.createNewVendor',
    defaultMessage: 'Create new vendor',
  },
};

export default {
  prefix: 'Assign assessment tab',
  messages,
};
