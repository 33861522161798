import axios from 'axios';
import { GetProfileCustomersGroups } from 'api/endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getProfileCustomersGroupsRequest = async () => {
  return axios.post(GetProfileCustomersGroups.url);
};

export const getCustomersGroups = createAsyncThunk(
  'getCustomersGroups',
  getProfileCustomersGroupsRequest,
);
