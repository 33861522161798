import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { saveTemplate } from 'api/editor';
import { selectTemplate } from 'pages/Editor/selectors';
import Radio from 'ui/Radio';
import { ControlType } from 'constants/controlType';

import TriggerMenu from './triggerMenu';
import TriggersModal from './triggersModal';
import './cloudMonitoringOptions.scss';

const CloudMonitoringOptions = ({ addQuestion, control, options, onSave }) => {
  const { upstream } = useSelector(selectTemplate);
  const [showTriggersModal, setShowTriggersModal] = useState();
  const [activeAnswer, setActiveAnswer] = useState();
  const dispatch = useDispatch();

  const filteredOptions = (options || []).filter(
    (option) => option.value !== '' || option.value !== 0,
  );

  const createNewTrigger = (key) => {
    setShowTriggersModal(true);
    setActiveAnswer(key);
  };

  const onControlSave = (key, triggers) => {
    const payload = {
      categories: [
        {
          id: control.categoryId,
          controls: [
            {
              ...control,
              triggers: {
                ...control.triggers,
                [key]: control.triggers[key] ? [...control.triggers[key], ...triggers] : triggers,
              },
            },
          ],
        },
      ],
    };
    dispatch(saveTemplate(payload));
  };

  return (
    <div className="editor-cloud-monitoring-options">
      {filteredOptions.map((option, index) => {
        return (
          <div key={option.key} className="editor-cloud-monitoring-options__option">
            <div className="editor-cloud-monitoring-options__value">
              <Radio
                disabled
                key={option.key}
                value={option?.key}
                name={`radio-${control.id}`}
                checked={false}
              >
                {option.value}
              </Radio>
            </div>
            {!upstream && option.key === 3 && (
              <TriggerMenu
                className="editor-cloud-monitoring-options__trigger-menu"
                active={(control.triggers[option.key] || []).length > 0}
                createNewTrigger={() => createNewTrigger(option.key)}
                addQuestion={() => addQuestion(option.key)}
              />
            )}
          </div>
        );
      })}
      <TriggersModal
        controlId={control.id}
        onSelectTriggers={onControlSave}
        selectedAnswer={activeAnswer}
        selectedTriggers={control.triggers[activeAnswer] || []}
        toggleModal={setShowTriggersModal}
        open={showTriggersModal}
      />
    </div>
  );
};

CloudMonitoringOptions.propTypes = {
  addQuestion: PropTypes.func,
  control: PropTypes.object,
  controlType: PropTypes.oneOf([ControlType.Checkbox, ControlType.RadioGroup]),
  options: PropTypes.array,
  onSave: PropTypes.func,
};

export default CloudMonitoringOptions;
