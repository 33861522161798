import { translate } from 'utils/index';
import { generalMessages } from 'constants/messages';

export class FindingsRisk {
  static getRiskValue(riskNumber) {
    if (riskNumber >= 0 && riskNumber <= 7) {
      return translate(generalMessages.lowRisk);
    }
    if (riskNumber >= 8 && riskNumber <= 14) {
      return translate(generalMessages.mediumRisk);
    }
    if (riskNumber >= 15 && riskNumber <= 18) {
      return translate(generalMessages.highRisk);
    }
    if (riskNumber >= 19 && riskNumber <= 23) {
      return translate(generalMessages.criticalRisk);
    }
    if (riskNumber >= 24) {
      return translate(generalMessages.showstopperRisk);
    }
    return translate(generalMessages.notSpecified);
  }
}
