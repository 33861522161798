import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import AssessmentPreview from 'pages/Showcases/showcasePreview/assessmentPreview';
import PreviewHeader from 'pages/Showcases/showcasePreview/previewHeader';
import AssessmentsList from 'pages/Showcases/showcasePreview/assessmentsList';

// REDUX
import { selectShowCasePreview } from 'pages/Showcases/showcasePreview/selectors';
import { getAssignedShowcase } from 'api/showcase';
import { resetAssignedShowcasePreview } from 'pages/Showcases/showcasePreview/reducers';
import './index.scss';

const ShowcasePreview = () => {
  const { showcaseId } = useParams();
  const { activeAssessmentId } = useSelector(selectShowCasePreview);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssignedShowcase({ showcaseId })).unwrap();
    return () => resetAssignedShowcasePreview();
  }, []);

  const assessmentPreview = useMemo(() => {
    // preventing getAssessment error
    if (activeAssessmentId && typeof activeAssessmentId === 'string') {
      return <AssessmentPreview assessmentId={activeAssessmentId} />;
    }
    return null;
  }, [activeAssessmentId]);

  return (
    <div className="showcase-preview">
      <PreviewHeader />
      <AssessmentsList />
      <div>{assessmentPreview}</div>
    </div>
  );
};

export default ShowcasePreview;
