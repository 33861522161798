import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetFiltersOptions } from './endpoints';

const getFilterOptionsRequest = async ({
  filterKey,
  dependencies,
  upstream = false,
  queryBlockItemKey,
}) => {
  const payload = {
    filterKey,
    upstream,
  };
  if (queryBlockItemKey) {
    payload.queryBlockItemKey = queryBlockItemKey;
  }
  if (dependencies) {
    payload.dependencies = JSON.stringify(dependencies);
  }

  return axios.post(GetFiltersOptions.url, payload);
};

export const getFilterOptions = createAsyncThunk('getFilterOptions', getFilterOptionsRequest);
