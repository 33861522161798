export const messages = {
  assessments: {
    key: 'Assessments.assessments',
    defaultMessage: 'Assessments',
  },
  date: {
    key: 'Assessments.date',
    defaultMessage: 'DATE',
  },
  organization: {
    key: 'Assessments.organization',
    defaultMessage: 'ORGANIZATION',
  },
  vendor: {
    key: 'Assessments.vendor',
    defaultMessage: 'VENDOR',
  },
  customer: {
    key: 'Assessments.customer',
    defaultMessage: 'CUSTOMER',
  },
  type: {
    key: 'Assessments.type',
    defaultMessage: 'TYPE',
  },
  due: {
    key: 'Assessments.due',
    defaultMessage: 'DUE',
  },
  score: {
    key: 'Assessments.score',
    defaultMessage: 'SCORE',
  },
  status: {
    key: 'Assessments.status',
    defaultMessage: 'STATUS',
  },
  openFindings: {
    key: 'Assessments.openFindings',
    defaultMessage: 'OPEN FINDINGS',
  },
  addFilter: {
    key: 'Assessments.addFilter',
    defaultMessage: 'Add filter',
  },
  assignAssessment: {
    key: 'Assessments.assignAssessment',
    defaultMessage: 'ASSIGN ASSESSMENT',
  },
  self: {
    key: 'Assessments.self',
    defaultMessage: 'self',
  },
  showcaseIndicator: {
    key: 'Assessments.showcaseIndicator',
    defaultMessage: 'SHOWCASE',
  },
  emptyStateValue: {
    key: 'Assessments.emptyStateValue',
    defaultMessage: 'N/A',
  },
  statusNotStarted: {
    key: 'Assessments.statusNotStarted',
    defaultMessage: 'NOT STARTED',
  },
  statusInProgress: {
    key: 'Assessments.statusInProgress',
    defaultMessage: 'IN PROGRESS',
  },
  statusCompleted: {
    key: 'Assessments.statusCompleted',
    defaultMessage: 'COMPLETED',
  },
  statusSubmitted: {
    key: 'Assessments.statusSubmitted',
    defaultMessage: 'SUBMITTED',
  },
  statusShowCase: {
    key: 'Assessments.statusShowCase',
    defaultMessage: 'SHOWCASE',
  },
  fail: {
    key: 'Assessments.fail',
    defaultMessage: 'FAIL',
  },
  pass: {
    key: 'Assessments.pass',
    defaultMessage: 'PASS',
  },
  undefined: {
    key: 'Assessments.undefined',
    defaultMessage: 'UNDEFINED',
  },
  pastDueDate: {
    key: 'Assessments.pastDueDate',
    defaultMessage: 'Past due date',
  },
  by: {
    key: 'Assessments.by',
    defaultMessage: 'by',
  },
  importWizardMenuModalTitle: {
    key: 'Assessments.importWizardMenuModalTitle',
    defaultMessage: 'Import Assessment File to Findings',
  },
  importWizardMenuModalSubTitle: {
    key: 'Assessments.importWizardMenuModalSubTitle',
    defaultMessage: 'Please select file type',
  },
  importWizardMenuModalButtonText: {
    key: 'Assessments.importWizardMenuModalButtonText',
    defaultMessage: 'customize your assessment',
  },
  createShowcase: {
    key: 'Assessments.createShowcase',
    defaultMessage: 'create showcase',
  },
  downloadReport: {
    key: 'Assessments.downloadReport',
    defaultMessage: 'Download report',
  },
  exportCsv: {
    key: 'Assessments.exportCsv',
    defaultMessage: 'Export (csv)',
  },
  sendToArchive: {
    key: 'Assessments.sendToArchive',
    defaultMessage: 'Send to archive',
  },
  restoreFromArchive: {
    key: 'Assessments.restoreFromArchive',
    defaultMessage: 'Restore from archive',
  },
  notAvailable: {
    key: 'Assessments.notAvailable',
    defaultMessage: 'NOT AVAILABLE',
  },
  assigned: {
    key: 'Assessments.assigned',
    defaultMessage: 'Assigned',
  },
  templates: {
    key: 'Assessments.templates',
    defaultMessage: 'Templates',
  },
};

export default {
  prefix: 'Assessments',
  messages,
};
