export const TableSource = {
  // Assessments
  assessmentsRecent: 'assessmentRecent',
  assessmentsAssigned: 'assessmentsAssigned',
  assessmentsTemplates: 'assessmentsTemplates',
  // Vendors
  vendorsRecent: 'vendorsRecent',
  vendorsList: 'vendorsList',
  // Customers
  customersRecent: 'customersRecent',
  customersList: 'customersList',
  // Findings
  findingsRecent: 'findingsRecent',
  findingsList: 'findingsList',
  // Evidence
  evidenceRecent: 'evidenceRecent',
  evidenceList: 'evidenceList',
  // Messages
  messages: 'messages',
};

export const mappedColumns = (columns) => {
  return columns.map((column) => {
    const cellStyles = {};

    if (column.flex) {
      cellStyles.flex = column.flex;
    }

    if (column.minWidth) {
      cellStyles.minWidth = column.minWidth;
    }

    if (column.maxWidth) {
      cellStyles.maxWidth = column.maxWidth;
    }

    return {
      ...column,
      onHeaderCell: () => ({ style: { ...cellStyles } }),
      onCell: () => ({ style: { ...cellStyles } }),
    };
  });
};
