import axios from 'axios';
import {
  AddProfileAssessment,
  AddProfileCustomerAssessment,
  CollabControl,
  EditFileMetaData,
  FinalizeProfileAssessment,
  GenerateCloudAnswers,
  GetAnswerStaticRepository,
  GetAssessment,
  GetFileMetaData,
  GetOrganizationAssessment,
  GetProfileAssessmentReport,
  LinkFiles,
  ReinviteProfileAssessment,
  UnlinkFile,
  RevertFinalizeAssessment,
  RevertReviewAssessment,
  ReviewApprovedProfileAssessment,
  SetAssessment,
  ShareCMResults,
  RemoveCloudAnswers,
} from 'api/endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { triggerCategoriesControls, triggerControlsForCategories } from 'utils/triggers';
import { FileObjectType } from 'constants/FileObjectType';
import { extractFileIdsFromFiles } from 'utils/files';

export const getAssessmentRequest = async ({ id, preview }) => {
  const payload = {
    'get-assessment-assessment-token': id,
    preview,
  };

  return axios.post(GetAssessment.url, payload);
};

export const getAssessment = createAsyncThunk('getAssessment', getAssessmentRequest);

const saveControlWithTriggersRequest = async ({ _assessment, control }, { getState }) => {
  const { assessment } = getState();
  const categories = triggerCategoriesControls(
    control,
    _assessment ? _assessment.categories : assessment.assessment.categories,
  );
  const categoriesToUpdate = getPropertiesToSave(categories);

  const preparedCategoriesToUpdate = categoriesToUpdate.map(({ controls, ...singleCategory }) => ({
    ...singleCategory,
    controls: controls.map(({ answer, findings = [], ...singleControl }) => ({
      ...singleControl,
      answer,
      findings: findings.map(({ id, triggers }) => ({
        id,
        enabled: triggers.some((triggerValue) => {
          if (Array.isArray(answer)) return answer.includes(triggerValue);
          return triggers.includes(answer);
        }),
      })),
    })),
  }));

  const data = {
    _assessment: {
      categories: preparedCategoriesToUpdate,
    },
  };

  const payload = {
    'set-assessment-assessment-token': assessment.assessment.id,
    'set-assessment-_assessment-json': JSON.stringify(data),
  };
  return axios.post(SetAssessment.url, payload);
};

export const saveControlWithTriggers = createAsyncThunk(
  'saveControlWithTriggers',
  saveControlWithTriggersRequest,
);

function getPropertiesToSave(categories) {
  return categories.map((category) => ({
    ...category,
    controls: category.controls.map(({ answer, id, status, enabled, comment, findings }) => ({
      answer,
      id,
      status,
      enabled,
      comment,
      findings,
    })),
  }));
}

const saveControlsWithTriggersRequest = async (controls, { getState }) => {
  const { assessment } = getState();
  const categories = triggerControlsForCategories(controls, assessment.assessment.categories);
  const categoriesToUpdate = getPropertiesToSave(categories);

  const data = {
    _assessment: {
      categories: categoriesToUpdate,
    },
  };

  const payload = {
    'set-assessment-assessment-token': assessment.assessment.id,
    'set-assessment-_assessment-json': JSON.stringify(data),
  };
  return axios.post(SetAssessment.url, payload);
};

export const saveControlsWithTriggers = createAsyncThunk(
  'saveControlsWithTriggers',
  saveControlsWithTriggersRequest,
);

export const saveControlRequest = async ({ categoryId, assessmentId, control }, { getState }) => {
  const state = getState();
  const data = {
    _assessment: {
      categories: [
        {
          id: categoryId,
          controls: [control],
        },
      ],
    },
  };

  const payload = {
    'set-assessment-assessment-token': assessmentId
      ? assessmentId
      : state?.assessment?.assessment.id,
    'set-assessment-_assessment-json': JSON.stringify(data),
  };
  return axios.post(SetAssessment.url, payload);
};

export const saveControl = createAsyncThunk('saveControl', saveControlRequest);

const finalizeAssessmentRequest = async (args, { getState }) => {
  const { assessment } = getState();
  const payload = {
    'finalize-assessment-assessment-token': assessment.assessment.id,
  };

  if (args?.forceSubmitted) {
    payload.forceSubmitted = true;
  }

  return axios.post(FinalizeProfileAssessment.url, payload);
};

export const finalizeAssessment = createAsyncThunk('finalizeAssessment', finalizeAssessmentRequest);

const markAsReviewAssessmentRequest = async (args, { getState }) => {
  const { assessment } = getState();
  const payload = {
    'review-approved-assessment-assessment-token': assessment.assessment.id,
  };
  return axios.post(ReviewApprovedProfileAssessment.url, payload);
};

export const markAsReviewAssessment = createAsyncThunk(
  'markAsReviewAssessment',
  markAsReviewAssessmentRequest,
);

const uploadFilesRequest = async ({ files, abortController }, { getState }) => {
  const { assessment } = getState();

  const payload = {
    'object-id': assessment.assessment.id,
    'object-type': FileObjectType.assessment,
    'file-ids': extractFileIdsFromFiles(files),
  };

  return axios.post(LinkFiles.url, payload, { signal: abortController?.signal });
};

export const uploadFiles = createAsyncThunk('uploadFiles', uploadFilesRequest);

const removeFileRequest = async ({ fileId, assessmentType, assessmentId }, { getState }) => {
  const { assessment } = getState();
  const payload = {
    'remove-assessment-file-_assessment-token': assessmentId || assessment.assessment.id,
    'remove-assessment-file-file-id': fileId,
    objectId: assessmentId || assessment.assessment.id,
  };

  if (assessmentType !== undefined) {
    payload['assessment-type'] = assessmentType;
  }

  return axios.post(UnlinkFile.url, payload);
};

export const removeFile = createAsyncThunk('removeFile', removeFileRequest);

export const uploadControlFilesRequest = async ({ controlId, files, abortController }) => {
  const payload = {
    'object-id': controlId,
    'object-type': FileObjectType.control,
    'file-ids': extractFileIdsFromFiles(files),
  };

  return axios.post(LinkFiles.url, payload, { signal: abortController?.signal });
};

export const uploadControlFiles = createAsyncThunk('uploadControlFiles', uploadControlFilesRequest);

const removeControlFileRequest = async ({ fileId, controlId, assessmentId }, { getState }) => {
  const { assessment } = getState();
  const payload = {
    'remove-assessment-file-_assessment-token': assessment.assessment.id || assessmentId,
    'remove-assessment-file-file-id': fileId,
    'assessment-type': 0,
    objectId: controlId,
  };

  return axios.post(UnlinkFile.url, payload);
};

export const removeControlFile = createAsyncThunk('removeControlFile', removeControlFileRequest);

const resendInvitationRequest = async (args, { getState }) => {
  const { assessment } = getState();
  const payload = {
    'reinvite-assessment-assessment-token': assessment.assessment.id,
  };
  return axios.post(ReinviteProfileAssessment.url, payload);
};

export const resendInvitation = createAsyncThunk('resendInvitation', resendInvitationRequest);

const assignAssessmentRequest = async ({ customer, due, id }) => {
  const payload = {
    'add-customer-assessment-assessment-token': id,
    'add-customer-assessment-relation-id': customer,
    'add-customer-assessment-assessment-due-date-attr': due,
  };
  return axios.post(AddProfileCustomerAssessment.url, payload);
};

export const assignAssessment = createAsyncThunk('assignAssessment', assignAssessmentRequest);

const assignSelfAssessmentRequest = async ({ id }) => {
  const payload = {
    'add-assessment-assessment-token': id,
  };
  return axios.post(AddProfileAssessment.url, payload);
};

export const assignSelfAssessment = createAsyncThunk(
  'assignSelfAssessment',
  assignSelfAssessmentRequest,
);

const collaborateControlsRequest = async (payload) => {
  return axios.post(CollabControl.url, payload);
};

export const collaborateControls = createAsyncThunk(
  'collaborateControls',
  collaborateControlsRequest,
);

const getAnswerRepositoryRequest = async () => {
  return axios.post(GetAnswerStaticRepository.url);
};

export const getAnswerRepository = createAsyncThunk(
  'getAnswerRepository',
  getAnswerRepositoryRequest,
);

const getFileMetaDataRequest = async ({ id }) => {
  const payload = {
    'file-id': id,
  };
  return axios.post(GetFileMetaData.url, payload);
};

export const getFileMetaData = createAsyncThunk('getFileMetaData', getFileMetaDataRequest);

const editFileMetaDataRequest = async ({ id, metadata }) => {
  const payload = {
    fileId: id,
    metadata,
  };
  return axios.post(EditFileMetaData.url, payload);
};

export const editFileMetaData = createAsyncThunk('editFileMetaData', editFileMetaDataRequest);

export const getAssessmentReport = async ({ assessmentId }) => {
  const payload = {
    'get-assessment-report-assessment-token': assessmentId,
  };
  return axios.post(GetProfileAssessmentReport.url, payload);
};

const revertFinalizedAssessmentRequest = async (args, { getState }) => {
  const { assessment } = getState();
  const payload = {
    'assessment-token': assessment.assessment.id,
  };
  return axios.post(RevertFinalizeAssessment.url, payload);
};

export const revertFinalizedAssessment = createAsyncThunk(
  'revertFinalizedAssessment',
  revertFinalizedAssessmentRequest,
);

const revertReviewedAssessmentRequest = async (args, { getState }) => {
  const { assessment } = getState();
  const payload = {
    'assessment-token': assessment.assessment.id,
  };
  return axios.post(RevertReviewAssessment.url, payload);
};

export const revertReviewedAssessment = createAsyncThunk(
  'revertReviewedAssessment',
  revertReviewedAssessmentRequest,
);

const getTemplateFileRequest = async () => {
  return axios.post(GetOrganizationAssessment.url);
};

export const getTemplateFile = createAsyncThunk('getTemplateFile', getTemplateFileRequest);

const generateCloudAnswersRequest = async (args, { getState }) => {
  const { assessment } = getState();
  const payload = {
    'assessment_token': assessment.assessment.id,
  };
  return axios.post(GenerateCloudAnswers.url, payload);
};

export const generateCloudAnswers = createAsyncThunk(
  'generateCloudAnswers',
  generateCloudAnswersRequest,
);

const cancelCloudAnswersRequest = async (appId, { getState }) => {
  const { assessment } = getState();
  const payload = {
    assessment_token: assessment.assessment.id,
    app_id: appId,
  };
  return axios.post(RemoveCloudAnswers.url, payload);
};

export const cancelCloudAnswers = createAsyncThunk(
  'generateCloudAnswers',
  cancelCloudAnswersRequest,
);

const reviewCloudMonitoringRequest = async ({ categoryId }, { getState }) => {
  const { assessment } = getState();

  const data = {
    _assessment: {
      categories: [
        {
          id: categoryId,
          reviewed: true,
        },
      ],
    },
  };

  const payload = {
    'set-assessment-assessment-token': assessment.assessment.id,
    'set-assessment-_assessment-json': JSON.stringify(data),
  };
  return axios.post(SetAssessment.url, payload);
};

export const reviewCloudMonitoring = createAsyncThunk(
  'reviewCloudMonitoring',
  reviewCloudMonitoringRequest,
);

export const shareCMResultsRequest = async ({ appId, assessmentId }) => {
  return axios.post(ShareCMResults.url, {
    appId,
    assessmentId,
  });
};

export const shareCMResults = createAsyncThunk('shareCMResults', shareCMResultsRequest);
