import axios from 'axios';
import { GetEvidences } from './endpoints';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FILTERABLE_KEYS } from 'components/Filters/lib';

const getEvidencesRequest = async (args = {}, { getState }) => {
  const { app, filters, evidences } = getState();
  const argsPagination = args?.pagination;

  const params = {
    filters: JSON.stringify(
      filters?.allFilters[FILTERABLE_KEYS.EVIDENCES_LIST_FILTERS]?.filtersToApi || {},
    ),
    descending: true,
    upstream: !app.isMyVendorsTabSelected,
    offset: argsPagination?.current || evidences.pagination?.current,
    search: evidences.search,
    count: argsPagination?.pageSize || evidences.pagination?.pageSize,
  };

  return axios.get(GetEvidences.url, { params });
};

export const getEvidences = createAsyncThunk('getEvidences', getEvidencesRequest);
