import ROUTES from 'constants/routes';
import { useAppNavigate } from 'hooks/navigation';

function useViewAssessment() {
  const appNavigate = useAppNavigate();

  return ({ appId, currentVendor }) => {
    if (!currentVendor || !currentVendor.continuousMonitoring) {
      return null;
    }

    const { assessmentId, allResults } = currentVendor.continuousMonitoring.find(
      ({ id }) => id === appId,
    );

    appNavigate(
      `${ROUTES.VENDOR_ASSESSMENT}/*`,
      { assessmentId, '*': `?categoryId=${allResults.id}` },
      {},
      true,
    );
  };
}

export default useViewAssessment;
