import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { getAnswerExample, getExplainedControl, getImprovedAnswer } from 'api/upstreamAi';
import { auditControl, confirmAuditFinding } from 'api/downstreamAi';

const initialState = {
  loading: false,
  controls: {},
  enableImprove: undefined,
};

const reducers = createSlice({
  name: 'upstreamAI',
  initialState,
  reducers: {
    setEnableAI: (state, { payload }) => {
      state.enableImprove = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          getExplainedControl.pending,
          getAnswerExample.pending,
          getImprovedAnswer.pending,
          auditControl.pending,
          confirmAuditFinding.pending,
        ),
        (state) => {
          state.loading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          getExplainedControl.fulfilled,
          getAnswerExample.fulfilled,
          getImprovedAnswer.fulfilled,
          auditControl.fulfilled,
        ),
        (state, { payload: { controlId, response, source } }) => {
          state.loading = false;

          const controlsObject = {
            ...state.controls,
            [controlId]: {
              ...state.controls[controlId],
              // source: [explain, example, improve, audit]
              [`${source}`]: response,
            },
          };

          state.controls = controlsObject;
        },
      )
      .addMatcher(
        isAnyOf(confirmAuditFinding.fulfilled),
        (state, { payload: { response, controlId, findingId } }) => {
          state.loading = false;

          if (response.status) {
            const updatedFindings = state.controls[controlId].audit.findings.map((finding) => {
              if (finding.id === findingId) {
                return {
                  ...finding,
                  confirmed: true,
                };
              }
              return finding;
            });
            const controlsObject = {
              ...state.controls,
              [controlId]: {
                ...state.controls[controlId],
                // source: [audit]
                audit: {
                  ...state.controls[controlId].audit,
                  findings: updatedFindings,
                },
              },
            };

            state.controls = controlsObject;
          }
        },
      );
  },
});

export const { setEnableAI } = reducers.actions;

export default reducers;
