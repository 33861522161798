export const messages = {
  columnSendDate: {
    key: 'ShowcasesSentList.columnSendDate',
    defaultMessage: 'SEND DATE',
  },
  columnTitle: {
    key: 'ShowcasesSentList.columnTitle',
    defaultMessage: 'Title',
  },
  columnDescription: {
    key: 'ShowcasesSentList.columnDescription',
    defaultMessage: 'DESCRIPTION',
  },
  columnSendTo: {
    key: 'ShowcasesSentList.columnSendTo',
    defaultMessage: 'SEND TO',
  },
  columnSharedUntil: {
    key: 'ShowcasesSentList.columnSharedUntil',
    defaultMessage: 'SHARED UNTIL',
  },
  shareButton: {
    key: 'ShowcasesSentList.shareButton',
    defaultMessage: 'SHARE SHOWCASE',
  },
  emptyState: {
    key: 'ShowcasesSentList.emptyState',
    defaultMessage:
      'Findings Showcase allows you to leverage existing answered assessments, combining multiple frameworks if needed, to create a beautiful branded showcase to share with your customers.\n' +
      '\n' +
      'To start, first create a showcase and then select "share showcase” in the ‘shared’ tab\n',
  },
  createShowcase: {
    key: 'ShowcasesSentList.createShowcase',
    defaultMessage: 'CREATE SHOWCASE',
  },
};

export default {
  prefix: 'ShowcasesSentList',
  messages,
};
