import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { parsePhoneNumber } from 'react-phone-number-input';

import Form from 'ui/Form';
import PrefixSelect from 'components/PhoneInput/prefixSelect';
import { translate } from 'utils/index';
import usePhoneInput from 'components/PhoneInput/usePhoneInput';
import Input from 'ui/Input';
import { phoneWithoutPrefix } from 'components/PhoneInput/lib';
import { messages } from './messages';

import './index.scss';

const PhoneInput = ({ onChange, name, phoneNumberErrors, phone: defaultPhone, prefix }) => {
  const { values, errors, onChangePhoneInput } = usePhoneInput(
    phoneNumberErrors,
    defaultPhone,
    prefix,
  );
  const { phone, corporateGeography } = values;
  const [phoneNumber, setPhoneNumber] = useState(phoneWithoutPrefix(phone, corporateGeography));

  useEffect(() => {
    const { nationalNumber } = parsePhoneNumber(phone || '') || {};

    onChange({
      phone,
      nationalNumber,
      corporateGeography,
      isError: !!errors.phone,
    });
  }, [errors]);

  const onBlur = () => {
    const { nationalNumber } = parsePhoneNumber(phone || '') || {};
    const newPhoneNumber = `+${corporateGeography}${phoneNumber}`;
    onChange({
      phone: newPhoneNumber,
      nationalNumber,
      corporateGeography,
      isError: !!errors.phone,
    });
  };
  const onChangePhone = (event) => {
    const { value } = event.target;
    setPhoneNumber(value);
    onChangePhoneInput('phone', value);
  };

  function onFrefixSelectChange(value) {
    onChangePhoneInput('corporateGeography', value);
    const { nationalNumber } = parsePhoneNumber(phone || '') || {};
    const newPhoneNumber = `+${value}${phoneNumber}`;

    onChange({
      phone: newPhoneNumber,
      nationalNumber,
      corporateGeography: value,
      isError: !!errors.phone,
    });
  }

  return (
    <div className="phone-input">
      <Form.Item>
        <Form.Label text={translate(messages.prefixLabel)} />
        <PrefixSelect
          error={errors.prefix}
          selectedValue={corporateGeography}
          onChange={onFrefixSelectChange}
        />
        <Form.Error error={errors.prefix} />
      </Form.Item>

      <Form.Item>
        <Form.Label text={translate(messages.phoneLabel)} />
        <Input
          data-test="phone-input"
          name={name}
          error={errors.phone}
          type="text"
          disabled={!corporateGeography}
          placeholder={translate(messages.phonePlaceholder)}
          onBlur={onBlur}
          value={phoneNumber}
          onChange={onChangePhone}
        />
      </Form.Item>
    </div>
  );
};

PhoneInput.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  phoneNumberErrors: PropTypes.object,
  phone: PropTypes.string,
  prefix: PropTypes.number,
};

export default PhoneInput;
