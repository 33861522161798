import * as yup from 'yup';

// eslint-disable-next-line
const PASSWORD_STRUCTURE_VALIDATION_REGEX = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]+/;
const PASSWORD_LENGTH_VALIDATION_REGEX = '(.*[0-9]){1}';
const PASSWORD_UPPER_VALIDATION_REGEX = /[A-Z]/;
const PASSWORD_LOWER_VALIDATION_REGEX = /[a-z]/;
const PASSWORD_LENGTH = 8;

export const passwordRules = (
  passwordLengthValidation,
  passwordSpecialCharsQtyValidation,
  passwordNumbersQtyValidation,
  passwordUpperValidation,
  passwordLowerValidation,
  passwordRequired,
  passwordConfirmationMatch,
  passwordConfirmationRequired,
  termsAndConditionsCheck,
) =>
  yup.object({
    showOldPassword: yup.bool(),
    oldPasswordValue: yup.string().when('showOldPassword', {
      is: true,
      then: () => yup.string().required(passwordRequired),
    }),
    password: yup
      .string()
      .matches(PASSWORD_STRUCTURE_VALIDATION_REGEX, passwordSpecialCharsQtyValidation)
      .matches(PASSWORD_LENGTH_VALIDATION_REGEX, passwordNumbersQtyValidation)
      .matches(PASSWORD_UPPER_VALIDATION_REGEX, passwordUpperValidation)
      .matches(PASSWORD_LOWER_VALIDATION_REGEX, passwordLowerValidation)
      .min(PASSWORD_LENGTH, passwordLengthValidation)
      .required(passwordRequired),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], passwordConfirmationMatch)
      .required(passwordConfirmationRequired),

    // termsAndConditions checkbox is not always shown,
    // therefore the validation check depends on validateTermsAndConditions and termsAndConditions not being null
    termsAndConditions: yup
      .bool()
      .nullable()
      .when('validateTermsAndConditions', {
        is: (val) => val !== null,
        then: () => yup.bool().oneOf([true], termsAndConditionsCheck),
        otherwise: yup.bool(),
      }),
  });
