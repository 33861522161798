import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PageHeader from 'components/PageHeader';
import { translate } from 'utils/index';
import Card from 'ui/Card';
import { messages } from './messages';
import { FILTERABLE_KEYS } from 'components/Filters/lib';
import { getEvidences } from 'api/evidences';
import Filters from 'components/Filters';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { EVIDENCES_LIST_ALLOWED_FILTERS_DOWNSTREAM } from 'components/Filters/allowedFilters';
import Search from './search';
import useEvidencesTable from './useEvidencesTable';
import Table from 'ui/Table';
import { setPagination, setSorter } from './reducers';
import { TableSource } from 'ui/Table/lib';

import './index.scss';

const Evidences = () => {
  const dispatch = useDispatch();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const { evidences, loading, sorter, pagination } = useSelector((state) => state.evidences);
  const { filtersToApi } = useSelector(
    (state) => state.filters.allFilters[FILTERABLE_KEYS.EVIDENCES_LIST_FILTERS],
  );

  const { columns, editEvidenceMeta, previewEvidence } = useEvidencesTable({
    evidences,
    evidencesSorter: sorter,
    evidencesLoading: loading,
    recent: false,
    fetchEvidences: () => dispatch(getEvidences()),
  });

  useEffect(() => {
    dispatch(getEvidences());
  }, [filtersToApi]);

  const dataSource = (evidences || []).map((evidence) => ({
    ...evidence,
    key: evidence.evidenceId,
  }));

  const onTableChange = (newPagination, _filters, sorter) => {
    if (newPagination) dispatch(setPagination(newPagination));

    const payload = {
      upstream: !isMyVendorsTabSelected,
      pagination: newPagination,
    };

    const sorterParams = {
      column: sorter.field,
      descending: sorter.order !== 'ascend',
    };
    const params = sorter.column ? sorterParams : {};

    dispatch(setSorter(params));
    dispatch(getEvidences(payload));
  };

  // const handleExportCsv = () => {
  //   // TODO: implement export csv
  // };

  // const items = [
  //   {
  //     key: 'evidence-menu-export-csv',
  //     label: translate(messages.exportCsv),
  //     icon: <ExportCsvFileIcon />,
  //     onClick: handleExportCsv,
  //     'data-test': 'evidence-menu-export-csv',
  //   },
  // ];

  return (
    <div className="evidences-page">
      <PageHeader className="findings-page__header">{translate(messages.pageTitle)}</PageHeader>
      <Card>
        <div className="evidences-page__action-bar">
          <div className="evidences-page__action-bar__search-filters">
            <Search />
            <Filters
              filterableKey={FILTERABLE_KEYS.EVIDENCES_LIST_FILTERS}
              allowedFilters={EVIDENCES_LIST_ALLOWED_FILTERS_DOWNSTREAM}
            />
          </div>
          <div className="evidences-page__action-bar__dropdown">
            {/* <Dropdown
              menu={{ items, className: 'evidences-page__action-bar__dropdown__menu' }}
              trigger="click"
            >
              <button
                className="evidences-page__action-bar__dropdown__button"
                data-test="evidences-menu-trigger"
              >
                <KebabIcon />
              </button>
            </Dropdown> */}
          </div>
        </div>

        <Table
          className="findings-page__list"
          dataSource={dataSource}
          columns={columns}
          pagination={pagination}
          onChange={onTableChange}
          loading={loading}
          sortable
          emptyStateSource={TableSource.evidenceList}
          scroll={{
            x: 1160,
          }}
        />
      </Card>
      {editEvidenceMeta()}
      {previewEvidence()}
    </div>
  );
};

export default Evidences;
