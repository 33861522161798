import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import NewVendorFooter from 'components/NewVendorFooter';
import RenderAppsView from 'pages/Vendors/VendorManage/Tabs/ContinuousMonitoring/renderAppsView';
import AddDomainView from './addDomainView';

import { generalMessages } from 'constants/messages';
import { validPayload } from 'pages/Vendors/VendorManage/lib';
import { useVendorManageContext } from 'hooks/useVendorManageContext';
import { getProfileVendor, runContinuousMonitoring } from 'api/vendor';
import { ReactComponent as ArrowIconRight } from 'assets/svgs/arrowRight.svg';
import { translate } from 'utils/index';
import { messages } from './messages';

import './index.scss';

const ContinuousMonitoring = ({ disabled, handleTabChange, prevAction }) => {
  const dispatch = useDispatch();
  const {
    currentVendor,
    contactFormErrors,
    updateVendor,
    isContactFormDirty,
    vendorContactData,
    onChangeContactFrom,
  } = useVendorManageContext();
  const { isLoading } = useSelector((state) => state.vendors);
  const [loader, setLoader] = useState();
  const [currentErrors, setCurrentErrors] = useState(contactFormErrors);
  const [touched, setTouched] = useState({});

  const validAsync = useCallback(async (payload) => {
    const currentError = await validPayload(payload);
    setCurrentErrors(currentError);
  }, []);

  useEffect(() => {
    if (isContactFormDirty.current) {
      validAsync(vendorContactData);
    }
  }, [vendorContactData]);

  const onSubmit = (event) => {
    event.preventDefault();
    currentVendor ? updateVendor() : handleTabChange();
  };

  const scanDomain = async () => {
    setLoader(true);
    await dispatch(
      runContinuousMonitoring({
        vendorToken: currentVendor.id,
        companyDomain: vendorContactData.companyDomain,
      }),
    );
    await dispatch(getProfileVendor(currentVendor.id));
    setLoader(false);
  };

  return (
    <div className="continuous-monitoring-tab">
      {currentVendor?.continuousMonitoring?.length > 0 ? (
        <RenderAppsView currentVendor={currentVendor} />
      ) : (
        <AddDomainView
          vendorContactData={vendorContactData}
          onChangeContactFrom={onChangeContactFrom}
          setTouched={setTouched}
          touched={touched}
          currentErrors={currentErrors}
          scanDomain={scanDomain}
          loader={loader}
          disabled={disabled}
        />
      )}
      <NewVendorFooter
        nextAfterComponent={!currentVendor && <ArrowIconRight width={13} height={14} />}
        nextAction={onSubmit}
        prevAction={prevAction}
        nextLabel={
          currentVendor ? translate(messages.saveAndClose) : translate(generalMessages.next)
        }
        prevLabel={translate(generalMessages.cancel)}
        isNextDisabled={disabled}
        isNextLoading={isLoading}
        dataTestNext={'continuous-monitoring-next'}
        dataTestPrev={'continuous-monitoring-prev'}
      />
    </div>
  );
};

ContinuousMonitoring.propTypes = {
  disabled: PropTypes.bool,
  handleTabChange: PropTypes.func,
  prevAction: PropTypes.func,
};

export default ContinuousMonitoring;
