import React from 'react';

// UTILS
import { translate } from 'utils/index';
import { messages } from 'components/Messenger/messages';

const EmptyState = () => {
  return (
    <div className="messenger-notifications-list__empty-state">
      <span>{translate(messages.noMessages)}</span>
    </div>
  );
};

export default EmptyState;
