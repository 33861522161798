import React from 'react';
import PropTypes from 'prop-types';

import './stageDescription.scss';

const StageDescription = ({ title, text }) => {
  return (
    <div className="stage-description">
      <h1>{title}</h1>
      <h2>{text}</h2>
    </div>
  );
};

StageDescription.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};
export default StageDescription;
