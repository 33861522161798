import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import classNames from 'classnames';

// COMPONENTS
import Filter from 'ui/Filter';
import { getAssessments } from 'api/assessments';
import { translate } from 'utils/index';
import { removeFilter, setFilter } from 'components/AssessmentsFilters/reducers';
import { messages } from 'components/AddFilterButton/messages';
import FilterCustomer from './filterCustomer';
import FilterType from './filterType';
import FilterGroup from './filterGroup';
import FilterArchive from './filterArchive';
import FilterParentOrganization from './filterParentOrganization';

// UTILS
import { statuses, vendorStatuses } from '../AddFilterButton/lib';

// REDUX
import { selectFilters } from './selectors';

// STYLE
import './index.scss';

const { Option } = Select;

const AssessmentsFilters = ({ callback, className, hideArchive }) => {
  // TODELETE: when old overview will be deleted (old overview uses this component)
  const filters = useSelector(selectFilters);
  const dispatch = useDispatch();

  if (filters.length === 0) {
    return null;
  }

  const fetchAssessments = () => {
    return callback || (() => dispatch(getAssessments()));
  };

  const renderStatus = (statusTypes) =>
    statusTypes.map(({ name, value }) => (
      <Option key={value} value={translate(name)} id={value}>
        {translate(name)}
      </Option>
    ));

  const onChange = (value, id, type) => {
    dispatch(setFilter({ type, value, id }));
  };

  const onRemove = (fetchData, type) => {
    dispatch(removeFilter({ type }));
    fetchData();
  };

  return (
    <div className={classNames('assessments-filters', className)}>
      {filters.map((filter) => {
        const generalProps = {
          mode: 'multiple',
          key: filter.type,
          filter,
          fetchData: fetchAssessments(),
          onChange,
          onRemove:
            filter.type === 'archive' ? undefined : () => onRemove(fetchAssessments(), filter.type),
        };

        switch (filter.type) {
          case 'customer':
            return <FilterCustomer {...generalProps} />;
          case 'vendor':
            return <FilterCustomer {...generalProps} />;
          case 'assessment':
            return <FilterType {...generalProps} />;
          case 'group':
            return <FilterGroup {...generalProps} />;
          case 'archive':
            return hideArchive ? null : <FilterArchive {...generalProps} />;
          case 'status':
            return (
              <Filter {...generalProps} innerLabel={translate(messages.statusFilter)}>
                {renderStatus(statuses)}
              </Filter>
            );
          case 'vendorStatus':
            return (
              <Filter {...generalProps} innerLabel={translate(messages.vendorStatusFilter)}>
                {renderStatus(vendorStatuses)}
              </Filter>
            );
          case 'parent-organization':
            return <FilterParentOrganization {...generalProps} />;
          default:
            return null;
        }
      })}
    </div>
  );
};

AssessmentsFilters.propTypes = {
  callback: PropTypes.func,
  className: PropTypes.string,
  hideArchive: PropTypes.bool,
};

export default AssessmentsFilters;
