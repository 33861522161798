import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Modal from 'ui/Modal';
import Button from 'ui/Button';

import { translate } from 'utils/index';
import { useIsMyVendorsTabSelected } from 'hooks/navigation';
import { getProfileCustomerBulkLog } from 'api/vendor';

import { ReactComponent as AcceptIcon } from 'assets/svgs/accept-comic.svg';
import { ReactComponent as CheckIcon } from 'assets/svgs/check-mark-filled.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/close-mark-filled.svg';

import { messages } from './messages';
import { sanitizer } from 'utils/sanitizer';

import './index.scss';

const UploadStatisticsModal = ({ statistics, onOk, onHide, open }) => {
  const dispatch = useDispatch();
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();

  const isFail = statistics?.fail > 0;
  const isSuccess = statistics?.success > 0;

  const downloadFile = (data, fileName, mimeType) => {
    const blob = new Blob([data], { type: mimeType });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getFile = async () => {
    const res = await dispatch(
      getProfileCustomerBulkLog({
        'file-name': statistics?.file_name,
      }),
    ).unwrap();

    if (res) {
      downloadFile(res, statistics?.file_name, 'text/csv');
    }
  };

  useEffect(() => {
    if (statistics?.file_name) {
      getFile();
    }
  }, [statistics]);

  const handleDownload = () => {
    getFile();
  };

  return (
    <Modal open={open} onCancel={onHide} onOk={onOk} footer={null} width={572}>
      <div className="upload-modal">
        <div className="upload-modal__header">
          <div className="upload-modal__header-icon">
            <AcceptIcon />
          </div>
          <div className="upload-modal__header-text">{translate(messages.modalTitle)}</div>
        </div>

        {(isFail || isSuccess) && (
          <div className="upload-modal__statistics">
            {isSuccess && (
              <div className="upload-modal__statistics-row">
                <CheckIcon />
                <div className="upload-modal__statistics-row-value">{statistics?.success}</div>
                {isMyVendorsTabSelected
                  ? translate(messages.vendorSuccess)
                  : translate(messages.customerSuccess)}
              </div>
            )}
            {isFail && (
              <div className="upload-modal__statistics-row">
                <CloseIcon />
                <div className="upload-modal__statistics-row-value">{statistics.fail}</div>
                {isMyVendorsTabSelected
                  ? translate(messages.vendorError)
                  : translate(messages.customerError)}
              </div>
            )}
          </div>
        )}

        {isFail && (
          <div
            className="upload-modal__description"
            dangerouslySetInnerHTML={{
              __html: isMyVendorsTabSelected
                ? sanitizer(translate(messages.vendorDescription))
                : sanitizer(translate(messages.customerDescription)),
            }}
          ></div>
        )}
        <div className="upload-modal__button">
          <Button data-test="upload-modal-got-it" onClick={onHide}>
            {translate(messages.buttonText)}
          </Button>
        </div>
        {isFail && (
          <div className="upload-modal__info">
            {translate(messages.fileDidntUpload)}{' '}
            <Button link data-test="upload-modal-file-didnt-upload" onClick={handleDownload}>
              {translate(messages.fileDidntUploadLink)}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

UploadStatisticsModal.propTypes = {
  statistics: PropTypes.object,
  onOk: PropTypes.func,
  onHide: PropTypes.func,
  open: PropTypes.bool,
};

export default UploadStatisticsModal;
