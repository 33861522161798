import { v4 as uuidv4 } from 'uuid';

export const SUBJECT_KEY_TABS = {
  MAIN: 'main',
  QUESTIONNAIRE: 'questionnaire',
};

export const EMPTY_CATEGORY = {
  benchmark: 0,
  controls: [],
  findings: 0,
  id: null,
  overview: '',
  position: 0,
  progress: 0,
  score: 0,
  status: 0,
  title: '',
  weight: 0,
};

export const fillCategoriesByEmpty = (categories) => {
  if (categories.length > 3) {
    return categories;
  }

  return [0, 1, 2].map((i) => categories[i] || { ...EMPTY_CATEGORY, id: uuidv4() });
};

export const createNewEmptyCategories = () => [
  { ...EMPTY_CATEGORY, id: uuidv4(), title: 'Category' },
];
