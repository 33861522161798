import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import UploadFileArea from 'components/ImportWizard/UploadFileArea';
import CardWithLogo from 'components/CardWithLogo';

// UTILS
import { STAGE } from 'pages/Assessments/importWizardMenuModal/lib';
import { convertExcelToJson } from 'components/ImportWizard/lib';

// REDUX
import { setParam } from 'components/ImportWizard/excel/reducers';
import { selectExcelImportWizard } from 'components/ImportWizard/selectors';
import { ASSESSMENT_FILE_UPLOAD } from 'constants/assessmentFileUpload';

const UploadFileStage = ({ setStage, uploadedFile, setUploadedFile, closeMenuModal }) => {
  const dispatch = useDispatch();
  const { selectedType } = useSelector(selectExcelImportWizard);

  const setFileData = (value) => {
    dispatch(setParam({ key: 'fileData', value }));
  };

  const onClickNext = useCallback(() => {
    if (selectedType !== ASSESSMENT_FILE_UPLOAD.WORD.type) {
      convertExcelToJson(uploadedFile, setFileData);
    }
    setStage(STAGE.WIZARD);
    closeMenuModal();
  }, [uploadedFile]);

  const rightButtonAdditionalProps = useMemo(
    () => ({
      disabled: uploadedFile === undefined,
    }),
    [uploadedFile],
  );

  return (
    <div className="import-wizard-menu-modal__upload-file-content">
      <UploadFileArea uploadedFile={uploadedFile} setUploadedFile={setUploadedFile} />
      <CardWithLogo.Footer
        className="import-wizard-menu-modal__footer"
        rightButtonOnClick={onClickNext}
        rightButtonAdditionalProps={rightButtonAdditionalProps}
        dynamicColor
        leftComponent={<div />}
      />
    </div>
  );
};

UploadFileStage.propTypes = {
  setStage: PropTypes.func,
  uploadedFile: PropTypes.object,
  setUploadedFile: PropTypes.func,
  closeMenuModal: PropTypes.func,
};

export default UploadFileStage;
