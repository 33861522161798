import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { translate } from 'utils/index';
import { getOffset } from 'utils/html';
import { saveRecommendation } from 'api/recommendation';
import { selectFinding } from 'pages/Assessment/finalized/findings/selectors';
import { useRole } from 'hooks/useRole';
import { Apps } from 'constants/apps';
import { FindingIcon } from 'components/Icons';
import SecondaryButton from 'ui/SecondaryButton';

import { messages } from './messages';
import { API_STATUS } from 'constants/api';
import { mixpanelRecommendationUpdated } from 'utils/mixpanel';
import './addRecommendation.scss';

const AddRecommendation = () => {
  const dispatch = useDispatch();
  const { isRestrictedBoSoViewerRole } = useRole();
  const {
    control,
    id: findingId,
    app,
    description: findingDescription,
    recommendations,
  } = useSelector(selectFinding);
  const [loading, setLoading] = useState();
  const assessment = useSelector((state) => state.assessment);
  const { user } = useSelector((state) => state.profile);

  const onAdd = async () => {
    const id = uuidv4();
    const payload = {
      control,
      findingId,
      recommendation: {
        id,
        description: '',
      },
    };

    setLoading(true);
    const { status } = await dispatch(saveRecommendation(payload)).unwrap();
    if (status === API_STATUS.SUCCESS) {
      mixpanelRecommendationUpdated({
        relation: assessment?.customerId,
        type: 'created',
        assessmentName: assessment?.title,
        originalControl: control?.title,
        originalFinding: findingDescription,
        assignedOrganizationName: user?.current_organization?.label,
        description: '',
        numberOfRecommendations: recommendations?.length + 1,
      });
    }
    setLoading(false);

    setTimeout(() => {
      const recommendationElement = document.querySelector(
        '.assessment-finalized-recommendation:last-child',
      );

      const recommendationElementOffset = getOffset(recommendationElement);

      window.scroll({
        top: recommendationElementOffset.top - 70,
        left: 0,
        behavior: 'smooth',
      });
    }, 200);
  };

  return app === Apps.sbom ? null : (
    <SecondaryButton
      data-test="add-recommendation-button"
      className="assessment-finalized-add-recommendation__button"
      disabled={isRestrictedBoSoViewerRole}
      onClick={onAdd}
      loading={loading}
      iconComponent={
        <FindingIcon className="assessment-finalized-add-recommendation__button-ico" />
      }
      noBorder
    >
      {translate(messages.addRecommendation)}
    </SecondaryButton>
  );
};

export default AddRecommendation;
