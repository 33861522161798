import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import MyFilesFilters from 'pages/MyFiles/myFilesFilters';
import Loader from 'ui/Loader';
import Modal from 'ui/Modal';
import Icon from 'ui/Icon';
import { getMyFiles, uploadMyFiles } from 'api/myFiles';
import { generalMessages } from 'constants/messages';
import { translate } from 'utils/index';
import { messages } from './messages';
import EvidenceMetadataEditor from 'components/EvidenceMetadataEditor';
import FilePreview from 'pages/MyFiles/filePreview';
import RenameFile from 'pages/MyFiles/renameFile';
import RemoveFile from 'pages/MyFiles/removeFile';
import MyFilesList from 'pages/MyFiles/MyFilesList';
import { selectProfile } from 'pages/Profile/selectors';
import { API_STATUS } from 'constants/api';
import { useFileUploadProcess } from 'components/FileUploadIndicator/useFileUploadProcess';
import { FIRST_OFFSET } from 'pages/MyFiles/constants';

import './index.scss';

const MyFilesPopup = ({ visible, onCancel, onOk, multi = true }) => {
  const dispatch = useDispatch();
  const [galleryFetched, setGalleryFetched] = useState(false);
  const [isMyFileUpload, setIsMyFileUpload] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [search, setSearch] = useState('');
  const files = useSelector((state) => state.myFiles.myFiles);
  const filters = useSelector((state) => state.myFiles.filters);
  const [fileToEditMeta, setFileToEditMeta] = useState(null);
  const [fileIndexToPreview, setFileIndexToPreview] = useState(null);
  const [fileIndexToRename, setFileIndexToRename] = useState(null);
  const [fileIndexToDelete, setFileIndexToDelete] = useState(null);
  const { abortController, startUploading, finishUploding, renderConfirmationModal } =
    useFileUploadProcess();

  const {
    user: {
      current_organization: { id: currentOrganizationId },
    },
  } = useSelector(selectProfile);

  const fetchMyFiles = async (offset = FIRST_OFFSET) => {
    const parsedFilters = filters.reduce((acc, filter) => {
      if (filter.value) {
        acc[filter.type] = filter.id;
      }
      return acc;
    }, {});

    dispatch(
      getMyFiles({
        offset,
        search,
        ...parsedFilters,
      }),
    );
  };

  useEffect(async () => {
    await fetchMyFiles();
    if (!galleryFetched) {
      setGalleryFetched(true);
    }
  }, [filters, search]);

  const onFiltersChange = (value) => {
    setSearch(value);
  };

  const onFileUpload = async (e) => {
    setIsMyFileUpload(true);
    startUploading();
    const response = await dispatch(
      uploadMyFiles({
        currentOrganizationId,
        abortController,
        file: e.target.files[0],
      }),
    ).unwrap();
    finishUploding();
    fetchMyFiles();
    setIsMyFileUpload(false);
    if (response?.file && response?.status === API_STATUS.SUCCESS) {
      setFileToEditMeta(response.file);
    }
  };

  const selectFile = (event) => {
    const filesToLink = selectedFiles?.map((id) => files.find((file) => file.id === id));
    onOk(event, multi ? filesToLink : filesToLink[0]);
  };

  return (
    <Modal
      className="my-files-popup__modal"
      closeIcon={<Icon icon="close" />}
      open={visible}
      onCancel={onCancel}
      onOk={selectFile}
      okDisabled={!selectedFiles?.length}
      width={1170}
      height={600}
      okText={translate(generalMessages.select)}
      cancelText={translate(generalMessages.cancel)}
    >
      <div className="my-files-popup">
        <div className="my-files-popup__header">
          <h3 className="my-files-popup__title">
            <Icon icon="file" /> {translate(messages.myFiles)}
          </h3>
          <div className="my-files-popup__description">
            {translate(messages.selectFileOrClickToUpload)}
          </div>
          <MyFilesFilters onFiltersChange={onFiltersChange} />
        </div>
        <div className="my-files-popup__body">
          <MyFilesList
            files={files}
            fetchMyFiles={fetchMyFiles}
            onEditClick={(file) => setFileToEditMeta(file)}
            handlePreview={(fileIndex) => setFileIndexToPreview(fileIndex)}
            handleRename={(fileIndex) => setFileIndexToRename(fileIndex)}
            handleDelete={(fileIndex) => setFileIndexToDelete(fileIndex)}
            handleChooseFile={(event, selectedFiles) => selectFile(event, selectedFiles)}
            onFileUpload={onFileUpload}
            selectMulti={true}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        </div>
      </div>
      {fileToEditMeta && (
        <EvidenceMetadataEditor
          file={fileToEditMeta}
          onHide={() => setFileToEditMeta(null)}
          open={!!fileToEditMeta}
        />
      )}
      {fileIndexToPreview !== null && (
        <FilePreview
          fileData={files[fileIndexToPreview] ? files[fileIndexToPreview] : undefined}
          open={fileIndexToPreview !== null}
          onClose={() => setFileIndexToPreview(null)}
          hasNext={fileIndexToPreview > files?.length}
          hasPrev={fileIndexToPreview > 0}
          onClickNext={() =>
            setFileIndexToPreview((prev) => (prev < files?.length - 1 ? prev + 1 : 0))
          }
          onClickPrev={() =>
            setFileIndexToPreview((prev) => (prev > 0 ? prev - 1 : files?.length - 1))
          }
        />
      )}
      {fileIndexToRename !== null && (
        <RenameFile
          open={fileIndexToRename !== null}
          onClose={() => setFileIndexToRename(null)}
          file={files[fileIndexToRename] ? files[fileIndexToRename] : undefined}
        />
      )}
      {fileIndexToDelete !== null && (
        <RemoveFile
          open={fileIndexToDelete !== null}
          onClose={() => setFileIndexToDelete(null)}
          file={files[fileIndexToDelete] ? files[fileIndexToDelete] : undefined}
        />
      )}
      {renderConfirmationModal()}
      {isMyFileUpload && <Loader />}
    </Modal>
  );
};

MyFilesPopup.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  multi: PropTypes.bool,
};

export default MyFilesPopup;
