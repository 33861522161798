import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Table from 'ui/Table';
import ScoreBenchmark from 'components/AssessmentScoreBenchmark';
import ROUTES from 'constants/routes';
import { translate } from 'utils/index';
import { useAppNavigate, useIsMyVendorsTabSelected } from 'hooks/navigation';

import { messages } from 'pages/Assessment/messages';
import { selectCategory } from 'pages/Assessment/reducers';
import {
  selectCategories,
  selectCategory as selectorsSelectCategory,
} from 'pages/Assessment/selectors';
import Approved from './columns/approved';
import Findings from './columns/findings';
import Title from './columns/title';
import { countNotApprovedControls } from './lib';
import './categories.scss';

const Categories = ({ isQuestionnaireTab }) => {
  const [searchParams] = useSearchParams();
  const appNavigate = useAppNavigate();
  const categories = useSelector(selectCategories);
  const selectedCategory = useSelector(selectorsSelectCategory);
  const { assessmentId } = useParams();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const isMyVendorsTabSelected = useIsMyVendorsTabSelected();
  const categoryId = searchParams.get('categoryId');

  const redirectRoute = useMemo(
    () =>
      isMyVendorsTabSelected
        ? ROUTES.VENDOR_ASSESSMENT_QUESTIONNAIRE
        : ROUTES.CUSTOMER_ASSESSMENT_QUESTIONNAIRE,
    [isMyVendorsTabSelected],
  );

  useEffect(() => {
    if (categoryId) {
      dispatch(selectCategory(categoryId));
    }
  }, [categoryId]);

  useEffect(() => {
    if (categories[0] && !selectedCategory.id) {
      dispatch(selectCategory(categories[0].id));
    }
  }, [categories]);

  useEffect(() => {
    const categoryIndex = categories.findIndex(({ id }) => id === selectedCategory.id);
    if (isQuestionnaireTab) {
      setIndex(categoryIndex);
    }
  }, [selectedCategory]);

  const defaultColumns = [
    {
      title: translate(messages.name),
      dataIndex: 'title',
      render: (title, category, rowIndex) => (
        <Title category={category} rowIndex={rowIndex} title={title} />
      ),
    },
    {
      title: translate(messages.approved),
      dataIndex: 'approved',
      align: 'center',
      render: (value, category) => <Approved category={category} />,
    },
    {
      title: translate(messages.notApproved),
      dataIndex: 'notApproved',
      align: 'center',
      render: (value, category) => <div>{countNotApprovedControls(category) || '-'}</div>,
    },
    {
      title: translate(messages.scoreBenchmark),
      dataIndex: 'score',
      render: (score, category) => <ScoreBenchmark score={score} benchmark={category?.benchmark} />,
    },
    {
      title: translate(messages.findingsColumn),
      dataIndex: 'findings',
      align: 'center',
      render: (findings, category) => <Findings category={category} />,
    },
  ];

  const questionnaireColumns = [
    {
      title: translate(messages.name),
      dataIndex: 'title',
      render: (title, category, rowIndex) => (
        <Title category={category} rowIndex={rowIndex} title={title} />
      ),
    },
    {
      title: translate(messages.approved),
      dataIndex: 'approved',
      align: 'center',
      render: (value, category) => <Approved category={category} showCounter />,
    },
    {
      title: translate(messages.scoreBenchmark),
      dataIndex: 'score',
      render: (score, category) => <ScoreBenchmark score={score} benchmark={category?.benchmark} />,
    },
    {
      title: translate(messages.findingsColumn),
      dataIndex: 'findings',
      align: 'center',
      render: (findings, category) => <Findings category={category} />,
    },
  ];

  const columns = isQuestionnaireTab ? questionnaireColumns : defaultColumns;

  const onRow = (category, rowIndex) => ({
    onClick: () => {
      setIndex(rowIndex);
      dispatch(selectCategory(category.id));
      if (!isQuestionnaireTab) {
        appNavigate(redirectRoute, { assessmentId }, { replace: true });
      }
    },
  });

  const rowClassName = (record, rowIndex) => {
    return isQuestionnaireTab && index === rowIndex ? 'selected' : '';
  };

  return (
    <div
      className={classNames('assessment-finalized-categories', {
        'assessment-finalized-categories--is-questionnaire-tab': isQuestionnaireTab,
      })}
    >
      <div className="assessment-finalized-categories__header-title">
        <span className="assessment-finalized-categories__header-title-text">
          {translate(messages.categories)}
        </span>
        {` (${categories?.length})`}
      </div>
      <Table
        columns={columns}
        dataSource={categories}
        onRow={onRow}
        pagination={false}
        rowClassName={rowClassName}
      />
    </div>
  );
};

Categories.propTypes = {
  isQuestionnaireTab: PropTypes.bool,
};

export default Categories;
