export const ControlType = {
  Input: 0,
  TextArea: 1,
  RadioGroup: 2,
  Checkbox: 3,
  DropDown: 4,
  FileUpload: 5,
  MultiSelect: 6,
  DatePicker: 7,
};
