import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Radio from 'ui/Radio';

import './index.scss';

const RadioGroup = ({
  children,
  error,
  horizontal,
  className,
  options,
  onChange,
  name,
  value,
  rtl,
  ...otherProps
}) => {
  const radioGroupClassNames = classNames('ui-radio-group', className, {
    'ui-radio-group--horizontal': horizontal,
    'ui-radio-group__error': error,
    'ui-radio-group--rtl': rtl,
  });

  const renderOptions = (options || []).map((option) => {
    return (
      <Radio
        key={option.key}
        onChange={onChange}
        value={option?.value}
        name={name}
        checked={option?.value.toString() === value?.toString()}
        {...otherProps}
        data-test={`${otherProps['data-test'] || option['data-test']}-radio-${
          option.label || option.key
        }`}
      >
        {option.label}
      </Radio>
    );
  });

  return (
    <>
      <div className={radioGroupClassNames}>{options?.length > 0 ? renderOptions : children}</div>
      <div className="ui-radio-group__error">{error}</div>
    </>
  );
};

RadioGroup.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string,
  horizontal: PropTypes.bool,
  className: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
  rtl: PropTypes.bool,
};

export default RadioGroup;
