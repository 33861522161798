import * as yup from 'yup';

const REGEX_LATIN = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi;
const REGEX_MULTIPLE_WORDS = /^\s*[\S]+(\s[\S]+)+\s*$/gms;

export const newVendorDetails = (
  fullnameInvalid,
  fullnameError,
  fullnameRequired,
  emailError,
  emailRequired,
  companyRequired,
) =>
  yup.object().shape({
    fullname: yup
      .string()
      .matches(REGEX_LATIN, fullnameInvalid)
      .matches(REGEX_MULTIPLE_WORDS, fullnameError)
      .required(fullnameRequired),
    email: yup.string().email(emailError).required(emailRequired),
    company: yup.string().required(companyRequired),
  });
