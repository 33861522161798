import { useEffect, useState } from 'react';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { translate } from 'utils/index';
import { useSelector } from 'react-redux';
import { selectProfile } from 'pages/Profile/selectors';
import { messages } from './messages';

const usePhoneInput = (phoneNumberErrors, phone, prefix) => {
  const { user } = useSelector(selectProfile);

  const [values, setValues] = useState({
    phone: user.phone || phone || '',
    corporateGeography: user.corporateGeography || prefix || 0,
    nationalNumber: undefined,
  });

  const [errors, setErrors] = useState(phoneNumberErrors || {});

  useEffect(() => {
    setErrors((prev) => {
      return {
        ...prev,
        ...phoneNumberErrors,
      };
    });
  }, [phoneNumberErrors]);

  const onChangePhoneInput = (key, value) => {
    // Changing a prefix should clear phone number as well
    if (key === 'corporateGeography') {
      const newData = {
        ...values,
        corporateGeography: value,
      };
      onValidatePhoneInput(newData);
      return setValues(newData);
    }

    onValidatePhoneInput({
      corporateGeography: values.corporateGeography,
      phone: value,
    });

    // Empty string in `parsePhoneNumber` prevents from page crush which package causes
    const { number, nationalNumber } = parsePhoneNumber(`${value}` || '') || {};
    return setValues({ ...values, phone: number || value, nationalNumber });
  };

  const onValidatePhoneInput = (data) => {
    const { phone, corporateGeography } = data;
    const newErrors = {};

    if (!phone) {
      newErrors.phone = translate(messages.phoneErrorRequired);
    } else if (phone && !isValidPhoneNumber(`+${corporateGeography}${phone}`)) {
      newErrors.phone = translate(messages.phoneErrorInvalidFormat);
    }

    if (!corporateGeography) {
      newErrors.prefix = translate(messages.prefixErrorRequired);
    }

    setErrors(newErrors);
    return newErrors;
  };

  return {
    onValidatePhoneInput,
    onChangePhoneInput,
    errors,
    values,
  };
};

export default usePhoneInput;
