import React from 'react';
import UiAlert from 'ui/Alert';
import { useSelector } from 'react-redux';
import { translate } from 'utils/index';
import { selectOverview } from 'pages/Overview/selectors';
import { messages } from './messages';
import { sanitizer } from 'utils/sanitizer';

import './alert.scss';

const Alert = () => {
  const overview = useSelector(selectOverview);

  const getAlertType = () => {
    switch (overview['risk-score']) {
      case 0:
        return 'success';
      case 1:
        return 'warning';
      case 2:
        return 'error';
      default:
        return 'success';
    }
  };

  const getAlertMessage = () => {
    switch (overview['risk-score']) {
      case 0:
        return translate(messages.lowRisk);
      case 1:
        return translate(messages.mediumRisk);
      case 2:
        return translate(messages.highRisk);
      default:
        return translate(messages.lowRisk);
    }
  };

  return (
    <UiAlert
      className="overview-alert"
      type={getAlertType()}
      message={<span dangerouslySetInnerHTML={{ __html: sanitizer(getAlertMessage()) }} />}
      showIcon
    />
  );
};

export default Alert;
